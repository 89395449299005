import React from 'react';
import { AccordionSummary, Typography } from '@talentmesh/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps, Theme, useTheme } from '@mui/material';

interface AdditionalInformationAccordionSummaryProps {
    title: string;
    sx?: SxProps<Theme> | undefined;
}

const AdditionalInformationAccordionSummary = ({
    title,
    sx,
}: AdditionalInformationAccordionSummaryProps): JSX.Element => {
    const theme = useTheme();

    return (
        <AccordionSummary
            sx={{
                backgroundColor: theme.palette.background.aliciaBlue,
                height: theme.spacing(8),
                ...sx,
            }}
            expandIcon={<ExpandMoreIcon color="secondary" />}
        >
            <Typography variant="body1" fontWeight="bold" color={theme.palette.text.menuTitle}>
                {title}
            </Typography>
        </AccordionSummary>
    );
};

export default AdditionalInformationAccordionSummary;
