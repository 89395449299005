import React, { useState } from 'react';
import { Box, Stack } from '@talentmesh/core';
import { PaymentIntent as StripePaymentIntent } from '@stripe/stripe-js';
import PageBackNavigation from '../../Components/PageBackNavigation';
import CompensationSection from './CompensationSection/CompensationSection';
import Compensation, { defaultCompensation } from './CompensationSection/Compensation';
import ThreeColumnContainer from '../../Components/Payment/ThreeColumnContainer';
import CheckoutDetails from './CheckoutDetails/CheckoutDetails';
import { PaymentIntent } from '../../Models/PaymentIntent';
import PaymentDetailsSection from './PaymentDetailsSection/PaymentDetailsSection';
import BillingInfo from '../../Models/BillingInfo';
import PaymentMethod from '../../Models/PaymentMethod';

interface CheckoutFormsProps {
    recruitmentId: string | null;
    applicantId: string | null;
    billingInfo: BillingInfo | undefined;
    savedPaymentMethod: PaymentMethod | undefined;
    setBillingInfo: React.Dispatch<React.SetStateAction<BillingInfo | undefined>>;
    setResultPaymentIntent: React.Dispatch<React.SetStateAction<StripePaymentIntent | undefined>>;
}

const CheckoutForms = ({
    recruitmentId,
    applicantId,
    billingInfo,
    savedPaymentMethod,
    setBillingInfo,
    setResultPaymentIntent,
}: CheckoutFormsProps) => {
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>(undefined);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
    const [compensation, setCompensation] = useState<Compensation>(defaultCompensation);
    const [isCompensationInEdit, setIsCompensationInEdit] = React.useState<boolean>(true);

    return (
        <ThreeColumnContainer sx={{ marginTop: 3.75, marginBottom: 2.5 }} left={<PageBackNavigation />}>
            <Stack direction="row" justifyContent="center" spacing={2}>
                <Stack sx={{ width: '50%' }} spacing={3.75}>
                    <CompensationSection
                        recruitmentId={recruitmentId}
                        applicantId={applicantId}
                        paymentLoading={paymentLoading}
                        setPaymentLoading={setPaymentLoading}
                        setPaymentIntent={setPaymentIntent}
                        compensation={compensation}
                        setCompensation={setCompensation}
                        isCompensationInEdit={isCompensationInEdit}
                        setIsCompensationInEdit={setIsCompensationInEdit}
                    />
                    <PaymentDetailsSection
                        paymentIntent={paymentIntent}
                        setResultPaymentIntent={setResultPaymentIntent}
                        isCompensationInEdit={isCompensationInEdit}
                        billingInfo={billingInfo}
                        setBillingInfo={setBillingInfo}
                        savedPaymentMethod={savedPaymentMethod}
                    />
                </Stack>
                <Box sx={{ width: '50%' }}>
                    <CheckoutDetails paymentIntent={paymentIntent} compensation={compensation} />
                </Box>
            </Stack>
        </ThreeColumnContainer>
    );
};

export default CheckoutForms;
