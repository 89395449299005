import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UpdateCompanyProfileDTO } from '../Clients/CompanyClient';
import { useCompanyClient } from '../Hooks/ClientHooks';
import { CompanyProfile, companyProfileFromDTO, DefaultCompanyProfile } from '../Models/CompanyProfile';
import useCompanyIdRouteMatch from '../Hooks/UseCompanyIdRouteMatch';
import { useUserContext } from './UserContext';
import ErrorQueryNames from '../Pages/Errors/ErrorQueryNames';
import UIStrings from '../Utils/UIStrings';
import RoutePath from '../Routing/RoutePath';

type CompanyContextType = {
    currentCompanyProfile: CompanyProfile;
    isLoading: boolean;
    refreshCurrentCompanyProfile: (companyId: string) => Promise<void>;
    updateCompanyProfile: (input: CompanyProfile) => Promise<void>;
};

export const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

const CompanyContextProvider = CompanyContext.Provider;

interface CompanyProviderProps {
    children: ReactNode;
}

export const CompanyProvider = ({ children }: CompanyProviderProps): JSX.Element => {
    const [currentCompanyProfile, setCurrentCompanyProfile] = useState<CompanyProfile>(DefaultCompanyProfile);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const companyClient = useCompanyClient();
    const navigate = useNavigate();
    const companyId = useCompanyIdRouteMatch();
    const { memberships } = useUserContext();

    const updateCompanyProfile = async (newCompanyProfile: CompanyProfile) => {
        const companyProfile: UpdateCompanyProfileDTO = {
            id: '',
            companyName: newCompanyProfile.companyName,
            companySlug: newCompanyProfile.companySlug,
            companyDescription: newCompanyProfile.companyDescription,
        };

        const response = await companyClient.updateCompanyProfileAsync(currentCompanyProfile.id, companyProfile);
        setCurrentCompanyProfile((prev) => {
            return {
                ...companyProfileFromDTO(response),
                id: prev.id,
            };
        });
    };

    const refreshCurrentCompanyProfile = async (id: string) => {
        const companyProfile = await companyClient.getCompanyProfile(id);
        setCurrentCompanyProfile(companyProfileFromDTO(companyProfile));
    };

    useEffect(() => {
        if (companyId && memberships.length > 0) {
            const membership = memberships.find((m) => m.companyId === companyId);
            // ensure that the user is a member in a company with company id which is taken from the url path or query string
            // if not then we simply redirect to the error page.
            if (!membership) {
                const params = new URLSearchParams();
                params.append(ErrorQueryNames.ErrorTitle, UIStrings.NoAccessToOrganization);
                navigate(
                    {
                        pathname: RoutePath.Error,
                        search: params.toString(),
                    },
                    { replace: true },
                );
            } else {
                const get = async () => {
                    try {
                        setIsLoading(true);
                        await refreshCurrentCompanyProfile(companyId);
                    } finally {
                        setIsLoading(false);
                    }
                };
                get();
            }
        }
    }, [companyId, memberships]);

    return (
        <CompanyContextProvider
            value={{
                currentCompanyProfile,
                isLoading,
                refreshCurrentCompanyProfile,
                updateCompanyProfile,
            }}
        >
            {children}
        </CompanyContextProvider>
    );
};

export function useCompanyContext() {
    const context = useContext(CompanyContext);
    if (!context) {
        throw new Error('useCompanyContext must be used within the CompanyContext.Provider');
    }
    return context;
}
