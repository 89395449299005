import { useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { Stack } from '@talentmesh/core';

interface PanelContainerProps {
    children: ReactNode;
}

function PanelContainer({ children }: PanelContainerProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.silverSand,
                alignItems: 'start',
                padding: theme.spacing(1.25),
                borderRadius: theme.shape.smallBorderRadius,
            }}
        >
            {children}
        </Stack>
    );
}

export default PanelContainer;
