import React from 'react';
import { Box } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import useWrongEmailInstructionsHandlers from '../Utils/useWrongEmailInstructionsHandlers';
import ContentLink from './ContentLink';
import InstructionsSpanTypography from './InstructionsSpanTypography';

function WrongEmailInstructions(): JSX.Element {
    const { createNewAccountHandler, loginAgainHandler } = useWrongEmailInstructionsHandlers();

    return (
        <Box>
            <ContentLink onClick={createNewAccountHandler}>{UIStrings.CreateANewAccount}</ContentLink>
            <InstructionsSpanTypography>{` ${UIStrings.IfYouMistypedEmailPreviouslyOrTry} `}</InstructionsSpanTypography>
            <ContentLink onClick={loginAgainHandler}>{`${UIStrings.LogInAgain}`}</ContentLink>
            <InstructionsSpanTypography>{` ${UIStrings.WithAnotherEmail}`}</InstructionsSpanTypography>
        </Box>
    );
}

export default WrongEmailInstructions;
