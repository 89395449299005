import { useTheme } from '@mui/material/styles';
import { Stack } from '@talentmesh/core';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useCreateRecruitmentContext } from '../../../Create/Contexts/CreateRecruitmentContext';
import { informationFormValues2Model, RequestInformationForm } from '../RequestInformationStepUtils';
import GeneralInformationAccordion from './GeneralInformationAccordion';
import MotivationalInformatioAccordion from './MotivationalInformatioAccordion';
import StartupInformationAccordion from './StartupInformationAccordion';

const RequestInformationStepBody = (): JSX.Element => {
    const theme = useTheme();
    const { setApplicantInformation } = useCreateRecruitmentContext();

    const { values } = useFormikContext<RequestInformationForm>();

    useEffect(() => {
        setApplicantInformation(informationFormValues2Model(values));
    }, [values]);

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <GeneralInformationAccordion />
            <MotivationalInformatioAccordion />
            <StartupInformationAccordion />
        </Stack>
    );
};

export default RequestInformationStepBody;
