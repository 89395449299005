import React from 'react';
import { Paper } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';
import ScoreDetailsHeader from '../Components/Common/ScoreDetailsHeader';
import EICharacteristicsPanel from './Components/Characteristics/EICharacteristicsPanel';

function EmotionalIntelligenceScoreDetails(): JSX.Element {
    return (
        <Paper elevation={0} sx={{ p: 2.5 }}>
            <ScoreDetailsHeader description={UIStrings.EmotionalIntelligenceScoreDetailsHeader} />
            <EICharacteristicsPanel />
        </Paper>
    );
}

export default EmotionalIntelligenceScoreDetails;
