import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@talentmesh/core';
import { KeyboardArrowDownIcon } from '@talentmesh/icons';
import SummaryTypography from './SummaryTypography';
import PanelContainer from './PanelContainer';

interface AccordionItemProps extends AccordionProps {
    header: string;
}

function AccordionItem({ header, children, expanded, onChange }: AccordionItemProps): JSX.Element {
    const theme = useTheme();

    return (
        <Accordion elevation={0} square expanded={expanded} onChange={onChange} disableGutters>
            <AccordionSummary
                expandIcon={<KeyboardArrowDownIcon sx={{ color: theme.palette.secondary.main }} />}
                sx={{
                    padding: 0,
                    marginY: 0,
                    marginX: theme.spacing(1.25),
                }}
            >
                <SummaryTypography text={header} />
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: 0,
                }}
            >
                <PanelContainer>{children}</PanelContainer>
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordionItem;
