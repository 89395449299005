import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import RoutePath from '../Routing/RoutePath';
import { UserSignupNavigationData } from './UserSignupNavigationContext';

function useUserSignupNavigationContextValue(): UserSignupNavigationData {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);

    const navigateToNextPage = (): void => {
        navigate(RoutePath.Provision, { replace: true });
    };

    const refreshTokenAsync = async () => {
        setLoading(true);
        await getAccessTokenSilently({ ignoreCache: true });
        setLoading(false);
    };

    return {
        navigateToNextPage,
        refreshTokenAsync,
        isLoading,
    };
}

export default useUserSignupNavigationContextValue;
