import GeneratedExperienceCriteria, {
    DefaultExperienceCriteria,
} from '../DTO/ExperienceCriteria/GeneratedExperienceCriteria';
import GenerateExperienceCriteriaDTO from '../DTO/ExperienceCriteria/GenerateExperienceCriteriaDTO';
import { post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class CriteriaClient extends ClientBase {
    async generateCriteriaAsync(dto: GenerateExperienceCriteriaDTO): Promise<GeneratedExperienceCriteria> {
        const response = await post<GeneratedExperienceCriteria>({
            path: this.addHost(`ai/criteria/generate`),
            accessToken: await this.getAccessTokenSilently(),
            ...toJson(dto),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultExperienceCriteria;
    }
}
