import * as yup from 'yup';
import YupValidationErrors from '../Utils/YupValidationErrors';
import { CandidateInviteDTO } from '../DTO/Users/CandidateInviteDTO';
import UIStrings from '../Utils/UIStrings';

export interface Candidate {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    isInternal: boolean;
}

export const DefaultCandidate: Candidate = {
    email: '',
    id: '',
    firstName: '',
    lastName: '',
    isInternal: false,
};

export type CandidateAction = (candidate: Candidate) => void;

export function candidateFromCandidateInviteDTO(dto: CandidateInviteDTO): Candidate {
    return {
        id: dto.candidateId,
        firstName: dto.firstName,
        lastName: dto.lastName,
        email: dto.email,
        isInternal: dto.isInternal,
    };
}

export const candidateValidationSchema = yup.object({
    firstName: yup.string().max(128, YupValidationErrors.MaxSymbolsValidationError).required(UIStrings.Required),
    lastName: yup.string().max(128, YupValidationErrors.MaxSymbolsValidationError).required(UIStrings.Required),
    email: yup
        .string()
        .email(YupValidationErrors.EmailValidationError)
        .max(128, YupValidationErrors.MaxSymbolsValidationError)
        .required(UIStrings.Required),
});
