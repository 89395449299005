import React from 'react';
import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';

const AssessmentResetDialogHeader = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Stack spacing={theme.spacing(1)} alignItems="flex-start">
            <Typography variant="h4" color={theme.palette.text.primary}>
                {UIStrings.ResetAssessment}
            </Typography>
        </Stack>
    );
};

export default AssessmentResetDialogHeader;
