import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import { AutoAwesome } from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';

function ImproveWithAILabel(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="row" spacing={theme.spacing(1)} justifyContent="center" alignItems="center">
            <AutoAwesome />
            <Typography variant="button">{UIStrings.ImproveWithAI}</Typography>
        </Stack>
    );
}

export default ImproveWithAILabel;
