import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Formik, FormikHelpers } from 'formik';
import CompanyProfileForm from './CompanyProfileForm';
import UIStrings from '../../../Utils/UIStrings';
import { CompanyImage } from '../../../Clients/CompanyClient';
import CompanyUploadImageSection from './CompanyUploadImageSection';
import { useNotificationContext } from '../../../Context/NotificationContext';
import SlugExistsError from '../../../Utils/HTTP/Errors/SlugExistsError';
import { useCompanyContext } from '../../../Context/CompanyContext';
import useCompanyProfileValidationSchema from '../Hooks/useCompanyProfileValidationSchema';
import LoadingTab from '../AccountSettingsTabs/LoadingTab';
import useCompanyId from '../../../Hooks/UseCompanyId';

interface AccountInfoFormInitialValues {
    companyName: string;
    companySlug: string;
    companyDescription: string;
}

const CompanyProfileTab = () => {
    const theme = useTheme();
    const { currentCompanyProfile, updateCompanyProfile, refreshCurrentCompanyProfile } = useCompanyContext();
    const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
    const { showSuccessToaster, showFailToaster } = useNotificationContext();
    const { companyName, companySlug, companyDescription } = currentCompanyProfile;
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
    const companyId = useCompanyId();

    const initialValues: AccountInfoFormInitialValues = {
        companyName,
        companySlug,
        companyDescription,
    };

    const validationSchema = useCompanyProfileValidationSchema();

    useEffect(() => {
        const doRefetchCompany = async () => {
            try {
                await refreshCurrentCompanyProfile(companyId);
            } catch (error) {
                setLoadingFailed(true);
            } finally {
                setLoading(false);
            }
        };
        doRefetchCompany();
    }, []);

    const submitHandler = async (
        values: AccountInfoFormInitialValues,
        helpers: FormikHelpers<AccountInfoFormInitialValues>,
    ) => {
        try {
            setSavingInProgress(true);

            await updateCompanyProfile({
                ...currentCompanyProfile,
                ...values,
            });
            showSuccessToaster(UIStrings.AccountDataSuccessfullyUpdated);
        } catch (error) {
            if (error instanceof SlugExistsError) {
                const { detail } = error;
                const { setErrors } = helpers;
                setErrors({ companySlug: detail });
            }
            showFailToaster();
        } finally {
            setSavingInProgress(false);
        }
    };

    return (
        <LoadingTab isLoading={loading} loadingFailed={loadingFailed}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
                enableReinitialize
            >
                <CompanyProfileForm loading={savingInProgress}>
                    <CompanyUploadImageSection
                        circularCrop
                        title={UIStrings.Icon}
                        description={UIStrings.AScaledDownVersionOfYourLogo}
                        src={currentCompanyProfile.companyIconURL}
                        companyImageType={CompanyImage.Icon}
                    />
                    <CompanyUploadImageSection
                        title={UIStrings.CompanyLogo}
                        description={UIStrings.TheFullScaleVersionOfYourLogo}
                        src={currentCompanyProfile.companyLogoURL}
                        aspect={3}
                        companyImageType={CompanyImage.Logo}
                        sx={{
                            borderRadius: theme.shape.smallBorderRadius,
                            width: theme.spacing(26.25),
                            height: theme.spacing(8.75),
                        }}
                    />
                </CompanyProfileForm>
            </Formik>
        </LoadingTab>
    );
};

export default CompanyProfileTab;
