import { useTheme } from '@mui/material/styles';
import { BoxProps } from '@talentmesh/core';
import { RichTextEditor } from '@talentmesh/rte';
import React from 'react';

interface EmailTemplateViewerProps extends Pick<BoxProps, 'sx'> {
    htmlTemplate: string;
}

function EmailTemplateViewer({ htmlTemplate, sx }: EmailTemplateViewerProps): JSX.Element {
    const theme = useTheme();
    const boxHeight = theme.spacing(62.75);

    return (
        <RichTextEditor
            editable={false}
            features={['bold', 'italic', 'underline', 'ol', 'ul']}
            initialHtml={htmlTemplate}
            toolbarProps={{
                sx: { display: 'none' },
            }}
            contentEditableProps={{
                ariaLabel: 'view email template',
                sx: {
                    height: boxHeight,
                    overflowY: 'auto',
                    padding: theme.spacing(1.25),
                    border: 'none',
                    ...sx,
                },
            }}
        />
    );
}

export default EmailTemplateViewer;
