import { AssessmentStatus } from '../../Models/Configuration';
import { RecruiterTestDTO } from '../Tests/RecruiterTestDTO';
import { CandidateInviteDTO } from '../Users/CandidateInviteDTO';
import { AssessmentSpecificationDTO, DefaultAssessmentSpecificationDTO } from './AssessmentSpecificationDTO';

export interface RecruiterAssessmentDTO {
    id: string;
    clientId: string;
    name: string;
    specification: AssessmentSpecificationDTO;
    createdAt: string;
    closedAt: string;
    status: AssessmentStatus;
    candidates: CandidateInviteDTO[];
    tests: RecruiterTestDTO[];
}

export const DefaultRecruiterAssessmentDTO: RecruiterAssessmentDTO = {
    id: '',
    clientId: '',
    name: '',
    specification: DefaultAssessmentSpecificationDTO,
    createdAt: '',
    closedAt: '',
    status: 'NonPublic',
    candidates: [],
    tests: [],
};
