import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Dialog, Typography, DialogProps, DialogContent } from '@talentmesh/core';
import { ImageCropStrings } from './ImageCropUtils';
import { ImageCropContextProps } from './ImageCropContext';

export interface ImageCropDialogProps
    extends Pick<ImageCropContextProps, 'validationError'>,
        Pick<DialogProps, 'open' | 'children'>,
        Pick<ImageCropStrings, 'dialogContentText'> {
    header: ReactNode;
    actions: ReactNode;
}

export const ImageCropDialog = ({
    open,
    children,
    header,
    actions,
    dialogContentText,
}: ImageCropDialogProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Dialog
            data-cy="ImageCropDialog"
            open={open}
            PaperProps={{
                sx: {
                    margin: 0,
                    padding: theme.spacing(2.5),
                    width: theme.spacing(74),
                    height: theme.spacing(54.125),
                },
            }}
        >
            <DialogContent sx={{ padding: 0 }}>
                <Stack spacing={1.25} sx={{ maxHeight: theme.spacing(38.875) }}>
                    {header}
                    <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
                        {dialogContentText}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: theme.palette.grey[300],
                            width: theme.spacing(69),
                            height: theme.spacing(30),
                        }}
                    >
                        {children}
                    </Box>
                </Stack>
                {actions}
            </DialogContent>
        </Dialog>
    );
};
