import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../Utils/UIStrings';
import { useAssessmentsOverviewContext } from '../Contexts/AssessmentsOverviewContext';
import EmptyAssessmentsOverview from './EmptyAssessmentOverview/EmptyAssessmentsOverview';

const EmptyRecruitmentList = (): JSX.Element => {
    const theme = useTheme();
    const { search } = useAssessmentsOverviewContext();

    return (
        <>
            {search && (
                <Stack
                    sx={{
                        height: theme.spacing(30),
                        backgroundColor: theme.palette.common.white,
                        borderRadius: theme.shape.smallBorderRadius,
                    }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="body2">{UIStrings.NoExactMatchesFound}</Typography>
                </Stack>
            )}
            {!search && <EmptyAssessmentsOverview />}
        </>
    );
};

export default EmptyRecruitmentList;
