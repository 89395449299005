/* eslint-disable no-param-reassign */
import { GridColDef } from '@talentmesh/core';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import {
    createFlexBlankColumn,
    createApplicantColumn,
    createFavoriteColumn,
    createInterviewColumn,
    createMoreActionsColumn,
    createRejectColumn,
    createApplicantProfileColumn,
    createTalentScoreColumn,
    createTestColumn,
    createHireColumn,
    createExperienceScoreColumn,
} from '../Utils/CandidateOverviewListUtils';

function useCandidateOverviewListColumns(): Array<GridColDef> {
    const { testTypes, tabValue, includesExperienceCriteria } = useCandidatesOverviewContext();
    const talentScoreColumn = createTalentScoreColumn();

    const testColumns = testTypes.map((item, index) => createTestColumn(item, true, index < testTypes.length - 1));

    const columns: GridColDef[] = [
        createFavoriteColumn(tabValue),
        createApplicantColumn(),
        createApplicantProfileColumn(),
        talentScoreColumn,
        ...testColumns,
        createFlexBlankColumn(),
        createRejectColumn(),
        createInterviewColumn(),
        createHireColumn(),
        createMoreActionsColumn(),
    ];

    columns.forEach((item: GridColDef) => {
        item.sortable = false;
        item.hideSortIcons = true;
        item.disableColumnMenu = true;
    });

    testColumns.forEach((item: GridColDef) => {
        item.hideSortIcons = false;
        item.sortable = true;
    });

    talentScoreColumn.hideSortIcons = false;
    talentScoreColumn.sortable = true;

    if (includesExperienceCriteria) {
        const experienceScoreColumn = createExperienceScoreColumn();
        experienceScoreColumn.hideSortIcons = false;
        experienceScoreColumn.sortable = true;
        experienceScoreColumn.disableColumnMenu = true;
        columns.splice(4, 0, experienceScoreColumn);
    }

    return columns;
}

export default useCandidateOverviewListColumns;
