import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

// Have to register the languages you want to use
countries.registerLocale(enLocale);

function getFullCountryName(code: string) {
    return countries.getName(code, 'en');
}

export default getFullCountryName;
