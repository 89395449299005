import Page, { EmptyClientOverviewPage } from '../Models/Page';
import { get, httpDelete, post, put, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';
import { ClientOverviewResponse } from '../DTO/Clients/ClientOverviewResponse';
import { ClientResponse, DefaultClientResponse, DefaultDefaultClientResponse } from '../DTO/Clients/ClientResponse';
import { CreateClientRequest } from '../DTO/Clients/CreateClientRequest';
import { CreateDefaultClientRequest } from '../DTO/Clients/CreateDefaultClientRequest';
import { UpdateClientRequest } from '../DTO/Clients/UpdateClientRequest';
import { extractImageContentType } from '../Components/ImageCrop/ImageCropUtils';

export default class ClientsClient extends ClientBase {
    async getClientsOverviewsAsync(
        companyId: string,
        offset: number,
        limit: number,
        search?: string | null,
    ): Promise<Page<ClientOverviewResponse>> {
        const queryString = this.buildAssessmentQuery(offset, limit, search);
        const response = await get<Page<ClientOverviewResponse>>({
            path: this.addHost(`clients/company/${companyId}/clients-overview/?${queryString}`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            const data = response.body.data ? response.body.data : [];
            return {
                pagination: response.body.pagination,
                data,
            };
        } else {
            return EmptyClientOverviewPage;
        }
    }

    buildAssessmentQuery(offset: number, limit: number, search?: string | null): string {
        const query = new URLSearchParams({
            offset: offset.toString(),
            limit: limit.toString(),
        });

        if (search && search?.length > 1) {
            query.append('search', search);
        }

        return query.toString();
    }

    async getClientByIdAsync(clientId: string): Promise<ClientResponse> {
        const response = await get<ClientResponse>({
            path: this.addHost(`clients/${clientId}`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        } else {
            return DefaultClientResponse;
        }
    }

    async createClientAsync(companyId: string, createDto: CreateClientRequest): Promise<ClientResponse> {
        const strategy = toJson(createDto);
        const response = await post<ClientResponse>({
            path: this.addHost(`clients/company/${companyId}`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultClientResponse;
    }

    async createDefaultClientAsync(companyId: string, createDto: CreateDefaultClientRequest): Promise<ClientResponse> {
        const strategy = toJson(createDto);
        const response = await post<ClientResponse>({
            path: this.addHost(`clients/company/${companyId}/default`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultDefaultClientResponse;
    }

    async updateClientAsync(clientId: string, updateDto: UpdateClientRequest): Promise<ClientResponse> {
        const strategy = toJson(updateDto);
        const response = await put<ClientResponse>({
            path: this.addHost(`clients/${clientId}`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultClientResponse;
    }

    updateNonDefaultClientIcon = async (clientId: string, image: Blob): Promise<ClientResponse> => {
        const path = `clients/${clientId}/icon`;
        const type = extractImageContentType(image.type);
        const form = new FormData();

        form.append('Image', image, `icon.${type}`);

        const response = await post<ClientResponse>({
            path: this.addHost(path),
            body: form,
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultClientResponse;
    };

    resetNonDefaultClientIcon = async (clientId: string): Promise<ClientResponse> => {
        const path = `clients/${clientId}/icon`;
        const response = await httpDelete<ClientResponse>({
            path: this.addHost(path),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultClientResponse;
    };
}
