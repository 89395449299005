import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Grid, PercentileLevel } from '@talentmesh/core';
import { Aspect, aspectCompare } from '../../../../../../../../../Models/Aspect';
import EICharacteristicAspect from './EICharacteristicAspect';
import EICharacteristicHeader from './EICharacteristicHeader';

interface EICharacteristicPanelProps {
    characteristicName: string;
    percentileScore: number;
    percentileLevel: PercentileLevel;
    aspects: Aspect[];
    importanceRating: number;
}
function EICharacteristicPanel({
    characteristicName,
    percentileScore,
    percentileLevel,
    aspects,
    importanceRating,
}: EICharacteristicPanelProps): JSX.Element {
    const theme = useTheme();

    return (
        <Grid container sx={{ paddingTop: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            <EICharacteristicHeader
                characteristicName={characteristicName}
                percentileScore={percentileScore}
                percentileLevel={percentileLevel}
                importanceRating={importanceRating}
            />
            {aspects.sort(aspectCompare).map((aspect) => (
                <EICharacteristicAspect key={aspect.descriptor} {...aspect} />
            ))}
        </Grid>
    );
}

export default EICharacteristicPanel;
