import React from 'react';
import { Stack, StackProps } from '@talentmesh/core';
import { Header } from './Common/Header';

interface DialogHeaderProps extends StackProps {
    title: string;
    backgroundColor: string;
    headerWidth?: string;
}
function DialogHeader({ title, backgroundColor, headerWidth, ...props }: DialogHeaderProps): JSX.Element {
    return (
        <Stack {...props} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Header backgroundColor={backgroundColor} headerWidth={headerWidth}>
                {title}
            </Header>
        </Stack>
    );
}

export default DialogHeader;
