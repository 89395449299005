import React from 'react';
import { Stack, IconButton, Typography, TMTooltip } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import { FormikAutocompleteChips, FormikSwitch, getFieldValue } from '@talentmesh/forms';
import { useTheme } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import AutocompleteOptionType from '../../../../../../Models/AutocompleteOptionType';
import { useAutocompleteOptionsClient } from '../../../../../../Hooks/ClientHooks';
import { getAutocompleteSkillsType, getSkillsSwitchName } from '../JobAdStepUtils';
import SkillSelectorTooltipBody from './SkillsSelectorTooltipBody';

interface SkillsSelectorProps {
    type: AutocompleteOptionType;
    label: string;
    placeholder: string;
    tooltip: string;
}

function SkillsSelector({ type, label, placeholder, tooltip }: SkillsSelectorProps): JSX.Element {
    const theme = useTheme();
    const name = getAutocompleteSkillsType(type);
    const formik = useFormikContext();
    const autocompleteOptionsClient = useAutocompleteOptionsClient();
    const switchName = getSkillsSwitchName(type);
    const [, meta] = useField(name);
    const { isSubmitting } = useFormikContext();

    const getAutocompleteOptions = async (search: string) => {
        return search === '' ? [] : await autocompleteOptionsClient.getAutocompleteOptions(search, type);
    };

    return (
        <Stack
            paddingLeft={theme.spacing(1.25)}
            paddingRight={theme.spacing(1.25)}
            paddingTop={theme.spacing(2.5)}
            paddingBottom={theme.spacing(2.5)}
            spacing={theme.spacing(1.125)}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                    <Typography variant="body1" color={theme.palette.grey[700]}>
                        {label}
                    </Typography>
                    <TMTooltip placement="bottom" title={<SkillSelectorTooltipBody message={tooltip} />}>
                        <IconButton>
                            <InfoIcon color="secondary" />
                        </IconButton>
                    </TMTooltip>
                </Stack>
                <FormikSwitch name={switchName} data-cy={switchName} disabled={isSubmitting} />
            </Stack>
            <FormikAutocompleteChips
                name={name}
                placeholder={placeholder}
                getAutocompleteOptions={getAutocompleteOptions}
                disabled={isSubmitting || !getFieldValue(formik, switchName)}
            />
            {meta.error && (
                <Typography variant="caption" color={theme.palette.error.main}>
                    {meta.error}
                </Typography>
            )}
        </Stack>
    );
}

export default SkillsSelector;
