import { JobExperience } from './JobExperience';

export type Experience = {
    title: string;
    jobExperience: JobExperience;
    company: string;
    workingHere: boolean;
    startDate: Date;
    endDate: Date | null;
};

export const DefaultExperience: Experience = {
    company: '',
    endDate: null,
    jobExperience: {},
    startDate: new Date(),
    title: '',
    workingHere: false,
};
