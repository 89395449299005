import React from 'react';
import withTalentMeshUserRequired from '../../../Routing/withTalentMeshUserRequired';
import withSignupChecks from '../../../Routing/withSignupChecks';
import useQuery from '../../../Hooks/UseQuery';
import { AssessmentStatuses } from '../../../Models/AssessmentData';
import UIStrings from '../../../Utils/UIStrings';
import { AssessmentsOverviewContext } from './Contexts/AssessmentsOverviewContext';
import useAssessmentsOverviewContextValue from './Contexts/UseAssessmentsOverviewContextValue';
import AssessmentsOverviewPageContent from './AssessmentsOverviewPageContent';
import withCompanyProfileRequired from '../../../Routing/withCompanyProfileRequired';

const AssessmentsOverviewPage = () => {
    const tab = useQuery('filter');

    let initialStatus = AssessmentStatuses.Public;
    if (tab === UIStrings.Closed.toLowerCase()) {
        initialStatus = AssessmentStatuses.Closed;
    }

    const contextValue = useAssessmentsOverviewContextValue(initialStatus);

    return (
        <AssessmentsOverviewContext.Provider value={contextValue}>
            <AssessmentsOverviewPageContent />
        </AssessmentsOverviewContext.Provider>
    );
};

export default withCompanyProfileRequired(withTalentMeshUserRequired(withSignupChecks(AssessmentsOverviewPage)));
