import React, { ReactNode } from 'react';
import { Grid } from '@talentmesh/core';
import GridRow from './GridRow';

interface GridRowNodeProps {
    title: ReactNode;
    node: ReactNode;
}

function GridRowNode({ title, node }: GridRowNodeProps): JSX.Element {
    return (
        <GridRow container item>
            <Grid item xs={3}>
                {title}
            </Grid>
            <Grid item xs={9}>
                {node}
            </Grid>
        </GridRow>
    );
}
export default GridRowNode;
