import React from 'react';
import { TabPanel } from '@talentmesh/core';
import RoutePath from '../../../../Routing/RoutePath';
import CareerPage from '../CarrerPageTab/CareerPage';
import InviteCandidateTabContents from './InviteCandidateTabContents';
import JobBoardsTabContent from './JobBoardsTabContents';

const InviteCandidateTabButtons = (): JSX.Element => {
    return (
        <>
            <TabPanel value={RoutePath.AttractApplicantsJobBoards} sx={{ padding: 0 }}>
                <JobBoardsTabContent />
            </TabPanel>
            <TabPanel value={RoutePath.AttractApplicantsCareerPage} sx={{ padding: 0 }}>
                <CareerPage />
            </TabPanel>
            <TabPanel value={RoutePath.AttractApplicantsInvite} sx={{ padding: 0 }}>
                <InviteCandidateTabContents />
            </TabPanel>
        </>
    );
};

export default InviteCandidateTabButtons;
