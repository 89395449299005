import React from 'react';
import { ChevronRightIcon } from '@talentmesh/icons';
import ProcessingState from '../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../Utils/UIStrings';
import NextStepButton from '../../../Components/NextStepButton';
import { StepperNavigationButtons } from '../../../Components/StepperNavigationButtons';
import StepProps from '../../StepProps';
import { useCreateRecruitmentContext } from '../../../Create/Contexts/CreateRecruitmentContext';

function RequestInformationStepNavigation({ activeStep, handleBack }: StepProps): JSX.Element {
    const { processingState } = useCreateRecruitmentContext();

    return (
        <StepperNavigationButtons
            activeStep={activeStep}
            handleBack={handleBack}
            nextButtonComponent={
                <NextStepButton loading={processingState === ProcessingState.Processing} endIcon={<ChevronRightIcon />}>
                    {UIStrings.Next}
                </NextStepButton>
            }
            processingState={processingState}
        />
    );
}

export default RequestInformationStepNavigation;
