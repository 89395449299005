import React from 'react';

function CheckedBadgeIcon(): JSX.Element {
    return (
        <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2325_28837)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.3415 7.40843C27.8235 6.6454 25.9929 4.45001 25.6941 1.83596C25.6405 1.38182 25.2951 1.01671 24.8447 0.937725C21.998 0.444432 18.5878 0 15.5367 0C12.4856 0 9.07471 0.444432 6.22841 0.93806C5.77762 1.01737 5.43258 1.38283 5.3787 1.83696C5.08085 4.45068 3.25058 6.64573 0.733246 7.40943C0.298854 7.54296 0.00234264 7.94389 0.00200798 8.39803C0 9.61553 0 10.9408 0 12.3025C0 21.2056 4.62136 29.4707 12.2062 34.1326C13.4203 34.8789 14.4169 35.4913 14.9939 35.8461C15.1602 35.9485 15.3486 35.9997 15.5367 35.9997C15.7248 35.9997 15.9129 35.9485 16.0795 35.8461C16.6568 35.4913 17.6531 34.8789 18.8673 34.1326C26.4521 29.4704 31.0734 21.2056 31.0734 12.3025V8.39869C31.0734 7.94355 30.7766 7.54196 30.3415 7.40809V7.40843Z"
                    fill="#3764E7"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.017 9.08625C25.1469 8.4504 23.7852 6.77106 23.5148 4.76743C23.4532 4.32266 23.1118 3.96792 22.6697 3.88928C20.472 3.50475 17.8703 3.16406 15.5367 3.16406C13.2031 3.16406 10.6011 3.50475 8.4034 3.89028C7.96132 3.96893 7.62029 4.32367 7.55872 4.76844C7.28864 6.77206 5.92724 8.45106 4.05748 9.08692C3.63948 9.23083 3.3587 9.62372 3.35836 10.0658C3.35736 11.0065 3.35736 12.0229 3.35736 13.0661C3.35736 20.2319 6.98008 26.884 12.926 30.6362C13.7935 31.1837 14.5194 31.6419 14.9839 31.9347C15.1529 32.0414 15.345 32.0947 15.5371 32.0947C15.7292 32.0947 15.9213 32.0414 16.0903 31.9347C16.5548 31.6415 17.2803 31.1837 18.1481 30.6362C24.0941 26.884 27.7168 20.2319 27.7168 13.0661V10.0661C27.7168 9.62372 27.436 9.23016 27.0177 9.08625H27.017Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.4306 10.0533C23.8525 9.50378 22.702 8.09581 22.4574 6.41446C22.397 6.00671 22.0804 5.68329 21.6717 5.61132C19.7879 5.28604 17.5673 5 15.5736 5C13.5799 5 11.359 5.28604 9.47513 5.61224C9.06643 5.68422 8.75018 6.00764 8.68979 6.41508C8.44513 8.09612 7.29498 9.50378 5.71683 10.0533C5.33209 10.1883 5.07436 10.5491 5.07405 10.9544C5.07343 11.7971 5.07343 12.7553 5.07343 13.6378C5.07343 19.7686 8.19642 25.5549 13.3225 28.765C14.0474 29.2191 14.6575 29.6012 15.0587 29.8523C15.2159 29.9509 15.3946 30 15.5733 30C15.7519 30 15.9306 29.9509 16.0878 29.8523C16.489 29.6009 17.0994 29.2188 17.8244 28.765C22.9504 25.5546 26.0734 19.7683 26.0734 13.6378V10.955C26.0734 10.5497 25.816 10.1883 25.4306 10.053V10.0533Z"
                    fill="#E7E9FC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9253 18.9559C13.9253 18.9559 11.6931 16.7237 10.3417 15.3723C10.0743 15.1052 9.71222 14.955 9.33405 14.955C8.95588 14.955 8.59378 15.1052 8.32638 15.3723C8.05899 15.6394 7.90906 16.0018 7.90906 16.38C7.90906 16.7581 8.05899 17.1202 8.32638 17.3876L12.6516 21.7128C12.9892 22.0505 13.4474 22.2402 13.925 22.2402C14.4025 22.2402 14.8607 22.0505 15.1987 21.7128L22.7467 14.1648C23.3032 13.6083 23.3032 12.706 22.7467 12.1498C22.1901 11.5933 21.2879 11.5933 20.7317 12.1498C18.6203 14.2612 13.9253 18.9562 13.9253 18.9562V18.9559Z"
                    fill="#3764E7"
                />
            </g>
            <defs>
                <clipPath id="clip0_2325_28837">
                    <rect width="31.0734" height="36" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CheckedBadgeIcon;
