import React from 'react';
import { Button, Link, Stack, theme, Typography } from '@talentmesh/core';
import { siteUrls } from '../AppSettings';
import RoutePath from '../Routing/RoutePath';
import UIStrings from '../Utils/UIStrings';
import useTalentMeshHistory from '../Routing/useTalentMeshHistory';

const NotFoundPageInfo = () => {
    const history = useTalentMeshHistory();

    const onClick = () => {
        history.push(RoutePath.Root);
    };

    return (
        <>
            <Typography variant="h2" sx={{ color: theme.palette.secondary.main }}>
                {UIStrings.Found404}
            </Typography>
            <Stack
                sx={{
                    borderRadius: theme.shape.smallBorderRadius,
                    backgroundColor: theme.palette.status.main,
                    marginTop: theme.spacing(2),
                    padding: theme.spacing(1),
                }}
            >
                <Typography variant="body1">
                    {`${UIStrings.PageNotFoundTextBeforeLink} `}
                    <Link variant="body1" href={siteUrls.contactSupportUrl}>
                        {UIStrings.ContactUsCamel}
                    </Link>
                    {` ${UIStrings.PageNotFoundTextAfterLink}`}
                </Typography>
                <Button
                    sx={{ width: theme.spacing(23), marginTop: theme.spacing(3) }}
                    variant="contained"
                    onClick={onClick}
                >
                    {UIStrings.GoToTalentMesh}
                </Button>
            </Stack>
        </>
    );
};

export default NotFoundPageInfo;
