import { AutocompleteOption } from '@talentmesh/core';
import { RTEInitValue } from '@talentmesh/rte';
import { DefaultWriteJobAdDTO, WriteJobAdDTO } from '../../../../DTO/Assessments/WriteJobAdDTO';
import { JobAdSkillsDTO } from '../../../../DTO/Assessments/JobAdSkillsDTO';
import AutocompleteOptionType from '../../../../Models/AutocompleteOptionType';
import autoMapping from '../../../../Utils/autoMapping';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';

export interface JobAd {
    title: string;
    description: string;
    descriptionLen: number;
    hardSkills: AutocompleteOption[];
    hardSkillsSwitch: boolean;
    softSkills: AutocompleteOption[];
    softSkillsSwitch: boolean;
    companyDescription: string;
    status: AssessmentStatuses;
}

export const DefaultJobAd: JobAd = {
    title: '',
    description: RTEInitValue, // we use this initial value for correct RTE behavior
    descriptionLen: 0,
    hardSkills: [],
    hardSkillsSwitch: false,
    softSkills: [],
    softSkillsSwitch: false,
    companyDescription: RTEInitValue,
    status: AssessmentStatuses.NonPublic,
};

const isNullOrUndefined = (value?: string | null) => {
    return value === undefined || value === null;
};

export function mapJobAdModel2Dto(model: JobAd): WriteJobAdDTO {
    const dto = autoMapping(model, DefaultWriteJobAdDTO);
    const { hardSkills, hardSkillsSwitch, softSkills, softSkillsSwitch } = model;

    dto.skills = hardSkillsSwitch
        ? hardSkills.map(
              (skill) =>
                  ({
                      id: skill.id,
                      skillName: skill.label,
                      skillType: AutocompleteOptionType.HardSkill,
                      isUserDefined: isNullOrUndefined(skill.id),
                  }) as JobAdSkillsDTO,
          )
        : [];

    dto.skills = dto.skills.concat(
        softSkillsSwitch
            ? softSkills.map(
                  (skill) =>
                      ({
                          id: skill.id,
                          skillName: skill.label,
                          skillType: AutocompleteOptionType.SoftSkill,
                          isUserDefined: isNullOrUndefined(skill.id),
                      }) as JobAdSkillsDTO,
              )
            : [],
    );

    return dto;
}
