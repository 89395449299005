import { useTheme, Popover, PopoverProps } from '@mui/material';
import React from 'react';
import { MenuViews } from '../../../Models/ProfileMenu';

interface ProfilePopoverProps extends PopoverProps {
    currentView: MenuViews;
}

const ProfilePopover = React.forwardRef<HTMLDivElement, ProfilePopoverProps>(
    ({ currentView, ...popoverProps }: ProfilePopoverProps, ref) => {
        const theme = useTheme();

        return (
            <div ref={ref}>
                <Popover
                    onMouseDown={(e) => e.stopPropagation()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                borderRadius: '1px',
                                width: theme.spacing(25),
                                minHeight:
                                    currentView === MenuViews.Companies ? theme.spacing(10) : theme.spacing(23.18),
                                maxHeight: theme.spacing(45),
                                height: 'auto',
                            },
                        },
                    }}
                    {...popoverProps}
                />
            </div>
        );
    },
);

export default ProfilePopover;
