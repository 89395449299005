import { useTheme } from '@mui/material';
import { Button, TextField, Stack } from '@talentmesh/core';
import { LinkIcon } from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';

interface CopyLinkControlProps {
    link: string;
    onClickHandler: () => void;
    width: string;
}

function CopyLinkControl({ link, onClickHandler, width }: CopyLinkControlProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={theme.spacing(2)} sx={{ paddingY: theme.spacing(1.25) }}>
            <TextField
                disabled
                value={link}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: theme.palette.common.white,
                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                            border: `1px dashed ${theme.palette.secondary.main}`,
                        },
                    },
                    width,
                }}
                data-cy="recruitmentPublicLink"
            />
            <Button variant="contained" color="secondary" onClick={onClickHandler} endIcon={<LinkIcon />}>
                {UIStrings.CopyLink}
            </Button>
        </Stack>
    );
}

export default CopyLinkControl;
