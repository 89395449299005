import React, { ReactNode } from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/system';

interface CheckoutSectionTitleProps {
    prefix?: ReactNode;
    title: string;
}

const CheckoutSectionTitle = ({ prefix, title }: CheckoutSectionTitleProps) => {
    const theme = useTheme();
    return (
        <Stack sx={{ mb: theme.spacing(2.5) }} direction="row" alignItems="center" spacing={2}>
            {prefix && prefix}
            <Typography variant="h3" color={theme.palette.primary.main}>
                {title}
            </Typography>
        </Stack>
    );
};

export default CheckoutSectionTitle;
