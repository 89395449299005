import React from 'react';
import { FormikDropdown } from '@talentmesh/forms';
import UIStrings from '../../../../../../Utils/UIStrings';
import directReportsOptions from './DirectReportsOptions';
import { JobDetailsFieldTypes } from '../JobDetailsStepUtils';

function DirectReports(): JSX.Element {
    return (
        <FormikDropdown
            label={`${UIStrings.DirectReports}*`}
            name={JobDetailsFieldTypes.DirectReports}
            data-cy={JobDetailsFieldTypes.DirectReports}
            options={directReportsOptions}
        />
    );
}

export default DirectReports;
