import React, { ReactNode } from 'react';
import { Stack, StackProps } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

interface DialogCardContainerProps extends Pick<StackProps, 'sx'> {
    icon?: React.ReactNode;
    dialogTitle: ReactNode;
    dialogBody: ReactNode;
}

function DialogCardContainer({ dialogTitle, dialogBody, icon, sx }: DialogCardContainerProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1.25}
            sx={{
                paddingInline: theme.spacing(1.25),
                paddingTop: theme.spacing(1.25),
                backgroundColor: theme.palette.background.lightBlue,
                borderRadius: theme.shape.smallBorderRadius,
                ...sx,
            }}
            alignItems="center"
        >
            <Stack sx={{ height: '100%', justifyContent: 'end' }}>{icon}</Stack>
            <Stack direction="column" justifyContent="center" spacing={1.25}>
                {dialogTitle}
                {dialogBody}
            </Stack>
        </Stack>
    );
}

export default DialogCardContainer;
