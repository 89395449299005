import { TestTypes } from '../../../../../../../Models/Configuration';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { AssessmentMeanBenchmark, MeanTopicScore } from '../../../../../../../Models/AssessmentMeanBenchmark';

function getAssessmentAverageValue(type: TestTypes, benchmark: AssessmentMeanBenchmark): number | null {
    switch (type) {
        case TestTypes.Aptitude:
            return benchmark.meanAptitudePercentileScore;
        case TestTypes.Skills:
            return benchmark.meanSkillsPercentileScore;
        default:
            throw new Error(UIStrings.TestNotSupportedError(type));
    }
}

function getAssessmentInternalAverageValue(type: TestTypes, benchmark: AssessmentMeanBenchmark): number | null {
    switch (type) {
        case TestTypes.Aptitude:
            return benchmark.internalMeanAptitudePercentileScore;
        case TestTypes.Skills:
            return benchmark.internalMeanSkillsPercentileScore;
        default:
            throw new Error(UIStrings.TestNotSupportedError(type));
    }
}

function getMeanTopicScores(type: TestTypes, benchmark: AssessmentMeanBenchmark): Array<MeanTopicScore> {
    switch (type) {
        case TestTypes.Aptitude:
            return benchmark.meanAptitudeTopicScores;
        case TestTypes.Skills:
            return benchmark.meanSkillsTopicScores;
        default:
            throw new Error(UIStrings.TestNotSupportedError(type));
    }
}

export { getAssessmentAverageValue, getAssessmentInternalAverageValue, getMeanTopicScores };
