import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { Check } from '@talentmesh/icons';

interface BaseTestCardTagItemProps {
    label: string;
}

function BaseTestCardTagItem({ label }: BaseTestCardTagItemProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={0.625} alignItems="center">
            <Check
                sx={{
                    color: theme.palette.success.dark,
                    fontSize: theme.spacing(2),
                }}
            />
            <Typography variant="caption" color={theme.palette.text.menuTitle}>
                {label}
            </Typography>
        </Stack>
    );
}

export default BaseTestCardTagItem;
