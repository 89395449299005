import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';

export default function useRouteMatch(paths: string[]) {
    const location = useLocation();

    return useMemo(
        () => paths.find((pattern) => matchPath(pattern, location.pathname)?.pattern.path),
        [location, paths],
    );
}
