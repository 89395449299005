import React from 'react';
import { useTheme } from '@mui/material';
import { Dialog, DialogTitle, Typography, DialogContent, Stack, Button } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';
import useChangesMaybeLostDialog from './useChangesMaybeLostDialog';

function ChangesMaybeLostDialog(): JSX.Element {
    const { show, leaveHandler, stayHandler } = useChangesMaybeLostDialog();
    const theme = useTheme();

    return (
        <Dialog
            open={show}
            PaperProps={{
                sx: {
                    width: theme.spacing(48),
                },
            }}
        >
            <DialogTitle variant="h4" color={theme.palette.text.primary}>
                {UIStrings.AreYouSure}
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={theme.spacing(2)}>
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        {UIStrings.AreYouSureYouWantToLeaveWithoutSendingInvites}
                    </Typography>
                    <Stack direction="row" spacing={theme.spacing(2)} width="100%">
                        <Button variant="outlined" sx={{ flex: 1 }} color="secondary" onClick={leaveHandler}>
                            {UIStrings.LeaveThePage}
                        </Button>
                        <Button variant="contained" sx={{ flex: 1 }} onClick={stayHandler}>
                            {UIStrings.Stay}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default ChangesMaybeLostDialog;
