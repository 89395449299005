/* eslint-disable @typescript-eslint/lines-between-class-members */
import AIJobAdDTO from '../../../../../../DTO/JobAds/AIJobAdDTO';
import DescriptionDTO from '../../../../../../DTO/JobAds/DescriptionDTO';
import { DefaultJobAd, JobAd } from '../../../Models/JobAd';

export default class LexicalHtmlBuilder {
    dto: AIJobAdDTO;
    html: Array<string>;
    existingCompanyDescription: string;

    constructor(dto: AIJobAdDTO, existingCompanyDescription: string) {
        this.dto = dto;
        this.html = [];
        this.existingCompanyDescription = existingCompanyDescription;
    }

    newLine() {
        this.html.push('<p><br /></p>');
    }

    span(input: string) {
        this.html.push(`<span>${input}</span>`);
    }

    header(input: string) {
        this.html.push('<h4>');
        this.span(input);
        this.html.push('</h4>');
    }

    paragraph(input: string) {
        this.html.push('<p>');
        this.span(input);
        this.html.push('</p>');
    }

    listItem(input: string, value: number) {
        this.html.push(`<li value="${value}">`);
        this.span(input);
        this.html.push('</li>');
    }

    list(items: Array<string>) {
        this.html.push('<ol>');
        for (let i = 0; i < items.length; i++) {
            this.listItem(items[i], i + 1);
        }
        this.html.push('</ol>');
    }

    processDescription(description: DescriptionDTO): void {
        const { title, body, items } = description;

        this.header(title);

        if (body) {
            this.newLine();
            this.paragraph(body);
        }

        if (items) {
            this.newLine();
            this.list(items);
        }

        this.newLine();
    }

    serializeJobDescription(): string {
        const { aboutTheRole, conclusion, roleResponsibilitiesDescription, compensationAndBenefits } = this.dto;
        this.processDescription(aboutTheRole);
        this.processDescription(roleResponsibilitiesDescription);
        this.processDescription(compensationAndBenefits);
        this.header(conclusion);
        const result = this.html.join('');
        return result;
    }

    serializeCompanyDescription(): string {
        const { companyDescription } = this.dto;
        if (companyDescription) {
            this.processDescription(companyDescription);
            const result = this.html.join('');
            return result;
        }
        return this.existingCompanyDescription;
    }

    map(): JobAd {
        const html = this.serializeJobDescription();
        const length = new DOMParser().parseFromString(html, 'text/html').documentElement.textContent?.length || 0;
        this.html = [];
        const companyDescription = this.serializeCompanyDescription();
        return {
            ...DefaultJobAd,
            description: html,
            title: this.dto.title,
            descriptionLen: length,
            companyDescription,
        };
    }
}
