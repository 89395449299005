interface DescriptionDTO {
    title: string;
    body?: string;
    items?: string[];
}

export const DefaultDescriptionDTO: DescriptionDTO = {
    title: '',
};

export default DescriptionDTO;
