import React from 'react';
import { Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material';

export interface JobAdLabelProps {
    label: string;
}

function JobAdLabel({ label }: JobAdLabelProps): JSX.Element {
    const theme = useTheme();
    return (
        <Typography color={theme.palette.text.menuTitle} fontWeight={theme.typography.fontWeightBold} variant="h4">
            {label}
        </Typography>
    );
}

export default JobAdLabel;
