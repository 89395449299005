import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';

const PostJobStepHeader = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={theme.spacing(1)} py={2.5}>
            <Typography variant="h2" color={theme.palette.text.menuTitle}>
                {UIStrings.HowDoYouWantToPost}
            </Typography>
        </Stack>
    );
};
export default PostJobStepHeader;
