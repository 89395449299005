import { useTheme } from '@mui/material';
import { Dialog, Typography, Stack, Button, DialogTitle, DialogContent, DialogActions } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';

interface FailedToGenerateJobAdDialogProps {
    dialogTitle: string;
    message1: string;
    message2: string;
    open: boolean;
    close: () => void;
}

function FailedToGenerateJobAdDialog({
    dialogTitle,
    message1,
    message2,
    open,
    close,
}: FailedToGenerateJobAdDialogProps): JSX.Element {
    const theme = useTheme();

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Typography variant="h4">{dialogTitle}</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={theme.spacing(2)}>
                    <Typography variant="body1">{message1}</Typography>
                    <Typography variant="body1">{message2}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Button onClick={close} variant="contained">
                    {UIStrings.IUnderstand}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FailedToGenerateJobAdDialog;
