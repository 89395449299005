import { createContext, useContext } from 'react';
import { ToasterStatus } from '@talentmesh/core';
import { CandidateStatusDTO } from '../../../../DTO/Candidates/CandidateStatusDTO';
import { CandidateDialogModel } from '../../Models/CandidateDialogModel';
import { TestTypes } from '../../../../Models/Configuration';

export enum DialogEnum {
    Interview,
    InterviewConfirm,
    Reject,
    Hire,
    CongratsOnHire,
    CloseAssessment,
}

export interface ActionDialogContextModel {
    selectedCandidates: CandidateDialogModel[];
    candidateStatuses: CandidateStatusDTO[];

    removeCandidate: (candidate: CandidateDialogModel) => void;

    isDialogOpen: (status: DialogEnum) => boolean;
    showDialog: (
        c: CandidateDialogModel[],
        status: DialogEnum,
        testTypes?: Array<TestTypes>,
        includeExperienceCriteria?: boolean,
    ) => void;
    hideDialog: (status: DialogEnum) => void;

    handleUndoActionAsync: (assessmentId: string) => Promise<void>;

    interviewCandidateAsync: () => Promise<void>;
    rejectCandidatesAsync: (sendEmail: boolean) => Promise<void>;
    hireCandidateAsync: () => Promise<void>;
    closeAssessmentAsync: (assessmentId: string, rejectCandidates: boolean, sendEmail: boolean) => Promise<void>;

    alertMessage: string;
    alertTitle: string;
    alertStatus: ToasterStatus;
    openUndoToaster: boolean;
    setOpenUndoToaster: (value: boolean) => void;

    showUndoHireMessage: () => void;
    showRejectToaster: (hideUndo: boolean) => void;
    showCloseToaster: (assessmentName: string, withCandidatesRejection: boolean) => void;
    showUndoInviteForInterviewMessage: () => void;

    closeUndoMessage: () => void;

    resendInvitationAsync: (
        assessmentId: string,
        candidateId: string,
        candidateFirstName: string,
        candidateLastName: string,
    ) => Promise<void>;

    availableTestTypes: Array<TestTypes>;
    includeExperienceCriteria: boolean;

    isInterviewLoading: boolean;
    isRejectLoading: boolean;
    isHireLoading: boolean;
}

export const ActionDialogContext = createContext<ActionDialogContextModel | undefined>(undefined);

export function useActionDialogContext() {
    const context = useContext(ActionDialogContext);
    if (!context) {
        throw new Error('useActionDialogContext must be used within the ActionDialogContext.Provider');
    }
    return context;
}
