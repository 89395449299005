import { useParams } from 'react-router';
import useQuery from './UseQuery';

function useCompanyId() {
    const { companyId } = useParams<{ companyId: string }>();
    const companyIdQuery = useQuery('company_id');

    return (companyId || companyIdQuery) as string;
}

export default useCompanyId;
