import { useTheme } from '@mui/material';
import React from 'react';
import { DropdownOption, Stack, Typography, Box } from '@talentmesh/core';
import { FormikDropdown } from '@talentmesh/forms';
import UIStrings from '../../../../../../../../../Utils/UIStrings';
import { useCreateRecruitmentContext } from '../../../../../../Create/Contexts/CreateRecruitmentContext';
import mapLocaleCodeToLanguageName from '../../../../../../../../../Utils/mapLocaleCodeToLanguageName';

export const testLanguageField = 'testLanguageField';

function TestLanguage(): JSX.Element {
    const theme = useTheme();
    const { locales } = useCreateRecruitmentContext();

    const anyLocale: DropdownOption = { id: 'applicant', name: UIStrings.ApplicantsSelectLanguage };

    const options: DropdownOption[] = [
        anyLocale,
        ...locales.map((locale) => {
            return {
                id: locale,
                name: mapLocaleCodeToLanguageName(locale),
            };
        }),
    ];

    return (
        <Stack
            spacing={1.25}
            sx={{
                backgroundColor: theme.palette.background.aliciaBlue,
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.smallBorderRadius,
                padding: theme.spacing(2),
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                {UIStrings.TestLanguage}
            </Typography>
            <Box minWidth={theme.spacing(31.25)}>
                <FormikDropdown name={testLanguageField} data-cy={testLanguageField} options={options} fullWidth />
            </Box>
        </Stack>
    );
}

export default TestLanguage;
