import React from 'react';
import { SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';

const PublicRecruitmentCreatedNotificationBody = () => {
    return (
        <>
            <SpanTypography variant="body1" fontWeight="bold">
                {UIStrings.PublicRecruitmentCreatedText1}
            </SpanTypography>
            <SpanTypography variant="body1">{UIStrings.PublicRecruitmentCreatedText2}</SpanTypography>
        </>
    );
};

export default PublicRecruitmentCreatedNotificationBody;
