import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

function CongratsOnHireDialogBody(): JSX.Element {
    const theme = useTheme();
    return (
        <Box>
            <Typography variant="body2" color={theme.palette.text.menuTitle}>
                {UIStrings.YouAreRequiredToMakePayment}
            </Typography>
            <br />
            <Typography variant="body2" color={theme.palette.text.menuTitle}>
                {UIStrings.PaymentIs10Percent}
            </Typography>
            <br />
            <Typography variant="body2" color={theme.palette.text.menuTitle} gutterBottom>
                {UIStrings.InTheEventTheApplicantLeaves}
            </Typography>
        </Box>
    );
}

export default CongratsOnHireDialogBody;
