import { RTEInitValue } from '@talentmesh/rte';
import { CompanyProfileDTO } from '../DTO/Users/CompanyProfileDTO';

export interface CompanyProfile {
    id: string;
    companyDescription: string;
    companyName: string;
    companySlug: string;
    companyLogoURL: string;
    companyIconURL: string;
}

export const DefaultCompanyProfile: CompanyProfile = {
    id: '',
    companyDescription: RTEInitValue,
    companyName: '',
    companySlug: '',
    companyLogoURL: '',
    companyIconURL: '',
};

export function companyProfileFromDTO(dto: CompanyProfileDTO): CompanyProfile {
    return {
        ...dto,
        companyLogoURL: dto.companyLogoURL ?? '',
        companyIconURL: dto.companyIconURL ?? '',
        companyDescription: dto.companyDescription ?? RTEInitValue,
    };
}
