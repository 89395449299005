import React from 'react';
import { AccordionDetails } from '@talentmesh/core';
import getApplicantInformationLabel from '../../../../../../Utils/getLabelsFunctions';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import FormikChoiceSelector from './FormikChoiceSelector';
import UIStrings from '../../../../../../Utils/UIStrings';
import AdditionalInformationAccordionSummary from './Accordion/AdditionalInformationAccordionSummary';
import { AdditionalInformationAccordion } from './Accordion/AdditionalInformationAccordion';

const motivationalInformationToggles: ApplicantInformationType[] = [
    ApplicantInformationType.ReasonForApplying,
    ApplicantInformationType.ReasonForLeaving,
];

const MotivationalInformatioAccordion = (): JSX.Element => {
    return (
        <AdditionalInformationAccordion>
            <AdditionalInformationAccordionSummary title={UIStrings.MotivationalInformation} />
            <AccordionDetails>
                {motivationalInformationToggles.map((value) => (
                    <FormikChoiceSelector
                        key={ApplicantInformationType[value]}
                        label={getApplicantInformationLabel(value)}
                        name={value}
                    />
                ))}
            </AccordionDetails>
        </AdditionalInformationAccordion>
    );
};

export default MotivationalInformatioAccordion;
