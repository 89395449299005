export interface CandidateTestMonitoring {
    assessmentId: string;
    isWebcamEnabled: boolean;
    isScreenInvigilationEnabled: boolean;
    isFullScreenEnabled: boolean;
}

export const DefaultCTMonitoring: CandidateTestMonitoring = {
    assessmentId: '',
    isWebcamEnabled: false,
    isFullScreenEnabled: false,
    isScreenInvigilationEnabled: false,
};
