import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { assessmentsApiUrl } from '../AppSettings';

export type GetAccessTokenSilently = (options?: GetTokenSilentlyOptions) => Promise<string>;

export default class ClientBase {
    constructor(protected getAccessTokenSilently: GetAccessTokenSilently) {}

    addHost(path: string) {
        return `${assessmentsApiUrl}${path}`;
    }
}
