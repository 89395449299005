import React from 'react';
import { FormikHelpers } from 'formik';
import { Stack } from '@talentmesh/core';
import TestCards from './Components/TestCards/TestCards';
import ExpertsBadge from './Components/ExpertsBadge/ExpertsBadge';
import TestLanguage from './Components/TestLanguage/TestLanguage';

interface TestsPanelProps {
    helpers: FormikHelpers<any>;
}

function TestsPanel({ helpers }: TestsPanelProps): JSX.Element {
    return (
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
            <Stack spacing={2}>
                <TestLanguage />
                <TestCards helpers={helpers} />
            </Stack>
            <ExpertsBadge />
        </Stack>
    );
}

export default TestsPanel;
