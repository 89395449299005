import React from 'react';
import { useTheme } from '@mui/material';
import { Divider, Grid, Stack, Typography } from '@talentmesh/core';
import { ContactMailOutlinedIcon, WorkIcon } from '@talentmesh/icons';
import UIStrings from '../../../../../Utils/UIStrings';
import InstructionItem from './InstructionItem';

function Instructions(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            direction="column"
            spacing={theme.spacing(2)}
            sx={{
                borderRadius: theme.shape.smallBorderRadius,
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Typography variant="h4" color={theme.palette.text.menuTitle}>
                {`${UIStrings.InsertIn}:`}
            </Typography>
            <Grid container>
                <Grid item xs>
                    <InstructionItem
                        icon={<WorkIcon color="secondary" />}
                        header={UIStrings.JobAds}
                        instruction={UIStrings.JobAdsInsertInstructions}
                    />
                </Grid>
                <Divider
                    sx={{ marginRight: theme.spacing(3.75), marginLeft: theme.spacing(3.75) }}
                    orientation="vertical"
                    color={theme.palette.charts.chart12}
                    flexItem
                />
                <Grid item xs>
                    <InstructionItem
                        icon={<ContactMailOutlinedIcon color="secondary" />}
                        header={UIStrings.CustomInvitationEmail}
                        instruction={UIStrings.CustomInvitationEmailInstructions}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
}

export default Instructions;
