import React from 'react';
import withSignupChecks from '../../../../Routing/withSignupChecks';
import withTalentMeshUserRequired from '../../../../Routing/withTalentMeshUserRequired';
import { CreateRecruitmentProvider } from './Contexts/CreateRecruitmentContext';
import CreateRecruitmentPageContent from './CreateRecruitmentPageContent';
import withGooglePlacesAPICheck from '../../../../Routing/withGooglePlacesAPICheck';
import withCompanyProfileRequired from '../../../../Routing/withCompanyProfileRequired';

const CreateRecruitmentPage = () => {
    return (
        <CreateRecruitmentProvider>
            <CreateRecruitmentPageContent />
        </CreateRecruitmentProvider>
    );
};

export default withCompanyProfileRequired(
    withTalentMeshUserRequired(withSignupChecks(withGooglePlacesAPICheck(CreateRecruitmentPage))),
);
