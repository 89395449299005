import React from 'react';
import { FormControlLabel, Radio } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import { AssessmentStatus } from '../../../../../../Models/Configuration';
import PostingOptionLabel from './PostingOptionLabel';

interface PostingOptionProps {
    currentValue: AssessmentStatus | '';
    value: AssessmentStatus;
    label: string;
    description?: React.ReactNode;
    dataCy: string;
}

const PostingOption = ({ currentValue, value, label, description, dataCy }: PostingOptionProps): JSX.Element => {
    const theme = useTheme();
    const isSelected = value === currentValue;

    return (
        <FormControlLabel
            sx={{
                backgroundColor: isSelected ? theme.palette.hue.primary.h50 : 'transparent',
                width: '100%',
                maxWidth: theme.spacing(55),
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.smallBorderRadius,
                py: theme.spacing(3.75),
                px: theme.spacing(1.25),
            }}
            value={value}
            control={<Radio data-cy={dataCy} />}
            label={<PostingOptionLabel label={label} description={description} />}
            labelPlacement="bottom"
        />
    );
};

export default PostingOption;
