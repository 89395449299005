import React, { useEffect, useState } from 'react';
import UIStrings from '../../Utils/UIStrings';
import ErrorDescription from './Components/DefaultErrorDescription';
import ErrorDialog from './Components/ErrorDialog';
import OidcErrorBody from './Components/OidcErrorBody';
import ErrorQueryNames from './ErrorQueryNames';

function IdentityErrorPage(): JSX.Element {
    const [errorDescription, setErrorDescription] = useState<string>('');

    useEffect(() => {
        const error = new URLSearchParams(window.location.search).get(ErrorQueryNames.ErrorDescription);
        if (error) {
            setErrorDescription(error);
        }
    }, []);

    return errorDescription === UIStrings.Auth0OidcErrorMessage ? (
        <ErrorDialog errorTitle={UIStrings.OopsSomethingWentWrong} errorBody={<OidcErrorBody />} />
    ) : (
        <ErrorDialog
            errorTitle={UIStrings.AuthenticationError}
            errorBody={<ErrorDescription errorDescription={errorDescription} />}
        />
    );
}

export default IdentityErrorPage;
