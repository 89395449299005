import LocationPrediction from '../../../../../../../../Models/LocationPrediction';

function convertGoogleAutocompletePredictionsToLocationPredictions(
    results?: google.maps.places.AutocompletePrediction[],
) {
    if (results === undefined) return [] as LocationPrediction[];

    return results.map((result: google.maps.places.AutocompletePrediction) => {
        return {
            locationId: result.place_id,
            description: result.description,
            mainText: result.structured_formatting.main_text,
            secondaryText: result.structured_formatting.secondary_text,
            mainTextMatchedSubstrings: result.structured_formatting.main_text_matched_substrings,
        } as LocationPrediction;
    });
}

export default convertGoogleAutocompletePredictionsToLocationPredictions;
