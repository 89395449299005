import React, { useEffect, useState } from 'react';
import { Checkbox, IconButton, Stack } from '@talentmesh/core';
import { DeleteOutlineIcon } from '@talentmesh/icons';
import { CandidateRowEntry } from '../../../../../Models/CandidateRowEntry';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';

const CandidatesToolbar = (): JSX.Element => {
    const { dirtyCount, candidates, selectedCandidateIds, deleteCandidatesAsync, selectCandidate } =
        useInviteCandidateContext();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (selectedCandidateIds.length === 0) {
            setChecked(false);
        }

        if (selectedCandidateIds.length > 0 && selectedCandidateIds.length === candidates.length) {
            setChecked(true);
        }
    }, [selectedCandidateIds]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        setChecked(value);

        candidates.forEach((candidate: CandidateRowEntry) => {
            if (!candidate.isReadOnly) {
                selectCandidate(candidate, value);
            }
        });
    };

    return (
        <Stack direction="row" spacing={0.5}>
            <Checkbox
                disabled={dirtyCount === 0}
                checked={checked}
                indeterminate={selectedCandidateIds.length > 0 && selectedCandidateIds.length !== candidates.length}
                onChange={handleChange}
            />
            <IconButton
                disabled={selectedCandidateIds.length === 0}
                edge="end"
                onClick={() => deleteCandidatesAsync()}
                size="large"
                data-cy="deleteBatchButton"
            >
                <DeleteOutlineIcon />
            </IconButton>
        </Stack>
    );
};

export default CandidatesToolbar;
