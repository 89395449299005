import React from 'react';
import BaseFilter, { BaseFilterProps } from './BaseFilter';
import FilterPanel, { FilterPanelProps } from './FilterPanel';

export interface AdvancedFilterButtonProps
    extends Omit<BaseFilterProps, 'children' | 'activeFilterCount'>,
        FilterPanelProps {}

function AdvancedFilterButton({ label, menuItems, resetCallback }: AdvancedFilterButtonProps): JSX.Element {
    const activeFilterCount = menuItems.filter((menuItem) => menuItem.selected === true).length;

    return (
        <BaseFilter label={label} activeFilterCount={activeFilterCount}>
            <FilterPanel menuItems={menuItems} resetCallback={resetCallback} />
        </BaseFilter>
    );
}

export default AdvancedFilterButton;
