import { Pagination } from '@talentmesh/core';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ClientFilters, ClientFormValues } from '../components/Client';
import useClientOverview from './useClientOverview';
import { ClientOverviewResponse } from '../../../DTO/Clients/ClientOverviewResponse';
import ProcessingState from '../../../Models/ProcessingState';
import useCompanyId from '../../../Hooks/UseCompanyId';
import { useClientsClient } from '../../../Hooks/ClientHooks';
import { CreateClientRequest } from '../../../DTO/Clients/CreateClientRequest';
import { UpdateClientRequest } from '../../../DTO/Clients/UpdateClientRequest';
import { ClientResponse } from '../../../DTO/Clients/ClientResponse';
import { resetEmptyStringToNull } from '../../../Utils/Helpers';
import { useNotificationContext } from '../../../Context/NotificationContext';
import UIStrings from '../../../Utils/UIStrings';

interface ClientsOverviewContextType {
    clients: ClientOverviewResponse[];
    getOverviewProcessingState: ProcessingState;
    pagination: Pagination;
    setPagination: React.Dispatch<React.SetStateAction<Pagination>>;

    clientFilters: ClientFilters;
    setClientFilters: React.Dispatch<React.SetStateAction<ClientFilters>>;

    createClient: (newClient: ClientFormValues) => Promise<void>;
    createClientProcessingState: ProcessingState;

    updateClient: (clientId: string, nextClient: ClientFormValues) => Promise<ClientResponse | undefined>;
    updateClientProcessingState: ProcessingState;

    updateClientIcon: (clientId: string, image: Blob) => Promise<ClientResponse | undefined>;
    resetClientIcon: (clientId: string) => Promise<ClientResponse | undefined>;
    updateClientIconProcessingState: ProcessingState;
}

const ClientsOverviewContext = createContext<ClientsOverviewContextType>(undefined!);

const useMemoContext = <T extends {}>(data: T): T => useMemo(() => data, [data]);

export const ClientsOverviewContextProvider = ({ children }: React.PropsWithChildren) => {
    const companyId = useCompanyId();
    const clientsClient = useClientsClient();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    const {
        clients,
        getClientOverviewPageAsync,
        getOverviewProcessingState,
        pagination,
        setPagination,
        clientFilters,
        setClientFilters,
    } = useClientOverview();

    const [createClientProcessingState, setCreateClientProcessingState] = useState<ProcessingState>(
        ProcessingState.Processing,
    );
    const createClient: ClientsOverviewContextType['createClient'] = useCallback(
        async ({ name, websiteURL, linkedInURL }) => {
            setCreateClientProcessingState(ProcessingState.Processing);
            try {
                const createRequest: CreateClientRequest = {
                    name,
                    websiteURL: resetEmptyStringToNull(websiteURL),
                    linkedInURL: resetEmptyStringToNull(linkedInURL),
                };

                await clientsClient.createClientAsync(companyId, createRequest);
                // reload the page to reflect the new client
                await getClientOverviewPageAsync(pagination.offset, pagination.limit);

                setCreateClientProcessingState(ProcessingState.Success);
            } catch (error) {
                setCreateClientProcessingState(ProcessingState.Error);
            }
        },
        [setCreateClientProcessingState],
    );

    const [updateClientProcessingState, setUpdateClientProcessingState] = useState<ProcessingState>(
        ProcessingState.Processing,
    );

    // Update details of a non default client, default client should instead propagate
    // to company profile settings values
    const updateClient: ClientsOverviewContextType['updateClient'] = useCallback(
        async (clientId, { name, websiteURL, linkedInURL, description }) => {
            let result;
            setUpdateClientProcessingState(ProcessingState.Processing);
            try {
                const updateRequest: UpdateClientRequest = {
                    name,
                    websiteURL: resetEmptyStringToNull(websiteURL),
                    linkedInURL: resetEmptyStringToNull(linkedInURL),
                    description,
                };

                result = await clientsClient.updateClientAsync(clientId, updateRequest);
                // reload the page to reflect the new client
                await getClientOverviewPageAsync(pagination.offset, pagination.limit);

                setUpdateClientProcessingState(ProcessingState.Success);
                showSuccessToaster(UIStrings.SuccessYourClientDetailsHaveBeenUpdated);
            } catch (error) {
                setUpdateClientProcessingState(ProcessingState.Error);
                showFailToaster();
            }

            return result;
        },
        [setUpdateClientProcessingState],
    );

    const [updateClientIconProcessingState, setUpdateClientIconProcessingState] = useState<ProcessingState>(
        ProcessingState.Processing,
    );

    // Update the icon of a non default client, default client should instead
    // update the current company profile settings icon
    const updateClientIcon: ClientsOverviewContextType['updateClientIcon'] = useCallback(
        async (clientId, image) => {
            let result;
            setUpdateClientIconProcessingState(ProcessingState.Processing);
            try {
                result = await clientsClient.updateNonDefaultClientIcon(clientId, image);
                // reload the page to reflect the new client
                await getClientOverviewPageAsync(pagination.offset, pagination.limit);

                setUpdateClientIconProcessingState(ProcessingState.Success);
                showSuccessToaster(UIStrings.SuccessYourClientDetailsHaveBeenUpdated);
            } catch (error) {
                setUpdateClientIconProcessingState(ProcessingState.Error);
                showFailToaster();
            }

            return result;
        },
        [setUpdateClientIconProcessingState],
    );

    // Reset the icon of a non default client, default client should instead
    // reset the current company profile settings icon
    const resetClientIcon: ClientsOverviewContextType['resetClientIcon'] = useCallback(
        async (clientId) => {
            let result;
            setUpdateClientIconProcessingState(ProcessingState.Processing);
            try {
                result = await clientsClient.resetNonDefaultClientIcon(clientId);
                // reload the page to reflect the new client
                await getClientOverviewPageAsync(pagination.offset, pagination.limit);

                setUpdateClientIconProcessingState(ProcessingState.Success);
                showSuccessToaster(UIStrings.SuccessYourClientDetailsHaveBeenUpdated);
            } catch (error) {
                setUpdateClientIconProcessingState(ProcessingState.Error);
                showFailToaster();
            }

            return result;
        },
        [setUpdateClientIconProcessingState],
    );

    return (
        <ClientsOverviewContext.Provider
            value={useMemoContext<ClientsOverviewContextType>({
                clients,
                getOverviewProcessingState,
                pagination,
                setPagination,

                clientFilters,
                setClientFilters,

                createClient,
                createClientProcessingState,

                updateClient,
                updateClientProcessingState,

                updateClientIcon,
                resetClientIcon,
                updateClientIconProcessingState,
            })}
        >
            {children}
        </ClientsOverviewContext.Provider>
    );
};

export const useClientsOverviewContext = () => {
    const context = useContext(ClientsOverviewContext);

    if (!context) {
        throw new Error('useClientsOverviewContext must be used inside ClientsOverviewContextProvider');
    }

    return context;
};
