import React from 'react';
import { Box } from '@talentmesh/core';
import { FormikDropdown } from '@talentmesh/forms';
import { useTheme } from '@mui/material';
import UIStrings from '../../../../../../../Utils/UIStrings';
import workArrangementOptions from '../WorkArrangementOptions';
import { JobDetailsFieldTypes } from '../../JobDetailsStepUtils';

function WorkArrangement(): JSX.Element {
    const theme = useTheme();

    return (
        <Box sx={{ width: theme.spacing(17.875) }}>
            <FormikDropdown
                label={`${UIStrings.WorkArrangement}*`}
                name={JobDetailsFieldTypes.WorkArrangement}
                options={workArrangementOptions}
                data-cy={JobDetailsFieldTypes.WorkArrangement}
                sx={{ width: theme.spacing(17.875) }}
            />
        </Box>
    );
}

export default WorkArrangement;
