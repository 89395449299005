import React, { useEffect } from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { FormikTextFieldBase } from '@talentmesh/forms';
import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import UIStrings from '../../../../../../Utils/UIStrings';
import { JobDetailsFieldTypes, JobDetailsFormValues } from '../JobDetailsStepUtils';
import NumberFormatter from './NumberFormatter';

function YearlySalaryRange(): JSX.Element {
    const theme = useTheme();
    const {
        values: { doNotDisplaySalary },
        setFieldValue,
        setFieldTouched,
    } = useFormikContext<JobDetailsFormValues>();

    useEffect(() => {
        if (doNotDisplaySalary) {
            setFieldValue(JobDetailsFieldTypes.YearlySalaryFrom, '');
            setFieldValue(JobDetailsFieldTypes.YearlySalaryTo, '');
        } else {
            setFieldTouched(JobDetailsFieldTypes.YearlySalaryFrom, false);
            setFieldTouched(JobDetailsFieldTypes.YearlySalaryTo, false);
        }
    }, [doNotDisplaySalary]);

    return (
        <Stack spacing={theme.spacing(1)} flex={1}>
            <Typography color={theme.palette.text.menuTitle} variant="h6">
                {`${UIStrings.YearlySalaryRange}*`}
            </Typography>
            <Stack direction="row" spacing={theme.spacing(1.125)} alignItems="center">
                <FormikTextFieldBase
                    name={JobDetailsFieldTypes.YearlySalaryFrom}
                    data-cy={JobDetailsFieldTypes.YearlySalaryFrom}
                    disabled={doNotDisplaySalary}
                    InputProps={{
                        inputComponent: NumberFormatter,
                    }}
                />
                <Typography color={theme.palette.text.menuTitle} variant="h6">
                    -
                </Typography>
                <FormikTextFieldBase
                    name={JobDetailsFieldTypes.YearlySalaryTo}
                    data-cy={JobDetailsFieldTypes.YearlySalaryTo}
                    disabled={doNotDisplaySalary}
                    InputProps={{
                        inputComponent: NumberFormatter,
                    }}
                />
            </Stack>
        </Stack>
    );
}

export default YearlySalaryRange;
