import { useFormikContext } from 'formik';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { FormControl, Label, LoadingButton, Stack } from '@talentmesh/core';
import { PersonAddAlt1OutlinedIcon } from '@talentmesh/icons';
import { FormikTextField } from '@talentmesh/forms';
import ProcessingState from '../../../../../Models/ProcessingState';
import UIStrings from '../../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';

function CandidateInputForm(): JSX.Element {
    const theme = useTheme();
    const { processingState } = useInviteCandidateContext();
    const { isSubmitting } = useFormikContext();

    return (
        <Stack direction="row" spacing={2} alignItems="flex-start">
            <FormikTextField fullWidth name="firstName" data-cy="firstName" label={UIStrings.FirstName} />
            <FormikTextField fullWidth name="lastName" data-cy="lastName" label={UIStrings.LastName} />
            <FormikTextField fullWidth name="email" data-cy="email" label={UIStrings.ApplicantEmail} />
            <FormControl sx={{ minWidth: theme.spacing(19.375) }}>
                <Label hidden>hidden</Label>
                <LoadingButton
                    loading={isSubmitting}
                    disabled={processingState === ProcessingState.Processing}
                    startIcon={<PersonAddAlt1OutlinedIcon />}
                    size="large"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    data-cy="submitAddApplicant"
                    sx={{ padding: theme.spacing(1) }}
                >
                    {UIStrings.AddApplicant}
                </LoadingButton>
            </FormControl>
        </Stack>
    );
}

export default CandidateInputForm;
