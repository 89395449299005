import { useTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { Box, Button, Stack } from '@talentmesh/core';
import { ChevronLeftIcon } from '@talentmesh/icons';
import { useField } from 'formik';
import { useCreateRecruitmentContext } from '../../../../Create/Contexts/CreateRecruitmentContext';
import UIStrings from '../../../../../../../Utils/UIStrings';

export interface SelectTestsStepperNavigationButtonsProps {
    activeStep: number;
    handleBack?: () => void;
    nextButtonComponent: ReactNode;
    centeredComponent: ReactNode;
}

export const SelectTestsStepperNavigationButtons = ({
    activeStep,
    handleBack,
    nextButtonComponent,
    centeredComponent,
}: SelectTestsStepperNavigationButtonsProps): JSX.Element => {
    const theme = useTheme();
    const { setTestLanguage } = useCreateRecruitmentContext();
    const [field, ,] = useField('testLanguageField');

    const handleBackWrapper = () => {
        setTestLanguage(field.value);
        if (handleBack) {
            handleBack();
        }
    };

    return (
        <Box sx={{ marginTop: theme.spacing(4) }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {handleBack && (
                    <Button
                        variant="outlined"
                        startIcon={<ChevronLeftIcon />}
                        disabled={activeStep === 0}
                        onClick={handleBackWrapper}
                    >
                        {UIStrings.Back}
                    </Button>
                )}
                {centeredComponent}
                {nextButtonComponent && nextButtonComponent}
            </Stack>
        </Box>
    );
};
