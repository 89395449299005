import { LoadingOverlay } from '@talentmesh/core';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet } from 'react-router-dom';
import RoutePath from './RoutePath';
import { useUserContext } from '../Context/UserContext';

const ProtectedRoute = () => {
    const { user } = useUserContext();
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    const search = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (isLoading || isAuthenticated) {
            return;
        }

        (async (): Promise<void> => {
            await loginWithRedirect({
                appState: {
                    returnTo: `${window.location.pathname}${window.location.search}`,
                },
                email: search.get('email') || undefined,
                token: search.get('token') || undefined,
                recruiter_email: search.get('recruiter_email') || undefined,
            });
        })();
    }, [user, isLoading, isAuthenticated, loginWithRedirect]);

    if (!user) {
        return <Navigate replace to={RoutePath.Error} />;
    }

    return isAuthenticated ? <Outlet /> : <LoadingOverlay />;
};

export default ProtectedRoute;
