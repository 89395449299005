import React from 'react';
import { Dialog, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import AssessmentResetDialogHeader from './AssessmentResetDialogHeader';
import AssessmentResetDialogBody from './AssessmentResetDialogBody';
import AssessmentResetDialogActions from './AssessmentResetDialogActions';

export interface AssessmentResetDialogProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    onConfirm: () => Promise<void>;
}

const AssessmentResetDialog = ({ open, setOpen, onConfirm }: AssessmentResetDialogProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Dialog open={open}>
            <Stack spacing={2} sx={{ padding: theme.spacing(3), width: theme.spacing(59.625) }}>
                <AssessmentResetDialogHeader />
                <AssessmentResetDialogBody />
                <AssessmentResetDialogActions onCancel={() => setOpen(false)} onConfirm={onConfirm} />
            </Stack>
        </Dialog>
    );
};

export default AssessmentResetDialog;
