import { useTheme } from '@mui/material';
import React from 'react';
import { Box, CircularProgress, Stack, ToasterStatus } from '@talentmesh/core';

export type SnackbarMessageStatus = ToasterStatus & 'pending';

export interface SnackbarMessageContentProps {
    message: string;
    status: SnackbarMessageStatus;
}

export default function SnackbarMessageContent({ message, status }: SnackbarMessageContentProps) {
    const theme = useTheme();

    return (
        <Stack>
            <Stack
                flexDirection="row"
                sx={{
                    color: theme.palette.common.white,
                }}
            >
                {status === 'pending' && (
                    <Box>
                        <CircularProgress
                            size={theme.spacing(2)}
                            sx={{
                                color: theme.palette.common.white,
                            }}
                        />
                    </Box>
                )}
                <Box ml={theme.spacing(2)} sx={{ fontWeight: theme.typography.fontWeightRegular }}>
                    {message}
                </Box>
            </Stack>
        </Stack>
    );
}
