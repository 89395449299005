import React from 'react';

export interface CareerPageTabContextState {
    isCareerPageEnabled: boolean;
    isRecruitmentPublic: boolean;
    dialogOpened: boolean;
    loading: boolean;
    enableCareerPageToggle: boolean;
}

export interface CareerPageTabContextAPI extends CareerPageTabContextState {
    enableCareerPage: () => void;
    bookMeetingHandler: () => void;
    closeDialog: () => void;
}

const CareerPageTabContext = React.createContext<CareerPageTabContextAPI | undefined>(undefined);
export const CareerPageTabContextProvider = CareerPageTabContext.Provider;

export function useCareerPageTabContext() {
    const context = React.useContext(CareerPageTabContext);
    if (!context) {
        throw new Error('useCareerPageTabContext must be used within a CareerPageTabContextProvider');
    }
    return context;
}
