import React, { useContext } from 'react';
import { GridPaginationModel } from '@talentmesh/core';
import { TeamMember } from '../../../../../Models/TeamMember';
import { RecruiterRoles } from '../../../../../Models/RecruiterRoles';

export type ConfirmationDialogsTypes = 'RevokeInvitationConfirmation' | 'RemoveAccessConfirmation';

export interface TeamMembersContextState {
    teamMembers: TeamMember[];
    isLoading: boolean;
    search: string;
    isTabLoadingError: boolean;
    isOperationLoading: boolean;
    pageNumber: number;
    pageSize: number;
    totalMembersCount: number;
    activeDialog?: ConfirmationDialogsTypes;
    selectedMember?: TeamMember;
}

export interface TeamMembersContextAPI extends Omit<TeamMembersContextState, 'pageSize'> {
    sendInvitation: (email: string, role: RecruiterRoles) => Promise<void>;
    resendInvitation: (memberId: string) => Promise<void>;
    revokeInvitation: (member: TeamMember) => Promise<void>;
    removeMember: (member: TeamMember) => Promise<void>;
    searchTeamMember: (query: string) => void;
    loadPage: () => Promise<void>;
    handlePaginationModelChange: (model: GridPaginationModel) => void;
    openDialog: (memberId: string, dialog: ConfirmationDialogsTypes) => void;
    closeDialog: () => void;
}

export const TeamMembersContext = React.createContext<TeamMembersContextAPI | undefined>(undefined);

export function useTeamMembersContext() {
    const context = useContext(TeamMembersContext);
    if (!context) {
        throw new Error('useTeamMemberContext must be used within the TeamMembersContext.Provider');
    }
    return context;
}
