import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { PaymentIntent as StripePaymentIntent } from '@stripe/stripe-js';
import EmptyTopBar from '../../Components/TopBars/EmptyTopBar';
import withSignupChecks from '../../Routing/withSignupChecks';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';
import Page from '../Page';
import CheckoutForms from './CheckoutForms';
import { useBillingInfoClient, usePaymentMethodClient } from '../../Hooks/ClientHooks';
import BillingInfo from '../../Models/BillingInfo';
import PageContentLoading from '../PageContentLoading';
import PaymentMethod from '../../Models/PaymentMethod';
import IntentStatus from '../../Models/IntentStatus';
import PaymentResult from './PaymentResult/PaymentResult';
import QueryParams from '../../Routing/QueryParams';
import useQuery from '../../Hooks/UseQuery';
import useCompanyId from '../../Hooks/UseCompanyId';
import withCompanyProfileRequired from '../../Routing/withCompanyProfileRequired';

const CheckoutPage = () => {
    const recruitmentId = useQuery(QueryParams.RecruitmentId);
    const applicantId = useQuery(QueryParams.ApplicantId);

    const theme = useTheme();
    const companyId = useCompanyId();

    const billingClient = useBillingInfoClient();
    const paymentMethodClient = usePaymentMethodClient();

    const [billingInfo, setBillingInfo] = useState<BillingInfo | undefined>(undefined);
    const [savedPaymentMethod, setSavedPaymentMethod] = useState<PaymentMethod | undefined>(undefined);

    const [isLoadingBilling, setIsLoadingBilling] = useState<boolean>(true);
    const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState<boolean>(true);
    const [resultPaymentIntent, setResultPaymentIntent] = useState<StripePaymentIntent>();

    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);

    useEffect(() => {
        const doLoadBillingInfo = async () => {
            try {
                const retrievedBillingInfo = await billingClient.getBillingInfoAsync(companyId);
                setBillingInfo(retrievedBillingInfo);
            } catch {
                setLoadingFailed(true);
            } finally {
                setIsLoadingBilling(false);
            }
        };
        doLoadBillingInfo();
    }, []);

    useEffect(() => {
        const doPaymentMethod = async () => {
            try {
                const retrievedPaymentMethod = await paymentMethodClient.getPaymentMethodAsync(companyId);
                setSavedPaymentMethod(retrievedPaymentMethod);
            } catch {
                setLoadingFailed(true);
            } finally {
                setIsLoadingPaymentMethod(false);
            }
        };
        doPaymentMethod();
    }, []);

    return (
        <Page sx={{ backgroundColor: theme.palette.common.white }} topBar={<EmptyTopBar />}>
            <PageContentLoading isLoading={isLoadingBilling || isLoadingPaymentMethod} loadingFailed={loadingFailed}>
                {resultPaymentIntent && resultPaymentIntent.status === IntentStatus.Succeeded ? (
                    <PaymentResult paymentIntent={resultPaymentIntent} />
                ) : (
                    <CheckoutForms
                        recruitmentId={recruitmentId}
                        applicantId={applicantId}
                        billingInfo={billingInfo}
                        setBillingInfo={setBillingInfo}
                        savedPaymentMethod={savedPaymentMethod}
                        setResultPaymentIntent={setResultPaymentIntent}
                    />
                )}
            </PageContentLoading>
        </Page>
    );
};

export default withCompanyProfileRequired(withTalentMeshUserRequired(withSignupChecks(CheckoutPage)));
