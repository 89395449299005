import React, { SyntheticEvent, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, ButtonTab, Stack, TabContext, TabList, TabPanel, Typography } from '@talentmesh/core';
import TemplateEditor from './TemplateEditor';
import { useAccountSettingsContext } from '../../../Context/AccountSettingsContext';
import { EmailTemplateType } from '../../../Models/Configuration';
import UIStrings from '../../../Utils/UIStrings';
import ExitDialog from './ExitDialog';

function EditEmailTemplate(): JSX.Element {
    const theme = useTheme();

    const { accountSettings } = useAccountSettingsContext();

    const [value, setValue] = useState('1');
    const [newValue, setNewValue] = useState('');
    const [dirty, setDirty] = useState(false);
    const [open, setOpen] = useState(false);

    const handleChange = (event: SyntheticEvent, nv: string) => {
        if (dirty) {
            setNewValue(nv);
            setOpen(true);
        } else {
            setValue(nv);
        }
    };

    return (
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Stack spacing={1}>
                <Typography variant="h2">{UIStrings.EmailTemplates}</Typography>
                <Typography variant="body2">{UIStrings.ManageInterviewInvitation}</Typography>
            </Stack>

            <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                    <TabList onChange={handleChange}>
                        <ButtonTab
                            sx={{ width: theme.spacing(28) }}
                            label={UIStrings.InterviewInvitationEmail}
                            value="1"
                        />
                        <ButtonTab sx={{ width: theme.spacing(28) }} label={UIStrings.RejectionEmail} value="2" />
                    </TabList>

                    <TabPanel sx={{ p: 0 }} value="1">
                        <TemplateEditor
                            setDirty={setDirty}
                            {...accountSettings.inviteEmailTemplate}
                            templateType={EmailTemplateType.InviteTemplate}
                        />
                    </TabPanel>
                    <TabPanel sx={{ p: 0 }} value="2">
                        <TemplateEditor
                            setDirty={setDirty}
                            {...accountSettings.rejectEmailTemplate}
                            templateType={EmailTemplateType.RejectTemplate}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
            <ExitDialog
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => {
                    setOpen(false);
                    setValue(newValue);
                    setDirty(false);
                }}
            />
        </Stack>
    );
}

export default EditEmailTemplate;
