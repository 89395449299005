import ClientBase from './ClientBase';
import { put, post, httpDelete, get } from '../Utils/HTTP/HttpUtils';
import ContentTypes from '../Utils/ContentTypes';
import { extractImageContentType } from '../Components/ImageCrop/ImageCropUtils';
import { CompanyProfileDTO, DefaultCompanyProfileDTO } from '../DTO/Users/CompanyProfileDTO';

export enum CompanyImage {
    Icon = 'Icon',
    Logo = 'Logo',
}

export interface UpdateCompanyProfileDTO extends Omit<CompanyProfileDTO, 'companyLogoURL' | 'companyIconURL'> {}

export default class CompanyClient extends ClientBase {
    updateCompanyProfileAsync = async (
        companyId: string,
        companyProfile: UpdateCompanyProfileDTO,
    ): Promise<CompanyProfileDTO> => {
        const path = `companies/${companyId}/profile`;
        const response = await put<UpdateCompanyProfileDTO, CompanyProfileDTO>({
            path: this.addHost(path),
            body: JSON.stringify(companyProfile),
            contentType: ContentTypes.ApplicationJson,
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultCompanyProfileDTO;
    };

    updateCompanyImage = async (companyId: string, image: Blob, imageType: CompanyImage): Promise<void> => {
        const name = imageType.toLowerCase();
        const path = `companies/${companyId}/${name}`;
        const type = extractImageContentType(image.type);
        const form = new FormData();

        form.append('file', image, `${name}.${type}`);

        await post({
            path: this.addHost(path),
            body: form,
            accessToken: await this.getAccessTokenSilently(),
        });
    };

    resetCompanyImage = async (companyId: string, imageType: CompanyImage): Promise<void> => {
        const type = imageType.toLowerCase();
        const path = `companies/${companyId}/${type}`;
        await httpDelete({
            path: this.addHost(path),
            accessToken: await this.getAccessTokenSilently(),
        });
    };

    getCompanyProfile = async (companyId: string): Promise<CompanyProfileDTO> => {
        const path = `companies/${companyId}/profile`;
        const response = await get<CompanyProfileDTO>({
            path: this.addHost(path),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultCompanyProfileDTO;
    };
}
