import { useTheme } from '@mui/material';
import { Box, Button, IconButton, Paper, Stack, Typography } from '@talentmesh/core';
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@talentmesh/icons';
import React from 'react';
import { TestTypes } from '../../../../../../../../../../../../Models/Configuration';
import UIStrings from '../../../../../../../../../../../../Utils/UIStrings';
import TalentMeshButterfly from './tmbutterfly.svg';

export interface Pagination {
    index: number;
    total: number;
}

export interface DialogHeaderProps {
    testType: TestTypes;
    pagination: Pagination;
    onClickClose: React.MouseEventHandler;
    onNext: React.MouseEventHandler;
    onPrevious: React.MouseEventHandler;
}

const DialogHeader = ({
    testType,
    pagination: { index, total },
    onClickClose,
    onNext,
    onPrevious,
}: DialogHeaderProps) => {
    const theme = useTheme();
    const headerDescription = UIStrings.TalentMeshTestPreviewQuestions(testType);
    const isNavigable = !!total;

    return (
        <Paper
            elevation={4}
            sx={{
                display: 'flex',
                placeItems: 'center',
                px: theme.spacing(1.875),
                py: theme.spacing(1.125),
                gap: theme.spacing(1.625),
                zIndex: theme.zIndex.appBar,
            }}
        >
            <Box
                display="flex"
                border="1px solid"
                borderColor={theme.palette.border.main}
                borderRadius="60%"
                p={theme.spacing(1.125)}
                sx={{ aspectRatio: '1 / 1' }}
            >
                <img src={TalentMeshButterfly} alt="logo" width="100%" />
            </Box>
            <Stack>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {UIStrings.PreviewQuestions}
                </Typography>
                <Typography variant="body2">
                    {headerDescription} {isNavigable && `${index + 1}/${total}`}
                </Typography>
            </Stack>
            <Box ml="auto" />
            <Stack direction="row" display="flex" spacing={4.5}>
                {isNavigable && (
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="outlined"
                            onClick={onPrevious}
                            startIcon={<ChevronLeftIcon />}
                            disabled={!index}
                        >
                            {UIStrings.Previous}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={onNext}
                            endIcon={<ChevronRightIcon />}
                            disabled={index + 1 === total}
                        >
                            {UIStrings.Next}
                        </Button>
                    </Stack>
                )}
            </Stack>
            <IconButton onClick={onClickClose}>
                <CloseIcon />
            </IconButton>
        </Paper>
    );
};

export default DialogHeader;
