import { useTheme } from '@mui/material';
import { useLocation, matchPath } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@talentmesh/core';
import React from 'react';
import { useCareerBuilderContext } from '../../Contexts/CareerBuilderContext';
import UIStrings from '../../../../Utils/UIStrings';
import RoutePath from '../../../../Routing/RoutePath';
import useTalentMeshHistory from '../../../../Routing/useTalentMeshHistory';

function PublishedSuccessfullyDialog(): JSX.Element {
    const { showPublishedSuccessFullyDialog, closedPublishedSuccessFullyDialog } = useCareerBuilderContext();
    const { state } = useLocation();
    const history = useTalentMeshHistory();
    const theme = useTheme();

    let isAttractApplicantsCareerTab = false;
    if (state?.from) {
        const match = matchPath({ path: RoutePath.AttractApplicantsCareerPage, end: true }, state.from);
        isAttractApplicantsCareerTab = match?.pattern.path === RoutePath.AttractApplicantsCareerPage;
    }

    const handleGoTo = () => {
        if (isAttractApplicantsCareerTab) {
            history.goBack();
        } else {
            history.push(RoutePath.AccountSettingsCareerPage);
        }
    };

    return (
        <Dialog open={showPublishedSuccessFullyDialog} disableEscapeKeyDown disableEnforceFocus maxWidth="sm">
            <DialogTitle variant="h4">{UIStrings.CareerPageHasBeenPublished}</DialogTitle>
            <DialogContent>{UIStrings.ConnectCareerPageToWebsiteInstruction}</DialogContent>
            <DialogActions
                sx={{
                    paddingBottom: theme.spacing(2),
                    justifyContent: 'center',
                }}
            >
                <Button
                    variant="outlined"
                    size="large"
                    onClick={closedPublishedSuccessFullyDialog}
                    sx={{
                        width: theme.spacing(30),
                    }}
                >
                    {UIStrings.StayOnCareerPageEditor}
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    onClick={handleGoTo}
                    sx={{
                        width: theme.spacing(30),
                    }}
                >
                    {isAttractApplicantsCareerTab ? UIStrings.GoToAttractApplicants : UIStrings.GoToSettings}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PublishedSuccessfullyDialog;
