import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Tooltip, Typography, ButtonProps } from '@talentmesh/core';
import { AddIcon } from '@talentmesh/icons';
import { ImageCropStrings } from './ImageCropUtils';

export interface ImageCropSelectButtonProps
    extends Pick<ImageCropStrings, 'selectButtonTooltipText'>,
        Pick<ButtonProps, 'sx' | 'onClick' | 'disabled'> {
    children: ReactNode;
    removeButton: ReactNode;
}

export const ImageCropSelectButton = ({
    children,
    removeButton,
    sx,
    onClick,
    disabled,
    selectButtonTooltipText,
}: ImageCropSelectButtonProps): JSX.Element => {
    const theme = useTheme();
    const defaultButtonSize = theme.spacing(8.75);

    return (
        <Tooltip
            placement="top"
            arrow
            title={<Typography variant="label">{selectButtonTooltipText}</Typography>}
            disableHoverListener={disabled}
            disableFocusListener={disabled}
            disableTouchListener={disabled}
        >
            <Box sx={{ position: 'relative' }}>
                <Button
                    data-cy="ImageCropSelectButton"
                    onClick={onClick}
                    disabled={disabled}
                    sx={{
                        border: `1px solid ${theme.palette.grey[600]}`,
                        borderRadius: '50%',
                        backgroundColor: theme.palette.common.white,
                        overflow: 'hidden',
                        height: defaultButtonSize,
                        width: defaultButtonSize,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                        ...sx,
                    }}
                >
                    {disabled ? children : <AddIcon sx={{ color: theme.palette.primary.main }} />}
                </Button>
                {disabled && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: theme.spacing(-1),
                            right: theme.spacing(-1),
                        }}
                    >
                        {removeButton}
                    </Box>
                )}
            </Box>
        </Tooltip>
    );
};
