import * as yup from 'yup';
import YupValidationErrors from '../../../Utils/YupValidationErrors';
import { UpdateClientRequest } from '../../../DTO/Clients/UpdateClientRequest';

export interface ClientFilters {
    // zero-ed value has no meaning and will not be applied
    search?: string | null;
}

export const clientSchema: yup.SchemaOf<UpdateClientRequest> = yup.object().shape({
    name: yup.string().required(YupValidationErrors.Required).max(128),
    websiteURL: yup.string().url(YupValidationErrors.UrlFieldMustBeAValidUrl).max(128),
    linkedInURL: yup.string().url(YupValidationErrors.UrlFieldMustBeAValidUrl).max(128),
    description: yup.string(),
});

export type ClientFormValues = yup.InferType<typeof clientSchema>;

export const clientFormFieldNames: { [key in keyof ClientFormValues]: key } = {
    name: 'name',
    websiteURL: 'websiteURL',
    linkedInURL: 'linkedInURL',
    description: 'description',
};
