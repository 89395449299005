import React from 'react';
import { useTheme } from '@mui/material';
import { Dialog, DialogTitle, Typography, DialogContent, Stack, Button } from '@talentmesh/core';
import { useCareerBuilderContext } from '../../Contexts/CareerBuilderContext';
import UIStrings from '../../../../Utils/UIStrings';

const UnsavedPrompt = (): JSX.Element => {
    const theme = useTheme();
    const { showLeaveDialog, handleBuilderDirtyLeave, handleBuilderDirtyStay } = useCareerBuilderContext();

    return (
        <Dialog
            open={showLeaveDialog}
            PaperProps={{
                sx: {
                    width: theme.spacing(55.5),
                },
            }}
        >
            <DialogTitle variant="h4" color={theme.palette.text.primary}>
                {UIStrings.ExitWithUnsavedChanges}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ marginTop: theme.spacing(1) }}>
                    <Typography variant="body2" color={theme.palette.text.primary}>
                        {UIStrings.ChangesYouHaveMadeToYourCareerPage}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ justifyContent: 'end' }}>
                        <Button variant="text" color="primary" onClick={handleBuilderDirtyStay}>
                            {UIStrings.Cancel}
                        </Button>
                        <Button variant="contained" onClick={handleBuilderDirtyLeave}>
                            {UIStrings.Confirm}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default UnsavedPrompt;
