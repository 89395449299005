import parse from 'autosuggest-highlight/parse';

function parseWithKey(text: string, matches: [number, number][]) {
    const tokens = parse(text, matches);
    return tokens.map((token, index) => ({
        key: `${index}-${token.text}`,
        ...token,
    }));
}

export default parseWithKey;
