import React from 'react';
import AttractCandidatesPageContent from './AttractCandidatesPageContent';
import { InvitationPageContextProvider } from './Contexts/InvitationPageContext';
import useInvitationPageContextValue from './Contexts/useInvitationPageContextValue';
import withCompanyProfileRequired from '../../../Routing/withCompanyProfileRequired';

export interface InviteApplicantsPageLocationState {
    from?: string;
    allowDialog?: boolean;
}

function InviteCandidatesPage(): JSX.Element {
    const value = useInvitationPageContextValue();
    return (
        <InvitationPageContextProvider value={value}>
            <AttractCandidatesPageContent />
        </InvitationPageContextProvider>
    );
}

export default withCompanyProfileRequired(InviteCandidatesPage);
