import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { Dialog, DialogActions, DialogContent, IconButton, LoadingButton, Stack, Typography } from '@talentmesh/core';
import { RHFTextField } from '@talentmesh/forms';
import { CloseIcon } from '@talentmesh/icons';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ClientOverviewResponse } from '../../../../DTO/Clients/ClientOverviewResponse';
import UIStrings from '../../../../Utils/UIStrings';
import { clientFormFieldNames, ClientFormValues, clientSchema } from '../Client';
import ClientDescription from './ClientDescription';
import ClientImageCrop, { ClientImageCropProps } from './ClientImageCrop';

interface ClientFormDialogProps extends Partial<Omit<ClientImageCropProps, 'src'>> {
    open: boolean;
    client?: ClientOverviewResponse;
    onClose: () => void;
    onSubmit: (form: ClientFormValues) => Promise<void>;
}

const ClientFormDialog = ({
    client,
    open,
    onClose,
    onSubmit,
    handleUploadImage,
    handleResetImage,
}: ClientFormDialogProps) => {
    const theme = useTheme();
    const form = useForm<ClientFormValues>({
        resolver: yupResolver(clientSchema),
        mode: 'all',
    });
    const {
        reset,
        formState: { isDirty, isValid, isSubmitting },
    } = form;
    const isEditMode = client !== undefined;

    useEffect(() => {
        if (open) {
            const initialValues: ClientFormValues = {
                description: client?.description ?? '',
                linkedInURL: client?.linkedInURL ?? '',
                name: client?.name ?? '',
                websiteURL: client?.websiteURL ?? '',
            };

            reset(initialValues);
        }
    }, [open, client, reset]);

    return (
        <FormProvider {...form}>
            <Dialog
                data-cy="ClientEditDialog"
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="xs"
                PaperProps={{
                    component: 'form',
                    onSubmit: form.handleSubmit(onSubmit),
                    sx: { padding: theme.spacing(2.5), maxWidth: isEditMode ? theme.spacing(118.125) : undefined },
                }}
            >
                <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4">{isEditMode ? UIStrings.EditClient : UIStrings.CreateClient}</Typography>
                    <IconButton data-cy="ClientEditDialogClose" aria-label="close" onClick={onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <DialogContent
                    sx={{
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(3.75),
                        my: theme.spacing(2.75),
                        py: theme.spacing(1),
                    }}
                >
                    <RHFTextField label={`${UIStrings.CompanyName}*`} name={clientFormFieldNames.name} fullWidth />
                    <Stack direction={client ? 'row' : 'column'} spacing={3.75}>
                        <RHFTextField label={UIStrings.WebsiteUrl} name={clientFormFieldNames.websiteURL} fullWidth />
                        <RHFTextField label={UIStrings.LinkedInUrl} name={clientFormFieldNames.linkedInURL} fullWidth />
                    </Stack>
                    {isEditMode && handleUploadImage && handleResetImage && (
                        <ClientImageCrop
                            src={client.iconURL ?? ''}
                            handleUploadImage={handleUploadImage}
                            handleResetImage={handleResetImage}
                        />
                    )}
                    {isEditMode && <ClientDescription initialHtml={client.description} />}
                </DialogContent>
                <DialogActions sx={{ padding: 0 }}>
                    <LoadingButton
                        data-cy="ClientEditDialogSubmit"
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="large"
                        loading={isSubmitting}
                        disabled={!(isDirty && isValid)}
                    >
                        {isEditMode ? UIStrings.Update : UIStrings.CreateClient}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
};

export default ClientFormDialog;
