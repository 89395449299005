import React from 'react';
import { useTheme } from '@mui/material';
import { SpanTypography, Stack, TypographyProps } from '@talentmesh/core';

export interface MultiFontWeightLabelProps extends TypographyProps {
    label: string;
    comment?: string;
}

const MultiFontWeightLabel = ({ label, comment, ...props }: MultiFontWeightLabelProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <SpanTypography fontWeight={theme.typography.fontWeightBold} {...props}>{`${label}`}</SpanTypography>
            {comment && (
                <SpanTypography fontWeight={theme.typography.fontWeightRegular} {...props}>
                    {comment}
                </SpanTypography>
            )}
        </Stack>
    );
};

export default MultiFontWeightLabel;
