import React from 'react';
import { Typography, Stack, IconButton } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material';
import RecruitmentCreatedIcon from './RecruitmentCreatedIcon.svg';

interface RecruitmentCreatedNotificationHeaderProps {
    title: string;
    onClose: () => void;
}

const RecruitmentCreatedNotificationHeader = ({ title, onClose }: RecruitmentCreatedNotificationHeaderProps) => {
    const theme = useTheme();
    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1.25}>
                <object type="image/svg+xml" data={RecruitmentCreatedIcon} aria-label="Payment successful" />
                <Typography variant="h4">{title}</Typography>
            </Stack>
            <IconButton
                data-cy="ImageCropDialogCloseButton"
                onClick={onClose}
                sx={{ height: theme.spacing(3.75), width: theme.spacing(3.75) }}
            >
                <CloseIcon />
            </IconButton>
        </Stack>
    );
};

export default RecruitmentCreatedNotificationHeader;
