import { useTheme } from '@mui/material';
import { Grid, Stack } from '@talentmesh/core';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { useAssessmentSettingsContext } from '../../../../../Context/AssessmentSettingsContext';
import { findJobCategoryByJobFunctionId } from '../../../../../Models/JobCategory';
import YupValidationErrors from '../../../../../Utils/YupValidationErrors';
import StepProps from '../StepProps';
import JobDetailsStepHeader from './Components/JobDetailsStepHeader';
import JobDetailsStepNavigation from './Components/JobDetailsStepNavigation';
import DirectReports from './Components/DirectReports';
import EmploymentType from './Components/EmploymentType';
import JobFunctionSelection from './Components/JobFunctionSelection';
import JobLocation from './Components/JobLocation/JobLocation';
import WorkArrangement from './Components/JobLocation/WorkArrangement';
import JobTitle from './Components/JobTitle';
import WorkExperience from './Components/WorkExperience';
import {
    JobDetailsFieldTypes,
    JobDetailsFormValues,
    jobDetailsFormValues2Model,
    jobDetailsModel2FormValues,
    MAX_SALARY,
} from './JobDetailsStepUtils';
import Salary from './Components/Salary';
import JobDescriptionLanguage from './Components/JobDescriptionLanguage';
import { JobDetails } from '../../Models/JobDetails';
import { Currency } from '../../../../../Models/Currency';
import ProcessingState from '../../../../../Models/ProcessingState';
import JobPostingOptions from './Components/JobPostingOptions';
import { ClientResponse } from '../../../../../DTO/Clients/ClientResponse';

interface JobDetailsStepProps extends StepProps {
    processingState: ProcessingState;
    jobDetails: JobDetails;
    updateJobDetails: (newJobDetails: JobDetails) => Promise<void>;
    currencies: Array<Currency>;
    client: ClientResponse;
    companyName: string;
    disableJobDescriptionLanguage?: boolean;
    disableJobFunctionSelection?: boolean;
    disableHowDoYouWantToPost?: boolean;
}

const JobDetailsStep = ({
    activeStep,
    handleNext,
    handleBack,
    processingState,
    jobDetails,
    updateJobDetails,
    currencies,
    client,
    companyName,
    disableJobDescriptionLanguage = false,
    disableJobFunctionSelection = false,
    disableHowDoYouWantToPost = false,
}: JobDetailsStepProps): JSX.Element => {
    const theme = useTheme();

    const { assessmentSettings } = useAssessmentSettingsContext();

    const getJobCategoryId = (jobFunctionId?: number): string => {
        if (!jobFunctionId || jobFunctionId <= 0) {
            return '';
        }

        const jobCategory = findJobCategoryByJobFunctionId(assessmentSettings?.jobCategories || [], jobFunctionId);

        return jobCategory != null ? `${jobCategory.id}` : '';
    };

    const initialValues: JobDetailsFormValues = jobDetailsModel2FormValues(
        getJobCategoryId(jobDetails.jobFunctionId),
        jobDetails,
    );

    const submitHandlerAsync = async (values: JobDetailsFormValues) => {
        await updateJobDetails(jobDetailsFormValues2Model(values));

        if (handleNext) {
            handleNext();
        }
    };

    const validationScheme = yup.object().shape({
        assessmentName: yup
            .string()
            .required(YupValidationErrors.Required)
            .max(50, YupValidationErrors.JobTitleMaxSymbolsValidationError),
        jobLocation: yup.object().nullable().required(YupValidationErrors.Required),
        jobFunctionId: yup.string().required(YupValidationErrors.Required),
        jobCategoryId: yup.string().required(YupValidationErrors.Required),
        doNotDisplaySalary: yup.boolean(),
        yearlySalaryFrom: yup.number().when(JobDetailsFieldTypes.DoNotDisplaySalary, {
            is: false,
            then: yup
                .number()
                .typeError(YupValidationErrors.InvalidInputError)
                .positive(YupValidationErrors.NegativeValueError)
                .required(YupValidationErrors.EmptyFieldError)
                .max(MAX_SALARY, YupValidationErrors.SalaryExceedsSystemLimitError), // this is max number before system complains about precision
        }),
        yearlySalaryTo: yup.number().when(JobDetailsFieldTypes.DoNotDisplaySalary, {
            is: false,
            then: yup
                .number()
                .typeError(YupValidationErrors.InvalidInputError)
                .positive(YupValidationErrors.NegativeValueError)
                .required(YupValidationErrors.EmptyFieldError)
                .max(MAX_SALARY, YupValidationErrors.SalaryExceedsSystemLimitError) // this is max number before system complains about precision
                .when([JobDetailsFieldTypes.YearlySalaryFrom], (yearlySalaryFrom, schema) => {
                    return schema.test({
                        name: 'maxGreaterThanMin',
                        test(value?: number) {
                            if (yearlySalaryFrom !== undefined && value !== undefined) {
                                return value >= yearlySalaryFrom;
                            }
                            return true; // If either field is not present, don't perform the test.
                        },
                        message: YupValidationErrors.IncorrectSalaryLimitsError,
                    });
                }),
        }),
        currency: yup.object().when(JobDetailsFieldTypes.DoNotDisplaySalary, {
            is: false,
            then: yup.object().shape({
                id: yup.string(),
                label: yup.string().required(YupValidationErrors.EmptyFieldError),
            }),
        }),
        jobDescriptionLanguage: yup.object().required(YupValidationErrors.Required),
        jobPostingType: yup.string().required(YupValidationErrors.Required),
    });

    return (
        <Grid container columns={14}>
            <Grid item xs={2} />
            <Grid item xs={10}>
                <JobDetailsStepHeader />
                <Formik initialValues={initialValues} onSubmit={submitHandlerAsync} validationSchema={validationScheme}>
                    <Form>
                        <Stack
                            spacing={theme.spacing(4.375)}
                            px={theme.spacing(2.5)}
                            py={theme.spacing(3.75)}
                            sx={{
                                backgroundColor: theme.palette.background.aliciaBlue,
                                borderRadius: theme.shape.smallBorderRadius,
                                border: `1px solid ${theme.palette.border.main}`,
                            }}
                        >
                            <JobTitle />
                            <Stack direction="row" spacing={theme.spacing(2.5)}>
                                <WorkArrangement />
                                <JobLocation />
                            </Stack>
                            {assessmentSettings && (
                                <JobFunctionSelection
                                    assessmentConfiguration={assessmentSettings}
                                    disabled={disableJobFunctionSelection}
                                />
                            )}
                            <Stack direction="row" spacing={theme.spacing(2.5)}>
                                <WorkExperience />
                                <EmploymentType />
                            </Stack>
                            <DirectReports />
                            <Salary currencies={currencies} />
                            {!client.isDefault && (
                                <JobPostingOptions
                                    companyName={companyName}
                                    clientName={client.name}
                                    disabled={disableHowDoYouWantToPost}
                                />
                            )}
                            <JobDescriptionLanguage disabled={disableJobDescriptionLanguage} />
                        </Stack>
                        <JobDetailsStepNavigation
                            processingState={processingState}
                            activeStep={activeStep}
                            handleBack={handleBack}
                        />
                    </Form>
                </Formik>
            </Grid>
            <Grid item xs={2} />
        </Grid>
    );
};

export default JobDetailsStep;
