import React from 'react';
import { Paper, PaperProps } from '@talentmesh/core';
import { useTheme } from '@mui/material';

function TabPaper({ children, ...props }: PaperProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            elevation={0}
            sx={{
                padding: 2.5,
                border: `1px solid ${theme.palette.border.main}`,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            }}
            {...props}
        >
            {children}
        </Paper>
    );
}

export default TabPaper;
