import React from 'react';
import { useTheme } from '@mui/material';
import { Box, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import VerifyEmailSpanTypography from './VerifyEmailSpanTypography';

interface VerifyEmailInstructionsProps {
    email: string;
}

function VerifyEmailInstructions({ email }: VerifyEmailInstructionsProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            spacing={2.5}
            sx={{
                paddingX: theme.spacing(2.5),
                paddingTop: theme.spacing(2.5),
                paddingBottom: theme.spacing(5),
            }}
        >
            <Typography variant="h3" color={theme.palette.text.menuTitle}>
                {UIStrings.VerifyYourEmailToStartUsingTalentMesh}
            </Typography>
            <Box>
                <VerifyEmailSpanTypography>{`${UIStrings.VerifyEmailInstructions1} `}</VerifyEmailSpanTypography>
                <VerifyEmailSpanTypography
                    fontWeight={theme.typography.fontWeightBold}
                >{`${email}. `}</VerifyEmailSpanTypography>
                <VerifyEmailSpanTypography>{UIStrings.VerifyEmailInstructions2}</VerifyEmailSpanTypography>
            </Box>
        </Stack>
    );
}

export default VerifyEmailInstructions;
