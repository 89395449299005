import React from 'react';
import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';
import { useNotificationContext } from '../../../../../Context/NotificationContext';
import CopyLinkControl from '../../Components/CopyLinkControl';
import { useInvitationPageContext } from '../../Contexts/InvitationPageContext';

function Header(): JSX.Element {
    const theme = useTheme();
    const { allowDialog } = useInvitationPageContext();
    const { openLink, setCopyLinkDialogOpen } = useInviteCandidateContext();
    const { showSuccessToaster } = useNotificationContext();

    const copyHandler = async () => {
        await navigator.clipboard.writeText(openLink);
        if (allowDialog) {
            setCopyLinkDialogOpen(true);
        } else {
            showSuccessToaster('', UIStrings.YourRecruitmentLinkCopied);
        }
    };

    return (
        <Stack>
            <Typography variant="h4" color={theme.palette.text.menuTitle} sx={{ paddingY: theme.spacing(1.25) }}>
                {UIStrings.InvitationLink}
            </Typography>
            <CopyLinkControl link={openLink} onClickHandler={copyHandler} width={theme.spacing(40)} />
        </Stack>
    );
}

export default Header;
