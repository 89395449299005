import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { Dialog, DialogActions, DialogContent, IconButton, LoadingButton, Stack, Typography } from '@talentmesh/core';
import { RHFTextField } from '@talentmesh/forms';
import { CloseIcon } from '@talentmesh/icons';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { criteriaScoreFieldNames, CriteriaScoreFormValues, criteriaScoreSchema } from '../CriteriaScore';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { ExperienceCriterionFinalScore } from '../../../../../../../Models/ExperienceCriterionFinalScore';
import NumberFormatter from '../../../../../../Assessments/Modify/Steps/JobDetailsStep/Components/NumberFormatter';

interface CriteriaScoreFormDialogProps {
    open: boolean;
    criteriaFinalScore: ExperienceCriterionFinalScore;
    onClose: () => void;
    onSubmit: (form: CriteriaScoreFormValues, criterionScore: ExperienceCriterionFinalScore) => Promise<void>;
}

const CriteriaScoreFormDialog = ({ criteriaFinalScore, open, onClose, onSubmit }: CriteriaScoreFormDialogProps) => {
    const theme = useTheme();
    const form = useForm<CriteriaScoreFormValues>({
        resolver: yupResolver(criteriaScoreSchema),
        mode: 'all',
    });
    const isFormEqualOnSubmit = !form.formState.isDirty && form.formState.isSubmitted;

    useEffect(() => {
        if (open && criteriaFinalScore) {
            form.reset({
                userDefinedScore: criteriaFinalScore.criterionScore?.score,
                userDefinedJustification: criteriaFinalScore.justification ?? undefined,
            });
        }
    }, [open, criteriaFinalScore, form]);

    const handleSubmit = async (formValues: CriteriaScoreFormValues) => {
        if (form.formState.isDirty) {
            await onSubmit(formValues, criteriaFinalScore);
        }
    };

    return (
        <FormProvider {...form}>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    component: 'form',
                    onSubmit: form.handleSubmit(handleSubmit),
                    sx: { padding: theme.spacing(2.5) },
                }}
            >
                <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4">{UIStrings.NewJustificationAndScore}</Typography>
                    <IconButton aria-label="close" onClick={onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <DialogContent
                    sx={{
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(3.75),
                        my: theme.spacing(2.75),
                        py: theme.spacing(1),
                    }}
                >
                    <RHFTextField
                        label={UIStrings.NewScore}
                        name={criteriaScoreFieldNames.userDefinedScore}
                        InputProps={{ inputComponent: NumberFormatter }}
                        fullWidth
                    />
                    <RHFTextField
                        label={UIStrings.NewJustification}
                        name={criteriaScoreFieldNames.userDefinedJustification}
                        multiline
                        rows={6}
                        fullWidth
                    />
                    {isFormEqualOnSubmit && (
                        <Typography variant="body2" color={theme.palette.error.main}>
                            {UIStrings.PleaseUpdateScoreAndJustificationToProceed}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions sx={{ padding: 0 }}>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="large"
                        loading={form.formState.isSubmitting}
                    >
                        {UIStrings.Update}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
};

export default CriteriaScoreFormDialog;
