import { useTheme } from '@mui/material';
import {
    FilePreview,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@talentmesh/core';
import React from 'react';
import { Question, QuestionType } from '../../../../../../../../Models/Questions';
import HtmlViewer from './QuestionComponents/HtmlViewer';
import useText from './hardcode/useText';

interface AnswerQuestionsPreviewProps {
    demoQuestion: Question;
}

const AnswerQuestionsPreview = ({ demoQuestion }: AnswerQuestionsPreviewProps) => {
    const theme = useTheme();
    const UIStrings = useText();

    return (
        <Grid container spacing={6} p={theme.spacing(2.5)} height="100%" overflow="auto">
            <Grid item md={6}>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="flex-end">
                        <Typography variant="h4" color={theme.palette.primary.main}>
                            {UIStrings.Question}
                        </Typography>
                    </Stack>
                    <Stack>
                        <HtmlViewer html={demoQuestion.question} />
                    </Stack>
                    <FilePreview src={demoQuestion.blobFileURI} fileMimeType={demoQuestion.fileMimeType} />
                </Stack>
            </Grid>
            <Grid item md={6}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h4" color={theme.palette.primary.main}>
                        {UIStrings.Answer}
                    </Typography>
                    <Typography variant="h5">
                        {demoQuestion.questionType === QuestionType.Choice && UIStrings.ChooseAnswers}
                        {demoQuestion.questionType === QuestionType.Exact && UIStrings.EnterAnswer}
                    </Typography>
                    <Stack spacing={2} alignItems="flex-start">
                        {demoQuestion.questionType === QuestionType.Choice && (
                            <FormControl>
                                <RadioGroup value="">
                                    {demoQuestion.answers.map((answer) => (
                                        <FormControlLabel
                                            key={answer.id}
                                            value={answer.id}
                                            label={<HtmlViewer html={answer.content} />}
                                            control={<Radio />}
                                            sx={{
                                                marginTop: theme.spacing(1),
                                                marginBottom: theme.spacing(1),
                                            }}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        )}
                        {demoQuestion.questionType === QuestionType.Exact && <TextField />}
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default AnswerQuestionsPreview;
