import React from 'react';
import { useTheme } from '@mui/material/styles';

import { TMTooltip, Paper, Typography } from '@talentmesh/core';

export interface CandidatesListToolTipProps {
    title: string;
    children: React.ReactElement<any, any>;
}

const CandidatesListToolTip = ({ title, children }: CandidatesListToolTipProps) => {
    const theme = useTheme();
    return (
        <TMTooltip
            title={
                <Paper
                    elevation={5}
                    sx={{
                        backgroundColor: theme.palette.grey[700],
                        borderRadius: theme.shape.tinyBorderRadius,
                        color: theme.palette.text.contrast,
                        paddingInline: 1,
                    }}
                >
                    <Typography variant="label" color={theme.palette.common.white}>
                        {title}
                    </Typography>
                </Paper>
            }
        >
            {children}
        </TMTooltip>
    );
};

export default CandidatesListToolTip;
