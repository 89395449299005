import { useTheme } from '@mui/material';
import { Stack } from '@talentmesh/core';
import React, { ReactNode } from 'react';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import BodyTypography from '../../../../Common/BodyTypography';
import GridRowNode from '../Common/GridRowNode';

export interface QualificationItemProps {
    period: string;
    header1: string;
    header2: string;
    label: string;
    badge?: ReactNode;
}

export function QualificationItem({ period, header1, header2, label, badge }: QualificationItemProps): JSX.Element {
    const theme = useTheme();
    return (
        <GridRowNode
            title={<BodyTypography color={theme.palette.secondary.main}>{period}</BodyTypography>}
            node={
                <Stack direction="column" spacing={1}>
                    <BodyTypography color={theme.palette.secondary.main} fontWeight="bold">
                        <span>{header1}</span>
                        <span style={{ fontWeight: theme.typography.fontWeightRegular }}>{` ${UIStrings.At} `}</span>
                        <span>{header2}</span>
                    </BodyTypography>
                    <BodyTypography>{label}</BodyTypography>
                    {badge && badge}
                </Stack>
            }
        />
    );
}
