interface AddressComponentDTO {
    longName: string;
    shortName: string;
}

const DefaultAddressComponentDTO = {
    longName: '',
    shortName: '',
};

export interface CoordinatesDTO {
    latitude: number;
    longitude: number;
}

export interface LocationDetailsDTO {
    locationId: string;
    description: string;
    geometry: CoordinatesDTO;
    city?: AddressComponentDTO;
    administrativeAreaLevel3?: AddressComponentDTO;
    administrativeAreaLevel2?: AddressComponentDTO;
    administrativeAreaLevel1?: AddressComponentDTO;
    country: AddressComponentDTO;
    utcOffsetMinutes: number;
}

export const DefaultLocationDetailsDTO = {
    locationId: '',
    description: '',
    geometry: { latitude: 0, longitude: 0 },
    city: DefaultAddressComponentDTO,
    administrativeAreaLevel3: undefined,
    administrativeAreaLevel2: undefined,
    administrativeAreaLevel1: undefined,
    country: DefaultAddressComponentDTO,
    utcOffsetMinutes: 0,
};
