import { JobAdSkillsDTO } from './JobAdSkillsDTO';

export interface WriteJobAdDTO {
    title: string;
    description: string;
    skills: JobAdSkillsDTO[];
    companyDescription: string;
}

export const DefaultWriteJobAdDTO: WriteJobAdDTO = {
    title: '',
    description: '',
    skills: [],
    companyDescription: '',
};
