import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Button, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';

function CandidateInputFormHeader(): JSX.Element {
    const theme = useTheme();
    const { setPreviewInvitationEmailDialogOpen } = useInviteCandidateContext();

    return (
        <Stack direction="row" spacing={1.25}>
            <Box>
                <Typography variant="h4" component="span" color={theme.palette.text.menuTitle}>
                    {UIStrings.InvitationEmail}
                </Typography>
                <Typography
                    variant="h4"
                    component="span"
                    color={theme.palette.text.menuTitle}
                    sx={{ fontWeight: theme.typography.body1.fontWeight }}
                >
                    {` - ${UIStrings.UsesTalentMeshBestPractices} `}
                </Typography>
            </Box>
            <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setPreviewInvitationEmailDialogOpen(true)}
            >
                {UIStrings.Preview}
            </Button>
        </Stack>
    );
}

export default CandidateInputFormHeader;
