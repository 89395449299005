import React from 'react';
import { TMCircularProgress, Stack, PercentileLevel } from '@talentmesh/core';
import ScoreConnector from './ScoreConnector';

interface TestTypeCellProps {
    connectorLeft?: boolean;
    connectorRight?: boolean;
    score: number | undefined;
    level: PercentileLevel;
}

function TestTypeCell({ connectorLeft, connectorRight, score, level }: TestTypeCellProps): JSX.Element {
    return (
        <>
            {connectorLeft ? <ScoreConnector /> : <Stack flex="0.5" />}
            <TMCircularProgress
                size={52}
                thickness={2}
                level={level}
                progress={score}
                typographyProps={{ variant: 'h6' }}
            />
            {connectorRight ? <ScoreConnector /> : <Stack flex="0.5" />}
        </>
    );
}

export default TestTypeCell;
