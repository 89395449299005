import { useTheme } from '@mui/material';
import React from 'react';
import { Box, SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { useActionDialogContext } from '../../Context/ActionDialogContext';

function HireDialogHeader(): JSX.Element {
    const theme = useTheme();
    const { selectedCandidates } = useActionDialogContext();
    const { name } = selectedCandidates[0];
    return (
        <Box>
            <SpanTypography variant="h3" color={theme.palette.text.menuTitle}>
                {`${UIStrings.HaveYouHired} `}
            </SpanTypography>
            <SpanTypography variant="h3" color={theme.palette.text.menuTitle}>
                {`${name}?`}
            </SpanTypography>
        </Box>
    );
}

export default HireDialogHeader;
