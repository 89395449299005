import { Box, SpanTypography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';
import ConfirmationDialog from './ConfirmationDialog';
import { useTeamMembersContext } from '../../Context/TeamMembersContext';
import { useNotificationContext } from '../../../../../../Context/NotificationContext';

const TeamMemberRemoveDialog = () => {
    const { removeMember, activeDialog, closeDialog, selectedMember } = useTeamMembersContext();
    const { showFailToaster } = useNotificationContext();

    const handleClose = () => closeDialog();
    const handleConfirm = async () => {
        try {
            await removeMember(selectedMember!);
            handleClose();
        } catch (e) {
            showFailToaster();
        }
    };

    return (
        <ConfirmationDialog
            open={activeDialog === 'RemoveAccessConfirmation'}
            title={UIStrings.RemoveMemberAccess}
            actionButtonText={UIStrings.RemoveAccess}
            onClose={handleClose}
            onConfirm={handleConfirm}
        >
            <Box>
                <SpanTypography variant="body1">
                    {`${UIStrings.AreYouSureYouWantToRemoveTheAccessForMemberName} `}
                </SpanTypography>
                <SpanTypography variant="body1" fontWeight="bold">
                    {`${selectedMember?.firstName} ${selectedMember?.lastName}`}
                </SpanTypography>
            </Box>
        </ConfirmationDialog>
    );
};

export default TeamMemberRemoveDialog;
