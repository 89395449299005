import { useTheme } from '@mui/material';
import React from 'react';
import { Grid } from '@talentmesh/core';
import { Characteristic } from '../../../../../../../../../../Models/Characteristic';
import PersonalityCharacteristicHeader from './Components/PersonalityCharacteristicHeader';
import PersonalityCharacteristicItem from './Components/PersonalityCharacteristicItem';

export interface CharacteristicsPanelProps {
    characteristics: Characteristic[];
}

function PersonalityCharacteristicsPanel({ characteristics }: CharacteristicsPanelProps): JSX.Element {
    const theme = useTheme();

    return (
        <Grid container sx={{ paddingTop: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            <PersonalityCharacteristicHeader />
            {characteristics.map((characteristic) => (
                <PersonalityCharacteristicItem key={characteristic.characteristicName} {...characteristic} />
            ))}
        </Grid>
    );
}

export default PersonalityCharacteristicsPanel;
