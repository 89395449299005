import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button } from '@talentmesh/core';
import { FilterAlt } from '@talentmesh/icons';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';

function ToolbarFilterButton(): JSX.Element {
    const theme = useTheme();
    const { openFilter, toggleOpenFilter } = useCandidatesOverviewContext();

    const activeColor = openFilter ? theme.palette.primary.main : theme.palette.secondary.main;

    return (
        <Button
            size="small"
            onClick={toggleOpenFilter}
            sx={{
                height: theme.spacing(5),
                minWidth: theme.spacing(5.5),
                color: activeColor,
                backgroundColor: openFilter ? theme.palette.action.hover : 'inherit',

                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: activeColor,
                },
            }}
            data-cy="ToolbarFilterButton"
        >
            <FilterAlt />
        </Button>
    );
}

export default ToolbarFilterButton;
