import { styled } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { ReactNode } from 'react';
import { NotificationProvider } from '../../Context/NotificationContext';
import NotificationToaster from './NotificationToaster';

export interface NotificationSystemProps {
    children: ReactNode;
}

const SnackbarStyled = styled(SnackbarProvider)`
    &.SnackbarItem-variantSuccess {
        background: ${(p) => p.theme.palette.success.main};
    }
    &.SnackbarItem-variantError {
        background: ${(p) => p.theme.palette.error.main};
    }
    &.SnackbarItem-variantWarning {
        background: ${(p) => p.theme.palette.warning.main};
    }
`;

function NotificationSystem({ children }: NotificationSystemProps): JSX.Element {
    return (
        <SnackbarStyled
            maxSnack={4}
            autoHideDuration={6000}
            sx={{
                width: '357px',
            }}
        >
            <NotificationProvider>
                {children}
                <NotificationToaster />
            </NotificationProvider>
        </SnackbarStyled>
    );
}

export default NotificationSystem;
