import { useTheme } from '@mui/material';
import React from 'react';
import { Stack } from '@talentmesh/core';
import BaseTestCardDuration, { BaseTestCardDurationProps } from './BaseTestCardDuration';
import BaseTestCardTagCloud, { BaseTestCardTagCloudProps } from './BaseTestCardTagCloud';

export interface BaseTestCardContentProps extends BaseTestCardTagCloudProps, BaseTestCardDurationProps {}

function BaseTestCardContent({
    testType,
    topics,
    totalTopicsCount,
    duration,
    isPreviewQuestionsLoading,
    toggleDialog,
}: BaseTestCardContentProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            spacing={2}
            sx={{
                padding: theme.spacing(1.25),
                justifyContent: 'space-between',
                height: '100%',
            }}
        >
            <BaseTestCardTagCloud topics={topics} totalTopicsCount={totalTopicsCount} testType={testType} />
            <BaseTestCardDuration
                toggleDialog={toggleDialog}
                isPreviewQuestionsLoading={isPreviewQuestionsLoading}
                duration={duration}
            />
        </Stack>
    );
}

export default BaseTestCardContent;
