import React from 'react';
import { Divider, Label, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import UIStrings from '../../../../../../Utils/UIStrings';
import SkillsSelector from './SkillsSelector';
import AutocompleteOptionType from '../../../../../../Models/AutocompleteOptionType';

function RequiredSkills(): JSX.Element {
    const theme = useTheme();

    return (
        <Stack spacing={theme.spacing(1.25)}>
            <Label>{UIStrings.SkillsRequiredToSucceed}</Label>
            <Stack
                sx={{
                    borderRadius: theme.spacing(1.25),
                    border: `1px solid ${theme.palette.border.main}`,
                    borderWidth: '1px',
                    backgroundColor: theme.palette.background.paper,
                }}
                display="flex"
                flexDirection="column"
            >
                <SkillsSelector
                    type={AutocompleteOptionType.HardSkill}
                    label={UIStrings.HardSkills}
                    placeholder={UIStrings.AddSkills}
                    tooltip={UIStrings.ThisSectionIncludesTheSkillRequiredToSucceed('hard skills')}
                />
                <Divider
                    sx={{
                        border: `1px solid ${theme.palette.border.main}`,
                        borderWidth: '1px',
                    }}
                />
                <SkillsSelector
                    type={AutocompleteOptionType.SoftSkill}
                    label={UIStrings.SoftSkillsPersonalityTraits}
                    placeholder={UIStrings.TypePersonalityTraits}
                    tooltip={UIStrings.ThisSectionIncludesTheSkillRequiredToSucceed(
                        'soft skills and personality traits',
                    )}
                />
            </Stack>
        </Stack>
    );
}

export default RequiredSkills;
