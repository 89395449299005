import { Autocomplete, styled, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FormControl, WorkArrangements } from '@talentmesh/core';
import { useField } from 'formik';
import GoogleAutocompletePaper from './GoogleAutocompletePaper';
import LocationPrediction from '../../../../../../../Models/LocationPrediction';
import LocationOption from './LocationOption';
import UIStrings from '../../../../../../../Utils/UIStrings';
import JobLocationTextField from './JobLocationTextField';
import parseWithKey from './Utils/parseWithKey';
import convertGooglePlaceToLocationDetails from './Utils/convertGooglePlaceToLocationDetails';
import convertGoogleAutocompletePredictionsToLocationPredictions from './Utils/convertGoogleAutocompletePredictionsToLocationPredictions';
import FormikAutocompleteLocationState, {
    DefaultFormikAutocompleteLocationState,
} from './FormikAutocompleteLocationState';
import { JobDetailsFieldTypes } from '../../JobDetailsStepUtils';
import LocationConsts from './Utils/LocationConsts';

interface FormikAutocompleteLocationProps {
    label: string | JSX.Element;
}

let autocompleteService: google.maps.places.AutocompleteService;
let placesService: google.maps.places.PlacesService;
let sessionToken: google.maps.places.AutocompleteSessionToken;

function FormikAutocompleteLocation({ label }: FormikAutocompleteLocationProps): JSX.Element {
    const theme = useTheme();
    const [field, meta, helpers] = useField(JobDetailsFieldTypes.JobLocation);
    const [{ value: workArrangement }] = useField<WorkArrangements>(JobDetailsFieldTypes.WorkArrangement);
    const [previousWorkArrangement, setPreviousWorkArrangement] = useState(workArrangement);
    const isRemote = useMemo(() => workArrangement === 'Remote', [workArrangement]);

    const [state, setState] = useState<FormikAutocompleteLocationState>({
        ...DefaultFormikAutocompleteLocationState,
        value: field.value
            ? ({
                  locationId: field.value.locationId,
                  description: field.value.description,
                  mainText: field.value.description,
                  secondaryText: '',
                  mainTextMatchedSubstrings: undefined,
              } as LocationPrediction)
            : null,
        inputValue: field.value?.description || '',
    });

    const { value, inputValue, options, open } = state;

    const LocationGoogleAutocompletePaper = useMemo(
        () => styled(GoogleAutocompletePaper)(() => ({ '&': { marginTop: theme.spacing(0.5) } })),
        [theme],
    );

    const handleInputChange = (_: React.SyntheticEvent, newInputValue: string, reason: string) => {
        switch (reason) {
            case 'input':
                if (newInputValue.length === 0) {
                    setState(DefaultFormikAutocompleteLocationState);
                    helpers.setValue(null);
                } else {
                    setState((prev) => {
                        return {
                            ...prev,
                            inputValue: newInputValue,
                            open: true,
                        };
                    });
                }
                break;
            case 'reset':
                setState((prev) => {
                    return {
                        ...prev,
                        open: false,
                    };
                });

                if (!meta.touched) {
                    helpers.setTouched(true);
                }
                break;
            case 'clear':
            default:
                setState(DefaultFormikAutocompleteLocationState);
                helpers.setValue(null);
                break;
        }
    };

    // reset location if we're going in/out full remote
    useEffect(() => {
        if (
            (workArrangement === 'Remote' && previousWorkArrangement !== 'Remote') ||
            (workArrangement !== 'Remote' && previousWorkArrangement === 'Remote')
        ) {
            setState(DefaultFormikAutocompleteLocationState);
        }

        setPreviousWorkArrangement(workArrangement);
    }, [workArrangement]);

    useEffect(() => {
        // get predictions on inputValueChange
        let active = true;

        if (!autocompleteService && (window as any).google) {
            autocompleteService = new google.maps.places.AutocompleteService();
        }
        if (!autocompleteService) {
            return undefined;
        }

        if (!sessionToken && (window as any).google) {
            sessionToken = new google.maps.places.AutocompleteSessionToken();
        }
        if (!sessionToken) {
            return undefined;
        }

        if (inputValue === '') {
            return undefined;
        }

        const request = {
            input: inputValue,
            types: isRemote ? ['(regions)'] : ['(cities)'],
            language: LocationConsts.Language,
            sessionToken,
        };
        autocompleteService.getPlacePredictions(request, (results?: google.maps.places.AutocompletePrediction[]) => {
            if (active && results) {
                setState((prev) => {
                    return {
                        ...prev,
                        options: convertGoogleAutocompletePredictionsToLocationPredictions(results),
                    };
                });
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue]);

    const onChangeHandler = (event: React.SyntheticEvent, newValue: any) => {
        // get location details
        if (newValue === null) {
            setState(DefaultFormikAutocompleteLocationState);
            return;
        }

        if (!placesService && (window as any).google) {
            placesService = new google.maps.places.PlacesService(document.createElement('div'));
        }

        const request = {
            placeId: newValue.locationId,
            fields: ['place_id', 'address_components', 'geometry.location', 'utc_offset_minutes'],
            language: LocationConsts.Language,
            sessionToken,
        };
        placesService.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                helpers.setTouched(true);
                const locationDetails = convertGooglePlaceToLocationDetails(place);
                helpers.setValue(locationDetails);
                setState((prev) => {
                    return {
                        ...prev,
                        value: {
                            ...newValue,
                            description: locationDetails.description,
                        },
                        open: false,
                    };
                });
            } else {
                setState(DefaultFormikAutocompleteLocationState);
                helpers.setValue(null);
            }
        });
        sessionToken = new google.maps.places.AutocompleteSessionToken();
    };

    return (
        <FormControl fullWidth error={meta.error != null} variant="outlined">
            <Autocomplete
                id="JobLocation"
                fullWidth
                forcePopupIcon={false}
                loading={options.length === 0}
                loadingText={UIStrings.Loading}
                noOptionsText={UIStrings.NoLocations}
                value={value}
                options={options}
                getOptionLabel={(option) => option.description}
                filterOptions={(x) => x}
                onInputChange={handleInputChange}
                onChange={onChangeHandler}
                PaperComponent={LocationGoogleAutocompletePaper}
                renderInput={(params) => <JobLocationTextField params={params} label={label} />}
                open={open}
                renderOption={(props, option) => {
                    const matches = option.mainTextMatchedSubstrings || [];

                    const parts = parseWithKey(
                        option.mainText,
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                    );

                    return <LocationOption key={option.locationId} props={props} parts={parts} option={option} />;
                }}
            />
        </FormControl>
    );
}

export default FormikAutocompleteLocation;
