import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogProps, Paper, Stack, StatusChip, Typography } from '@talentmesh/core';
import { ImportantIcon, ReportProblemOutlinedIcon } from '@talentmesh/icons';
import UIStrings from '../../../Utils/UIStrings';

interface ConfirmDialogProps extends DialogProps {
    onOk: (event: React.SyntheticEvent) => void;
    onCancel: (event: React.SyntheticEvent) => void;
}

function ConfirmDialog({ onOk, onCancel, ...props }: ConfirmDialogProps): JSX.Element {
    const theme = useTheme();

    return (
        <Dialog
            {...props}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    maxWidth: 'unset',
                    width: 'auto',
                    height: 'auto',
                },
            }}
        >
            <Paper sx={{ width: theme.spacing(67) }} elevation={1}>
                <Stack
                    sx={{
                        padding: 3,
                    }}
                    spacing={2}
                >
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Stack spacing={2}>
                            <ImportantIcon sx={{ width: theme.spacing(16) }} />
                            <Typography variant="h3" color={theme.palette.text.menuTitle}>
                                {UIStrings.ReadyToCloseThisRecruitment}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            border: `1px solid ${theme.palette.border.main}`,
                            borderRadius: theme.spacing(2.5),
                            padding: theme.spacing(1.5),
                        }}
                    >
                        <StatusChip status="warning" label="Important" icon={<ReportProblemOutlinedIcon />} />
                        <Typography gutterBottom variant="body2">
                            {UIStrings.CloseRecruitmentProceed1}
                        </Typography>
                        <Typography variant="body2">{UIStrings.CloseRecruitmentProceed2}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Button fullWidth variant="outlined" color="secondary" onClick={onCancel}>
                            {UIStrings.KeepRecruitmentOpen}
                        </Button>
                        <Button fullWidth variant="contained" color="primary" onClick={onOk}>
                            {UIStrings.CloseRecruitment}
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Dialog>
    );
}

export default ConfirmDialog;
