import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@talentmesh/core';

export interface MultiLineHeaderProps {
    header: string;
}
export const MultiLineHeader = ({ header }: MultiLineHeaderProps) => {
    const theme = useTheme();
    return (
        <Stack
            direction="column"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
            }}
        >
            {header.split(' ').map((h) => (
                <Typography
                    key={h}
                    variant="button"
                    color={theme.palette.text.primary}
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {h}
                </Typography>
            ))}
        </Stack>
    );
};
