import { CandidateInviteDTO } from '../DTO/Users/CandidateInviteDTO';
import { Candidate, candidateFromCandidateInviteDTO } from './Candidate';

export interface CandidateRowEntry extends Candidate {
    isReadOnly?: boolean;
}

export function candidateRowEntryFromCandidateInviteDTO(
    dto: CandidateInviteDTO,
    isReadOnly?: boolean,
): CandidateRowEntry {
    return {
        ...candidateFromCandidateInviteDTO(dto),
        isReadOnly,
    };
}
