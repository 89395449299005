import { LocationDescriptor, LocationState, Path } from 'history';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router';
import useCompanyId from '../Hooks/UseCompanyId';

// Custom hook that wraps the useNavigate hook
function useTalentMeshHistory() {
    const navigate = useNavigate();
    const companyId = useCompanyId();

    const buildLocation = (path: Path | LocationDescriptor<LocationState>) => {
        if (typeof path === 'string') {
            return generatePath(path, { companyId });
        }

        if (path.pathname) {
            return { ...path, pathname: generatePath(path.pathname, { companyId }) };
        }
        return path;
    };

    // Wrap the push function to include the companyId
    const push = (path: Path | LocationDescriptor<LocationState>, state?: any) => {
        const p = buildLocation(path);
        navigate(p, { state });
    };

    // Wrap the replace function to include the companyId
    const replace = (path: Path | LocationDescriptor<LocationState>, state?: any) => {
        const p = buildLocation(path);
        navigate(p, { state, replace: true });
    };

    const goBack = useCallback(() => navigate(-1), [navigate]);

    return { push, replace, goBack };
}

export default useTalentMeshHistory;
