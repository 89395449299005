import { Candidate } from '../../Models/Candidate';

export interface CandidateInviteDTO {
    firstName: string;
    lastName: string;
    email: string;
    isInternal: boolean;
    candidateId: string;
}

export function inviteCandidateDTOFromCandidate(model: Candidate): CandidateInviteDTO {
    return {
        firstName: model.firstName,
        lastName: model.lastName,
        email: model.email,
        isInternal: model.isInternal,
        candidateId: model.id,
    } as CandidateInviteDTO;
}
