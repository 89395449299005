import { LoadingOverlay } from '@talentmesh/core';
import React, { useEffect, useState } from 'react';
import { useInvitationClient } from '../../Hooks/ClientHooks';
import useQuery from '../../Hooks/UseQuery';
import InvitationExpiredError from '../../Utils/HTTP/Errors/InvitationExpiredError';
import RoutePath from '../../Routing/RoutePath';
import ErrorQueryNames from '../Errors/ErrorQueryNames';
import UIStrings from '../../Utils/UIStrings';
import InvitationAcceptedError from '../../Utils/HTTP/Errors/InvitationAcceptedError';
import useTalentMeshHistory from '../../Routing/useTalentMeshHistory';

function FetchInvitationPage() {
    const client = useInvitationClient();
    const [link, setLink] = useState<string | null>(null);
    const token = useQuery('token');
    const history = useTalentMeshHistory();

    const redirectToErrorPage = (title: string, description: string, loginEnabled: boolean) => {
        const params = new URLSearchParams();
        params.append(ErrorQueryNames.ErrorTitle, title);
        params.append(ErrorQueryNames.ErrorDescription, description);
        if (loginEnabled) {
            params.append(ErrorQueryNames.LoginAvailable, 'true');
        }
        history.replace({
            pathname: RoutePath.Error,
            search: params.toString(),
        });
    };

    useEffect(() => {
        const doFetchLink = async () => {
            try {
                const response = await client.fetchInvitationLink(token!);
                setLink(response);
            } catch (error: any) {
                if (error instanceof InvitationExpiredError) {
                    redirectToErrorPage(UIStrings.LinkExpired, UIStrings.LinkExpiredMessage, false);
                }

                if (error instanceof InvitationAcceptedError) {
                    redirectToErrorPage(UIStrings.LinkAlreadyRedeemed, UIStrings.LinkAlreadyRedeemedMessage, true);
                }

                if (error.status === 404) {
                    redirectToErrorPage(UIStrings.LinkNotFound, UIStrings.LinkNotFoundMessage, false);
                }
            }
        };

        doFetchLink();
    }, []);

    if (link != null) {
        // because link is most likely a url outside of recruiter app, we need to use browser API directly to navigate there
        window.location.href = link;
    }

    return <LoadingOverlay />;
}

export default FetchInvitationPage;
