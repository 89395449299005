import React from 'react';
import { Typography } from '@talentmesh/core';
import Compensation from '../CompensationSection/Compensation';
import DetailsCard from '../../../Components/Payment/DetailsCard';
import UIStrings from '../../../Utils/UIStrings';
import { PaymentIntent } from '../../../Models/PaymentIntent';
import PriceBreakdown from './PriceBreakdown';
import CheckoutSectionTitle from '../Components/CheckoutSectionTitle';
import { getAmountWithCurrencySymbol } from '../../../Utils/Amounts/AmountHelpers';

interface CheckoutDetailsProps {
    compensation: Compensation | undefined;
    paymentIntent: PaymentIntent | undefined;
}

const CheckoutDetails = ({ compensation, paymentIntent }: CheckoutDetailsProps) => {
    let fullCompensation = '0';
    if (
        compensation &&
        compensation.annualFixedAmount !== '' &&
        compensation.annualTargetBonus !== '' &&
        compensation.currency.id !== ''
    ) {
        const totalCompensation =
            parseInt(compensation.annualFixedAmount, 10) + parseInt(compensation.annualTargetBonus, 10);
        fullCompensation = getAmountWithCurrencySymbol(totalCompensation, compensation.currency.id);
    }

    const serviceFeePercentage = paymentIntent?.serviceFeePercentage || 0;

    return (
        <>
            <CheckoutSectionTitle title={UIStrings.Invoice} />
            <DetailsCard>
                <Typography variant="body2">{`${UIStrings.Description}:`}</Typography>
                {compensation && (
                    <Typography variant="body2">{UIStrings.OneHire(fullCompensation, serviceFeePercentage)}</Typography>
                )}
            </DetailsCard>
            <PriceBreakdown paymentIntent={paymentIntent} />
        </>
    );
};

export default CheckoutDetails;
