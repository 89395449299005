import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import { Box, Divider, LoadingButton } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import * as yup from 'yup';
import { useNotificationContext } from '../../../Context/NotificationContext';
import { useUserContext } from '../../../Context/UserContext';
import User from '../../../Models/User';
import UIStrings from '../../../Utils/UIStrings';
import ContactSupportSection from './ContactSupportSection';

interface AccountInfoFormInitialValues {
    firstName: string;
    lastName: string;
}

const AccountInfoForm = () => {
    const theme = useTheme();
    const { user, updateUser } = useUserContext();
    const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    const initialValues: AccountInfoFormInitialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
    };

    const validationSchema = yup.object({
        firstName: yup.string().required(UIStrings.Required),
        lastName: yup.string().required(UIStrings.Required),
    });

    const submitHandler = async (values: AccountInfoFormInitialValues) => {
        try {
            setSavingInProgress(true);
            const updatedUser: User = {
                ...user,
                ...values,
            };
            await updateUser(updatedUser);
            showSuccessToaster(UIStrings.AccountDataSuccessfullyUpdated);
        } catch (error) {
            showFailToaster();
        } finally {
            setSavingInProgress(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => submitHandler(values)}
            validateOnChange={false}
            validateOnBlur={false}
        >
            <Form>
                <Box sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    <Stack spacing={3} width="50%">
                        <Stack direction="row" spacing={3}>
                            <FormikTextField
                                id="firstName"
                                name="firstName"
                                type="text"
                                label={UIStrings.FirstName}
                                fullWidth
                            />
                            <FormikTextField
                                id="lastName"
                                name="lastName"
                                type="text"
                                label={UIStrings.LastName}
                                fullWidth
                            />
                        </Stack>
                    </Stack>
                    <LoadingButton
                        sx={{
                            marginTop: theme.spacing(5),
                        }}
                        size="large"
                        loading={savingInProgress}
                        variant="contained"
                        type="submit"
                    >
                        {UIStrings.Save}
                    </LoadingButton>
                    <Divider
                        sx={{
                            marginTop: theme.spacing(5),
                            marginBottom: theme.spacing(5),
                            borderColor: theme.palette.border.main,
                        }}
                    />
                </Box>
                <ContactSupportSection />
            </Form>
        </Formik>
    );
};

export default AccountInfoForm;
