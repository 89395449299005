// TODO: https://dev.azure.com/TalentMesh/TalentMesh/_sprints/taskboard/TalentMesh%20Team/TalentMesh/Sprint%2010?workitem=726
// eslint-disable-next-line import/prefer-default-export
export enum AssessmentStatuses {
    NonPublic = 'NonPublic',
    Public = 'Public',
    Closed = 'Closed',
}

export function mapAssessmentStatus(value: any): AssessmentStatuses {
    switch (value) {
        case 1:
            return AssessmentStatuses.NonPublic;
        case 2:
            return AssessmentStatuses.Public;
        case 3:
            return AssessmentStatuses.Closed;
        default:
            return value as AssessmentStatuses;
    }
}
