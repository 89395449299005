import { JobTestInfoDTO } from '../../../../DTO/Questions/JobTestInfoDTO';
import { TestTypes } from '../../../../Models/Configuration';
import autoMapping from '../../../../Utils/autoMapping';
import getTestDuration from '../Utils/getTestDuration';

export default interface TestInfo {
    jobFunctionName: string;
    duration: number;
    totalTopicsCount: number;
    testType: TestTypes;
    totalQuestions: number;
    topics: Array<string>;
}

const DefaultTestInfo: TestInfo = {
    jobFunctionName: '',
    testType: TestTypes.Personality,
    topics: [],
    totalQuestions: 0,
    totalTopicsCount: 0,
    duration: 0,
};

export function mapTestInfoDto2Model(dto: JobTestInfoDTO): TestInfo {
    const model = autoMapping(dto, DefaultTestInfo);

    const { topTopics, totalQuestionsCount } = dto;

    model.duration = getTestDuration(dto);
    model.topics = topTopics;
    model.totalQuestions = totalQuestionsCount;

    return model;
}
