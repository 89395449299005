import React from 'react';
import { BulletedList, BulletedListItem, SpanTypography, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import UIStrings from '../../../../../../Utils/UIStrings';

const NonPublicOptionDescription = (): JSX.Element => {
    const theme = useTheme();
    return (
        <BulletedList style={{ margin: 0 }}>
            <Stack spacing={1.25}>
                <BulletedListItem>
                    <SpanTypography variant="body1" color={theme.palette.text.menuTitle}>
                        {UIStrings.NonPublicCreatePoint1}
                    </SpanTypography>
                    <SpanTypography variant="body1" fontWeight="bold" color={theme.palette.text.menuTitle}>
                        {UIStrings.NonPublicCreatePoint1Bold}
                    </SpanTypography>
                    <SpanTypography variant="body1" color={theme.palette.text.menuTitle}>
                        .
                    </SpanTypography>
                </BulletedListItem>
                <BulletedListItem>
                    <SpanTypography variant="body1" color={theme.palette.text.menuTitle}>
                        {UIStrings.NonPublicCreatePoint2}
                    </SpanTypography>
                </BulletedListItem>
            </Stack>
        </BulletedList>
    );
};

export default NonPublicOptionDescription;
