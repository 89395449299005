import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { getAmountWithCurrencySymbol } from '../../../Utils/Amounts/AmountHelpers';

interface PriceEntryProps {
    description: string;
    amount: number;
    currency: string;
}

const PriceEntry = ({ description, amount, currency }: PriceEntryProps) => {
    const theme = useTheme();
    return (
        <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" color={theme.palette.text.menuTitle}>
                {description}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.menuTitle}>
                {getAmountWithCurrencySymbol(amount, currency, 'symbolOnly')}
            </Typography>
        </Stack>
    );
};

export default PriceEntry;
