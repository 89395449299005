import React, { useState, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import AccordionItem from './AccordionItem';
import ResendEmailPanel from './ResendEmailPanel';
import VerifiedOnMobileInstructions from './VerifiedOnMobileInstructions';
import WrongEmailInstructions from './WrongEmailInstructions';

interface CommonProblemItem {
    problem: string;
    solution: NonNullable<ReactNode>;
}

const commonProblems: Array<CommonProblemItem> = [
    {
        problem: UIStrings.IHaveNotReceivedTheEmail,
        solution: <ResendEmailPanel />,
    },
    {
        problem: UIStrings.IHaveUsedWrongEmailToSignup,
        solution: <WrongEmailInstructions />,
    },
    {
        problem: UIStrings.IHaveVerifiedOnMobile,
        solution: <VerifiedOnMobileInstructions />,
    },
];

function AccordionPanel(): JSX.Element {
    const theme = useTheme();
    const [expanded, setExpanded] = useState<number>(-1);

    const handleChange = (accordionKey: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? accordionKey : -1);
    };

    return (
        <Stack
            sx={{ paddingX: theme.spacing(2.5), paddingBottom: theme.spacing(2.5), paddingTop: theme.spacing(4.375) }}
        >
            <Typography
                variant="body2"
                sx={{
                    color: theme.palette.text.menuTitle,
                    fontWeight: theme.typography.fontWeightBold,
                    marginX: theme.spacing(1.25),
                }}
            >
                {UIStrings.HavingIssues}
            </Typography>
            {commonProblems.map((item, index) => (
                <AccordionItem
                    key={item.problem}
                    header={item.problem}
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                >
                    {item.solution}
                </AccordionItem>
            ))}
        </Stack>
    );
}

export default AccordionPanel;
