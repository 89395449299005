import { RHFTextField } from '@talentmesh/forms';
import React from 'react';

export interface CriteriaTextAreaInputProps {
    name?: string;
    disabled?: boolean;
    commentary?: string;
}

const CriteriaTextAreaInput = ({ name = '', commentary, disabled }: CriteriaTextAreaInputProps) => {
    return <RHFTextField name={name} fullWidth multiline rows={4} commentary={commentary} disabled={disabled} />;
};

export default CriteriaTextAreaInput;
