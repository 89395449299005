import React, { ReactNode, useContext, createContext, useState, useEffect, useCallback } from 'react';
import { AdvancedFilter, AdvancedFilterTypes, JobAdDTO, JobAdsPagination } from '@talentmesh/builder-components';
import Page, { EmptyPage } from '@talentmesh/core/dist/cjs/Models/Page';
import { useBlocker } from 'react-router';
import {
    CareerPage,
    mapCareerPageToCareerPageDTO,
    mapCareerImageDTOToCareerImage,
    mapCareerPageDTOToCareerPage,
} from '../../../Models/Career';
import { useCareerClient } from '../../../Hooks/ClientHooks';
import { useNotificationContext } from '../../../Context/NotificationContext';
import UIStrings from '../../../Utils/UIStrings';
import useCompanyId from '../../../Hooks/UseCompanyId';
import { useCompanyContext } from '../../../Context/CompanyContext';

export interface CareerBuilderState {
    loading: boolean;
    isDirty: boolean;
    isDraftEmpty: boolean;
    draft: CareerPage;
    showLeaveDialog: boolean;
    showPublishedSuccessFullyDialog: boolean;
}

export interface CareerBuilderExternalState {}

export interface CareerBuilderHelpers {
    setDirty: (value: boolean) => void;
    handleSaveDraftAsync: (value: CareerPage) => Promise<void>;
    handlePublishAsync: (value: CareerPage) => Promise<void>;
    handleUploadImageAsync: (blob: Blob) => Promise<string>;
    handleGetJobAdsOverviewAsync: (pagination: JobAdsPagination) => Promise<Page<JobAdDTO>>;
    processingImageErrorCallback: () => void;
    handleGetCountryFiltersAsync: () => Promise<AdvancedFilter[]>;
    handleGetJobCategoryFiltersAsync: () => Promise<AdvancedFilter[]>;
    handleBuilderDirtyLeave: () => void;
    handleBuilderDirtyStay: () => void;
    closedPublishedSuccessFullyDialog: () => void;
}

export interface CareerBuilderContextState extends CareerBuilderState {}

export interface CareerBuilderContextProps
    extends CareerBuilderContextState,
        CareerBuilderHelpers,
        CareerBuilderExternalState {}

export interface CareerBuilderContextProviderProps {
    children?: ReactNode;
}

export const CareerBuilderContext = createContext<CareerBuilderContextProps | undefined>(undefined);

export const useCareerBuilderContext = (): CareerBuilderContextProps => {
    const context = useContext(CareerBuilderContext);

    if (!context) {
        throw new Error('useCareerBuilderContext must be used within the CareerBuilderContext.Provider');
    }

    return context;
};

export const useCareerBuilderProvider = (): CareerBuilderContextProps => {
    const careerClient = useCareerClient();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();
    const { currentCompanyProfile } = useCompanyContext();
    const companyId = useCompanyId();

    const [state, setState] = useState<CareerBuilderContextState>({
        loading: true,
        isDirty: false,
        isDraftEmpty: false,
        draft: '',
        showLeaveDialog: false,
        showPublishedSuccessFullyDialog: false,
    });

    const { loading, isDirty, isDraftEmpty, draft, showLeaveDialog, showPublishedSuccessFullyDialog } = state;
    const { companySlug } = currentCompanyProfile;

    const closedPublishedSuccessFullyDialog = () => {
        setState((prev) => {
            return {
                ...prev,
                showPublishedSuccessFullyDialog: false,
            };
        });
    };

    const handleGetDraftAsync = async (): Promise<void> => {
        let result = '';

        try {
            const dto = await careerClient.getCareerPageDraftAsync(companyId);

            result = mapCareerPageDTOToCareerPage(dto);
        } catch {
            setState((prev) => {
                return {
                    ...prev,
                    isDraftEmpty: true,
                };
            });
        } finally {
            setState((prev) => {
                return {
                    ...prev,
                    draft: result,
                    loading: false,
                };
            });
        }
    };

    const handleSaveDraftAsync = async (value: CareerPage): Promise<void> => {
        let error = false;

        try {
            await careerClient.saveCareerPageDraftAsync(mapCareerPageToCareerPageDTO(value, companyId));
        } catch {
            error = true;

            showFailToaster(UIStrings.CareerErrorSaveOrPublish);
        } finally {
            if (!error) {
                showSuccessToaster(UIStrings.TheChangesHaveBeenSavedSuccessfully);

                setState((prev) => {
                    return {
                        ...prev,
                        isDirty: false,
                    };
                });
            }
        }
    };

    const handlePublishAsync = async (value: CareerPage): Promise<void> => {
        let error = false;

        try {
            await careerClient.publishCareerPageAsync(mapCareerPageToCareerPageDTO(value, companyId));
        } catch {
            error = true;

            showFailToaster(UIStrings.CareerErrorSaveOrPublish);
        } finally {
            if (!error) {
                setState((prev) => {
                    return {
                        ...prev,
                        showPublishedSuccessFullyDialog: true,
                        isDirty: false,
                    };
                });
            }
        }
    };

    const handleUploadImageAsync = async (blob: Blob): Promise<string> => {
        const result = await careerClient.uploadCareerImageAsync(companyId, blob);

        return mapCareerImageDTOToCareerImage(result);
    };

    const setDirty = useCallback((value: boolean) => {
        setState((prev) => {
            return {
                ...prev,
                isDirty: value,
            };
        });
    }, []);

    const handleGetJobAdsOverviewAsync = useCallback(
        async (pagination: JobAdsPagination) => {
            const selectedLocationFilters = pagination.filters.country.filter((filter) => filter.selected);
            const selectedJobCategoryFilters = pagination.filters.workIndustry.filter((filter) => filter.selected);
            const filters = selectedLocationFilters.concat(selectedJobCategoryFilters);

            try {
                const response = await careerClient.getJobAdsOverviewAsync(
                    companySlug,
                    pagination.filters.title,
                    filters,
                    pagination.filters.isRemote,
                    pagination.page * pagination.limit,
                    pagination.limit,
                );

                return response;
            } catch (error) {
                return EmptyPage;
            }
        },
        [companySlug],
    );

    const handleGetCountryFiltersAsync = useCallback(async () => {
        return await careerClient.getAdvancedFilters(companySlug, AdvancedFilterTypes.Country);
    }, [companySlug]);

    const handleGetJobCategoryFiltersAsync = useCallback(async () => {
        return await careerClient.getAdvancedFilters(companySlug, AdvancedFilterTypes.JobCategory);
    }, [companySlug]);

    const processingImageErrorCallback = () => {
        showFailToaster(`${UIStrings.SomethingWentWrong}. ${UIStrings.TryAgainLater}`);
    };

    const blocker = useBlocker(() => isDirty);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            setState((prev) => ({
                ...prev,
                showLeaveDialog: true,
            }));
        }
    }, [blocker.state]);

    const handleBuilderDirtyLeave = () => {
        if (blocker.proceed) {
            blocker.proceed();
        }
    };

    const handleBuilderDirtyStay = () => {
        if (blocker.reset) {
            blocker.reset();
        }

        setState((prev) => {
            return {
                ...prev,
                showLeaveDialog: false,
            };
        });
    };

    useEffect(() => {
        handleGetDraftAsync();

        window.onbeforeunload = (event) => {
            event.preventDefault();

            if (isDirty) {
                setState((prev) => {
                    return {
                        ...prev,
                        showLeaveDialog: true,
                    };
                });

                return false;
            }

            return undefined;
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return {
        loading,
        isDirty,
        isDraftEmpty,
        draft,
        showLeaveDialog,

        handleSaveDraftAsync,
        handlePublishAsync,
        handleUploadImageAsync,

        handleGetJobAdsOverviewAsync,
        handleGetCountryFiltersAsync,
        handleGetJobCategoryFiltersAsync,

        setDirty,

        processingImageErrorCallback,

        handleBuilderDirtyLeave,
        handleBuilderDirtyStay,
        closedPublishedSuccessFullyDialog,
        showPublishedSuccessFullyDialog,
    };
};

export const CareerBuilderContextProvider = ({ children }: CareerBuilderContextProviderProps) => {
    const provider = useCareerBuilderProvider();

    return <CareerBuilderContext.Provider value={provider}>{children}</CareerBuilderContext.Provider>;
};
