import React from 'react';
import { useTheme } from '@mui/material';
import { Box, Stack, Container } from '@talentmesh/core';
import Header from './Components/Header';
import PageTabs from './Tabs/PageTabs';

function InviteCandidatesPageHeader(): JSX.Element {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.common.white,
                borderBottom: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Container disableGutters>
                <Stack
                    spacing={3.75}
                    sx={{
                        paddingTop: theme.spacing(2.5),
                    }}
                >
                    <Header />
                    <PageTabs />
                </Stack>
            </Container>
        </Box>
    );
}

export default InviteCandidatesPageHeader;
