import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, SpanTypography, Box, Link } from '@talentmesh/core';
import { siteUrls } from '../../../AppSettings';
import UIStrings from '../../../Utils/UIStrings';

function ContactSupportSection(): JSX.Element {
    const theme = useTheme();

    return (
        <Stack alignItems="start">
            <Box>
                <SpanTypography
                    variant="body2"
                    color={theme.palette.text.primary}
                >{`${UIStrings.ContactSupportSection} `}</SpanTypography>
            </Box>
            <Link
                href={siteUrls.contactSupportUrl}
                target="_blank"
                sx={{
                    marginTop: theme.spacing(2),
                    textDecoration: 'none',
                    '&:hover': {
                        color: theme.palette.text.primary,
                    },
                    '&:visited': {
                        color: theme.palette.text.primary,
                    },
                }}
                color={theme.palette.secondary.main}
                fontWeight={theme.typography.fontWeightBold}
            >
                {UIStrings.ContactSupport}
            </Link>
        </Stack>
    );
}
export default ContactSupportSection;
