import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Paper, PaperProps, Typography } from '@talentmesh/core';

export interface HeaderProps extends PaperProps {
    backgroundColor?: string;
    headerWidth?: string;
}

export function Header({ children, backgroundColor, headerWidth, ...props }: HeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            elevation={0}
            {...props}
            sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
                padding: theme.spacing(1.25, 2.5),
                width: headerWidth || 'fit-content',
            }}
        >
            <Typography color={theme.palette.common.white} variant="h5">
                {children}
            </Typography>
        </Paper>
    );
}
