import { Search } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import { useClientsOverviewContext } from '../../contexts/ClientsOverviewContext';

const ClientSearch = () => {
    const { setPagination, setClientFilters } = useClientsOverviewContext();

    const onSearch = (search: string) => {
        setPagination((p) => ({ ...p, offset: 0 }));
        setClientFilters((prev) => ({ ...prev, search }));
    };

    return <Search data-cy="clientSearch" onSearch={onSearch} placeholder={UIStrings.SearchClients} />;
};

export default ClientSearch;
