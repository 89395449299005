import { Theme } from '@mui/material';
import UIStrings from '../../../Utils/UIStrings';
import { TestTypes } from '../../../Models/Configuration';
import { CandidateStatuses } from '../../../Models/CandidateOverview';

export function statusToString(status: CandidateStatuses): string {
    return UIStrings[status];
}

export function statusToColor(status: CandidateStatuses, theme: Theme): string | undefined {
    switch (status) {
        case CandidateStatuses.Invited:
        case CandidateStatuses.AssessmentOpened:
        case CandidateStatuses.AssessmentOngoing:
        case CandidateStatuses.AssessmentCompleted:
        case CandidateStatuses.AssessmentRetaken:
            return theme.palette.grey[700];
        case CandidateStatuses.Hired:
            return theme.palette.common.white;
        case CandidateStatuses.Interviewing:
            return theme.palette.hue.green.h900;
        case CandidateStatuses.Rejected:
            return theme.palette.common.white;
        default:
            return theme.palette.chip.default;
    }
}

export function statusToBackgroundColor(status: CandidateStatuses, theme: Theme): string | undefined {
    switch (status) {
        case CandidateStatuses.Invited:
        case CandidateStatuses.AssessmentOpened:
        case CandidateStatuses.AssessmentOngoing:
        case CandidateStatuses.AssessmentCompleted:
        case CandidateStatuses.AssessmentRetaken:
            return theme.palette.grey[200];
        case CandidateStatuses.Hired:
            return theme.palette.hue.green.h700;
        case CandidateStatuses.Interviewing:
            return theme.palette.hue.green.h50;
        case CandidateStatuses.Rejected:
            return theme.palette.hue.red.h500;
        default:
            return theme.palette.chip.default;
    }
}

export const mainTestTypesRenderingOrder: Array<TestTypes> = [
    TestTypes.Personality,
    TestTypes.Aptitude,
    TestTypes.Skills,
    TestTypes.EmotionalIntelligence,
];
