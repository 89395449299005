import DialogActions from '@mui/material/DialogActions';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Dialog, DialogContent, LoadingButton, Stack } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import { useBillingInfoClient } from '../../../../Hooks/ClientHooks';
import BillingInfo, { billingInfoValidationScheme, EmptyBillingInfo } from '../../../../Models/BillingInfo';
import UIStrings from '../../../../Utils/UIStrings';
import CountrySelector from '../../../../Components/Address/CountrySelector';
import SettingsDialogTitle from './SettingsDialogTitle';
import useCompanyId from '../../../../Hooks/UseCompanyId';

interface EditBillingAddressDialogProps {
    open: boolean;
    onClose: () => void;
    billingInfo: BillingInfo | undefined;
    setBillingInfo: React.Dispatch<React.SetStateAction<BillingInfo | undefined>>;
}

const EditBillingAddressDialog = ({ open, billingInfo, onClose, setBillingInfo }: EditBillingAddressDialogProps) => {
    const client = useBillingInfoClient();
    const companyId = useCompanyId();
    const [loading, setLoading] = useState<boolean>(false);
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    const handleClose = () => {
        if (!loading) {
            onClose();
        }
    };

    const handleSubmit = async (values: BillingInfo) => {
        setLoading(true);
        try {
            const updatedBillingInfo = await client.saveBillingInfoAsync(companyId, values);
            setBillingInfo(updatedBillingInfo);
            onClose();
            showSuccessToaster(UIStrings.BillingDataSuccessfullyUpdated);
        } catch {
            showFailToaster();
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onBackdropClick={handleClose} onClose={handleClose}>
            <SettingsDialogTitle title={UIStrings.BillingAddress} />
            <Formik
                initialValues={billingInfo || EmptyBillingInfo}
                validationSchema={billingInfoValidationScheme}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Form>
                    <DialogContent sx={{ paddingTop: 0, paddingRight: 3.75, paddingBottom: 3.75, paddingLeft: 3.75 }}>
                        <Stack direction="column" spacing={2.5}>
                            <FormikTextField label={UIStrings.BillingTo} name="billingTo" />
                            <CountrySelector label={`${UIStrings.Country}*`} name="country" />
                            <FormikTextField label={UIStrings.AddressLine1} name="addressLine1" />
                            <FormikTextField label={UIStrings.AddressLine2} name="addressLine2" />
                            <Stack direction="row" spacing={2.5}>
                                <Stack sx={{ width: '100%' }} spacing={2.5}>
                                    <FormikTextField label={UIStrings.City} name="city" />
                                    <FormikTextField label={UIStrings.Zip} name="postalCode" />
                                </Stack>
                                <FormikTextField label={UIStrings.State} name="state" />
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ paddingTop: 0, paddingLeft: 3.75, paddingRight: 3.75, paddingBottom: 3.75 }}>
                        <Button fullWidth variant="outlined" onClick={handleClose} disabled={loading}>
                            {UIStrings.Cancel}
                        </Button>
                        <LoadingButton size="large" loading={loading} fullWidth variant="contained" type="submit">
                            {UIStrings.Save}
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default EditBillingAddressDialog;
