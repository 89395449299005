import { Typography } from '@talentmesh/core';
import React from 'react';

interface AllowanceColumnHeaderProps {
    headerName: string;
}

function AllowanceColumnHeader({ headerName }: AllowanceColumnHeaderProps): JSX.Element {
    return (
        <Typography variant="caption" sx={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign: 'center' }}>
            {headerName}
        </Typography>
    );
}

export default AllowanceColumnHeader;
