import React from 'react';
import { Box, CompanyLogo, Stack, theme, SpanTypography } from '@talentmesh/core';
import GoToDesktop from './GoToDesktop.svg';
import UIStrings from '../../Utils/UIStrings';

function GetFullExperiencePage(): JSX.Element {
    return (
        <Box sx={{ height: '100vh', overflowY: 'auto' }}>
            <Stack
                spacing={4}
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                sx={{ paddingBlock: theme.spacing(4), paddingInline: theme.spacing(2.5) }}
            >
                <CompanyLogo />
                <Box sx={{ textAlign: 'left' }}>
                    <SpanTypography variant="h2" color={theme.palette.text.menuTitle}>
                        {`${UIStrings.GetFull} `}
                        <SpanTypography variant="h2" color={theme.palette.primary.main}>
                            {UIStrings.TalentMeshExperience}
                        </SpanTypography>
                        {` ${UIStrings.OnYourDesktopBrowser}`}
                    </SpanTypography>
                </Box>
                <Box>
                    <SpanTypography sx={{ textAlign: 'left' }}>{UIStrings.WeWorkHard}</SpanTypography>
                    <Box mt={1} sx={{ textAlign: 'left' }}>
                        <SpanTypography>
                            {`${UIStrings.PleaseVisitSite} `}
                            <SpanTypography sx={{ fontWeight: theme.typography.fontWeightBold }}>
                                {window.location.host}
                            </SpanTypography>
                            {` ${UIStrings.PleaseVisitAfterSite}`}
                        </SpanTypography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    <object type="image/svg+xml" data={GoToDesktop} aria-label={UIStrings.GoToDesktop} />
                </Box>
            </Stack>
        </Box>
    );
}

export default GetFullExperiencePage;
