import React, { CSSProperties } from 'react';
import { GridColDef, GridCellParams, HeaderTypography } from '@talentmesh/core';
import ATSCell, { ATSCellProps } from '../Cells/ATSCell';
import { CandidateOverview, CandidateStatuses } from '../../../../../Models/CandidateOverview';
import { DialogEnum } from '../../../../Dialogs/ATS/Context/ActionDialogContext';

interface ATSColumnProps
    extends Pick<GridColDef, 'field' | 'width' | 'colSpan'>,
        Pick<ATSCellProps, 'tooltipMessage' | 'icon'> {
    headerLabel: string;
    dialogStatus: DialogEnum;
    candidateStatus: CandidateStatuses;
    activeStyle: CSSProperties;
    internalBenchmarkComponent?: JSX.Element;
}

function createATSColumn({
    field,
    width,
    colSpan,
    headerLabel,
    dialogStatus,
    candidateStatus,
    activeStyle,
    tooltipMessage,
    icon,
    internalBenchmarkComponent,
}: ATSColumnProps): GridColDef {
    return {
        field,
        renderHeader: () => <HeaderTypography>{headerLabel}</HeaderTypography>,
        width,
        minWidth: width,
        maxWidth: width,
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        colSpan,
        renderCell: (params: GridCellParams) => {
            const candidate = params.row as CandidateOverview;

            const {
                currentStatus: { status },
                isInternal,
            } = candidate;

            if (isInternal) {
                return internalBenchmarkComponent ?? null;
            }

            return (
                <ATSCell
                    candidate={candidate}
                    dialogStatus={dialogStatus}
                    tooltipMessage={tooltipMessage}
                    icon={icon}
                    sx={{
                        '&:disabled': status === candidateStatus ? activeStyle : '',
                    }}
                />
            );
        },
    };
}

export default createATSColumn;
