import { useTheme } from '@mui/material/styles';
import React, { ReactElement } from 'react';
import { Box, Button } from '@talentmesh/core';
import { ChevronLeftIcon } from '@talentmesh/icons';
import UIStrings from '../../../../Utils/UIStrings';
import ProcessingState from '../../../../Models/ProcessingState';

export interface StepperNavigationButtonsProps {
    activeStep: number;
    handleBack?: () => void;
    nextButtonComponent: ReactElement<any, any> | null;
    processingState: ProcessingState;
}

export const StepperNavigationButtons = ({
    activeStep,
    handleBack,
    nextButtonComponent,
    processingState,
}: StepperNavigationButtonsProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Box sx={{ marginTop: theme.spacing(4), display: 'flex', flexDirection: 'row' }}>
            {handleBack && (
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<ChevronLeftIcon />}
                    disabled={activeStep === 0 || processingState === ProcessingState.Processing}
                    onClick={handleBack}
                >
                    {UIStrings.Back}
                </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            {nextButtonComponent && nextButtonComponent}
        </Box>
    );
};
