import { EducationField } from './EducationField';
import { EducationLevel } from './EducationLevel';

export type Education = {
    schoolName: string;
    educationLevel: EducationLevel;
    educationField: EducationField;
    startDate: Date;
    endDate: Date;
};

export const DefaultEducation: Education = {
    educationField: {},
    educationLevel: {},
    schoolName: '',
    startDate: new Date(),
    endDate: new Date(),
};
