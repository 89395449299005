import { useTheme } from '@mui/material/styles';
import { Stack } from '@talentmesh/core';
import React from 'react';

interface RTELoadingOverlayProps {
    svg: string;
    dataCy?: string;
}

function RTELoadingOverlay({ svg, dataCy }: RTELoadingOverlayProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            direction="column"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            sx={{
                background: theme.palette.common.white,
                borderColor: theme.palette.border.main,
                borderWidth: '1px',
                borderStyle: 'solid',
            }}
            data-cy={dataCy}
        >
            <object type="image/svg+xml" data={svg} aria-label="overlay" />
        </Stack>
    );
}

export default RTELoadingOverlay;
