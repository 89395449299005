import React from 'react';
import { SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';

const PublicRecruitmentCreatedNotificationBodyNoCareerPage = () => {
    return (
        <>
            <SpanTypography variant="body1">{UIStrings.PublicRecruitmentCreatedNoCareerPageText1}</SpanTypography>
            <SpanTypography variant="body1" fontWeight="bold">
                {UIStrings.PublicRecruitmentCreatedNoCareerPageText2}
            </SpanTypography>
            <SpanTypography variant="body1">{UIStrings.PublicRecruitmentCreatedNoCareerPageText3}</SpanTypography>
        </>
    );
};

export default PublicRecruitmentCreatedNotificationBodyNoCareerPage;
