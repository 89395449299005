import React from 'react';
import { FormikHelpers } from 'formik';
import BaseTestCard from '../BaseTestCard/BaseTestCard';
import TestSelectionFormFieldNames from '../../../../../../Models/TestSelectionFormFieldNames';
import TestInfo from '../../../../../../../../Models/TestInfo';
import UIStrings from '../../../../../../../../../../../Utils/UIStrings';
import { resourceBaseUrl } from '../../../../../../../../../../../AppSettings';
import { PreviewQuestionsProps } from '../PreviewQuestions/Dialog/usePreviewQuestions';

interface AptitudeTestCardProps extends Omit<PreviewQuestionsProps, 'testType'> {
    test: TestInfo;
    helpers: FormikHelpers<any>;
}

function AptitudeTestCard({ test, helpers, demoQuestions, likertOptions }: AptitudeTestCardProps): JSX.Element {
    return (
        <BaseTestCard
            {...test}
            helpers={helpers}
            testName={UIStrings.Aptitude}
            fieldName={TestSelectionFormFieldNames.isAptitudeSelected}
            imageURL={`${resourceBaseUrl}/images/Aptitude.png`}
            demoQuestions={demoQuestions}
            likertOptions={likertOptions}
        />
    );
}

export default AptitudeTestCard;
