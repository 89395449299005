import { Paper, Stack } from '@talentmesh/core';
import React from 'react';
import TabPaper from '../Common/TabPaper';
import CriteriaList from './ExperienceTab/CriteriaList/CriteriaList';
import ExperienceScore from './ExperienceTab/ExperienceScore';

const ExperienceTab = () => {
    return (
        <Stack spacing={2.5}>
            <TabPaper>
                <ExperienceScore />
            </TabPaper>
            <Paper elevation={0}>
                <CriteriaList />
            </Paper>
        </Stack>
    );
};

export default ExperienceTab;
