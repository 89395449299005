import { Stack, Typography } from '@talentmesh/core';
import React from 'react';

export interface InputSectionProps {
    InputControl: () => JSX.Element;
    dataCy?: string;
    title: string;
    description: string;
    width?: string;
}

function InputSection({ InputControl, dataCy, title, description, width }: InputSectionProps): JSX.Element {
    return (
        <Stack data-cy={dataCy} spacing={1.25} width={width}>
            <Typography variant="h4" color="secondary">
                {title}
            </Typography>
            <Typography variant="body2" color="secondary">
                {description}
            </Typography>
            {InputControl()}
        </Stack>
    );
}

export default InputSection;
