/* eslint-disable import/prefer-default-export */
import { TestTypes } from '../../../../Models/Configuration';

function getOrderByTest(testType: TestTypes): number {
    switch (testType) {
        case TestTypes.Personality:
            return 1;
        case TestTypes.Aptitude:
            return 2;
        case TestTypes.Skills:
            return 3;
        case TestTypes.EmotionalIntelligence:
            return 4;
        default:
            return 0;
    }
}

function sortByTestType(a: TestTypes, b: TestTypes): number {
    return getOrderByTest(a) - getOrderByTest(b);
}

export { sortByTestType };
