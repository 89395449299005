import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDownloadReportStatusContext } from '../../../../../Context/UseDownloadReportStatusContext';
import { useAssessmentClient } from '../../../../../Hooks/ClientHooks';
import { formatStringToYYYYMMDD } from '../../../../../Utils/DateHelper';
import HttpError from '../../../../../Utils/HTTP/Errors/HttpError';
import HttpStatusCodes from '../../../../../Utils/HTTP/HttpStatusCodes';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import { getSnackbarMessage, getSnackbarOptions } from '../../Utils/SnackbarUtils';
import { SnackbarMessageStatus } from '../SnackbarMessageContent';

interface UseCandidateResultsHeaderInterface {
    isReportDownloading: boolean;
    downloadReportHandler: () => Promise<void>;
    openAssessmentResetDialog: boolean;
    setOpenAssessmentResetDialog: (value: boolean) => void;
}

function useCandidateResultsHeader(): UseCandidateResultsHeaderInterface {
    const assessmentClient = useAssessmentClient();
    const notiStackContext = useSnackbar();
    const { results } = useCandidatesResultsContext();
    const { assessmentId, candidateId } = results;
    const [openAssessmentResetDialog, setOpenAssessmentResetDialog] = useState<boolean>(false);
    const { downloads, start, complete, getKey } = useDownloadReportStatusContext();

    const downloadReportHandler = async () => {
        const candidateIdBase64 = btoa(candidateId);
        const { candidateFirstName, candidateLastName, completedAt } = results;

        const pendingMessage = getSnackbarMessage(candidateFirstName, 'pending' as SnackbarMessageStatus);
        const messageId = notiStackContext.enqueueSnackbar(pendingMessage, getSnackbarOptions('default'));
        try {
            start(assessmentId, candidateId);
            const blob = await assessmentClient.getCandidateResultsPdfReport(assessmentId, candidateIdBase64);
            notiStackContext.closeSnackbar(messageId);
            notiStackContext.enqueueSnackbar(null, {
                content: (key, message) =>
                    getSnackbarMessage(candidateFirstName, 'success' as SnackbarMessageStatus, key),
            });
            saveAs(
                blob,
                `${candidateFirstName}_${candidateLastName}_Applicant_Report_${formatStringToYYYYMMDD(
                    completedAt,
                )}.pdf`,
            );
        } catch (exception) {
            const error = exception as HttpError;

            if (error.status === HttpStatusCodes.TooManyRequests) {
                notiStackContext.closeSnackbar(messageId);
                notiStackContext.enqueueSnackbar(null, {
                    content: (key, message) =>
                        getSnackbarMessage(candidateFirstName, 'warning' as SnackbarMessageStatus, key),
                });
            } else {
                notiStackContext.closeSnackbar(messageId);
                notiStackContext.enqueueSnackbar(null, {
                    content: (key, message) =>
                        getSnackbarMessage(candidateFirstName, 'error' as SnackbarMessageStatus, key),
                });
            }
        } finally {
            complete(assessmentId, candidateId);
        }
    };

    return {
        isReportDownloading: downloads.has(getKey(assessmentId, candidateId)),
        downloadReportHandler,
        openAssessmentResetDialog,
        setOpenAssessmentResetDialog,
    };
}

export default useCandidateResultsHeader;
