import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Navigate, generatePath } from 'react-router-dom';
import { LoadingOverlay } from '@talentmesh/core';
import { useAssessmentSettingsContext } from '../../Context/AssessmentSettingsContext';
import { useUserContext } from '../../Context/UserContext';
import { useUserSignupNavigationContext } from '../../Context/UserSignupNavigationContext';
import RoutePath from '../../Routing/RoutePath';

function ProvisionPage(): JSX.Element {
    const { isLoading } = useUserSignupNavigationContext();
    const { user } = useAuth0();
    const [configurationIsLoading, setConfigurationIsLoading] = useState<boolean>(true);
    const { loadConfigurationAsync } = useAssessmentSettingsContext();
    const { tryProvisionAccount, provisioningInProcess } = useUserContext();

    useEffect(() => {
        const doInitContext = async () => {
            try {
                // we need to reload configuration during sign up, because first call fails
                // with 403 because email is not verified yet
                await loadConfigurationAsync();
            } finally {
                setConfigurationIsLoading(false);
            }
        };
        doInitContext();

        const doTryProvisionAccount = async () => await tryProvisionAccount();
        doTryProvisionAccount();
    }, []);

    if (isLoading || configurationIsLoading || provisioningInProcess) {
        return <LoadingOverlay />;
    }

    if (!user?.email_verified) {
        return <Navigate to={RoutePath.VerifyEmail} />;
    }

    return <Navigate to={generatePath(RoutePath.Root)} />;
}

export default ProvisionPage;
