import ClientBase from './ClientBase';

export default class BlobClient extends ClientBase {
    async downloadCvAsync(assessmentId: string, applicantId: string): Promise<Blob> {
        const response = await fetch(
            `${this.addHost('blob/cv')}?assessmentId=${assessmentId}&applicantId=${applicantId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${await this.getAccessTokenSilently()}`,
                },
            },
        );
        if (response.body && response.ok) {
            return response.blob();
        }
        return new Blob();
    }
}
