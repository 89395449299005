import { CandidateStatuses } from '../../Models/CandidateOverview';
import ATSAction from '../../Models/ATSAction';

function isInviteForInteviewAllowed(candidateStatus: CandidateStatuses) {
    switch (candidateStatus) {
        case CandidateStatuses.Invited:
        case CandidateStatuses.AssessmentOpened:
        case CandidateStatuses.AssessmentOngoing:
        case CandidateStatuses.AssessmentCompleted:
        case CandidateStatuses.AssessmentRetaken:
            return true;
        default:
            return false;
    }
}

function isRejectAllowed(candidateStatus: CandidateStatuses) {
    switch (candidateStatus) {
        case CandidateStatuses.Invited:
        case CandidateStatuses.AssessmentOpened:
        case CandidateStatuses.AssessmentOngoing:
        case CandidateStatuses.AssessmentCompleted:
        case CandidateStatuses.AssessmentRetaken:
        case CandidateStatuses.Interviewing:
            return true;
        default:
            return false;
    }
}

function isHireAllowed(candidateStatus: CandidateStatuses) {
    switch (candidateStatus) {
        case CandidateStatuses.Invited:
        case CandidateStatuses.AssessmentOpened:
        case CandidateStatuses.AssessmentOngoing:
        case CandidateStatuses.AssessmentCompleted:
        case CandidateStatuses.AssessmentRetaken:
        case CandidateStatuses.Interviewing:
            return true;
        default:
            return false;
    }
}

function isATSActionAllowedInState(action: ATSAction, candidateStatus: CandidateStatuses): boolean {
    switch (action) {
        case ATSAction.InviteForInterview:
            return isInviteForInteviewAllowed(candidateStatus);
        case ATSAction.Reject:
            return isRejectAllowed(candidateStatus);
        case ATSAction.Hire:
            return isHireAllowed(candidateStatus);
        default:
            // by default an action is not allowed
            return false;
    }
}

export default isATSActionAllowedInState;
