import { JobFunction } from './JobFunction';

export interface JobCategory {
    id: number;
    jobCategoryName: string;
    isDeprecated: boolean;
    jobFunctions: JobFunction[];
}

export function findJobCategoryByJobFunctionId(
    jobCategories: JobCategory[],
    jobFunctionId: number,
): JobCategory | undefined {
    return jobCategories.find((jobCategory) =>
        jobCategory.jobFunctions.find((jobFunction) => jobFunction.id === jobFunctionId),
    );
}
