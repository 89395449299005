import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Container,
    IconButton,
    RoundButtonTab,
    Search,
    Stack,
    TabContext,
    TabList,
    Typography,
} from '@talentmesh/core';
import { ChevronLeftIcon } from '@talentmesh/icons';
import React from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import { useAssessmentsOverviewContext } from '../Contexts/AssessmentsOverviewContext';
import CreateRecruitmentButton from './CreateRecruitmentButton';
import useCompanyId from '../../../../Hooks/UseCompanyId';
import useClientIdPathParam from '../../../../Hooks/UseClientIdPathParam';
import { VerticalDivider } from '../../../Candidates/Results/Components/Tabs/Components/Common/CustomDivider';
import { resourceBaseUrl } from '../../../../AppSettings';
import { useCompanyContext } from '../../../../Context/CompanyContext';
import { ClientResponse } from '../../../../DTO/Clients/ClientResponse';

function AssessmentsOverviewHeader() {
    const theme = useTheme();
    const { searchByName, currentTab, client } = useAssessmentsOverviewContext();
    const width = theme.spacing(12.5);
    const companyId = useCompanyId();
    const clientId = useClientIdPathParam();
    const navigate = useNavigate();
    const { currentCompanyProfile } = useCompanyContext();
    const clientIconPlaceholder = `${resourceBaseUrl}/clients/client_placeholder.svg`;

    const active = generatePath(RoutePath.AssessmentsActive, { companyId, clientId });
    const closed = generatePath(RoutePath.AssessmentsClosed, { companyId, clientId });

    const backClickHandler = () => {
        const clientsOverviewPath = generatePath(RoutePath.CompanyRoot, { companyId });
        navigate(clientsOverviewPath);
    };

    const getCurrentClientIconURL = (clientResponse: ClientResponse): string => {
        if (clientResponse.isDefault) {
            if (currentCompanyProfile.companyIconURL !== '') {
                return currentCompanyProfile.companyIconURL;
            }
        }

        return clientResponse.iconURL ?? clientIconPlaceholder;
    };

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.default,
                borderBottom: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Container disableGutters>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: theme.spacing(3.75),
                        marginBottom: theme.spacing(1.5),
                    }}
                >
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <IconButton onClick={backClickHandler}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography variant="h4" color={theme.palette.text.menuTitle}>
                            {UIStrings.RecruitmentsOverview}
                        </Typography>
                    </Stack>
                    <VerticalDivider />
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <Avatar
                            alt={UIStrings.CompanyNameLogo(client.name)}
                            src={getCurrentClientIconURL(client)}
                            data-cy="ClientIcon"
                        />
                        <Typography variant="h4" color={theme.palette.text.menuTitle}>
                            {client.name}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <TabContext value={currentTab}>
                        <TabList sx={{ placeSelf: 'end' }}>
                            <RoundButtonTab
                                label={UIStrings.Active}
                                value={active}
                                component={Link}
                                to={active}
                                sx={{ width }}
                            />
                            <RoundButtonTab
                                label={UIStrings.Closed}
                                value={closed}
                                component={Link}
                                to={closed}
                                sx={{ width }}
                            />
                        </TabList>
                    </TabContext>
                    <Stack direction="row" spacing={theme.spacing(1.25)} mb={theme.spacing(1)}>
                        <Search
                            placeholder={UIStrings.SearchRecruitments}
                            sx={{
                                paddingLeft: theme.spacing(30),
                                minWidth: theme.spacing(40),
                            }}
                            onSearch={searchByName}
                        />
                        <CreateRecruitmentButton />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}

export default AssessmentsOverviewHeader;
