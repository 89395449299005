import { styled } from '@mui/system';
import React from 'react';
import { theme, TextareaAutosize, TextareaAutosizeProps } from '@talentmesh/core';

const StyledTextareaAutosizeInner = styled(TextareaAutosize)(() => ({
    width: '100%',

    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    letterSpacing: theme.typography.body1.letterSpacing,
    lineHeight: '150%',

    color: theme.palette.text.menuTitle,
    background: theme.palette.common.white,

    padding: 0,

    border: 'none',
    resize: 'none',
    '&:hover': {
        border: 'none',
    },
    '&:focus': {
        border: 'none',
    },
}));

function StyledTextareaAutosize(props: TextareaAutosizeProps): JSX.Element {
    return <StyledTextareaAutosizeInner disabled maxRows={10} {...props} />;
}

export default StyledTextareaAutosize;
