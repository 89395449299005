import { createContext, useContext } from 'react';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';
import { AssessmentOverview } from '../../../../Models/AssessmentOverview';
import { ClientResponse } from '../../../../DTO/Clients/ClientResponse';

export interface AssessmentsOverviewState {
    client: ClientResponse;
    assessments: AssessmentOverview[];
    loading: boolean;
    status: AssessmentStatuses;
    search: string;
    currentOffset: number;
    totalAssessmentsCount: number;
}

export interface AssessmentsOverviewContextState extends AssessmentsOverviewState {}

export type AssessmentsOverviewContextType = AssessmentsOverviewState & {
    loadPageAsync: (offset: number, pageSize?: number) => Promise<void>;
    filterByStatus: (status: AssessmentStatuses) => void;
    searchByName: (name: string) => void;
    currentTab: string;
};

export const AssessmentsOverviewContext = createContext<AssessmentsOverviewContextType | undefined>(undefined);

export function useAssessmentsOverviewContext() {
    const context = useContext(AssessmentsOverviewContext);
    if (!context) {
        throw new Error('useAssessmentsOverviewContext must be used within the AssessmentsOverviewContext.Provider');
    }
    return context;
}
