import { useTheme } from '@mui/material';
import React from 'react';
import { Box, SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

interface DialogCardRejectBodyProps {
    name: string;
}

function DialogCardRejectBody({ name }: DialogCardRejectBodyProps): JSX.Element {
    const theme = useTheme();
    return (
        <Box>
            <SpanTypography variant="body1" color={theme.palette.text.primary}>
                {`${UIStrings.RejectApplicantDialogBody1} `}
            </SpanTypography>
            <SpanTypography variant="body1" color={theme.palette.text.primary}>
                {`${UIStrings.RejectApplicantDialogBody2} ${name} ${UIStrings.RejectApplicantDialogBody3}`}
            </SpanTypography>
        </Box>
    );
}
export default DialogCardRejectBody;
