import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router';
import { useInviteCandidateContext } from '../Contexts/InviteCandidateContext';

interface ChangesMayBeLostDialogInterface {
    stayHandler: () => void;
    leaveHandler: () => void;
    show: boolean;
}

function useChangesMaybeLostDialog(): ChangesMayBeLostDialogInterface {
    const { shouldBlockNavigation } = useInviteCandidateContext();
    const [show, setShow] = useState(false);

    const blocker = useBlocker(() => shouldBlockNavigation);

    useEffect(() => setShow(blocker.state === 'blocked'), [blocker.state, setShow]);

    useEffect(() => {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            if (shouldBlockNavigation) {
                return '';
            }

            return undefined;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const stayHandler = () => {
        if (blocker.reset) {
            blocker.reset();
        }

        setShow(false);
    };
    const leaveHandler = () => {
        if (blocker.proceed) {
            blocker.proceed();
        }
    };

    return { show, stayHandler, leaveHandler };
}

export default useChangesMaybeLostDialog;
