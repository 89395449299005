import React from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack, Typography } from '@talentmesh/core';
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from '@talentmesh/icons';
import UIStrings from '../../../../../Utils/UIStrings';
import CandidateTestMonitoringFeature from './CandidateTestMonitoringFeature';

export interface CTMonitoringHeaderProps {
    isWebcamEnabled: boolean;
    isScreenSnapshotsEnabled: boolean;
    isFullScreenEnabled: boolean;
    isOpened: boolean;
    togglePanel: () => void;
}

function CTMonitoringHeader({
    isWebcamEnabled,
    isScreenSnapshotsEnabled,
    isFullScreenEnabled,
    isOpened,
    togglePanel,
}: CTMonitoringHeaderProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
                borderTopLeftRadius: theme.shape.mediumBorderRadius,
                borderTopRightRadius: theme.shape.mediumBorderRadius,
                borderBottomLeftRadius: isOpened ? 0 : theme.shape.mediumBorderRadius,
                borderBottomRightRadius: isOpened ? 0 : theme.shape.mediumBorderRadius,
                cursor: 'pointer',
                border: `1px solid ${theme.palette.border.main}`,
                borderBottom: isOpened ? 'none' : `1px solid ${theme.palette.border.main}`,
            }}
            onClick={togglePanel}
        >
            <Typography variant="h4" color={theme.palette.secondary.main}>
                {UIStrings.ApplicantTestMonitoring}
            </Typography>
            <CandidateTestMonitoringFeature label={UIStrings.ScreenSnapshots} isEnabled={isScreenSnapshotsEnabled} />
            <CandidateTestMonitoringFeature label={UIStrings.WebcamSnapshots} isEnabled={isWebcamEnabled} />
            <CandidateTestMonitoringFeature label={UIStrings.FullscreenMode} isEnabled={isFullScreenEnabled} />
            <IconButton onClick={togglePanel} size="large">
                {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </Stack>
    );
}

export default CTMonitoringHeader;
