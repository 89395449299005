import React from 'react';
import { Box, ListItem, ListItemIcon, ListItemText, SpanTypography, Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

export interface OtherJobBoardInstructionStepProps {
    stepCount: number;
    stepTitle: string;
    stepDescription: string;
    stepSubDescription?: string;
}

const OtherJobBoardInstructionStep = ({
    stepCount,
    stepTitle,
    stepDescription,
    stepSubDescription,
}: OtherJobBoardInstructionStepProps): JSX.Element => {
    const theme = useTheme();
    const iconSize = theme.spacing(3.75);
    const minimumTitleHeight = theme.spacing(6);

    return (
        <ListItem dense disablePadding sx={{ alignItems: 'flex-start' }}>
            <ListItemIcon sx={{ minWidth: theme.spacing(5), height: minimumTitleHeight }}>
                <Box
                    sx={{
                        height: iconSize,
                        width: iconSize,
                        paddingX: theme.spacing(1.25),
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginY: 'auto',
                    }}
                >
                    <Typography variant="body1" color="white">
                        {stepCount}
                    </Typography>
                </Box>
            </ListItemIcon>
            <ListItemText disableTypography sx={{ margin: 0 }}>
                <Stack spacing={1.25}>
                    <Box
                        sx={{
                            height: minimumTitleHeight,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: theme.typography.fontWeightBold }}
                        >
                            {stepTitle}
                        </Typography>
                    </Box>
                    <Box>
                        <SpanTypography variant="body1" color="secondary">
                            {stepDescription}
                        </SpanTypography>
                        {stepSubDescription && (
                            <SpanTypography
                                variant="body1"
                                color="secondary"
                                sx={{ fontWeight: theme.typography.fontWeightBold }}
                            >
                                {stepSubDescription}
                            </SpanTypography>
                        )}
                    </Box>
                </Stack>
            </ListItemText>
        </ListItem>
    );
};

export default OtherJobBoardInstructionStep;
