import UIStrings from './UIStrings';

function mapLocaleCodeToLanguageName(localeCode: string | null): string {
    switch (localeCode) {
        case 'de':
            return UIStrings.German;
        case 'es':
            return UIStrings.Spanish;
        case 'fr':
            return UIStrings.French;
        case 'it':
            return UIStrings.Italian;
        case 'pt':
            return UIStrings.Portuguese;
        case 'en':
        default:
            return UIStrings.English;
    }
}

export default mapLocaleCodeToLanguageName;
