import { useMemo } from 'react';
import useQuery from '../../../../../Hooks/UseQuery';
import { CandidateTabs } from '../../../../../Models/CandidateOverview';
import CandidateFilterByEnum from '../../Models/CandidateFilterByEnum';
import AdvancedFilterTypes from '../../../../../Models/AdvancedFilterTypes';

export type CandidateOverviewQueryParams = 'page' | 'tab' | 'filterBy' | keyof typeof AdvancedFilterTypes | 'search';
export const CandidateOverviewQueryParamsSeparator = ',';

// parse query to applicant's overview filter state, page position, etc
const useCandidatesOverviewQueryParams = () => {
    const pageNumberQuery = useQuery<CandidateOverviewQueryParams>('page');
    const candidateTabQuery = useQuery<CandidateOverviewQueryParams>('tab');
    const filterByQuery = useQuery<CandidateOverviewQueryParams>('filterBy');
    const search = useQuery<CandidateOverviewQueryParams>('search') ?? '';

    const countryQuery = useQuery<CandidateOverviewQueryParams>('Country');
    const fieldOfEducationQuery = useQuery<CandidateOverviewQueryParams>('FieldOfEducation');
    const highestLevelOfEducationQuery = useQuery<CandidateOverviewQueryParams>('HighestLevelOfEducation');
    const jobTypeQuery = useQuery<CandidateOverviewQueryParams>('JobType');

    return useMemo(() => {
        let pageNumber = +pageNumberQuery!;
        let candidateTab: CandidateTabs;

        const splitQuery = (query: string | null) => query?.split(CandidateOverviewQueryParamsSeparator) || [];

        const countries = splitQuery(countryQuery);
        const fieldOfEducations = splitQuery(fieldOfEducationQuery);
        const highestLevelOfEducations = splitQuery(highestLevelOfEducationQuery);
        const jobTypes = splitQuery(jobTypeQuery);
        const filterBy = splitQuery(filterByQuery) as CandidateFilterByEnum[];

        if (Number.isNaN(pageNumber)) {
            pageNumber = 0;
        }

        switch (candidateTabQuery) {
            case CandidateTabs.Hired:
                candidateTab = CandidateTabs.Hired;
                break;
            case CandidateTabs.Rejected:
                candidateTab = CandidateTabs.Rejected;
                break;
            case CandidateTabs.Open:
            default:
                candidateTab = CandidateTabs.Open;
                break;
        }

        return {
            pageNumber,
            candidateTab,
            filterBy,
            countries,
            fieldOfEducations,
            highestLevelOfEducations,
            jobTypes,
            search,
        };
    }, []);
};

export default useCandidatesOverviewQueryParams;
