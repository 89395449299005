import { PixelCrop, PercentCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import ContentTypes from '../../Utils/ContentTypes';

export interface ImageCropStrings {
    selectButtonTooltipText: string;
    removeButtonTooltipText: string;
    uploadButtonText: string;
    dialogHeaderText: string;
    dialogContentText: string;
}

export const defaultRelativeSelectionArea = 100;
export const defaultMinimumCropWidth = 90;
export const defaultMaximumCropWidth = 500;
export const defaultAspectRatio = 1;
export const defaultCanvasConversionQuality = 0.9;
export const defaultCanvasConversionType = ContentTypes.ImageWebp;
export const defaultAllowedImageTypes = [ContentTypes.ImageJpeg, ContentTypes.ImagePng];

export const centerCropByAspectRatio = (
    imageWidth: number,
    imageHeight: number,
    aspectRatio: number,
    selectionWidth: number = defaultRelativeSelectionArea,
    selectionHeight: number = defaultRelativeSelectionArea,
): PercentCrop => {
    return centerCrop(
        makeAspectCrop(
            { unit: '%', width: selectionWidth, height: selectionHeight },
            aspectRatio,
            imageWidth,
            imageHeight,
        ),
        imageWidth,
        imageHeight,
    );
};

export const getDownScaleFactor = (size: number, width: number): number => {
    if (size > width) {
        return width / size;
    }

    return 1;
};

export const generateCropCanvas = (
    imageRef: HTMLImageElement,
    canvasRef: HTMLCanvasElement,
    pixelCrop: PixelCrop,
): void => {
    const image = imageRef;
    const canvas = canvasRef;

    if (!image) throw new Error('Missing Image Element');
    if (!canvas) throw new Error('Missing Canvas Element');

    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) throw new Error('Missing 2D Context');

    if (pixelCrop) {
        const { width, height, naturalWidth, naturalHeight } = image;

        const scaleX = naturalWidth / width;
        const scaleY = naturalHeight / height;
        const maxCropHeight = defaultMaximumCropWidth * (pixelCrop.height / pixelCrop.width);
        const downScaleXTo = getDownScaleFactor(pixelCrop.width * scaleX, defaultMaximumCropWidth);
        const downScaleYTo = getDownScaleFactor(pixelCrop.height * scaleY, maxCropHeight);
        const cropX = pixelCrop.x * scaleX;
        const cropY = pixelCrop.y * scaleY;
        const centerX = naturalWidth / 2;
        const centerY = naturalHeight / 2;

        canvas.width = Math.floor(pixelCrop.width * scaleX * downScaleXTo);
        canvas.height = Math.floor(pixelCrop.height * scaleY * downScaleYTo);

        canvasContext.imageSmoothingQuality = 'high';
        canvasContext.scale(downScaleXTo, downScaleYTo);
        canvasContext.save();

        canvasContext.translate(-cropX, -cropY);
        canvasContext.translate(centerX, centerY);
        canvasContext.rotate(0);
        canvasContext.scale(1, 1);
        canvasContext.translate(-centerX, -centerY);

        canvasContext.drawImage(image, 0, 0, naturalWidth, naturalHeight, 0, 0, naturalWidth, naturalHeight);

        canvasContext.restore();
    }
};

export const isWebkit = (): boolean => {
    return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;
};

export const convertCanvasToBlob = async (canvasRef: HTMLCanvasElement, fallbackType: string): Promise<Blob | null> => {
    const type = isWebkit() ? fallbackType : defaultCanvasConversionType;

    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => canvasRef.toBlob(resolve, type, defaultCanvasConversionQuality));
};

// 6 is taken from the length of image mime type prefix `image/`
export const extractImageContentType = (mimeType: string) => mimeType.slice(6);
