import { ClientOverviewResponse } from '../DTO/Clients/ClientOverviewResponse';

export interface Pagination {
    offset: number;
    limit: number;
    totalCount: number;
}

export default interface Page<T> {
    pagination: Pagination;
    data: T[];
}

export interface ApplicantsPage<T> extends Page<T> {
    totalOpen: number;
    totalHired: number;
    totalRejected: number;
}

export const EmptyPage: Page<any> = {
    pagination: {
        offset: 0,
        limit: 0,
        totalCount: 0,
    },
    data: [],
};

export const EmptyApplicantsPage: ApplicantsPage<any> = {
    pagination: {
        offset: 0,
        limit: 0,
        totalCount: 0,
    },
    data: [],
    totalOpen: 0,
    totalHired: 0,
    totalRejected: 0,
};

export const EmptyAssessmentOverviewPage: Page<any> = {
    pagination: {
        offset: 0,
        limit: 0,
        totalCount: 0,
    },
    data: [],
};

export const EmptyClientOverviewPage: Page<ClientOverviewResponse> = {
    pagination: {
        offset: 0,
        limit: 0,
        totalCount: 0,
    },
    data: [],
};
