import React from 'react';
import { Outlet } from 'react-router';
import NotificationSystem from '../Components/NotificationSystem/NotificationSystem';
import RecruitmentCreatedNotificationSystem from '../Components/RecruitmentCreatedNotificationSystem/RecruitmentCreatedNotificationSystem';
import StripeInitialization from '../Initialization/StripeInitialization';
import AssessmentSettingsProvider from './AssessmentSettingsProvider';
import { DownloadReportStatusContext } from './UseDownloadReportStatusContext';
import useDownloadReportStatusContextValue from './UseDownloadReportStatusContextValue';
import useUserSignupNavigationContextValue from './UseUserSignupNavigationContextValue';
import { UserProvider } from './UserContext';
import { UserSignupNavigationContext } from './UserSignupNavigationContext';

function RecruiterApplicationContextProvider() {
    const userSignupNavigationContextValue = useUserSignupNavigationContextValue();
    const downloadReportStatusContextValue = useDownloadReportStatusContextValue();

    return (
        <UserProvider>
            <StripeInitialization>
                <AssessmentSettingsProvider>
                    <RecruitmentCreatedNotificationSystem>
                        <NotificationSystem>
                            <UserSignupNavigationContext.Provider value={userSignupNavigationContextValue}>
                                <DownloadReportStatusContext.Provider value={downloadReportStatusContextValue}>
                                    <Outlet />
                                </DownloadReportStatusContext.Provider>
                            </UserSignupNavigationContext.Provider>
                        </NotificationSystem>
                    </RecruitmentCreatedNotificationSystem>
                </AssessmentSettingsProvider>
            </StripeInitialization>
        </UserProvider>
    );
}

export default RecruiterApplicationContextProvider;
