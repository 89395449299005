import { AutocompleteOption } from '@talentmesh/core';
import { DefaultSkillsOptionsDTO } from '../DTO/Assessments/DefaultSkillsOptionsDTO';
import AutocompleteOptionType from '../Models/AutocompleteOptionType';
import { get } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class AutocompleteOptionsClient extends ClientBase {
    prefix = 'questions/autocomplete-options';

    async getAutocompleteOptions(
        search: string,
        optionType: AutocompleteOptionType,
        limit?: number,
    ): Promise<AutocompleteOption[]> {
        const response = await get<AutocompleteOption[]>({
            path: this.addHost(`${this.prefix}?${this.buildAutocompleteOptionsSearchQuery(search, optionType, limit)}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }

        return [];
    }

    buildAutocompleteOptionsSearchQuery(search: string, optionType: AutocompleteOptionType, limit?: number): string {
        const query = new URLSearchParams({
            search,
            type: optionType.toString(),
        });

        if (limit) {
            query.append('limit', Math.min(limit, 50).toString());
        }

        return query.toString();
    }

    async getDefaultSkillsOptions(jobFunctionId: number): Promise<DefaultSkillsOptionsDTO> {
        const response = await get<DefaultSkillsOptionsDTO>({
            path: this.addHost(`${this.prefix}/default-skills?jobFunctionId=${jobFunctionId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }

        return { hardSkills: [], softSkills: [] } as DefaultSkillsOptionsDTO;
    }
}
