import { get } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class QuestionsConfigurationClient extends ClientBase {
    getTestLocalesAsync = async (): Promise<string[]> => {
        const result = await get<string[]>({
            path: this.addHost(`questions/configuration/test-locales`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return result.body;
        }
        return [];
    };
}
