import { DemoQuestionsDTO } from '../../../../DTO/Questions/DemoQuestionsDTO';
import autoMapping from '../../../../Utils/autoMapping';

interface Answer {
    id: string;
    content: string;
}

export enum QuestionType {
    Choice = 'Choice',
    Exact = 'Exact',
    Likert = 'Likert',
}

export interface Question {
    id: string;
    question: string;
    answers: Answer[];
    questionType: QuestionType;
    blobFileURI: string;
    fileMimeType: string;
}

const DefaultQuestion: Question = {
    answers: [],
    blobFileURI: '',
    fileMimeType: '',
    id: '',
    question: '',
    questionType: QuestionType.Choice,
};

export interface LikertOption {
    answerText: string;
    score: number;
}

export const DefaultLikertOption = {
    answerText: '',
    score: 0,
};

export interface DemoQuestions {
    personality: Question[];
    aptitude: Question[];
    skills: Question[];
    emotionalIntelligence: Question[];
    likertAnswers: LikertOption[];
}

export const mapDemoQuestionsDto2Model = (dto: DemoQuestionsDTO): DemoQuestions => {
    const skills = dto.skills.map((qdto) => autoMapping(qdto, DefaultQuestion));
    const aptitude = dto.aptitude.map((qdto) => autoMapping(qdto, DefaultQuestion));
    const personality = dto.personality.map((q) => autoMapping(q, DefaultQuestion));
    const emotionalIntelligence = dto.emotionalIntelligence.map((q) => autoMapping(q, DefaultQuestion));
    const likertAnswers = dto.likertAnswers.map((a) => autoMapping(a, DefaultLikertOption));

    return {
        aptitude,
        emotionalIntelligence,
        likertAnswers,
        personality,
        skills,
    };
};
