import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router';
import { ApplicationInsightsSettings } from '../AppSettings';
import RoutePath from '../Routing/RoutePath';
import { reactPlugin } from './AppInsights';

interface TelemetryErrorBoundaryProps {
    children: ReactElement;
}

function ErrorPageRedirect() {
    return <Navigate to={RoutePath.Error} replace />;
}

function TelemetryErrorBoundary({ children }: TelemetryErrorBoundaryProps): JSX.Element {
    if (ApplicationInsightsSettings.connectionString) {
        return (
            <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorPageRedirect}>
                <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
            </AppInsightsErrorBoundary>
        );
    } else {
        return children;
    }
}

export default TelemetryErrorBoundary;
