import React from 'react';
import { Box, Divider, Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../Utils/UIStrings';

function EndOfListMessage(): JSX.Element {
    const theme = useTheme();

    return (
        <Stack sx={{ mt: 5 }} alignItems="center">
            <Box>
                <Divider sx={{ width: theme.spacing(32.5) }}>
                    <Typography variant="body2" color={theme.palette.text.secondary}>
                        {UIStrings.NoMoreRecruitmentsToShow}
                    </Typography>
                </Divider>
            </Box>
        </Stack>
    );
}

export default EndOfListMessage;
