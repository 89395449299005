import React from 'react';
import { FormikTextField } from '@talentmesh/forms';
import AmountFormatter from './AmountFormatter';
import LabelWithTooltip from './LabelWithTooltip';

interface AmountTextFieldProps {
    name: string;
    label: string;
    tooltipText: string;
}

function AmountTextField({ name, label, tooltipText }: AmountTextFieldProps): JSX.Element {
    return (
        <FormikTextField
            name={name}
            data-cy={name}
            label={<LabelWithTooltip label={`${label}*`} tooltipText={tooltipText} />}
            InputProps={{
                inputComponent: AmountFormatter,
            }}
        />
    );
}

export default AmountTextField;
