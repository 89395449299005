import { AdvancedFilterDTO } from '../DTO/Results/AdvancedFilterDTO';
import AdvancedFilterTypes from '../Models/AdvancedFilterTypes';
import { get } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class ResultsClient extends ClientBase {
    async getAdvancedFilters(assessmentId: string, filterType?: AdvancedFilterTypes): Promise<AdvancedFilterDTO[]> {
        const response = await get<AdvancedFilterDTO[]>({
            path: this.addHost(
                `assessments/${assessmentId}/advanced-filters${this.buildAdvancedFiltersQuery(filterType)}`,
            ),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body || [];
        } else {
            return [];
        }
    }

    buildAdvancedFiltersQuery(filterType?: AdvancedFilterTypes): string {
        if (!filterType) {
            return '';
        }

        return `?filterType=${filterType}`;
    }
}
