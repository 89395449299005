import React from 'react';
import { Box, Button, Stack } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';
import Compensation from '../Compensation';
import CompensationLine from './CompensationLine';
import { getFormattedAmount } from '../../../../Utils/Amounts/AmountHelpers';

interface CompensationReadProps {
    compensation: Compensation;
    setIsCompensationInEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompensationRead = ({ compensation, setIsCompensationInEdit }: CompensationReadProps) => {
    const onEditClick = () => {
        setIsCompensationInEdit(true);
    };

    const formattedAnnualFixedAmount = getFormattedAmount(parseInt(compensation.annualFixedAmount, 10));
    const formattedAnnualTargetBonus = getFormattedAmount(parseInt(compensation.annualTargetBonus, 10));

    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" spacing={1.25}>
                <CompensationLine title={UIStrings.Currency} value={compensation.currency.label} />
                <CompensationLine title={UIStrings.AnnualFixedAmount} value={formattedAnnualFixedAmount} />
                <CompensationLine title={UIStrings.AnnualTargetBonus} value={formattedAnnualTargetBonus} />
            </Stack>
            <Box>
                <Button variant="outlined" color="secondary" size="small" fullWidth={false} onClick={onEditClick}>
                    {UIStrings.Edit}
                </Button>
            </Box>
        </Stack>
    );
};

export default CompensationRead;
