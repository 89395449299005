import React from 'react';
import { Button } from '@talentmesh/core';
import { AddIcon } from '@talentmesh/icons';
import { generatePath, useNavigate } from 'react-router';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import useClientIdPathParam from '../../../../Hooks/UseClientIdPathParam';
import useCompanyId from '../../../../Hooks/UseCompanyId';

interface CreateRecruitmentButtonProps {
    label?: string;
    hideIcon?: boolean;
    hidden?: boolean;
}

const CreateRecruitmentButton = ({ label, hideIcon, hidden }: CreateRecruitmentButtonProps) => {
    const navigate = useNavigate();
    const companyId = useCompanyId();
    const clientId = useClientIdPathParam();

    const handleAssessmentCreateClick = async () => {
        const createRecruitmentPath = generatePath(RoutePath.CreateAssessment, { companyId, clientId });
        navigate(createRecruitmentPath);
    };

    return (
        <Button
            startIcon={hideIcon ? null : <AddIcon />}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleAssessmentCreateClick}
            sx={{ visibility: hidden ? 'hidden' : 'unset' }}
        >
            {label || UIStrings.CreateRecruitment}
        </Button>
    );
};

export default CreateRecruitmentButton;
