import { stepConnectorClasses, styled } from '@mui/material';
import { StepConnector } from '@talentmesh/core';

/**
 * lower the value to make the line closer to each other,
 * increase the value to make the line more far apart
 *
 * @link https://mui.com/material-ui/react-stepper/#customized-horizontal-stepper
 * */
const LineWidth = '7px';

const ThickConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: theme.spacing(4.5),
        left: `calc(-50% + ${LineWidth})`,
        right: `calc(50% + ${LineWidth})`,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderTopWidth: 3,
    },
}));

export default ThickConnector;
