import {
    AutocompleteOption,
    DirectReportsType,
    EmploymentTypes,
    WorkArrangements,
    WorkExperienceType,
} from '@talentmesh/core';
import { JobAdLanguage } from '../../../../Models/JobAdLanguage';
import LocationDetails, { DefaultLocationDetails } from '../../../../Models/LocationDetails';
import { defaultLanguage } from '../../../../Utils/JobAdLanguage/JobAdLanguages';
import { JobPostingType } from '../../../../DTO/Assessments/JobPostingType';

export interface JobDetails {
    name: string;
    workArrangement: WorkArrangements;
    locationDetails: LocationDetails;
    jobFunctionId: number;
    workExperience: WorkExperienceType;
    employmentType: EmploymentTypes;
    directReports: DirectReportsType;
    yearlySalaryFrom: string;
    yearlySalaryTo: string;
    currency: AutocompleteOption;
    doNotDisplaySalary: boolean;
    jobDescriptionLanguage: JobAdLanguage;
    jobPostingType: JobPostingType;
}

export const DefaultJobDetails: JobDetails = {
    directReports: 'None',
    workArrangement: 'OnLocation',
    locationDetails: DefaultLocationDetails,
    jobFunctionId: 0,
    name: '',
    workExperience: 'EntryLevel',
    employmentType: 'FullTime',
    yearlySalaryFrom: '',
    yearlySalaryTo: '',
    currency: { id: '', label: '' },
    doNotDisplaySalary: false,
    jobDescriptionLanguage: defaultLanguage,
    jobPostingType: 'Direct',
};
