import { Stack } from '@talentmesh/core';
import React from 'react';
import { AssessmentOverview } from '../../../../../Models/AssessmentOverview';
import UIStrings from '../../../../../Utils/UIStrings';
import DateTimeCaption from './components/DateTimeCaption';

interface RecruitmentListItemFooterProps {
    recruitment: AssessmentOverview;
}

const RecruitmentListItemFooter = ({ recruitment }: RecruitmentListItemFooterProps) => {
    const { createdAt, closedAt, lastViewedAt } = recruitment;

    return (
        <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <DateTimeCaption label={UIStrings.Created} dateAt={createdAt} />
            <DateTimeCaption label={UIStrings.LastViewed} dateAt={lastViewedAt} />
            <DateTimeCaption label={UIStrings.Closed} dateAt={closedAt} />
        </Stack>
    );
};

export default RecruitmentListItemFooter;
