import { useTheme } from '@mui/material';
import React from 'react';
import { SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

interface DialogCardRejectTitleProps {
    name: string;
}

function DialogCardRejectTitle({ name }: DialogCardRejectTitleProps): JSX.Element {
    const theme = useTheme();
    return (
        <SpanTypography variant="h5" color={theme.palette.text.menuTitle}>
            {`${UIStrings.RejectApplicantDialogHeader1} ${name}`}
        </SpanTypography>
    );
}

export default DialogCardRejectTitle;
