import React from 'react';
import SelectTestsStep from '../Steps/SelectTests/SelectTestsStep';
import RequestInformationStep from '../Steps/RequestInformation/RequestInformationStep';
import EditJobAdStep from '../Steps/JobAdStep/EditJobAdStep';
import PostJobStep from '../Steps/PostJobStep/PostJobStep';
import IdentifyExperienceStep from '../Steps/IdentifyExperienceStep/IdentifyExperienceStep';
import CreateJobDetailsStep from '../Steps/JobDetailsStep/CreateJobDetailsStep';
import EditJobDetailsStep from '../Steps/JobDetailsStep/EditJobDetailsStep';
import CreateJobAdStep from '../Steps/JobAdStep/CreateJobAdStep';

export interface StepMapperProps {
    activeStep: number;
    handleNext: () => void;
    handleBack: () => void;
}

export function ModifyRecruitmentStepMapper({ activeStep, handleNext, handleBack }: StepMapperProps): JSX.Element {
    return (
        <>
            {activeStep === 0 && (
                <EditJobDetailsStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 1 && <EditJobAdStep activeStep={activeStep} handleBack={handleBack} />}
        </>
    );
}

export function CreateFullRecruitmentStepMapper({ activeStep, handleNext, handleBack }: StepMapperProps): JSX.Element {
    return (
        <>
            {activeStep === 0 && (
                <CreateJobDetailsStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 1 && (
                <CreateJobAdStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 2 && (
                <SelectTestsStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 3 && (
                <RequestInformationStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 4 && (
                <IdentifyExperienceStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 5 && <PostJobStep activeStep={activeStep} handleBack={handleBack} />}
        </>
    );
}
