import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Typography } from '@talentmesh/core';

interface FormGlobalErrorMessageProps {
    errorMessages: string;
}

const FormGlobalErrorMessage = ({ errorMessages }: FormGlobalErrorMessageProps) => {
    const theme = useTheme();

    return (
        <Typography variant="caption" color={theme.palette.error.main}>
            {errorMessages}
        </Typography>
    );
};

export default FormGlobalErrorMessage;
