import React from 'react';
import { IconButton, Stack, TMTooltip, TooltipPaper, Typography } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material';
import UIStrings from '../../Utils/UIStrings';

function TalentScoreTooltip(): JSX.Element {
    const theme = useTheme();

    return (
        <TMTooltip
            title={
                <TooltipPaper minWidth={theme.spacing(40)}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="h5" color="secondary">
                            {UIStrings.TalentScore}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                            {UIStrings.TalentScoreDescription}
                        </Typography>
                    </Stack>
                </TooltipPaper>
            }
            placement="bottom"
        >
            <IconButton sx={{ padding: 0 }}>
                <InfoIcon color="secondary" />
            </IconButton>
        </TMTooltip>
    );
}

export default TalentScoreTooltip;
