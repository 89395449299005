import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@talentmesh/core';
import { useBillingInfoClient } from '../../../../Hooks/ClientHooks';
import BillingInfo from '../../../../Models/BillingInfo';
import UIStrings from '../../../../Utils/UIStrings';
import LoadingTab from '../LoadingTab';
import BillingAddress from './BillingAddress';
import useCompanyId from '../../../../Hooks/UseCompanyId';

const PaymentTab = () => {
    const theme = useTheme();
    const client = useBillingInfoClient();
    const companyId = useCompanyId();
    const [billingInfo, setBillingInfo] = useState<BillingInfo | undefined>(undefined);
    const [isLoadingBillingInfo, setIsLoadingBillingInfo] = useState<boolean>(true);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);

    useEffect(() => {
        const doLoadBillingInfo = async () => {
            try {
                const retrievedBillingInfo = await client.getBillingInfoAsync(companyId);
                setBillingInfo(retrievedBillingInfo);
            } catch {
                setLoadingFailed(true);
            } finally {
                setIsLoadingBillingInfo(false);
            }
        };
        doLoadBillingInfo();
    }, []);

    return (
        <LoadingTab isLoading={isLoadingBillingInfo} loadingFailed={loadingFailed}>
            <Typography sx={{ marginBottom: 3 }} variant="h3" color={theme.palette.text.primary}>
                {UIStrings.PaymentSettingsTab}
            </Typography>
            <Stack direction="row" spacing={2}>
                <Box sx={{ width: '50%' }}>
                    <BillingAddress billingInfo={billingInfo} setBillingInfo={setBillingInfo} />
                </Box>
            </Stack>
        </LoadingTab>
    );
};

export default PaymentTab;
