import BillingInfoDTO from '../DTO/Users/BillingInfoDTO';
import BillingInfo, { fromBillingInfo, fromBillingInfoDTO } from '../Models/BillingInfo';
import { get, put, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class BillingInfoClient extends ClientBase {
    getBillingInfoAsync = async (companyId: string): Promise<BillingInfo | undefined> => {
        const result = await get<BillingInfoDTO>({
            path: this.addHost(`users/company/${companyId}/billing`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromBillingInfoDTO(result.body);
        }
        return undefined;
    };

    saveBillingInfoAsync = async (companyId: string, billingInfo: BillingInfo): Promise<BillingInfo | undefined> => {
        const result = await put<BillingInfoDTO>({
            path: this.addHost(`users/company/${companyId}/billing`),
            ...toJson(fromBillingInfo(billingInfo)),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromBillingInfoDTO(result.body);
        }
        return undefined;
    };
}
