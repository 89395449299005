import React from 'react';
import { InputBaseComponentProps } from '@talentmesh/core';
import { NumericFormat } from 'react-number-format';

interface AmountFormatterProps extends Omit<InputBaseComponentProps, 'defaultValue'> {}

function AmountFormatter(props: AmountFormatterProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
        />
    );
}

export default AmountFormatter;
