import { useState } from 'react';
import { useNotificationContext } from '../../../Context/NotificationContext';
import { useUsersClient } from '../../../Hooks/ClientHooks';
import UIStrings from '../../../Utils/UIStrings';

interface UseResendEmailPanelStateInterface {
    resendHandler: () => Promise<void>;
    resendButtonLoading: boolean;
}

function useResendEmailPanelState(): UseResendEmailPanelStateInterface {
    const client = useUsersClient();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();
    const [resendButtonLoading, setResendButtonLoading] = useState<boolean>(false);

    const resendHandler = async () => {
        try {
            setResendButtonLoading(true);
            await client.sendVerificationEmailAsync();
            showSuccessToaster('', UIStrings.VerificationEmailSent);
        } catch (error) {
            showFailToaster('', UIStrings.AnErrorOccurredPleaseTryAgain);
        }
        setResendButtonLoading(false);
    };

    return { resendHandler, resendButtonLoading };
}

export default useResendEmailPanelState;
