import React from 'react';
import { Divider, DividerProps } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

export function VerticalDivider(props: DividerProps): JSX.Element {
    const theme = useTheme();

    return <Divider orientation="vertical" color={theme.palette.border.main} variant="middle" flexItem {...props} />;
}

export function HorizontalDivider(props: DividerProps): JSX.Element {
    const theme = useTheme();

    return <Divider orientation="horizontal" color={theme.palette.border.main} variant="middle" flexItem {...props} />;
}
