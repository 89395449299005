import { DropdownOption } from '@talentmesh/core';

const employmentTypeOptions: DropdownOption[] = [
    { id: 'FullTime', name: 'Full-time' },
    { id: 'Permanent', name: 'Permanent' },
    { id: 'Contract', name: 'Contract' },
    { id: 'PartTime', name: 'Part-time' },
    { id: 'Temporary', name: 'Temporary' },
    { id: 'Internship', name: 'Internship' },
];

export default employmentTypeOptions;
