import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import { useCandidatesClient, useAssessmentClient } from '../../../../Hooks/ClientHooks';
import { CandidateRowEntry, candidateRowEntryFromCandidateInviteDTO } from '../../../../Models/CandidateRowEntry';
import ProcessingState from '../../../../Models/ProcessingState';
import UIStrings from '../../../../Utils/UIStrings';
import RoutePath from '../../../../Routing/RoutePath';
import InviteCandidatesPageRouteParams from '../Utils/InviteCandidatesPageRouteParams';
import { useInvitationPageContext } from './InvitationPageContext';
import useCompanyId from '../../../../Hooks/UseCompanyId';

interface InviteCandidateContextData {
    processingState: ProcessingState;
    dirtyCount: number;
    candidates: Array<CandidateRowEntry>;
    selectedCandidateIds: string[];
    confirmationDialogOpen: boolean;
    openLink: string;
    shouldBlockNavigation: boolean;
    unblockNavigation: () => void;
    closeConfirmationDialog: () => Promise<void>;

    copyLinkDialogOpen: boolean;
    setCopyLinkDialogOpen: (value: boolean) => void;

    previewInvitationEmailDialogOpen: boolean;
    setPreviewInvitationEmailDialogOpen: (value: boolean) => void;

    createCandidateAccountAsync: (candidate: CandidateRowEntry) => Promise<string>;
    loadCandidatesAsync: (assessmentId: string, loadCandidatesReadOnly?: boolean) => Promise<void>;
    inviteCandidatesAsync: (allowDialog: boolean) => Promise<string>;
    updateCandidateAsync: (candidateUpdate: CandidateRowEntry) => Promise<string>;
    deleteCandidateAsync: (candidate: CandidateRowEntry) => Promise<void>;
    deleteCandidatesAsync: (forceDelete?: boolean) => Promise<void>;
    selectCandidate: (candidate: CandidateRowEntry, checked: boolean) => void;
    navigateToMyRecruitments: () => void;

    dispose: () => void;
}

const InviteCandidateContext = createContext<InviteCandidateContextData | undefined>(undefined);

const InviteCandidateContextProvider = InviteCandidateContext.Provider;

interface InviteCandidateContextProps {
    children: ReactNode;
}

export function InviteCandidateProvider({ children }: InviteCandidateContextProps): JSX.Element {
    const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.Idle);
    const [dirtyCount, setDirtyCount] = useState(0);
    const [candidates, setCandidates] = useState<Array<CandidateRowEntry>>([]);
    const candidateClient = useCandidatesClient();
    const assessmentClient = useAssessmentClient();
    const [selectedCandidateIds, setSelectedCandidateIds] = useState<string[]>([]);
    const { recruitmentId } = useParams<Partial<InviteCandidatesPageRouteParams>>();
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [openLink, setOpenLink] = useState<string>('');
    const [copyLinkDialogOpen, setCopyLinkDialogOpen] = useState<boolean>(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
    const [previewInvitationEmailDialogOpen, setPreviewInvitationEmailDialogOpen] = useState<boolean>(false);

    const { clientId } = useInvitationPageContext();
    const companyId = useCompanyId();
    const navigate = useNavigate();

    const { showSuccessToaster } = useNotificationContext();

    useEffect(() => {
        const dirtyCandidatesCount = candidates.filter((x) => !x.isReadOnly).length;
        setDirtyCount(dirtyCandidatesCount);
        setShouldBlockNavigation(dirtyCandidatesCount > 0);
    }, [candidates]);

    const loadCandidatesAsync = async (assessmentId: string, loadCandidatesReadOnly?: boolean): Promise<void> => {
        setProcessingState(ProcessingState.Processing);
        const response = await assessmentClient.getCandidatesAsync(assessmentId);

        setCandidates(() => {
            const nextState: CandidateRowEntry[] = [];

            response.candidates.forEach((item) => {
                nextState.push(candidateRowEntryFromCandidateInviteDTO(item, loadCandidatesReadOnly));
            });

            return nextState;
        });
        setOpenLink(response.openLink);
        setProcessingState(ProcessingState.Success);
    };

    useEffect(() => {
        const doLoadCandidates = async () => await loadCandidatesAsync(recruitmentId!, true);
        doLoadCandidates();
    }, []);

    const closeConfirmationDialog = async () => {
        setConfirmationDialogOpen(false);
        await loadCandidatesAsync(recruitmentId!, true);
        showSuccessToaster('', UIStrings.XApplicantsHasBeenInvited(dirtyCount));
    };

    const navigateToMyRecruitments = () => {
        if (dirtyCount > 0) {
            showSuccessToaster('', UIStrings.XApplicantsHasBeenInvited(dirtyCount));
        }

        const recruitmentOverviewPath = generatePath(RoutePath.AssessmentsActive, { companyId, clientId });
        navigate(recruitmentOverviewPath);
    };

    const createCandidateAccountAsync = async (candidate: CandidateRowEntry) => {
        let errorText = '';
        setProcessingState(ProcessingState.Processing);
        try {
            const existing = candidates.find((item) => item.email === candidate.email);
            if (existing) {
                setProcessingState(ProcessingState.Success);
                return UIStrings.ApplicantAlreadyExistsInCurrentRecruitment;
            }
            const account = await candidateClient.createCandidateAccountAsync(candidate.email);
            setProcessingState(ProcessingState.Success);

            setCandidates([
                {
                    ...candidate,
                    id: account.id as string,
                },
                ...candidates,
            ]);
            setShouldBlockNavigation(true);
        } catch (e: any) {
            errorText = e.message || 'error';
            setProcessingState(ProcessingState.Error);
        }

        return errorText;
    };

    const inviteCandidatesAsync = async (allowDialog: boolean = false): Promise<string> => {
        let errorText = '';
        setProcessingState(ProcessingState.Processing);
        try {
            const invites = candidates.filter((item) => !item.isReadOnly);
            if (invites.length > 0) {
                await candidateClient.inviteCandidatesAsync(recruitmentId!, invites);
            }
            setProcessingState(ProcessingState.Success);

            if (allowDialog) {
                setConfirmationDialogOpen(true);
            } else {
                closeConfirmationDialog();
            }

            setShouldBlockNavigation(false);
        } catch (e: any) {
            errorText = e.message || 'error';
            setProcessingState(ProcessingState.Error);
        }

        return errorText;
    };

    const updateCandidateAsync = async (candidate: CandidateRowEntry): Promise<string> => {
        let errorText = '';
        setProcessingState(ProcessingState.Processing);
        try {
            const existing = candidates.find((item) => item.email === candidate.email && item.id !== candidate.id);
            if (existing) {
                setProcessingState(ProcessingState.Success);
                return UIStrings.ApplicantAlreadyExistsInCurrentRecruitment;
            }
            let newId = candidate.id;
            const oldValue = candidates.find((item) => item.id === candidate.id);
            if (oldValue) {
                const account = await candidateClient.createCandidateAccountAsync(candidate.email);
                newId = account.id as string;

                setCandidates((prev) => {
                    return prev.map((item) => {
                        if (item.id === oldValue.id) {
                            return {
                                ...candidate,
                                id: newId,
                            };
                        }
                        return item;
                    });
                });
            }

            setProcessingState(ProcessingState.Success);
        } catch (e: any) {
            errorText = e.message || 'error';
            setProcessingState(ProcessingState.Error);
        }

        return errorText;
    };

    const deleteCandidateAsync = async (candidate: CandidateRowEntry): Promise<void> => {
        setCandidates((prev) => prev.filter((item) => item.id !== candidate.id));
    };

    const deleteCandidatesAsync = async (forceDelete?: boolean): Promise<void> => {
        const ids = forceDelete ? candidates.filter((x) => !x.isReadOnly).map((x) => x.id) : selectedCandidateIds;

        setCandidates((prev) => prev.filter((item) => ids.findIndex((x) => x === item.id) === -1));

        setSelectedCandidateIds([]);
    };

    const selectCandidate = (candidate: CandidateRowEntry, selected: boolean) => {
        setSelectedCandidateIds((prevState) => {
            let nextState: any[];
            if (selected) {
                nextState = [...prevState, candidate.id];
            } else {
                nextState = prevState.filter((x) => x !== candidate.id);
            }

            return nextState;
        });
    };

    const dispose = () => {
        setCandidates([]);
        setSelectedCandidateIds([]);
    };

    return (
        <InviteCandidateContextProvider
            value={{
                processingState,
                dirtyCount,
                candidates,
                selectedCandidateIds,
                confirmationDialogOpen,
                openLink,
                closeConfirmationDialog,
                shouldBlockNavigation,
                unblockNavigation: () => setShouldBlockNavigation(false),

                copyLinkDialogOpen,
                setCopyLinkDialogOpen,

                previewInvitationEmailDialogOpen,
                setPreviewInvitationEmailDialogOpen,

                createCandidateAccountAsync,
                loadCandidatesAsync,
                inviteCandidatesAsync,
                updateCandidateAsync,
                deleteCandidateAsync,
                deleteCandidatesAsync,
                selectCandidate,
                navigateToMyRecruitments,

                dispose,
            }}
        >
            {children}
        </InviteCandidateContextProvider>
    );
}

export function useInviteCandidateContext() {
    const context = useContext(InviteCandidateContext);
    if (!context) {
        throw new Error('useInviteCandidateContext must be used within the InviteCandidateContext.Provider');
    }
    return context;
}
