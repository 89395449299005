import * as yup from 'yup';
import UIStrings from '../../Utils/UIStrings';
import YupValidationErrors from '../../Utils/YupValidationErrors';

export default function useAccountFormValidationScheme() {
    const maxLength = 128;
    return yup.object({
        firstName: yup
            .string()
            .required(UIStrings.Required)
            .max(maxLength, YupValidationErrors.MaxSymbolsValidationError),
        lastName: yup
            .string()
            .required(UIStrings.Required)
            .max(maxLength, YupValidationErrors.MaxSymbolsValidationError),
        companyName: yup
            .string()
            .required(UIStrings.Required)
            .max(maxLength, YupValidationErrors.MaxSymbolsValidationError),
    });
}
