import React from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, List, ListItem, Box, Stack, Typography, TypographyIcon } from '@talentmesh/core';
import { PersonOutlineOutlinedIcon, RemoveCircleOutlineIcon } from '@talentmesh/icons';
import { useActionDialogContext } from '../Context/ActionDialogContext';

interface DialogListProps {
    title: string;
    readonly?: boolean;
}
function DialogList({ title, readonly }: DialogListProps): JSX.Element {
    const theme = useTheme();
    const { selectedCandidates, removeCandidate } = useActionDialogContext();

    return (
        <Stack spacing={1} sx={{ height: '100%' }}>
            <Typography variant="h3" color={theme.palette.text.hint}>
                {title}
            </Typography>

            <Box
                data-cy="dialogList"
                sx={{
                    height: theme.spacing(36.75),
                    border: `1px solid ${theme.palette.border.main}`,
                    borderRadius: theme.shape.tinyBorderRadius,
                    paddingBottom: theme.spacing(0.5),
                    overflowY: 'auto',
                }}
            >
                <List sx={{ padding: 0, paddingLeft: theme.spacing(1.25) }}>
                    {selectedCandidates.map((item) => {
                        return (
                            <ListItem
                                key={item.candidateId}
                                sx={{
                                    marginY: theme.spacing(1.25),
                                    padding: 0,
                                    '& .MuiListItemSecondaryAction-root': { right: theme.spacing(1.5) },
                                }}
                                secondaryAction={
                                    !readonly && (
                                        <IconButton
                                            color="error"
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => removeCandidate(item)}
                                            size="large"
                                        >
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    )
                                }
                            >
                                <TypographyIcon
                                    variant="body1"
                                    icon={<PersonOutlineOutlinedIcon />}
                                    sx={{
                                        color: theme.palette.text.menuTitle,
                                        fontWeight: theme.typography.fontWeightMedium,
                                    }}
                                >
                                    {item.name}
                                </TypographyIcon>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Stack>
    );
}

export default DialogList;
