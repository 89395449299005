import React, { useMemo } from 'react';
import { FormikDropdown } from '@talentmesh/forms';
import { DropdownOption } from '@talentmesh/core';
import { JobDetailsFieldTypes } from '../JobDetailsStepUtils';
import UIStrings from '../../../../../../Utils/UIStrings';

interface JobPostingOptionsProps {
    companyName: string;
    clientName: string;
    disabled?: boolean;
}

function getJobPostingOptions(companyName: string, clientName: string): DropdownOption[] {
    return [
        { id: 'OnBehalf', name: UIStrings.CompanyOnBehalfOfClient(companyName, clientName) },
        { id: 'OnBehalfNoNamedBasis', name: UIStrings.CompanyOnBehalfOfClientNoNameBasis(companyName, clientName) },
    ];
}

function JobPostingOptions({ companyName, clientName, disabled }: JobPostingOptionsProps): JSX.Element {
    const options = useMemo(() => {
        return getJobPostingOptions(companyName, clientName);
    }, [companyName, clientName]);

    return (
        <FormikDropdown
            label={UIStrings.HowDoYouWantToPostTheJob}
            name={JobDetailsFieldTypes.JobPostingType}
            disabled={disabled}
            options={options}
            data-cy={JobDetailsFieldTypes.JobPostingType}
        />
    );
}

export default JobPostingOptions;
