import { Stack, Typography } from '@talentmesh/core';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../../../Utils/UIStrings';

const JobDetailsStepHeader = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={theme.spacing(1)} py={2.5}>
            <Typography variant="h2" color={theme.palette.text.menuTitle}>
                {UIStrings.ProvideKeyJobDetails}
            </Typography>
            <Typography variant="body1" color={theme.palette.secondary.main} textAlign="center">
                {UIStrings.QuickSummaryCreateStep}
            </Typography>
        </Stack>
    );
};

export default JobDetailsStepHeader;
