import { LogoutOptions } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { MenuViews } from '../../../../Models/ProfileMenu';
import { authProviderSettings, siteUrls } from '../../../../AppSettings';

const useProfileMenuButton = () => {
    const { logout } = useAuth0();
    const [currentView, setCurrentView] = useState<MenuViews>(MenuViews.Main);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = !!anchorEl;

    const handleClick: React.MouseEventHandler<HTMLElement> = (event) => {
        setCurrentView(MenuViews.Main);
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const logoutHandler = () => {
        const logoutOptions: LogoutOptions = {
            client_id: authProviderSettings.clientId,
            returnTo: siteUrls.homePageUrl,
        };
        logout(logoutOptions);
    };

    return {
        anchorEl,
        setAnchorEl,
        open,
        handleClick,
        currentView,
        setCurrentView,
        logoutHandler,
    };
};

export default useProfileMenuButton;
