import React from 'react';
import { TestTypes } from '../../../../../Models/Configuration';
import UIStrings from '../../../../../Utils/UIStrings';
import AptitudeTab from './AptitudeTab';
import EmotionalIntelligenceTab from './EmotionalIntelligenceTab';
import PersonalityTab from './PersonalityTab';
import SkillsTab from './SkillsTab';

interface TabByTestTypeSwitcherProps {
    testType: TestTypes;
}

function TabByTestTypeSwitcher({ testType }: TabByTestTypeSwitcherProps): JSX.Element {
    switch (testType) {
        case TestTypes.Aptitude:
            return <AptitudeTab />;
        case TestTypes.EmotionalIntelligence:
            return <EmotionalIntelligenceTab />;
        case TestTypes.Personality:
            return <PersonalityTab />;
        case TestTypes.Skills:
            return <SkillsTab />;
        default:
            throw new Error(UIStrings.TestNotSupportedError(testType));
    }
}

export default TabByTestTypeSwitcher;
