import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import { getAmountWithCurrencySymbol } from '../../../Utils/Amounts/AmountHelpers';

interface TotalAmountLineProps {
    description?: string;
    amount: number;
    currency: string;
}

const TotalAmountLine = ({ description = UIStrings.AmountDueToday, amount, currency }: TotalAmountLineProps) => {
    const theme = useTheme();
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
                fontWeight={700}
                variant="body1"
                color={theme.palette.text.menuTitle}
                sx={{ maxWidth: { xs: theme.spacing(12), md: '100%' } }}
            >
                {description}
            </Typography>
            <Typography variant="body1" fontWeight={700} color={theme.palette.text.menuTitle}>
                {getAmountWithCurrencySymbol(amount, currency)}
            </Typography>
        </Stack>
    );
};

export default TotalAmountLine;
