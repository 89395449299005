import React from 'react';
import { Box, BoxProps } from '@talentmesh/core';

export interface WideTabPanelProps extends Pick<BoxProps, 'children'> {
    value: string;
    tabKey: string;
}

function WideTabPanel({ tabKey, children, value }: WideTabPanelProps): JSX.Element {
    return tabKey !== value ? <></> : <Box>{children}</Box>;
}
export default WideTabPanel;
