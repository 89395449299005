import { useTheme } from '@mui/material';
import { Page } from '@talentmesh/core';
import React from 'react';
import TopBar from '../../Components/TopBars/TopBar';
import ClientsOverviewCards from './components/ClientsOverviewCards/ClientsOverviewCards';
import ClientsOverviewHeader from './components/ClientsOverviewHeader/ClientsOverviewHeader';
import { ClientsOverviewContextProvider } from './contexts/ClientsOverviewContext';

const ClientsOverviewPage = () => {
    const theme = useTheme();

    return (
        <ClientsOverviewContextProvider>
            <Page
                topBar={<TopBar />}
                header={<ClientsOverviewHeader />}
                sx={{ backgroundColor: theme.palette.background.aliciaBlue }}
                containerProps={{ disableGutters: true, sx: { pt: theme.spacing(3.75), height: '100%' } }}
            >
                <ClientsOverviewCards />
            </Page>
        </ClientsOverviewContextProvider>
    );
};

export default ClientsOverviewPage;
