/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Box, Button, Link, Stack, Typography } from '@talentmesh/core';
import BillingInfo from '../../../../Models/BillingInfo';
import UIStrings from '../../../../Utils/UIStrings';
import EditBillingAddressDialog from './EditBillingAddressDialog';
import PaymentSection from './PaymentSection';

interface BillingAddressProps {
    billingInfo: BillingInfo | undefined;
    setBillingInfo: React.Dispatch<React.SetStateAction<BillingInfo | undefined>>;
}

const BillingAddress = ({ billingInfo, setBillingInfo }: BillingAddressProps) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const editBillingAddressComponent = billingInfo ? (
        <Box>
            <Button variant="outlined" color="secondary" size="small" fullWidth={false} onClick={handleDialogOpen}>
                {UIStrings.Edit}
            </Button>
        </Box>
    ) : null;

    return (
        <>
            <PaymentSection header={UIStrings.BillingAddress} actionComponent={editBillingAddressComponent}>
                {billingInfo ? (
                    <Stack>
                        <Typography variant="body1" color={theme.palette.text.primary}>
                            {billingInfo.billingTo}
                        </Typography>
                        <Typography variant="body1" color={theme.palette.text.primary}>
                            {billingInfo.addressLine1}
                        </Typography>
                        <Typography variant="body1" color={theme.palette.text.primary}>
                            {billingInfo.addressLine2}
                        </Typography>
                        <Typography variant="body1" color={theme.palette.text.primary}>
                            {billingInfo.city}, {billingInfo.state} {billingInfo.postalCode}
                        </Typography>
                        <Typography variant="body1" color={theme.palette.text.primary}>
                            {billingInfo.country}
                        </Typography>
                    </Stack>
                ) : (
                    <Box>
                        <Link component="button" variant="body2" onClick={handleDialogOpen}>
                            {UIStrings.AddBillingAddress}
                        </Link>
                    </Box>
                )}
            </PaymentSection>
            <EditBillingAddressDialog
                open={open}
                setBillingInfo={setBillingInfo}
                onClose={handleClose}
                billingInfo={billingInfo}
            />
        </>
    );
};

export default BillingAddress;
