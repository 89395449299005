import { generatePath } from 'react-router';
import useQuery from '../../../../Hooks/UseQuery';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import useCompanyId from '../../../../Hooks/UseCompanyId';
import useClientIdPathParam from '../../../../Hooks/UseClientIdPathParam';

const useAssessmentCurrentTab = () => {
    const tab = useQuery('filter');
    const companyId = useCompanyId();
    const clientId = useClientIdPathParam();

    return tab === UIStrings.Closed.toLowerCase()
        ? generatePath(RoutePath.AssessmentsClosed, { companyId, clientId })
        : generatePath(RoutePath.AssessmentsActive, { companyId, clientId });
};

export default useAssessmentCurrentTab;
