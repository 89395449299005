import { useTheme } from '@mui/material';
import React from 'react';
import { Typography } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';
import TeamMemberTabContent from './TeamMemberTabContent';
import { TeamMembersContext } from './Context/TeamMembersContext';
import useTeamMembersContextValue from './Context/useTeamMembersContextValue';

const TeamMembersTab = () => {
    const theme = useTheme();
    const value = useTeamMembersContextValue();

    return (
        <TeamMembersContext.Provider value={value}>
            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2.5) }}>
                {UIStrings.TeamMembers}
            </Typography>
            <TeamMemberTabContent />
        </TeamMembersContext.Provider>
    );
};

export default TeamMembersTab;
