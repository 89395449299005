import React, { MouseEventHandler } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, LoadingButton } from '@talentmesh/core';
import { ImageCropStrings } from './ImageCropUtils';
import { ImageCropContextProps } from './ImageCropContext';

export interface ImageCropDialogActionsProps
    extends Pick<ImageCropStrings, 'uploadButtonText'>,
        Pick<ImageCropContextProps, 'processing'> {
    onUpload: MouseEventHandler<HTMLButtonElement>;
}

export const ImageCropDialogActions = ({
    onUpload,
    processing,
    uploadButtonText,
}: ImageCropDialogActionsProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            sx={{
                justifyContent: 'flex-end',
                marginTop: theme.spacing(3.75),
            }}
        >
            <LoadingButton
                data-cy="ImageCropUploadButton"
                variant="contained"
                loading={processing}
                onClick={onUpload}
                sx={{ minHeight: theme.spacing(6.5), minWidth: theme.spacing(18.75) }}
            >
                {uploadButtonText}
            </LoadingButton>
        </Stack>
    );
};
