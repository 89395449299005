import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack } from '@talentmesh/core';
import BackButton from './BackButton';
import UIStrings from '../../../../Utils/UIStrings';
import { useInvitationPageContext } from '../Contexts/InvitationPageContext';

const Header = (): JSX.Element => {
    const theme = useTheme();
    const { recruitmentName } = useInvitationPageContext();
    return (
        <Stack spacing={1.25} sx={{ alignItems: 'flex-start' }}>
            <BackButton />
            <Stack direction="column">
                <Typography variant="h3" color={theme.palette.text.menuTitle}>
                    {`${UIStrings.AttractApplicants} ${UIStrings.For} ${recruitmentName}`}
                </Typography>
                <Typography variant="body1" color={theme.palette.common.black}>
                    {UIStrings.EasilyAttractTopApplicantsThrough}
                </Typography>
            </Stack>
        </Stack>
    );
};
export default Header;
