import React from 'react';
import { TMDataGrid } from '@talentmesh/core';
import { gridClasses } from '@mui/x-data-grid';
import { useTeamMembersContext } from '../Context/TeamMembersContext';
import { TeamMember } from '../../../../../Models/TeamMember';
import TeamMemberRemoveDialog from './Components/TeamMemberRemoveDialog';
import TeamMemberInvitationRevokeDialog from './Components/TeamMemberInvitationRevokeDialog';
import useTeamMembersTableColumns from './Hooks/useTeamMembersTableColumns';
import PaginationConsts from '../../../../../Utils/PaginationConsts';
import NoTeamMembersOverlay from './Components/NoTeamMembersOverlay';

export interface TeamMembersTableDialogs {
    removeMember?: TeamMember;
    revokeInvitee?: TeamMember;
}

const TeamMembersTable = () => {
    const { teamMembers, isOperationLoading, totalMembersCount, pageNumber, handlePaginationModelChange } =
        useTeamMembersContext();
    const columns = useTeamMembersTableColumns();

    return (
        <>
            <TMDataGrid
                columns={columns}
                rows={teamMembers}
                autoHeight
                getRowId={(row) => row.id}
                rowHeight={90}
                loading={isOperationLoading}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                sx={{
                    [`& .${gridClasses.row}:hover`]: {
                        '&:hover': {
                            backgroundColor: 'inherit',
                            cursor: 'default',
                        },
                    },
                    [`& .${gridClasses.cell}`]: {
                        borderBottom: 'none',
                        '&:focus-within': {
                            outline: 'none',
                        },
                    },
                    [`& .${gridClasses.columnHeader}`]: {
                        cursor: 'default',
                        backgroundColor: 'transparent',
                    },
                }}
                paginationModel={{ pageSize: PaginationConsts.regularPageSize, page: pageNumber }}
                onPaginationModelChange={handlePaginationModelChange}
                rowCount={totalMembersCount}
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                slots={{
                    noRowsOverlay: NoTeamMembersOverlay,
                }}
                pageSizeOptions={[PaginationConsts.regularPageSize]}
            />
            <TeamMemberInvitationRevokeDialog />
            <TeamMemberRemoveDialog />
        </>
    );
};

export default TeamMembersTable;
