import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingOverlay } from '@talentmesh/core';
import { createContactFormId, hubspotPortalId } from '../../AppSettings';
import { useUserContext } from '../../Context/UserContext';
import SubmitFormDTO, { createAccountFormData } from '../../DTO/Marketing/SubmitFormDTO';
import UTMCookieNames from '../../DTO/Marketing/UTMCookieNames';
import { useHubspotClient } from '../../Hooks/ClientHooks';
import RoutePath from '../../Routing/RoutePath';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';
import getCookieSafe from '../../Utils/Cookies/CookieUtils';

function FinalizeRegistrationPage(): JSX.Element {
    const hubspotClient = useHubspotClient();
    const { user } = useUserContext();
    const [isCreateContactProcessing, setIsCreateContactProcessing] = useState<boolean>(true);
    const [error, setError] = useState<Error | undefined>(undefined);

    const utmSource = getCookieSafe(UTMCookieNames.UtmSource);
    const utmMedium = getCookieSafe(UTMCookieNames.UtmMedium);
    const utmCampaign = getCookieSafe(UTMCookieNames.UtmCampaign);
    const utmTerm = getCookieSafe(UTMCookieNames.UtmTerm);
    const utmContent = getCookieSafe(UTMCookieNames.UtmContent);

    useEffect(() => {
        async function createMarketingContact() {
            try {
                if (user && user.email) {
                    const formData: SubmitFormDTO = createAccountFormData(
                        user.email,
                        utmSource,
                        utmMedium,
                        utmCampaign,
                        utmTerm,
                        utmContent,
                    );

                    await hubspotClient.submitForm(hubspotPortalId, createContactFormId, formData);
                }
            } catch (exception) {
                setError(exception as Error);
            } finally {
                setIsCreateContactProcessing(false);
            }
        }
        createMarketingContact();
    }, []);

    if (error) {
        return <Navigate to={RoutePath.Error} />;
    }

    if (isCreateContactProcessing) {
        return <LoadingOverlay />;
    }

    return <Navigate to={RoutePath.Root} />;
}

export default withTalentMeshUserRequired(FinalizeRegistrationPage);
