import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { FiberManualRecordIcon } from '@talentmesh/icons';
import UIStrings from '../../../../../Utils/UIStrings';

interface CandidateTestMonitoringFeatureProps {
    label: string;
    isEnabled: boolean;
}

function CandidateTestMonitoringFeature({ label, isEnabled }: CandidateTestMonitoringFeatureProps): JSX.Element {
    const theme = useTheme();
    const getColor = (value: boolean): string => {
        if (value) {
            return theme.palette.success.main;
        }
        return theme.palette.error.main;
    };

    const getText = (value: boolean): string => {
        if (value) {
            return UIStrings.Enabled;
        }
        return UIStrings.Disabled;
    };
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Typography variant="body2">{`${label}:`}</Typography>
            <FiberManualRecordIcon sx={{ color: getColor(isEnabled) }} />
            <Typography variant="body2" color={getColor(isEnabled)}>
                {getText(isEnabled)}
            </Typography>
        </Stack>
    );
}

export default CandidateTestMonitoringFeature;
