import React from 'react';
import { Box, Link, SpanTypography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../Utils/UIStrings';
import { calendlyJobBoardSetUpUrl } from '../../../../AppSettings';

const JobBoardBookMeeting = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                marginTop: theme.spacing(1.25),
                padding: theme.spacing(2.5),
                backgroundColor: theme.palette.hue.primary.h50,
                borderRadius: theme.shape.smallBorderRadius,
            }}
        >
            <SpanTypography variant="body1" color="secondary">
                {`${UIStrings.IfYouAreHavingIssuesConnecting} `}
            </SpanTypography>
            <Link target="_blank" href={calendlyJobBoardSetUpUrl}>
                <SpanTypography variant="body1" color="primary" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                    {UIStrings.BookAMeeting}
                </SpanTypography>
            </Link>
            <SpanTypography variant="body1" color="secondary">
                {` ${UIStrings.WithUsAndWeWillHelpYouConnectIt}`}
            </SpanTypography>
        </Box>
    );
};

export default JobBoardBookMeeting;
