import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Paper, TabContext, TabList, TabPanel, TextTab } from '@talentmesh/core';
import RoutePath from '../../../Routing/RoutePath';
import UIStrings from '../../../Utils/UIStrings';
import PaymentTab from '../AccountSettingsTabs/PaymentTab/PaymentTab';
import AccountInfoForm from './AccountInfoForm';
import AccountNotifications from './AccountNotifications';
import EditEmailTemplate from './EditEmailTemplateTab';
import CompanyProfileTab from './CompanyProfileTab';
import TeamMembersTab from '../AccountSettingsTabs/TeamMembersTab/TeamMembersTab';
import CareerPageTab from './CareerPageTab';
import useCompanyId from '../../../Hooks/UseCompanyId';
import useRouteMatch from '../../../Hooks/useRouteMatch';

function AccountSettingsTabs(): JSX.Element {
    const theme = useTheme();
    const companyId = useCompanyId();

    const routeMatch = useRouteMatch([
        RoutePath.AccountSettingsGeneral,
        RoutePath.AccountSettingsCompanyProfile,
        RoutePath.AccountSettingsCareerPage,
        RoutePath.AccountSettingsNotifications,
        RoutePath.AccountSettingsPayment,
        RoutePath.AccountSettingsEmailTemplates,
        RoutePath.AccountSettingsTeamMembers,
    ]);

    const currentTab = routeMatch || RoutePath.AccountSettingsGeneral;
    const [value, setValue] = useState(currentTab);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Paper
            elevation={0}
            sx={{
                marginTop: 6,
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.smallBorderRadius,
            }}
        >
            <TabContext value={value}>
                <TabList
                    sx={{
                        paddingLeft: 8,
                        borderBottom: `1px solid ${theme.palette.border.main}`,
                        borderTopRightRadius: theme.shape.smallBorderRadius,
                        borderTopLeftRadius: theme.shape.smallBorderRadius,
                        backgroundColor: theme.palette.table.header2,
                    }}
                    onChange={handleTabChange}
                    disableTabIndicator={false}
                >
                    <TextTab
                        label={UIStrings.GeneralSettingsTab}
                        value={RoutePath.AccountSettingsGeneral}
                        to={generatePath(RoutePath.AccountSettingsGeneral, { companyId })}
                        component={Link}
                    />
                    <TextTab
                        label={UIStrings.CompanyProfile}
                        value={RoutePath.AccountSettingsCompanyProfile}
                        to={generatePath(RoutePath.AccountSettingsCompanyProfile, { companyId })}
                        component={Link}
                    />
                    <TextTab
                        label={UIStrings.CareerPage}
                        value={RoutePath.AccountSettingsCareerPage}
                        to={generatePath(RoutePath.AccountSettingsCareerPage, { companyId })}
                        component={Link}
                    />
                    <TextTab
                        label={UIStrings.Notifications}
                        value={RoutePath.AccountSettingsNotifications}
                        to={generatePath(RoutePath.AccountSettingsNotifications, { companyId })}
                        component={Link}
                    />
                    <TextTab
                        label={UIStrings.PaymentSettingsTab}
                        value={RoutePath.AccountSettingsPayment}
                        to={generatePath(RoutePath.AccountSettingsPayment, { companyId })}
                        component={Link}
                    />
                    <TextTab
                        label={UIStrings.EditEmailTemplateSettingsTab}
                        value={RoutePath.AccountSettingsEmailTemplates}
                        to={generatePath(RoutePath.AccountSettingsEmailTemplates, { companyId })}
                        component={Link}
                    />
                    <TextTab
                        label={UIStrings.TeamMembers}
                        value={RoutePath.AccountSettingsTeamMembers}
                        to={generatePath(RoutePath.AccountSettingsTeamMembers, { companyId })}
                        component={Link}
                    />
                </TabList>

                <TabPanel value={RoutePath.AccountSettingsGeneral}>
                    <AccountInfoForm />
                </TabPanel>
                <TabPanel value={RoutePath.AccountSettingsCompanyProfile}>
                    <CompanyProfileTab />
                </TabPanel>
                <TabPanel value={RoutePath.AccountSettingsCareerPage}>
                    <CareerPageTab />
                </TabPanel>
                <TabPanel value={RoutePath.AccountSettingsNotifications}>
                    <AccountNotifications />
                </TabPanel>
                <TabPanel value={RoutePath.AccountSettingsPayment}>
                    <PaymentTab />
                </TabPanel>
                <TabPanel value={RoutePath.AccountSettingsEmailTemplates}>
                    <EditEmailTemplate />
                </TabPanel>
                <TabPanel value={RoutePath.AccountSettingsTeamMembers}>
                    <TeamMembersTab />
                </TabPanel>
            </TabContext>
        </Paper>
    );
}

export default AccountSettingsTabs;
