import React from 'react';
import { Paper } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';
import ScoreDetailsHeader from '../Components/Common/ScoreDetailsHeader';
import FactorsPanel from './Components/Factors/FactorsPanel';

function PersonalityScoreDetails(): JSX.Element {
    return (
        <Paper elevation={0} sx={{ p: 2.5 }}>
            <ScoreDetailsHeader description={UIStrings.PersonalityScoreDetailsHeader} />
            <FactorsPanel />
        </Paper>
    );
}

export default PersonalityScoreDetails;
