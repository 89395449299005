import { Stack, IconButton, Typography, SmallTMTooltip } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import AdvancedFilterTypes from '../../../../../Models/AdvancedFilterTypes';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import { AdvancedFilter } from '../../Models/AdvancedFilter';
import AdvancedFilterButton from '../Filter/AdvancedFilterButton';
import StatusFilterButton from '../Filter/StatusFilterButton';
import { CustomMenuItemWithValueProps } from '../Filter/CheckboxRow';

function AdvancedFiltersToolbar(): JSX.Element {
    const theme = useTheme();
    const { advancedFilters, updateAdvancedFilter, resetAdvancedFilterByType, resetAdvancedFilter } =
        useCandidatesOverviewContext();

    const sortFilters = (a: AdvancedFilter, b: AdvancedFilter): number => {
        return a.name?.localeCompare(b?.name);
    };

    const getMenuItemsByFilterType = (
        filters: AdvancedFilter[],
        filterType: AdvancedFilterTypes,
    ): CustomMenuItemWithValueProps[] => {
        return filters
            .filter((x) => x.filterType === filterType)
            .sort(sortFilters)
            .map(mapFilter2MenuItem);
    };

    const mapFilter2MenuItem = (filter: AdvancedFilter): CustomMenuItemWithValueProps => {
        const { id, name, count, selected } = filter;
        return {
            id,
            menuItemLabel: `${name} (${count})`,
            selected,
            changeCallback: () => {
                updateAdvancedFilter({ ...filter, selected: !selected });
            },
        };
    };

    const countryMenuItems = getMenuItemsByFilterType(advancedFilters, AdvancedFilterTypes.Country);
    const educationLevelMenuItems = getMenuItemsByFilterType(
        advancedFilters,
        AdvancedFilterTypes.HighestLevelOfEducation,
    );
    const educationFieldMenuItems = getMenuItemsByFilterType(advancedFilters, AdvancedFilterTypes.FieldOfEducation);
    const jobTypeMenuItems = getMenuItemsByFilterType(advancedFilters, AdvancedFilterTypes.JobType);

    return (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Typography
                variant="caption"
                color="secondary"
                sx={{ fontWeight: theme.typography.fontWeightBold }}
            >{`${UIStrings.Filter}:`}</Typography>
            <StatusFilterButton />
            {countryMenuItems.length > 0 && (
                <AdvancedFilterButton
                    label={UIStrings.Country}
                    menuItems={countryMenuItems}
                    resetCallback={() => resetAdvancedFilterByType(AdvancedFilterTypes.Country)}
                />
            )}
            {educationLevelMenuItems.length > 0 && (
                <AdvancedFilterButton
                    label={UIStrings.HighestLevelOfEducation}
                    menuItems={educationLevelMenuItems}
                    resetCallback={() => resetAdvancedFilterByType(AdvancedFilterTypes.HighestLevelOfEducation)}
                />
            )}
            {educationFieldMenuItems.length > 0 && (
                <AdvancedFilterButton
                    label={UIStrings.FieldOfEducation}
                    menuItems={educationFieldMenuItems}
                    resetCallback={() => resetAdvancedFilterByType(AdvancedFilterTypes.FieldOfEducation)}
                />
            )}
            {jobTypeMenuItems.length > 0 && (
                <AdvancedFilterButton
                    label={UIStrings.JobType}
                    menuItems={jobTypeMenuItems}
                    resetCallback={() => resetAdvancedFilterByType(AdvancedFilterTypes.JobType)}
                />
            )}
            <SmallTMTooltip title={UIStrings.ClearFilters} disableInteractive>
                <IconButton size="small" color="secondary" onClick={resetAdvancedFilter} data-cy="AdvancedFilterReset">
                    <CloseIcon />
                </IconButton>
            </SmallTMTooltip>
        </Stack>
    );
}

export default AdvancedFiltersToolbar;
