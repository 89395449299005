import React from 'react';
import TopBar from '../../Components/TopBars/TopBar';
import withSignupChecks from '../../Routing/withSignupChecks';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';
import Page from '../Page';
import AccountSettingsTabs from './Components/AccountSettingsTabs';
import withCompanyProfileRequired from '../../Routing/withCompanyProfileRequired';

function AccountSettingsPage(): JSX.Element {
    return (
        <Page topBar={<TopBar />}>
            <AccountSettingsTabs />
        </Page>
    );
}

export default withCompanyProfileRequired(withTalentMeshUserRequired(withSignupChecks(AccountSettingsPage)));
