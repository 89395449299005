import React from 'react';
import { Typography, TooltipPaper } from '@talentmesh/core';
import { useTheme } from '@mui/material';

interface SkillSelectorTooltipBodyProps {
    message: string;
}

function SkillSelectorTooltipBody({ message }: SkillSelectorTooltipBodyProps): JSX.Element {
    const theme = useTheme();
    return (
        <TooltipPaper minWidth={theme.spacing(45)}>
            <Typography variant="body2" color={theme.palette.secondary.main}>
                {message}
            </Typography>
        </TooltipPaper>
    );
}

export default SkillSelectorTooltipBody;
