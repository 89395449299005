import { useTheme } from '@mui/material';
import { Button, FormControlLabel, FormHelperText, Paper, Stack, Switch } from '@talentmesh/core';
import { AddIcon } from '@talentmesh/icons';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ProcessingState from '../../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { useCreateRecruitmentContext } from '../../../../Create/Contexts/CreateRecruitmentContext';
import { ExperienceCriteria, experienceCriteriaFieldNames, maxExperienceCriteria } from '../ExperienceCriteria';
import CriteriaField from './CriteriaField/CriteriaField';

const IdentifyExperienceStepFields = () => {
    const theme = useTheme();
    const { formState, register, watch } = useFormContext<ExperienceCriteria>();
    const { fields, remove, append } = useFieldArray<ExperienceCriteria>({
        name: experienceCriteriaFieldNames.criteria,
    });
    const { experienceCriteriaProcessingState } = useCreateRecruitmentContext();
    const enableExperienceCriteria = watch('enableExperienceCriteria');
    const isLoading = experienceCriteriaProcessingState === ProcessingState.Processing;
    // for some reason there are 2 different places you access the same error message
    const criteriaEmptyError =
        !fields.length && (formState.errors.criteria?.root?.message || formState.errors.criteria?.message);

    const appendExperienceRow = () => {
        append({ value: '' });
    };

    return (
        <Paper elevation={0} sx={{ backgroundColor: theme.palette.background.aliciaBlue, padding: theme.spacing(2.5) }}>
            <Stack spacing={2.5}>
                <FormControlLabel
                    checked={enableExperienceCriteria}
                    control={<Switch {...register('enableExperienceCriteria')} />}
                    slotProps={{
                        typography: {
                            variant: 'h5',
                            color: theme.palette.text.menuTitle,
                            marginLeft: theme.spacing(1.25),
                        },
                    }}
                    label={UIStrings.ExperienceCriteriaStatus(enableExperienceCriteria)}
                />
                {isLoading
                    ? Array.from({ length: maxExperienceCriteria }).map((key: any, index) => (
                          <CriteriaField key={key} index={index} disabled />
                      ))
                    : fields.map((field, index) => (
                          <CriteriaField
                              key={field.id}
                              index={index}
                              disabled={!enableExperienceCriteria}
                              name={`${experienceCriteriaFieldNames.criteria}.${index}.value`}
                              onRemove={() => remove(index)}
                          />
                      ))}
                {!isLoading && fields.length < maxExperienceCriteria && (
                    <Button
                        fullWidth
                        variant="text"
                        onClick={appendExperienceRow}
                        startIcon={<AddIcon />}
                        disabled={!enableExperienceCriteria}
                        sx={{
                            height: theme.spacing(17.5),
                            color: theme.palette.secondary.main,
                            border: `1px solid ${theme.palette.border.main}`,
                        }}
                    >
                        {UIStrings.AddCriteria}
                    </Button>
                )}
                {enableExperienceCriteria && criteriaEmptyError && (
                    <FormHelperText error>{criteriaEmptyError}</FormHelperText>
                )}
            </Stack>
        </Paper>
    );
};

export default IdentifyExperienceStepFields;
