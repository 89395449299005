import { useTheme } from '@mui/material';
import { Grid, Stack } from '@talentmesh/core';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import StepProps from '../StepProps';
import JobAdExplanation from './Components/JobAdExplanation';
import JobAdStepNavigation from './Components/JobAdStepNavigation';
import JobAdStepHeader from './Components/JobAdStepHeader';
import JobAdTitle from './Components/JobAdTitle';
import JobDescription from './Components/JobDescription';
import RequiredSkills from './Components/RequiredSkills';
import {
    JobAdFormValues,
    fillInInitialSkills,
    jobAdFormValues2Model,
    jobAdModel2FormValues,
    jobAdValidationScheme,
} from './JobAdStepUtils';
import UIStrings from '../../../../../Utils/UIStrings';
import JobAdCompanyDescription from './Components/JobAdCompanyDescription';
import FailedToGenerateJobAdDialog from './Components/FailedToGenerateJobAdDialog';
import { useCreateRecruitmentContext } from '../../Create/Contexts/CreateRecruitmentContext';
import ProcessingState from '../../../../../Models/ProcessingState';
import { JobAd } from '../../Models/JobAd';

const CreateJobAdStep = ({ activeStep, handleNext, handleBack }: StepProps): JSX.Element => {
    const theme = useTheme();
    const ref = useRef<FormikProps<JobAdFormValues>>(null);
    const {
        jobDetails,
        jobAd,
        setJobAd,
        generateJobAdAsync,
        regenerateAtStep2,
        regenerateExperienceCriteria,
        showErrorDialog,
        closeDialog,
        generateExperienceCriteria,
        processingState,
        shouldGenerateJobAdCompanyDescription,
        setShouldRegenExperienceCriteria,
    } = useCreateRecruitmentContext();

    const [isJobAdGenerated, setIsJobAdGenerated] = useState(false);

    const shouldGenerateCompanyDescription = shouldGenerateJobAdCompanyDescription();

    let initialValues: JobAdFormValues = jobAdModel2FormValues(jobAd);
    initialValues.companyDescription =
        // jobAd.companyDescription is initialized as user.companyProfile.companyDescription
        // during creation of first recruitment (when user.companyProfile.companyDescription is empty)
        // we try to init form with jobAd.companyDescription (which points to the same value) => we have to generate company description with AI
        shouldGenerateCompanyDescription
            ? jobAd.companyDescription
            : // Second branch covers cases for creation of second and next recruitments
              // We need to handle situations below:
              // - User lands on step 2 for the first time during creation of second recruitment
              // In this case we init company description with jobAd.companyDescription, which contains data from user.companyProfile.companyDescription.
              //
              // - User edits company description while job ad is being generated / improved
              // In this case, completion of the call to AI endpoint will update state in context and trigger re-rendering with the new data.
              // This will cause the form to be re-initialized with the new data which is coming from the backend.
              // Now we have to merge to states carefully: one coming from context / backend and one coming from formik form.
              // Because we want to keep user changes for company description, we choose the most recent value for company description.
              // This most recent value is coming from formik form.
              //
              // - User navigates between the steps and comes back to step 2
              // For example, user navigates to step 3 and back to step 2.
              // Or user navigates to step 1 and back to step 2.
              // When user navigates away from step 2, all formik fields lose its inner state.
              // That's why in handlers responsible for navigation between steps we save formik values to context.
              // When user navigates back to step 2, we initialize formik form with values from context,
              // because there is no inner state for formik fields and ref.current?.values.companyDescription will be undefined.
              ref.current?.values.companyDescription || jobAd.companyDescription;

    initialValues = fillInInitialSkills(initialValues, ref.current?.values || jobAd);

    // override setJobAd because preview is saving jobAd on preview for some reason
    const saveJobAd = (newJobAd: JobAd) => {
        setJobAd(newJobAd);
        setShouldRegenExperienceCriteria(isJobAdGenerated || !!ref.current?.dirty);
    };

    const submitHandlerAsync = async (values: JobAdFormValues) => {
        setJobAd(jobAdFormValues2Model(values));

        if (regenerateExperienceCriteria || ref.current?.dirty) {
            generateExperienceCriteria();
        }

        if (handleNext) {
            handleNext();
        }
    };

    const handleBackWrapper = () => {
        const values = ref.current?.values || jobAd;
        saveJobAd(values);

        if (handleBack) {
            handleBack();
        }
    };

    useEffect(() => {
        const doGenerateJobAd = async () => {
            if (regenerateAtStep2) {
                await generateJobAdAsync();
                setIsJobAdGenerated(true);
            }
        };

        doGenerateJobAd();
    }, []);

    useEffect(() => {
        if (ref.current && isJobAdGenerated) {
            ref.current.setTouched(
                {
                    title: true,
                    description: true,
                    descriptionLen: true,
                    companyDescription: true,
                },
                true,
            );
        }
    }, [isJobAdGenerated]);

    return (
        <Grid container columns={14}>
            <Grid item xs={2} />
            <Grid item xs={10}>
                <JobAdStepHeader />
                <Formik
                    initialValues={initialValues}
                    onSubmit={submitHandlerAsync}
                    validationSchema={jobAdValidationScheme}
                    enableReinitialize
                    innerRef={ref}
                >
                    <Form>
                        <Stack
                            spacing={theme.spacing(4.375)}
                            px={theme.spacing(2.5)}
                            py={theme.spacing(3.75)}
                            sx={{
                                backgroundColor: theme.palette.background.aliciaBlue,
                                borderRadius: theme.shape.smallBorderRadius,
                                border: `1px solid ${theme.palette.border.main}`,
                            }}
                        >
                            <JobAdExplanation includeImproveWithAI />
                            <JobAdTitle loading={processingState === ProcessingState.Processing} />
                            <RequiredSkills />
                            <JobDescription initialHtml={initialValues.description} processingState={processingState} />
                            <JobAdCompanyDescription
                                initialHtml={initialValues.companyDescription}
                                processingState={processingState}
                                showLoadingOverlay={shouldGenerateCompanyDescription}
                            />
                        </Stack>
                        <JobAdStepNavigation
                            activeStep={activeStep}
                            handleBack={handleBackWrapper}
                            processingState={processingState}
                            jobAd={jobAd}
                            setJobAd={saveJobAd}
                            jobDetails={jobDetails}
                            nextButtonLabel={UIStrings.Next}
                        />
                    </Form>
                </Formik>
            </Grid>
            <Grid item xs={2} />
            <FailedToGenerateJobAdDialog
                open={showErrorDialog === 'JobAdGenerationError'}
                dialogTitle={UIStrings.JobAdGenerationErrorTitle}
                message1={UIStrings.JobAdGenerationErrorMessage}
                message2={UIStrings.PleaseGenerateAgainLater}
                close={closeDialog}
            />
            <FailedToGenerateJobAdDialog
                open={showErrorDialog === 'JobAdImproveError'}
                dialogTitle={UIStrings.JobAdImprovementErrorTitle}
                message1={UIStrings.JobAdImprovementErrorMessage}
                message2={UIStrings.PleaseImproveAgainLater}
                close={closeDialog}
            />
        </Grid>
    );
};

export default CreateJobAdStep;
