import { useTheme } from '@mui/material';
import React from 'react';
import { Box, IconButton, Stack, TMTooltip, TooltipPaper, Typography } from '@talentmesh/core';
import { HelpIcon } from '@talentmesh/icons';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { getColorByTestValidity, getTextByTestValidity } from './TestValidityUtils';

interface TestValidityScore {
    scoreName: string;
    descritpion: string;
}

const testValidityScores: Array<TestValidityScore> = [
    { scoreName: UIStrings.TestValidityVeryGood, descritpion: UIStrings.TestResultsCanBeReliedOn },
    { scoreName: UIStrings.TestValidityGood, descritpion: UIStrings.TestResultsCanBeReliedOn },
    { scoreName: UIStrings.TestValidityModerate, descritpion: UIStrings.UseWithCaution },
    { scoreName: UIStrings.TestValidityPoor, descritpion: UIStrings.ImpressionManagement },
    { scoreName: UIStrings.TestValidityVeryPoor, descritpion: UIStrings.ImpressionManagement },
];

interface TestValidityProps {
    value: number;
}

function TestValidity({ value }: TestValidityProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack direction="row" justifyContent="center" alignItems="center">
                <Typography variant="body2" color={theme.palette.text.primary}>
                    {UIStrings.TestValidity}
                </Typography>
                <TMTooltip
                    placement="bottom-end"
                    title={
                        <TooltipPaper minWidth={theme.spacing(56)}>
                            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                                <Typography variant="h5" color={theme.palette.text.menuTitle}>
                                    {UIStrings.TestValidityScore}
                                </Typography>
                                <Typography variant="body2" color={theme.palette.text.menuTitle}>
                                    {UIStrings.HighTestValidityScore}
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.info.light,
                                        borderRadius: theme.shape.smallBorderRadius,
                                        padding: 1,
                                        width: '100%',
                                    }}
                                >
                                    <Stack spacing={1}>
                                        {testValidityScores.map((score) => (
                                            <Box key={score.scoreName}>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    fontWeight="bold"
                                                    color={theme.palette.secondary.main}
                                                >
                                                    {`${score.scoreName} - `}
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color={theme.palette.secondary.main}
                                                >
                                                    {score.descritpion}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                            </Stack>
                        </TooltipPaper>
                    }
                >
                    <IconButton size="small" sx={{ color: theme.palette.secondary.main }}>
                        <HelpIcon fontSize="small" />
                    </IconButton>
                </TMTooltip>
            </Stack>
            <Typography variant="body2" fontWeight="bold" sx={{ color: getColorByTestValidity(theme, value) }}>
                {getTextByTestValidity(value)}
            </Typography>
        </Stack>
    );
}

export default TestValidity;
