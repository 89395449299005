import React from 'react';
import { Box } from '@talentmesh/core';
import { useTheme } from '@mui/system';

interface RecruitmentCreatedNotificationBodyProps {
    text: React.ReactNode;
}

const RecruitmentCreatedNotificationBody = ({ text }: RecruitmentCreatedNotificationBodyProps) => {
    const theme = useTheme();
    return <Box sx={{ pt: theme.spacing(2), pb: theme.spacing(2.5) }}>{text}</Box>;
};

export default RecruitmentCreatedNotificationBody;
