import { useTheme, Grid } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import { CareerPageStats, DefaultCareerPageStats } from '../../../../Models/Career';
import StatusChip from '../../../../Components/StatusChip';

interface CareerPageInfoProps {
    stats: CareerPageStats;
}

const CareerPageInfo = ({ stats }: CareerPageInfoProps) => {
    const theme = useTheme();
    const labelColumnSize = 1.5;

    return (
        <Stack direction="column" spacing={theme.spacing(2)}>
            <Grid container rowSpacing={theme.spacing(1.25)}>
                <Grid item xs={labelColumnSize}>
                    <Typography variant="body2">{UIStrings.PublishedDate}</Typography>
                </Grid>
                <Grid item xs={12 - labelColumnSize}>
                    <Typography variant="body2">{stats.publishedDate}</Typography>
                </Grid>
                <Grid item xs={labelColumnSize}>
                    <Typography variant="body2">{UIStrings.ActiveJobs}</Typography>
                </Grid>
                <Grid item xs={12 - labelColumnSize}>
                    <Typography variant="body2">{stats.activeJobs}</Typography>
                </Grid>
            </Grid>
            <Stack direction="row" spacing={theme.spacing(2)} alignItems="center">
                <StatusChip
                    color={stats.publishedDate === DefaultCareerPageStats.publishedDate ? 'warning' : 'success'}
                    label={
                        <Typography variant="body2">
                            {stats.publishedDate === DefaultCareerPageStats.publishedDate
                                ? UIStrings.CareerPageNotEnabled
                                : UIStrings.YourCareerPageIsActive}
                        </Typography>
                    }
                />
            </Stack>
        </Stack>
    );
};

export default CareerPageInfo;
