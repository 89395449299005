import { useTheme } from '@mui/material';
import React from 'react';
import { Box, SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

interface InterviewConfirmDialogBodyProps {
    name: string;
}

function InterviewConfirmDialogBody({ name }: InterviewConfirmDialogBodyProps): JSX.Element {
    const theme = useTheme();
    return (
        <Box>
            <SpanTypography variant="body2" color={theme.palette.text.primary}>
                {`${UIStrings.InterviewConfirmDialogBody1} `}
            </SpanTypography>
            <SpanTypography variant="body2" color={theme.palette.text.primary}>
                {name}
            </SpanTypography>
            <SpanTypography variant="body2" color={theme.palette.text.primary}>
                {UIStrings.InterviewConfirmDialogBody2}
            </SpanTypography>
        </Box>
    );
}

export default InterviewConfirmDialogBody;
