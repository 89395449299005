import { useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { Stack, Typography } from '@talentmesh/core';

interface InstructionItemProps {
    icon: ReactNode;
    header: string;
    instruction: string;
}

function InstructionItem({ icon, header, instruction }: InstructionItemProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{ alignContent: 'center' }}>
                <Stack
                    sx={{
                        borderRadius: '50%',
                        border: `1px solid ${theme.palette.secondary.main}`,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: theme.spacing(5),
                        height: theme.spacing(5),
                    }}
                >
                    {icon}
                </Stack>
                <Stack alignItems="center">
                    <Typography sx={{ margin: 'auto' }} variant="h4" color={theme.palette.text.menuTitle}>
                        {header}
                    </Typography>
                </Stack>
            </Stack>
            <Typography variant="body1" color={theme.palette.text.menuTitle}>
                {instruction}
            </Typography>
        </Stack>
    );
}

export default InstructionItem;
