import React from 'react';
import { Grid } from '@talentmesh/core';
import { useCandidatesResultsContext } from '../../../../../Contexts/CandidateResultsContext';
import EICharacteristicPanel from './Components/EICharacteristicPanel';

const colNum = 2;
const itemSize = 12 / colNum;

function EICharacteristicsPanel(): JSX.Element {
    const { results } = useCandidatesResultsContext();
    const { emotionalIntelligenceScore } = results;

    const characteristics = emotionalIntelligenceScore?.characteristics || [];
    return (
        <Grid container rowSpacing={5} paddingTop={5}>
            {characteristics
                .sort((a, b) => {
                    return a.characteristicName.localeCompare(b.characteristicName);
                })
                .map((characteristic) => (
                    <Grid item key={characteristic.characteristicName} xs={itemSize}>
                        <EICharacteristicPanel {...characteristic} />
                    </Grid>
                ))}
        </Grid>
    );
}

export default EICharacteristicsPanel;
