/* eslint-disable no-nested-ternary */
import React, { ReactNode } from 'react';
import { Stack } from '@talentmesh/core';
import DialogCardCandidateInfo from './DialogCardCandidateInfo';
import DialogCardTestInfo from './DialogCardTestInfo';
import { useActionDialogContext } from '../Context/ActionDialogContext';
import DialogCardContainer from './DialogCardContainer';

interface DialogCardProps {
    icon: React.ReactNode;
    dialogTitle: ReactNode;
    dialogBody: ReactNode;
}

function DialogCard({ dialogTitle, dialogBody, icon }: DialogCardProps): JSX.Element {
    const { selectedCandidates, availableTestTypes, includeExperienceCriteria } = useActionDialogContext();
    const candidate = selectedCandidates[0];

    return (
        <Stack spacing={2.5} height="100%" justifyContent="space-between">
            <Stack spacing={2.5} alignItems="flex-start">
                <DialogCardCandidateInfo name={candidate.name} email={candidate.candidateEmail} />
                <DialogCardTestInfo
                    aptitudeScore={candidate.aptitudePercentileScore}
                    emotionalIntelligenceScore={candidate.emotionalIntelligencePercentileScore}
                    personalityScore={candidate.personalityPercentileScore}
                    skillsScore={candidate.skillsPercentileScore}
                    talentScore={candidate.talentScore}
                    experienceCriteriaScore={candidate.experienceCriteriaScore}
                    testTypes={availableTestTypes}
                    includesExperienceCriteria={includeExperienceCriteria}
                />
            </Stack>
            <DialogCardContainer dialogTitle={dialogTitle} dialogBody={dialogBody} icon={icon} />
        </Stack>
    );
}

export default DialogCard;
