function isNullOrWhiteSpace(value?: string): boolean {
    return !value || value.trim() === '';
}

function resetEmptyStringToNull(value?: string | null): string | null {
    if (value === '' || value === undefined) {
        return null;
    }

    return value;
}

export { isNullOrWhiteSpace, resetEmptyStringToNull };
