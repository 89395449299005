import React from 'react';
import LoadingTab from '../LoadingTab';
import TeamMembersActionBar from './TeamMembersActionBar/TeamMembersActionBar';
import TeamMembersTable from './TeamMembersTable/TeamMembersTable';
import { useTeamMembersContext } from './Context/TeamMembersContext';

const TeamMemberTabContent = () => {
    const { isLoading, isTabLoadingError } = useTeamMembersContext();
    return (
        <LoadingTab isLoading={isLoading} loadingFailed={isTabLoadingError}>
            <TeamMembersActionBar />
            <TeamMembersTable />
        </LoadingTab>
    );
};

export default TeamMemberTabContent;
