import { useTheme } from '@mui/material';
import React from 'react';
import { Box, IconButton, Stack, TMTooltip, SpanTypography } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import CTMonitoringTooltipBody from './Components/CTMonitoringTooltipBody';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { useCreateRecruitmentContext } from '../../../../Create/Contexts/CreateRecruitmentContext';
import { useAssessmentSettingsContext } from '../../../../../../../Context/AssessmentSettingsContext';
import { getJobFunctionName } from '../../../../../../../Utils/JobFunctionSelectionUtils';

function SelectTestsStepHeader(): JSX.Element {
    const theme = useTheme();
    const { assessmentSettings } = useAssessmentSettingsContext();
    const {
        jobDetails: { jobFunctionId },
    } = useCreateRecruitmentContext();

    const jobFunctionName = getJobFunctionName(assessmentSettings.jobCategories, jobFunctionId);

    return (
        <Stack alignItems="center">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={1.5} py={2.5} mx={8.625}>
                <Box sx={{ textAlign: 'center' }}>
                    <SpanTypography
                        variant="h3"
                        color={theme.palette.text.menuTitle}
                    >{`${UIStrings.SkillsTestsForJobFunctionName(jobFunctionName ?? '')} `}</SpanTypography>
                </Box>
                <SpanTypography variant="body1" color={theme.palette.text.primary} textAlign="center">
                    {UIStrings.QuickSummaryRecommendationStep}
                    <TMTooltip title={<CTMonitoringTooltipBody />} placement="bottom">
                        <IconButton sx={{ color: theme.palette.secondary.light }} size="small">
                            <InfoIcon />
                        </IconButton>
                    </TMTooltip>
                </SpanTypography>
            </Stack>
        </Stack>
    );
}

export default SelectTestsStepHeader;
