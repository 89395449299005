import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import OidcActionButtons from './OidcActionButtons';
import CopyUrlSection from './CopyUrlSection';

function OidcErrorBody(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack spacing={theme.spacing(2)}>
            <Box>
                <Typography variant="h6" color={theme.palette.text.primary}>
                    {UIStrings.DueToSecurityReasonsWeCouldNotAuthenticateYourRequest}
                </Typography>
                <Typography variant="h6" color={theme.palette.text.primary}>
                    {UIStrings.PleaseTryAgain}
                </Typography>
            </Box>
            <OidcActionButtons />
            <CopyUrlSection />
        </Stack>
    );
}

export default OidcErrorBody;
