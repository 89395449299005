import { SpanTypography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useTeamMembersContext } from '../../Context/TeamMembersContext';
import ConfirmationDialog from './ConfirmationDialog';
import { useNotificationContext } from '../../../../../../Context/NotificationContext';

const TeamMemberInvitationRevokeDialog = () => {
    const { revokeInvitation, activeDialog, closeDialog, selectedMember } = useTeamMembersContext();
    const { showFailToaster } = useNotificationContext();

    const handleClose = () => closeDialog();
    const handleConfirm = async () => {
        try {
            await revokeInvitation(selectedMember!);
            handleClose();
        } catch (e) {
            showFailToaster();
        }
    };

    return (
        <ConfirmationDialog
            open={activeDialog === 'RevokeInvitationConfirmation'}
            title={UIStrings.RevokeInvitation}
            actionButtonText={UIStrings.RevokeInvitation}
            onClose={handleClose}
            onConfirm={handleConfirm}
        >
            <SpanTypography variant="body1">{`${UIStrings.AreYouSureYouWantToRevokeInvitationFor} `}</SpanTypography>
            <SpanTypography variant="body1" fontWeight="bold">
                {selectedMember?.email}
            </SpanTypography>
        </ConfirmationDialog>
    );
};

export default TeamMemberInvitationRevokeDialog;
