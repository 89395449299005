import { useTheme } from '@mui/material';
import React from 'react';
import { Box, SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import CopyToClipboard from './CopyToClipboard';

function CopyUrlSection(): JSX.Element {
    const theme = useTheme();
    return (
        <Box>
            <SpanTypography variant="body1" color={theme.palette.text.secondary}>
                {`${UIStrings.BookmarkingThisPageUseThisUrl}: `}
            </SpanTypography>
            <CopyToClipboard />
        </Box>
    );
}

export default CopyUrlSection;
