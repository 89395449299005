import React from 'react';
import { useTheme } from '@mui/material';
import { Box, Chip, Stack, Typography } from '@talentmesh/core';
import MultiFontWeightLabel from '../../../Components/MultiFontWeightLabel';
import UIStrings from '../../../../../../Utils/UIStrings';

interface MandatoryInformationOptionProps {
    label: string;
}

const MandatoryInformationOption = ({ label }: MandatoryInformationOptionProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Box p={2.5}>
            <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={5}>
                <Typography variant="h5" color={theme.palette.text.menuTitle}>
                    <MultiFontWeightLabel variant="h5" label={label} />
                </Typography>
                <Stack direction="row" spacing={1.25}>
                    <Chip
                        sx={{
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.primary.main,
                            paddingLeft: theme.spacing(1.25),
                            paddingRight: theme.spacing(1.25),
                            paddingTop: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            borderWidth: 0,
                            borderRadius: theme.shape.smallBorderRadius,
                            '& .MuiChip-label': {
                                padding: 0,
                            },
                        }}
                        label={UIStrings.Mandatory}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};

export default MandatoryInformationOption;
