import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, TooltipPaper, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../../../../Utils/UIStrings';

function CTMonitoringTooltipBody(): JSX.Element {
    const theme = useTheme();

    const listItems = [
        UIStrings.ApplicantTestMonitoringExplanations1,
        UIStrings.ApplicantTestMonitoringExplanations2,
        UIStrings.ApplicantTestMonitoringExplanations3,
    ];

    return (
        <TooltipPaper minWidth={theme.spacing(42)}>
            <Stack direction="column" spacing={theme.spacing(2)} justifyContent="start" alignContent="start">
                <Typography variant="h5" color={theme.palette.text.menuTitle}>
                    {UIStrings.ApplicantTestMonitoring}
                </Typography>
                {listItems.map((listItem) => (
                    <Typography key={listItem} variant="body2" color={theme.palette.text.menuTitle}>
                        {listItem}
                    </Typography>
                ))}
            </Stack>
        </TooltipPaper>
    );
}

export default CTMonitoringTooltipBody;
