import { useTheme } from '@mui/material';
import React from 'react';
import { Typography } from '@talentmesh/core';

interface SummaryTypographyProps {
    text: string;
}

function SummaryTypography({ text }: SummaryTypographyProps): JSX.Element {
    const theme = useTheme();
    return (
        <Typography
            variant="body2"
            color={theme.palette.text.menuTitle}
            sx={{
                margin: 0,
                padding: 0,
            }}
        >
            {text}
        </Typography>
    );
}

export default SummaryTypography;
