/* eslint-disable import/prefer-default-export */
import { RecruiterTestDTO } from '../../../../DTO/Tests/RecruiterTestDTO';
import { AssessmentSettings, TestTypes } from '../../../../Models/Configuration';
import UIStrings from '../../../../Utils/UIStrings';
import TestInfo from '../Models/TestInfo';
import getTestDuration from './getTestDuration';
import { getJobFunctionName } from '../../../../Utils/JobFunctionSelectionUtils';

function getTopicsDescriptionHeaderByTestType(totalTopics: number, testType: TestTypes): string {
    switch (testType) {
        case TestTypes.Aptitude:
            return `${totalTopics} ${UIStrings.CognitiveAbilityModules}:`;
        case TestTypes.EmotionalIntelligence:
        case TestTypes.Personality:
            return `${totalTopics} ${UIStrings.CharacteristicsIncluding}:`;
        case TestTypes.Skills:
            return `${totalTopics} ${UIStrings.Modules}:`;
        default:
            throw new Error(`Test type '${testType}' is not supported`);
    }
}

function RecruiterTestDTO2TestInfo(
    jobFunctionId: number,
    configuration: AssessmentSettings,
    dto?: RecruiterTestDTO,
): TestInfo | undefined {
    if (dto) {
        return {
            jobFunctionName: getJobFunctionName(configuration.jobCategories, jobFunctionId),
            testType: dto.testType,
            totalTopicsCount: dto.totalTopicsCount,
            topics: dto.topTopics,
            totalQuestions: dto.totalQuestionsCount,
            duration: getTestDuration(dto),
        };
    }

    return undefined;
}

export { RecruiterTestDTO2TestInfo, getTopicsDescriptionHeaderByTestType };
