import LocationDetails from '../../../../../../../../Models/LocationDetails';

function convertGooglePlaceToLocationDetails(place: google.maps.places.PlaceResult) {
    const addressComponents = place.address_components || [];

    const getAddressComponentByType = (type: string) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const component of addressComponents) {
            if (component.types.includes(type)) {
                return { shortName: component.short_name, longName: component.long_name };
            }
        }

        return undefined;
    };

    const city = getAddressComponentByType('locality');
    const administrativeAreaLevel3 = getAddressComponentByType('administrative_area_level_3');
    const administrativeAreaLevel2 = getAddressComponentByType('administrative_area_level_2');
    const administrativeAreaLevel1 = getAddressComponentByType('administrative_area_level_1');
    const country = getAddressComponentByType('country');

    const doRenderState = () => {
        return (country?.shortName === 'US' || country?.shortName === 'CA') && administrativeAreaLevel1;
    };

    const cityName = city?.longName || administrativeAreaLevel3?.longName; // According to Google manual, if type is (cities), when place should have either locality or administrative_area_level_3 (maybe both)
    const isCityAvailable = cityName !== undefined;
    // prepend city if available (not guaranteed with remote type of work arrangment)
    const withCity = (higherDivision: string) => {
        if (isCityAvailable) {
            return `${cityName}, ${higherDivision}`;
        } else {
            return higherDivision;
        }
    };

    // We have to build location description by our-self because we do not support localization
    const getLocationDescription = () => {
        if (doRenderState() && isCityAvailable) {
            return withCity(`${administrativeAreaLevel1?.shortName}, ${country?.longName}`);
        } else {
            return withCity(`${country?.longName}`);
        }
    };

    return {
        locationId: place.place_id,
        description: getLocationDescription(),
        geometry: { latitude: place.geometry?.location.lat(), longitude: place.geometry?.location.lng() },
        city,
        administrativeAreaLevel3,
        administrativeAreaLevel2,
        administrativeAreaLevel1,
        country,
        utcOffsetMinutes: place.utc_offset_minutes,
    } as LocationDetails;
}

export default convertGooglePlaceToLocationDetails;
