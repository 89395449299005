import * as yup from 'yup';
import YupValidationErrors from '../../../../../../Utils/YupValidationErrors';

const scoreConstraintMessage = YupValidationErrors.ConstraintNumberError(0, 100);

export const criteriaScoreSchema = yup.object({
    userDefinedJustification: yup
        .string()
        .required(YupValidationErrors.Required)
        .max(1024, YupValidationErrors.MaxSymbolsValidationError),
    userDefinedScore: yup
        .number()
        .typeError(YupValidationErrors.Required)
        .min(0, scoreConstraintMessage)
        .max(100, scoreConstraintMessage)
        .required(YupValidationErrors.Required),
});

export type CriteriaScoreFormValues = yup.InferType<typeof criteriaScoreSchema>;

export const criteriaScoreFieldNames: { [key in keyof CriteriaScoreFormValues]: key } = {
    userDefinedJustification: 'userDefinedJustification',
    userDefinedScore: 'userDefinedScore',
};
