import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { FormikCheckbox } from '@talentmesh/forms';
import { useTheme } from '@mui/material';
import { useField } from 'formik';
import CurrencyAutocomplete from './CurrencyAutocomplete';
import YearlySalaryRange from './YearlySalaryRange';
import UIStrings from '../../../../../../Utils/UIStrings';
import YupValidationErrors from '../../../../../../Utils/YupValidationErrors';
import SalaryError from './SalaryError';
import { JobDetailsFieldTypes } from '../JobDetailsStepUtils';
import { Currency } from '../../../../../../Models/Currency';

interface SalaryProps {
    currencies: Array<Currency>;
}

function Salary({ currencies }: SalaryProps): JSX.Element {
    const theme = useTheme();

    function xor(a: boolean, b: boolean): boolean {
        return (!a && b) || (a && !b);
    }

    const [, metaYearlySalaryFrom] = useField(JobDetailsFieldTypes.YearlySalaryFrom);
    const [, metaYearlySalaryTo] = useField(JobDetailsFieldTypes.YearlySalaryTo);
    const [, metaCurrency] = useField(`${JobDetailsFieldTypes.Currency}.label`);
    const [customError, setCustomError] = useState<string | undefined>('');

    useEffect(() => {
        const yearlySalaryFromError = metaYearlySalaryFrom.touched ? metaYearlySalaryFrom.error : undefined;
        const yearlySalaryToError = metaYearlySalaryTo.touched ? metaYearlySalaryTo.error : undefined;
        const currencyError = metaCurrency.touched ? metaCurrency.error : undefined;

        // xor(a,b) returns true if only one variable is true
        // to check if only one field has error, we use xor(xor(a, b), c)
        // using !! we convert string to boolean
        if (xor(xor(!!yearlySalaryFromError, !!yearlySalaryToError), !!currencyError)) {
            // Only 1 error
            setCustomError(yearlySalaryFromError || yearlySalaryToError || currencyError);
        } else if (yearlySalaryFromError || yearlySalaryToError || currencyError) {
            // More than 1 error
            setCustomError(YupValidationErrors.InvalidInputError);
        } else {
            // No errors
            setCustomError(undefined);
        }
    }, [metaYearlySalaryFrom, metaYearlySalaryTo, metaCurrency]);

    return (
        <Stack>
            <Stack direction="row" spacing={theme.spacing(2.5)}>
                <YearlySalaryRange />
                <CurrencyAutocomplete currencies={currencies} />
            </Stack>
            {customError && <SalaryError error={customError} />}
            <FormikCheckbox
                name={JobDetailsFieldTypes.DoNotDisplaySalary}
                data-cy={JobDetailsFieldTypes.DoNotDisplaySalary}
                variant="body1"
                label={
                    <Typography variant="subtitle2" color={theme.palette.text.menuTitle}>
                        {UIStrings.DoNotDisplaySalary}
                    </Typography>
                }
            />
        </Stack>
    );
}

export default Salary;
