import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { authProviderSettings, tokenStorage } from '../AppSettings';

interface Props {
    children?: ReactNode;
}

const Auth0ProviderWithNavigate = ({ children }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onRedirectCallback = (appState: AppState | undefined) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    const search = new URLSearchParams(location.search);
    const providerSettings = {
        ...authProviderSettings,
        hint: search.get('hint') || 'login-password',
    };

    return (
        <Auth0Provider
            {...providerSettings}
            onRedirectCallback={onRedirectCallback}
            cacheLocation={tokenStorage}
            useRefreshTokens
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithNavigate;
