function objectPropertiesToEnumValues<TInterface, TEnum>(enumEntries: any[], object: TInterface): Array<TEnum> {
    return enumEntries.reduce((filtered, [key, value]) => {
        if (object[key as keyof TInterface] !== undefined && object[key as keyof TInterface]) {
            filtered.push(value);
        }
        return filtered;
    }, [] as Array<TEnum>);
}

export default objectPropertiesToEnumValues;
