import { useTheme } from '@mui/material';
import { Box, SpanTypography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../Utils/UIStrings';

interface AccountHasBeenRevokedProps {
    companyName: string;
}

function AccountHasBeenRevoked({ companyName }: AccountHasBeenRevokedProps): JSX.Element {
    const theme = useTheme();
    return (
        <Box>
            <SpanTypography
                variant="body2"
                color={theme.palette.common.black}
            >{`${UIStrings.YourAccountHasBeenRevoked}. `}</SpanTypography>
            <SpanTypography
                variant="body2"
                color={theme.palette.common.black}
            >{`${UIStrings.YouCanNoLongerJoin} `}</SpanTypography>
            <SpanTypography
                variant="body2"
                color={theme.palette.common.black}
                fontWeight="bold"
            >{`${companyName}. `}</SpanTypography>
            <SpanTypography variant="body2" color={theme.palette.common.black}>
                {UIStrings.InvitationMistakeMessage}
            </SpanTypography>
        </Box>
    );
}

export default AccountHasBeenRevoked;
