import AcceptInvitationDTO from '../DTO/AcceptInvitationDTO';
import { InvitationLinkDTO } from '../DTO/InvitationLinkDTO';
import InviteMemberDTO from '../DTO/Membership/InviteMemberDTO';
import UserDTO from '../DTO/Users/UserDTO';
import User, { DefaultUser, fromUserDTO } from '../Models/User';
import { get, post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class InvitationClient extends ClientBase {
    async inviteMember(dto: InviteMemberDTO): Promise<void> {
        await post({
            path: this.addHost('users/invitations/invite'),
            ...toJson(dto),
            accessToken: await this.getAccessTokenSilently(),
        });
    }

    async fetchInvitationLink(token: string): Promise<string> {
        const response = await get<InvitationLinkDTO>({
            path: this.addHost(`users/invitations/fetch-invitation?token=${encodeURIComponent(token)}`),
        });

        if (response.ok && response.body) {
            return response.body.invitationLink;
        }

        return '';
    }

    async revokeInvitation(id: string): Promise<void> {
        await post({
            path: this.addHost(`users/invitations/revoke/${id}`),
            accessToken: await this.getAccessTokenSilently(),
        });
    }

    async acceptInvitation(dto: AcceptInvitationDTO): Promise<User> {
        const response = await post<UserDTO>({
            path: this.addHost('invitations/accept'),
            accessToken: await this.getAccessTokenSilently(),
            ...toJson(dto),
        });

        if (response.ok && response.body) {
            return fromUserDTO(response.body);
        }
        return DefaultUser;
    }

    async resendInvitation(id: string): Promise<void> {
        await post({
            path: this.addHost(`users/invitations/resend/${id}`),
            accessToken: await this.getAccessTokenSilently(),
        });
    }
}
