import SetupCardDTO from '../DTO/Users/SetupCardDTO';

export default interface SetupCard {
    clientSecret: string;
}

export const fromSetupCardDTO = (dto: SetupCardDTO): SetupCard => {
    return {
        clientSecret: dto.clientSecret,
    } as SetupCard;
};
