import { useLocation } from 'react-router';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import { Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useCareerPageTabContext } from '../Contexts/CareerPageTabContext';
import UIStrings from '../../../../Utils/UIStrings';
import RoutePath from '../../../../Routing/RoutePath';
import useTalentMeshHistory from '../../../../Routing/useTalentMeshHistory';

function SetupCareerPageDialog(): JSX.Element {
    const { dialogOpened, bookMeetingHandler, closeDialog } = useCareerPageTabContext();
    const theme = useTheme();
    const history = useTalentMeshHistory();
    const location = useLocation();

    const setupCareerPageHandler = () => history.push(RoutePath.CareerBuilder, { from: location.pathname });

    return (
        <Dialog open={dialogOpened} maxWidth="xs" onClose={closeDialog}>
            <DialogTitle display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" fontWeight="bold">
                    {UIStrings.SetUpYourCareerPage}
                </Typography>
                <IconButton onClick={closeDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">{UIStrings.SetUpAndEnableCareerPage}</Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    paddingBottom: theme.spacing(2),
                    justifyContent: 'center',
                }}
            >
                <Button variant="outlined" onClick={bookMeetingHandler} size="large">
                    {UIStrings.BookSetupMeeting}
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    onClick={setupCareerPageHandler}
                    sx={{
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none',
                        },
                    }}
                >
                    {UIStrings.SetUpCareerPage}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SetupCareerPageDialog;
