/* eslint-disable no-param-reassign */
import React, { useState } from 'react';

import { Formik } from 'formik';
import { LoadingButton, Stack, TableRow, TMTableCell, TypographyIcon } from '@talentmesh/core';
import {
    CheckCircleOutlineIcon,
    MailOutlineIcon,
    PersonOutlineOutlinedIcon,
    PersonPinOutlinedIcon,
} from '@talentmesh/icons';
import { FormikTextField, getFieldValue, resetFieldState, setFieldError, validate } from '@talentmesh/forms';
import { useTheme } from '@mui/material/styles';
import { emailRegExp } from '../../../../../AppSettings';
import InternalBenchmarkBadge from '../../../../../Components/InternalBadges';
import { Candidate, CandidateAction, DefaultCandidate } from '../../../../../Models/Candidate';
import ProcessingState from '../../../../../Models/ProcessingState';
import UIStrings from '../../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';

export interface CandidateEditRowProps {
    candidate: Candidate;
    setEditableCandidate: CandidateAction;
}

export const CandidateEditRow = ({ candidate, setEditableCandidate }: CandidateEditRowProps): JSX.Element => {
    const theme = useTheme();
    const fieldNames = ['firstName', 'lastName', 'email', 'isInternal'];
    const { updateCandidateAsync, processingState } = useInviteCandidateContext();
    const [updateCandidateProcessingState, setUpdateCandidateProcessingState] = useState(false);

    return (
        <Formik initialValues={candidate} validateOnChange={false} validateOnBlur={false} onSubmit={() => {}}>
            {(formik) => (
                <TableRow>
                    <TMTableCell width={theme.spacing(6)} isInternal={candidate.isInternal}>
                        <Stack direction="row" spacing={1}>
                            <TypographyIcon
                                sx={{ marginLeft: theme.spacing(6) }}
                                icon={candidate.isInternal ? <PersonPinOutlinedIcon /> : <PersonOutlineOutlinedIcon />}
                                variant="h5"
                                color={theme.palette.text.disabled}
                            />
                        </Stack>
                    </TMTableCell>
                    <TMTableCell width={theme.spacing(50)} isInternal={candidate.isInternal}>
                        <FormikTextField
                            size="small"
                            sx={{
                                paddingRight: theme.spacing(1),
                            }}
                            name="firstName"
                            data-cy="firstName"
                            value={candidate.firstName}
                        />
                    </TMTableCell>
                    <TMTableCell width={theme.spacing(50)} isInternal={candidate.isInternal}>
                        <FormikTextField
                            size="small"
                            sx={{ paddingRight: theme.spacing(1) }}
                            name="lastName"
                            data-cy="editLastName"
                            value={candidate.lastName}
                        />
                    </TMTableCell>
                    <TMTableCell width={theme.spacing(50)} isInternal={candidate.isInternal}>
                        <Stack direction="row" spacing={1}>
                            <TypographyIcon
                                icon={<MailOutlineIcon />}
                                variant="h5"
                                color={theme.palette.text.disabled}
                            />
                            <FormikTextField
                                size="small"
                                sx={{ paddingRight: theme.spacing(1) }}
                                color="secondary"
                                name="email"
                                data-cy="email"
                                value={candidate.email}
                            />
                        </Stack>
                    </TMTableCell>
                    <TMTableCell width={theme.spacing(12)} isInternal={candidate.isInternal}>
                        {candidate.isInternal && <InternalBenchmarkBadge />}
                    </TMTableCell>
                    <TMTableCell align="right" isInternal={candidate.isInternal}>
                        <Stack direction="row" spacing={1}>
                            <LoadingButton
                                sx={{ color: theme.palette.success.main, minWidth: theme.spacing(6) }}
                                loading={updateCandidateProcessingState}
                                disabled={processingState === ProcessingState.Processing}
                                onClick={async () => {
                                    let hasError = false;
                                    fieldNames.forEach((fieldName: string) => {
                                        resetFieldState(formik, fieldName);

                                        const message = validate(formik, fieldName);

                                        hasError = setFieldError(formik, fieldName, message) || hasError;
                                    });

                                    if (hasError) {
                                        return;
                                    }

                                    const emailValue = getFieldValue(formik, 'email');
                                    if (emailValue.match(emailRegExp) == null) {
                                        setFieldError(formik, 'email', UIStrings.PleaseEnterValidEmailAddress);
                                        return;
                                    }

                                    const obj = candidate as any;
                                    fieldNames.forEach((fieldName: string) => {
                                        obj[fieldName] = getFieldValue(formik, fieldName);
                                    });

                                    let error = '';
                                    try {
                                        setUpdateCandidateProcessingState(true);
                                        error = await updateCandidateAsync(candidate);
                                    } finally {
                                        setUpdateCandidateProcessingState(false);
                                    }
                                    if (error !== '') {
                                        setFieldError(formik, 'email', error);
                                        return;
                                    }

                                    setEditableCandidate(DefaultCandidate);
                                }}
                                size="large"
                                data-cy="confirmButton"
                            >
                                <CheckCircleOutlineIcon />
                            </LoadingButton>
                        </Stack>
                    </TMTableCell>
                </TableRow>
            )}
        </Formik>
    );
};
