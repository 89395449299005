import React, { useEffect, useState } from 'react';
import { CareerPageTabContextAPI, CareerPageTabContextState } from './CareerPageTabContext';
import { useCareerClient } from '../../../../Hooks/ClientHooks';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';
import { calendlyOnboardingUrl } from '../../../../AppSettings';
import { useInvitationPageContext } from './InvitationPageContext';
import useCompanyId from '../../../../Hooks/UseCompanyId';

function useCareerPageTabContextValue(): CareerPageTabContextAPI {
    const { recruitmentStatus } = useInvitationPageContext();
    const [state, setState] = useState<CareerPageTabContextState>({
        isCareerPageEnabled: false,
        isRecruitmentPublic: false,
        dialogOpened: false,
        loading: true,
        enableCareerPageToggle: false,
    });
    const companyId = useCompanyId();
    const careerPageClient = useCareerClient();

    useEffect(() => {
        const doLoadData = async () => {
            const stats = await careerPageClient.getCareerPageStatsAsync(companyId);
            setState((prevState) => ({
                ...prevState,
                isCareerPageEnabled: stats.publishedDate !== null,
                enableCareerPageToggle: stats.publishedDate !== null,
                isRecruitmentPublic: recruitmentStatus === AssessmentStatuses.Public,
                loading: false,
            }));
        };
        doLoadData();
    }, [companyId]);

    const { isCareerPageEnabled, isRecruitmentPublic, dialogOpened, loading, enableCareerPageToggle } = state;

    const enableCareerPage = () => {
        if (isCareerPageEnabled) {
            // we need to set the state twice so we can play animation on the switch
            setState((prevState) => ({ ...prevState, enableCareerPageToggle: false }));
            setTimeout(() => {
                setState((prevState) => ({ ...prevState, enableCareerPageToggle: true }));
            }, 200);
            return;
        }

        setState((prevState) => ({ ...prevState, dialogOpened: true, enableCareerPageToggle: true }));
    };

    const bookMeetingHandler = () => {
        window.open(calendlyOnboardingUrl, '_blank');
        setState((prevState) => ({ ...prevState, dialogOpened: false, enableCareerPageToggle: false }));
    };

    return {
        isCareerPageEnabled,
        isRecruitmentPublic,
        dialogOpened,
        loading,
        enableCareerPageToggle,
        enableCareerPage,
        bookMeetingHandler,
        closeDialog: () =>
            setState((prevState) => ({ ...prevState, dialogOpened: false, enableCareerPageToggle: false })),
    };
}

export default useCareerPageTabContextValue;
