import { useTheme } from '@mui/material';
import React from 'react';
import BodyTypography from '../../../../Common/BodyTypography';
import GridRowNode from './GridRowNode';

interface GridRowItemProps {
    title: string;
    value: string;
}
function GridRowItem({ title, value }: GridRowItemProps): JSX.Element {
    const theme = useTheme();

    return (
        <GridRowNode
            title={<BodyTypography color={theme.palette.text.secondary}>{title}:</BodyTypography>}
            node={<BodyTypography color={theme.palette.text.menuTitle}>{value}</BodyTypography>}
        />
    );
}
export default GridRowItem;
