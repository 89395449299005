/* eslint-disable max-classes-per-file */
import HttpErrorTypes from './HttpErrorType';

export default class HttpError extends Error {
    constructor(
        public title: string,
        public status: number,
        public type: HttpErrorTypes | string,
        public instance?: string,
        public detail?: string,
    ) {
        super(title);
    }
}
