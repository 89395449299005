import React from 'react';
import {
    PageEditor,
    BuilderShell,
    TemplateV1InitialJSON,
    PageBuilderContextProvider,
    ImageUploadContextProvider,
    JobAdsContextProvider,
} from '@talentmesh/builder-components';
import { Link } from 'react-router-dom';
import { LoadingOverlay } from '@talentmesh/core';
import UnsavedPrompt from './Components/UnsavedPrompt';
import { useCareerBuilderContext } from '../Contexts/CareerBuilderContext';
import RoutePath from '../../../Routing/RoutePath';
import PublishedSuccessfullyDialog from './Components/PublishedSuccessfullyDialog';
import { useCompanyIdRoute } from '../../../Routing/useCompanyIdRoute';
import { useCompanyContext } from '../../../Context/CompanyContext';

const CareerBuilder = (): JSX.Element => {
    const {
        currentCompanyProfile: { companyName, companyLogoURL },
    } = useCompanyContext();
    const tmLogoUrl = useCompanyIdRoute(RoutePath.CompanyRoot);
    const {
        draft,
        loading,
        handleUploadImageAsync,
        handleSaveDraftAsync,
        handlePublishAsync,
        handleGetJobAdsOverviewAsync,
        handleGetCountryFiltersAsync,
        handleGetJobCategoryFiltersAsync,
        setDirty,
        processingImageErrorCallback,
    } = useCareerBuilderContext();

    return (
        <>
            <UnsavedPrompt />
            <PublishedSuccessfullyDialog />
            <PageEditor enabled>
                <ImageUploadContextProvider
                    uploadImageAndRetrieveURLAsync={handleUploadImageAsync}
                    processingImageErrorCallback={processingImageErrorCallback}
                    recruiterCompanyLogo={companyLogoURL}
                >
                    <PageBuilderContextProvider
                        saveCallbackAsync={handleSaveDraftAsync}
                        publishCallbackAsync={handlePublishAsync}
                        onDirty={setDirty}
                        companyName={companyName}
                    >
                        <JobAdsContextProvider
                            jobAdsGetter={handleGetJobAdsOverviewAsync}
                            countryOptionsGetter={handleGetCountryFiltersAsync}
                            jobTypesOptionsGetter={handleGetJobCategoryFiltersAsync}
                            onJobAdClickListener={() => {}}
                        >
                            {loading ? (
                                <LoadingOverlay />
                            ) : (
                                <BuilderShell
                                    data={draft === '' ? TemplateV1InitialJSON : draft}
                                    logoClickURL={tmLogoUrl}
                                    logoLinkComponent={Link}
                                />
                            )}
                        </JobAdsContextProvider>
                    </PageBuilderContextProvider>
                </ImageUploadContextProvider>
            </PageEditor>
        </>
    );
};

export default CareerBuilder;
