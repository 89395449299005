import { useTheme } from '@mui/material';
import { Container, Paper } from '@talentmesh/core';
import React, { ReactNode } from 'react';

interface PageHeaderProps {
    children: ReactNode;
}
function PageHeader({ children }: PageHeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            elevation={0}
            square
            sx={{
                backgroundColor: theme.palette.background.default,
                borderBottom: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Container disableGutters>{children}</Container>
        </Paper>
    );
}

export default PageHeader;
