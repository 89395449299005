import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import RecruiterApplicationContextProvider from '../Context/RecruiterApplicationContextProvider';
import AcceptInvitationPage from '../Pages/AcceptInvitation/AcceptInvitationPage';
import EmailVerifiedPage from '../Pages/EmailVerifiedPage/EmailVerifiedPage';
import ErrorPage from '../Pages/Errors/ErrorPage';
import IdentityErrorPage from '../Pages/Errors/IdentityErrorPage';
import FetchInvitationPage from '../Pages/FetchInvitationLink/FetchInvitationPage';
import GetFullExperiencePage from '../Pages/GetFullExperiencePage/GetFullExperiencePage';
import NotFoundPageContainer from '../Pages/NotFoundPage/NotFoundPageContainer';
import ObsoleteCandidateResultsPage from '../Pages/ObsoleteCandidateResults/ObsoleteCandidateResultsPage';
import FinalizeRegistrationPage from '../Pages/Registration/FinalizeRegistrationPage';
import ProvisionPage from '../Pages/Registration/ProvisionPage';
import RegisterPage from '../Pages/Registration/RegisterPage';
import RootPage from '../Pages/Root/RootPage';
import SetupAccountPage from '../Pages/SetupAccountPage/SetupAccountPage';
import VerifyEmail from '../Pages/VerifyEmailPage/VerifyEmail';
import VersionPage from '../Pages/VersionPage';
import CompanyRoutesContextsProvider, { companyRouter } from './CompanyRouter';
import DesktopOnlyRoutes from './DesktopOnlyRoutes';
import ProtectedRoute from './ProtectedRoute';
import ProvisionApp from './ProvisionApp';
import RoutePath from './RoutePath';

const router = createBrowserRouter([
    {
        element: <ProvisionApp />,
        children: [
            {
                path: '*',
                element: <NotFoundPageContainer />,
            },
            {
                path: RoutePath.FetchInvitation,
                element: <FetchInvitationPage />,
            },
            {
                path: RoutePath.Register,
                element: <RegisterPage />,
            },
            {
                path: RoutePath.IdentityError,
                element: <IdentityErrorPage />,
            },
            {
                path: RoutePath.Error,
                element: <ErrorPage />,
            },
            {
                path: RoutePath.Version,
                element: <VersionPage />,
            },
            {
                path: RoutePath.FullExperience,
                element: <GetFullExperiencePage />,
            },
            {
                element: <RecruiterApplicationContextProvider />,
                children: [
                    {
                        path: RoutePath.EmailVerified,
                        element: <EmailVerifiedPage />,
                    },
                    {
                        element: <ProtectedRoute />,
                        children: [
                            {
                                element: <DesktopOnlyRoutes />,
                                children: [
                                    {
                                        path: RoutePath.Root,
                                        element: <RootPage />,
                                    },
                                    {
                                        path: RoutePath.ObsoleteCandidateResult,
                                        element: <ObsoleteCandidateResultsPage />,
                                    },
                                    {
                                        path: RoutePath.AcceptInvitation,
                                        element: <AcceptInvitationPage />,
                                    },
                                ],
                            },
                            {
                                path: RoutePath.SetupAccount,
                                element: <SetupAccountPage />,
                            },
                            {
                                path: RoutePath.VerifyEmail,
                                element: <VerifyEmail />,
                            },
                            {
                                path: RoutePath.FinalizeRegistration,
                                element: <FinalizeRegistrationPage />,
                            },
                            {
                                path: RoutePath.Provision,
                                element: <ProvisionPage />,
                            },
                            {
                                path: RoutePath.CompanyRoot,
                                element: <CompanyRoutesContextsProvider />,
                                children: companyRouter,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

export default router;
