import React from 'react';
import { Navigate } from 'react-router-dom';
import { BrandedCardContainer, ContainerBackground, LoadingOverlay } from '@talentmesh/core';
import RoutePath from '../../Routing/RoutePath';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';
import UIStrings from '../../Utils/UIStrings';
import AccountCreationForm from '../../Components/AccountCreation/AccountCreationForm';
import useSetupAccountPageState from './Hook/useSetupAccountPageState';
import { useUserContext } from '../../Context/UserContext';

const SetupAccountPage = () => {
    const { allowAccountSetup, isLoading, isSubmitting, submitHandler } = useSetupAccountPageState();
    const { user } = useUserContext();

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return allowAccountSetup ? (
        <ContainerBackground>
            <BrandedCardContainer header={UIStrings.SetupYourProfile}>
                <AccountCreationForm
                    submitHandler={submitHandler}
                    isSubmitting={isSubmitting}
                    initialCompanyName=""
                    initialFirstName={user.firstName || ''}
                    initialLastName={user.lastName || ''}
                />
            </BrandedCardContainer>
        </ContainerBackground>
    ) : (
        <Navigate to={RoutePath.Root} />
    );
};

export default withTalentMeshUserRequired(SetupAccountPage);
