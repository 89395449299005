import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
    SpanTypography,
    Stack,
    TooltipPaper,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
} from '@talentmesh/core';
import { Check } from '@talentmesh/icons';
import CheckedBadgeIcon from './CheckedBadgeIcon';
import UIStrings from '../../../../../../../../../Utils/UIStrings';

function ExpertsBadgeTooltip(): JSX.Element {
    const theme = useTheme();

    const listItems: Array<string> = [
        UIStrings.SoftAndHardSkillsRequiredForAGivenJobFunction,
        UIStrings.RightIndustryExpertIsIdentifiedAndAssigned,
        UIStrings.QuestionsBasedOnTheResearchFindings,
        UIStrings.ValidatedByALeadingPsychologist,
    ];

    return (
        <TooltipPaper minWidth={theme.spacing(63.25)}>
            <Stack direction="column" spacing={1.25} justifyContent="start" alignContent="start">
                <Stack direction="row" spacing={2.5}>
                    <Box sx={{ minWidth: theme.spacing(3.875), display: 'flex', alignItems: 'center' }}>
                        <CheckedBadgeIcon />
                    </Box>
                    <Typography variant="h4" color={theme.palette.text.menuTitle}>
                        {UIStrings.QualityTestsBuiltByIndustryExpertsValidatedByPsychologists}
                    </Typography>
                </Stack>
                <SpanTypography
                    variant="body1"
                    color={theme.palette.text.menuTitle}
                    style={{ lineHeight: theme.spacing(3) }}
                >
                    {`${UIStrings.TalentMeshTestsAreCreatedByTheFollowingScientificMethod}:`}
                </SpanTypography>
                <List sx={{ paddingTop: theme.spacing(1.25), paddingBottom: 0 }}>
                    {listItems.map((item) => (
                        <ListItem
                            key={item}
                            color={theme.palette.text.menuTitle}
                            sx={{ ...theme.typography.body1, padding: 0, alignItems: 'start' }}
                        >
                            <ListItemIcon sx={{ minWidth: 0, marginRight: theme.spacing(1.25) }}>
                                <Check sx={{ color: theme.palette.success.dark }} />
                            </ListItemIcon>
                            <ListItemText sx={{ marginTop: 0, marginBottom: theme.spacing(1.25) }}>{item}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Stack>
        </TooltipPaper>
    );
}

export default ExpertsBadgeTooltip;
