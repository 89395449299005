import React from 'react';
import { Grid, GridProps } from '@talentmesh/core';

function GridRow({ children, ...props }: GridProps): JSX.Element {
    return (
        <Grid item {...props} xs={12}>
            <Grid container direction="row" spacing={3}>
                {children}
            </Grid>
        </Grid>
    );
}

export default GridRow;
