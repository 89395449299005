import { useTheme } from '@mui/material';
import React from 'react';
import { Paper, PercentileLevel, Stack, TMCircularProgress, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { heightSpacing, paddingBottomSpacing, paddingTopSpacing } from './RecruitmentListItemConsts';

interface HighestTalentScoreProps {
    score?: number;
    scoreLevel?: PercentileLevel;
    scoreLabel: string;
}
function HighestTalentScore({ score, scoreLevel, scoreLabel }: HighestTalentScoreProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: theme.palette.background.silverSand,
                height: theme.spacing(heightSpacing),
                paddingTop: theme.spacing(paddingTopSpacing),
                paddingBottom: 1,
            }}
        >
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                spacing={paddingBottomSpacing}
                sx={{ height: '100%' }}
            >
                <Typography variant="h6" color={theme.palette.text.menuTitle}>
                    {UIStrings.HighestTalentScore}
                </Typography>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{ height: '100%' }}
                >
                    <TMCircularProgress level={scoreLevel || 'Low'} progress={score} />
                    <Typography variant="label" color={theme.palette.secondary.main}>
                        {scoreLabel}
                    </Typography>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default HighestTalentScore;
