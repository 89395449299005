import React from 'react';
import { Dataset, VerticalBarChart } from '@talentmesh/charts';
import { TestTypes } from '../../../../../../../../Models/Configuration';
import { AssessmentMeanBenchmark } from '../../../../../../../../Models/AssessmentMeanBenchmark';
import ChartBox from './ChartBox';
import { TopicScore } from '../../../../../../../../Models/TestScore';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import { getAssessmentAverageValue, getAssessmentInternalAverageValue, getMeanTopicScores } from '../BenchmarkUtils';
import buildDetailedGraphData from './DetailedBreakdownBenchmarkTab.utils';

interface DetailedBreakdownBenchmarkTabProps {
    candidateFirstName: string;
    topicScores: Array<TopicScore>;
    testType: TestTypes;
    benchmark: AssessmentMeanBenchmark;
}

function DetailedBreakdownBenchmarkTab({
    candidateFirstName,
    topicScores,
    testType,
    benchmark,
}: DetailedBreakdownBenchmarkTabProps): JSX.Element {
    const averageScore = getAssessmentAverageValue(testType, benchmark);
    const internalAverageScore = getAssessmentInternalAverageValue(testType, benchmark);
    const meanTopicScores = getMeanTopicScores(testType, benchmark);

    const { labels, candidateScoreData, meanScoreData, internalMeanScoreData } = buildDetailedGraphData(
        testType,
        topicScores,
        meanTopicScores,
    );

    const candidateDataset: Dataset = {
        label: UIStrings.SomeoneScore(candidateFirstName),
        data: candidateScoreData,
    };

    const averageDataset: Dataset = {
        label: `${UIStrings.AverageOfInvited} ${UIStrings.InvitedApplicants}`,
        data: averageScore !== null ? meanScoreData : null,
    };

    const internalDataset: Dataset = {
        label: `${UIStrings.AverageOfInvited} ${UIStrings.InternalBenchmarks}`,
        data: internalAverageScore !== null ? internalMeanScoreData : null,
    };

    return (
        <ChartBox>
            <VerticalBarChart
                labels={labels}
                candidateScores={candidateDataset}
                averageScores={averageDataset}
                internalScores={internalDataset}
            />
        </ChartBox>
    );
}

export default DetailedBreakdownBenchmarkTab;
