import React from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack, TMTooltip, Typography } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import CheckedBadgeIcon from './CheckedBadgeIcon';
import UIStrings from '../../../../../../../../../Utils/UIStrings';
import ExpertsBadgeTooltip from './ExpertsBadgeTooltip';

function ExpertsBadge(): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            spacing={1.25}
            sx={{
                paddingX: theme.spacing(1.25),
                paddingY: theme.spacing(0.625),
                justifyContent: 'center',
                alignItems: 'center',
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.spacing(12.5),
            }}
        >
            <CheckedBadgeIcon />
            <Typography variant="body1" sx={{ color: theme.palette.text.menuTitle }}>
                {UIStrings.QualityTestsBuiltByIndustryExpertsValidatedByPsychologists}
            </Typography>
            <TMTooltip title={<ExpertsBadgeTooltip />} placement="bottom">
                <IconButton sx={{ color: theme.palette.secondary.light, padding: 0 }}>
                    <InfoIcon />
                </IconButton>
            </TMTooltip>
        </Stack>
    );
}

export default ExpertsBadge;
