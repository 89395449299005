import React from 'react';
import { LoadingOverlay } from '@talentmesh/core';
import { useCompanyContext } from '../Context/CompanyContext';

// eslint-disable-next-line func-names
const withCompanyProfileRequired = function <T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) {
    return (props: T) => {
        const { isLoading } = useCompanyContext();

        if (isLoading) {
            return <LoadingOverlay />;
        }

        return <Component {...props} />;
    };
};

export default withCompanyProfileRequired;
