import React from 'react';
import { FormikDropdown } from '@talentmesh/forms';
import UIStrings from '../../../../../../Utils/UIStrings';
import employmentTypeOptions from './employmentTypeOptions';
import { JobDetailsFieldTypes } from '../JobDetailsStepUtils';

function EmploymentType(): JSX.Element {
    return (
        <FormikDropdown
            label={`${UIStrings.EmploymentType}*`}
            name={JobDetailsFieldTypes.EmploymentType}
            options={employmentTypeOptions}
            data-cy={JobDetailsFieldTypes.EmploymentType}
        />
    );
}

export default EmploymentType;
