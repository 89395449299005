import React, { useRef } from 'react';
import EmptyTopBar from '../../../../Components/TopBars/EmptyTopBar';
import ProcessingState from '../../../../Models/ProcessingState';
import UIStrings from '../../../../Utils/UIStrings';
import Page from '../../../Page';
import PageContentLoading from '../../../PageContentLoading';
import ModifyRecruitmentStepper from '../Components/ModifyRecruitmentStepper';
import { CreateFullRecruitmentStepMapper } from '../Components/StepMappers';
import { useCreateRecruitmentContext } from './Contexts/CreateRecruitmentContext';

const CreateRecruitmentPageContent = () => {
    const { processingState, gettingDataProcessingState } = useCreateRecruitmentContext();
    const steps = [
        UIStrings.JobDetails,
        UIStrings.JobAd,
        UIStrings.SkillsTests,
        UIStrings.ApplicantInformation,
        UIStrings.IdentifyExperience,
        UIStrings.PostJob,
    ];
    const pageContentContainerRef = useRef<HTMLDivElement>(null);

    return (
        <Page topBar={<EmptyTopBar />} ref={pageContentContainerRef}>
            <PageContentLoading
                isLoading={gettingDataProcessingState === ProcessingState.Processing}
                loadingFailed={
                    processingState === ProcessingState.Error || gettingDataProcessingState === ProcessingState.Error
                }
            >
                <ModifyRecruitmentStepper
                    steps={steps}
                    stepMapper={CreateFullRecruitmentStepMapper}
                    pageContentContainerRef={pageContentContainerRef}
                />
            </PageContentLoading>
        </Page>
    );
};

export default CreateRecruitmentPageContent;
