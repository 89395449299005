import { CssBaseline, GlobalStyles, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { ThemeProvider, theme } from '@talentmesh/core';
import App from './App';
import { marketing } from './AppSettings';

if (marketing.gtm_container_id) {
    const tagManagerArgs = {
        gtmId: marketing.gtm_container_id,
    };

    TagManager.initialize(tagManagerArgs);
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const app = (
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>
);

root.render(app);
