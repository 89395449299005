import ApplicantInformationType from '../Models/ApplicantInformationType';
import UIStrings from './UIStrings';

function getApplicantInformationLabel(type: ApplicantInformationType) {
    switch (type) {
        case ApplicantInformationType.PhoneNumber:
            return UIStrings.PhoneNumber;
        case ApplicantInformationType.NoticePeriod:
            return UIStrings.NoticePeriod;
        case ApplicantInformationType.ReasonForApplying:
            return UIStrings.ReasonForApplying;
        case ApplicantInformationType.ReasonForLeaving:
            return UIStrings.ReasonForLeaving;
        case ApplicantInformationType.ValidWorkPermit:
            return UIStrings.ValidWorkPermitForTheCountry;
        case ApplicantInformationType.WhyWorkInStartup:
            return `${UIStrings.WhyDoYouWantToWorkForStartup}?`;
        case ApplicantInformationType.RankRemunerationLearningBalance:
            return `${UIStrings.RankRemunerationPaceOfLearningWorkLifeBalance}?`;
        case ApplicantInformationType.StructureImportance:
            return `${UIStrings.HowImportantAreStructureAndProcesses}?`;
        case ApplicantInformationType.PersonalProject:
            return `${UIStrings.GiveAnExampleOfProject}?`;
        case ApplicantInformationType.WhereDoYouSeeYourself:
            return `${UIStrings.WhereDoYouSeeYourselfInTheNextFewYears}?`;
        case ApplicantInformationType.SalaryExpectation:
            return UIStrings.ExpectedYearlySalaryRange;
        default:
            return '';
    }
}

export default getApplicantInformationLabel;
