import React from 'react';
import { useTheme } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@talentmesh/core';
import { DownloadIcon } from '@talentmesh/icons';

interface DownloadButtonProps extends LoadingButtonProps {
    label: string;
}

function DownloadButton({ label, ...props }: DownloadButtonProps): JSX.Element {
    const theme = useTheme();

    const textColor = theme.palette.secondary.main;
    const brdColor = theme.palette.secondary.main;
    const iconColor = props.disabled ? theme.palette.text.disabled : theme.palette.primary.main;

    return (
        <LoadingButton
            {...props}
            variant="outlined"
            startIcon={
                <DownloadIcon
                    sx={{
                        color: props.loading ? 'transparent' : iconColor,
                    }}
                />
            }
            size="small"
            sx={{
                backgroundColor: theme.palette.common.white,
                color: props.disabled ? theme.palette.text.disabled : textColor,
                borderColor: brdColor,
                ':hover': {
                    color: textColor,
                    borderColor: brdColor,
                },
                ':active': {
                    color: textColor,
                    borderColor: brdColor,
                    backgroundColor: theme.palette.grey[300],
                },
            }}
        >
            {label}
        </LoadingButton>
    );
}

export default DownloadButton;
