import {
    AuBankAccountElement,
    CardCvcElement,
    CardElement,
    CardExpiryElement,
    CardNumberElement,
    FpxBankElement,
    IbanElement,
    IdealBankElement,
} from '@stripe/react-stripe-js';
import * as React from 'react';
import { TextField, TextFieldProps } from '@talentmesh/core';
import StripeInput from './StripeInput';

type StripeElement =
    | typeof AuBankAccountElement
    | typeof CardCvcElement
    | typeof CardExpiryElement
    | typeof CardNumberElement
    | typeof FpxBankElement
    | typeof IbanElement
    | typeof IdealBankElement
    | typeof CardElement;

export interface StripeTextFieldProps<T extends StripeElement>
    extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
    inputProps?: React.ComponentProps<T>;
    labelErrorMessage?: string;
    onChange?: React.ComponentProps<T>['onChange'];
    stripeElement?: T;
}

const StripeTextField = <T extends StripeElement>(props: StripeTextFieldProps<T>) => {
    const {
        helperText,
        InputLabelProps,
        InputProps = {},
        inputProps,
        error,
        labelErrorMessage,
        stripeElement,
        ...other
    } = props;

    return (
        <TextField
            sx={{
                '& .MuiOutlinedInput-input': {
                    margin: 'auto',
                },
            }}
            fullWidth
            InputLabelProps={{
                ...InputLabelProps,
                shrink: true,
            }}
            error={error}
            InputProps={{
                ...InputProps,
                inputProps: {
                    ...inputProps,
                    ...InputProps.inputProps,
                    component: stripeElement,
                },
                inputComponent: StripeInput,
            }}
            helperText={error ? labelErrorMessage : helperText}
            {...(other as any)}
        />
    );
};

export default StripeTextField;
