import React from 'react';
import { ScoreItem, Stack } from '@talentmesh/core';
import { TestTypes } from '../../../../../Models/Configuration';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import BenchmarkPanel from './Components/Benchmark/BenchmarkPanel';
import ScoreOverview from './Components/ScoreOverview';
import UIStrings from '../../../../../Utils/UIStrings';

function SkillsTab(): JSX.Element | null {
    const { results, jobFunction } = useCandidatesResultsContext();
    const { skillsScore, candidateFirstName } = results;

    if (!skillsScore) {
        return null;
    }

    const { percentileScore, percentileLevel } = skillsScore;

    const overall: ScoreItem = {
        level: percentileLevel,
        name: UIStrings.OverallScore,
        value: percentileScore,
    };

    const scores: ScoreItem[] = skillsScore.scores.map((score) => {
        return {
            level: score.percentileLevel,
            name: score.questionTopicName,
            value: score.percentileScore,
        };
    });

    return (
        <Stack spacing={2.5} sx={{ marginBottom: 2.5 }}>
            <ScoreOverview
                scoreOverviewTitle={UIStrings.SkillsScoreOverview}
                scoreOverviewDescription={UIStrings.TheOverallScoreIsCalculatedBasedOnSkills(jobFunction)}
                candidateFirstName={candidateFirstName}
                scores={scores}
                overall={overall}
            />
            <BenchmarkPanel
                testType={TestTypes.Skills}
                candidateFirstName={candidateFirstName}
                candidateScore={skillsScore}
            />
        </Stack>
    );
}

export default SkillsTab;
