import { useTheme } from '@mui/material';
import { Box, Grid, SquareChip, Stack, Typography } from '@talentmesh/core';
import {
    AptitudeTestIcon,
    EmotionalIntelligenceTestIcon,
    PersonalityTestIcon,
    SkillsTestIcon,
} from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../../../../../Utils/UIStrings';

interface TestIncludedProps {
    hasPersonality?: boolean;
    hasAptitude?: boolean;
    hasSkills?: boolean;
    hasEmotionalIntelligence?: boolean;
}

function TestIncluded({
    hasPersonality,
    hasAptitude,
    hasSkills,
    hasEmotionalIntelligence,
}: TestIncludedProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.25}
            sx={{
                height: theme.spacing(12),
                width: '55%',
            }}
        >
            <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                sx={{ paddingTop: 1.25, paddingBottom: 1 }}
            >
                {UIStrings.TestsIncluded}
            </Typography>

            <Box>
                <Grid container spacing={0.525} sx={{ placeContent: 'center' }}>
                    {hasPersonality && (
                        <Grid item xs="auto">
                            <SquareChip icon={<PersonalityTestIcon />} label={UIStrings.Personality} />
                        </Grid>
                    )}
                    {hasAptitude && (
                        <Grid item xs="auto">
                            <SquareChip icon={<AptitudeTestIcon />} label={UIStrings.Aptitude} />
                        </Grid>
                    )}
                    {hasSkills && (
                        <Grid item xs="auto">
                            <SquareChip icon={<SkillsTestIcon />} label={UIStrings.Skills} />
                        </Grid>
                    )}
                    {hasEmotionalIntelligence && (
                        <Grid item xs="auto">
                            <SquareChip
                                icon={<EmotionalIntelligenceTestIcon />}
                                label={UIStrings.EmotionalIntelligence}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Stack>
    );
}

export default TestIncluded;
