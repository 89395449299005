import React from 'react';
import { CardBrand } from '../../../Models/CardBrand';
import Amex from './Amex.svg';
import DinersClub from './DinersClub.svg';
import Discover from './Discover.svg';
import JCB from './JCB.svg';
import Mastercard from './Mastercard.svg';
import Union from './Union.svg';
import UnknownCard from './UnknownCard.svg';
import Visa from './Visa.svg';

interface CreditCardLogoProps {
    cardBrand: CardBrand;
}

function getCardLogoPath(cardBrand: CardBrand) {
    switch (cardBrand.toLowerCase()) {
        case 'mastercard':
            return Mastercard;
        case 'amex':
            return Amex;
        case 'diners':
            return DinersClub;
        case 'discover':
            return Discover;
        case 'jcb':
            return JCB;
        case 'unionpay':
            return Union;
        case 'visa':
            return Visa;
        default:
            return UnknownCard;
    }
}

const CreditCardLogo = ({ cardBrand }: CreditCardLogoProps) => {
    const imageSource = getCardLogoPath(cardBrand);
    return <img src={imageSource} alt="credit card logo" />;
};

export default CreditCardLogo;
