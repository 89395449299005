import { Divider, Stack } from '@talentmesh/core';
import React, { useState } from 'react';
import { CriteriaScoreFormValues } from '../CriteriaScore';
import CriteriaScoreFormDialog from './CriteriaScoreFormDialog';
import CriteriaRow from './CriteriaRow';
import { useCandidatesResultsContext } from '../../../../Contexts/CandidateResultsContext';
import { ExperienceCriterionFinalScore } from '../../../../../../../Models/ExperienceCriterionFinalScore';
import { useNotificationContext } from '../../../../../../../Context/NotificationContext';

const CriteriaList = () => {
    const { results, updateUserDefinedCriteriaScore } = useCandidatesResultsContext();
    const [open, setOpen] = useState(false);
    const [criteriaScoreToEdit, setCriteriaScoreToEdit] = useState<ExperienceCriterionFinalScore | undefined>();
    const { showFailToaster } = useNotificationContext();

    const handleEdit = (criterionScoreToEdit: ExperienceCriterionFinalScore) => {
        setCriteriaScoreToEdit(criterionScoreToEdit);
        setOpen(true);
    };

    const handleSubmit = async (newScore: CriteriaScoreFormValues, criterionScore: ExperienceCriterionFinalScore) => {
        try {
            await updateUserDefinedCriteriaScore(
                newScore.userDefinedScore,
                newScore.userDefinedJustification,
                criterionScore,
            );
            setOpen(false);
        } catch {
            showFailToaster();
        }
    };

    return (
        <Stack divider={<Divider />}>
            {results.experienceCriteriaScore?.criteriaScores.map((criteriaScore, i) => (
                <CriteriaRow
                    key={criteriaScore.experienceCriteriaId}
                    criterionFinalScore={criteriaScore}
                    index={i}
                    onEdit={handleEdit}
                />
            ))}
            <CriteriaScoreFormDialog
                open={open}
                criteriaFinalScore={criteriaScoreToEdit!}
                onSubmit={handleSubmit}
                onClose={() => setOpen(false)}
            />
        </Stack>
    );
};

export default CriteriaList;
