import React from 'react';
import { StepperNavigationButtons } from '../../../Components/StepperNavigationButtons';
import StepProps from '../../StepProps';
import JobAdNavigationButtons from './JobAdNavigationButtons';
import ProcessingState from '../../../../../../Models/ProcessingState';
import { JobAd } from '../../../Models/JobAd';
import { JobDetails } from '../../../Models/JobDetails';

interface JobAdStepNavigationProps extends StepProps {
    processingState: ProcessingState;
    jobAd: JobAd;
    setJobAd: (value: JobAd) => void;
    jobDetails: JobDetails;
    nextButtonLabel: string;
}

function JobAdStepNavigation({
    activeStep,
    handleBack,
    processingState,
    jobAd,
    setJobAd,
    jobDetails,
    nextButtonLabel,
}: JobAdStepNavigationProps): JSX.Element {
    return (
        <StepperNavigationButtons
            activeStep={activeStep}
            handleBack={handleBack}
            nextButtonComponent={
                <JobAdNavigationButtons
                    jobAd={jobAd}
                    setJobAd={setJobAd}
                    jobDetails={jobDetails}
                    processingState={processingState}
                    nextButtonLabel={nextButtonLabel}
                />
            }
            processingState={processingState}
        />
    );
}

export default JobAdStepNavigation;
