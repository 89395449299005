import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Crop, PixelCrop } from 'react-image-crop';
import { generateCropCanvas, convertCanvasToBlob } from './ImageCropUtils';

export interface ImageCropContextState {
    openDialog: boolean;
    processing: boolean;
    validationError: boolean;
    selectedImage: string;
    selectedImageType: string;
    crop?: Crop;
    pixelCrop?: PixelCrop;
}

export interface ImageCropContextHelper {
    setOpenDialog: (value: boolean) => void;
    setProcessing: (value: boolean) => void;
    setSelectedImage: (value: string, type: string) => void;
    setCrop: (value: Crop) => void;
    setPixelCrop: (value: PixelCrop) => void;
    uploadImageAsync: (imagePreviewRef: HTMLImageElement, canvasRef: HTMLCanvasElement) => Promise<void>;
    resetImage: () => Promise<void>;
}

export interface ImageCropContextProps extends ImageCropContextState, ImageCropContextHelper {}

export interface ImageCropProviderProps {
    uploadCallbackAsync: (value: Blob) => Promise<void>;
    resetCallbackAsync: () => Promise<void>;
    processingErrorCallback: () => void;
}

export interface ImageCropContextProviderProps extends ImageCropProviderProps {
    children?: ReactNode;
}

export const ImageCropContext = createContext<ImageCropContextProps | undefined>(undefined);

export const useImageCropContext = (): ImageCropContextProps => {
    const context = useContext(ImageCropContext);

    if (!context) {
        throw new Error('useImageCropContext must be used within the ImageCropContext.Provider');
    }

    return context;
};

export const useImageCropProvider = ({
    uploadCallbackAsync,
    resetCallbackAsync,
    processingErrorCallback,
}: ImageCropProviderProps): ImageCropContextProps => {
    const [state, setState] = useState<ImageCropContextState>({
        openDialog: false,
        processing: false,
        validationError: false,
        selectedImage: '',
        selectedImageType: '',
        crop: undefined,
        pixelCrop: undefined,
    });

    const { openDialog, processing, validationError, selectedImage, selectedImageType, crop, pixelCrop } = state;

    const setOpenDialog = (value: boolean) => {
        setState((prev) => {
            return {
                ...prev,
                openDialog: value,
            };
        });
    };

    const setProcessing = (value: boolean) => {
        setState((prev) => {
            return {
                ...prev,
                processing: value,
            };
        });
    };

    const setSelectedImage = (value: string, type: string) => {
        setState((prev) => {
            return {
                ...prev,
                selectedImage: value,
                selectedImageType: type,
                pixelCrop: undefined,
                crop: undefined,
                openDialog: true,
            };
        });
    };

    const setCrop = (value: Crop) => {
        setState((prev) => {
            return {
                ...prev,
                crop: value,
            };
        });
    };

    const setPixelCrop = (value: PixelCrop) => {
        setState((prev) => {
            return {
                ...prev,
                pixelCrop: value,
            };
        });
    };

    const uploadImageAsync = async (imagePreviewRef: HTMLImageElement, canvasRef: HTMLCanvasElement) => {
        if (pixelCrop) {
            setProcessing(true);
            generateCropCanvas(imagePreviewRef, canvasRef, pixelCrop);

            const blob = await convertCanvasToBlob(canvasRef, selectedImageType);

            if (blob) {
                await uploadCallbackAsync(blob);

                setState((prev) => {
                    return {
                        ...prev,
                        processing: false,
                        openDialog: false,
                    };
                });
            } else {
                setProcessing(false);
                processingErrorCallback();
            }
        }
    };

    const resetImage = async () => {
        try {
            setProcessing(true);
            await resetCallbackAsync();
        } finally {
            setProcessing(false);
        }
    };

    return {
        openDialog,
        processing,
        validationError,
        selectedImage,
        selectedImageType,
        crop,
        pixelCrop,

        setOpenDialog,
        setProcessing,
        setSelectedImage,
        setCrop,
        setPixelCrop,
        uploadImageAsync,
        resetImage,
    };
};

export const ImageCropContextProvider = ({
    children,
    uploadCallbackAsync,
    resetCallbackAsync,
    processingErrorCallback,
}: ImageCropContextProviderProps) => {
    const provider = useImageCropProvider({
        uploadCallbackAsync,
        resetCallbackAsync,
        processingErrorCallback,
    });

    return <ImageCropContext.Provider value={provider}>{children}</ImageCropContext.Provider>;
};
