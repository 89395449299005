import { Currency } from '../../Models/Currency';

export const IDRCurrencyISO = 'IDR';

const currencies: Currency[] = [
    {
        id: 'USD',
        label: 'United States Dollar (USD)',
    },
    {
        id: 'EUR',
        label: 'Euro (EUR)',
    },
    {
        id: 'JPY',
        label: 'Japanese Yen (JPY)',
    },
    {
        id: 'GBP',
        label: 'British Pound (GBP)',
    },
    {
        id: 'CNY',
        label: 'Chinese Renminbi Yuan (CNY)',
    },
    {
        id: 'AUD',
        label: 'Australian Dollar (AUD)',
    },
    {
        id: 'CAD',
        label: 'Canadian Dollar (CAD)',
    },
    {
        id: 'CHF',
        label: 'Swiss Franc (CHF)',
    },
    {
        id: 'HKD',
        label: 'Hong Kong Dollar (HKD)',
    },
    {
        id: 'SGD',
        label: 'Singapore Dollar (SGD)',
    },
    {
        id: 'AFN',
        label: 'Afghan Afghani (AFN)',
    },
    {
        id: 'ALL',
        label: 'Albanian Lek (ALL)',
    },
    {
        id: 'DZD',
        label: 'Algerian Dinar (DZD)',
    },
    {
        id: 'AOA',
        label: 'Angolan Kwanza (AOA)',
    },
    {
        id: 'ARS',
        label: 'Argentine Peso (ARS)',
    },
    {
        id: 'AMD',
        label: 'Armenian Dram (AMD)',
    },
    {
        id: 'AWG',
        label: 'Aruban Florin (AWG)',
    },
    {
        id: 'AZN',
        label: 'Azerbaijani Manat (AZN)',
    },
    {
        id: 'BSD',
        label: 'Bahamian Dollar (BSD)',
    },
    {
        id: 'BDT',
        label: 'Bangladeshi Taka (BDT)',
    },
    {
        id: 'BBD',
        label: 'Barbadian Dollar (BBD)',
    },
    {
        id: 'BYN',
        label: 'Belarusian Ruble (BYN)',
    },
    {
        id: 'BZD',
        label: 'Belize Dollar (BZD)',
    },
    {
        id: 'BMD',
        label: 'Bermudian Dollar (BMD)',
    },
    {
        id: 'BOB',
        label: 'Bolivian Boliviano (BOB)',
    },
    {
        id: 'BAM',
        label: 'Bosnia and Herzegovina Convertible Mark (BAM)',
    },
    {
        id: 'BWP',
        label: 'Botswana Pula (BWP)',
    },
    {
        id: 'BRL',
        label: 'Brazilian Real (BRL)',
    },
    {
        id: 'BND',
        label: 'Brunei Dollar (BND)',
    },
    {
        id: 'BGN',
        label: 'Bulgarian Lev (BGN)',
    },
    {
        id: 'BIF',
        label: 'Burundian Franc (BIF)',
    },
    {
        id: 'KHR',
        label: 'Cambodian Riel (KHR)',
    },
    {
        id: 'CVE',
        label: 'Cape Verdean Escudo (CVE)',
    },
    {
        id: 'KYD',
        label: 'Cayman Islands Dollar (KYD)',
    },
    {
        id: 'XAF',
        label: 'Central African Cfa Franc (XAF)',
    },
    {
        id: 'XPF',
        label: 'Cfp Franc (XPF)',
    },
    {
        id: 'CLP',
        label: 'Chilean Peso (CLP)',
    },
    {
        id: 'COP',
        label: 'Colombian Peso (COP)',
    },
    {
        id: 'KMF',
        label: 'Comorian Franc (KMF)',
    },
    {
        id: 'CDF',
        label: 'Congolese Franc (CDF)',
    },
    {
        id: 'CRC',
        label: 'Costa Rican Colón (CRC)',
    },
    {
        id: 'CZK',
        label: 'Czech Koruna (CZK)',
    },
    {
        id: 'DKK',
        label: 'Danish Krone (DKK)',
    },
    {
        id: 'DJF',
        label: 'Djiboutian Franc (DJF)',
    },
    {
        id: 'DOP',
        label: 'Dominican Peso (DOP)',
    },
    {
        id: 'XCD',
        label: 'East Caribbean Dollar (XCD)',
    },
    {
        id: 'EGP',
        label: 'Egyptian Pound (EGP)',
    },
    {
        id: 'ETB',
        label: 'Ethiopian Birr (ETB)',
    },
    {
        id: 'FKP',
        label: 'Falkland Pound (FKP)',
    },
    {
        id: 'FJD',
        label: 'Fijian Dollar (FJD)',
    },
    {
        id: 'GMD',
        label: 'Gambian Dalasi (GMD)',
    },
    {
        id: 'GEL',
        label: 'Georgian Lari (GEL)',
    },
    {
        id: 'GIP',
        label: 'Gibraltar Pound (GIP)',
    },
    {
        id: 'GTQ',
        label: 'Guatemalan Quetzal (GTQ)',
    },
    {
        id: 'GNF',
        label: 'Guinean Franc (GNF)',
    },
    {
        id: 'GYD',
        label: 'Guyanese Dollar (GYD)',
    },
    {
        id: 'HTG',
        label: 'Haitian Gourde (HTG)',
    },
    {
        id: 'HNL',
        label: 'Honduran Lempira (HNL)',
    },
    {
        id: 'HUF',
        label: 'Hungarian Forint (HUF)',
    },
    {
        id: 'ISK',
        label: 'Icelandic Króna (ISK)',
    },
    {
        id: 'INR',
        label: 'Indian Rupee (INR)',
    },
    {
        id: 'IDR',
        label: 'Indonesian Rupiah (IDR)',
    },
    {
        id: 'ILS',
        label: 'Israeli New Sheqel (ILS)',
    },
    {
        id: 'JMD',
        label: 'Jamaican Dollar (JMD)',
    },
    {
        id: 'KZT',
        label: 'Kazakhstani Tenge (KZT)',
    },
    {
        id: 'KES',
        label: 'Kenyan Shilling (KES)',
    },
    {
        id: 'KGS',
        label: 'Kyrgyzstani Som (KGS)',
    },
    {
        id: 'LAK',
        label: 'Lao Kip (LAK)',
    },
    {
        id: 'LBP',
        label: 'Lebanese Pound (LBP)',
    },
    {
        id: 'LSL',
        label: 'Lesotho Loti (LSL)',
    },
    {
        id: 'LRD',
        label: 'Liberian Dollar (LRD)',
    },
    {
        id: 'MOP',
        label: 'Macanese Pataca (MOP)',
    },
    {
        id: 'MKD',
        label: 'Macedonian Denar (MKD)',
    },
    {
        id: 'MGA',
        label: 'Malagasy Ariary (MGA)',
    },
    {
        id: 'MWK',
        label: 'Malawian Kwacha (MWK)',
    },
    {
        id: 'MYR',
        label: 'Malaysian Ringgit (MYR)',
    },
    {
        id: 'MVR',
        label: 'Maldivian Rufiyaa (MVR)',
    },
    {
        id: 'MUR',
        label: 'Mauritian Rupee (MUR)',
    },
    {
        id: 'MXN',
        label: 'Mexican Peso (MXN)',
    },
    {
        id: 'MDL',
        label: 'Moldovan Leu (MDL)',
    },
    {
        id: 'MNT',
        label: 'Mongolian Tögrög (MNT)',
    },
    {
        id: 'MAD',
        label: 'Moroccan Dirham (MAD)',
    },
    {
        id: 'MZN',
        label: 'Mozambican Metical (MZN)',
    },
    {
        id: 'MMK',
        label: 'Myanmar Kyat (MMK)',
    },
    {
        id: 'NAD',
        label: 'Namibian Dollar (NAD)',
    },
    {
        id: 'NPR',
        label: 'Nepalese Rupee (NPR)',
    },
    {
        id: 'ANG',
        label: 'Netherlands Antillean Gulden (ANG)',
    },
    {
        id: 'TWD',
        label: 'New Taiwan Dollar (TWD)',
    },
    {
        id: 'NZD',
        label: 'New Zealand Dollar (NZD)',
    },
    {
        id: 'NIO',
        label: 'Nicaraguan Córdoba (NIO)',
    },
    {
        id: 'NGN',
        label: 'Nigerian Naira (NGN)',
    },
    {
        id: 'NOK',
        label: 'Norwegian Krone (NOK)',
    },
    {
        id: 'PKR',
        label: 'Pakistani Rupee (PKR)',
    },
    {
        id: 'PAB',
        label: 'Panamanian Balboa (PAB)',
    },
    {
        id: 'PGK',
        label: 'Papua New Guinean Kina (PGK)',
    },
    {
        id: 'PYG',
        label: 'Paraguayan Guaraní (PYG)',
    },
    {
        id: 'PEN',
        label: 'Peruvian Sol (PEN)',
    },
    {
        id: 'PHP',
        label: 'Philippine Peso (PHP)',
    },
    {
        id: 'PLN',
        label: 'Polish Złoty (PLN)',
    },
    {
        id: 'QAR',
        label: 'Qatari Riyal (QAR)',
    },
    {
        id: 'RON',
        label: 'Romanian Leu (RON)',
    },
    {
        id: 'RWF',
        label: 'Rwandan Franc (RWF)',
    },
    {
        id: 'SHP',
        label: 'Saint Helenian Pound (SHP)',
    },
    {
        id: 'WST',
        label: 'Samoan Tala (WST)',
    },
    {
        id: 'SAR',
        label: 'Saudi Riyal (SAR)',
    },
    {
        id: 'RSD',
        label: 'Serbian Dinar (RSD)',
    },
    {
        id: 'SCR',
        label: 'Seychellois Rupee (SCR)',
    },
    {
        id: 'SBD',
        label: 'Solomon Islands Dollar (SBD)',
    },
    {
        id: 'SOS',
        label: 'Somali Shilling (SOS)',
    },
    {
        id: 'ZAR',
        label: 'South African Rand (ZAR)',
    },
    {
        id: 'KRW',
        label: 'South Korean Won (KRW)',
    },
    {
        id: 'LKR',
        label: 'Sri Lankan Rupee (LKR)',
    },
    {
        id: 'SRD',
        label: 'Surinamese Dollar (SRD)',
    },
    {
        id: 'SZL',
        label: 'Swazi Lilangeni (SZL)',
    },
    {
        id: 'SEK',
        label: 'Swedish Krona (SEK)',
    },
    {
        id: 'TJS',
        label: 'Tajikistani Somoni (TJS)',
    },
    {
        id: 'TZS',
        label: 'Tanzanian Shilling (TZS)',
    },
    {
        id: 'THB',
        label: 'Thai Baht (THB)',
    },
    {
        id: 'TOP',
        label: 'Tongan Paʻanga (TOP)',
    },
    {
        id: 'TTD',
        label: 'Trinidad and Tobago Dollar (TTD)',
    },
    {
        id: 'TRY',
        label: 'Turkish Lira (TRY)',
    },
    {
        id: 'UGX',
        label: 'Ugandan Shilling (UGX)',
    },
    {
        id: 'UAH',
        label: 'Ukrainian Hryvnia (UAH)',
    },
    {
        id: 'AED',
        label: 'United Arab Emirates Dirham (AED)',
    },
    {
        id: 'UYU',
        label: 'Uruguayan Peso (UYU)',
    },
    {
        id: 'UZS',
        label: 'Uzbekistan Som (UZS)',
    },
    {
        id: 'VUV',
        label: 'Vanuatu Vatu (VUV)',
    },
    {
        id: 'VND',
        label: 'Vietnamese Đồng (VND)',
    },
    {
        id: 'XOF',
        label: 'West African Cfa Franc (XOF)',
    },
    {
        id: 'YER',
        label: 'Yemeni Rial (YER)',
    },
    {
        id: 'ZMW',
        label: 'Zambian Kwacha (ZMW)',
    },
];

export default currencies;
