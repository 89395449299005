import { useTheme } from '@mui/material';
import { Button, ButtonProps } from '@talentmesh/core';
import { PersonAddIcon } from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../Utils/UIStrings';

interface AttractApplicantsButtonProps extends ButtonProps {}

function AttractApplicantsButton(props: AttractApplicantsButtonProps): JSX.Element {
    const theme = useTheme();
    return (
        <Button
            sx={{
                minWidth: theme.spacing(19.5),
            }}
            size="medium"
            variant="contained"
            color="primary"
            startIcon={<PersonAddIcon />}
            {...props}
        >
            {UIStrings.AttractApplicants}
        </Button>
    );
}

export default AttractApplicantsButton;
