import React, { ReactNode } from 'react';
import { RecruitmentCreatedNotificationProvider } from '../../Context/RecruitmentCreatedNotificationContext';
import RecruitmentCreatedNotificationTrigger from './RecruitmentCreatedNotification/RecruitmentCreatedNotificationTrigger';

export interface NotificationSystemProps {
    children: ReactNode;
}

function RecruitmentCreatedNotificationSystem({ children }: NotificationSystemProps): JSX.Element {
    return (
        <RecruitmentCreatedNotificationProvider>
            {children}
            <RecruitmentCreatedNotificationTrigger />
        </RecruitmentCreatedNotificationProvider>
    );
}

export default RecruitmentCreatedNotificationSystem;
