import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Stack, Typography } from '@talentmesh/core';
import { CheckBoxIcon, CheckBoxOutlineBlankOutlinedIcon } from '@talentmesh/icons';
import { useFormikContext } from 'formik';
import { TestSelectionFormValues } from '../../../../../../../SelectTestsStep';
import getValueByName from '../../../../../../../../../../../../Utils/getValueByName';

export interface BaseTestCardHeaderProps {
    testName: string;
    fieldName: string;
}

function BaseTestCardHeader({ testName, fieldName }: BaseTestCardHeaderProps): JSX.Element {
    const theme = useTheme();
    const { values } = useFormikContext<TestSelectionFormValues>();

    const [checked, setChecked] = useState<boolean>(false);

    const cardHeaderHeight = theme.spacing(8.875);

    useEffect(() => {
        const v = getValueByName<boolean>(values, fieldName);
        setChecked(v);
    }, [values]);

    return (
        <Stack
            direction="row"
            sx={{
                paddingX: theme.spacing(1.25),
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(0.875),
                minHeight: cardHeaderHeight,
                maxHeight: cardHeaderHeight,
                justifyContent: 'left',
            }}
        >
            <FormControlLabel
                sx={{ pointerEvents: 'none' }}
                control={
                    <Checkbox
                        data-cy={testName}
                        icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: theme.palette.primary.main }} />}
                        checkedIcon={<CheckBoxIcon />}
                        checked={checked}
                    />
                }
                label={
                    <Typography variant="h5" color={theme.palette.text.menuTitle}>
                        {testName}
                    </Typography>
                }
            />
        </Stack>
    );
}

export default BaseTestCardHeader;
