import React from 'react';
import { Box, Stack } from '@talentmesh/core';
import LockIcon from '@mui/icons-material/Lock';
import UIStrings from '../../../Utils/UIStrings';
import Compensation from './Compensation';
import { PaymentIntent } from '../../../Models/PaymentIntent';
import CheckoutSection from '../Components/CheckoutSection';
import CheckoutSectionTitle from '../Components/CheckoutSectionTitle';
import CompensationRead from './Components/CompensationRead';
import CompensationEdit from './Components/CompensationEdit';

interface CompensationSectionProps {
    recruitmentId: string | null;
    applicantId: string | null;
    paymentLoading: boolean;
    setPaymentLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setPaymentIntent: React.Dispatch<React.SetStateAction<PaymentIntent | undefined>>;
    compensation: Compensation;
    setCompensation: React.Dispatch<React.SetStateAction<Compensation>>;
    isCompensationInEdit: boolean;
    setIsCompensationInEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompensationSection = ({
    recruitmentId,
    applicantId,
    paymentLoading,
    setPaymentLoading,
    setPaymentIntent,
    compensation,
    setCompensation,
    isCompensationInEdit,
    setIsCompensationInEdit,
}: CompensationSectionProps) => {
    return (
        <Box>
            <CheckoutSectionTitle title={UIStrings.SecurePayment} prefix={<LockIcon color="primary" />} />
            <CheckoutSection orderNumber={1} title={UIStrings.CompensationDetails}>
                <Stack>
                    {isCompensationInEdit ? (
                        <CompensationEdit
                            recruitmentId={recruitmentId}
                            applicantId={applicantId}
                            paymentLoading={paymentLoading}
                            setPaymentLoading={setPaymentLoading}
                            setPaymentIntent={setPaymentIntent}
                            compensation={compensation}
                            setCompensation={setCompensation}
                            setIsCompensationInEdit={setIsCompensationInEdit}
                        />
                    ) : (
                        <CompensationRead
                            compensation={compensation}
                            setIsCompensationInEdit={setIsCompensationInEdit}
                        />
                    )}
                </Stack>
            </CheckoutSection>
        </Box>
    );
};

export default CompensationSection;
