// todo! this is a hardcoded file, delete if we're able to change language or something

import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { TestSelectionFormValues } from '../../../../../../../SelectTestsStep';
import de from './locales/de/test.json';
import en from './locales/en/test.json';
import es from './locales/es/test.json';
import fr from './locales/fr/test.json';
import it from './locales/it/test.json';
import pt from './locales/pt/test.json';

const useText = () => {
    const {
        values: { testLanguageField },
    } = useFormikContext<TestSelectionFormValues>();

    return useMemo(() => {
        switch (testLanguageField) {
            case 'de':
                return de;
            case 'es':
                return es;
            case 'fr':
                return fr;
            case 'it':
                return it;
            case 'pt':
                return pt;
            case 'en':
            case 'applicant':
            default:
                return en;
        }
    }, [testLanguageField]);
};

export default useText;
