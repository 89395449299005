import React from 'react';
import withSignupChecks from '../../../../Routing/withSignupChecks';
import withTalentMeshUserRequired from '../../../../Routing/withTalentMeshUserRequired';
import { EditRecruitmentProvider } from './Contexts/EditRecruitmentContext';
import EditRecruitmentPageContent from './EditRecruitmentPageContent';
import withGooglePlacesAPICheck from '../../../../Routing/withGooglePlacesAPICheck';
import withCompanyProfileRequired from '../../../../Routing/withCompanyProfileRequired';

const EditRecruitmentPage = () => {
    return (
        <EditRecruitmentProvider>
            <EditRecruitmentPageContent />
        </EditRecruitmentProvider>
    );
};

export default withCompanyProfileRequired(
    withTalentMeshUserRequired(withSignupChecks(withGooglePlacesAPICheck(EditRecruitmentPage))),
);
