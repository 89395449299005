import React, { useRef } from 'react';
import EmptyTopBar from '../../../../Components/TopBars/EmptyTopBar';
import ProcessingState from '../../../../Models/ProcessingState';
import UIStrings from '../../../../Utils/UIStrings';
import Page from '../../../Page';
import PageContentLoading from '../../../PageContentLoading';
import ModifyRecruitmentStepper from '../Components/ModifyRecruitmentStepper';
import { ModifyRecruitmentStepMapper } from '../Components/StepMappers';
import { useEditRecruitmentContext } from './Contexts/EditRecruitmentContext';

const EditRecruitmentPageContent = () => {
    const { processingState, gettingDataProcessingState } = useEditRecruitmentContext();
    const steps = [UIStrings.JobDetails, UIStrings.JobAd];
    const pageContentContainerRef = useRef<HTMLDivElement>(null);

    return (
        <Page topBar={<EmptyTopBar />} ref={pageContentContainerRef}>
            <PageContentLoading
                isLoading={gettingDataProcessingState === ProcessingState.Processing}
                loadingFailed={
                    processingState === ProcessingState.Error || gettingDataProcessingState === ProcessingState.Error
                }
            >
                <ModifyRecruitmentStepper
                    steps={steps}
                    stepMapper={ModifyRecruitmentStepMapper}
                    pageContentContainerRef={pageContentContainerRef}
                />
            </PageContentLoading>
        </Page>
    );
};

export default EditRecruitmentPageContent;
