import { useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { Box } from '@talentmesh/core';

interface CvIconContainerProps {
    children: ReactNode;
}

function CvIconContainer({ children }: CvIconContainerProps): JSX.Element {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: theme.spacing(4),
                height: theme.spacing(4.75),
            }}
        >
            {children}
        </Box>
    );
}

export default CvIconContainer;
