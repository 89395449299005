import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';

interface DialogCardCandidateInfoProps {
    name: string;
    email: string;
}

function DialogCardCandidateInfo({ name, email }: DialogCardCandidateInfoProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="column">
            <Typography variant="h3" color={theme.palette.text.primary}>
                {name}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary} sx={{ lineBreak: 'anywhere' }}>
                {email}
            </Typography>
        </Stack>
    );
}

export default DialogCardCandidateInfo;
