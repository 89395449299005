import React from 'react';
import { useTheme } from '@mui/material/styles';
import { LoadingButton, Tooltip, Typography, LoadingButtonProps } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import { ImageCropStrings } from './ImageCropUtils';
import { ImageCropContextProps } from './ImageCropContext';

export interface ImageCropRemoveButtonProps
    extends Pick<ImageCropStrings, 'removeButtonTooltipText'>,
        Pick<LoadingButtonProps, 'onClick'>,
        Pick<ImageCropContextProps, 'processing'> {}

export const ImageCropRemoveButton = ({
    onClick,
    processing,
    removeButtonTooltipText,
}: ImageCropRemoveButtonProps): JSX.Element => {
    const theme = useTheme();
    const defaultSmallButtonSize = theme.spacing(3.75);

    return (
        <Tooltip placement="top" arrow title={<Typography variant="label">{removeButtonTooltipText}</Typography>}>
            <LoadingButton
                data-cy="ImageCropRemoveButton"
                onClick={onClick}
                loading={processing}
                disableFocusRipple
                disableTouchRipple
                sx={{
                    borderRadius: '50%',
                    backgroundColor: theme.palette.common.white,
                    boxShadow: theme.shadows[6],
                    height: defaultSmallButtonSize,
                    width: defaultSmallButtonSize,
                    minWidth: defaultSmallButtonSize,
                    minHeight: defaultSmallButtonSize,
                    padding: theme.spacing(1),
                    '&:hover': {
                        backgroundColor: theme.palette.error.light,
                        boxShadow: theme.shadows[6],
                    },
                    '&:focus': {
                        backgroundColor: theme.palette.error.light,
                        boxShadow: theme.shadows[6],
                    },
                }}
            >
                {!processing && (
                    <CloseIcon
                        sx={{
                            color: theme.palette.action.active,
                            height: theme.spacing(2.25),
                            width: theme.spacing(2.25),
                        }}
                    />
                )}
            </LoadingButton>
        </Tooltip>
    );
};
