import React, { memo, CSSProperties } from 'react';
import { Checkbox, MenuItemWithValueProps, FormControlLabel } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

export interface CustomMenuItemWithValueProps
    extends Pick<MenuItemWithValueProps, 'id' | 'menuItemLabel' | 'selected'> {
    changeCallback: () => void;
}

export type CheckboxMenuItems = Array<CustomMenuItemWithValueProps>;

export interface CheckboxRowProps extends CustomMenuItemWithValueProps {
    style: CSSProperties;
}

function CheckboxRow({ id, menuItemLabel, selected, changeCallback, style }: CheckboxRowProps): JSX.Element {
    const theme = useTheme();

    return (
        <div key={id} style={{ ...style, paddingInline: theme.spacing(2.5) }}>
            <FormControlLabel
                control={<Checkbox checked={selected} onChange={changeCallback} />}
                label={menuItemLabel}
                slotProps={{ typography: { sx: { whiteSpace: 'nowrap' } } }}
            />
        </div>
    );
}

export default memo(CheckboxRow);
