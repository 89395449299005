import { useTheme } from '@mui/material';
import { Box, Chip, Grid, LoadingTick, Typography } from '@talentmesh/core';
import React from 'react';
import { LikertOption, Question } from '../../../../../../../../Models/Questions';
import useText from './hardcode/useText';

interface LikertQuestionsPreviewProps {
    demoQuestions: Question[];
    likertOptions: LikertOption[];
}

const getColorByScore = (score: number) => {
    switch (score) {
        case 2:
            return 'info';
        case 1:
            return 'success';
        case -1:
            return 'warning';
        case -2:
            return 'error';
        default:
            return undefined;
    }
};

const LikertQuestionsPreview = ({ demoQuestions, likertOptions }: LikertQuestionsPreviewProps) => {
    const theme = useTheme();
    const UIStrings = useText();

    const answerColumnSize = likertOptions.length * 2;
    const answersColumnSize = 5;

    return (
        <Box height="100%" sx={{ overflowY: 'auto' }}>
            <Grid
                container
                position="relative"
                px={theme.spacing(2.5)}
                sx={{ backgroundColor: theme.palette.background.silverSand, placeItems: 'center' }}
            >
                <Grid item md />
                <Grid item container md={answersColumnSize} columns={{ md: answerColumnSize }}>
                    {likertOptions.map(({ answerText }) => (
                        <Grid
                            key={answerText}
                            item
                            md={2}
                            display="flex"
                            sx={{ placeContent: 'center', placeItems: 'center', textAlign: 'center' }}
                        >
                            <Typography variant="body2" maxWidth={theme.spacing(13.75)} py={theme.spacing(1.875)}>
                                {answerText}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <Chip
                    size="medium"
                    color="primary"
                    label={<Typography variant="body1">{UIStrings.PersonalityTestHint}</Typography>}
                    sx={{
                        position: 'absolute',
                        bottom: '-25%',
                        height: theme.spacing(5.5),
                        borderRadius: theme.shape.largeBorderRadius,
                    }}
                />
            </Grid>
            <Box
                display="flex"
                flexDirection="column"
                height="auto"
                justifyContent="space-between"
                my={theme.spacing(5)}
                mx={theme.spacing(2.5)}
                gap={theme.spacing(5)}
            >
                {demoQuestions.map(({ id, question: questionText }) => (
                    <Grid container key={id}>
                        <Grid item md sx={{ placeContent: 'center' }}>
                            <Typography variant="h5">{questionText}</Typography>
                        </Grid>
                        <Grid item container md={answersColumnSize} columns={{ md: answerColumnSize }}>
                            {likertOptions
                                .sort((a, b) => b.score - a.score)
                                .map(({ answerText, score }) => (
                                    <Grid key={answerText} item md={2} display="flex" sx={{ justifyContent: 'center' }}>
                                        <LoadingTick checked={false} loading={false} color={getColorByScore(score)} />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Box>
    );
};

export default LikertQuestionsPreview;
