import React from 'react';
import { Box, BoxProps } from '@talentmesh/core';

function CustomBox({ children, ...props }: BoxProps): JSX.Element {
    return (
        <Box sx={{ p: 3, paddingBottom: 2 }} {...props}>
            {children}
        </Box>
    );
}

export default CustomBox;
