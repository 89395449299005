import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import AmountTextField from './AmountTextField';

function AnnualFixedAmountTextField(): JSX.Element {
    const name = 'annualFixedAmount';

    return (
        <AmountTextField
            name={name}
            data-cy={name}
            label={UIStrings.AnnualFixedAmount}
            tooltipText={UIStrings.AnnualFixedTooltipText}
        />
    );
}

export default AnnualFixedAmountTextField;
