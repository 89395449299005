import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import AmountTextField from './AmountTextField';

function AnnualTargetBonusTextField(): JSX.Element {
    const name = 'annualTargetBonus';

    return (
        <AmountTextField
            name={name}
            data-cy={name}
            label={UIStrings.AnnualTargetBonus}
            tooltipText={UIStrings.AnnualTargetBonusTooltipText}
        />
    );
}

export default AnnualTargetBonusTextField;
