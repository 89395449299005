import React from 'react';
import { SpanTypography, TypographyProps } from '@talentmesh/core';
import { useTheme } from '@mui/material';

function BodyTypography({ children, color, ...props }: TypographyProps): JSX.Element {
    const theme = useTheme();
    return (
        <SpanTypography variant="body1" color={color || theme.palette.text.primary} {...props}>
            {children}
        </SpanTypography>
    );
}

export default BodyTypography;
