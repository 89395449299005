export interface CareerImageDTO {
    url: string;
}

export interface CareerPageDTO {
    pageContent?: string;
    companyId: string;
}

export interface CareerPageStatsDTO {
    publishedDate: string | null;
    activeJobs: number;
}

export const DefaultCareerImageDTO: CareerImageDTO = {
    url: '',
};

export const DefaultCareerPageDTO: CareerPageDTO = {
    pageContent: undefined,
    companyId: '',
};

export const DefaultCareerPageStatsDTO: CareerPageStatsDTO = {
    publishedDate: null,
    activeJobs: 0,
};
