import React from 'react';
import { FormikDropdown } from '@talentmesh/forms';
import UIStrings from '../../../../../../Utils/UIStrings';
import workExperienceOptions from './WorkExperienceOptions';
import { JobDetailsFieldTypes } from '../JobDetailsStepUtils';

function WorkExperience(): JSX.Element {
    return (
        <FormikDropdown
            label={`${UIStrings.WorkExperience}*`}
            name={JobDetailsFieldTypes.WorkExperience}
            options={workExperienceOptions}
            data-cy={JobDetailsFieldTypes.WorkExperience}
        />
    );
}

export default WorkExperience;
