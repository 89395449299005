import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { TimerOutlinedIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import { TestSelectionFormValues } from '../../../SelectTestsStep';
import { useCreateRecruitmentContext } from '../../../../../Create/Contexts/CreateRecruitmentContext';
import getValueByName from '../../../../../../../../Utils/getValueByName';
import { TestTypes } from '../../../../../../../../Models/Configuration';
import TestSelectionFormFieldNames from '../../../Models/TestSelectionFormFieldNames';

function EstimatedTime(): JSX.Element {
    const theme = useTheme();
    const { values } = useFormikContext<TestSelectionFormValues>();
    const { testInfos } = useCreateRecruitmentContext();
    const [totalTime, setTotalTime] = useState(0);

    const getTestDurationByTest = (obj: any, fieldName: string, testType: TestTypes): number => {
        let duration = 0;
        if (getValueByName<boolean>(obj, fieldName)) {
            const test = testInfos.find((t) => t.testType === testType);
            if (test) {
                duration = test.duration;
            }
        }

        return duration;
    };

    useEffect(() => {
        let tt = 0;

        tt += getTestDurationByTest(values, TestSelectionFormFieldNames.isPersonalitySelected, TestTypes.Personality);
        tt += getTestDurationByTest(values, TestSelectionFormFieldNames.isAptitudeSelected, TestTypes.Aptitude);
        tt += getTestDurationByTest(values, TestSelectionFormFieldNames.isSkillsSelected, TestTypes.Skills);
        tt += getTestDurationByTest(
            values,
            TestSelectionFormFieldNames.isEmotionalIntelligenceSelected,
            TestTypes.EmotionalIntelligence,
        );

        setTotalTime(tt);
    }, [values]);
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <TimerOutlinedIcon />
            <Typography variant="body2" color={theme.palette.text.primary}>
                {`${UIStrings.EstimatedTotalAdministrationTime}:`}
            </Typography>
            <Typography data-cy={UIStrings.Minutes} variant="body2" color={theme.palette.primary.main}>
                {totalTime}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary} fontWeight="bold">
                {UIStrings.Minutes}
            </Typography>
        </Stack>
    );
}

export default EstimatedTime;
