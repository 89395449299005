import React from 'react';
import Dialogs from '../../../Dialogs/ATS/Dialogs';
import { useCandidatesResultsContext } from '../Contexts/CandidateResultsContext';

interface ResultsPageDialogsProps {
    assessmentId: string;
}

function ResultsPageDialogs({ assessmentId }: ResultsPageDialogsProps): JSX.Element {
    const { assessmentName } = useCandidatesResultsContext();
    return <Dialogs assessmentId={assessmentId} assessmentName={assessmentName} />;
}

export default ResultsPageDialogs;
