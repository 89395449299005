import React, { useState } from 'react';
import { Stack, Button, LoadingButton } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';

export interface AssessmentResetDialogActionsProps {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
}

const AssessmentResetDialogActions = ({ onConfirm, onCancel }: AssessmentResetDialogActionsProps): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <Stack direction="row" justifyContent="end" spacing={1}>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
                {UIStrings.Cancel}
            </Button>
            <LoadingButton
                size="large"
                loading={loading}
                variant="contained"
                onClick={async () => {
                    setLoading(true);
                    await onConfirm();
                    setLoading(true);
                    onCancel();
                }}
            >
                {UIStrings.ResetAssessment}
            </LoadingButton>
        </Stack>
    );
};

export default AssessmentResetDialogActions;
