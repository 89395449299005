import React from 'react';
import { Label, Stack } from '@talentmesh/core';
import { FormikAutocompleteDropdown } from '@talentmesh/forms';
import { JobDetailsFieldTypes } from '../JobDetailsStepUtils';
import UIStrings from '../../../../../../Utils/UIStrings';
import { languages } from '../../../../../../Utils/JobAdLanguage/JobAdLanguages';

interface JobDescriptionLanguageProps {
    disabled?: boolean;
}

function JobDescriptionLanguage({ disabled }: JobDescriptionLanguageProps): JSX.Element {
    return (
        <Stack flex={1}>
            <Label>{UIStrings.JobDescriptionLanguage}</Label>
            <FormikAutocompleteDropdown
                name={JobDetailsFieldTypes.JobDescriptionLanguage}
                data-cy={JobDetailsFieldTypes.JobDescriptionLanguage}
                autocompleteOptions={languages}
                disabled={disabled}
                disableClearable
                freeSolo={false}
                disableErrorMessage
            />
        </Stack>
    );
}

export default JobDescriptionLanguage;
