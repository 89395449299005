import { useEffect, useState } from 'react';
import { useClientsOverviewContext } from '../../contexts/ClientsOverviewContext';
import { ClientFormValues } from '../Client';
import { ClientOverviewResponse } from '../../../../DTO/Clients/ClientOverviewResponse';
import ProcessingState from '../../../../Models/ProcessingState';

const useClientsOverviewActions = () => {
    const { updateClient, updateClientProcessingState, updateClientIcon, resetClientIcon } =
        useClientsOverviewContext();
    const [open, setOpen] = useState<boolean>(false);
    const [clientToEdit, setClientToEdit] = useState<ClientOverviewResponse | undefined>(undefined);

    useEffect(() => {
        if (updateClientProcessingState === ProcessingState.Success) {
            setOpen(false);
        }
    }, [updateClientProcessingState]);

    const handleUpdateClientSubmit = async (updatedData: ClientFormValues) => {
        await updateClient(clientToEdit!.id, updatedData);
    };

    const handleUpdateClientIconSubmit = async (image: Blob) => {
        const updatedClient = await updateClientIcon(clientToEdit!.id, image);

        if (updatedClient && clientToEdit) {
            setClientToEdit({ ...clientToEdit, ...updatedClient });
        }
    };

    const handleResetClientIconSubmit = async () => {
        await resetClientIcon(clientToEdit!.id);

        if (clientToEdit) {
            setClientToEdit({ ...clientToEdit, iconURL: null });
        }
    };
    return {
        open,
        clientToEdit,
        setOpen,
        handleUpdateClientSubmit,
        handleUpdateClientIconSubmit,
        handleResetClientIconSubmit,
        setClientToEdit,
    };
};

export default useClientsOverviewActions;
