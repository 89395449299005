import React, { useEffect } from 'react';
import { generatePath } from 'react-router';
import { CompanyProfile, DefaultCompanyProfile, companyProfileFromDTO } from '../../../Models/CompanyProfile';
import useQuery from '../../../Hooks/UseQuery';
import { useUserContext } from '../../../Context/UserContext';
import { useCompanyClient, useInvitationClient } from '../../../Hooks/ClientHooks';
import {
    AccountCreationFormAPI,
    AccountCreationFormValues,
} from '../../../Components/AccountCreation/AccountCreationForm';
import AcceptInvitationDTO from '../../../DTO/AcceptInvitationDTO';
import RoutePath from '../../../Routing/RoutePath';
import useTalentMeshHistory from '../../../Routing/useTalentMeshHistory';

interface UseAcceptInvitationState {
    company: CompanyProfile;
    pageLoading: boolean;
    isSubmitting: boolean;
    isError: boolean;
}

interface UseAcceptInvitationStateAPI extends AccountCreationFormAPI {
    companyName: string;
    companyId: string;
    isError: boolean;
}

function useAcceptInvitationState(): UseAcceptInvitationStateAPI {
    const [state, setState] = React.useState<UseAcceptInvitationState>({
        company: DefaultCompanyProfile,
        pageLoading: true,
        isSubmitting: false,
        isError: false,
    });
    const companyId = useQuery('company_id') as string;
    const token = useQuery('token');
    const { user, isLoading, getUserAsync } = useUserContext();
    const companiesClient = useCompanyClient();
    const { company, pageLoading, isError } = state;
    const invitationClient = useInvitationClient();
    const history = useTalentMeshHistory();

    useEffect(() => {
        const doLoadCompanyAsync = async () => {
            if (companyId) {
                const dto = await companiesClient.getCompanyProfile(companyId);
                setState((prevState) => ({ ...prevState, company: companyProfileFromDTO(dto), pageLoading: false }));
            }
        };
        doLoadCompanyAsync();
    }, [companyId]);

    useEffect(() => {
        const doAutoSubmit = async () => {
            const { firstName, lastName } = user;
            if (firstName && lastName && firstName !== '' && lastName !== '' && companyId !== '') {
                await acceptInvitation({ firstName, lastName, companyName: company.companyName });
            }
        };
        doAutoSubmit();
    }, [user, companyId]);

    const acceptInvitation = async (values: AccountCreationFormValues) => {
        try {
            const dto: AcceptInvitationDTO = {
                firstName: values.firstName,
                lastName: values.lastName,
                token: token || '',
            };

            setState((prev) => ({ ...prev, isSubmitting: true }));
            await invitationClient.acceptInvitation(dto);
            await getUserAsync();
            history.replace(generatePath(RoutePath.CompanyRoot, { companyId }));
        } catch (error: any) {
            setState((prev) => ({ ...prev, isError: true, isSubmitting: false }));
        }
    };

    return {
        isLoading: isLoading || pageLoading,
        allowAccountSetup: !user.firstName || !user.lastName || user.firstName === '' || user.lastName === '',
        isSubmitting: state.isSubmitting,
        submitHandler: acceptInvitation,

        companyName: company.companyName,
        companyId: companyId as string,
        isError,
    };
}

export default useAcceptInvitationState;
