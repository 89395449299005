import React, { ReactNode } from 'react';
import { ContainerBackground, BrandedCardContainer } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import SupportSection from './SupportSection';

interface ErrorPageProps {
    errorTitle: string;
    errorBody?: ReactNode;
}

const ErrorDialog = ({
    errorTitle = UIStrings.OopsError,
    errorBody = UIStrings.SomethingWentWrongLong,
}: ErrorPageProps) => {
    return (
        <ContainerBackground>
            <div>
                <BrandedCardContainer header={errorTitle}>{errorBody}</BrandedCardContainer>
                <SupportSection />
            </div>
        </ContainerBackground>
    );
};

export default ErrorDialog;
