import React from 'react';
import { TestTypes } from '../../../../../Models/Configuration';
import { TestScore } from '../../../../../Models/TestScore';
import { EmotionalIntelligenceScore } from '../../../../../Models/EmotionalIntelligenceScore';
import { PersonalityScore } from '../../../../../Models/PersonalityScore';
import CandidateTestMonitoringPanel from './CandidateTestMonitoringPanel';
import UIStrings from '../../../../../Utils/UIStrings';
import { DefaultCTMonitoring } from '../../../../../Models/CandidateTestMonitoring';

interface CTMonitoringPanelByTestTypeProps {
    testType: TestTypes;
    skillsScore?: TestScore;
    aptitudeScore?: TestScore;
    emotionalIntelligenceScore?: EmotionalIntelligenceScore;
    personalityScore?: PersonalityScore;
}
function CTMonitoringPanelByTestType({
    testType,
    skillsScore,
    aptitudeScore,
    emotionalIntelligenceScore,
    personalityScore,
}: CTMonitoringPanelByTestTypeProps): JSX.Element {
    switch (testType) {
        case TestTypes.Aptitude: {
            const settings = aptitudeScore?.settings || DefaultCTMonitoring;
            return (
                <CandidateTestMonitoringPanel
                    testType={testType}
                    isScreenSnapshotsEnabled={settings.isScreenInvigilationEnabled}
                    {...settings}
                />
            );
        }
        case TestTypes.EmotionalIntelligence: {
            const settings = emotionalIntelligenceScore?.settings || DefaultCTMonitoring;
            return (
                <CandidateTestMonitoringPanel
                    testType={testType}
                    isScreenSnapshotsEnabled={settings.isScreenInvigilationEnabled}
                    {...settings}
                />
            );
        }
        case TestTypes.Personality: {
            const settings = personalityScore?.settings || DefaultCTMonitoring;
            return (
                <CandidateTestMonitoringPanel
                    testType={testType}
                    isScreenSnapshotsEnabled={settings.isScreenInvigilationEnabled}
                    {...settings}
                />
            );
        }
        case TestTypes.Skills: {
            const settings = skillsScore?.settings || DefaultCTMonitoring;
            return (
                <CandidateTestMonitoringPanel
                    testType={testType}
                    isScreenSnapshotsEnabled={settings.isScreenInvigilationEnabled}
                    {...settings}
                />
            );
        }
        default:
            throw new Error(UIStrings.TestNotSupportedError(testType));
    }
}

export default CTMonitoringPanelByTestType;
