import { Stack, Button } from '@talentmesh/core';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ChevronRightIcon } from '@talentmesh/icons';
import UIStrings from '../../../../../../Utils/UIStrings';
import ProcessingState from '../../../../../../Models/ProcessingState';
import { JobAdFormValues, jobAdFormValues2Model } from '../JobAdStepUtils';
import JobAdPagePreviewDialog from '../../../../../../Components/JobAdPreview/JobAdPagePreviewDialog';
import { JobAd } from '../../../Models/JobAd';
import { JobDetails } from '../../../Models/JobDetails';
import NextStepButton from '../../../Components/NextStepButton';

interface JobAdNavigationButtonsProps {
    jobAd: JobAd;
    setJobAd: (jobAd: JobAd) => void;
    jobDetails: JobDetails;
    processingState: ProcessingState;
    nextButtonLabel: string;
}

function JobAdNavigationButtons({
    jobAd,
    setJobAd,
    jobDetails,
    processingState,
    nextButtonLabel,
}: JobAdNavigationButtonsProps): JSX.Element {
    const { values, isValid } = useFormikContext<JobAdFormValues>();
    const [open, setOpen] = useState(false);

    const handlePreview = () => {
        setJobAd(jobAdFormValues2Model(values));
        setOpen(true);
    };

    const onClose = () => setOpen(false);

    return (
        <Stack direction="row" spacing={2}>
            <Button
                variant="outlined"
                onClick={handlePreview}
                disabled={!isValid || processingState === ProcessingState.Processing}
            >
                {UIStrings.PreviewJobAd}
            </Button>
            <NextStepButton
                disabled={!isValid}
                loading={processingState === ProcessingState.Processing}
                endIcon={nextButtonLabel === UIStrings.Next && <ChevronRightIcon />}
            >
                {nextButtonLabel}
            </NextStepButton>
            <JobAdPagePreviewDialog open={open} handleClose={onClose} jobAd={jobAd} jobDetails={jobDetails} />
        </Stack>
    );
}

export default JobAdNavigationButtons;
