import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Dialog, Stack } from '@talentmesh/core';
import { useAccountSettingsContext } from '../../../Context/AccountSettingsContext';
import { useUserContext } from '../../../Context/UserContext';
import RoutePath from '../../../Routing/RoutePath';
import UIStrings from '../../../Utils/UIStrings';
import DialogCard from './Components/DialogCard';
import DialogHeader from './Components/DialogHeader';
import DialogList from './Components/DialogList';
import DialogTemplate from './Components/DialogTemplate';
import DialogCardRejectBody from './Components/Reject/DialogCardRejectBody';
import DialogCardRejectTitle from './Components/Reject/DialogCardRejectTitle';
import RejectActions from './Components/Reject/RejectActions';
import { DialogEnum, useActionDialogContext } from './Context/ActionDialogContext';
import RejectIcon from './Components/Reject/RejectIcon';
import useTalentMeshHistory from '../../../Routing/useTalentMeshHistory';
import { useCompanyContext } from '../../../Context/CompanyContext';

interface RejectDialogProps {
    assessmentName: string;
}

function RejectDialog({ assessmentName }: RejectDialogProps): JSX.Element {
    const theme = useTheme();
    const history = useTalentMeshHistory();
    const { accountSettings } = useAccountSettingsContext();
    const { user } = useUserContext();
    const { currentCompanyProfile } = useCompanyContext();
    const { selectedCandidates, isDialogOpen, hideDialog } = useActionDialogContext();

    const [name, setName] = useState('');

    const width = theme.spacing(77.75);
    const height = selectedCandidates.length > 1 ? theme.spacing(76.25) : theme.spacing(80);

    useEffect(() => {
        setName(selectedCandidates.length > 1 ? UIStrings.ApplicantNameVariable : selectedCandidates[0]?.name);
    }, [selectedCandidates]);

    const closeDialog = () => {
        hideDialog(DialogEnum.Reject);
    };

    return (
        <Dialog
            open={isDialogOpen(DialogEnum.Reject)}
            PaperProps={{
                style: {
                    overflow: 'unset',
                    backgroundColor: theme.palette.common.white,
                    boxShadow: 'none',
                    maxWidth: 'unset',
                    width: 'auto',
                    height: 'auto',
                    border: `1px solid ${theme.palette.border.main}`,
                },
            }}
            onClose={closeDialog}
        >
            <Stack direction="row">
                <Stack
                    spacing={2.5}
                    sx={{
                        padding: theme.spacing(2.5),
                        paddingTop: 0,
                        maxWidth: width,
                        height,
                    }}
                >
                    <DialogHeader title={UIStrings.Reject} backgroundColor={theme.palette.charts.chart10} />
                    {selectedCandidates.length === 1 && (
                        <DialogCard
                            dialogTitle={<DialogCardRejectTitle name={name} />}
                            dialogBody={<DialogCardRejectBody name={name} />}
                            icon={<RejectIcon />}
                        />
                    )}
                    {selectedCandidates.length > 1 && <DialogList title={UIStrings.RejectMultipleApplicants} />}

                    <RejectActions />
                </Stack>

                <DialogTemplate
                    sx={{
                        width,
                        height,
                        backgroundColor: theme.palette.grey[200],
                        borderRadius: theme.shape.smallBorderRadius,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                    dialogTitle={UIStrings.PreviewRejectionEmail}
                    note={UIStrings.RejectDialogNote}
                    {...accountSettings.rejectEmailTemplate}
                    candidateName={name}
                    recruiterName={`${user.firstName} ${user.lastName}`}
                    companyName={currentCompanyProfile.companyName}
                    recruitmentName={assessmentName}
                    closeDialog={closeDialog}
                    editEmailTemplate={() => {
                        history.push(RoutePath.AccountSettingsEmailTemplates);
                    }}
                    boxHeight={selectedCandidates.length > 1 ? theme.spacing(47.75) : theme.spacing(51)}
                />
            </Stack>
        </Dialog>
    );
}

export default RejectDialog;
