import { useTheme } from '@mui/material';
import { FormikTextField } from '@talentmesh/forms';
import { useFormikContext } from 'formik';
import React from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';
import { JobAdFieldTypes } from '../JobAdStepUtils';
import WingsIcon from './WingsIcon';

interface JobAdTitleProps {
    loading?: boolean;
}

function JobAdTitle({ loading = false }: JobAdTitleProps): JSX.Element {
    const { isSubmitting } = useFormikContext();
    const theme = useTheme();

    return (
        <FormikTextField
            label={UIStrings.JobAdTitle}
            name={JobAdFieldTypes.Title}
            data-cy={JobAdFieldTypes.Title}
            placeholder={loading ? '' : UIStrings.CustomizeTheJobPostTitle}
            InputProps={{
                startAdornment: loading ? <WingsIcon marginRight={theme.spacing(2)} /> : null,
            }}
            disabled={isSubmitting || loading}
            sx={{
                '& .MuiInputBase-root.Mui-disabled': {
                    backgroundColor: theme.palette.common.white,
                },
            }}
        />
    );
}

export default JobAdTitle;
