import { Box, CircularProgress, Grid, Stack } from '@talentmesh/core';
import React, { useEffect } from 'react';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import ProcessingState from '../../../../Models/ProcessingState';
import { ClientOverviewResponse } from '../../../../DTO/Clients/ClientOverviewResponse';
import { resourceBaseUrl } from '../../../../AppSettings';
import { useCompanyContext } from '../../../../Context/CompanyContext';
import PaginationConsts from '../../../../Utils/PaginationConsts';
import { useClientsOverviewContext } from '../../contexts/ClientsOverviewContext';
import ClientFormDialog from '../ClientForm/ClientFormDialog';
import ClientCard from './ClientCard';
import ClientsOverviewPagination from './ClientsOverviewPagination/ClientsOverviewPagination';
import useClientsOverviewActions from './useClientsOverviewAction';

const ClientsOverviewCards = () => {
    const { showFailToaster } = useNotificationContext();
    const {
        clients,
        getOverviewProcessingState,
        pagination: { totalCount },
    } = useClientsOverviewContext();
    const {
        handleUpdateClientSubmit,
        open,
        setClientToEdit,
        setOpen,
        clientToEdit,
        handleUpdateClientIconSubmit,
        handleResetClientIconSubmit,
    } = useClientsOverviewActions();
    const { currentCompanyProfile } = useCompanyContext();
    const clientIconPlaceholder = `${resourceBaseUrl}/clients/client_placeholder.svg`;

    const getCurrentClientIconURL = (client: ClientOverviewResponse): string => {
        if (client.isDefault) {
            if (currentCompanyProfile.companyIconURL !== '') {
                return currentCompanyProfile.companyIconURL;
            }
        }

        return client.iconURL ?? clientIconPlaceholder;
    };

    const getCurrentClientName = (client: ClientOverviewResponse): string => {
        if (client.isDefault) {
            return currentCompanyProfile.companyName;
        }

        return client.name;
    };

    useEffect(() => {
        if (getOverviewProcessingState === ProcessingState.Error) {
            showFailToaster();
        }
    }, [getOverviewProcessingState]);

    return (
        <Stack height="100%" spacing={3.75} useFlexGap>
            {getOverviewProcessingState === ProcessingState.Processing ? (
                <Box display="flex" height="100%" sx={{ placeItems: 'center', placeContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3} data-cy="ClientCards">
                    {clients.map((client) => (
                        <Grid key={client.id} item xs={3}>
                            <ClientCard
                                onEditClient={(cl) => {
                                    setClientToEdit({ ...cl, iconURL: client.iconURL });
                                    setOpen(true);
                                }}
                                {...client}
                                name={getCurrentClientName(client)}
                                iconURL={getCurrentClientIconURL(client)}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
            {totalCount > PaginationConsts.largePageSize && <ClientsOverviewPagination />}
            <ClientFormDialog
                client={clientToEdit}
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={handleUpdateClientSubmit}
                handleUploadImage={handleUpdateClientIconSubmit}
                handleResetImage={handleResetClientIconSubmit}
            />
        </Stack>
    );
};

export default ClientsOverviewCards;
