import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Box, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';

const SettingsTabFailedLoading = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Box sx={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack alignItems="center">
                <Typography variant="h3" color={theme.palette.text.menuTitle}>
                    {UIStrings.OopsSomethingWentWrong}
                </Typography>
                <Typography variant="body1">{UIStrings.PleaseRefresh}</Typography>
            </Stack>
        </Box>
    );
};

export default SettingsTabFailedLoading;
