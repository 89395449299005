import React from 'react';
import { useTheme } from '@mui/material/styles';
import { PercentileLevel, Stack, TMLinearProgress, TMTooltip, getColorByPercentileLevel } from '@talentmesh/core';
import { InfoOutlinedIcon } from '@talentmesh/icons';
import { CharacteristicsPaper } from '../../../../../../Components/Common/CharacteristicsPaper';

export interface PersonalityCharacteristicsToolTipProps {
    children?: React.ReactNode;
    hidden?: boolean;
    percentileScore: number;
    percentileLevel: PercentileLevel;
}

const PersonalityCharacteristicsToolTip = ({
    children,
    hidden,
    percentileScore,
    percentileLevel,
}: PersonalityCharacteristicsToolTipProps) => {
    const theme = useTheme();
    return (
        <TMTooltip placement="top" title={!hidden && <CharacteristicsPaper>{children}</CharacteristicsPaper>}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <TMLinearProgress progress={percentileScore} level={percentileLevel} />
                <InfoOutlinedIcon
                    style={{
                        visibility: hidden ? 'hidden' : 'unset',
                        color: getColorByPercentileLevel(percentileLevel, theme),
                        height: theme.spacing(2.5),
                    }}
                />
            </Stack>
        </TMTooltip>
    );
};

export default PersonalityCharacteristicsToolTip;
