import { useTheme } from '@mui/material';
import { Box, DonutChart, Stack, Typography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useCandidatesResultsContext } from '../../../Contexts/CandidateResultsContext';

const ExperienceScore = () => {
    const theme = useTheme();
    const { results } = useCandidatesResultsContext();

    return (
        <Stack spacing={1.25} textAlign="center" sx={{ placeItems: 'center' }}>
            <Typography variant="h3" color={theme.palette.text.menuTitle}>
                {UIStrings.ExperienceScore}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.menuTitle} maxWidth={theme.spacing(99)}>
                {UIStrings.TheExperienceScoreShowsHowWellTheCandidateSResumeMatchesTheJobRequirements}
            </Typography>
            <Box py={theme.spacing(2.5)}>
                <DonutChart
                    value={results.experienceCriteriaScore?.averageExperienceCriteriaScore?.score}
                    level={results.experienceCriteriaScore?.averageExperienceCriteriaScore?.scoreLevel}
                    text={UIStrings.OverallScore}
                />
            </Box>
        </Stack>
    );
};

export default ExperienceScore;
