import { useTheme } from '@mui/material';
import { Chip, Stack } from '@talentmesh/core';
import React, { ReactNode } from 'react';

export type StatusChipColors = 'success' | 'warning';

interface StatusChipProps {
    color: StatusChipColors;
    label?: ReactNode;
}

function StatusChip({ color, label }: StatusChipProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={theme.spacing(1)} alignItems="center">
            <Chip
                sx={{
                    backgroundColor: color === 'success' ? theme.palette.success.light : theme.palette.warning.main,
                    borderRadius: '50%',
                    padding: theme.spacing(1),
                    width: theme.spacing(2),
                    height: theme.spacing(2),
                    borderWidth: '1px',
                    borderColor: color === 'success' ? theme.palette.success.dark : theme.palette.warning.dark,
                    borderStyle: 'solid',
                }}
            />
            {label}
        </Stack>
    );
}

export default StatusChip;
