import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import React, { ReactNode } from 'react';

interface CTMonitoringFunctionalityDisabledProps {
    icon: ReactNode;
    message: string;
    height: string;
}

function CTMonitoringFunctionalityDisabled({
    icon,
    message,
    height,
}: CTMonitoringFunctionalityDisabledProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            spacing={theme.spacing(2)}
            sx={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: theme.palette.border.main,
                height,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {icon}
            <Typography
                color={theme.palette.text.disabled}
                variant="h4"
                sx={{
                    display: 'flex',
                }}
            >
                {message}
            </Typography>
        </Stack>
    );
}
export default CTMonitoringFunctionalityDisabled;
