import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';

function EmailVerifiedPageContentTypography(): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="column" alignItems="center">
            <Typography variant="h4" color={theme.palette.text.menuTitle}>
                {UIStrings.YourEmailHasBeenVerified}
            </Typography>
            <Typography>{UIStrings.YouCanNowLoginToTalentMesh}</Typography>
        </Stack>
    );
}
export default EmailVerifiedPageContentTypography;
