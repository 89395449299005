import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Box, LoadingButton, Stack, Typography } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import { Form, Formik } from 'formik';
import { Divider, useTheme } from '@mui/material';
import { PaymentIntent as StripePaymentIntent } from '@stripe/stripe-js';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import UIStrings from '../../../Utils/UIStrings';
import { PaymentIntent } from '../../../Models/PaymentIntent';
import { CreditCardPreview } from '../../../Components/Payment/CreditCardPreview';
import CreditCard from '../../../Components/Card/CreaditCard';
import BillingInfo, { EmptyBillingInfo, billingInfoValidationScheme } from '../../../Models/BillingInfo';
import CountrySelector from '../../../Components/Address/CountrySelector';
import FormGlobalErrorMessage from '../../../Components/FormGlobalErrorMessage';
import BillingAddress from '../../AccountSettings/AccountSettingsTabs/PaymentTab/BillingAddress';
import { useBillingInfoClient, usePaymentMethodClient } from '../../../Hooks/ClientHooks';
import PaymentMethod from '../../../Models/PaymentMethod';
import PaymentMethodSwitcher, { PaymentMethodOption } from './PaymentMethodSwitcher';
import useCompanyId from '../../../Hooks/UseCompanyId';

interface CheckoutFormProps {
    paymentIntent: PaymentIntent | undefined;
    setResultPaymentIntent: React.Dispatch<React.SetStateAction<StripePaymentIntent | undefined>>;
    isCompensationInEdit: boolean;
    billingInfo: BillingInfo | undefined;
    savedPaymentMethod: PaymentMethod | undefined;
    setBillingInfo: React.Dispatch<React.SetStateAction<BillingInfo | undefined>>;
}

export default function CheckoutForm({
    paymentIntent,
    setResultPaymentIntent,
    isCompensationInEdit,
    billingInfo,
    savedPaymentMethod,
    setBillingInfo,
}: CheckoutFormProps) {
    const stripe = useStripe();
    const elements = useElements();
    const theme = useTheme();

    const appInsights = useAppInsightsContext();
    const billingInfoClient = useBillingInfoClient();
    const paymentMethodClient = usePaymentMethodClient();
    const companyId = useCompanyId();

    const [errorMessages, setErrorMessages] = useState<string>('');
    const [isPaymentInProgress, setIsPaymentInProgress] = useState<boolean>(false);

    const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentMethodOption>(
        savedPaymentMethod ? PaymentMethodOption.SavedCard : PaymentMethodOption.NewCard,
    );

    const handlePaymentMethodChange = (event: React.MouseEvent<HTMLElement>, value: PaymentMethodOption) => {
        setSelectedPaymentOption(value);
    };

    const handleSubmit = async (values: BillingInfo) => {
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (!paymentIntent) {
            // Payment intent is created when we submit compensation form.
            // Moreover, "Pay now" button is disabled until payment intent is created.
            return;
        }

        setIsPaymentInProgress(true);

        // save billing info
        if (!billingInfo) {
            try {
                await billingInfoClient.saveBillingInfoAsync(companyId, values);
            } catch {
                setErrorMessages(`${UIStrings.SomethingWentWrong}. ${UIStrings.TryAgainLater}.`);
                setIsPaymentInProgress(false);
                return;
            }
        }

        let result;
        if (savedPaymentMethod && selectedPaymentOption === PaymentMethodOption.SavedCard) {
            // reuse previeously saved payment method
            result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
                payment_method: savedPaymentMethod.stripePaymentMethodId,
            });
        } else {
            const cardElement = elements.getElement(CardElement);

            if (cardElement) {
                result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
                    payment_method: {
                        card: cardElement,
                    },
                });
            }
        }

        if (result?.error && result.error?.message) {
            // show error and collect new card details.
            setErrorMessages(result.error.message);
            setIsPaymentInProgress(false);
            return;
        }

        // save payment method details in our DB so that we can reuse the in the next payment.
        if (result?.paymentIntent?.payment_method && typeof result.paymentIntent.payment_method === 'string') {
            try {
                await paymentMethodClient.savePaymentMethodAsync(companyId, result.paymentIntent.payment_method);
            } catch {
                // if we can't save payment method, we do not want interrupt the user flow.
                // in the worst case they would need to enter card details again.
                appInsights.trackException({
                    error: new Error('Failed to save payment method'),
                    severityLevel: SeverityLevel.Error,
                });
            }
        }

        setIsPaymentInProgress(false);
        // update payment intent as the status should be "succeded"
        setResultPaymentIntent(result?.paymentIntent);
    };

    return (
        <Formik
            initialValues={EmptyBillingInfo}
            validationSchema={billingInfo ? undefined : billingInfoValidationScheme}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
        >
            <Form>
                <Stack direction="column" spacing={2.5}>
                    {savedPaymentMethod ? (
                        <Box>
                            <Stack
                                sx={{ marginBottom: 1.25 }}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="body1" color={theme.palette.text.secondary}>
                                    {UIStrings.PaymentMethod}
                                </Typography>
                                <PaymentMethodSwitcher
                                    paymentMethodOption={selectedPaymentOption}
                                    handleChange={handlePaymentMethodChange}
                                />
                            </Stack>
                            {selectedPaymentOption === PaymentMethodOption.NewCard ? (
                                <CreditCard />
                            ) : (
                                <CreditCardPreview
                                    cardLast4={savedPaymentMethod.cardLast4}
                                    cardBrand={savedPaymentMethod.cardBrand}
                                />
                            )}
                        </Box>
                    ) : (
                        <CreditCard />
                    )}
                    {billingInfo ? (
                        <>
                            <Box>
                                <Divider sx={{ my: theme.spacing(1.25) }} />
                            </Box>
                            <BillingAddress billingInfo={billingInfo} setBillingInfo={setBillingInfo} />
                        </>
                    ) : (
                        <Stack spacing={2.5}>
                            <FormikTextField label={UIStrings.BillingTo} name="billingTo" />
                            <CountrySelector label={`${UIStrings.Country}*`} name="country" />
                            <FormikTextField label={UIStrings.AddressLine1} name="addressLine1" />
                            <FormikTextField label={UIStrings.AddressLine2} name="addressLine2" />
                            <Stack direction="row" spacing={2}>
                                <Stack sx={{ width: '100%' }} spacing={2.5}>
                                    <FormikTextField label={UIStrings.City} name="city" />
                                    <FormikTextField label={UIStrings.Zip} name="postalCode" />
                                </Stack>
                                <FormikTextField label={UIStrings.State} name="state" />
                            </Stack>
                        </Stack>
                    )}
                </Stack>

                <FormGlobalErrorMessage errorMessages={errorMessages || ''} />

                <LoadingButton
                    sx={{ mt: 3.75 }}
                    fullWidth
                    size="large"
                    variant="contained"
                    loading={isPaymentInProgress}
                    type="submit"
                    disabled={!stripe || !elements || !paymentIntent || isCompensationInEdit}
                >
                    {UIStrings.PayNow}
                </LoadingButton>
            </Form>
        </Formik>
    );
}
