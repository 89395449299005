/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { Box, Stack, Paper, GridSortModel, TMDataGrid } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { useActionDialogContext } from '../../../Dialogs/ATS/Context/ActionDialogContext';
import { useCandidatesOverviewContext } from '../Contexts/CandidatesOverviewContext';
import ToolBar from './List/ToolBar';
import useCandidateOverviewList from './Hooks/useCandidateOverviewList';
import NoRowsOverlay from './List/NoRowsOverlay';
import GridLoadingOverlay from './List/GridLoadingOverlay';

function CandidatesOverviewList(): JSX.Element {
    const theme = useTheme();
    const {
        candidates,
        loading,
        loadPageAsync,
        totalCandidatesCount,
        pageSize,
        pageNumber,
        handlePaginationModelChange,
        tabValue,
    } = useCandidatesOverviewContext();
    const { columns, getRowId, onCellClickHandler, updateSorting } = useCandidateOverviewList();
    const { candidateStatuses } = useActionDialogContext();

    const updateSortingHandle = (model: GridSortModel) => {
        updateSorting(model);
    };

    useEffect(() => {
        const doReloadThePage = async () => await loadPageAsync();
        doReloadThePage();
    }, [candidateStatuses]);

    return (
        <Stack>
            <Paper
                elevation={0}
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: theme.shape.smallBorderRadius,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: theme.palette.common.white,
                    }}
                >
                    <TMDataGrid
                        getRowId={getRowId}
                        columns={columns}
                        rows={candidates}
                        rowHeight={150}
                        columnHeaderHeight={64}
                        loading={loading}
                        onCellClick={onCellClickHandler}
                        // temp disabled because of MUI implementation:
                        // when rows per page is changed on the last page
                        // we cannot land user to the first one - MUi forces us to stay on the last page
                        // rowsPerPageOptions={[PaginationConsts.regularPageSize]}
                        paginationModel={{ pageSize, page: pageNumber }}
                        onPaginationModelChange={handlePaginationModelChange}
                        rowCount={totalCandidatesCount}
                        paginationMode="server"
                        filterMode="server"
                        sortingMode="server"
                        disableRowSelectionOnClick
                        slots={{
                            noResultsOverlay: NoRowsOverlay,
                            loadingOverlay: GridLoadingOverlay,
                            toolbar: ToolBar,
                        }}
                        slotProps={{
                            toolbar: {
                                tabValue,
                            },
                        }}
                        sx={{
                            clipPath: 'inset(0px -50px -50px -50px)',
                            boxShadow: theme.palette.shadow.lightGlow,
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: theme.palette.grey[100],
                                borderRadius: '0px',
                                border: 'none',
                            },
                            '& .MuiDataGrid-columnHeaderTitleContainerContent span:not(.Mui-disabled)': {
                                color: theme.palette.secondary.main,
                                ...theme.typography.caption,
                            },
                            '& .MuiDataGrid-root, .MuiDataGrid-columnHeader, .MuiDataGrid-root, .MuiDataGrid-cell': {
                                padding: 0,
                            },
                        }}
                        onSortModelChange={(newSortModel) => updateSortingHandle(newSortModel)}
                        sortingOrder={['desc', 'asc', null]}
                        pageSizeOptions={[10]}
                    />
                </Box>
            </Paper>
        </Stack>
    );
}

export default CandidatesOverviewList;
