import { useTheme } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { TMDataGrid } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import { RoleItemProps, useAllowanceColumn, useRoleColumn } from './Hooks/useRolePickerColumns';
import classes from './TeamMembersTable/utils/classes';

const TeamMemberRolePicker = () => {
    const theme = useTheme();
    const columns: GridColDef<RoleItemProps>[] = [
        useRoleColumn(),
        useAllowanceColumn('inviteMember', UIStrings.InviteMembers),
        useAllowanceColumn('createRecruitment', UIStrings.CreateRecruitments),
        useAllowanceColumn('useAts', UIStrings.UseATSFunctionality),
        useAllowanceColumn('viewApplicants', UIStrings.ViewApplicants),
    ];

    const rows: GridRowsProp<RoleItemProps> = [
        {
            name: UIStrings.Admin,
            description: UIStrings.ThisUserWillHaveFullAccessToTheAccount,
            createRecruitment: true,
            inviteMember: true,
            useAts: true,
            viewApplicants: true,
        },
    ];

    return (
        <TMDataGrid
            autoHeight
            hideFooter
            getRowId={(row) => row.name}
            columnHeaderHeight={100}
            rowHeight={90}
            rows={rows}
            columns={columns}
            getDetailPanelContent={(params) => params.row.description}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            sx={{
                [`& .${classes.borderRight}`]: {
                    borderRight: `1px solid ${theme.palette.border.main}`,
                },
                '& .MuiDataGrid-columnHeaders': {
                    border: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                    '&:hover': {
                        backgroundColor: 'inherit',
                        cursor: 'default',
                    },
                },
                '& .MuiDataGrid-columnHeader': {
                    cursor: 'default',
                    backgroundColor: 'transparent',

                    '&:first-child': {
                        paddingLeft: 0,
                    },
                },

                '& .MuiDataGrid-cell:first-of-type': {
                    paddingLeft: 0,
                },
            }}
        />
    );
};

export default TeamMemberRolePicker;
