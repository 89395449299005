import React from 'react';
import { Box, DropdownMenuItem, Grid, SpanTypography, theme, Typography } from '@talentmesh/core';
import LocationPrediction from '../../../../../../../Models/LocationPrediction';

interface Parts {
    key: string;
    text: string;
    highlight: boolean;
}

interface LocationOptionProps {
    props: React.HTMLAttributes<HTMLLIElement>;
    parts: Parts[];
    option: LocationPrediction;
}

function LocationOption({ props, parts, option }: LocationOptionProps): JSX.Element {
    return (
        <DropdownMenuItem {...props}>
            <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: 'break-word' }}>
                    {parts.map((part) => (
                        <Box key={part.key} component="span">
                            <SpanTypography
                                variant="body1"
                                color={theme.palette.text.menuTitle}
                                fontWeight={part.highlight ? 'bold' : 'regular'}
                            >
                                {part.text}
                            </SpanTypography>
                        </Box>
                    ))}
                    <Typography variant="body2" color={theme.palette.text.secondary}>
                        {option.secondaryText}
                    </Typography>
                </Grid>
            </Grid>
        </DropdownMenuItem>
    );
}

export default LocationOption;
