type TestDurationType = {
    allocatedTimeMinutes?: number | null;
    estimatedTimeMinutes: number;
};

function getTestDuration(duration: TestDurationType): number {
    const { allocatedTimeMinutes, estimatedTimeMinutes } = duration;

    // allocatedTimeMinutes - for Aptitude & Skills
    // estimatedTimeMinutes - for Personality & EI
    return allocatedTimeMinutes || estimatedTimeMinutes;
}

export default getTestDuration;
