import React from 'react';
import { useTheme } from '@mui/material';
import { Grid, Typography } from '@talentmesh/core';
import { ArrowDownwardIcon, ArrowUpwardIcon } from '@talentmesh/icons';
import { characteristicSize, descriptionSize, importanceSize } from './EICharacteristicConsts';

interface EICharacteristicAspectProps {
    isHighDescriptor: boolean;
    descriptor: string;
}

function EICharacteristicAspect({ isHighDescriptor, descriptor }: EICharacteristicAspectProps): JSX.Element {
    const theme = useTheme();

    return (
        <>
            <Grid item display="flex" justifyContent="center" xs={characteristicSize}>
                {isHighDescriptor && <ArrowUpwardIcon style={{ color: theme.palette.charts.chart9 }} />}
                {!isHighDescriptor && <ArrowDownwardIcon style={{ color: theme.palette.charts.chart10 }} />}
            </Grid>
            <Grid item xs={descriptionSize} sx={{ paddingBottom: 1.5 }}>
                <Typography style={{ wordWrap: 'break-word' }} variant="body2">
                    {descriptor}
                </Typography>
            </Grid>
            <Grid item xs={importanceSize} />
        </>
    );
}

export default EICharacteristicAspect;
