export interface MeanTopicScore {
    questionTopicName: string;
    meanPercentileScore: number | null;
    internalMeanPercentileScore: number | null;
}

export interface AssessmentMeanBenchmark {
    assessmentId: string;

    meanAptitudePercentileScore: number | null;
    meanSkillsPercentileScore: number | null;

    internalMeanAptitudePercentileScore: number | null;
    internalMeanSkillsPercentileScore: number | null;

    meanAptitudeTopicScores: Array<MeanTopicScore>;
    meanSkillsTopicScores: Array<MeanTopicScore>;
}

export const DefaultAssessmentMeanBenchmark: AssessmentMeanBenchmark = {
    assessmentId: '',
    meanAptitudePercentileScore: null,
    meanSkillsPercentileScore: null,
    internalMeanAptitudePercentileScore: null,
    internalMeanSkillsPercentileScore: null,
    meanAptitudeTopicScores: [],
    meanSkillsTopicScores: [],
};
