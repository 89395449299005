function autoMapping<TSource, TDestination>(source: TSource, defaultDestination: TDestination): TDestination {
    const destination: TDestination = { ...defaultDestination };
    // @ts-ignore
    Object.keys(destination).forEach((key) => {
        const value = source[key as keyof TSource];
        if (value !== undefined) {
            // @ts-ignore
            destination[key as keyof TDestination] = value;
        }
    });
    return destination;
}

export default autoMapping;
