import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { Stack } from '@talentmesh/core';

interface RecruitmentListItemInnerProps {
    children: React.ReactNode;
}

function RecruitmentListItemInner({ children }: RecruitmentListItemInnerProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            sx={{
                p: 2.5,
                '&:hover': {
                    backgroundColor: theme.palette.grey[100],
                },
            }}
            spacing={1}
        >
            {children}
        </Stack>
    );
}

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
}));

interface RecruitmentListItemActionAreaProps {
    navigateTo: string;
    children: React.ReactNode;
}

function RecruitmentListItemActionArea({ navigateTo, children }: RecruitmentListItemActionAreaProps): JSX.Element {
    return (
        <StyledLink to={navigateTo}>
            <RecruitmentListItemInner>{children}</RecruitmentListItemInner>
        </StyledLink>
    );
}

export default RecruitmentListItemActionArea;
