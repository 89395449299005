import UserDTO from '../DTO/Users/UserDTO';

export type Role = 'Recruiter' | 'Candidate';

export default interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    role?: Role;
}

export const DefaultUser: User = {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    role: 'Recruiter',
};

export const fromUserDTO = (dto: UserDTO): User => {
    return {
        id: dto.id,
        email: dto.email,
        firstName: dto.firstName,
        lastName: dto.lastName,
        role: dto.role,
    } as User;
};
