import React, { memo } from 'react';
import { Stack, Typography, Button, Divider } from '@talentmesh/core';
import { RestartAltIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../../Utils/UIStrings';

export interface BaseFilterPanelContentProps {
    resetCallback: () => void;
    children: JSX.Element;
}

function BaseFilterPanelContent({ resetCallback, children }: BaseFilterPanelContentProps): JSX.Element {
    const theme = useTheme();

    return (
        <>
            <Stack spacing={1.25}>
                <Stack
                    direction="row"
                    spacing={1.25}
                    alignItems="center"
                    sx={{ padding: theme.spacing(2.5), paddingBottom: 0 }}
                >
                    <Typography color="secondary" variant="body1">
                        Filter
                    </Typography>
                    <Button
                        startIcon={<RestartAltIcon />}
                        size="small"
                        onClick={resetCallback}
                        data-cy="AdvancedFilterPanelReset"
                    >
                        {UIStrings.ClearAll}
                    </Button>
                </Stack>
                <Divider />
            </Stack>
            {children}
        </>
    );
}

export default memo(BaseFilterPanelContent);
