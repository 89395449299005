import React from 'react';
import CvIconContainer from './CvIconContainer';
import UIStrings from '../../../../../../../../../Utils/UIStrings';
import PdfLargeSVG from '../Icons/PdfLargeSVG.svg';

function PdfLargeIcon(): JSX.Element {
    return (
        <CvIconContainer>
            <object type="image/svg+xml" data={PdfLargeSVG} aria-label={UIStrings.Pdf} data-cy="PdfLargeIcon" />
        </CvIconContainer>
    );
}
export default PdfLargeIcon;
