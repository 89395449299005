import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, TMTooltip, IconButton } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import { FormikCheckbox } from '@talentmesh/forms';
import InviteCandidateTooltipBody from './InviteCandidateTooltipBody';
import UIStrings from '../../../../../Utils/UIStrings';

function CheckboxWithTooltip(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="row">
            <FormikCheckbox
                name="isInternal"
                data-cy="isInternal"
                label={UIStrings.UseAsInternalBenchmark}
                variant="body1"
            />
            <TMTooltip title={<InviteCandidateTooltipBody />} placement="right">
                <IconButton>
                    <InfoIcon
                        sx={{
                            color: theme.palette.secondary.main,
                        }}
                    />
                </IconButton>
            </TMTooltip>
        </Stack>
    );
}

export default CheckboxWithTooltip;
