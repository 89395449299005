import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import UIStrings from '../../../../../../../Utils/UIStrings';

const AdjustedByRecruiterChip = () => {
    const theme = useTheme();
    return (
        <Stack
            data-cy="AdjustedByRecruiterChip"
            direction="row"
            sx={{
                backgroundColor: theme.palette.hue.primary.h50,
                color: theme.palette.hue.primary.h900,
                borderRadius: theme.shape.smallBorderRadius,
                padding: theme.spacing(0.375, 0.5),
                alignItems: 'center',
                maxWidth: theme.spacing(32.375),
            }}
        >
            <Typography
                variant="chips"
                sx={{
                    paddingX: theme.spacing(0.75),
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                {UIStrings.AdjustedByRecruiter}
            </Typography>
        </Stack>
    );
};

export default AdjustedByRecruiterChip;
