import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { Grid } from '@talentmesh/core';
import UIStrings from '../../../../../../../../../../../Utils/UIStrings';
import { characteristicSize, importanceSize, progressSize, scoreSize } from './PersonalityCharacteristicConsts';

function PersonalityCharacteristicHeader(): JSX.Element {
    const theme = useTheme();

    return (
        <>
            <Grid item xs={characteristicSize} sx={{ paddingBottom: 1 }}>
                <Typography variant="label" color={theme.palette.text.secondary}>
                    {UIStrings.Characteristic}
                </Typography>
            </Grid>
            <Grid item xs={scoreSize}>
                <Typography variant="label" color={theme.palette.text.secondary}>
                    {UIStrings.Score}
                </Typography>
            </Grid>
            <Grid item xs={progressSize} />
            <Grid item xs={importanceSize}>
                <Typography variant="label" color={theme.palette.text.secondary}>
                    {UIStrings.Importance}
                </Typography>
            </Grid>
        </>
    );
}

export default PersonalityCharacteristicHeader;
