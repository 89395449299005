import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { PaymentIntent as StripePaymentIntent } from '@stripe/stripe-js';
import MarketingEvents from '../../../Marketing/MarketingEvents';
import RoutePath from '../../../Routing/RoutePath';
import UIStrings from '../../../Utils/UIStrings';
import SuccessTick from './SuccessTick.svg';
import PaymentResultContent from './PaymentResultContent';
import convertFromStripeAmount from '../../../Utils/Currency/converFromStripeAmount';
import useTalentMeshHistory from '../../../Routing/useTalentMeshHistory';

interface SuccessfulPaymentProps {
    paymentIntent: StripePaymentIntent;
}

const SuccessfulPayment = ({ paymentIntent }: SuccessfulPaymentProps) => {
    const history = useTalentMeshHistory();

    useEffect(() => {
        const tagManagerArgs = {
            dataLayer: {
                event: MarketingEvents.PurchaseCompleted,
                value: convertFromStripeAmount(paymentIntent.amount, paymentIntent.currency),
                currency: paymentIntent.currency,
                transaction_id: paymentIntent.id,
            },
        };

        TagManager.dataLayer(tagManagerArgs);
    }, []);

    const handleClick = () => {
        history.push(RoutePath.CompanyRoot);
    };

    return (
        <PaymentResultContent
            title={UIStrings.PaymentSuccessful}
            descriptionLine1={UIStrings.PaymentSuccessMessage1}
            descriptionLine2={UIStrings.PaymentSuccessMessage2}
            onClick={handleClick}
            imageData={SuccessTick}
            buttonText={UIStrings.GoToRecruitmentOverview}
        />
    );
};

export default SuccessfulPayment;
