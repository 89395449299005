import React from 'react';
import { Box, Button } from '@talentmesh/core';
import { KeyboardArrowLeftIcon } from '@talentmesh/icons';
import UIStrings from '../Utils/UIStrings';
import useTalentMeshHistory from '../Routing/useTalentMeshHistory';

const PageBackNavigation = () => {
    const history = useTalentMeshHistory();
    return (
        <Box>
            <Button
                fullWidth={false}
                color="secondary"
                value="text"
                size="medium"
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => history.goBack()}
            >
                {UIStrings.Back}
            </Button>
        </Box>
    );
};
export default PageBackNavigation;
