import { useTheme } from '@mui/material';
import React from 'react';
import { Box, SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

interface InterviewConfirmDialogHeaderProps {
    name: string;
}

function InterviewConfirmDialogHeader({ name }: InterviewConfirmDialogHeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Box>
            <SpanTypography variant="h3" color={theme.palette.text.menuTitle}>
                {`${UIStrings.InterviewConfirmDialogHeader1} `}
            </SpanTypography>
            <SpanTypography variant="h3" color={theme.palette.text.menuTitle}>
                {`${name}?`}
            </SpanTypography>
        </Box>
    );
}
export default InterviewConfirmDialogHeader;
