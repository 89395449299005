import { useTheme } from '@mui/material';
import React from 'react';
import { Button, Dialog, Paper, SpanTypography, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../Contexts/InviteCandidateContext';
import PopupDialogTitle from './PopupDialogTitle';

function PreviewInvitationEmailDialog(): JSX.Element {
    const theme = useTheme();
    const { previewInvitationEmailDialogOpen, setPreviewInvitationEmailDialogOpen } = useInviteCandidateContext();

    return (
        <Dialog open={previewInvitationEmailDialogOpen}>
            <PopupDialogTitle
                title={UIStrings.PreviewRecruitmentInvitationEmail}
                close={() => setPreviewInvitationEmailDialogOpen(false)}
            />
            <Paper
                elevation={0}
                sx={{
                    marginX: theme.spacing(1.25),
                    marginBottom: theme.spacing(1.25),
                    width: theme.spacing(67.125),
                    height: '100%',
                    backgroundColor: 'rgba(31, 68, 100, 0.10)',
                    textAlign: 'center',
                }}
            >
                <Stack spacing={theme.spacing(2.125)} paddingX={theme.spacing(2)} paddingY={theme.spacing(3.25)}>
                    <Typography variant="body2" color={theme.palette.text.primary}>
                        {`${UIStrings.Hi} `}
                        <SpanTypography variant="body2" color={theme.palette.text.primary} fontWeight="bold">
                            {`${UIStrings.ApplicantsNamePlaceholder},`}
                        </SpanTypography>
                    </Typography>
                    <Typography variant="body2" color={theme.palette.text.primary}>
                        <SpanTypography variant="body2" color={theme.palette.text.primary} fontWeight="bold">
                            {`${UIStrings.RecruitersNamePlaceholder} `}
                        </SpanTypography>
                        {`${UIStrings.From} `}
                        <SpanTypography variant="body2" color={theme.palette.text.primary} fontWeight="bold">
                            {`${UIStrings.CompanysNamePlaceholder} ${UIStrings.HasInvitedYouToTakeATalentMeshAssessment} `}
                        </SpanTypography>
                        {UIStrings.AsPartOfTheirRecruitmentProcess}
                        <br />
                        <br />
                        {`${UIStrings.ThePurposeOfThisAssessmentIsToGenerate} `}
                        <SpanTypography variant="body2" color={theme.palette.text.primary} fontWeight="bold">
                            {UIStrings.ItsAnOpportunityToShowcaseYourUnique}
                        </SpanTypography>
                        {UIStrings.WhichCanBeHardToEvaluateSolelyFromReadingYourCV}
                    </Typography>
                    <img
                        alt="Invitation"
                        src="https://8513883.fs1.hubspotusercontent-na1.net/hub/8513883/hubfs/Invitation.png?width=1120&amp;upscale=true&amp;name=Invitation.png"
                        style={{
                            outline: 'none',
                            textDecoration: 'none',
                            maxWidth: '100%',
                        }}
                        width="560"
                    />
                    <Typography variant="body2" color={theme.palette.text.menuTitle} fontWeight="bold">
                        {UIStrings.StartYourAssessmentNowOrContinueWhereYouLeftOff}
                    </Typography>
                    <Button variant="contained" color="primary" sx={{ width: 'fit-content', placeSelf: 'center' }}>
                        {UIStrings.GoToAssessment}
                    </Button>
                    <Typography variant="body2" color={theme.palette.text.menuTitle}>
                        {UIStrings.OrCopyAndPasteThisUrlIntoYourBrowser}
                    </Typography>
                    <SpanTypography variant="body2" color={theme.palette.text.primary} fontWeight="bold">
                        {UIStrings.AssessmentUrlPlaceholder}
                    </SpanTypography>
                    <Typography variant="body2" color={theme.palette.text.primary}>
                        {UIStrings.AllTheBest}
                        <br />
                        {UIStrings.TheTalentMeshTeam}
                    </Typography>
                </Stack>
            </Paper>
        </Dialog>
    );
}

export default PreviewInvitationEmailDialog;
