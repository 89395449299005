import { snackbarClasses, styled, useTheme } from '@mui/material';
import React, { forwardRef, LegacyRef, useState } from 'react';
import { Toaster, ToasterStatus } from '@talentmesh/core';

interface DownloadSuccessToasterProps {
    id: string;
    message: string;
    status: ToasterStatus;
}

const InnerToaster = styled(Toaster)(() => ({
    [`&.${snackbarClasses.root}`]: {
        '@media (min-width: 600px)': {
            left: 0,
            right: 0,
            bottom: 0,
        },
    },
}));

function DownloadToaster({ id, message, status }: DownloadSuccessToasterProps): JSX.Element {
    const [open, setOpen] = useState(true);

    const closeHandler = () => setOpen(false);

    return (
        <InnerToaster
            id={id}
            alertTitle={message}
            status={status}
            open={open}
            close={closeHandler}
            onClose={closeHandler}
            sx={{
                position: 'relative',
                width: '357px',
            }}
            hideCloseButton
            autoHideDuration={6000}
        />
    );
}

export default forwardRef((props: DownloadSuccessToasterProps, ref: LegacyRef<any>) => {
    const theme = useTheme();
    return (
        <div
            ref={ref}
            style={{
                width: theme.spacing(40),
            }}
        >
            <DownloadToaster {...props} />
        </div>
    );
});
