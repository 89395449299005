import * as yup from 'yup';
import UIStrings from '../../../Utils/UIStrings';
import { extractTextWithNewLinesFromHtml } from '../../Assessments/Modify/Steps/JobAdStep/JobAdStepUtils';

function validationHelper(limitExceedMessage: string) {
    return yup.string().required(UIStrings.Required).max(128, limitExceedMessage);
}

function useCompanyProfileValidationSchema() {
    const validationSchema = yup.object({
        companyName: validationHelper(UIStrings.CompanyNameInputLengthValidation),
        companySlug: validationHelper(UIStrings.CompanySlugInputLengthValidation).matches(
            /^[a-z0-9]+(-[a-z0-9]+)*$/, // validate that slug consists of lowercase letters, numbers and dashes only
            UIStrings.CompanySlugInvalidFormat,
        ),
        companyDescription: yup
            .string()
            .test('Required', UIStrings.Required, (value) => {
                const plainText = extractTextWithNewLinesFromHtml(value || '');
                return plainText.length !== 0;
            })
            .test('TooLong', UIStrings.CompanyDescriptionInputLengthValidation, (value) => {
                const plainText = extractTextWithNewLinesFromHtml(value || '');
                return plainText.length < 4000;
            }),
    });

    return validationSchema;
}

export default useCompanyProfileValidationSchema;
