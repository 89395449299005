import React from 'react';
import StepProps from '../StepProps';
import { useCreateRecruitmentContext } from '../../Create/Contexts/CreateRecruitmentContext';
import JobDetailsStep from './JobDetailsStep';
import { useCompanyContext } from '../../../../../Context/CompanyContext';

const CreateJobDetailsStep = ({ activeStep, handleNext, handleBack }: StepProps): JSX.Element => {
    const { client, jobDetails, updateJobDetails, currencies, processingState } = useCreateRecruitmentContext();
    const { currentCompanyProfile } = useCompanyContext();
    const { companyName } = currentCompanyProfile;
    return (
        <JobDetailsStep
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            jobDetails={jobDetails}
            client={client}
            companyName={companyName}
            updateJobDetails={updateJobDetails}
            currencies={currencies}
            processingState={processingState}
        />
    );
};

export default CreateJobDetailsStep;
