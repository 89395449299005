import { QuestionType } from '../../Pages/Assessments/Modify/Models/Questions';

export interface DemoQuestionsDTO {
    personality: DemoQuestionDTO[];
    aptitude: DemoQuestionDTO[];
    skills: DemoQuestionDTO[];
    emotionalIntelligence: DemoQuestionDTO[];
    likertAnswers: LikertAnswerDTO[];
}

export const DefaultDemoQuestionDTO: DemoQuestionsDTO = {
    personality: [],
    aptitude: [],
    skills: [],
    emotionalIntelligence: [],
    likertAnswers: [],
};

interface DemoQuestionDTO {
    id: string;
    question: string;
    answers: AnswerDTO[];
    questionType: QuestionType;
    blobFileURI: string;
    fileMimeType: string;
    likertAnswerGroupId: string;
}

interface AnswerDTO {
    id: string;
    content: string;
    isCorrect: boolean;
}

interface LikertAnswerDTO {
    id: string;
    answerText: string;
    score: number;
    reverseScore: number;
}
