/* eslint-disable import/prefer-default-export */
import { CandidateAssessmentResults } from '../../Models/CandidateAssessmentResults';
import { CandidateOverview } from '../../Models/CandidateOverview';
import { CandidateStatusModel } from '../../Models/CandidateStatusModel';
import { CandidateDialogModel } from './Models/CandidateDialogModel';

export function mapFromCandidateAssessmentResultsToCandidateDialogModel(
    results: CandidateAssessmentResults,
): CandidateDialogModel {
    return {
        ...results,

        aptitudePercentileScore: results.aptitudeScore,
        emotionalIntelligencePercentileScore: results.emotionalIntelligenceScore,
        personalityPercentileScore: results.personalityScore,
        skillsPercentileScore: results.skillsScore,
        status: results.candidateStatus,
        experienceCriteriaScore: results.experienceCriteriaScore?.averageExperienceCriteriaScore ?? undefined,
    };
}

export function mapFromCandidateOverviewToCandidateDialogModel(
    assessmentId: string,
    overview: CandidateOverview,
): CandidateDialogModel {
    return {
        ...overview,
        assessmentId,
        status: overview.currentStatus.status,
    };
}

export function mapFromCandidateDialogModelToCandidateStatusModel(
    candidate: CandidateDialogModel,
    rejectedByEmail: boolean,
): CandidateStatusModel {
    return {
        candidateId: candidate.candidateId,
        candidateStatus: candidate.status,
        rejectedByEmail,
    };
}
