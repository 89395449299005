import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ToasterStatus } from '@talentmesh/core';
import UIStrings from '../Utils/UIStrings';

type NotificationContextType = {
    open: boolean;
    setOpen: (value: boolean) => void;
    title?: string;
    message: string;
    status: ToasterStatus;
    showSuccessToaster: (message: string, title?: string) => void;
    showFailToaster: (message?: string, toasterTitle?: string) => void;
};

export const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const NotificationContextProvider = NotificationContext.Provider;

interface NotificationProviderProps {
    children: ReactNode;
}

interface Notification {
    open: boolean;
    message: string;
    title: string | undefined;
    status: ToasterStatus;
}

const DefaultNotification: Notification = {
    open: false,
    message: '',
    title: undefined,
    status: 'success',
};

export const NotificationProvider = ({ children }: NotificationProviderProps): JSX.Element => {
    const [notification, setNotification] = useState(DefaultNotification);

    const showSuccessToaster = (message: string, title?: string) =>
        setNotification((prev) => ({
            ...prev,
            status: 'success',
            message,
            title,
            open: true,
        }));

    const showFailToaster = (
        message: string = `${UIStrings.SomethingWentWrong}. ${UIStrings.TryAgainLater}.`,
        title?: string,
    ) =>
        setNotification((prev) => ({
            ...prev,
            status: 'error',
            message,
            title,
            open: true,
        }));

    return (
        <NotificationContextProvider
            value={{
                open: notification.open,
                setOpen: (open: boolean) => setNotification((prev) => ({ ...prev, open })),
                message: notification.message,
                title: notification.title,
                status: notification.status,
                showSuccessToaster,
                showFailToaster,
            }}
        >
            {children}
        </NotificationContextProvider>
    );
};

export function useNotificationContext() {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('NotificationContext must be used within the NotificationContext.Provider');
    }
    return context;
}
