import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import RoutePath from '../../Routing/RoutePath';

function RegisterPage(): JSX.Element {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        const redirectToAuth0SignUp = async () => {
            const redirectLoginOptions = {
                redirectUri: window.location.origin,
                appState: {
                    returnTo: RoutePath.FinalizeRegistration,
                },
                screen_hint: 'signup',
                hint: 'signup',
            };

            await loginWithRedirect(redirectLoginOptions);
        };
        redirectToAuth0SignUp();
    }, [loginWithRedirect]);

    return <></>;
}

export default RegisterPage;
