import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, IconButton, Typography, IconButtonProps } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import { ImageCropContextProps } from './ImageCropContext';
import { ImageCropStrings } from './ImageCropUtils';

export interface ImageCropDialogHeaderProps
    extends Pick<IconButtonProps, 'onClick'>,
        Pick<ImageCropStrings, 'dialogHeaderText'>,
        Pick<ImageCropContextProps, 'processing'> {}

export const ImageCropDialogHeader = ({
    onClick,
    processing,
    dialogHeaderText,
}: ImageCropDialogHeaderProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4" sx={{ color: theme.palette.secondary.main }}>
                {dialogHeaderText}
            </Typography>
            <IconButton
                data-cy="ImageCropDialogCloseButton"
                disabled={processing}
                onClick={onClick}
                sx={{ height: theme.spacing(3.75), width: theme.spacing(3.75) }}
            >
                <CloseIcon />
            </IconButton>
        </Stack>
    );
};
