import React from 'react';
import MUIAccordion, { AccordionProps as MUIAccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material';

export interface AccordionProps extends MUIAccordionProps {}

const AccordionInner = styled(MUIAccordion)<AccordionProps>(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    borderRight: `1px solid ${theme.palette.border.main}`,
    '&.MuiAccordion-root:before': {
        display: 'none',
    },
}));

export const AdditionalInformationAccordion = (props: AccordionProps): JSX.Element => {
    return <AccordionInner {...props} disableGutters elevation={0} />;
};
