import React, { ReactNode, useCallback, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, TMTooltip, TooltipPaper, Box } from '@talentmesh/core';

interface ProfileCellProps {
    label: string;
    icon: ReactNode;
    hoverContent: ReactNode;
    testLabel: string;
    /** only show the tooltip if `label` overflows */
    hideTooltipUntilLabelOverflow?: boolean;
}

function ProfileChip({
    label,
    icon,
    hoverContent,
    testLabel,
    hideTooltipUntilLabelOverflow,
}: ProfileCellProps): JSX.Element {
    const theme = useTheme();
    const [showTooltip, setShowTooltip] = useState(false);
    const textRef = useCallback(
        (node: HTMLSpanElement) =>
            hideTooltipUntilLabelOverflow
                ? setShowTooltip(node?.scrollWidth > node?.clientWidth)
                : setShowTooltip(true),
        [],
    );
    /** this is just to select tooltip element inside the dom, because using tooltip produce a span element to wrap the label */
    const tooltipSelector = '& > span';

    return (
        <Box
            sx={{
                width: '100%',
                [tooltipSelector]: {
                    width: '100%',
                    display: 'flex',
                },
            }}
        >
            <TMTooltip
                disableInteractive
                title={
                    showTooltip && (
                        <TooltipPaper>
                            <Typography variant="body2" color="secondary">
                                {hoverContent}
                            </Typography>
                        </TooltipPaper>
                    )
                }
            >
                <Stack
                    data-cy={`ApplicantProfileChip-${testLabel.replace(' ', '')}`}
                    direction="row"
                    sx={{
                        backgroundColor: theme.palette.hue.primary.h50,
                        color: theme.palette.hue.primary.h900,
                        borderRadius: theme.shape.smallBorderRadius,
                        padding: theme.spacing(0.375, 0.5),
                        alignItems: 'center',
                        maxWidth: '100%',
                    }}
                >
                    {icon}
                    <Box display="flex" overflow="auto" mx={theme.spacing(0.75)}>
                        <Typography
                            ref={textRef}
                            variant="caption"
                            sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                        >
                            {label}
                        </Typography>
                    </Box>
                </Stack>
            </TMTooltip>
        </Box>
    );
}

export default ProfileChip;
