import { DefaultJobAdDTO, JobAdDTO } from '../DTO/Assessments/JobAdDTO';
import AIJobAdDTO, { DefaultAIJobAdDTO } from '../DTO/JobAds/AIJobAdDTO';
import GenerateJobAdDTO from '../DTO/JobAds/GenerateJobAdDTO';
import ImproveJobAdDTO from '../DTO/JobAds/ImproveJobAdDTO';
import { mapAssessmentStatus } from '../Models/AssessmentData';
import { get, post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class JobAdClient extends ClientBase {
    readonly prefix = 'job-ads';

    async getJobAdAsync(jobAdId: string): Promise<JobAdDTO> {
        const response = await get<JobAdDTO>({
            path: this.addHost(`${this.prefix}/${jobAdId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return { ...response.body, status: mapAssessmentStatus(response.body.status) };
        }
        return DefaultJobAdDTO;
    }

    async getJobAdIdByRecruitmentIdAsync(recruitmentId: string): Promise<string> {
        const response = await get<string>({
            path: this.addHost(`${this.prefix}/recruitment/${recruitmentId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return '';
    }

    async generateJobAdAsync(dto: GenerateJobAdDTO): Promise<AIJobAdDTO> {
        const response = await post<AIJobAdDTO>({
            path: this.addHost(`ai/job-ad/generate`),
            accessToken: await this.getAccessTokenSilently(),
            ...toJson(dto),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultAIJobAdDTO;
    }

    async improveJobAdAsync(dto: ImproveJobAdDTO): Promise<AIJobAdDTO> {
        const response = await post<AIJobAdDTO>({
            path: this.addHost(`ai/job-ad/improve`),
            accessToken: await this.getAccessTokenSilently(),
            ...toJson(dto),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultAIJobAdDTO;
    }
}
