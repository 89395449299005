import React from 'react';
import { GridCellParams, GridColDef } from '@talentmesh/core';
import NameAndEmail from '../Components/NameAndEmail';
import Status from '../Components/Status';
import UIStrings from '../../../../../../Utils/UIStrings';
import Role from '../Components/Role';
import { TeamMember } from '../../../../../../Models/TeamMember';
import Header from '../Components/Header';
import MemberManagementButton from '../Components/MemberManagementButton';

function createBaseColumn(field: string): GridColDef {
    return {
        field,
        headerName: '',
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        hideSortIcons: true,
    };
}

function createNameAndEmailColumn(): GridColDef {
    return {
        ...createBaseColumn('nameAndEmail'),
        headerName: 'Name',
        width: 400,
        renderHeader: () => <Header header={UIStrings.NameAndEmail} />,
        renderCell: (params: GridCellParams) => {
            const { firstName, lastName, email } = params.row as TeamMember;
            return <NameAndEmail firstName={firstName} lastName={lastName} email={email} />;
        },
    };
}

function createRoleColumn(): GridColDef {
    return {
        ...createBaseColumn('role'),
        headerName: 'Role',
        width: 200,
        renderHeader: () => <Header header={UIStrings.Role} />,
        renderCell: (params: GridCellParams) => <Role recruiterRole={(params.row as TeamMember).role} />,
    };
}

function createStatusColumn(): GridColDef {
    return {
        ...createBaseColumn('status'),
        headerName: 'Status',
        width: 200,
        renderHeader: () => <Header header={UIStrings.Status} />,
        renderCell: (params: GridCellParams) => <Status status={(params.row as TeamMember).status} />,
    };
}

function createActionColumn(): GridColDef {
    return {
        ...createBaseColumn(''),
        headerName: '',
        width: 300,

        renderCell: (params: GridCellParams) => {
            const member = params.row as TeamMember;
            if (member.role === 'Owner') {
                return null;
            }
            return <MemberManagementButton member={params.row as TeamMember} />;
        },
    };
}

function useTeamMembersTableColumns(): Array<GridColDef> {
    return [createNameAndEmailColumn(), createRoleColumn(), createStatusColumn(), createActionColumn()];
}

export default useTeamMembersTableColumns;
