import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import RoutePath from './RoutePath';
import { useUserContext } from '../Context/UserContext';

// eslint-disable-next-line func-names
const withSignupChecks = function <T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) {
    return (props: T) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user: auth0User } = useAuth0();
        const { user, provisionStatus, memberships } = useUserContext();

        if (!auth0User) {
            return <Navigate to={RoutePath.Error} />;
        }

        if (!user.firstName || !user.lastName) {
            return <Navigate to={RoutePath.SetupAccount} />;
        }

        if (!auth0User.email_verified) {
            return <Navigate to={RoutePath.VerifyEmail} />;
        }

        if (provisionStatus.recruiterId && !provisionStatus.isProvisioned) {
            return <Navigate to={RoutePath.Provision} />;
        }

        if (memberships.length === 0) {
            return <Navigate to={RoutePath.SetupAccount} />;
        }

        return <Component {...props} />;
    };
};

export default withSignupChecks;
