import React, { useEffect } from 'react';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';
import { useAssessmentsOverviewContext } from '../Contexts/AssessmentsOverviewContext';
import AssessmentsOverviewList from './AssessmentsOverviewList';

function ClosedAssessmentsOverviewList(): JSX.Element {
    const { filterByStatus } = useAssessmentsOverviewContext();
    useEffect(() => {
        filterByStatus(AssessmentStatuses.Closed);
    }, []);

    return <AssessmentsOverviewList />;
}

export default ClosedAssessmentsOverviewList;
