import React from 'react';
import { Box, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material';

interface CompensationLineProps {
    title: string;
    value: string;
}

const CompensationLine = ({ title, value }: CompensationLineProps) => {
    const theme = useTheme();
    return (
        <Box>
            <Typography variant="body1" color={theme.palette.text.secondary}>
                {title}
            </Typography>
            <Typography fontWeight="bold" color="secondary">
                {value}
            </Typography>
        </Box>
    );
};

export default CompensationLine;
