import { useTheme } from '@mui/material';
import React from 'react';
import { TMTooltip, IconButton, Stack, Typography, TooltipPaper } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import { FormikTextField } from '@talentmesh/forms';
import UIStrings from '../../../../../../Utils/UIStrings';
import MultiFontWeightLabel from '../../../Components/MultiFontWeightLabel';

function JobTitleTooltipBody(): JSX.Element {
    const theme = useTheme();
    return (
        <TooltipPaper minWidth={theme.spacing(45)}>
            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="h5" color={theme.palette.text.menuTitle}>
                    {UIStrings.JobTitle}
                </Typography>
                <Typography variant="body2" color={theme.palette.secondary.main}>
                    {UIStrings.JobTitleDescription}
                </Typography>
            </Stack>
        </TooltipPaper>
    );
}

function JobTitleLabel(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <MultiFontWeightLabel variant="h6" label={`${UIStrings.JobTitle}*`} />
            <TMTooltip title={<JobTitleTooltipBody />} placement="bottom">
                <IconButton sx={{ color: theme.palette.secondary.light, padding: theme.spacing(0) }}>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </TMTooltip>
        </Stack>
    );
}

function JobTitle(): JSX.Element {
    return <FormikTextField label={<JobTitleLabel />} name="assessmentName" data-cy="jobTitle" />;
}

export default JobTitle;
