import { useTheme } from '@mui/material';
import React from 'react';
import { Page, TabContext } from '@talentmesh/core';
import TopBar from '../../../Components/TopBars/TopBar';
import PageContentLoading from '../../PageContentLoading';
import InviteCandidatesPageHeader from './InviteCandidatesPageHeader';
import { useInvitationPageContext } from './Contexts/InvitationPageContext';
import InviteCandidateTabButtons from './Tabs/InviteCandidateTabButtons';

function AttractCandidatesPageContent(): JSX.Element {
    const theme = useTheme();
    const { specsLoading, jobAdIdLoading, activeTab } = useInvitationPageContext();

    return (
        <TabContext value={activeTab}>
            <Page
                topBar={<TopBar />}
                header={<InviteCandidatesPageHeader />}
                sx={{ backgroundColor: theme.palette.background.aliciaBlue }}
                containerProps={{ disableGutters: true }}
            >
                <PageContentLoading isLoading={specsLoading || jobAdIdLoading} loadingFailed={false}>
                    <InviteCandidateTabButtons />
                </PageContentLoading>
            </Page>
        </TabContext>
    );
}

export default AttractCandidatesPageContent;
