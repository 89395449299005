import Page, { EmptyPage } from '@talentmesh/core/dist/cjs/Models/Page';
import { AdvancedFilter, AdvancedFilterTypes, JobAd as JobAdsOverviewDTO } from '@talentmesh/builder-components';
import ClientBase from './ClientBase';
import { post, get, toJson } from '../Utils/HTTP/HttpUtils';
import ContentTypes from '../Utils/ContentTypes';
import { extractImageContentType } from '../Components/ImageCrop/ImageCropUtils';
import {
    CareerImageDTO,
    CareerPageDTO,
    CareerPageStatsDTO,
    DefaultCareerImageDTO,
    DefaultCareerPageDTO,
    DefaultCareerPageStatsDTO,
} from '../DTO/Assessments/CareerDTO';

export default class CareerClient extends ClientBase {
    readonly basePath = 'career-page';

    getCareerPageDraftAsync = async (companyId: string): Promise<CareerPageDTO> => {
        const path = `${this.basePath}/company/${companyId}/draft`;

        const response = await get<CareerPageDTO>({
            path: this.addHost(path),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultCareerPageDTO;
    };

    saveCareerPageDraftAsync = async (careerPage: CareerPageDTO): Promise<CareerPageDTO> => {
        const path = `${this.basePath}/draft`;

        const response = await post<CareerPageDTO>({
            path: this.addHost(path),
            body: JSON.stringify(careerPage),
            contentType: ContentTypes.ApplicationJson,
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultCareerPageDTO;
    };

    publishCareerPageAsync = async (careerPage: CareerPageDTO): Promise<CareerPageDTO> => {
        const path = `${this.basePath}/published`;

        const response = await post<CareerPageDTO>({
            path: this.addHost(path),
            body: JSON.stringify(careerPage),
            contentType: ContentTypes.ApplicationJson,
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultCareerPageDTO;
    };

    uploadCareerImageAsync = async (companyId: string, image: Blob): Promise<CareerImageDTO> => {
        const path = `${this.basePath}/image`;
        const type = extractImageContentType(image.type);
        const form = new FormData();

        form.append('image', image, `image.${type}`);
        form.append('companyId', companyId);

        const result = await post<CareerImageDTO>({
            path: this.addHost(path),
            body: form,
            accessToken: await this.getAccessTokenSilently(),
        });

        if (result.ok && result.body) {
            return result.body;
        }

        return DefaultCareerImageDTO;
    };

    getCareerPageStatsAsync = async (companyId: string): Promise<CareerPageStatsDTO> => {
        const path = `${this.basePath}/company/${companyId}/stats`;

        const response = await get<CareerPageStatsDTO>({
            path: this.addHost(path),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultCareerPageStatsDTO;
    };

    async getJobAdsOverviewAsync(
        slug: string,
        search: string,
        filters: AdvancedFilter[],
        onlyFullyRemote: boolean,
        offset: number,
        limit: number,
    ): Promise<Page<JobAdsOverviewDTO>> {
        const path = `${this.basePath}/overview/company-slug/${slug}?${this.buildJobAdsQueryParam(
            search,
            onlyFullyRemote,
            offset,
            limit,
        )}`;
        const strategy = toJson(filters);

        const result = await post<Page<JobAdsOverviewDTO>>({
            path: this.addHost(path),
            ...strategy,
        });

        if (result.ok && result.body) {
            return result.body;
        }

        return EmptyPage;
    }

    buildJobAdsQueryParam = (search: string, onlyFullyRemote: boolean, offset: number, limit: number) => {
        const query = new URLSearchParams({
            offset: offset.toString(),
            limit: limit.toString(),
            onlyFullyRemote: onlyFullyRemote.toString(),
        });

        if (search?.length > 1) {
            query.append('search', search);
        }

        return query.toString();
    };

    async getAdvancedFilters(slug: string, filterType?: AdvancedFilterTypes): Promise<AdvancedFilter[]> {
        const path = `${this.basePath}/advanced-filters/company-slug/${slug}${this.buildAdvancedFiltersQuery(
            filterType,
        )}`;

        const result = await get<AdvancedFilter[]>({
            path: this.addHost(path),
        });

        if (result.ok && result.body) {
            return result.body || [];
        } else {
            return [];
        }
    }

    buildAdvancedFiltersQuery(filterType?: AdvancedFilterTypes): string {
        if (!filterType) {
            return '';
        }

        return `?filterType=${filterType}`;
    }
}
