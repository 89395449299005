import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Stack, Typography, SpanTypography, TooltipPaper } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

function InviteCandidateTooltipBody(): JSX.Element {
    const theme = useTheme();
    return (
        <TooltipPaper minWidth={theme.spacing(45)}>
            <Stack direction="column" spacing={2} paddingBottom={1.5} justifyContent="start" alignContent="start">
                <Typography variant="h5" color={theme.palette.text.primary}>
                    {UIStrings.InviteCoWorkersAsInternalBenchmark}
                </Typography>
                <Box>
                    <SpanTypography
                        variant="body2"
                        color={theme.palette.text.menuTitle}
                        sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
                    >
                        {`${UIStrings.DidYouKnow} `}
                    </SpanTypography>
                    <SpanTypography variant="body2" color={theme.palette.text.menuTitle}>
                        {`${UIStrings.InviteCoWorkersAsInternalBenchmarkExplanation1} `}
                    </SpanTypography>
                    <SpanTypography variant="body2" color={theme.palette.text.menuTitle}>
                        {`${UIStrings.InviteCoWorkersAsInternalBenchmarkExplanation2} `}
                    </SpanTypography>
                </Box>
            </Stack>
        </TooltipPaper>
    );
}

export default InviteCandidateTooltipBody;
