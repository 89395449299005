export interface CompanyProfileDTO {
    companyDescription?: string;
    companyName: string;
    companySlug: string;
    companyLogoURL?: string;
    companyIconURL?: string;
    id: string;
}

export const DefaultCompanyProfileDTO: CompanyProfileDTO = {
    companyName: '',
    companySlug: '',
    id: '',
};
