import React from 'react';
import { FormikHelpers } from 'formik';
import BaseTestCard from '../BaseTestCard/BaseTestCard';
import TestSelectionFormFieldNames from '../../../../../../Models/TestSelectionFormFieldNames';
import TestInfo from '../../../../../../../../Models/TestInfo';
import UIStrings from '../../../../../../../../../../../Utils/UIStrings';
import { resourceBaseUrl } from '../../../../../../../../../../../AppSettings';
import { PreviewQuestionsProps } from '../PreviewQuestions/Dialog/usePreviewQuestions';

interface EmotionalIntelligenceTestCardProps extends Omit<PreviewQuestionsProps, 'testType'> {
    test: TestInfo;
    helpers: FormikHelpers<any>;
}

function EmotionalIntelligenceTestCard({
    test,
    helpers,
    demoQuestions,
    likertOptions,
}: EmotionalIntelligenceTestCardProps): JSX.Element {
    return (
        <BaseTestCard
            {...test}
            helpers={helpers}
            testName={UIStrings.EmotionalIntelligence}
            fieldName={TestSelectionFormFieldNames.isEmotionalIntelligenceSelected}
            imageURL={`${resourceBaseUrl}/images/EmotionalIntelligence.png`}
            demoQuestions={demoQuestions}
            likertOptions={likertOptions}
        />
    );
}

export default EmotionalIntelligenceTestCard;
