import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { JobBoard } from './AvailableJobBoards';
import UIStrings from '../../../../Utils/UIStrings';
import { resourceBaseUrl } from '../../../../AppSettings';

export interface JobBoardOptionContentProps extends JobBoard {}

const JobBoardOptionContent = ({ name, region, jobType }: JobBoardOptionContentProps): JSX.Element => {
    const theme = useTheme();
    const isOtherSelected = name === 'other';
    const src = `${resourceBaseUrl}/images/jobboards/${name}.png`;

    return (
        <Stack spacing={1.5}>
            <img width={171} height={51} src={src} alt={name} style={{ margin: 'auto' }} />
            <Stack>
                <Typography variant="body1" color="secondary">
                    <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                        {isOtherSelected ? UIStrings.AllOtherJobBoards : `${UIStrings.RegionFocus}: `}
                    </span>
                    {isOtherSelected ? '' : region}
                </Typography>
                <Typography variant="body1" color="secondary">
                    <span style={{ fontWeight: theme.typography.fontWeightBold }}>{`${UIStrings.JobType}: `}</span>
                    {jobType}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default JobBoardOptionContent;
