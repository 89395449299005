import { useField } from 'formik';
import { Stack, FormHelperText } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { RichTextEditor, $generateHtmlFromNodes } from '@talentmesh/rte';
import React, { useMemo, useState } from 'react';

function CompanyDescription(): JSX.Element {
    const theme = useTheme();
    const [field, meta, helpers] = useField('companyDescription');
    const initialHtml = useMemo(() => field.value, []);
    const [isInitialRender, setIsInitialRender] = useState(true);

    return (
        <Stack
            sx={{
                borderRadius: theme.spacing(1.25),
                border: `1px solid ${theme.palette.border.main}`,
                borderWidth: '1px',
                backgroundColor: theme.palette.grey[100],
            }}
            display="flex"
            flexDirection="column"
            paddingLeft={theme.spacing(2.5)}
            paddingTop={theme.spacing(2.5)}
            paddingRight={theme.spacing(2.5)}
            paddingBottom={theme.spacing(2.5)}
            height={theme.spacing(43)}
            width="100%"
            spacing={theme.spacing(1)}
        >
            <RichTextEditor
                data-cy="CompanyDescription"
                initialHtml={initialHtml}
                features={['headingStyle', 'bold', 'italic', 'ol', 'ul']}
                onChange={(editorState, editor) => {
                    editorState.read(() => {
                        helpers.setValue($generateHtmlFromNodes(editor), !isInitialRender);
                        if (isInitialRender) {
                            setIsInitialRender(false);
                        }
                    });
                }}
                contentEditableProps={{
                    sx: {
                        overflowY: 'auto',
                        flex: 1,
                        borderColor: meta.error ? theme.palette.error.main : 'inherit',
                    },
                }}
                toolbarProps={{ sx: { marginBottom: theme.spacing(1.25) } }}
            />
            {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </Stack>
    );
}

export default CompanyDescription;
