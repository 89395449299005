import React from 'react';
import { Box, CircularProgress } from '@talentmesh/core';

const PageLoading = (): JSX.Element => {
    return (
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    );
};

export default PageLoading;
