import React from 'react';
import CustomBox from '../../../Components/Common/CustomBox';
import H4Typography from '../../../Components/Common/H4Typography';

interface DetailsHeaderProps {
    label: string;
}

function DetailsHeader({ label }: DetailsHeaderProps): JSX.Element {
    return (
        <CustomBox>
            <H4Typography>{label}</H4Typography>
        </CustomBox>
    );
}

export default DetailsHeader;
