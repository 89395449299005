import { useTheme } from '@mui/material/styles';
import { Container, Stack, Typography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import ClientAddButton from './ClientAddButton';
import ClientSearch from './ClientSearch';

function ClientsOverviewHeader() {
    const theme = useTheme();

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.default,
                borderBottom: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Container disableGutters>
                <Typography
                    mt={theme.spacing(3.75)}
                    mb={theme.spacing(1.5)}
                    variant="h4"
                    color={theme.palette.text.menuTitle}
                >
                    {UIStrings.ClientsOverview}
                </Typography>
                <Stack direction="row" justifyContent="end" spacing={theme.spacing(1.25)} mb={theme.spacing(1)}>
                    <ClientSearch />
                    <ClientAddButton />
                </Stack>
            </Container>
        </Stack>
    );
}

export default ClientsOverviewHeader;
