import { resourceBaseUrl } from '../../../../AppSettings';
import UIStrings from '../../../../Utils/UIStrings';

export const JobBoardNames = ['linkedin', 'indeed', 'ziprecruiter', 'monster', 'other'] as const;
export const LinkedInParams = ['normal', 'recruiter'] as const;

export type JobBoardName = (typeof JobBoardNames)[number];
export type LinkedInParam = (typeof LinkedInParams)[number];

export interface JobBoard {
    name: JobBoardName;
    region: string;
    jobType: string;
}

export const JobBoards: Array<JobBoard> = [
    {
        name: 'linkedin',
        region: UIStrings.Global,
        jobType: UIStrings.AllJobTypes,
    },
    {
        name: 'indeed',
        region: UIStrings.Global,
        jobType: UIStrings.AllJobTypes,
    },
    {
        name: 'ziprecruiter',
        region: UIStrings.USCanadaAndUK,
        jobType: UIStrings.AllJobTypes,
    },
    {
        name: 'monster',
        region: UIStrings.Global,
        jobType: UIStrings.AllJobTypes,
    },
    {
        name: 'other',
        region: UIStrings.Global,
        jobType: UIStrings.AllJobTypes,
    },
];

export const getJobBoardLabel = (jobBoardName: JobBoardName) => {
    switch (jobBoardName) {
        case 'linkedin':
            return UIStrings.LinkedIn;
        case 'indeed':
            return UIStrings.Indeed;
        case 'ziprecruiter':
            return UIStrings.ZipRecruiter;
        case 'monster':
            return UIStrings.Monster;
        default:
            return '';
    }
};

export const getVideoLink = (name: JobBoardName, linkedInParam: LinkedInParam): string => {
    switch (name) {
        case 'indeed':
            return 'https://www.youtube.com/embed/uO0EvqSV9VI?autoplay=1&rel=0&modestbranding=1&showinfo=1';
        case 'monster':
            return 'https://www.youtube.com/embed/H2D99S3D2Ys?autoplay=1&rel=0&modestbranding=1&showinfo=1';
        case 'ziprecruiter':
            return 'https://www.youtube.com/embed/gn_64nRAGEQ?autoplay=1&rel=0&modestbranding=1&showinfo=1';
        case 'linkedin':
            if (linkedInParam === 'recruiter') {
                return 'https://www.youtube.com/embed/x5SF6H3YYFA?autoplay=1&rel=0&modestbranding=1&showinfo=1';
            } else {
                return 'https://www.youtube.com/embed/1va-znTh3F0?autoplay=1&rel=0&modestbranding=1&showinfo=1';
            }
        case 'other':
            return '';
        default:
            throw new Error(`No video link for job board ${name}`);
    }
};

export const getVideoThumbnail = (name: JobBoardName, linkedInParam: LinkedInParam): string => {
    const postfix = name === 'linkedin' ? `_${linkedInParam}` : '';
    return `${resourceBaseUrl}/images/jobboards/thumbnail_${name}${postfix}.png`;
};
