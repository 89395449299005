import { useTheme } from '@mui/material';
import React from 'react';
import { Divider } from '@talentmesh/core';

function ScoreConnector(): JSX.Element {
    const theme = useTheme();

    return (
        <Divider
            sx={{
                borderColor: theme.palette.border.main,
                margin: 0,
                padding: 0,
                flex: 0.5,
            }}
        />
    );
}

export default ScoreConnector;
