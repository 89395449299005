import FormFieldDTO from './FormFieldDTO';
import HubSpotPropertryNames from './HubSpotPropertryNames';

export default interface SubmitFormDTO {
    fields: Array<FormFieldDTO>;
}

export function createAccountFormData(
    email: string,
    utmSource: string,
    utmMedium: string,
    utmCampaign: string,
    utmTerm: string,
    utmContent: string,
) {
    const formData: SubmitFormDTO = {
        fields: [
            {
                name: HubSpotPropertryNames.Email,
                value: email,
            },
            {
                name: HubSpotPropertryNames.UtmSource,
                value: utmSource ?? '',
            },
            {
                name: HubSpotPropertryNames.UtmMedium,
                value: utmMedium ?? '',
            },
            {
                name: HubSpotPropertryNames.UtmCampaign,
                value: utmCampaign ?? '',
            },
            {
                name: HubSpotPropertryNames.UtmTerm,
                value: utmTerm ?? '',
            },
            {
                name: HubSpotPropertryNames.UtmContent,
                value: utmContent ?? '',
            },
        ],
    };
    return formData;
}
