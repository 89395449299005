import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { Form } from 'formik';
import { Stack, LoadingButton, LoadingButtonProps, Typography, Divider } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import useCompanyProfileFormInput from '../Hooks/useCompanyProfileFormInput';
import InputSection from './InputSection';

interface CompanyProfileFormProps extends Pick<LoadingButtonProps, 'loading'> {
    children?: ReactNode;
}

const CompanyProfileForm = ({ loading, children }: CompanyProfileFormProps) => {
    const theme = useTheme();
    const components = useCompanyProfileFormInput();

    return (
        <Form encType="multipart/form-data">
            <Stack spacing={2.5} sx={{ alignItems: 'start' }}>
                <Typography variant="h3" color="secondary">
                    {UIStrings.CompanyProfile}
                </Typography>
                <Stack spacing={2} width="100%">
                    {components.map((item) => (
                        <InputSection {...item} key={item.title} />
                    ))}
                </Stack>
                <LoadingButton
                    data-cy="CompanyProfileSubmitButton"
                    size="large"
                    loading={loading}
                    variant="contained"
                    type="submit"
                >
                    {UIStrings.Save}
                </LoadingButton>
                <Stack spacing={2.5} sx={{ width: '100%' }}>
                    <Divider sx={{ borderColor: theme.palette.border.main }} />
                    <Stack spacing={1.25}>
                        <Typography variant="h4" color="secondary">
                            {UIStrings.BrandElements}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                            {UIStrings.UploadYourLogoAndIconHereToShowcaseYourCompany}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                            {UIStrings.CompanyBrandElementsValidationDescription}
                        </Typography>
                    </Stack>
                    {children}
                </Stack>
            </Stack>
        </Form>
    );
};

export default CompanyProfileForm;
