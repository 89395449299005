import React from 'react';
import { useTheme } from '@mui/material';
import { Stack, LoadingButton, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import useResendEmailPanelState from '../Utils/useResendEmailPanelState';

function ResendEmailPanel(): JSX.Element {
    const theme = useTheme();
    const { resendHandler, resendButtonLoading } = useResendEmailPanelState();

    return (
        <Stack spacing={1.25} sx={{ alignItems: 'flex-start', paddingY: theme.spacing(1) }}>
            <Typography variant="body2" sx={{ color: theme.palette.text.menuTitle }}>
                {UIStrings.DontSeeEmailInSpamJunkFolder}
            </Typography>
            <LoadingButton
                variant="outlined"
                onClick={resendHandler}
                sx={{
                    height: theme.spacing(4),
                    backgroundColor: theme.palette.common.white,
                }}
                loading={resendButtonLoading}
            >
                {UIStrings.ResendEmail}
            </LoadingButton>
        </Stack>
    );
}

export default ResendEmailPanel;
