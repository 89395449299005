import React from 'react';
import { Grid } from '@talentmesh/core';
import UIStrings from '../../../../../../../Utils/UIStrings';
import CustomBox from '../../Components/Common/CustomBox';
import { HorizontalDivider } from '../../Components/Common/CustomDivider';
import DetailsHeader from './Common/DetailsHeader';
import GridRowItem from './Common/GridRowItem';

interface ProfileInformationSectionProps {
    name: string;
    location?: string;
    isWillingToRelocate: boolean | null;
    email: string;
}

function ProfileInformationSection({
    name,
    location,
    isWillingToRelocate,
    email,
}: ProfileInformationSectionProps): JSX.Element {
    return (
        <>
            <DetailsHeader label={UIStrings.ProfileInformation} />
            <HorizontalDivider />
            <CustomBox>
                <Grid container direction="column" spacing={2}>
                    <GridRowItem title={UIStrings.Name} value={name} />
                    {location && <GridRowItem title={UIStrings.CurrentLocation} value={location} />}
                    {isWillingToRelocate != null && (
                        <GridRowItem
                            title={UIStrings.WillingToRelocate}
                            value={isWillingToRelocate ? UIStrings.Yes : UIStrings.No}
                        />
                    )}
                    <GridRowItem title={UIStrings.Email} value={email} />
                </Grid>
            </CustomBox>
        </>
    );
}

export default ProfileInformationSection;
