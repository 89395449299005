import { GridCellParams, GridColDef, GridRowData, GridSortModel } from '@talentmesh/core';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';
import RoutePath from '../../../../../Routing/RoutePath';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import { getCellData, getSortByEnum } from '../Utils/CandidateOverviewListUtils';
import useCandidateOverviewListColumns from './useCandidateOverviewListColumns';
import UIStrings from '../../../../../Utils/UIStrings';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';

interface UseCandidateOverviewListType {
    columns: Array<GridColDef>;
    onCellClickHandler: (params: GridCellParams) => void;
    getRowId: (row: GridRowData) => string;
    getCandidatesTabCountLabel: (value: UIStrings) => string;
    updateSorting: (model: GridSortModel) => void;
}

function useCandidateOverviewList(): UseCandidateOverviewListType {
    const fieldAction = 'action';
    const fieldDecision = 'decision';
    const fields = [fieldAction, fieldDecision];
    const history = useTalentMeshHistory();
    const { assessmentId, totalOpen, totalHired, totalReject, sort } = useCandidatesOverviewContext();
    const columns = useCandidateOverviewListColumns();

    const getRowId = (row: GridRowData) => {
        const item = row as CandidateOverview;
        return item.candidateId;
    };

    const onCellClickHandler = (params: GridCellParams) => {
        if (fields.includes(params.field)) {
            return;
        }

        const item = getCellData(params);

        const path = RoutePath.CandidateResult.replace(':assessmentId', assessmentId).replace(
            ':candidateId',
            item.candidateId,
        );
        history.push(path);
    };

    const getCandidatesTabCountLabel = (value: UIStrings): string => {
        let statusCount;

        switch (value) {
            case UIStrings.Open:
                statusCount = totalOpen;
                break;
            case UIStrings.Hired:
                statusCount = totalHired;
                break;
            case UIStrings.Rejected:
                statusCount = totalReject;
                break;

            default:
                statusCount = 0;
                break;
        }

        return statusCount > 0 ? `${value} (${statusCount})` : `${value}`;
    };

    const updateSorting = (model: GridSortModel) => {
        const sortBy = getSortByEnum(model);
        sort(sortBy);
    };

    return { columns, getRowId, onCellClickHandler, getCandidatesTabCountLabel, updateSorting };
}

export default useCandidateOverviewList;
