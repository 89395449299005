import React from 'react';
import { Box, ToggleWithText } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';

export enum PaymentMethodOption {
    SavedCard = 'savedCard',
    NewCard = 'newCard',
}

interface CadenceSwitcherProps {
    paymentMethodOption: PaymentMethodOption;
    handleChange: (event: React.MouseEvent<HTMLElement>, value: PaymentMethodOption) => void;
}

const PaymentMethodSwitcher = ({ paymentMethodOption, handleChange }: CadenceSwitcherProps) => {
    return (
        <Box>
            <ToggleWithText
                data-cy="paymentMethodSwitcher"
                leftOption={UIStrings.SavedCard}
                leftValue={PaymentMethodOption.SavedCard}
                rightOption={UIStrings.NewCard}
                rightValue={PaymentMethodOption.NewCard}
                onChange={handleChange}
                value={paymentMethodOption}
            />
        </Box>
    );
};

export default PaymentMethodSwitcher;
