import React, { useEffect } from 'react';
import { LoadingOverlay } from '@talentmesh/core';
import { generatePath, useParams } from 'react-router';
import { useAssessmentClient } from '../../Hooks/ClientHooks';
import useTalentMeshHistory from '../../Routing/useTalentMeshHistory';
import RoutePath from '../../Routing/RoutePath';

/*
This page is used for backward compatibility.
When an applicant completes an assessment we send a deep link to the results page to a recruiter.
This page redirects old links to the new page.
*/
function ObsoleteCandidateResultsPage(): JSX.Element {
    const { assessmentId, candidateId } = useParams<{ assessmentId: string; candidateId: string }>();
    const client = useAssessmentClient();
    const history = useTalentMeshHistory();

    useEffect(() => {
        if (!assessmentId || !candidateId) {
            return;
        }

        const doGetAssessment = async (): Promise<void> => {
            const results = await client.getCandidateResults(assessmentId, candidateId);
            const path = generatePath(RoutePath.CandidateResult, {
                companyId: results.companyId,
                assessmentId,
                candidateId,
            });
            history.push(path);
        };

        doGetAssessment();
    }, [assessmentId, client]);

    return <LoadingOverlay />;
}

export default ObsoleteCandidateResultsPage;
