/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
import DOMPurify from 'dompurify';
import React from 'react';

interface HtmlViewerProps {
    html: string;
}

const HtmlViewer = ({ html }: HtmlViewerProps) => {
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />;
};

export default HtmlViewer;
