import React from 'react';
import { generatePath } from 'react-router';
import { TabContext, TabPanel } from '@talentmesh/core';
import RoutePath from '../../../../Routing/RoutePath';
import { useAssessmentsOverviewContext } from '../Contexts/AssessmentsOverviewContext';
import ActiveAssessmentsOverviewList from './ActiveAssessmentsOverviewList';
import ClosedAssessmentsOverviewList from './ClosedAssessmentsOverviewList';
import useCompanyId from '../../../../Hooks/UseCompanyId';
import useClientIdPathParam from '../../../../Hooks/UseClientIdPathParam';

function AssessmentsOverviewTabs(): JSX.Element {
    const { assessments, currentTab } = useAssessmentsOverviewContext();

    const tabPanelSx = { p: 0, height: assessments.length > 0 ? 'auto' : '100%' };
    const companyId = useCompanyId();
    const clientId = useClientIdPathParam();

    const open = generatePath(RoutePath.AssessmentsActive, { companyId, clientId });
    const closed = generatePath(RoutePath.AssessmentsClosed, { companyId, clientId });

    return (
        <TabContext value={currentTab}>
            <TabPanel sx={tabPanelSx} value={open}>
                <ActiveAssessmentsOverviewList />
            </TabPanel>
            <TabPanel sx={tabPanelSx} value={closed}>
                <ClosedAssessmentsOverviewList />
            </TabPanel>
        </TabContext>
    );
}

export default AssessmentsOverviewTabs;
