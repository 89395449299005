import { CandidateStatuses } from '../Models/CandidateOverview';

function isResetAssessmentAllowedState(candidateStatus: CandidateStatuses) {
    switch (candidateStatus) {
        case CandidateStatuses.AssessmentOngoing:
        case CandidateStatuses.AssessmentCompleted:
        case CandidateStatuses.Interviewing:
        case CandidateStatuses.Rejected:
            return true;
        default:
            return false;
    }
}

export default isResetAssessmentAllowedState;
