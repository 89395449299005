import React from 'react';
import { CareerPageTabContextProvider } from '../Contexts/CareerPageTabContext';
import useCareerPageTabContextValue from '../Contexts/useCareerPageTabContextValue';
import CareerPageTabLoadingContent from './CarrerPageTabLoadingContent';

function CareerPage(): JSX.Element {
    const value = useCareerPageTabContextValue();
    return (
        <CareerPageTabContextProvider value={value}>
            <CareerPageTabLoadingContent />
        </CareerPageTabContextProvider>
    );
}

export default CareerPage;
