import { SnapshotsSliceDTO } from '../DTO/Snapshots/SnapshotsSliceDTO';
import { SnapshotsSlice, fromSnapshotsSliceDTO } from '../Models/SnapshotsSlice';
import { TestTypes } from '../Models/Configuration';
import Page, { EmptyPage } from '../Models/Page';
import { get } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class SnapshotsClient extends ClientBase {
    apiPrefix = 'snapshots';

    getSnapshotsAsync = async (
        assessmentId: string,
        candidateId: string,
        testType: TestTypes,
    ): Promise<Page<SnapshotsSlice>> => {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`${this.apiPrefix}/${candidateId}?assessmentId=${assessmentId}&testType=${testType}`);
        const response = await get<Page<SnapshotsSliceDTO>>({
            path,
            accessToken,
        });
        if (response.ok && response.body) {
            return {
                pagination: response.body.pagination,
                data: response.body.data ? response.body.data.map(fromSnapshotsSliceDTO) : [],
            };
        }
        return EmptyPage;
    };
}
