import { AutocompleteRenderInputParams } from '@mui/material';
import React from 'react';
import { TextField, WorkArrangements } from '@talentmesh/core';
import { useField } from 'formik';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { JobDetailsFieldTypes } from '../../JobDetailsStepUtils';

interface JobLocationTextFieldProps {
    params: AutocompleteRenderInputParams;
    label: string | JSX.Element;
}

function JobLocationTextField({ params, label }: JobLocationTextFieldProps): JSX.Element {
    const [, meta] = useField(JobDetailsFieldTypes.JobLocation);
    const [{ value: workArrangement }] = useField<WorkArrangements>(JobDetailsFieldTypes.WorkArrangement);

    return (
        <TextField
            {...params}
            placeholder={workArrangement === 'Remote' ? UIStrings.TypeCountryOrCity : UIStrings.TypeCity}
            label={label}
            error={meta.touched && meta.error != null}
            helperText={meta.touched ? meta.error : ''}
            fullWidth
            data-cy="jobLocationTextField"
        />
    );
}

export default JobLocationTextField;
