import { CareerImageDTO, CareerPageDTO, CareerPageStatsDTO } from '../DTO/Assessments/CareerDTO';
import toDateString from '../Utils/DateHelper';

export type CareerImage = string;

export type CareerPage = string;

export interface CareerPageStats {
    publishedDate: string;
    activeJobs: number;
}

export const DefaultCareerPageStats: CareerPageStats = {
    publishedDate: '-',
    activeJobs: 0,
};

export const mapCareerImageDTOToCareerImage = (dto: CareerImageDTO): CareerImage => dto.url;

export const mapCareerPageDTOToCareerPage = (dto: CareerPageDTO): CareerPage => {
    const { pageContent } = dto;

    if (pageContent) {
        return pageContent;
    }

    return '';
};

export const mapCareerPageToCareerPageDTO = (value: CareerPage, companyId: string): CareerPageDTO => {
    return { pageContent: value, companyId };
};

export const mapCareerPageStatsDTOtoCareerPageStats = (value: CareerPageStatsDTO): CareerPageStats => {
    return {
        ...value,
        publishedDate: value.publishedDate === null ? '-' : toDateString(new Date(value.publishedDate)),
    };
};
