import { useTheme } from '@mui/material';
import { Link } from '@talentmesh/core';
import React from 'react';
import { useNavigate } from 'react-router';
import { siteUrls } from '../../../AppSettings';
import UIStrings from '../../../Utils/UIStrings';
import FailedPayment from './FailedPayment.svg';
import PaymentResultContent from './PaymentResultContent';

const SuccessfulPayment = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleClick = () => {
        // refresh the page on the same URL
        navigate(0);
    };

    return (
        <PaymentResultContent
            title={UIStrings.PaymentFailed}
            descriptionLine1={UIStrings.PaymentFailedMessage1}
            descriptionLine2={UIStrings.PaymentFailedMessage2}
            onClick={handleClick}
            imageData={FailedPayment}
            buttonText={UIStrings.TryAgain}
        >
            <Link
                href={siteUrls.contactSupportUrl}
                target="_blank"
                sx={{
                    marginTop: theme.spacing(2),
                    textDecoration: 'none',
                    '&:hover': {
                        color: theme.palette.text.primary,
                    },
                    '&:visited': {
                        color: theme.palette.text.primary,
                    },
                }}
                color={theme.palette.secondary.main}
                fontWeight={theme.typography.fontWeightBold}
            >
                {UIStrings.ContactSupport}
            </Link>
        </PaymentResultContent>
    );
};

export default SuccessfulPayment;
