import { chipClasses, useTheme, IconProps } from '@mui/material';
import { SquareChip, SquareChipProps, Typography } from '@talentmesh/core';
import React from 'react';

interface TimelineChipProps extends SquareChipProps {
    label: string;
    icon: React.ReactElement;
}

const TimelineChip = ({ label, icon, ...props }: TimelineChipProps) => {
    const theme = useTheme();
    const iconWithInheritedColor = React.cloneElement<IconProps>(icon, { color: 'inherit' });

    return (
        <SquareChip
            sx={{
                backgroundColor: theme.palette.hue.primary.h50,
                color: theme.palette.hue.primary.h900,
                width: 'fit-content',
                [`.${chipClasses.label}`]: {
                    marginLeft: theme.spacing(0.25),
                },
            }}
            icon={iconWithInheritedColor}
            label={<Typography variant="chips">{label}</Typography>}
            {...props}
        />
    );
};

export default TimelineChip;
