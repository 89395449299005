import React from 'react';
import BaseFilter from './BaseFilter';
import StatusFilterPanel from './StatusFilterPanel';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import UIStrings from '../../../../../Utils/UIStrings';

function StatusFilterButton(): JSX.Element {
    const { filterBy } = useCandidatesOverviewContext();

    return (
        <BaseFilter label={UIStrings.Status} activeFilterCount={filterBy.length}>
            <StatusFilterPanel />
        </BaseFilter>
    );
}

export default StatusFilterButton;
