import { AutocompleteOption } from '@talentmesh/core';
import { DefaultJobAdDetailsDTO, JobAdDetailsDTO } from './JobDetailsDTO';
import { JobAdSkillsDTO } from './JobAdSkillsDTO';
import { JobAd } from '../../Pages/Assessments/Modify/Models/JobAd';
import AutocompleteOptionType from '../../Models/AutocompleteOptionType';
import { AssessmentStatuses } from '../../Models/AssessmentData';

export interface JobAdDTO {
    recruitmentId: string;
    clientId: string;
    status: AssessmentStatuses;
    jobDetails: JobAdDetailsDTO;
    jobAdTitle: string;
    skills: JobAdSkillsDTO[];
    jobAdDescription: string;
    companyDescription: string;
}

export const DefaultJobAdDTO: JobAdDTO = {
    recruitmentId: '',
    clientId: '',
    status: AssessmentStatuses.NonPublic,
    jobDetails: DefaultJobAdDetailsDTO,
    jobAdTitle: '',
    skills: [],
    jobAdDescription: '',
    companyDescription: '',
};

export function mapJobAdDTO2Model(dto: JobAdDTO): JobAd {
    const hardSkills = dto.skills
        .filter((x) => x.skillType === AutocompleteOptionType.HardSkill)
        .map((x) => ({ id: x.id, label: x.skillName }) as AutocompleteOption);
    const softSkills = dto.skills
        .filter((x) => x.skillType === AutocompleteOptionType.SoftSkill)
        .map((x) => ({ id: x.id, label: x.skillName }) as AutocompleteOption);

    return {
        title: dto.jobAdTitle,
        description: dto.jobAdDescription,
        hardSkills,
        hardSkillsSwitch: hardSkills.length > 0,
        softSkills,
        softSkillsSwitch: softSkills.length > 0,
        status: dto.status,
        companyDescription: dto.companyDescription,
    } as JobAd;
}
