interface ProvisionStateDTO {
    recruiterId: string;
    isProvisioned: boolean;
}

export const DefaultProvisionStatusDTO: ProvisionStateDTO = {
    recruiterId: '',
    isProvisioned: false,
};

export default ProvisionStateDTO;
