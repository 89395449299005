import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import UIStrings from '../../../../../../Utils/UIStrings';
import JobAdLabel from './JobAdLabel';
import ImproveWithAIButton from '../../JobDetailsStep/Components/ImproveWithAIButton';

interface JobAdExplanationProps {
    includeImproveWithAI?: boolean;
}

function JobAdExplanation({ includeImproveWithAI = false }: JobAdExplanationProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={theme.spacing(1)}>
            <Stack direction="column">
                <JobAdLabel label={UIStrings.ProvideInformationForTheJobAd} />
                <Typography color={theme.palette.text.menuTitle}>{UIStrings.DescribeTheJob}</Typography>
            </Stack>
            {includeImproveWithAI && <ImproveWithAIButton />}
        </Stack>
    );
}

export default JobAdExplanation;
