import React from 'react';
import { Button, Dialog, DialogProps, Paper, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import UIStrings from '../../../Utils/UIStrings';

interface ExitDialogProps extends DialogProps {
    onCancel: () => void;
    onOk: () => void;
}
function ExitDialog({ onCancel, onOk, ...props }: ExitDialogProps): JSX.Element {
    const theme = useTheme();

    return (
        <Dialog {...props}>
            <Paper elevation={0} sx={{ width: theme.spacing(48), p: 2.5 }}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h4">{UIStrings.ExitWithUnsavedChanges}</Typography>
                    <Stack direction="column">
                        <Typography variant="body2">{UIStrings.ExitingNowWillDiscardAnyUnsavedChanges}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                        <Button variant="outlined" color="secondary" onClick={() => onCancel()}>
                            {UIStrings.ExitAndDiscard}
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => onOk()}>
                            {UIStrings.ContinueEditing}
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Dialog>
    );
}

export default ExitDialog;
