import AdvancedFilterTypes from '../../Models/AdvancedFilterTypes';

export interface AdvancedFilterDTO {
    id: string;
    name: string;
    count: number;
    filterType: AdvancedFilterTypes;
}

export const DefaultAdvancedFilterDTO: AdvancedFilterDTO = {
    count: 0,
    filterType: AdvancedFilterTypes.All,
    id: '',
    name: '',
};
