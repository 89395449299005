import React from 'react';
import { PaymentIntent as StripePaymentIntent } from '@stripe/stripe-js';
import SuccessfulPayment from './SuccessfulPayment';
import FailedPayment from './FailedPayment';
import IntentStatus from '../../../Models/IntentStatus';

interface PaymentResultProps {
    paymentIntent: StripePaymentIntent;
}

const PaymentResult = ({ paymentIntent }: PaymentResultProps) => {
    if (paymentIntent.status === IntentStatus.Succeeded) {
        return <SuccessfulPayment paymentIntent={paymentIntent} />;
    }
    return <FailedPayment />;
};

export default PaymentResult;
