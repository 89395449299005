import React from 'react';
import { Grid } from '@talentmesh/core';
import ApplicantInformation from '../../../../../../../Models/ApplicantInformation';
import ApplicantInformationType from '../../../../../../../Models/ApplicantInformationType';
import UIStrings from '../../../../../../../Utils/UIStrings';
import CustomBox from '../../Components/Common/CustomBox';
import { HorizontalDivider } from '../../Components/Common/CustomDivider';
import ReasonItem from './RecruitmentInformation/ReasonItem';
import getFormattedInternationalPhoneNumber from './RecruitmentInformation/Utils/getFormattedInternationalPhoneNumber';
import ApplicantCvSection from './RecruitmentInformation/ApplicantCvSection';
import DetailsHeader from './Common/DetailsHeader';
import GridRowItem from './Common/GridRowItem';
import { formatSalaryExpectation } from '../../../../../../Assessments/Modify/Steps/RequestInformation/RequestInformationStepUtils';
import SalaryExpectation from '../../../../../../../Models/SalaryExpectation';

interface RecruitmentInformationSectionProps {
    additionalInformation: ApplicantInformation[];
}

function RecruitmentInformationSection({ additionalInformation }: RecruitmentInformationSectionProps): JSX.Element {
    const resumeCV = additionalInformation.find((item) => item.informationType === ApplicantInformationType.ResumeCV);
    const reasonForApplying = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.ReasonForApplying,
    );
    const reasonForLeaving = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.ReasonForLeaving,
    );
    const noticePeriod = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.NoticePeriod,
    );
    const phoneNumber = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.PhoneNumber,
    );
    const validWorkPermit = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.ValidWorkPermit,
    );
    const whyWorkInStartup = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.WhyWorkInStartup,
    );
    const rankRemunerationLearningBalance = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.RankRemunerationLearningBalance,
    );
    const structureImportance = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.StructureImportance,
    );
    const personalProject = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.PersonalProject,
    );
    const whereDoYouSeeYourself = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.WhereDoYouSeeYourself,
    );
    const salaryExpectation = additionalInformation.find(
        (item) => item.informationType === ApplicantInformationType.SalaryExpectation,
    );

    return (
        <>
            <DetailsHeader label={UIStrings.RecruitmentInformation} />
            <HorizontalDivider />
            <CustomBox>
                <Grid container direction="column" spacing={3}>
                    {resumeCV && <ApplicantCvSection cv={resumeCV} />}
                    {phoneNumber && (
                        <GridRowItem
                            title={UIStrings.PhoneNumber}
                            value={getFormattedInternationalPhoneNumber(phoneNumber.answer)}
                        />
                    )}
                    {noticePeriod && <GridRowItem title={UIStrings.NoticePeriod} value={noticePeriod.answer} />}
                    {validWorkPermit && (
                        <GridRowItem title={UIStrings.ValidWorkPermitForTheCountry} value={validWorkPermit.answer} />
                    )}
                    {reasonForApplying && (
                        <ReasonItem title={UIStrings.ReasonForApplying} value={reasonForApplying.answer} />
                    )}
                    {reasonForLeaving && (
                        <ReasonItem title={UIStrings.ReasonForLeaving} value={reasonForLeaving.answer} />
                    )}
                    {whyWorkInStartup && (
                        <ReasonItem title={UIStrings.WhyDoYouWantToWorkForStartup} value={whyWorkInStartup.answer} />
                    )}
                    {rankRemunerationLearningBalance && (
                        <ReasonItem
                            title={UIStrings.RankRemunerationPaceOfLearningWorkLifeBalance}
                            value={rankRemunerationLearningBalance.answer}
                        />
                    )}
                    {structureImportance && (
                        <ReasonItem
                            title={UIStrings.HowImportantAreStructureAndProcesses}
                            value={structureImportance.answer}
                        />
                    )}
                    {personalProject && (
                        <ReasonItem title={UIStrings.GiveAnExampleOfProject} value={personalProject.answer} />
                    )}
                    {whereDoYouSeeYourself && (
                        <ReasonItem
                            title={UIStrings.WhereDoYouSeeYourselfInTheNextFewYears}
                            value={whereDoYouSeeYourself.answer}
                        />
                    )}
                    {salaryExpectation && (
                        <ReasonItem
                            title={UIStrings.ExpectedYearlySalary}
                            value={formatSalaryExpectation(JSON.parse(salaryExpectation.answer) as SalaryExpectation)}
                        />
                    )}
                </Grid>
            </CustomBox>
        </>
    );
}
export default RecruitmentInformationSection;
