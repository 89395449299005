import { AdvancedFilterDTO, DefaultAdvancedFilterDTO } from '../../../../DTO/Results/AdvancedFilterDTO';
import AdvancedFilterTypes from '../../../../Models/AdvancedFilterTypes';
import autoMapping from '../../../../Utils/autoMapping';
import UIStrings from '../../../../Utils/UIStrings';
import {
    getEducationLevel,
    getEducationLevelLabel,
} from '../../Results/Components/Tabs/Details/Components/Qualification/QualificationUtils';

export interface AdvancedFilter {
    id: string;
    name: string;
    count: number;
    filterType: AdvancedFilterTypes;
    selected: boolean;
}

export const DefaultAdvancedFilter: AdvancedFilter = {
    selected: false,
    count: 0,
    filterType: AdvancedFilterTypes.All,
    id: '',
    name: '',
};

export function mapAdvancedFilterDto2Model(dto: AdvancedFilterDTO): AdvancedFilter {
    const model = autoMapping(dto, DefaultAdvancedFilter);

    if (model.filterType === AdvancedFilterTypes.HighestLevelOfEducation) {
        model.name = getEducationLevelLabel({ level: getEducationLevel(model.id), userDefined: UIStrings.Other });
    }

    return model;
}

export function mapAdvancedFilterModel2Dto(model: AdvancedFilter): AdvancedFilterDTO {
    return autoMapping(model, DefaultAdvancedFilterDTO);
}

export function mapAdvancedFiltersToIdsByType(model: AdvancedFilter[], type: AdvancedFilterTypes): string[] {
    return model.filter((filter) => filter.filterType === type).map((filter) => filter.id);
}
