import * as yup from 'yup';
import YupValidationErrors from '../../../../../../Utils/YupValidationErrors';

// https://react-hook-form.com/docs/usefieldarray#rules point 5 "Does not support flat field array."
interface RHFCriteriaValue {
    value: string;
}

export interface ExperienceCriteria {
    enableExperienceCriteria: boolean;
    criteria: RHFCriteriaValue[];
}

export const minExperienceCriteria = 1;
export const maxExperienceCriteria = 5;

export const experienceCriteriaSchema: yup.SchemaOf<ExperienceCriteria> = yup.object({
    enableExperienceCriteria: yup.boolean().required(),
    criteria: yup
        .array()
        .of(yup.object())
        .max(maxExperienceCriteria)
        .when('enableExperienceCriteria', {
            is: true,
            then: yup
                .array()
                .of(
                    // this can be simplified with yup.ObjectSchema or even inferred, but does not work maybe because yup is outdated
                    yup.object<Record<keyof RHFCriteriaValue, yup.StringSchema>>({
                        value: yup
                            .string()
                            .required(YupValidationErrors.Required)
                            .max(1024, YupValidationErrors.MaxSymbolsValidationError),
                    }),
                )
                .min(minExperienceCriteria, YupValidationErrors.PleaseAddAtLeastOneCriteriaRequiredError),
        })
        .required(),
});

export const experienceCriteriaFieldNames: { [key in keyof ExperienceCriteria]: key } = {
    criteria: 'criteria',
    enableExperienceCriteria: 'enableExperienceCriteria',
};

export const mapRhfCriteriaToString = (rhfCriteria: RHFCriteriaValue): string => rhfCriteria.value;
export const mapStringToRhfCriteria = (value: string): RHFCriteriaValue => ({ value });
