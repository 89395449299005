import React, { useEffect, useState } from 'react';
import { useBenchmarkClient } from '../../../../../../../Hooks/ClientHooks';
import { useCandidatesResultsContext } from '../../../../Contexts/CandidateResultsContext';
import {
    AssessmentMeanBenchmark,
    DefaultAssessmentMeanBenchmark,
} from '../../../../../../../Models/AssessmentMeanBenchmark';
import { TestTypes } from '../../../../../../../Models/Configuration';
import BenchmarkHeader from './Components/BenchmarkHeader';
import BenchmarkTabs from './Components/BenchmarkTabs';
import ResultsPaper from '../../../Common/ResultsPaper';
import { TestScore } from '../../../../../../../Models/TestScore';

export interface BenchmarkPanelProps {
    candidateFirstName: string;
    candidateScore: TestScore;
    testType: TestTypes;
}

function BenchmarkPanel({ candidateFirstName, candidateScore, testType }: BenchmarkPanelProps): JSX.Element {
    const client = useBenchmarkClient();
    const { assessmentId } = useCandidatesResultsContext();
    const [benchmark, setBenchmark] = useState<AssessmentMeanBenchmark>(DefaultAssessmentMeanBenchmark);

    useEffect(() => {
        const doGetBenchmark = async () => {
            const response = await client.getMeanBenchMarkAsync(assessmentId);
            setBenchmark(response);
        };
        doGetBenchmark();
    }, []);

    return (
        <ResultsPaper>
            <BenchmarkHeader />
            <BenchmarkTabs
                candidateFirstName={candidateFirstName}
                candidateScore={candidateScore}
                testType={testType}
                benchmark={benchmark}
            />
        </ResultsPaper>
    );
}

export default BenchmarkPanel;
