import React from 'react';
import { useTheme } from '@mui/material/styles';
import { resourceBaseUrl } from '../../../../../AppSettings';

function InviteIcon(): JSX.Element {
    const theme = useTheme();
    return <img src={`${resourceBaseUrl}/images/Invite.png`} width={theme.spacing(29.1875)} height="auto" alt="" />;
}

export default InviteIcon;
