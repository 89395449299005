import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Stack } from '@talentmesh/core';
import { FormikHelpers, useFormikContext } from 'formik';
import BaseTestCardConstants from './components/BaseTestCardConstants';
import BaseTestCardHeader, { BaseTestCardHeaderProps } from './components/BaseTestCardHeader';
import BaseTestCardContent from './components/BaseTestCardContent';
import getFieldNameByTestType from './utils/getFieldNameByTestType';
import { TestSelectionFormValues } from '../../../../../../SelectTestsStep';
import TestInfo from '../../../../../../../../Models/TestInfo';
import getValueByName from '../../../../../../../../../../../Utils/getValueByName';
import usePreviewQuestions, { PreviewQuestionsProps } from '../PreviewQuestions/Dialog/usePreviewQuestions';

export interface BaseTestCardProps extends TestInfo, BaseTestCardHeaderProps, PreviewQuestionsProps {
    helpers: FormikHelpers<any>;
    imageURL: string;
}

function BaseTestCard({
    helpers,
    imageURL,
    testType,
    topics,
    totalTopicsCount,
    duration,
    testName,
    demoQuestions,
    likertOptions,
}: BaseTestCardProps): JSX.Element {
    const theme = useTheme();

    const { values } = useFormikContext<TestSelectionFormValues>();
    const fieldName = getFieldNameByTestType(testType);
    const { dialog, toggleDialog, isPreviewQuestionsLoading } = usePreviewQuestions({
        testType,
        demoQuestions,
        likertOptions,
    });

    const [checked, setChecked] = useState(false);
    const [active, setActive] = useState(false);

    const isInteractive = checked || active;
    const cardWidth = theme.spacing(BaseTestCardConstants.MaxWidth);

    const handleMouseEnter = () => {
        setActive(true);
    };

    const handleMouseLeave = () => {
        setActive(false);
    };

    const handleClick = () => helpers.setFieldValue(fieldName, !checked);

    useEffect(() => {
        const v = getValueByName<boolean>(values, fieldName);
        setChecked(v);
    }, [values]);

    return (
        <>
            {dialog}
            <Stack
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
                sx={{
                    cursor: 'pointer',
                    maxWidth: cardWidth,
                    minWidth: cardWidth,
                    backgroundColor: checked ? theme.palette.common.white : theme.palette.background.lightBlue,
                    boxShadow: theme.shadows[isInteractive ? 5 : 0],
                    border: `1px solid ${theme.palette.border.main}`,
                    borderRadius: theme.shape.smallBorderRadius,
                    minHeight: theme.spacing(50.25),
                }}
            >
                <BaseTestCardHeader testName={testName} fieldName={fieldName} />
                <img
                    src={imageURL}
                    title="test image"
                    alt="test image"
                    style={{
                        width: theme.spacing(BaseTestCardConstants.MaxImageWidth),
                    }}
                />
                <BaseTestCardContent
                    testType={testType}
                    topics={topics}
                    totalTopicsCount={totalTopicsCount}
                    duration={duration}
                    isPreviewQuestionsLoading={isPreviewQuestionsLoading}
                    toggleDialog={toggleDialog}
                />
            </Stack>
        </>
    );
}

export default BaseTestCard;
