import PaymentMethodDTO from '../DTO/Users/PaymentMethodDTO';
import { CardBrand } from './CardBrand';

export default interface PaymentMethod {
    stripePaymentMethodId: string;
    cardLast4: string;
    cardBrand: CardBrand;
}

export const fromPaymentMethodDTO = (dto: PaymentMethodDTO): PaymentMethod => {
    return {
        stripePaymentMethodId: dto.stripePaymentMethodId,
        cardLast4: dto.cardLast4,
        cardBrand: dto.cardBrand,
    } as PaymentMethod;
};
