import React from 'react';
import { Paper, PaperProps } from '@talentmesh/core';

function ResultsPaper({ children, ...props }: PaperProps): JSX.Element {
    return (
        <Paper
            elevation={0}
            sx={{
                padding: 2.5,
            }}
            {...props}
        >
            {children}
        </Paper>
    );
}

export default ResultsPaper;
