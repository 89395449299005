import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';

export interface SnapshotProps {
    url: string;
    title: string;
}

function Snapshot({ url, title }: SnapshotProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="column" spacing={theme.spacing(1)}>
            <Typography variant="h5">{title}</Typography>
            <img src={url} alt="" width="100%" />
        </Stack>
    );
}

export default Snapshot;
