import React from 'react';
import { TMCircularProgress, Stack } from '@talentmesh/core';
import ScoreConnector from './ScoreConnector';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';

interface TalentScoreCellProps {
    candidate: CandidateOverview;
}

function TalentScoreCell({ candidate }: TalentScoreCellProps): JSX.Element {
    const { talentScore } = candidate;

    return (
        <>
            <Stack flex="0.5" />
            <TMCircularProgress
                size={92}
                thickness={2}
                level={talentScore?.scoreLevel}
                progress={talentScore?.talentScore}
                data-testid="talentmeshScoreCell"
            />
            <ScoreConnector />
        </>
    );
}

export default TalentScoreCell;
