export const getFormattedAmount = (amount: number): string => {
    return new Intl.NumberFormat('en-UK').format(amount);
};

export const convertFromStripeAmount = (amount: number): number => {
    return amount / 100;
};

export const getAmountWithCurrencySymbol = (
    amount: number,
    currencyCode: string,
    dispalyMode: 'symbolOnly' | 'symbolAndCode' = 'symbolAndCode',
    showDecimals: boolean = true,
): string => {
    if (currencyCode === '') {
        return getFormattedAmount(amount);
    }

    const numberFormatOptions: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
        // @ts-ignore: missing option in ts definition: https://github.com/microsoft/TypeScript/issues/52072
        roundingMode: 'trunc',
    };

    if (!showDecimals) {
        numberFormatOptions.minimumFractionDigits = 0;
        numberFormatOptions.maximumFractionDigits = 0;
    }

    const currencyFormatter = new Intl.NumberFormat('en-UK', numberFormatOptions);

    const formatted = currencyFormatter.format(amount);

    if (dispalyMode === 'symbolAndCode') {
        return `${formatted} ${currencyCode.toUpperCase()}`;
    }

    return formatted;
};
