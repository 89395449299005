import React from 'react';
import { FavoriteCheckbox } from '@talentmesh/core';
import { useCandidatesResultsContext } from '../../../Contexts/CandidateResultsContext';
import { useCandidatesClient } from '../../../../../../Hooks/ClientHooks';
import { useNotificationContext } from '../../../../../../Context/NotificationContext';

function CandidateFavorite(): JSX.Element {
    const { assessmentId, results, updateIsFavorite } = useCandidatesResultsContext();
    const { showFailToaster } = useNotificationContext();
    const client = useCandidatesClient();

    return (
        <FavoriteCheckbox
            data-testid="FavoriteCheckbox"
            checked={results.isFavorite}
            onChange={async () => {
                try {
                    const newValue = !results.isFavorite;
                    await client.saveFavoriteAsync(assessmentId, results.candidateId, newValue);
                    updateIsFavorite(newValue);
                } catch {
                    showFailToaster();
                }
            }}
        />
    );
}

export default CandidateFavorite;
