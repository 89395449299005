import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { ErrorOutlinedIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material';

interface SalaryErrorProps {
    error: string;
}

function SalaryError({ error }: SalaryErrorProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="row" alignItems="center" spacing={0.25} marginTop={theme.spacing(1)}>
            <ErrorOutlinedIcon color="error" fontSize="small" />
            <Typography variant="caption" marginTop={theme.spacing(1)} color={theme.palette.error.main}>
                {error}
            </Typography>
        </Stack>
    );
}

export default SalaryError;
