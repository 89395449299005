import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { ArrowUpwardIcon, ArrowDownwardIcon } from '@talentmesh/icons';
import { Aspect, aspectCompare } from '../../../../../../../../../../../Models/Aspect';

interface CharacteristicAspectsProps {
    characteristicName: string;
    aspects: Aspect[];
}

function PersonalityCharacteristicAspects({ characteristicName, aspects }: CharacteristicAspectsProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack spacing={1}>
            <Typography variant="subtitle2">{characteristicName}</Typography>
            {(aspects || []).sort(aspectCompare).map((aspect) => (
                <Stack
                    key={aspect.descriptor}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    {aspect.isHighDescriptor && <ArrowUpwardIcon style={{ color: theme.palette.charts.chart9 }} />}
                    {!aspect.isHighDescriptor && <ArrowDownwardIcon style={{ color: theme.palette.charts.chart10 }} />}
                    <Typography style={{ wordWrap: 'break-word' }} variant="body2">
                        {aspect.descriptor}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
}

export default PersonalityCharacteristicAspects;
