import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@talentmesh/core';
import UIStrings from '../Utils/UIStrings';

const PageFailedLoading = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack alignItems="center">
                <Typography variant="h3" color={theme.palette.text.menuTitle}>
                    {UIStrings.OopsSomethingWentWrong}
                </Typography>
                <Typography variant="body1">{UIStrings.PleaseRefresh}</Typography>
            </Stack>
        </Box>
    );
};

export default PageFailedLoading;
