import { PaymentIntentDTO } from '../DTO/Assessments/PaymentIntentDTO';

export interface PaymentIntent {
    clientSecret: string;
    amount: number;
    currency: string;
    serviceFeePercentage: number;
}

export const fromPaymentIntentDTO = (dto: PaymentIntentDTO): PaymentIntent => {
    return {
        clientSecret: dto.clientSecret,
        amount: dto.amount,
        currency: dto.currency,
        serviceFeePercentage: dto.serviceFeePercentage,
    } as PaymentIntent;
};
