import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import { ThumbUpOutlined } from '@mui/icons-material';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import UIStrings from '../../../../../../Utils/UIStrings';

interface JobDescriptionCharCounterProps {
    charNum: number;
    charLimit: number;
    hasError: boolean;
    error?: string;
}

function JobDescriptionCharCounter({
    charNum,
    charLimit,
    hasError,
    error,
}: JobDescriptionCharCounterProps): JSX.Element {
    const theme = useTheme();
    const linesColor = hasError ? theme.palette.error.main : theme.palette.hue.primary.h900;
    const backgroundColor = hasError ? theme.palette.hue.red.h50 : theme.palette.hue.primary.h50;

    return (
        <Stack
            direction="row"
            height={theme.spacing(5.5)}
            alignItems="center"
            justifyContent="space-between"
            padding={theme.spacing(1.25)}
            sx={{
                borderRadius: theme.spacing(1.25),
                border: `1px solid ${linesColor}`,
                backgroundColor,
            }}
        >
            <Typography color={linesColor}>{UIStrings.CharactersCounterWithLimit(charNum, charLimit)}</Typography>
            <Stack direction="row" spacing={theme.spacing(1.25)}>
                {hasError ? (
                    <ErrorOutlinedIcon sx={{ color: linesColor }} />
                ) : (
                    <ThumbUpOutlined sx={{ color: linesColor }} />
                )}
                {hasError && <Typography color={linesColor}>{error}</Typography>}
                <Typography color={linesColor}>{UIStrings.RecommendedCharacters}</Typography>
            </Stack>
        </Stack>
    );
}

export default JobDescriptionCharCounter;
