import React from 'react';
import { PercentileLevel, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import ResultsTalentScoreCircularProgress from './ResultsTalentScoreCircularProgress';

interface CandidateResultTalentScoreGroupProps {
    level: PercentileLevel;
    progress?: number;
}

function CandidateResultTalentScoreGroup({ level, progress }: CandidateResultTalentScoreGroupProps): JSX.Element {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6" color="secondary">
                {UIStrings.TalentScore}
            </Typography>
            <ResultsTalentScoreCircularProgress level={level} progress={progress} />
        </Stack>
    );
}

export default CandidateResultTalentScoreGroup;
