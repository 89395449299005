import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

function CongratsOnHireDialogHeader(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="row" spacing={theme.spacing(4)}>
            <Typography variant="h3" color={theme.palette.text.hint}>
                {UIStrings.CongratulationsOnTheHire}
            </Typography>
        </Stack>
    );
}

export default CongratsOnHireDialogHeader;
