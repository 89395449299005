import React from 'react';
import { Grid } from '@talentmesh/core';
import sortByFactorName from './Components/Factor.Utils';
import FactorPanel from './Components/FactorPanel';
import { useCandidatesResultsContext } from '../../../../../Contexts/CandidateResultsContext';

function FactorsPanel(): JSX.Element {
    const { results } = useCandidatesResultsContext();
    const { personalityScore } = results;

    const factors = personalityScore?.factors || [];

    const colNum = 2;
    const itemSize = 12 / colNum;

    return (
        <Grid
            container
            rowSpacing={5}
            columnSpacing={8.75}
            justifyContent="flex-start"
            alignItems="flex-start"
            paddingTop={5}
            paddingBottom={5}
        >
            {factors.sort(sortByFactorName).map((factor) => (
                <Grid item key={factor.factorName} xs={itemSize}>
                    <FactorPanel key={factor.factorName} factor={factor} />
                </Grid>
            ))}
        </Grid>
    );
}

export default FactorsPanel;
