import React, { ReactNode } from 'react';
import { Box } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

interface JobBoardInstructionsHeaderWrapperProps {
    children: ReactNode;
}

const JobBoardInstructionsHeaderWrapper = ({ children }: JobBoardInstructionsHeaderWrapperProps): JSX.Element => {
    const theme = useTheme();
    const headerHight = theme.spacing(6);

    return (
        <Box height={headerHight} display="flex" alignItems="center">
            {children}
        </Box>
    );
};

export default JobBoardInstructionsHeaderWrapper;
