export interface Delta {
    scale: 'minutes' | 'hours' | 'days' | 'weeks' | 'date';
    value: number;
}

export default function calculateTimeoutDelta(date: Date): Delta {
    const minutesToHoursThreshold = 60;
    const hoursToDaysThreshold = minutesToHoursThreshold * 24;
    const daysToWeeksThreshold = hoursToDaysThreshold * 7;
    const weeksToPlainDateThreshold = daysToWeeksThreshold * 4;
    const delta = (new Date().getTime() - date.getTime()) / 60000;
    if (delta < minutesToHoursThreshold) {
        return {
            scale: 'minutes',
            value: Math.ceil(delta),
        };
    }
    if (delta >= minutesToHoursThreshold && delta < hoursToDaysThreshold) {
        return {
            scale: 'hours',
            value: Math.round(delta / minutesToHoursThreshold),
        };
    }
    if (delta >= hoursToDaysThreshold && delta < daysToWeeksThreshold) {
        return {
            scale: 'days',
            value: Math.round(delta / hoursToDaysThreshold),
        };
    }
    if (delta >= daysToWeeksThreshold && delta < weeksToPlainDateThreshold) {
        return {
            scale: 'weeks',
            value: Math.round(delta / daysToWeeksThreshold),
        };
    }
    return {
        scale: 'date',
        value: date.getTime(),
    };
}
