import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Button, Stack, Typography, Toaster } from '@talentmesh/core';
import { ContentCopyIcon } from '@talentmesh/icons';
import UIStrings from '../../../Utils/UIStrings';

function CopyToClipboard(): JSX.Element {
    const theme = useTheme();
    const { protocol, host } = window.location;
    const [open, setOpen] = useState<boolean>(false);
    const linkContainer = 'linkContainer';

    const copyHandler = async () => {
        const element = document.getElementById(linkContainer);
        if (element) {
            await navigator.clipboard.writeText(element.innerText);
            setOpen(true);
        }
    };
    const closeToasterHandler = () => setOpen(false);

    return (
        <Stack
            direction="row"
            spacing={theme.spacing(2)}
            sx={{
                borderRadius: theme.shape.largeBorderRadius,
                backgroundColor: theme.palette.status.main,
            }}
            alignItems="center"
            justifyContent="space-around"
        >
            <Typography
                variant="h6"
                display="flex"
                color={theme.palette.text.menuTitle}
                id={linkContainer}
            >{`${protocol}//${host}`}</Typography>
            <Button startIcon={<ContentCopyIcon sx={{ color: theme.palette.text.menuTitle }} />} onClick={copyHandler}>
                <Typography color={theme.palette.text.menuTitle}>{UIStrings.CopyToClipboard}</Typography>
            </Button>
            <Toaster
                autoHideDuration={5000}
                status="success"
                alertMessage={UIStrings.LinkCopied}
                open={open}
                close={closeToasterHandler}
            />
        </Stack>
    );
}

export default CopyToClipboard;
