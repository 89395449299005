import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@talentmesh/core';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ProcessingState from '../../../../../Models/ProcessingState';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCreateRecruitmentContext } from '../../Create/Contexts/CreateRecruitmentContext';
import StepProps from '../StepProps';
import {
    ExperienceCriteria,
    experienceCriteriaSchema,
    mapRhfCriteriaToString,
    mapStringToRhfCriteria,
} from './Components/ExperienceCriteria';
import IdentifyExperienceStepFields from './Components/IdentifyExperienceStepFields/IdentifyExperienceStepFields';
import IdentifyExperienceStepHeader from './Components/IdentifyExperienceStepHeader';
import IdentifyExperienceStepNavigation from './Components/IdentifyExperienceStepNavigation';
import { useNotificationContext } from '../../../../../Context/NotificationContext';

const IdentifyExperienceStep = ({ activeStep, handleNext, handleBack }: StepProps): JSX.Element => {
    const {
        experienceCriteriaProcessingState,
        experienceCriteria,
        includesExperienceCriteria,
        setExperienceCriteriaForm,
        resetExperienceCriteriaProcessingState,
    } = useCreateRecruitmentContext();
    const { showFailToaster } = useNotificationContext();
    const form = useForm<ExperienceCriteria>({
        mode: 'all',
        defaultValues: {
            criteria: experienceCriteria.map(mapStringToRhfCriteria),
            enableExperienceCriteria: includesExperienceCriteria,
        },
        resolver: yupResolver(experienceCriteriaSchema),
    });
    const { reset, handleSubmit } = form;

    useEffect(() => {
        if (experienceCriteriaProcessingState === ProcessingState.Error) {
            showFailToaster(
                UIStrings.ThereWasAnErrorGeneratingTheExperienceCriteria,
                UIStrings.ErrorGeneratingExperienceCriteria,
            );
            resetExperienceCriteriaProcessingState();
        } else if (experienceCriteriaProcessingState === ProcessingState.Success) {
            reset({
                criteria: experienceCriteria.map(mapStringToRhfCriteria),
                enableExperienceCriteria: true,
            });
            resetExperienceCriteriaProcessingState();
        }
    }, [experienceCriteriaProcessingState, reset, showFailToaster, resetExperienceCriteriaProcessingState]);

    const onSubmit = ({ criteria, enableExperienceCriteria }: ExperienceCriteria) => {
        if (handleNext) {
            setExperienceCriteriaForm({
                experienceCriteria: criteria.map(mapRhfCriteriaToString),
                includesExperienceCriteria: enableExperienceCriteria,
            });
            handleNext();
        }
    };

    const handleBackWrapper = () => {
        if (handleBack) {
            const { criteria, enableExperienceCriteria } = form.getValues();

            setExperienceCriteriaForm({
                experienceCriteria: criteria.map(mapRhfCriteriaToString),
                includesExperienceCriteria: enableExperienceCriteria,
            });
            handleBack();
        }
    };

    return (
        <Grid container columns={14}>
            <Grid item xs={2} />
            <Grid item xs={10}>
                <FormProvider {...form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <IdentifyExperienceStepHeader />
                        <IdentifyExperienceStepFields />
                        <IdentifyExperienceStepNavigation
                            activeStep={activeStep}
                            handleBack={handleBackWrapper}
                            handleNext={handleNext}
                        />
                    </form>
                </FormProvider>
            </Grid>
            <Grid item xs={2} />
        </Grid>
    );
};

export default IdentifyExperienceStep;
