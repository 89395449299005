import React from 'react';
import { useTheme } from '@mui/material';
import { Typography } from '@talentmesh/core';
import { RecruiterRoles } from '../../../../../../Models/RecruiterRoles';

interface RoleProps {
    recruiterRole: RecruiterRoles;
}

function Role({ recruiterRole }: RoleProps): JSX.Element {
    const { palette } = useTheme();
    return (
        <Typography variant="body2" color={palette.text.primary}>
            {recruiterRole}
        </Typography>
    );
}

export default Role;
