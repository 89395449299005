import { Theme } from '@mui/material';
import UIStrings from '../../../../../../../Utils/UIStrings';

export function getColorByTestValidity(theme: Theme, value: number) {
    let color = theme.palette.grey[400];
    switch (value) {
        case 1:
        case 2:
            color = theme.palette.charts.chart10;
            break;
        case 3:
            color = theme.palette.charts.chart2;
            break;
        case 4:
            color = theme.palette.charts.chart3;
            break;
        case 5:
            color = theme.palette.charts.chart9;
            break;
        default:
            break;
    }

    return color;
}

export function getTextByTestValidity(value: number) {
    let text = '';
    switch (value) {
        case 1:
            text = UIStrings.TestValidityVeryPoor;
            break;
        case 2:
            text = UIStrings.TestValidityPoor;
            break;
        case 3:
            text = UIStrings.TestValidityModerate;
            break;
        case 4:
            text = UIStrings.TestValidityGood;
            break;
        case 5:
            text = UIStrings.TestValidityVeryGood;
            break;
        default:
            break;
    }

    return text;
}
