import React from 'react';
import countries from 'i18n-iso-countries';
// Import the languages you want to use
import enLocale from 'i18n-iso-countries/langs/en.json';
import { DropdownOption } from '@talentmesh/core';
import { FormikDropdown } from '@talentmesh/forms';

interface CountrySelectorProps {
    name: string;
    label?: string;
}

// Have to register the languages you want to use
countries.registerLocale(enLocale);

// Returns an object not a list
const countryObj = countries.getNames('en', { select: 'official' });

const countryOptions: DropdownOption[] = Object.entries(countryObj).map(([key, value]) => {
    return {
        id: key,
        name: value,
    };
});

const CountrySelector = ({ label, name }: CountrySelectorProps) => {
    return <FormikDropdown label={label} name={name} options={countryOptions?.length ? countryOptions : []} />;
};
export default CountrySelector;
