import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import EmailTemplatePlaceholders from '../EmailTemplatePlaceholders';
import EmailTemplateToolTip from '../EmailTemplateToolTip';
import { useCompanyContext } from '../../../../Context/CompanyContext';

interface SalutationProps {
    thankYouMessage: string;
}

const Salutation = ({ thankYouMessage }: SalutationProps) => {
    const theme = useTheme();
    const { currentCompanyProfile } = useCompanyContext();
    const thankYouFullMessage = thankYouMessage.replace(
        EmailTemplatePlaceholders.CompanyName,
        currentCompanyProfile.companyName,
    );

    return (
        <>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <FormikTextField sx={{ width: theme.spacing(14) }} name="title" />
                    <Typography sx={{ whiteSpace: 'nowrap' }} variant="body1">
                        {`${UIStrings.ApplicantsNamePlaceholder},`}
                    </Typography>
                </Stack>
                <EmailTemplateToolTip>{UIStrings.ApplicantNameTooltip}</EmailTemplateToolTip>
            </Stack>
            <Typography sx={{ paddingLeft: 1 }} variant="body1">
                {thankYouFullMessage}
            </Typography>
        </>
    );
};

export default Salutation;
