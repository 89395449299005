import React from 'react';
import { HorizontalBarChart } from '@talentmesh/charts';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import { getAssessmentAverageValue, getAssessmentInternalAverageValue } from '../BenchmarkUtils';
import { TestTypes } from '../../../../../../../../Models/Configuration';
import { AssessmentMeanBenchmark } from '../../../../../../../../Models/AssessmentMeanBenchmark';
import ChartBox from './ChartBox';

interface OverallScoreBenchmarkTabProps {
    candidateFirstName: string;
    candidateScore: number;
    testType: TestTypes;
    benchmark: AssessmentMeanBenchmark;
}

function OverallScoreBenchmarkTab({
    candidateFirstName,
    candidateScore,
    testType,
    benchmark,
}: OverallScoreBenchmarkTabProps): JSX.Element {
    return (
        <ChartBox>
            <HorizontalBarChart
                candidateScore={{
                    label: UIStrings.SomeoneScore(candidateFirstName),
                    value: candidateScore,
                }}
                averageScore={{
                    label: [UIStrings.AverageOfInvited, UIStrings.InvitedApplicants],
                    value: getAssessmentAverageValue(testType, benchmark),
                }}
                internalScore={{
                    label: [UIStrings.AverageOfInvited, UIStrings.InternalBenchmarks],
                    value: getAssessmentInternalAverageValue(testType, benchmark),
                }}
            />
        </ChartBox>
    );
}

export default OverallScoreBenchmarkTab;
