import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import React from 'react';

interface NameAndEmailProps {
    firstName: string;
    lastName: string;
    email: string;
}

function NameAndEmail({ firstName, lastName, email }: NameAndEmailProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="column" spacing={theme.spacing(1)}>
            <Typography variant="body2" color={theme.palette.text.menuTitle}>
                {firstName} {lastName}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary}>
                {email}
            </Typography>
        </Stack>
    );
}

export default NameAndEmail;
