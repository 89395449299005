import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactNode, useEffect, useState, useMemo } from 'react';
import { useSettingsClient } from '../Hooks/ClientHooks';
import { AssessmentSettings, DefaultAssessmentSettings } from '../Models/Configuration';
import { AssessmentSettingsContext } from './AssessmentSettingsContext';

interface AssessmentSettingsContextProviderProps {
    children: ReactNode;
}

function AssessmentSettingsProvider({ children }: AssessmentSettingsContextProviderProps): JSX.Element {
    const client = useSettingsClient();

    const [assessmentSettings, setAssessmentSettings] = useState<AssessmentSettings>(DefaultAssessmentSettings);

    const loadConfigurationAsync = async () => {
        const settings = await client.getAssessmentSettingsAsync();
        setAssessmentSettings(settings);
    };

    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        loadConfigurationAsync();
    }, [isAuthenticated]);

    const contextValue = useMemo(
        () => ({
            assessmentSettings,
            loadConfigurationAsync,
        }),
        [assessmentSettings],
    );

    return <AssessmentSettingsContext.Provider value={contextValue}>{children}</AssessmentSettingsContext.Provider>;
}

export default AssessmentSettingsProvider;
