import React from 'react';
import { GridOverlay, GridOverlayProps, LinearProgress } from '@talentmesh/core';

const GridLoadingOverlay = React.forwardRef<HTMLDivElement, GridOverlayProps>(function GridLoadingOverlay(props, ref) {
    return (
        <GridOverlay ref={ref} {...props}>
            <LinearProgress sx={{ width: '100%', alignSelf: 'start' }} />
        </GridOverlay>
    );
});

export default GridLoadingOverlay;
