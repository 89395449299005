import React from 'react';
import { Divider, List, Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../Utils/UIStrings';
import { jobsBaseUrl } from '../../../../AppSettings';
import OtherJobBoardInstructionStep, { OtherJobBoardInstructionStepProps } from './OtherJobBoardInstructionStep';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import CopyLinkControl from '../Components/CopyLinkControl';
import { useInvitationPageContext } from '../Contexts/InvitationPageContext';
import { useCompanyContext } from '../../../../Context/CompanyContext';

const steps: Array<OtherJobBoardInstructionStepProps> = [
    {
        stepCount: 1,
        stepTitle: UIStrings.CreateARecruitmentOnTalentMeshTitle,
        stepDescription: UIStrings.CreateARecruitmentOnTalentMeshDescription,
    },
    {
        stepCount: 2,
        stepTitle: UIStrings.CreateJobBoardPostAndCopyInTitle,
        stepDescription: UIStrings.CreateJobBoardPostAndCopyInDescription,
    },
    {
        stepCount: 3,
        stepTitle: UIStrings.RouteApplicantToTalentMeshTitle,
        stepDescription: UIStrings.RouteApplicantToTalentMeshDescription,
        stepSubDescription: UIStrings.CopyTheLinkShownBelow,
    },
];

const OtherJobBoardInstruction = (): JSX.Element => {
    const theme = useTheme();
    const { showSuccessToaster } = useNotificationContext();
    const {
        currentCompanyProfile: { companySlug },
    } = useCompanyContext();
    const { jobAdId } = useInvitationPageContext();

    const jobAdUrl = `${jobsBaseUrl}/${companySlug}/jobs/${jobAdId}`;
    const onClickHandler = () => {
        navigator.clipboard.writeText(jobAdUrl);
        showSuccessToaster('', UIStrings.LinkCopied);
    };

    return (
        <Stack
            spacing={theme.spacing(3)}
            sx={{
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2.5),
                borderRadius: theme.shape.smallBorderRadius,
                border: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Typography variant="h4" color="primary">
                {UIStrings.ConnectToAnyJobBoard}
            </Typography>
            <Typography variant="body1" color="secondary">
                {UIStrings.AllJobBoardsShouldAllow}
            </Typography>
            <Divider sx={{ borderColor: theme.palette.border.main }} />
            <List
                dense
                disablePadding
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    '& li:not(:first-of-type)': { marginRight: theme.spacing(3) },
                }}
            >
                {steps.map((item) => (
                    <OtherJobBoardInstructionStep key={item.stepCount} {...item} />
                ))}
            </List>
            <Divider sx={{ borderColor: theme.palette.border.main }} />
            <Stack alignItems="center">
                <Typography variant="h4" color="secondary">
                    {UIStrings.JobAdLinkForJobBoards}
                </Typography>
                <CopyLinkControl link={jobAdUrl} onClickHandler={onClickHandler} width={theme.spacing(40)} />
            </Stack>
        </Stack>
    );
};

export default OtherJobBoardInstruction;
