import React from 'react';
import { ChevronRightIcon } from '@talentmesh/icons';
import ProcessingState from '../../../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import NextStepButton from '../../../../../Components/NextStepButton';

interface NextButtonComponentProps {
    processingState: ProcessingState;
}

function NextButtonComponent({ processingState }: NextButtonComponentProps): JSX.Element {
    return (
        <NextStepButton loading={processingState === ProcessingState.Processing} endIcon={<ChevronRightIcon />}>
            {UIStrings.Next}
        </NextStepButton>
    );
}

export default NextButtonComponent;
