import { useTheme } from '@mui/material';
import { Box, Stack, TooltipPaper, Typography, WorkArrangements } from '@talentmesh/core';
import { useField } from 'formik';
import React from 'react';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { JobDetailsFieldTypes } from '../../JobDetailsStepUtils';

function JobLocationTooltipBody(): JSX.Element {
    const theme = useTheme();
    const [{ value: workArrangement }] = useField<WorkArrangements>(JobDetailsFieldTypes.WorkArrangement);

    return (
        <TooltipPaper minWidth={theme.spacing(45)}>
            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="h5" color={theme.palette.text.menuTitle}>
                    {workArrangement === 'Remote' ? UIStrings.RemoteTooltipTitle : UIStrings.OnLocationTooltipTitle}
                </Typography>
                <Box>
                    <Typography variant="body2" color={theme.palette.secondary.main}>
                        {workArrangement === 'Remote'
                            ? UIStrings.RemoteTooltipDescription
                            : UIStrings.OnLocationTooltipDescription}
                    </Typography>
                </Box>
            </Stack>
        </TooltipPaper>
    );
}

export default JobLocationTooltipBody;
