import { useTheme } from '@mui/material';
import React from 'react';
import { resourceBaseUrl } from '../../../AppSettings';
import UIStrings from '../../../Utils/UIStrings';
import ActionConfirmationDialog from './Components/ActionConfirmationDialog';
import CongratsOnHireDialogActions from './Components/CongratsOnHire/CongratsOnHireDialogActions';
import CongratsOnHireDialogHeader from './Components/CongratsOnHire/CongratsOnHireDialogHeader';
import DialogCardContainer from './Components/DialogCardContainer';
import DialogHeader from './Components/DialogHeader';
import { DialogEnum } from './Context/ActionDialogContext';
import CongratsOnHireDialogBody from './Components/CongratsOnHire/CongratsOnHireDialogBody';

interface CongratsOnHireDialogProps {
    assessmentId: string;
}

function CongratsOnHireDialog({ assessmentId }: CongratsOnHireDialogProps): JSX.Element {
    const theme = useTheme();

    return (
        <ActionConfirmationDialog
            dialogType={DialogEnum.CongratsOnHire}
            header={<DialogHeader title={UIStrings.Hire} backgroundColor={theme.palette.success.main} />}
            body={
                <DialogCardContainer
                    dialogTitle={<CongratsOnHireDialogHeader />}
                    dialogBody={<CongratsOnHireDialogBody />}
                    icon={
                        <img
                            src={`${resourceBaseUrl}/images/ATSHired.png`}
                            width={theme.spacing(29.1875)}
                            height="auto"
                            alt=""
                        />
                    }
                />
            }
            actions={<CongratsOnHireDialogActions assessmentId={assessmentId} />}
            renderClose={false}
            paperWidth={76}
        />
    );
}

export default CongratsOnHireDialog;
