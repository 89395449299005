import { useTheme } from '@mui/material/styles';
import { Button, LoadingButton, Paper, Stack } from '@talentmesh/core';
import { $generateHtmlFromNodes, RichTextEditor } from '@talentmesh/rte';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useAccountSettingsContext } from '../../../Context/AccountSettingsContext';
import { useNotificationContext } from '../../../Context/NotificationContext';
import { EmailTemplateType } from '../../../Models/Configuration';
import UIStrings from '../../../Utils/UIStrings';
import EmailTemplatePlaceholders from './EmailTemplatePlaceholders';
import PreviewEmailDialog from './PreviewEmailDialog';
import Closure from './TemplateEditor/Closure';
import Salutation from './TemplateEditor/Salutation';

interface TemplateFormInitialValues {
    title: string;
    regards: string;
}

interface TemplateEditorProps {
    title: string;
    bodyHtml: string;
    regards: string;
    thankYouMessage: string;
    templateType: EmailTemplateType;
    setDirty: (value: boolean) => void;
}

function TemplateEditor({
    title,
    bodyHtml,
    regards,
    thankYouMessage,
    templateType,
    setDirty,
}: TemplateEditorProps): JSX.Element {
    const theme = useTheme();
    const { openPreview, saveEmailTemplateAsync, getEmailTemplateHtml, setOpenPreview } = useAccountSettingsContext();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();
    const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
    const [currentBodyHtml, setCurrentBodyHtml] = useState<string>('');

    const dialogTitle =
        templateType === EmailTemplateType.InviteTemplate
            ? UIStrings.PreviewInvitationEmail
            : UIStrings.PreviewRejectionEmail;

    const initialValues: TemplateFormInitialValues = {
        title,
        regards,
    };

    const validationSchema = yup.object({
        title: yup.string().required(UIStrings.Required),
        regards: yup.string().required(UIStrings.Required),
    });

    const submitHandler = async (values: TemplateFormInitialValues) => {
        try {
            setSavingInProgress(true);
            const fullBodyHtml = getEmailTemplateHtml(
                values.title,
                thankYouMessage,
                currentBodyHtml,
                values.regards,
                EmailTemplatePlaceholders.ApplicantName,
                EmailTemplatePlaceholders.RecruiterName,
                EmailTemplatePlaceholders.CompanyName,
            );
            await saveEmailTemplateAsync(templateType, values.title, currentBodyHtml, values.regards, fullBodyHtml);
            setDirty(false);
            showSuccessToaster(UIStrings.EmailTemplateSuccessfullyUpdated);
        } catch (error) {
            showFailToaster();
        } finally {
            setSavingInProgress(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => submitHandler(values)}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
        >
            <Form>
                <Stack direction="column" spacing={2.5}>
                    <Paper
                        elevation={0}
                        sx={{
                            backgroundColor: theme.palette.action.disabledBackgroundDark,
                            p: 1,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}
                    >
                        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1.5}>
                            <Salutation thankYouMessage={thankYouMessage} />
                            <Stack sx={{ width: '100%' }} direction="column" spacing={0}>
                                <RichTextEditor
                                    features={['bold', 'italic', 'underline', 'ol', 'ul']}
                                    initialHtml={bodyHtml}
                                    onDirty={setDirty}
                                    onChange={(editorState, editor) => {
                                        editorState.read(() => {
                                            setCurrentBodyHtml($generateHtmlFromNodes(editor));
                                        });
                                    }}
                                    contentEditableProps={{
                                        ariaLabel: 'edit email template',
                                    }}
                                />
                            </Stack>
                            <Closure />
                        </Stack>
                    </Paper>
                    <Stack direction="row" spacing={1.5}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            loading={savingInProgress}
                            type="submit"
                        >
                            {UIStrings.SaveTemplate}
                        </LoadingButton>
                        <Button variant="outlined" color="secondary" size="large" onClick={() => setOpenPreview(true)}>
                            {UIStrings.PreviewEmail}
                        </Button>
                    </Stack>
                    <PreviewEmailDialog
                        dialogTitle={dialogTitle}
                        thankYouMessage={thankYouMessage}
                        bodyHtml={currentBodyHtml}
                        open={openPreview}
                        onClose={() => setOpenPreview(false)}
                    />
                </Stack>
            </Form>
        </Formik>
    );
}

export default TemplateEditor;
