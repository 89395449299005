import React from 'react';
import { useTheme } from '@mui/material';
import { Link, Typography } from '@talentmesh/core';
import { siteUrls } from '../../../AppSettings';
import UIStrings from '../../../Utils/UIStrings';

function SupportSection(): JSX.Element {
    const theme = useTheme();
    return (
        <>
            <Typography sx={{ textAlign: 'center', color: theme.palette.text.primary, marginTop: theme.spacing(3) }}>
                {`${UIStrings.StillHavingIssues} `}
                <Link
                    variant="body1"
                    href={siteUrls.contactSupportUrl}
                    mainColor={theme.palette.text.highlight}
                    highlightColor={theme.palette.text.highlight}
                >
                    {UIStrings.ContactUsCapitalized}
                </Link>
                {` ${UIStrings.ForAssistance}`}
            </Typography>
        </>
    );
}

export default SupportSection;
