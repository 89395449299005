import React from 'react';
import { useTheme } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Stack, Typography } from '@talentmesh/core';
import AccountHasBeenRevoked from './AccountHasBeenRevoked';
import useAcceptInvitationState from '../Hooks/useAcceptInvitationState';
import UIStrings from '../../../Utils/UIStrings';

function SubmissionError() {
    const { companyName } = useAcceptInvitationState();
    const { logout } = useAuth0();
    const theme = useTheme();

    const handleOnClick = () => {
        logout({
            returnTo: window.location.origin,
        });
    };

    return (
        <Stack
            direction="column"
            spacing={theme.spacing(2)}
            sx={{
                backgroundColor: theme.palette.hue.red.h50,
                padding: theme.spacing(1),
                borderRadius: theme.shape.smallBorderRadius,
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: theme.palette.border.main,
            }}
        >
            <AccountHasBeenRevoked companyName={companyName} />
            <Typography variant="body2" color={theme.palette.common.black}>
                {UIStrings.ToTryADifferentAccountPleaseLogout}
            </Typography>
            <Button
                onClick={handleOnClick}
                size="small"
                color="secondary"
                sx={{
                    width: theme.spacing(10),
                    borderRadius: theme.shape.smallBorderRadius,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: theme.palette.border.main,
                    backgroundColor: theme.palette.common.white,
                }}
            >
                {UIStrings.Logout}
            </Button>
        </Stack>
    );
}

export default SubmissionError;
