import React from 'react';
import { useTheme } from '@mui/material';
import { Dialog, DialogContent, Stack, Typography } from '@talentmesh/core';
import PopupDialogTitle from './PopupDialogTitle';
import UIStrings from '../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../Contexts/InviteCandidateContext';
import GoHomeButton from './GoHomeButton';

function RecruitmentLinkCopiedDialog(): JSX.Element {
    const theme = useTheme();
    const { copyLinkDialogOpen, setCopyLinkDialogOpen } = useInviteCandidateContext();

    const onClose = () => setCopyLinkDialogOpen(false);

    return (
        <Dialog open={copyLinkDialogOpen}>
            <PopupDialogTitle title={UIStrings.YourRecruitmentLinkCopied} close={onClose} />
            <DialogContent>
                <Stack
                    direction="column"
                    sx={{
                        width: theme.spacing(48),
                    }}
                    spacing={theme.spacing(2)}
                >
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        {UIStrings.InsertTheLinkInstruction}
                    </Typography>
                    <GoHomeButton />
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default RecruitmentLinkCopiedDialog;
