import React, { ReactNode } from 'react';
import { Paper } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

export interface CharacteristicsPaperProps {
    children: ReactNode;
}
export const CharacteristicsPaper = ({ children }: CharacteristicsPaperProps) => {
    const theme = useTheme();
    return (
        <Paper
            elevation={5}
            sx={{
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.smallBorderRadius,
                paddingTop: theme.spacing(1.5),
                paddingRight: theme.spacing(2),
                paddingBottom: theme.spacing(1.5),
                paddingLeft: theme.spacing(2),
            }}
        >
            {children}
        </Paper>
    );
};
