import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { toDateAgoString } from '../../../../../../Utils/DateHelper';

interface DateTimeCaptionProps {
    label: string;
    dateAt?: string;
}
function DateTimeCaption({ label, dateAt }: DateTimeCaptionProps) {
    const theme = useTheme();

    if (!dateAt) {
        return null;
    }

    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.625}>
            <Typography variant="caption" color={theme.palette.text.secondary}>
                {`${label}:`}
            </Typography>
            <Typography variant="caption" color={theme.palette.text.secondary}>
                {toDateAgoString(dateAt)}
            </Typography>
        </Stack>
    );
}

export default DateTimeCaption;
