import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { ReactNode } from 'react';
import { stripeConfig } from '../AppSettings';

interface StripeInitializationProps {
    children: ReactNode;
}

function StripeInitialization({ children }: StripeInitializationProps): JSX.Element {
    const stripeKey = stripeConfig.publishableKey;
    const stripePromise = React.useMemo(() => loadStripe(stripeKey || ''), []);
    return <Elements stripe={stripePromise}>{children}</Elements>;
}

export default StripeInitialization;
