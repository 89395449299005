import React from 'react';
import { useTheme } from '@mui/material/styles';
import { DialogTitle } from '@talentmesh/core';

interface SettingsDialogTitleProps {
    title: string;
}

const SettingsDialogTitle = ({ title }: SettingsDialogTitleProps) => {
    const theme = useTheme();
    return (
        <DialogTitle
            variant="h4"
            color={theme.palette.text.highlight}
            sx={{ paddingTop: 3.75, paddingLeft: 3.75, paddingRight: 3.75, paddingBottom: 2.5 }}
        >
            {title}
        </DialogTitle>
    );
};

export default SettingsDialogTitle;
