import React from 'react';
import { Box, VideoDialog, Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { getJobBoardLabel, getVideoLink, getVideoThumbnail, JobBoard, LinkedInParam } from './AvailableJobBoards';
import UIStrings from '../../../../Utils/UIStrings';
import WideTabPanel, { WideTabPanelProps } from '../../../../Components/Tab/WideTabPanel';
import JobBoardInstructionStep from './JobBoardInstructionStep';
import JobBoardInstructionsHeaderWrapper from './JobBoardInstructionsHeaderWrapper';
import LinkedInVideoHeader from './LinkedInVideoHeader';
import OtherJobBoardInstruction from './OtherJobBoardInstruction';

export interface JobBoardInstructionsProps extends Pick<JobBoard, 'name'>, Omit<WideTabPanelProps, 'children'> {}

const JobBoardInstructions = ({ name, tabKey, value }: JobBoardInstructionsProps): JSX.Element => {
    const theme = useTheme();
    const label = getJobBoardLabel(name);
    const [linkedInParam, setSelected] = React.useState<LinkedInParam>('normal');

    const handleChange = (event: React.MouseEvent<HTMLElement>, state: LinkedInParam) => {
        setSelected(state);
    };

    return (
        <WideTabPanel tabKey={tabKey} value={value}>
            {name === 'other' ? (
                <OtherJobBoardInstruction />
            ) : (
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{
                        backgroundColor: theme.palette.common.white,
                        padding: theme.spacing(2.5),
                        borderRadius: theme.shape.smallBorderRadius,
                        border: `1px solid ${theme.palette.border.main}`,
                    }}
                >
                    <Stack spacing={2.5} sx={{ width: '100%', maxWidth: theme.spacing(60.375) }}>
                        <JobBoardInstructionsHeaderWrapper>
                            <Typography variant="h4" color="primary">
                                {UIStrings.getConnectToJobBoards(label)}
                            </Typography>
                        </JobBoardInstructionsHeaderWrapper>
                        <Stack spacing={1.25}>
                            <JobBoardInstructionStep
                                stepIcon="seamless-connect"
                                stepLabel={UIStrings.getSeamlessAttract(label)}
                            />
                            <JobBoardInstructionStep
                                stepIcon="two-minute-step"
                                stepLabel={UIStrings.WatchOurTwoMinuteStep}
                            />
                            <JobBoardInstructionStep
                                stepIcon="routed-connection"
                                stepLabel={UIStrings.getOnceTheConnection(label)}
                            />
                        </Stack>
                    </Stack>
                    <Stack spacing={2.5} sx={{ width: '100%' }}>
                        <JobBoardInstructionsHeaderWrapper>
                            {name === 'linkedin' ? (
                                <LinkedInVideoHeader selected={linkedInParam} handleChange={handleChange} />
                            ) : (
                                <Typography variant="h4" color="primary">
                                    {UIStrings.getConnectToJobBoards(label)}
                                </Typography>
                            )}
                        </JobBoardInstructionsHeaderWrapper>
                        <Box
                            sx={{
                                border: `1px solid ${theme.palette.border.main}`,
                                borderRadius: theme.shape.smallBorderRadius,
                            }}
                        >
                            <VideoDialog
                                alt="TalentMesh Recruitment Platform Onboarding Video"
                                src={getVideoLink(name, linkedInParam)}
                                thumbnail={getVideoThumbnail(name, linkedInParam)}
                            />
                        </Box>
                    </Stack>
                </Stack>
            )}
        </WideTabPanel>
    );
};

export default JobBoardInstructions;
