import React from 'react';
import { useTheme, ListItemIcon, ListItemText, MenuItemProps, MenuItem, SxProps, Theme } from '@mui/material';

export interface ProfileMenuItemProps extends MenuItemProps {
    preventClose?: boolean;
    menuItemChildren?: React.ReactNode;
    menuItemLabel: string;
    icon?: React.ReactNode;
    sticky?: boolean;
    labelSx?: SxProps<Theme>;
}

export const ProfileMenuItemHeight = 5;

const ProfileMenuItem: React.FC<ProfileMenuItemProps> = ({
    menuItemLabel,
    icon,
    preventClose,
    menuItemChildren,
    sticky,
    labelSx,
    ...restProps
}) => {
    const theme = useTheme();
    return (
        <MenuItem
            disableRipple
            {...restProps}
            sx={[
                {
                    height: theme.spacing(ProfileMenuItemHeight),
                    color: theme.palette.secondary.main,
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    position: sticky ? 'sticky' : 'static',
                    top: 0,
                    backgroundColor: sticky ? theme.palette.common.white : 'transparent',
                    '&:hover': {
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.info.main,
                    },
                    '& .MuiTypography-root': {
                        ...labelSx,
                    },
                },
                ...(Array.isArray(restProps.sx) ? restProps.sx : [restProps.sx]),
            ]}
        >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText inset={!icon} sx={{ marginRight: theme.spacing(2) }}>
                {menuItemLabel}
            </ListItemText>
            {menuItemChildren}
        </MenuItem>
    );
};

export default ProfileMenuItem;
