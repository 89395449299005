import { useTheme } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Box, Stack } from '@talentmesh/core';
import { Candidate, DefaultCandidate, candidateValidationSchema } from '../../../../../Models/Candidate';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';
import CandidateInputForm from './CandidateInputForm';
import CandidateInputFormHeader from './CandidateInputFormHeader';
import CheckboxWithTooltip from './CheckboxWithTooltip';

const CandidateAddSection = (): JSX.Element => {
    const { createCandidateAccountAsync } = useInviteCandidateContext();
    const theme = useTheme();

    const validateApplicantAddition = async (
        values: Candidate,
        helpers: FormikHelpers<Candidate>,
    ): Promise<boolean> => {
        let createCandidateAccountResponse = '';

        createCandidateAccountResponse = await createCandidateAccountAsync({
            ...values,
        });

        if (createCandidateAccountResponse) {
            helpers.setFieldError('email', createCandidateAccountResponse);
            throw new Error(createCandidateAccountResponse);
        }

        return true;
    };

    const addCandidateHandlerAsync = async (values: Candidate, helpers: FormikHelpers<Candidate>) => {
        if (await validateApplicantAddition(values, helpers)) {
            helpers.resetForm({});
        }
    };

    return (
        <Stack direction="column">
            <Formik
                initialValues={DefaultCandidate}
                onSubmit={addCandidateHandlerAsync}
                validationSchema={candidateValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Form>
                    <Stack
                        direction="column"
                        sx={{
                            marginBlock: theme.spacing(1.25),
                        }}
                        spacing={theme.spacing(1.25)}
                    >
                        <CandidateInputFormHeader />
                        <Box>
                            <CandidateInputForm />
                            <CheckboxWithTooltip />
                        </Box>
                    </Stack>
                </Form>
            </Formik>
        </Stack>
    );
};

export default CandidateAddSection;
