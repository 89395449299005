import React from 'react';
import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';

const AssessmentResetDialogBody = (): JSX.Element => {
    const theme = useTheme();
    const warnings = UIStrings.getAssessmentResetDialogWarningList.map((warning: string) => (
        <li key={warning}>
            <Typography variant="body2" gutterBottom>
                {warning}
            </Typography>
        </li>
    ));
    return (
        <Stack spacing={theme.spacing(2)}>
            <Typography variant="h5" color={theme.palette.text.primary}>
                {UIStrings.Important}
            </Typography>
            <ul style={{ paddingInlineStart: theme.spacing(3) }}>{warnings}</ul>
        </Stack>
    );
};

export default AssessmentResetDialogBody;
