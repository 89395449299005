import React from 'react';
import { useTheme } from '@mui/material/styles';

interface ProfileDetailLabelProps {
    name: string;
    value: string;
}

function ProfileDetailLabel({ name, value }: ProfileDetailLabelProps): JSX.Element {
    const theme = useTheme();

    return (
        <>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>{`${name}: `}</span>
            {value}
            <br />
        </>
    );
}

export default ProfileDetailLabel;
