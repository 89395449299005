import React from 'react';
import { Grid, PercentileLevel } from '@talentmesh/core';
import { Aspect } from '../../../../../../../../../../../Models/Aspect';
import { progressSize } from './PersonalityCharacteristicConsts';
import PersonalityCharacteristicAspects from './PersonalityCharacteristicAspects';
import PersonalityCharacteristicsToolTip from './PersonalityCharacteristicsToolTip';

interface PersonalityCharacteristicAspectTooltipProps {
    characteristicName: string;
    percentileScore: number;
    percentileLevel: PercentileLevel;
    aspects: Aspect[];
}

function PersonalityCharacteristicAspectTooltip({
    characteristicName,
    percentileScore,
    percentileLevel,
    aspects,
}: PersonalityCharacteristicAspectTooltipProps): JSX.Element {
    return (
        <Grid item xs={progressSize} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <PersonalityCharacteristicsToolTip
                hidden={percentileLevel === 'Medium' || aspects.length === 0}
                percentileScore={percentileScore}
                percentileLevel={percentileLevel}
            >
                <PersonalityCharacteristicAspects characteristicName={characteristicName} aspects={aspects} />
            </PersonalityCharacteristicsToolTip>
        </Grid>
    );
}

export default PersonalityCharacteristicAspectTooltip;
