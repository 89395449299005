import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@talentmesh/core';
import React, { ReactNode } from 'react';

interface PaymentSectionProps {
    header: string;
    actionComponent?: ReactNode;
    children: ReactNode;
}

const PaymentSection = ({ header, actionComponent, children }: PaymentSectionProps) => {
    const theme = useTheme();

    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ marginBottom: 1.25 }} variant="body1" color={theme.palette.text.secondary}>
                    {header}
                </Typography>
                {actionComponent}
            </Stack>
            {children}
        </Stack>
    );
};

export default PaymentSection;
