import { Typography } from '@mui/material';
import React from 'react';
import { Stack } from '@talentmesh/core';
import { CardBrand } from '../../Models/CardBrand';
import CreditCardLogo from './CreditCardLogo/CreditCardLogo';

export interface CreditCardPreviewProps {
    cardBrand: CardBrand;
    cardLast4: string;
}

export const CreditCardPreview = ({ cardBrand, cardLast4 }: CreditCardPreviewProps): JSX.Element => {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <CreditCardLogo cardBrand={cardBrand} />
            <Typography variant="body1">{`${cardBrand} - ${cardLast4}`}</Typography>
        </Stack>
    );
};
