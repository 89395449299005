import { JobAd } from '../../Pages/Assessments/Modify/Models/JobAd';
import { JobDetails } from '../../Pages/Assessments/Modify/Models/JobDetails';

interface GenerateExperienceCriteriaDTO {
    companyDescription: string;
    jobTitle: string;
    jobDescription: string;
    hardSkills: Array<string>;
    softSkills: Array<string>;
    salary?: string;
}

export function createGenerateExperienceCriteriaDTO(
    jobAd: JobAd,
    jobDetails: JobDetails,
): GenerateExperienceCriteriaDTO {
    const dto: GenerateExperienceCriteriaDTO = {
        companyDescription: jobAd.companyDescription,
        jobTitle: jobAd.title,
        jobDescription: jobAd.description,
        hardSkills: jobAd.hardSkillsSwitch ? jobAd.hardSkills.map((skill) => skill.label) : [],
        softSkills: jobAd.softSkillsSwitch ? jobAd.softSkills.map((skill) => skill.label) : [],
        salary: jobDetails.doNotDisplaySalary
            ? undefined
            : `${jobDetails.yearlySalaryFrom} - ${jobDetails.yearlySalaryTo} ${jobDetails.currency.label} per year`,
    };
    return dto;
}

export default GenerateExperienceCriteriaDTO;
