import { useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { Dialog, Paper, Stack } from '@talentmesh/core';
import { useAccountSettingsContext } from '../../../Context/AccountSettingsContext';
import { useUserContext } from '../../../Context/UserContext';
import UIStrings from '../../../Utils/UIStrings';
import DialogCard from './Components/DialogCard';
import DialogHeader from './Components/DialogHeader';
import DialogTemplate from './Components/DialogTemplate';
import DialogCardInterviewBody from './Components/Interview/DialogCardInterviewBody';
import DialogCardInterviewHeader from './Components/Interview/DialogCardInterviewHeader';
import InterviewActions from './Components/Interview/InterviewActions';
import { DialogEnum, useActionDialogContext } from './Context/ActionDialogContext';
import InviteIcon from './Components/Interview/InviteIcon';
import { useCompanyContext } from '../../../Context/CompanyContext';

interface InterviewDialogProps {
    assessmentName: string;
}

function InterviewDialog({ assessmentName }: InterviewDialogProps): JSX.Element {
    const theme = useTheme();
    const width = theme.spacing(77.75);
    const { accountSettings } = useAccountSettingsContext();
    const { user } = useUserContext();
    const { currentCompanyProfile } = useCompanyContext();
    const { selectedCandidates, isDialogOpen, hideDialog } = useActionDialogContext();
    const [fullName, setFullName] = useState('');

    const closeInterviewDialog = () => {
        hideDialog(DialogEnum.Interview);
    };

    useEffect(() => {
        setFullName(selectedCandidates.length > 1 ? UIStrings.ApplicantNameVariable : selectedCandidates[0]?.name);
    }, [selectedCandidates]);

    return (
        <Dialog
            PaperProps={{
                style: {
                    overflow: 'unset',
                    backgroundColor: theme.palette.common.white,
                    boxShadow: 'none',
                    maxWidth: 'unset',
                    width: 'auto',
                    height: 'auto',
                    border: `1px solid ${theme.palette.border.main}`,
                },
            }}
            open={isDialogOpen(DialogEnum.Interview)}
            onClose={closeInterviewDialog}
        >
            <Stack direction="row" spacing={0}>
                <Paper sx={{ width }} elevation={0}>
                    <Stack
                        spacing={2.5}
                        sx={{
                            paddingTop: 0,
                            paddingLeft: 2.5,
                            paddingRight: 2.5,
                            paddingBottom: 2.5,
                            height: '100%',
                        }}
                    >
                        <DialogHeader title={UIStrings.Interview} backgroundColor={theme.palette.primary.main} />
                        <DialogCard
                            dialogTitle={<DialogCardInterviewHeader name={fullName} />}
                            dialogBody={<DialogCardInterviewBody name={fullName} />}
                            icon={<InviteIcon />}
                        />
                        <InterviewActions />
                    </Stack>
                </Paper>

                <DialogTemplate
                    sx={{
                        width,
                        height: theme.spacing(79.5),
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: theme.shape.smallBorderRadius,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                    dialogTitle={UIStrings.InterviewInvitationEmailTemplate}
                    {...accountSettings.inviteEmailTemplate}
                    candidateName={fullName}
                    recruiterName={`${user.firstName} ${user.lastName}`}
                    companyName={currentCompanyProfile.companyName}
                    recruitmentName={assessmentName}
                    closeDialog={closeInterviewDialog}
                    editEmailTemplate={() => {}}
                    boxHeight={theme.spacing(62.75)}
                />
            </Stack>
        </Dialog>
    );
}

export default InterviewDialog;
