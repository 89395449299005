import { AssessmentMeanBenchmark, DefaultAssessmentMeanBenchmark } from '../Models/AssessmentMeanBenchmark';
import { get } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class BenchmakrClient extends ClientBase {
    readonly prefix = 'benchmarks';

    async getMeanBenchMarkAsync(assessmentId: string): Promise<AssessmentMeanBenchmark> {
        const response = await get<AssessmentMeanBenchmark>({
            accessToken: await this.getAccessTokenSilently(),
            path: this.addHost(`${this.prefix}/${assessmentId}/mean`),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultAssessmentMeanBenchmark;
    }
}
