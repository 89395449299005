import React, { useState, MouseEvent } from 'react';
import BaseFilterPanel from './BaseFilterPanel';
import BaseFilterButton, { BaseFilterButtonProps } from './BaseFilterButton';

export interface BaseFilterProps extends Pick<BaseFilterButtonProps, 'label' | 'activeFilterCount'> {
    children: JSX.Element;
}

function BaseFilter({ label, activeFilterCount, children }: BaseFilterProps): JSX.Element {
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpenFilter = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
        setOpen(true);
    };

    const handleCloseFilter = () => {
        setAnchorElement(null);
        setOpen(false);
    };

    return (
        <>
            <BaseFilterButton
                open={open}
                label={label}
                activeFilterCount={activeFilterCount}
                handleOpen={handleOpenFilter}
            />
            <BaseFilterPanel open={open} anchorElement={anchorElement} handleClose={handleCloseFilter}>
                {children}
            </BaseFilterPanel>
        </>
    );
}

export default BaseFilter;
