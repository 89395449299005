import { useTheme } from '@mui/material';
import React from 'react';
import { Divider, Stack, TruncatedTypography, Typography } from '@talentmesh/core';
import { useUserContext } from '../../../Context/UserContext';

// this file contains div instead of Box is intentional because @talentmesh/core does not
// support forwardRef as of the time of writing this, please see https://dev.azure.com/TalentMesh/TalentMesh/_git/recruiter-app/pullRequest/3729#1692091931
const ProfileMenuHeader = React.forwardRef<HTMLDivElement>((_, ref) => {
    const theme = useTheme();
    const { user } = useUserContext();

    return (
        <div ref={ref}>
            <Stack width="100%" spacing={0.5} py={theme.spacing(1.25)} px={theme.spacing(1)}>
                <TruncatedTypography
                    typographyProps={{
                        variant: 'body2',
                        sx: {
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.menuTitle,
                        },
                    }}
                    value={user.firstName}
                    overrideTruncateAfter={25}
                />
                {user.role && (
                    <Typography variant="caption" color={theme.palette.text.menuTitle}>
                        {user.role}
                    </Typography>
                )}
            </Stack>
            <Divider sx={{ borderBottomWidth: theme.spacing(0.1) }} />
        </div>
    );
});

export default ProfileMenuHeader;
