/* eslint-disable @typescript-eslint/lines-between-class-members */
export default class RoutePath {
    static readonly Root = '/';
    static readonly SetupAccount = '/setup-account';
    static readonly VerifyEmail = '/verify-email';
    static readonly FinalizeRegistration = '/finalize-registration';
    static readonly Provision = '/provision';
    static readonly AcceptInvitation = '/accept-invitation';
    static readonly FetchInvitation = '/fetch-invitation';
    static readonly Register = '/register';
    static readonly IdentityError = '/identity-error';
    static readonly Error = '/error';
    static readonly Version = '/version';
    static readonly FullExperience = '/full-experience';
    static readonly EmailVerified = '/email-verified';
    // important: routes with parameters should be defined at the end
    // otherwise route matching in useCompanyIdRouteMatch won't work correctly
    static readonly ObsoleteCandidateResult = '/assessments/:assessmentId/candidate/:candidateId/results';
    static readonly CompanyRoot = '/:companyId';
    static readonly AssessmentsActive = `/:companyId/clients/:clientId/recruitments-overview`;
    static readonly AssessmentsClosed = `${RoutePath.AssessmentsActive}/?filter=closed`;
    static readonly CandidatesOverview = '/:companyId/assessments/:assessmentId/candidates-overview';
    static readonly CandidateResult = '/:companyId/assessments/:assessmentId/candidate/:candidateId/results';
    static readonly CreateAssessment = '/:companyId/clients/:clientId/create-assessment';
    static readonly EditRecruitment = '/:companyId/edit-recruitment';
    static readonly Checkout = '/:companyId/checkout';
    static readonly Career = '/:companyId/career';
    static readonly CareerBuilder = `${RoutePath.Career}/builder`;

    static readonly AccountSettings = '/:companyId/account-settings';
    static readonly AccountSettingsGeneral = `${RoutePath.AccountSettings}/general`;
    static readonly AccountSettingsNotifications = `${RoutePath.AccountSettings}/notifications`;
    static readonly AccountSettingsPayment = `${RoutePath.AccountSettings}/payment`;
    static readonly AccountSettingsEmailTemplates = `${RoutePath.AccountSettings}/email-templates`;
    static readonly AccountSettingsTeamMembers = `${RoutePath.AccountSettings}/team-members`;
    static readonly AccountSettingsCompanyProfile = `${RoutePath.AccountSettings}/company-profile`;
    static readonly AccountSettingsCareerPage = `${RoutePath.AccountSettings}/career-page`;

    static readonly AttractApplicants = '/:companyId/recruitments/:recruitmentId/attract';
    static readonly AttractApplicantsJobBoards = `${RoutePath.AttractApplicants}/job-boards`;
    static readonly AttractApplicantsCareerPage = `${RoutePath.AttractApplicants}/career-page`;
    static readonly AttractApplicantsInvite = `${RoutePath.AttractApplicants}/invite`;
}
