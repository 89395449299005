import { PaperProps, useTheme } from '@mui/material';
import React from 'react';
import { Paper, SpanTypography, Stack } from '@talentmesh/core';
import UIStrings from '../../../../../../../Utils/UIStrings';
import GoogleOnWhite from './google_on_white.png';

const GoogleAutocompletePaper = (props: PaperProps) => {
    const theme = useTheme();

    return (
        <Paper elevation={8} {...props}>
            {props.children}
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="right"
                width="inherit"
                paddingRight={theme.spacing(1)}
                paddingBottom={theme.spacing(0.5)}
                spacing={theme.spacing(0.5)}
            >
                <SpanTypography color={theme.palette.text.secondary} variant="body2">
                    {UIStrings.PoweredBy}
                </SpanTypography>
                <object width="59px" height="18px" type="image/png" data={GoogleOnWhite} aria-label="GoogleLogo" />
            </Stack>
        </Paper>
    );
};

export default GoogleAutocompletePaper;
