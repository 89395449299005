import { Pagination } from '@talentmesh/core';
import { useEffect, useState } from 'react';
import { ClientFilters } from '../components/Client';
import useCompanyId from '../../../Hooks/UseCompanyId';
import { useClientsClient } from '../../../Hooks/ClientHooks';
import { ClientOverviewResponse } from '../../../DTO/Clients/ClientOverviewResponse';
import ProcessingState from '../../../Models/ProcessingState';
import PaginationConsts from '../../../Utils/PaginationConsts';

const useClientOverview = () => {
    const companyId = useCompanyId();
    const clientsClient = useClientsClient();

    const [clients, setClients] = useState<ClientOverviewResponse[]>([]);
    const [getOverviewProcessingState, setGetOverviewProcessingState] = useState<ProcessingState>(
        ProcessingState.Processing,
    );
    const [pagination, setPagination] = useState<Pagination>({
        offset: 0,
        limit: PaginationConsts.largePageSize,
        totalCount: 0,
    });
    const [clientFilters, setClientFilters] = useState<ClientFilters>({});

    const getClientOverviewPageAsync = async (offset: number, pageSize: number = PaginationConsts.largePageSize) => {
        setGetOverviewProcessingState(ProcessingState.Processing);

        try {
            const searchTerm = clientFilters.search;

            const page = await clientsClient.getClientsOverviewsAsync(companyId, offset, pageSize, searchTerm);
            setClients(page.data);
            setPagination(page.pagination);
            setGetOverviewProcessingState(ProcessingState.Success);
        } catch {
            setGetOverviewProcessingState(ProcessingState.Error);
        }
    };

    useEffect(() => {
        const doLoadClientsAsync = async () => await getClientOverviewPageAsync(pagination.offset, pagination.limit);
        doLoadClientsAsync();
    }, [clientFilters, pagination.offset, setClients, setGetOverviewProcessingState, companyId]);

    return {
        clients,
        getClientOverviewPageAsync,
        getOverviewProcessingState,
        pagination,
        setPagination,
        clientFilters,
        setClientFilters,
    };
};

export default useClientOverview;
