import { useTheme } from '@mui/material';
import { Dialog } from '@talentmesh/core';
import React from 'react';
import { TestTypes } from '../../../../../../../../../../../../Models/Configuration';
import { LikertOption, Question, QuestionType } from '../../../../../../../../../Models/Questions';
import AnswerQuestionsPreview from '../AnswerQuestionsPreview';
import LikertQuestionsPreview from '../LikertQuestionsPreview';
import DialogHeader, { DialogHeaderProps } from './DialogHeader';

interface PreviewQuestionsDialogProps extends Omit<DialogHeaderProps, 'onClickClose'> {
    open: boolean;
    likertOptions?: LikertOption[];
    demoQuestions?: Question[];
    questionType?: QuestionType;
    testType: TestTypes;
    onClose: (e: React.MouseEvent) => void;
}

const PreviewQuestionsDialog = ({
    onClose = () => {},
    pagination,
    onNext,
    onPrevious,
    testType,
    demoQuestions,
    likertOptions,
    questionType,
    open,
}: React.PropsWithChildren<PreviewQuestionsDialogProps>) => {
    const theme = useTheme();
    const isAnswersType = questionType === QuestionType.Choice || questionType === QuestionType.Exact;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                sx: {
                    overflow: 'hidden',
                    height: theme.spacing(95),
                    background:
                        questionType === QuestionType.Likert
                            ? theme.palette.common.white
                            : `linear-gradient(to right, ${theme.palette.background.silverSand} 50%, ${theme.palette.common.white} 50%)`,
                },
            }}
        >
            <DialogHeader
                pagination={pagination}
                onClickClose={onClose}
                testType={testType}
                onNext={onNext}
                onPrevious={onPrevious}
            />
            {isAnswersType ? (
                <AnswerQuestionsPreview demoQuestion={demoQuestions![pagination.index]} />
            ) : (
                <LikertQuestionsPreview demoQuestions={demoQuestions!} likertOptions={likertOptions!} />
            )}
        </Dialog>
    );
};

export default PreviewQuestionsDialog;
