import { useTheme } from '@mui/material';
import React from 'react';
import { CropFree } from '@talentmesh/icons';
import UIStrings from '../../../../../Utils/UIStrings';
import CTMonitoringFunctionalityDisabled from './CTMonitoringFunctionalityDisabled';

interface CTMonitoringScreenshotsStubProps {
    isScreenSnapshotsEnabled?: boolean;
}

function CTMonitoringScreenshotsStub({ isScreenSnapshotsEnabled }: CTMonitoringScreenshotsStubProps): JSX.Element {
    const theme = useTheme();
    return (
        <CTMonitoringFunctionalityDisabled
            icon={<CropFree sx={{ color: theme.palette.text.disabled }} />}
            message={isScreenSnapshotsEnabled ? UIStrings.ScreenSnapshotsIsMissing : UIStrings.ScreenSnapshotsDisabled}
            height={theme.spacing(50)}
        />
    );
}

export default CTMonitoringScreenshotsStub;
