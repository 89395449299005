import { useTheme } from '@mui/material';
import React from 'react';
import { Divider, Paper, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { heightSpacing, paddingTopSpacing } from './RecruitmentListItemConsts';
import StatusCounter from './components/StatusCounter';
import TestIncluded from './TestIncluded';

interface RecruitmentInfoProps {
    hasPersonality: boolean;
    hasAptitude: boolean;
    hasSkills: boolean;
    hasEmotionalIntelligence: boolean;
    openedCount: number;
    ongoingCount: number;
    completedCount: number;
}

function AssessmentInfo({
    hasPersonality,
    hasAptitude,
    hasSkills,
    hasEmotionalIntelligence,
    openedCount,
    ongoingCount,
    completedCount,
}: RecruitmentInfoProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: theme.palette.background.silverSand,
                height: theme.spacing(heightSpacing),
                paddingTop: theme.spacing(paddingTopSpacing),
                paddingLeft: 1.25,
                paddingRight: 1.25,
            }}
        >
            <Stack direction="column" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color={theme.palette.text.menuTitle}>
                    {UIStrings.Assessment}
                </Typography>
                <Divider orientation="horizontal" color={theme.palette.border.main} variant="fullWidth" flexItem />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={0}
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <TestIncluded
                        hasPersonality={hasPersonality}
                        hasAptitude={hasAptitude}
                        hasSkills={hasSkills}
                        hasEmotionalIntelligence={hasEmotionalIntelligence}
                    />
                    <StatusCounter status={UIStrings.Opened} count={openedCount} />
                    <StatusCounter status={UIStrings.Ongoing} count={ongoingCount} />
                    <StatusCounter status={UIStrings.Completed} count={completedCount} />
                </Stack>
            </Stack>
        </Paper>
    );
}

export default AssessmentInfo;
