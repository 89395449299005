import { useTheme } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { nameof } from 'ts-simple-nameof';
import { useAccountSettingsContext } from '../../../Context/AccountSettingsContext';
import { useNotificationContext } from '../../../Context/NotificationContext';
import { Notifications } from '../../../Models/Configuration';
import UIStrings from '../../../Utils/UIStrings';
import LabeledFormikSwitch from './LabeledFormikSwitch';

function AccountSettingsNotifications(): JSX.Element {
    const theme = useTheme();
    const { showSuccessToaster, showFailToaster, setOpen } = useNotificationContext();
    const { accountSettings, patchNotificationsAsync } = useAccountSettingsContext();
    const { notifications } = accountSettings;
    const { sendNotificationsOnAssessmentComplete } = notifications;

    const switchChangeHandler = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        try {
            setOpen(false);
            await patchNotificationsAsync(event.target.name as keyof Notifications, checked);
            showSuccessToaster(UIStrings.NotificationsSettingsHasBeenUpdatedSuccessfully);
        } catch {
            showFailToaster();
        }
    };

    return (
        <Stack direction="column" spacing={theme.spacing(3)}>
            <Typography variant="h3" color={theme.palette.text.primary}>
                {UIStrings.AssessmentNotifications}
            </Typography>
            <Stack direction="column" spacing={theme.spacing(2)}>
                <Typography variant="h5" color={theme.palette.text.primary}>
                    {`${UIStrings.EmailMe}:`}
                </Typography>
                <LabeledFormikSwitch
                    label={UIStrings.WhenApplicantSubmitsAssessment}
                    name={nameof<Notifications>((x) => x.sendNotificationsOnAssessmentComplete)}
                    onChange={switchChangeHandler}
                    checked={sendNotificationsOnAssessmentComplete}
                />
            </Stack>
        </Stack>
    );
}

export default AccountSettingsNotifications;
