import { Auth0ProviderOptions, CacheLocation } from '@auth0/auth0-react';

export const assessmentsServerURL = process.env.REACT_APP_API_SERVER;
export const assessmentsApiUrl = `${assessmentsServerURL}/api/`;
export const resourceBaseUrl = process.env.REACT_APP_RESOURCE_BASE_URL;
export const onBoardVideoSource =
    'https://www.youtube.com/embed/wKGKMepMKYY?autoplay=1&rel=0&modestbranding=1&showinfo=1';

export const hubspotFormApiUrl = 'https://api.hsforms.com/';
export const hubspotPortalId = process.env.REACT_APP_PORTAL_ID ?? '';
export const createContactFormId = process.env.REACT_APP_CREATE_CONTACT_FORM_ID ?? '';
export const jobsBaseUrl = process.env.REACT_APP_JOBS_BASE_URL ?? '';

export const tokenStorage = (process.env.REACT_APP_TOKEN_STORAGE as CacheLocation) || 'memory';

export const siteUrls = {
    termsAndConditionsUrl: process.env.REACT_APP_TERMS ?? 'https://talentmesh.com/terms-and-conditions',
    privacyPolicyUrl: process.env.REACT_APP_PRIVACY ?? 'https://talentmesh.com/privacy',
    contactSupportUrl: process.env.REACT_APP_CONTACT ?? 'https://talentmesh.com/contact',
    homePageUrl: process.env.REACT_APP_HOME ?? 'https://talentmesh.com',
};

export const stripeConfig = {
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? null,
};

export const authProviderSettings: Auth0ProviderOptions = {
    domain: process.env.REACT_APP_AUTH_DOMAIN ?? '',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
    redirectUri: window.location.origin,
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? '',
    connection_hint: 'External-Accounts',
};

export const ApplicationInsightsSettings = {
    connectionString: process.env.REACT_APP_AI_CONNECTION_STRING ?? null,
    cloudRole: 'Recruiter App',
};

export const marketing = {
    gtm_container_id: process.env.REACT_APP_GTM_CONTAINER_ID ?? null,
};

export const emailRegExp =
    "^((([!#$%&'*+\\-/=?^_`{|}~\\w])|([!#$%&'*+\\-/=?^_`{|}~\\w][!#$%&'*+\\-/=?^_`{|}~\\.\\w]{0,}[!#$%&'*+\\-/=?^_`{|}~\\w]))[@]\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*)$";

export const calendlyOnboardingUrl = 'https://calendly.com/talentmesh/onboarding';
export const calendlyJobBoardSetUpUrl = 'https://calendly.com/talentmesh/free-job-board-set-up';
