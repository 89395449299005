import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../Utils/UIStrings';
import { resourceBaseUrl } from '../../../../AppSettings';

export interface JobBoardInstructionStepProps {
    stepLabel: string;
    stepIcon: 'seamless-connect' | 'two-minute-step' | 'routed-connection';
}

const JobBoardInstructionStep = ({ stepLabel, stepIcon }: JobBoardInstructionStepProps): JSX.Element => {
    const theme = useTheme();
    const iconSize = 66;
    const iconSrc = `${resourceBaseUrl}/icons/${stepIcon}.svg`;

    return (
        <Stack
            direction="row"
            spacing={2.5}
            sx={{
                padding: theme.spacing(2.5),
                borderRadius: theme.shape.smallBorderRadius,
                border: `1px solid ${theme.palette.border.main}`,
                alignItems: 'center',
                height: theme.spacing(14),
            }}
        >
            <img
                src={iconSrc}
                alt={UIStrings.Icon}
                width={iconSize}
                height={iconSize}
                style={{ minWidth: theme.spacing(8.25) }}
            />
            <Typography variant="body1" color="secondary">
                {stepLabel}
            </Typography>
        </Stack>
    );
};

export default JobBoardInstructionStep;
