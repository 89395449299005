import { theme, Stack, Divider } from '@talentmesh/core';
import React from 'react';
import CareerPageIntegration from '../../../../Components/CareerPageIntegration';
import CareerPageStatus from './CareerPageStatus';
import CareerPageToggle from './CareerPageToggle';
import JobAdStatus from './JobAdStatus';
import SetupCareerPageDialog from './SetupCareerPageDialog';

function CareerPageTabContent(): JSX.Element {
    return (
        <Stack direction="column" spacing={theme.spacing(3)}>
            <CareerPageToggle />
            <CareerPageStatus />
            <JobAdStatus />
            <Divider
                sx={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                }}
            />
            <CareerPageIntegration />
            <SetupCareerPageDialog />
        </Stack>
    );
}

export default CareerPageTabContent;
