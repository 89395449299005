import React, { useEffect, useMemo, useState } from 'react';
import { TestTypes } from '../../../../../../../../../../../../Models/Configuration';
import { LikertOption, Question, QuestionType } from '../../../../../../../../../Models/Questions';
import { Pagination } from './DialogHeader';
import PreviewQuestionsDialog from './PreviewQuestionsDialog';

export interface PreviewQuestionsProps {
    likertOptions?: LikertOption[];
    demoQuestions?: Question[];
    testType: TestTypes;
}

const usePreviewQuestions = ({ testType, likertOptions, demoQuestions }: PreviewQuestionsProps) => {
    const [open, setOpen] = useState(false);
    const [pagination, setPagination] = useState<Pagination>({ index: 0, total: 0 });
    const questionType = useMemo(() => demoQuestions && demoQuestions[0]?.questionType, [demoQuestions]);
    const isAnswersType = questionType === QuestionType.Choice || questionType === QuestionType.Exact;

    useEffect(() => {
        // reset when open
        if (open) {
            setPagination((p) => ({ ...p, index: 0 }));
        }
    }, [open, setPagination]);

    useEffect(() => {
        if (isAnswersType) {
            setPagination((p) => ({ ...p, total: demoQuestions?.length || 0 }));
        }
    }, [isAnswersType, pagination.index]);

    const toggleDialog = () => {
        setOpen(!open);
    };
    const nextPage = () => setPagination((p) => ({ ...p, index: p.index + 1 }));
    const previousPage = () => setPagination((p) => ({ ...p, index: p.index - 1 }));

    return {
        dialog: (
            <PreviewQuestionsDialog
                onNext={nextPage}
                onPrevious={previousPage}
                onClose={toggleDialog}
                open={open}
                pagination={pagination}
                questionType={questionType}
                testType={testType}
                demoQuestions={demoQuestions}
                likertOptions={likertOptions}
            />
        ),
        toggleDialog,
        isPreviewQuestionsLoading: !demoQuestions || !likertOptions,
    };
};

export default usePreviewQuestions;
