import React from 'react';
import { ScoreItem, Stack } from '@talentmesh/core';
import { TestTypes } from '../../../../../Models/Configuration';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import BenchmarkPanel from './Components/Benchmark/BenchmarkPanel';
import ScoreOverview from './Components/ScoreOverview';
import UIStrings from '../../../../../Utils/UIStrings';

function AptitudeTab(): JSX.Element | null {
    const { results } = useCandidatesResultsContext();
    const { aptitudeScore, candidateFirstName } = results;

    if (!aptitudeScore) {
        return null;
    }

    const { percentileScore, percentileLevel } = aptitudeScore;

    const overall: ScoreItem = {
        level: percentileLevel,
        name: UIStrings.OverallScore,
        value: percentileScore,
    };

    const scores: ScoreItem[] = aptitudeScore.scores.map((score) => {
        return {
            level: score.percentileLevel,
            name: score.questionTopicName,
            value: score.percentileScore,
        };
    });

    return (
        <Stack spacing={2.5} sx={{ marginBottom: 2.5 }}>
            <ScoreOverview
                scoreOverviewTitle={UIStrings.AptitudeScoreOverview}
                scoreOverviewDescription={UIStrings.TheOverallScoreIsCalculatedBasedOnAptitude}
                candidateFirstName={candidateFirstName}
                scores={scores}
                overall={overall}
            />
            <BenchmarkPanel
                candidateFirstName={candidateFirstName}
                testType={TestTypes.Aptitude}
                candidateScore={aptitudeScore}
            />
        </Stack>
    );
}

export default AptitudeTab;
