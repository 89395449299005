import React from 'react';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';
import canSelectCandidate from '../../Utils/CanSelectCandidate';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import ATSCheckbox from '../ATSCheckbox/ATSCheckbox';

interface CheckAllProps {
    tabValue: string;
}

function CheckAll({ tabValue }: CheckAllProps): JSX.Element {
    const { candidates, selected, setSelected } = useCandidatesOverviewContext();
    return (
        <ATSCheckbox
            indeterminate={selected.length > 0 && selected.length !== candidates.length}
            checked={selected.length > 0 && selected.length === candidates.length}
            disabled={tabValue !== 'Open'}
            onChange={(event) => {
                const { checked } = event.target;

                let nextState: CandidateOverview[] = [];
                if (checked && selected.length === 0) {
                    nextState = candidates.filter(canSelectCandidate);
                }
                setSelected(nextState);
            }}
            data-cy="selectAll"
        />
    );
}

export default CheckAll;
