import React from 'react';
import { useTheme } from '@mui/material';
import { Link, LinkProps } from '@talentmesh/core';

function ContentLink(props: LinkProps): JSX.Element {
    const theme = useTheme();

    return (
        <Link
            variant="body2"
            {...props}
            sx={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
            }}
        />
    );
}

export default ContentLink;
