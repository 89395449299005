import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { getSnapshotCounterString, getSnapshotDateString } from '../../Utils/ResultsUtils';

export interface SnapshotMetadataProps {
    current: number;
    total: number;
    date: Date;
}

function SnapshotMetadata({ current, total, date }: SnapshotMetadataProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="column" spacing={theme.spacing(0.5)}>
            <Typography variant="body1" color={theme.palette.secondary.main}>
                {getSnapshotCounterString(current, total)}
            </Typography>
            <Typography variant="caption">{getSnapshotDateString(date)}</Typography>
        </Stack>
    );
}

export default SnapshotMetadata;
