import React from 'react';
import { ErrorPage } from '@talentmesh/core';
import { resourceBaseUrl } from '../../AppSettings';
import NotFoundPageInfo from '../../Components/NotFoundPageInfo';
import UIStrings from '../../Utils/UIStrings';

function NotFoundPageContainer(): JSX.Element {
    return (
        <ErrorPage
            imageUrl={`${resourceBaseUrl}/general/404.svg`}
            imageAlt={UIStrings.PageNotFound}
            imageHeight="100%"
            imageWidth="100%"
        >
            <NotFoundPageInfo />
        </ErrorPage>
    );
}

export default NotFoundPageContainer;
