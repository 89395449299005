import React, { memo } from 'react';
import { CheckboxMenuItems } from './CheckboxRow';
import ListVirtualization from './ListVirtualization';
import BaseFilterPanelContent, { BaseFilterPanelContentProps } from './BaseFilterPanelContent';

export interface FilterPanelProps extends Omit<BaseFilterPanelContentProps, 'children'> {
    menuItems: CheckboxMenuItems;
}

function FilterPanel({ menuItems, resetCallback }: FilterPanelProps): JSX.Element {
    const rowHeight = 42;
    const maxVisibleRows = 6;
    const selectedVisibleRows = menuItems.length < maxVisibleRows ? menuItems.length : maxVisibleRows;
    const totalRowHeight = rowHeight * selectedVisibleRows + 20;

    return (
        <BaseFilterPanelContent resetCallback={resetCallback}>
            <ListVirtualization items={menuItems} containerHeight={totalRowHeight} itemHeight={rowHeight} />
        </BaseFilterPanelContent>
    );
}

export default memo(FilterPanel);
