import React from 'react';
import { PercentileLevel, TMCircularProgress } from '@talentmesh/core';

interface ResultsTalentScoreCircularProgressProps {
    level: PercentileLevel;
    progress?: number;
}

function ResultsTalentScoreCircularProgress({ level, progress }: ResultsTalentScoreCircularProgressProps): JSX.Element {
    return (
        <TMCircularProgress
            level={level}
            progress={progress}
            size={50}
            thickness={3}
            typographyProps={{ variant: 'h6' }}
        />
    );
}

export default ResultsTalentScoreCircularProgress;
