import { useTheme } from '@mui/material';
import React from 'react';
import { CameraAltOutlined } from '@talentmesh/icons';
import UIStrings from '../../../../../Utils/UIStrings';
import CTMonitoringFunctionalityDisabled from './CTMonitoringFunctionalityDisabled';

function CTMonitoringWebcamStub(): JSX.Element {
    const theme = useTheme();
    return (
        <CTMonitoringFunctionalityDisabled
            icon={<CameraAltOutlined sx={{ color: theme.palette.text.disabled }} />}
            message={UIStrings.WebcamDisabled}
            height={theme.spacing(25)}
        />
    );
}
export default CTMonitoringWebcamStub;
