import React from 'react';
import { useTheme } from '@mui/material/styles';
import { SquareChip } from '@talentmesh/core';
import UIStrings from '../Utils/UIStrings';

function InternalBenchmarkBadge(): JSX.Element {
    const theme = useTheme();
    return (
        <SquareChip
            sx={{
                backgroundColor: theme.palette.hue.primary.h700,
                borderRadius: theme.shape.smallBorderRadius,
                color: theme.palette.common.white,
            }}
            label={UIStrings.InternalBenchmark}
        />
    );
}
export default InternalBenchmarkBadge;
