import { useAuth0 } from '@auth0/auth0-react';
import { useNotificationContext } from '../../../Context/NotificationContext';
import UIStrings from '../../../Utils/UIStrings';
import { useUserSignupNavigationContext } from '../../../Context/UserSignupNavigationContext';

interface UseVerifiedOnMobileInstructionsInterface {
    proceedToProductHandler: () => Promise<void>;
}

function useVerifiedOnMobileInstructions(): UseVerifiedOnMobileInstructionsInterface {
    const { getIdTokenClaims } = useAuth0();
    const { showFailToaster } = useNotificationContext();
    const { navigateToNextPage, refreshTokenAsync } = useUserSignupNavigationContext();

    const proceedToProductHandler = async () => {
        await refreshTokenAsync();
        const claims = await getIdTokenClaims();
        if (!claims?.email_verified) {
            showFailToaster('', UIStrings.ItSeemsLikeEmailWasNotVerified);
            return;
        }
        navigateToNextPage();
    };

    return { proceedToProductHandler };
}

export default useVerifiedOnMobileInstructions;
