import { CandidateInviteDTO } from './CandidateInviteDTO';

export interface InvitedCandidatesDTO {
    candidates: Array<CandidateInviteDTO>;
    openLink: string;
}

export const DefaultInvitedCandidatesDTO: InvitedCandidatesDTO = {
    candidates: [],
    openLink: '',
};
