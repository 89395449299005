import { InputBaseComponentProps } from '@mui/material/InputBase';
import * as React from 'react';

type StripeInputProps = InputBaseComponentProps & {
    component: React.FunctionComponent<InputBaseComponentProps>;
    options: Record<string, unknown>;
};

const StripeInput = React.forwardRef<any, StripeInputProps>(function StripeInput(props, ref) {
    const { component: Component, options, ...other } = props;
    const [mountNode, setMountNode] = React.useState<any | null>(null);

    React.useImperativeHandle(
        ref,
        () => ({
            focus: () => mountNode.focus(),
        }),
        [mountNode],
    );

    return (
        <Component
            onReady={setMountNode}
            options={{
                ...options,
            }}
            {...other}
        />
    );
});

export default StripeInput;
