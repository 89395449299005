import { useTheme } from '@mui/material';
import { Typography } from '@talentmesh/core';
import React from 'react';

interface HeaderProps {
    header: string;
}

function Header({ header }: HeaderProps): JSX.Element {
    const { palette } = useTheme();
    return (
        <Typography variant="caption" color={palette.text.menuTitle}>
            {header}
        </Typography>
    );
}

export default Header;
