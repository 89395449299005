import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack, PercentileLevel, TMCircularProgress } from '@talentmesh/core';
import ScoreConnector from '../../../Candidates/Overview/Components/Cells/ScoreConnector';

interface TestScoreProps {
    label: string;
    scoreLevel?: PercentileLevel;
    scoreValue?: number;
    talentScore?: boolean;
}

const MainCircleSize = 96;
const RegularCircleSize = 54;
const ConnectorLength = 36;

function TestScore({ label, scoreLevel, scoreValue, talentScore }: TestScoreProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            alignItems="flex-end"
            spacing={1.25}
            marginTop={talentScore ? 0 : `${MainCircleSize / 2 - RegularCircleSize / 2}px`}
        >
            <Stack direction="row">
                <Stack width={talentScore ? 0 : ConnectorLength}>{!talentScore && <ScoreConnector />}</Stack>
                <Stack width={talentScore ? MainCircleSize : RegularCircleSize}>
                    <TMCircularProgress
                        thickness={talentScore ? 3 : 4}
                        size={talentScore ? MainCircleSize : RegularCircleSize}
                        level={scoreLevel}
                        progress={scoreValue}
                        typographyProps={{ variant: 'h4' }}
                    />
                </Stack>
            </Stack>
            <Stack
                alignItems="center"
                sx={{
                    marginLeft: talentScore ? 0 : `${ConnectorLength}px`,
                    width: talentScore ? `${MainCircleSize}px` : `${RegularCircleSize}px`,
                }}
            >
                <Typography variant="h6" sx={{ textAlign: 'center', color: theme.palette.text.menuTitle }}>
                    {label}
                </Typography>
            </Stack>
        </Stack>
    );
}

export default TestScore;
