import { useTheme } from '@mui/material';
import React from 'react';
import BodyTypography from '../../../../Common/BodyTypography';
import GridRowNode from '../Common/GridRowNode';
import StyledTextareaAutosize from './StyledTextareaAutosize';

interface ReasonItemProps {
    title: string;
    value: string;
}
function ReasonItem({ title, value }: ReasonItemProps): JSX.Element {
    const theme = useTheme();

    return (
        <GridRowNode
            title={<BodyTypography color={theme.palette.text.secondary}>{title}:</BodyTypography>}
            node={<StyledTextareaAutosize defaultValue={value} />}
        />
    );
}

export default ReasonItem;
