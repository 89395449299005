import { useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { Stack } from '@talentmesh/core';
import { informationModel2FormValues } from './RequestInformationStepUtils';
import { useCreateRecruitmentContext } from '../../Create/Contexts/CreateRecruitmentContext';
import StepProps from '../StepProps';
import RequestInformationStepBody from './Components/RequestInformationStepBody';
import RequestInformationStepHeader from './Components/RequestInformationStepHeader';
import RequestInformationStepNavigation from './Components/RequestInformationStepNavigation';

const RequestInformationStep = ({ activeStep, handleNext, handleBack }: StepProps): JSX.Element => {
    const theme = useTheme();
    const { applicantInformation } = useCreateRecruitmentContext();

    const initialValues = informationModel2FormValues(applicantInformation);

    const submitHandlerAsync = async () => {
        if (handleNext) {
            handleNext();
        }
    };

    return (
        <Stack width={theme.spacing(105.75)} alignSelf="center">
            <RequestInformationStepHeader />
            <Formik initialValues={initialValues} onSubmit={submitHandlerAsync}>
                <Form>
                    <RequestInformationStepBody />
                    <RequestInformationStepNavigation activeStep={activeStep} handleBack={handleBack} />
                </Form>
            </Formik>
        </Stack>
    );
};

export default RequestInformationStep;
