import React from 'react';
import { useTheme } from '@mui/material/styles';
import { JobAdPage } from '@talentmesh/job-ad';
import { AutocompleteOption, Dialog, DialogContent, DialogTitle, IconButton } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import { JobAdSkill, JobAdSkillType } from '@talentmesh/job-ad/dist/cjs/Models/JobAdSkill';
import PreviewDisclaimer from './PreviewDisclaimer';
import { JobAd } from '../../Pages/Assessments/Modify/Models/JobAd';
import { JobDetails } from '../../Pages/Assessments/Modify/Models/JobDetails';
import { siteUrls } from '../../AppSettings';
import { useCompanyContext } from '../../Context/CompanyContext';
import { useAssessmentSettingsContext } from '../../Context/AssessmentSettingsContext';
import { getJobCategoryJobFunctionPair } from '../../Utils/JobFunctionSelectionUtils';

interface JobAdPreviewDialogProps {
    open: boolean;
    handleClose: () => void;
    jobAd: JobAd;
    jobDetails: JobDetails;
}

function JobAdPagePreviewDialog({ open, handleClose, jobAd, jobDetails }: JobAdPreviewDialogProps): JSX.Element {
    const theme = useTheme();
    const { currentCompanyProfile } = useCompanyContext();
    const { assessmentSettings } = useAssessmentSettingsContext();
    const { companyName, companyIconURL } = currentCompanyProfile;

    const skills = [] as JobAdSkill[];
    const mapSkills = (input: AutocompleteOption[], type: JobAdSkillType) => {
        return input.map(
            (item) =>
                ({
                    skillName: item.label,
                    skillType: type,
                }) as JobAdSkill,
        );
    };

    if (jobAd.softSkillsSwitch) {
        skills.push(...mapSkills(jobAd.softSkills, 'SoftSkill'));
    }
    if (jobAd.hardSkillsSwitch) {
        skills.push(...mapSkills(jobAd.hardSkills, 'HardSkill'));
    }

    const previewjobAd = {
        ...jobAd,
        recruitmentId: '',
        companyName,
        companyIcon: companyIconURL,
        isAvailable: false,
        jobDetails: {
            ...jobDetails,
            jobFunctionName: getJobCategoryJobFunctionPair(assessmentSettings.jobCategories, jobDetails.jobFunctionId)
                .jobFunctionName,
            locationDetails: jobDetails.locationDetails,
            salary: jobDetails.doNotDisplaySalary
                ? undefined
                : {
                      yearlySalaryFrom: +jobDetails.yearlySalaryFrom, // + is used to convert string to number
                      yearlySalaryTo: +jobDetails.yearlySalaryTo,
                      currency: jobDetails.currency.id || '',
                  },
        },
        jobAdTitle: jobAd.title,
        jobAdDescription: jobAd.description,
        tests: [], // we do not show test information in preview
        minutesToComplete: 0, // we do not minutes to complete information in preview
        skills,
    };

    const settings = {
        preview: true,
        platformUrl: '', // apply button is not shown in preview
        websiteUrl: siteUrls.homePageUrl,
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            sx={{
                padding: 0,
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: 0,
                }}
            >
                <PreviewDisclaimer />
                <IconButton edge="end" color="inherit" onClick={handleClose} data-cy="ClosePreviewDialogButton">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    marginTop: theme.spacing(5),
                }}
            >
                <JobAdPage
                    jobAd={previewjobAd}
                    settings={{
                        ...settings,
                        preview: true,
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}

export default JobAdPagePreviewDialog;
