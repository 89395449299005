import { JobAdLanguage } from '../../Models/JobAdLanguage';

export const defaultLanguage: JobAdLanguage = {
    id: 'en',
    label: 'English',
};

export const isDefaultLanguage = (language: JobAdLanguage): boolean => {
    return language.id === defaultLanguage.id;
};

export const isDefaultLanguageByShortCode = (shortCode: string): boolean => {
    return shortCode === defaultLanguage.id;
};

export const languages: JobAdLanguage[] = [
    {
        id: 'sq',
        label: 'Albanian',
    },
    {
        id: 'am',
        label: 'Amharic',
    },
    {
        id: 'ar',
        label: 'Arabic',
    },
    {
        id: 'hy',
        label: 'Armenian',
    },
    {
        id: 'bn',
        label: 'Bengali',
    },
    {
        id: 'bs',
        label: 'Bosnian',
    },
    {
        id: 'bg',
        label: 'Bulgarian',
    },
    {
        id: 'my',
        label: 'Burmese',
    },
    {
        id: 'ca',
        label: 'Catalan',
    },
    {
        id: 'zh',
        label: 'Chinese',
    },
    {
        id: 'hr',
        label: 'Croatian',
    },
    {
        id: 'cs',
        label: 'Czech',
    },
    {
        id: 'da',
        label: 'Danish',
    },
    {
        id: 'nl',
        label: 'Dutch',
    },
    {
        id: 'en',
        label: 'English',
    },
    {
        id: 'et',
        label: 'Estonian',
    },
    {
        id: 'fi',
        label: 'Finnish',
    },
    {
        id: 'fr',
        label: 'French',
    },
    {
        id: 'ka',
        label: 'Georgian',
    },
    {
        id: 'de',
        label: 'German',
    },
    {
        id: 'el',
        label: 'Greek',
    },
    {
        id: 'gu',
        label: 'Gujarati',
    },
    {
        id: 'hi',
        label: 'Hindi',
    },
    {
        id: 'hu',
        label: 'Hungarian',
    },
    {
        id: 'is',
        label: 'Icelandic',
    },
    {
        id: 'id',
        label: 'Indonesian',
    },
    {
        id: 'it',
        label: 'Italian',
    },
    {
        id: 'ja',
        label: 'Japanese',
    },
    {
        id: 'kn',
        label: 'Kannada',
    },
    {
        id: 'kk',
        label: 'Kazakh',
    },
    {
        id: 'ko',
        label: 'Korean',
    },
    {
        id: 'lv',
        label: 'Latvian',
    },
    {
        id: 'lt',
        label: 'Lithuanian',
    },
    {
        id: 'mk',
        label: 'Macedonian',
    },
    {
        id: 'ms',
        label: 'Malay',
    },
    {
        id: 'ml',
        label: 'Malayalam',
    },
    {
        id: 'mr',
        label: 'Marathi',
    },
    {
        id: 'mn',
        label: 'Mongolian',
    },
    {
        id: 'no',
        label: 'Norwegian',
    },
    {
        id: 'fa',
        label: 'Persian',
    },
    {
        id: 'pl',
        label: 'Polish',
    },
    {
        id: 'pt',
        label: 'Portuguese',
    },
    {
        id: 'pa',
        label: 'Punjabi',
    },
    {
        id: 'ro',
        label: 'Romanian',
    },
    {
        id: 'ru',
        label: 'Russian',
    },
    {
        id: 'sr',
        label: 'Serbian',
    },
    {
        id: 'sk',
        label: 'Slovak',
    },
    {
        id: 'sl',
        label: 'Slovenian',
    },
    {
        id: 'so',
        label: 'Somali',
    },
    {
        id: 'es',
        label: 'Spanish',
    },
    {
        id: 'sw',
        label: 'Swahili',
    },
    {
        id: 'sv',
        label: 'Swedish',
    },
    {
        id: 'tl',
        label: 'Tagalog',
    },
    {
        id: 'ta',
        label: 'Tamil',
    },
    {
        id: 'te',
        label: 'Telugu',
    },
    {
        id: 'th',
        label: 'Thai',
    },
    {
        id: 'tr',
        label: 'Turkish',
    },
    {
        id: 'uk',
        label: 'Ukrainian',
    },
    {
        id: 'ur',
        label: 'Urdu',
    },
    {
        id: 'vi',
        label: 'Vietlabelse',
    },
];
