import { useTheme } from '@mui/material';
import { Grid, Stack } from '@talentmesh/core';
import { Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { generatePath, useNavigate } from 'react-router';
import RoutePath from '../../../../../Routing/RoutePath';
import StepProps from '../StepProps';
import JobAdExplanation from './Components/JobAdExplanation';
import JobAdStepNavigation from './Components/JobAdStepNavigation';
import JobAdStepHeader from './Components/JobAdStepHeader';
import JobAdTitle from './Components/JobAdTitle';
import JobDescription from './Components/JobDescription';
import RequiredSkills from './Components/RequiredSkills';
import {
    JobAdFormValues,
    fillInInitialSkills,
    jobAdFormValues2Model,
    jobAdModel2FormValues,
    jobAdValidationScheme,
} from './JobAdStepUtils';
import { useNotificationContext } from '../../../../../Context/NotificationContext';
import UIStrings from '../../../../../Utils/UIStrings';
import JobAdCompanyDescription from './Components/JobAdCompanyDescription';
import useCompanyId from '../../../../../Hooks/UseCompanyId';
import { useEditRecruitmentContext } from '../../Edit/Contexts/EditRecruitmentContext';

const EditJobAdStep = ({ activeStep, handleBack }: StepProps): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const companyId = useCompanyId();
    const ref = useRef<FormikProps<JobAdFormValues>>(null);
    const { jobAd, setJobAd, jobDetails, client, updateRecruitmentAsync, processingState } =
        useEditRecruitmentContext();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    let initialValues: JobAdFormValues = jobAdModel2FormValues(jobAd);
    initialValues = fillInInitialSkills(initialValues, ref.current?.values || jobAd);

    const submitHandlerAsync = async (values: JobAdFormValues) => {
        const { status: currentStatus } = jobAd;
        try {
            await updateRecruitmentAsync({ ...values, status: currentStatus });
            showSuccessToaster(UIStrings.RecruitmentUpdated);

            // navigate to client recruitment overview.
            const createRecruitmentPath = generatePath(RoutePath.AssessmentsActive, { companyId, clientId: client.id });
            navigate(createRecruitmentPath);
        } catch {
            showFailToaster();
        }
    };

    const handleBackWrapper = () => {
        const values = ref.current?.values || jobAd;
        setJobAd(jobAdFormValues2Model(values));
        if (handleBack) {
            handleBack();
        }
    };

    return (
        <Grid container columns={14}>
            <Grid item xs={2} />
            <Grid item xs={10}>
                <JobAdStepHeader />
                <Formik
                    initialValues={initialValues}
                    onSubmit={submitHandlerAsync}
                    validationSchema={jobAdValidationScheme}
                    enableReinitialize
                    innerRef={ref}
                >
                    <Form>
                        <Stack
                            spacing={theme.spacing(4.375)}
                            px={theme.spacing(2.5)}
                            py={theme.spacing(3.75)}
                            sx={{
                                backgroundColor: theme.palette.background.aliciaBlue,
                                borderRadius: theme.shape.smallBorderRadius,
                                border: `1px solid ${theme.palette.border.main}`,
                            }}
                        >
                            <JobAdExplanation />
                            <JobAdTitle />
                            <RequiredSkills />
                            <JobDescription initialHtml={initialValues.description} processingState={processingState} />
                            <JobAdCompanyDescription
                                initialHtml={initialValues.companyDescription}
                                processingState={processingState}
                            />
                        </Stack>
                        <JobAdStepNavigation
                            activeStep={activeStep}
                            handleBack={handleBackWrapper}
                            processingState={processingState}
                            jobAd={jobAd}
                            setJobAd={setJobAd}
                            jobDetails={jobDetails}
                            nextButtonLabel={UIStrings.Update}
                        />
                    </Form>
                </Formik>
            </Grid>
            <Grid item xs={2} />
        </Grid>
    );
};

export default EditJobAdStep;
