import { useState, useEffect } from 'react';
import { GridPaginationModel } from '@talentmesh/core';
import { TeamMember } from '../../../../../Models/TeamMember';
import { ConfirmationDialogsTypes, TeamMembersContextAPI, TeamMembersContextState } from './TeamMembersContext';
import PaginationConsts from '../../../../../Utils/PaginationConsts';
import { useInvitationClient, useUsersClient } from '../../../../../Hooks/ClientHooks';
import InviteMemberDTO from '../../../../../DTO/Membership/InviteMemberDTO';
import { RecruiterRoles } from '../../../../../Models/RecruiterRoles';
import { useNotificationContext } from '../../../../../Context/NotificationContext';
import UIStrings from '../../../../../Utils/UIStrings';
import useCompanyId from '../../../../../Hooks/UseCompanyId';

function useTeamMembersContextValue(): TeamMembersContextAPI {
    const [state, setState] = useState<TeamMembersContextState>({
        teamMembers: [],
        isLoading: true,
        search: '',
        isTabLoadingError: false,
        isOperationLoading: false,
        pageNumber: 0,
        totalMembersCount: 0,
        pageSize: PaginationConsts.regularPageSize,
    });
    const { showFailToaster, showSuccessToaster } = useNotificationContext();
    const invitationClient = useInvitationClient();
    const usersClient = useUsersClient();
    const companyId = useCompanyId();
    const {
        search,
        isLoading,
        teamMembers,
        isTabLoadingError,
        isOperationLoading,
        totalMembersCount,
        pageNumber,
        pageSize,
        activeDialog,
        selectedMember,
    } = state;

    useEffect(() => {
        const doLoadPage = async () => {
            await loadPage();
        };
        doLoadPage();
    }, [search, pageNumber, pageSize]);

    const loadPage = async () => {
        setState((prev) => ({ ...prev, isOperationLoading: true }));

        try {
            const response = await usersClient.getMembersAsync(companyId, pageNumber * pageSize, pageSize, search);
            setState((prev) => ({
                ...prev,
                teamMembers: [...response.data],
                totalMembersCount: response.pagination.totalCount,
                isTabLoadingError: false,
                isLoading: false,
                isOperationLoading: false,
            }));
        } catch (error) {
            showFailToaster();
            setState((prev) => ({ ...prev, isTabLoadingError: false, isOperationLoading: false }));
        }
    };

    const sendInvitation = async (email: string, role: RecruiterRoles): Promise<void> => {
        try {
            const dto: InviteMemberDTO = {
                email,
                role,
                companyId,
            };
            setState((prev) => ({ ...prev, isOperationLoading: true }));
            await invitationClient.inviteMember(dto);
            await loadPage();
        } finally {
            setState((prev) => ({ ...prev, isOperationLoading: false }));
        }
    };

    const removeMember = async (member: TeamMember): Promise<void> => {
        await usersClient.removeMemberAsync(member.id);
        await loadPage();
    };

    const resendInvitation = async (memberId: string): Promise<void> => {
        try {
            const localMember = teamMembers.find((item) => item.id === memberId)!;
            await invitationClient.resendInvitation(localMember.id);
            showSuccessToaster(UIStrings.InvitationHasBeenResend(localMember.email));
        } catch (error) {
            showFailToaster();
        }
    };

    const revokeInvitation = async (member: TeamMember): Promise<void> => {
        await invitationClient.revokeInvitation(member.id);
        await loadPage();
    };

    const searchTeamMember = (query: string) => {
        setState((prev) => ({
            ...prev,
            search: query,
        }));
    };

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setState((prev) => ({
            ...prev,
            pageNumber: model.page,
            pageSize: model.pageSize,
        }));
    };

    const openDialog = (memberId: string, dialog: ConfirmationDialogsTypes) => {
        setState((prev) => ({
            ...prev,
            activeDialog: dialog,
            selectedMember: teamMembers.find((member) => member.id === memberId),
        }));
    };

    const closeDialog = () => {
        setState((prev) => ({
            ...prev,
            activeDialog: undefined,
        }));
    };

    return {
        teamMembers,
        isLoading,
        search,
        isTabLoadingError,
        isOperationLoading,
        loadPage,
        searchTeamMember,
        removeMember,
        sendInvitation,
        resendInvitation,
        revokeInvitation,
        totalMembersCount,
        pageNumber,
        handlePaginationModelChange,
        activeDialog,
        openDialog,
        closeDialog,
        selectedMember,
    };
}

export default useTeamMembersContextValue;
