import { useTheme } from '@mui/material';
import React from 'react';
import { Box, SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { useActionDialogContext } from '../../Context/ActionDialogContext';

function HireDialogBody(): JSX.Element {
    const theme = useTheme();
    const { selectedCandidates } = useActionDialogContext();
    const { name } = selectedCandidates[0];
    return (
        <Box>
            <SpanTypography variant="body2" color={theme.palette.text.primary}>
                {`${UIStrings.HireDialogBody1} `}
            </SpanTypography>
            <SpanTypography variant="body2" color={theme.palette.text.primary}>
                {name}
            </SpanTypography>
            <SpanTypography variant="body2" color={theme.palette.text.primary}>
                {`${UIStrings.HireDialogBody2} `}
            </SpanTypography>
        </Box>
    );
}

export default HireDialogBody;
