import { Stack, Typography } from '@talentmesh/core';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import TabPaper from '../Common/TabPaper';
import H4Typography from './Components/Common/H4Typography';
import NoteRTE from './NotesTab/NoteRTE';
import noteValidationScheme from './NotesTab/noteValidationScheme';

interface NoteValues {
    noteRte: string;
}

function NotesTab(): JSX.Element {
    const { results, updateNoteAsync } = useCandidatesResultsContext();
    const { applicantProfile, note } = results;

    const [saved, setSaved] = useState<boolean>(false);
    const [errorSaving, setErrorSaving] = useState<boolean>(false);

    const initialValues: NoteValues = {
        noteRte: note ?? '',
    };

    const handleSubmit = async (values: NoteValues) => {
        let success = true;

        try {
            setSaved(false);
            setErrorSaving(false);
            await updateNoteAsync(values.noteRte);
        } catch {
            setErrorSaving(true);
            success = false;
        } finally {
            if (success) {
                setSaved(true);
            }
        }
    };

    return (
        <TabPaper data-cy="NotesTab">
            <Stack spacing={3.75}>
                <Stack spacing={0.625}>
                    <H4Typography>
                        {UIStrings.InterviewNotesAndHighlightsForApplicantName(applicantProfile.name)}
                    </H4Typography>
                    <Typography variant="body1">{UIStrings.PleaseUseThisSectionToSummarizeTheKeyPoints}</Typography>
                </Stack>
                <Formik
                    initialValues={initialValues}
                    validationSchema={noteValidationScheme}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <Form>
                        <NoteRTE saved={saved} errorSaving={errorSaving} />
                    </Form>
                </Formik>
            </Stack>
        </TabPaper>
    );
}

export default NotesTab;
