import { MenuButton } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import ImproveWithAILabel from './ImproveWithAILabel';
import useImproveWithAIMenuItems from '../Hooks/useImpoveWithAIMenuItems';
import ProcessingState from '../../../../../../Models/ProcessingState';
import { useCreateRecruitmentContext } from '../../../Create/Contexts/CreateRecruitmentContext';

function ImproveWithAIButton(): JSX.Element {
    const theme = useTheme();
    const options = useImproveWithAIMenuItems();
    const { processingState } = useCreateRecruitmentContext();
    const { isValid } = useFormikContext();
    return (
        <MenuButton
            menuItems={options}
            buttonProps={{
                variant: 'contained',
                color: 'primary',
                disabled: processingState === ProcessingState.Processing || !isValid,
                sx: {
                    width: theme.spacing(48),
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            }}
            label={<ImproveWithAILabel />}
        />
    );
}

export default ImproveWithAIButton;
