import { useTheme } from '@mui/material';
import { SquareChip } from '@talentmesh/core';
import React from 'react';
import { MembershipStatus } from '../../../../../../Models/TeamMember';
import UIStrings from '../../../../../../Utils/UIStrings';

interface StatusProps {
    status: MembershipStatus;
}

function getLabel(status: MembershipStatus): string {
    // eslint-disable-next-line default-case
    switch (status) {
        case 'Invited':
            return UIStrings.Invited;
        case 'Member':
            return UIStrings.Member;
        case 'Expired':
            return UIStrings.Expired;
    }

    throw new Error(`Unknown status: ${status}`);
}

function Status({ status }: StatusProps): JSX.Element {
    const { palette } = useTheme();

    return (
        <SquareChip
            label={getLabel(status)}
            sx={{ backgroundColor: status === 'Member' ? palette.chip.accepted : palette.grey[200] }}
        />
    );
}

export default Status;
