import { useTheme } from '@mui/material';
import React from 'react';
import { Paper, Stack, Typography } from '@talentmesh/core';

interface StatusCounterProps {
    status: string;
    count: number;
}
function StatusCounter({ status, count }: StatusCounterProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: theme.palette.background.silverSand,
                paddingTop: 1.25,
            }}
        >
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    height: '100%',
                }}
            >
                <Typography variant="caption" color={theme.palette.text.secondary}>
                    {status}
                </Typography>
                <Typography variant="h2" color={theme.palette.text.menuTitle}>
                    {count}
                </Typography>
            </Stack>
        </Paper>
    );
}

export default StatusCounter;
