/* eslint-disable max-classes-per-file */
import CouponExpiredError from './CouponExpiredError';
import CouponInvalidError from './CouponInvalidError';
import ErrorResponse from './ErrorResponse';
import HttpError from './HttpError';
import HttpErrorTypes from './HttpErrorType';
import InvalidDomainError from './InvalidDomainError';
import InvitationAcceptedError from './InvitationAcceptedError';
import InvitationExistsError from './InvitationExistsError';
import InvitationExpiredError from './InvitationExpiredError';
import MembershipExistsError from './MembershipExistsError';
import SlugExistsError from './SlugExistsError';

export default function handleHttpError(payload: ErrorResponse): HttpError {
    switch (payload.type) {
        case HttpErrorTypes.CouponIsInvalid:
            return new CouponInvalidError(
                payload.title,
                payload.status,
                payload.type,
                payload.instance,
                payload.detail,
            );

        case HttpErrorTypes.CouponExpired:
            return new CouponExpiredError(
                payload.title,
                payload.status,
                payload.type,
                payload.instance,
                payload.detail,
            );

        case HttpErrorTypes.SlugExists:
            return new SlugExistsError(payload.title, payload.status, payload.type, payload.instance, payload.detail);
        case HttpErrorTypes.MembershipExists:
            return new MembershipExistsError(
                payload.title,
                payload.status,
                payload.type,
                payload.instance,
                payload.detail,
            );
        case HttpErrorTypes.InvitationExists:
            return new InvitationExistsError(
                payload.title,
                payload.status,
                payload.type,
                payload.instance,
                payload.detail,
            );
        case HttpErrorTypes.InvalidDomain:
            return new InvalidDomainError(
                payload.title,
                payload.status,
                payload.type,
                payload.instance,
                payload.detail,
            );
        case HttpErrorTypes.InvitationExpired:
            return new InvitationExpiredError(
                payload.title,
                payload.status,
                payload.type,
                payload.instance,
                payload.detail,
            );
        case HttpErrorTypes.InvitationAccepted:
            return new InvitationAcceptedError(
                payload.title,
                payload.status,
                payload.type,
                payload.instance,
                payload.detail,
            );
        default:
            return new HttpError(payload.title, payload.status, payload.type, payload.instance, payload.detail);
    }
}
