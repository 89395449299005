import React from 'react';
import { useParams } from 'react-router';
import { Page } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import TopBar from '../../../Components/TopBars/TopBar';
import CandidatesOverviewList from './Components/CandidatesOverviewList';
import { CandidatesOverviewContext } from './Contexts/CandidatesOverviewContext';
import useCandidatesOverviewContextValue from './Contexts/UseCandidatesOverviewContextValue';
import CandidatesOverviewHeader from './Components/CandidatesOverviewHeader';
import { ActionDialogContext } from '../../Dialogs/ATS/Context/ActionDialogContext';
import useActionDialogContextValue from '../../Dialogs/ATS/Context/ActionDialogContextValue';
import withTalentMeshUserRequired from '../../../Routing/withTalentMeshUserRequired';
import withSignupChecks from '../../../Routing/withSignupChecks';
import { useAssessmentSettingsContext } from '../../../Context/AssessmentSettingsContext';
import withCompanyProfileRequired from '../../../Routing/withCompanyProfileRequired';
import CandidateOverviewDialogs from './Components/CandidateOverviewDialogs';

interface CandidatesOverviewPageParams {
    assessmentId: string;
}

const CandidatesOverviewPage = () => {
    const theme = useTheme();
    const { assessmentId } = useParams<Partial<CandidatesOverviewPageParams>>();
    const { assessmentSettings } = useAssessmentSettingsContext();

    const contextValue = useCandidatesOverviewContextValue(assessmentId!, assessmentSettings.jobCategories);
    const dialogContextValue = useActionDialogContextValue();

    return (
        <ActionDialogContext.Provider value={dialogContextValue}>
            <CandidatesOverviewContext.Provider value={contextValue}>
                <Page
                    topBar={<TopBar />}
                    header={<CandidatesOverviewHeader />}
                    sx={{ backgroundColor: theme.palette.background.aliciaBlue }}
                    containerProps={{ disableGutters: true }}
                >
                    <CandidatesOverviewList />
                    <CandidateOverviewDialogs assessmentId={assessmentId!} />
                </Page>
            </CandidatesOverviewContext.Provider>
        </ActionDialogContext.Provider>
    );
};

export default withCompanyProfileRequired(withTalentMeshUserRequired(withSignupChecks(CandidatesOverviewPage)));
