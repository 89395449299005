import React from 'react';
import { PaymentIntent as StripePaymentIntent } from '@stripe/stripe-js';
import CheckoutSection from '../Components/CheckoutSection';
import { PaymentIntent } from '../../../Models/PaymentIntent';
import UIStrings from '../../../Utils/UIStrings';
import CheckoutForm from './CheckoutForm';
import BillingInfo from '../../../Models/BillingInfo';
import PaymentMethod from '../../../Models/PaymentMethod';

interface PaymentDetailsSectionProps {
    paymentIntent: PaymentIntent | undefined;
    setResultPaymentIntent: React.Dispatch<React.SetStateAction<StripePaymentIntent | undefined>>;
    isCompensationInEdit: boolean;
    billingInfo: BillingInfo | undefined;
    savedPaymentMethod: PaymentMethod | undefined;
    setBillingInfo: React.Dispatch<React.SetStateAction<BillingInfo | undefined>>;
}

const PaymentDetailsSection = ({
    paymentIntent,
    setResultPaymentIntent,
    isCompensationInEdit,
    billingInfo,
    savedPaymentMethod,
    setBillingInfo,
}: PaymentDetailsSectionProps) => {
    return (
        <CheckoutSection orderNumber={2} title={UIStrings.PaymentDetails}>
            <CheckoutForm
                paymentIntent={paymentIntent}
                setResultPaymentIntent={setResultPaymentIntent}
                savedPaymentMethod={savedPaymentMethod}
                isCompensationInEdit={isCompensationInEdit}
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
            />
        </CheckoutSection>
    );
};

export default PaymentDetailsSection;
