import React, { useEffect } from 'react';
import { Label, Stack } from '@talentmesh/core';
import { FormikAutocompleteDropdown } from '@talentmesh/forms';
import { useFormikContext } from 'formik';
import UIStrings from '../../../../../../Utils/UIStrings';
import { JobDetailsFieldTypes, JobDetailsFormValues } from '../JobDetailsStepUtils';
import { Currency } from '../../../../../../Models/Currency';

interface CurrencyAutocompleteProps {
    currencies: Array<Currency>;
}

function CurrencyAutocomplete({ currencies }: CurrencyAutocompleteProps): JSX.Element {
    const {
        values: { doNotDisplaySalary },
        setFieldValue,
        setFieldTouched,
    } = useFormikContext<JobDetailsFormValues>();

    useEffect(() => {
        if (doNotDisplaySalary) {
            setFieldValue(JobDetailsFieldTypes.Currency, { id: '', label: '' });
        } else {
            setFieldTouched(JobDetailsFieldTypes.Currency, false);
        }
    }, [doNotDisplaySalary]);

    return (
        <Stack flex={1}>
            <Label>{`${UIStrings.Currency}*`}</Label>
            <FormikAutocompleteDropdown
                name={JobDetailsFieldTypes.Currency}
                data-cy={JobDetailsFieldTypes.Currency}
                autocompleteOptions={currencies}
                disabled={doNotDisplaySalary}
                disableClearable
                freeSolo={false}
                disableErrorMessage
            />
        </Stack>
    );
}

export default CurrencyAutocomplete;
