import React from 'react';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';

export interface InvitationPageContextState {
    specsLoading: boolean;
    jobAdIdLoading: boolean;
    jobAdId: string;
    recruitmentName: string;
    recruitmentStatus: AssessmentStatuses;
    clientId: string;
    activeTab: string;
    returnURL?: string;
    allowDialog: boolean;
}

export interface InvitationPageContextAPI extends InvitationPageContextState {
    setActiveTab: (tab: string) => void;
}

export const InvitationPageContext = React.createContext<InvitationPageContextAPI | undefined>(undefined);
export const InvitationPageContextProvider = InvitationPageContext.Provider;

export function useInvitationPageContext() {
    const context = React.useContext(InvitationPageContext);
    if (!context) {
        throw new Error('useInvitationPageContext must be used within a InvitationPageContextProvider');
    }
    return context;
}
