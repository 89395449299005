import { CreatePaymentIntentDTO } from '../../../DTO/Assessments/CreatePaymentIntentDTO';
import { Currency } from '../../../Models/Currency';

export default interface Compensation {
    annualFixedAmount: string;
    annualTargetBonus: string;
    currency: Currency;
}

export function mapCompensation2Dto(
    model: Compensation,
    recruitmentId: string | null,
    applicantId: string | null,
): CreatePaymentIntentDTO {
    const dto = {
        annualFixedAmount: parseInt(model.annualFixedAmount, 10),
        annualTargetBonus: parseInt(model.annualTargetBonus, 10),
        currency: model.currency.id,
        recruitmentId,
        applicantId,
    };

    return dto;
}

export const defaultCompensation: Compensation = {
    currency: { id: '', label: '' },
    annualFixedAmount: '',
    annualTargetBonus: '',
};
