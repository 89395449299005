import React from 'react';
import { ScoreItem, Stack } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import ScoreOverview from './Components/ScoreOverview';
import EmotionalIntelligenceScoreDetails from './EmotionalIntelligence/EmotionalIntelligenceScoreDetails';

function EmotionalIntelligenceTab(): JSX.Element | null {
    const { results } = useCandidatesResultsContext();
    const { emotionalIntelligenceScore, candidateFirstName } = results;

    if (emotionalIntelligenceScore) {
        const { percentileScore, percentileLevel } = emotionalIntelligenceScore;

        const overall: ScoreItem = {
            level: percentileLevel,
            name: UIStrings.OverallScore,
            value: percentileScore,
        };

        const scores: ScoreItem[] = emotionalIntelligenceScore.characteristics.map((characteristic) => {
            return {
                level: characteristic.percentileLevel,
                name: characteristic.characteristicName,
                value: characteristic.percentileScore,
            };
        });

        return (
            <Stack spacing={2.5} sx={{ marginBottom: 2.5 }}>
                <ScoreOverview
                    scoreOverviewTitle={UIStrings.EmotionalIntelligenceScoreOverview}
                    scoreOverviewDescription={UIStrings.TheOverallScoreIsCalculatedBasedOnEI}
                    candidateFirstName={candidateFirstName}
                    scores={scores}
                    overall={overall}
                />
                <EmotionalIntelligenceScoreDetails />
            </Stack>
        );
    }
    return null;
}

export default EmotionalIntelligenceTab;
