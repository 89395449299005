import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack, SquareChip } from '@talentmesh/core';
import { statusToColor, statusToString, statusToBackgroundColor } from '../../../Utils/Utils';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';

interface ApplicantCellProps extends Pick<CandidateOverview, 'name' | 'currentStatus'> {}

function ApplicantCell({ name, currentStatus }: ApplicantCellProps): JSX.Element {
    const theme = useTheme();
    const { status, lastUpdatedAt } = currentStatus;

    return (
        <Stack
            alignItems="start"
            sx={{
                width: '100%',
                maxWidth: '100%',
                paddingRight: theme.spacing(1.25),
            }}
            spacing={1}
        >
            <Typography
                variant="h5"
                color={theme.palette.secondary.main}
                sx={{
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {name}
            </Typography>
            <SquareChip
                label={statusToString(status)}
                sx={{
                    color: statusToColor(status, theme),
                    backgroundColor: statusToBackgroundColor(status, theme),
                    borderRadius: theme.shape.smallBorderRadius,
                }}
            />
            <Typography sx={{ fontSize: theme.typography.caption, color: theme.palette.text.secondary }}>
                {lastUpdatedAt}
            </Typography>
        </Stack>
    );
}

export default ApplicantCell;
