import { useTheme } from '@mui/material';
import React from 'react';
import { Grid, PercentileLevel, Typography, Stack } from '@talentmesh/core';
import { Aspect } from '../../../../../../../../../../../Models/Aspect';
import Importance from '../../../../../../Components/Importance';
import { characteristicSize, scoreSize, importanceSize } from './PersonalityCharacteristicConsts';
import PersonalityCharacteristicAspectTooltip from './PersonalityCharacteristicAspectsTooltip';

interface CharacteristicItemProps {
    characteristicName: string;
    percentileScore: number;
    percentileLevel: PercentileLevel;
    aspects: Aspect[];
    importanceRating: number;
}
function PersonalityCharacteristicItem({
    characteristicName,
    percentileScore,
    percentileLevel,
    aspects,
    importanceRating,
}: CharacteristicItemProps): JSX.Element {
    const theme = useTheme();

    return (
        <>
            <Grid item xs={characteristicSize} sx={{ paddingBottom: 2 }}>
                <Typography variant="body2" color={theme.palette.text.primary}>
                    {characteristicName}
                </Typography>
            </Grid>
            <Grid item xs={scoreSize}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Typography variant="h5" color={theme.palette.text.secondary}>
                        {percentileScore}
                    </Typography>
                </Stack>
            </Grid>
            <PersonalityCharacteristicAspectTooltip
                characteristicName={characteristicName}
                percentileScore={percentileScore}
                percentileLevel={percentileLevel}
                aspects={aspects}
            />
            <Grid item xs={importanceSize}>
                <Importance weight={importanceRating} />
            </Grid>
        </>
    );
}

export default PersonalityCharacteristicItem;
