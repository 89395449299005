import React from 'react';
import { Toaster } from '@talentmesh/core';
import { useNotificationContext } from '../../Context/NotificationContext';

function NotificationToaster(): JSX.Element {
    const { open, message, title, status, setOpen } = useNotificationContext();

    return (
        <Toaster
            autoHideDuration={5000}
            status={status}
            alertMessage={message}
            alertTitle={title}
            open={open}
            close={() => setOpen(false)}
        />
    );
}

export default NotificationToaster;
