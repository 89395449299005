import { useTheme } from '@mui/material';
import React from 'react';
import { Button } from '@talentmesh/core';
import { ChevronLeftIcon } from '@talentmesh/icons';
import { generatePath, useNavigate } from 'react-router';
import UIStrings from '../../../../Utils/UIStrings';
import { useInvitationPageContext } from '../Contexts/InvitationPageContext';
import useCompanyId from '../../../../Hooks/UseCompanyId';
import RoutePath from '../../../../Routing/RoutePath';

function BackButton(): JSX.Element {
    const theme = useTheme();
    const navigate = useNavigate();
    const companyId = useCompanyId();
    const { returnURL: from, clientId } = useInvitationPageContext();

    const handleOnClick = () => {
        const recruitmentOverviewPath = generatePath(RoutePath.AssessmentsActive, { companyId, clientId });
        const nextLocation = from || recruitmentOverviewPath;
        navigate(nextLocation);
    };

    return (
        <Button
            variant="text"
            size="small"
            startIcon={<ChevronLeftIcon />}
            sx={{
                color: theme.palette.secondary.main,
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                ':active': {
                    backgroundColor: 'transparent',
                },
            }}
            onClick={handleOnClick}
        >
            {UIStrings.Back}
        </Button>
    );
}

export default BackButton;
