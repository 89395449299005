import React from 'react';
import { useTheme } from '@mui/material';
import { Dialog, DialogContent, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../Contexts/InviteCandidateContext';
import PopupDialogTitle from './PopupDialogTitle';
import GoHomeButton from './GoHomeButton';

function ConfirmationDialog(): JSX.Element {
    const theme = useTheme();
    const { confirmationDialogOpen, closeConfirmationDialog } = useInviteCandidateContext();

    return (
        <Dialog open={confirmationDialogOpen}>
            <PopupDialogTitle title={UIStrings.YourInvitationsAreSent} close={closeConfirmationDialog} />
            <DialogContent>
                <Stack
                    spacing={theme.spacing(2)}
                    sx={{
                        width: theme.spacing(48),
                    }}
                >
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        {UIStrings.YouWillBeNotifiedByEmail}
                    </Typography>
                    <GoHomeButton />
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default ConfirmationDialog;
