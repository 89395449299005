import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Paper, Stack } from '@talentmesh/core';
import { TestTypes } from '../../../../../Models/Configuration';
import CTMonitoringBody from './CTMonitoringBody';
import CTMonitoringHeader from './CTMonitoringHeader';

export interface CTMonitoringPanelProps {
    isWebcamEnabled: boolean;
    isScreenSnapshotsEnabled: boolean;
    isFullScreenEnabled: boolean;
    testType: TestTypes;
}

function CandidateTestMonitoringPanel(props: CTMonitoringPanelProps): JSX.Element {
    const theme = useTheme();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const togglePanel = () => {
        setIsOpened(!isOpened);
    };

    return (
        <Paper
            elevation={0}
            sx={{
                borderRadius: theme.shape.mediumBorderRadius,
            }}
        >
            <Stack direction="column">
                <CTMonitoringHeader isOpened={isOpened} togglePanel={togglePanel} {...props} />
                <CTMonitoringBody isOpened={isOpened} {...props} />
            </Stack>
        </Paper>
    );
}

export default CandidateTestMonitoringPanel;
