import React from 'react';
import { useTheme } from '@mui/material';
import { SpanTypography, TypographyProps } from '@talentmesh/core';

function VerifyEmailSpanTypography(props: TypographyProps): JSX.Element {
    const theme = useTheme();

    return <SpanTypography variant="body1" color={theme.palette.text.menuTitle} {...props} />;
}

export default VerifyEmailSpanTypography;
