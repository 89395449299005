import { useEffect, useState } from 'react';
import { useUserContext } from '../../../Context/UserContext';
import {
    AccountCreationFormAPI,
    AccountCreationFormValues,
} from '../../../Components/AccountCreation/AccountCreationForm';
import { SetupAccountDTO } from '../../../DTO/Users/SetupAccountDTO';
import { useUserSignupNavigationContext } from '../../../Context/UserSignupNavigationContext';
import { useNotificationContext } from '../../../Context/NotificationContext';

interface UseSetupAccountPageState {
    pageLoading: boolean;
    isSubmitting: boolean;
}

interface UseSetupAccountPageAPI extends AccountCreationFormAPI {}

function useSetupAccountPageState(): UseSetupAccountPageAPI {
    const { user, isLoading, getUserAsync, setupAccount, memberships } = useUserContext();
    const { refreshTokenAsync, navigateToNextPage } = useUserSignupNavigationContext();
    const [state, setState] = useState<UseSetupAccountPageState>({ pageLoading: true, isSubmitting: false });
    const { showFailToaster } = useNotificationContext();
    const { pageLoading } = state;

    useEffect(() => {
        const doLoadUserInfo = async () => {
            await getUserAsync();
            setState((prev) => ({ ...prev, pageLoading: false }));
        };
        doLoadUserInfo();
    }, []);

    const submitHandler = async (values: AccountCreationFormValues) => {
        try {
            const data: SetupAccountDTO = {
                ...values,
            };
            setState((prev) => ({ ...prev, isSubmitting: true }));
            await refreshTokenAsync();
            await setupAccount(data);
            navigateToNextPage();
        } catch (error) {
            showFailToaster();
        } finally {
            setState((prev) => ({ ...prev, isSubmitting: false }));
        }
    };

    return {
        isLoading: isLoading || pageLoading,
        allowAccountSetup: !user.firstName || !user.lastName || memberships.length === 0,
        isSubmitting: state.isSubmitting,
        submitHandler,
    };
}

export default useSetupAccountPageState;
