import { createContext, useContext } from 'react';
import { AssessmentSettings } from '../Models/Configuration';

export interface AssessmentSettingsContextData {
    assessmentSettings: AssessmentSettings;
    loadConfigurationAsync: () => Promise<void>;
}

export const AssessmentSettingsContext = createContext<AssessmentSettingsContextData | undefined>(undefined);

export function useAssessmentSettingsContext() {
    const context = useContext(AssessmentSettingsContext);
    if (!context) {
        throw new Error('useAssessmentSettingsContext must be used within the AssessmentSettingsContext.Provider');
    }
    return context;
}
