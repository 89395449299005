import {
    AccountSettings,
    AssessmentSettings,
    DefaultAccountSettings,
    DefaultAssessmentSettings,
    SaveEmailTemplate,
} from '../Models/Configuration';
import { Currency } from '../Models/Currency';
import { get, patch, post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class SettingsClient extends ClientBase {
    async getCurrenciesAsync(): Promise<Currency[]> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<Currency[]>({
            path: super.addHost('settings/currencies'),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        }

        return [];
    }

    async getAssessmentSettingsAsync(): Promise<AssessmentSettings> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<AssessmentSettings>({
            path: super.addHost('settings/assessment'),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return DefaultAssessmentSettings;
        }
    }

    async getAccountSettingsAsync(companyId: string): Promise<AccountSettings> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<AccountSettings>({
            path: super.addHost(`settings/company/${companyId}/account`),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return DefaultAccountSettings;
        }
    }

    async saveEmailTemplateAsync(dto: SaveEmailTemplate): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const strategy = toJson(dto);

        await post<SaveEmailTemplate>({
            path: super.addHost('settings/account/email-template'),
            body: strategy.body,
            contentType: strategy.contentType,
            accessToken,
        });
    }

    async patchNotificationsSettingsAsync(companyId: string, propertyName: string, value: boolean): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        await patch({
            path: this.addHost(`settings/company/${companyId}/account/notifications`),
            accessToken,
            ...toJson([
                {
                    op: 'replace',
                    path: `/${propertyName}`,
                    value: value.toString(),
                },
            ]),
        });
    }
}
