import calculateTimeoutDelta from './calculateTimeoutDelta';

export function formatDateToYYYYMMDD(date: Date) {
    return date.toISOString().split('T')[0].split('-').join('');
}

export function formatStringToYYYYMMDD(value?: string) {
    const date = value ? new Date(value) : new Date();
    return formatDateToYYYYMMDD(date);
}

export function toUTCDate(date: Date | string): Date {
    const currentDate = new Date(date);
    return new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
}

export default function toDateString(date: Date): string {
    return date.toLocaleDateString('en-au', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
}

export function toMonthYearDateString(date: Date | string): string {
    const UTCDate = toUTCDate(date);
    return UTCDate.toLocaleDateString('en-au', {
        month: 'long',
        year: 'numeric',
    });
}

function unixTimeToDate(unixTimestamp: number): Date {
    return new Date(unixTimestamp * 1000);
}

export function unixTimeToDateString(unixTimestamp: number): string {
    return toDateString(unixTimeToDate(unixTimestamp));
}

export function toDateAgoString(dateAt: string): string {
    const date = new Date(dateAt);
    let dateValue = toDateString(date);
    const delta = calculateTimeoutDelta(date);
    if (delta.scale !== 'date') {
        const scale = delta.value === 1 ? delta.scale.slice(0, delta.scale.length - 1) : delta.scale;
        dateValue = `${delta.value} ${scale} ago`;
    }

    return dateValue;
}

export function differenceInDays(date1: Date, date2: Date): number {
    const diffTime = date2.valueOf() - date1.valueOf();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}
