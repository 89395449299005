import { Currency } from './Currency';
import { JobCategory } from './JobCategory';

export type AssessmentStatus = 'NonPublic' | 'Public' | 'Closed';
// TODO: https://dev.azure.com/TalentMesh/TalentMesh/_sprints/taskboard/TalentMesh%20Team/TalentMesh/Sprint%2010?workitem=726
export enum TestTypes {
    None = '',
    Skills = 'Skills',
    Aptitude = 'Aptitude',
    Personality = 'Personality',
    EmotionalIntelligence = 'EmotionalIntelligence',
}

export interface AssessmentSettings {
    jobCategories: JobCategory[];
    currencies: Currency[];
}

export interface AccountSettings {
    rejectEmailTemplate: EmailTemplate;
    inviteEmailTemplate: EmailTemplate;
    notifications: Notifications;
}

export interface Notifications {
    sendNotificationsOnAssessmentComplete: boolean;
}

export const DefaultNotifications: Notifications = {
    sendNotificationsOnAssessmentComplete: true,
};

export interface SaveEmailTemplate {
    type: EmailTemplateType;
    title: string;
    bodyHtml: string;
    regards: string;
    templateHtml: string;
    companyId: string;
}

export enum EmailTemplateType {
    RejectTemplate = 1,
    InviteTemplate,
}

export interface EmailTemplate {
    title: string;
    thankYouMessage: string;
    bodyHtml: string;
    regards: string;
    templateHtml: string;
}

export const DefaultAssessmentSettings: AssessmentSettings = {
    jobCategories: [],
    currencies: [],
};

export const DefaultAccountSettings: AccountSettings = {
    inviteEmailTemplate: {
        title: '',
        thankYouMessage: '',
        bodyHtml: '',
        regards: '',
        templateHtml: '',
    },
    rejectEmailTemplate: {
        title: '',
        thankYouMessage: '',
        bodyHtml: '',
        regards: '',
        templateHtml: '',
    },
    notifications: DefaultNotifications,
};
