import React from 'react';
import { Stack, ToggleWithText, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { LinkedInParam } from './AvailableJobBoards';
import UIStrings from '../../../../Utils/UIStrings';

interface LinkedInVideoHeaderProps {
    selected: LinkedInParam;
    handleChange: (event: React.MouseEvent<HTMLElement>, value: LinkedInParam) => void;
}

const LinkedInVideoHeader = ({ selected, handleChange }: LinkedInVideoHeaderProps) => {
    const theme = useTheme();

    return (
        <Stack direction="row" alignItems="center" spacing={theme.spacing(2.5)}>
            <Typography variant="h4" color="primary">
                {UIStrings.SelectYourLinkedInProfileType}
            </Typography>
            <div>
                <ToggleWithText
                    leftOption={UIStrings.NormalProfile}
                    leftValue="normal"
                    rightOption={UIStrings.RecruiterProfile}
                    rightValue="recruiter"
                    onChange={handleChange}
                    value={selected}
                />
            </div>
        </Stack>
    );
};

export default LinkedInVideoHeader;
