import { useTheme } from '@mui/material/styles';
import React from 'react';
import { DialogTitle, IconButton, Stack } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';

interface PopupDialogTitleProps {
    title: string;
    close: () => void;
}

function PopupDialogTitle({ title, close }: PopupDialogTitleProps): JSX.Element {
    const theme = useTheme();
    return (
        <DialogTitle variant="h4" color={theme.palette.text.menuTitle}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                {title}
                <IconButton onClick={close} data-cy="closeDialogButton">
                    <CloseIcon />
                </IconButton>
            </Stack>
        </DialogTitle>
    );
}

export default PopupDialogTitle;
