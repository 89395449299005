import React from 'react';
import { InputSectionProps } from '../Components/InputSection';
import UIStrings from '../../../Utils/UIStrings';
import CompanyNameSection from '../Components/CompanyNameSection';
import SubdomainSection from '../Components/SubdomainSection';
import CompanyDescription from '../Components/CompanyDescription';

function useDefaultProfileFormInput(): InputSectionProps[] {
    const components = [
        {
            InputControl: () => <CompanyNameSection />,
            dataCy: 'CompanyNameSection',
            title: UIStrings.CompanyNameAsterisk,
            description: UIStrings.TheCompanyNameThatIsDisplayedToApplicants,
        },
        {
            InputControl: () => <SubdomainSection />,
            dataCy: 'SubdomainSection',
            title: UIStrings.SubdomainAsterisk,
            description: UIStrings.YouCanUseLettersNumberAndDashes,
        },
        {
            InputControl: () => <CompanyDescription />,
            dataCy: 'CompanyDescriptionSection',
            title: `${UIStrings.CompanyDescription}*`,
            description: UIStrings.CompanyDescriptionExplanation,
            width: '100%',
        },
    ];

    return components;
}

export default useDefaultProfileFormInput;
