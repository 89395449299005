import React from 'react';
import { Box, MenuButton } from '@talentmesh/core';
import { MembershipStatus, TeamMember } from '../../../../../../Models/TeamMember';
import useTeamMemberManagementMenuItems from '../Hooks/useTeamMemberManagementMenuItems';
import UIStrings from '../../../../../../Utils/UIStrings';

interface MemberManagementButtonProps {
    member: TeamMember;
}

function getLabel(status: MembershipStatus): string {
    switch (status) {
        case 'Member':
            return UIStrings.ManageMember;
        case 'Expired':
        case 'Invited':
            return UIStrings.ManageInvitation;
        default:
            throw new Error(`Unknown status: ${status}`);
    }
}

function MemberManagementButton({ member }: MemberManagementButtonProps): JSX.Element {
    const { status, id } = member;
    const items = useTeamMemberManagementMenuItems(status, id);
    return (
        <Box
            sx={{
                margin: '0 auto',
            }}
        >
            <MenuButton
                label={getLabel(status)}
                menuItems={items}
                buttonProps={{
                    color: 'primary',
                }}
            />
        </Box>
    );
}

export default MemberManagementButton;
