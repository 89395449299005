import React from 'react';
import { useTheme } from '@mui/material';
import { IconButton, Stack, Typography } from '@talentmesh/core';
import { KeyboardArrowLeftIcon } from '@talentmesh/icons';
import CandidateFavorite from './Components/CandidateFavorite';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';

function HeaderInfo(): JSX.Element {
    const theme = useTheme();
    const { results } = useCandidatesResultsContext();
    const { candidateFirstName, candidateLastName } = results;
    const history = useTalentMeshHistory();
    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <IconButton onClick={() => history.goBack()}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <CandidateFavorite />
            <Typography variant="h5" color={theme.palette.text.menuTitle}>
                {candidateFirstName} {candidateLastName}
            </Typography>
        </Stack>
    );
}

export default HeaderInfo;
