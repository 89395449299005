import React, { ReactNode, SyntheticEvent } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, SmallTMTooltip, IconButtonProps } from '@talentmesh/core';
import { AssessmentStatuses } from '../../../../../Models/AssessmentData';
import { CandidateOverview, CandidateStatuses } from '../../../../../Models/CandidateOverview';
import { DialogEnum, useActionDialogContext } from '../../../../Dialogs/ATS/Context/ActionDialogContext';
import { mapFromCandidateOverviewToCandidateDialogModel } from '../../../../Dialogs/Dialogs.Utils';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import ATSAction from '../../../../../Models/ATSAction';
import isATSActionAllowedInState from '../../../../../Utils/ATSActions/isATSActionAllowedInState';

export interface ATSCellProps extends Pick<IconButtonProps, 'sx'> {
    candidate: CandidateOverview;
    dialogStatus: DialogEnum;
    icon: ReactNode;
    tooltipMessage: string;
}

function getATSCellButtonDisabledState(dialogStatus: DialogEnum, status: CandidateStatuses): boolean {
    switch (dialogStatus) {
        case DialogEnum.Reject:
            return !isATSActionAllowedInState(ATSAction.Reject, status);
        case DialogEnum.Hire:
            return !isATSActionAllowedInState(ATSAction.Hire, status);
        case DialogEnum.Interview:
            return !isATSActionAllowedInState(ATSAction.InviteForInterview, status);
        default:
            return false;
    }
}

function ATSCell({ candidate, dialogStatus, icon, tooltipMessage, sx }: ATSCellProps): JSX.Element {
    const theme = useTheme();
    const { assessmentId, testTypes, assessmentStatus, includesExperienceCriteria } = useCandidatesOverviewContext();
    const { showDialog } = useActionDialogContext();
    const {
        currentStatus: { status },
    } = candidate;

    const onClickHandler = (event: SyntheticEvent) => {
        event.stopPropagation();
        showDialog(
            [mapFromCandidateOverviewToCandidateDialogModel(assessmentId, candidate)],
            dialogStatus,
            testTypes,
            includesExperienceCriteria,
        );
    };

    const isDisabled =
        getATSCellButtonDisabledState(dialogStatus, status) || assessmentStatus === AssessmentStatuses.Closed;

    return (
        <SmallTMTooltip
            title={tooltipMessage}
            disableInteractive
            disableHoverListener={isDisabled}
            disableFocusListener={isDisabled}
            disableTouchListener={isDisabled}
        >
            <IconButton
                onClick={onClickHandler}
                disabled={isDisabled}
                sx={{ padding: theme.spacing(0.5), color: theme.palette.secondary.main, ...sx }}
            >
                {icon}
            </IconButton>
        </SmallTMTooltip>
    );
}

export default ATSCell;
