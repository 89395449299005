import { useState, useEffect } from 'react';

function useDebounce<T>(
    /** Value to debounce */
    value: T,
    /** The number of milliseconds to wait before returning value */
    delay: number,
) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(timeout);
    }, [value, delay]);

    return debouncedValue;
}

export default useDebounce;
