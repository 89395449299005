import { SnapshotsSliceDTO } from '../DTO/Snapshots/SnapshotsSliceDTO';

export interface SnapshotsSlice {
    createdAt: Date;
    screenshotUrl: string;
    webcamPhotoUrl: string;
}

export function fromSnapshotsSliceDTO(dto: SnapshotsSliceDTO): SnapshotsSlice {
    return {
        createdAt: new Date(dto.createdAt),
        screenshotUrl: dto.screenshotUrl,
        webcamPhotoUrl: dto.webcamPhotoUrl,
    };
}
