import { useTheme } from '@mui/material';
import React from 'react';
import { Button, Stack } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';

function OidcActionButtons(): JSX.Element {
    const theme = useTheme();

    const getBaseUrl = (): string => {
        const { protocol, host } = window.location;
        return `${protocol}//${host}`;
    };

    const loginHandler = () => {
        window.location.href = getBaseUrl();
    };

    const signUpHandler = () => {
        window.location.href = `${getBaseUrl()}/register`;
    };

    return (
        <Stack spacing={theme.spacing(2)} direction="row">
            <Button variant="contained" onClick={loginHandler}>
                {UIStrings.LogInToTalentmesh}
            </Button>
            <Button variant="outlined" onClick={signUpHandler}>
                {UIStrings.GetFreeTrial}
            </Button>
        </Stack>
    );
}

export default OidcActionButtons;
