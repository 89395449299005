import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, StackProps, TMCircularProgress, Typography } from '@talentmesh/core';
import { Factor } from '../../../../../../../../../Models/Factor';
import PersonalityCharacteristicsPanel from './Characteristics/PersonalityCharacteristicsPanel';

export interface FactorPanelProps extends StackProps {
    factor: Factor;
}
function FactorPanel({ factor, ...props }: FactorPanelProps): JSX.Element {
    const theme = useTheme();
    const { percentileLevel, percentileScore, factorName, characteristics } = factor;
    return (
        <Stack direction="column" {...props}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <TMCircularProgress level={percentileLevel} progress={percentileScore} />
                <Typography variant="h4" color={theme.palette.text.menuTitle}>
                    {factorName}
                </Typography>
            </Stack>
            <PersonalityCharacteristicsPanel characteristics={characteristics} />
        </Stack>
    );
}

export default FactorPanel;
