import React from 'react';
import { useTheme } from '@mui/material';
import { TMTooltip, Typography, TooltipPaper, IconButton } from '@talentmesh/core';
import { InfoOutlinedIcon } from '@talentmesh/icons';

export interface EmailTemplateToolTipProps {
    children?: React.ReactNode;
}

const EmailTemplateToolTip = ({ children }: EmailTemplateToolTipProps) => {
    const theme = useTheme();
    return (
        <TMTooltip
            placement="right"
            title={
                <TooltipPaper minWidth={theme.spacing(36)}>
                    <Typography variant="body2">{children}</Typography>
                </TooltipPaper>
            }
        >
            <IconButton size="small">
                <InfoOutlinedIcon fontSize="small" />
            </IconButton>
        </TMTooltip>
    );
};

export default EmailTemplateToolTip;
