import PaymentMethodDTO from '../DTO/Users/PaymentMethodDTO';
import PaymentMethod, { fromPaymentMethodDTO } from '../Models/PaymentMethod';
import { get, post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class PaymentMethodClient extends ClientBase {
    getPaymentMethodAsync = async (companyId: string): Promise<PaymentMethod | undefined> => {
        const result = await get<PaymentMethodDTO>({
            path: this.addHost(`users/company/${companyId}/payment-method`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromPaymentMethodDTO(result.body);
        }
        return undefined;
    };

    savePaymentMethodAsync = async (companyId: string, paymentMethodId: string): Promise<PaymentMethod | undefined> => {
        const result = await post<PaymentMethodDTO>({
            path: this.addHost(`users/company/${companyId}/payment-method`),
            ...toJson({ PaymentMethodId: paymentMethodId }),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromPaymentMethodDTO(result.body);
        }
        return undefined;
    };
}
