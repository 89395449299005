export default function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
    enumType: T,
    enumValue: string,
): keyof T {
    const keys = Object.keys(enumType).filter((x) => enumType[x] === enumValue);
    if (keys.length > 0) {
        return keys[0];
    }
    throw new Error('Provided enum value was not found in enum');
}
