import { FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Stack } from '@talentmesh/core';
import { TestTypes } from '../../../../../../../../../Models/Configuration';
import { useCreateRecruitmentContext } from '../../../../../../Create/Contexts/CreateRecruitmentContext';
import TestInfo from '../../../../../../Models/TestInfo';
import PersonalityTestCard from './Components/PersonalityTestCard/PersonalityTestCard';
import SkillsTestCard from './Components/SkillsTestCard/SkillsTestCard';
import TestCardGroup from './Components/TestCardGroup/TestCardGroup';
import AptitudeTestCard from './Components/AptitudeTestCard/AptitudeTestCard';
import EmotionalIntelligenceTestCard from './Components/EmotionalIntelligenceTestCard/EmotionalIntelligenceTestCard';
import UIStrings from '../../../../../../../../../Utils/UIStrings';
import { DemoQuestions } from '../../../../../../Models/Questions';
import { TestSelectionFormValues } from '../../../../SelectTestsStep';

interface TestCardsProps {
    helpers: FormikHelpers<any>;
}

function TestCards({ helpers }: TestCardsProps): JSX.Element {
    const { testInfos, getDemoQuestions } = useCreateRecruitmentContext();
    const {
        values: { testLanguageField },
    } = useFormikContext<TestSelectionFormValues>();

    const [personality, setPersonality] = useState<TestInfo | undefined>();
    const [skills, setSkills] = useState<TestInfo | undefined>();
    const [aptitude, setAptitude] = useState<TestInfo | undefined>();
    const [emotionalIntelligence, setEmotionalIntelligence] = useState<TestInfo | undefined>();
    const [demoQuestions, setDemoQuestions] = useState<DemoQuestions | undefined>();

    useEffect(() => {
        let testInfo = testInfos.find((item) => item.testType === TestTypes.Personality);
        setPersonality(testInfo);

        testInfo = testInfos.find((item) => item.testType === TestTypes.Skills);
        setSkills(testInfo);

        testInfo = testInfos.find((item) => item.testType === TestTypes.Aptitude);
        setAptitude(testInfo);

        testInfo = testInfos.find((item) => item.testType === TestTypes.EmotionalIntelligence);
        setEmotionalIntelligence(testInfo);
    }, [testInfos]);

    useEffect(() => {
        const getAndSetDemoQuestions = async () => {
            const result = await getDemoQuestions(testLanguageField);
            setDemoQuestions(result);
        };

        setDemoQuestions(undefined);
        getAndSetDemoQuestions();
    }, [testLanguageField, setDemoQuestions]);

    return (
        <Stack direction="row" spacing={2}>
            <TestCardGroup groupName={UIStrings.HardSkills}>
                {skills && (
                    <SkillsTestCard
                        test={skills}
                        demoQuestions={demoQuestions?.skills}
                        likertOptions={demoQuestions?.likertAnswers}
                        helpers={helpers}
                    />
                )}
                {aptitude && (
                    <AptitudeTestCard
                        test={aptitude}
                        demoQuestions={demoQuestions?.aptitude}
                        likertOptions={demoQuestions?.likertAnswers}
                        helpers={helpers}
                    />
                )}
            </TestCardGroup>
            <TestCardGroup groupName={UIStrings.SoftSkills}>
                {personality && (
                    <PersonalityTestCard
                        test={personality}
                        demoQuestions={demoQuestions?.personality}
                        likertOptions={demoQuestions?.likertAnswers}
                        helpers={helpers}
                    />
                )}
                {emotionalIntelligence && (
                    <EmotionalIntelligenceTestCard
                        test={emotionalIntelligence}
                        demoQuestions={demoQuestions?.emotionalIntelligence}
                        likertOptions={demoQuestions?.likertAnswers}
                        helpers={helpers}
                    />
                )}
            </TestCardGroup>
        </Stack>
    );
}

export default TestCards;
