import React from 'react';
import { AccordionDetails } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import getApplicantInformationLabel from '../../../../../../Utils/getLabelsFunctions';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import FormikChoiceSelector from './FormikChoiceSelector';
import UIStrings from '../../../../../../Utils/UIStrings';
import AdditionalInformationAccordionSummary from './Accordion/AdditionalInformationAccordionSummary';
import { AdditionalInformationAccordion } from './Accordion/AdditionalInformationAccordion';

const startUpInformationToggles: ApplicantInformationType[] = [
    ApplicantInformationType.WhyWorkInStartup,
    ApplicantInformationType.RankRemunerationLearningBalance,
    ApplicantInformationType.StructureImportance,
    ApplicantInformationType.PersonalProject,
    ApplicantInformationType.WhereDoYouSeeYourself,
];

const StartupInformationAccordion = (): JSX.Element => {
    const theme = useTheme();
    return (
        <AdditionalInformationAccordion sx={{ borderBottom: `1px solid ${theme.palette.border.main}` }}>
            <AdditionalInformationAccordionSummary
                sx={{
                    borderBottomLeftRadius: theme.shape.smallBorderRadius,
                    borderBottomRightRadius: theme.shape.smallBorderRadius,
                    '&.Mui-expanded': {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                }}
                title={UIStrings.StartupInformation}
            />
            <AccordionDetails>
                {startUpInformationToggles.map((value) => (
                    <FormikChoiceSelector
                        key={ApplicantInformationType[value]}
                        label={getApplicantInformationLabel(value)}
                        name={value}
                    />
                ))}
            </AccordionDetails>
        </AdditionalInformationAccordion>
    );
};

export default StartupInformationAccordion;
