import { Education } from './Education';
import { Experience } from './Experience';

export interface ApplicantProfile {
    name: string;
    firstName: string;
    lastName: string;
    location?: string;
    isWillingToRelocate: boolean | null;
    email: string;
    experiences: Experience[];
    educations: Education[];
}

export const DefaultApplicantProfile: ApplicantProfile = {
    firstName: '',
    lastName: '',
    email: '',
    isWillingToRelocate: null,
    location: '',
    name: '',
    educations: [],
    experiences: [],
};
