import { Stepper, StepperProps } from '@talentmesh/core';
import React from 'react';
// todo! reexport in @talentmesh/core
import { stepClasses, stepLabelClasses, useTheme } from '@mui/material';
import ThickConnector from './ThickConnector';

const CustomStepper = ({ activeStep, children }: React.PropsWithChildren<Pick<StepperProps, 'activeStep'>>) => {
    const theme = useTheme();

    return (
        <Stepper
            activeStep={activeStep}
            connector={<ThickConnector />}
            alternativeLabel
            sx={{
                [`& > .${stepClasses.root} > .${stepLabelClasses.root}`]: {
                    flexDirection: 'column-reverse',
                    [`.${stepLabelClasses.label}`]: {
                        mt: 0,
                    },
                    [`.${stepLabelClasses.iconContainer}`]: {
                        mt: theme.spacing(0.75),
                        // this is the containing icon, I think it's <StepIcon />
                        '& > div': {
                            width: theme.spacing(1.75),
                            height: theme.spacing(1.75),
                        },
                    },
                },
            }}
        >
            {children}
        </Stepper>
    );
};

export default CustomStepper;
