import { useTheme } from '@mui/material';
import React from 'react';
import { Chamomile, Grid, Paper, ScoreItem, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';
import TestValidity from '../Personality/Components/TestValidity';

interface ScoreOverviewProps {
    scoreOverviewTitle: string;
    scoreOverviewDescription: string;
    candidateFirstName: string;
    scores: ScoreItem[];
    overall: ScoreItem;
    testValidity?: number;
}

function ScoreOverview({
    scoreOverviewTitle,
    scoreOverviewDescription,
    candidateFirstName,
    scores,
    overall,
    testValidity,
}: ScoreOverviewProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                borderTopLeftRadius: 0,
                paddingTop: 3,
                paddingBottom: 3,
            }}
        >
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
                <Grid container>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                            <Typography variant="h3" color={theme.palette.text.menuTitle}>
                                {scoreOverviewTitle}
                            </Typography>
                            <Typography textAlign="center" variant="body2" color={theme.palette.text.menuTitle}>
                                {scoreOverviewDescription}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={2}>
                        {testValidity && <TestValidity value={testValidity} />}
                    </Grid>
                </Grid>
                <Chamomile scores={scores} overall={overall} />
                <Typography variant="body2" color={theme.palette.text.menuTitle}>
                    {UIStrings.ScoreOverviewFooter(candidateFirstName, overall.value)}
                </Typography>
            </Stack>
        </Paper>
    );
}

export default ScoreOverview;
