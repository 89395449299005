import { Factor } from '../../../../../../../../../Models/Factor';

const extraversion = 'Extraversion';

function sortByFactorName(a: Factor, b: Factor): number {
    let result = 0;

    if (a.factorName.startsWith(extraversion) || b.factorName.startsWith(extraversion)) {
        if (a.factorName.startsWith(extraversion) && !b.factorName.startsWith(extraversion)) {
            result = 1;
        }

        if (!a.factorName.startsWith(extraversion) && b.factorName.startsWith(extraversion)) {
            result = -1;
        }
    } else {
        result = a.factorName.localeCompare(b.factorName);
    }

    return result;
}

export default sortByFactorName;
