import { useTheme } from '@mui/material';
import React from 'react';
import { Button, Stack } from '@talentmesh/core';
import { CheckCircleOutlineIcon } from '@talentmesh/icons';
import UIStrings from '../../../Utils/UIStrings';
import { useUserSignupNavigationContext } from '../../../Context/UserSignupNavigationContext';
import EmailVerifiedPageContentTypography from './EmailVerifiedPageContentTypography';

function EmailVerifiedPageContent(): JSX.Element {
    const theme = useTheme();
    const { navigateToNextPage } = useUserSignupNavigationContext();

    return (
        <Stack
            borderRadius={theme.shape.smallBorderRadius}
            direction="column"
            alignItems="center"
            spacing={theme.spacing(2)}
        >
            <CheckCircleOutlineIcon
                sx={{
                    color: theme.palette.success.main,
                }}
            />
            <EmailVerifiedPageContentTypography />
            <Button variant="contained" fullWidth onClick={navigateToNextPage}>
                {UIStrings.ContinueToTalentMesh}
            </Button>
        </Stack>
    );
}

export default EmailVerifiedPageContent;
