import { theme, RoundButtonTab, TabList } from '@talentmesh/core';
import React from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import { useInvitationPageContext } from '../Contexts/InvitationPageContext';
import useCompanyId from '../../../../Hooks/UseCompanyId';

interface PageTabConfiguration {
    label: string;
    value: string;
}

const pageTabsConfiguration: Array<PageTabConfiguration> = [
    {
        label: UIStrings.PostOnJobBoards,
        value: RoutePath.AttractApplicantsJobBoards,
    },
    {
        label: UIStrings.CareerPage,
        value: RoutePath.AttractApplicantsCareerPage,
    },
    {
        label: UIStrings.InvitationLinkOrEmail,
        value: RoutePath.AttractApplicantsInvite,
    },
];

function PageTabs(): JSX.Element {
    const { setActiveTab, jobAdId } = useInvitationPageContext();

    const tabs = jobAdId ? pageTabsConfiguration : [pageTabsConfiguration[pageTabsConfiguration.length - 1]];

    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };
    const companyId = useCompanyId();

    const { recruitmentId } = useParams<{ recruitmentId: string }>();

    return (
        <TabList
            disableTabIndicator
            onChange={handleChange}
            sx={{
                marginTop: theme.spacing(2),
            }}
        >
            {tabs.map(({ label, value }) => {
                const path = generatePath(value, { companyId, recruitmentId });
                return (
                    <RoundButtonTab
                        key={value}
                        label={label}
                        value={value}
                        to={path}
                        component={Link}
                        sx={{
                            borderBottom: 'none',
                        }}
                    />
                );
            })}
        </TabList>
    );
}

export default PageTabs;
