import { DropdownOption } from '@talentmesh/core';

const directReportsOptions: Array<DropdownOption> = [
    {
        id: 'None',
        name: '0',
    },
    {
        id: 'SmallGroup',
        name: '1 to 5',
    },
    {
        id: 'MediumGroup',
        name: '6 to 10',
    },
    {
        id: 'LargeGroup',
        name: 'More than 10',
    },
];

export default directReportsOptions;
