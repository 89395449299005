import React, { useEffect, useState } from 'react';
import { Stack } from '@talentmesh/core';
import { Typography } from '@mui/material';
import { useAssessmentClient, useJobTestInfoClient, useUsersClient } from '../Hooks/ClientHooks';
import { VersionDto } from '../DTO/VersionDto';

function VersionPage(): JSX.Element {
    const assessmentClient = useAssessmentClient();
    const jobTestInfoClient = useJobTestInfoClient();
    const usersClient = useUsersClient();

    const [versions, setVersion] = useState<VersionDto[]>([]);

    const getVersionsAsync = async () => {
        const v1 = await assessmentClient.getVersionAsync();
        const v2 = await jobTestInfoClient.getVersionAsync();
        const v3 = await usersClient.getVersionAsync();

        setVersion([v1, v2, v3]);
    };

    useEffect(() => {
        getVersionsAsync();
    }, []);

    return (
        <Stack direction="column">
            <Typography>{`app version: ${process.env.REACT_APP_VERSION}`}</Typography>

            {versions.map((version) => (
                <Typography key={version.name}>
                    {version.name} : {version.version}
                </Typography>
            ))}
        </Stack>
    );
}

export default VersionPage;
