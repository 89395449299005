import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

function NoRowsOverlay(): JSX.Element {
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography>{UIStrings.NoRows}</Typography>
        </Stack>
    );
}

export default NoRowsOverlay;
