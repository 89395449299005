import React from 'react';
import StepProps from '../StepProps';
import JobDetailsStep from './JobDetailsStep';
import { useEditRecruitmentContext } from '../../Edit/Contexts/EditRecruitmentContext';
import { useCompanyContext } from '../../../../../Context/CompanyContext';

const EditJobDetailsStep = ({ activeStep, handleNext, handleBack }: StepProps): JSX.Element => {
    const { client, jobDetails, updateJobDetails, currencies, processingState } = useEditRecruitmentContext();
    const { currentCompanyProfile } = useCompanyContext();
    const { companyName } = currentCompanyProfile;
    return (
        <JobDetailsStep
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            processingState={processingState}
            jobDetails={jobDetails}
            updateJobDetails={updateJobDetails}
            currencies={currencies}
            companyName={companyName}
            client={client}
            disableJobDescriptionLanguage
            disableJobFunctionSelection
            disableHowDoYouWantToPost
        />
    );
};

export default EditJobDetailsStep;
