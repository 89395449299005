import { post, put, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';
import CreateExperienceCriteriaScoreDTO from '../DTO/ExperienceCriteria/CreateExperienceCriteriaScoreDTO';
import UpdateExperienceCriteriaScoreDTO from '../DTO/ExperienceCriteria/UpdateExperienceCriteriaScoreDTO';

export default class ExperienceCriteriaScoreClient extends ClientBase {
    createExperienceCriteriaScore = async (dto: CreateExperienceCriteriaScoreDTO): Promise<void> => {
        const strategy = toJson(dto);
        await post({
            path: this.addHost(`experience-criteria-score`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
    };

    updateExperienceCriteriaScore = async (
        experienceCriteriaScoreId: string,
        dto: UpdateExperienceCriteriaScoreDTO,
    ): Promise<void> => {
        const strategy = toJson(dto);
        await put({
            path: this.addHost(`experience-criteria-score/${experienceCriteriaScoreId}`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
    };
}
