export interface Aspect {
    isHighDescriptor: boolean;
    descriptor: string;
}

export function aspectCompare(a: Aspect, b: Aspect): number {
    if (a.isHighDescriptor && !b.isHighDescriptor) {
        return -1;
    }

    if (!a.isHighDescriptor && b.isHighDescriptor) {
        return 1;
    }

    return 0;
}
