import { useTheme } from '@mui/material/styles';
import React from 'react';
import UIStrings from '../../../Utils/UIStrings';
import ActionConfirmationDialog from './Components/ActionConfirmationDialog';
import DialogCardContainer from './Components/DialogCardContainer';
import DialogHeader from './Components/DialogHeader';
import HireActions from './Components/Hire/HireActions';
import HireDialogBody from './Components/Hire/HireDialogBody';
import HireDialogHeader from './Components/Hire/HireDialogHeader';
import { DialogEnum } from './Context/ActionDialogContext';

function HireDialog() {
    const theme = useTheme();

    return (
        <ActionConfirmationDialog
            dialogType={DialogEnum.Hire}
            header={<DialogHeader title={UIStrings.Hire} backgroundColor={theme.palette.success.main} />}
            body={
                <DialogCardContainer
                    dialogTitle={<HireDialogHeader />}
                    dialogBody={<HireDialogBody />}
                    sx={{ padding: 0, backgroundColor: theme.palette.common.white }}
                />
            }
            actions={<HireActions />}
        />
    );
}

export default HireDialog;
