import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Popover } from '@talentmesh/core';

export interface BaseFilterPanelProps {
    children: JSX.Element;
    anchorElement: HTMLButtonElement | null;
    open: boolean;
    handleClose: () => void;
}

function BaseFilterPanel({ children, anchorElement, handleClose, open }: BaseFilterPanelProps): JSX.Element {
    const theme = useTheme();

    return (
        <Popover
            data-cy="AdvancedFilterPanel"
            open={open}
            onClose={handleClose}
            anchorEl={anchorElement}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            PaperProps={{
                elevation: 6,
                sx: {
                    border: `1px solid ${theme.palette.border.main}`,
                    marginTop: theme.spacing(1),
                    minWidth: theme.spacing(40.125),
                    color: theme.palette.secondary.main,
                },
            }}
        >
            {children}
        </Popover>
    );
}

export default BaseFilterPanel;
