import { Box, useTheme } from '@mui/material';
import React from 'react';

interface ChartBoxProps {
    children: React.ReactNode;
}

const ChartBox = ({ children }: ChartBoxProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                maxWidth: theme.spacing(130),
                height: theme.spacing(35),
            }}
        >
            {children}
        </Box>
    );
};

export default ChartBox;
