import React from 'react';
import { useTheme } from '@mui/material';
import { Stack } from '@talentmesh/core';
import CareerPageTabContent from './CareerPageTabContent';
import { useCareerPageTabContext } from '../Contexts/CareerPageTabContext';
import TabLoadingSpinner from '../../../../Components/TabLoadingSpinner';

function CareerPageTabLoadingContent(): JSX.Element {
    const { loading } = useCareerPageTabContext();
    const theme = useTheme();

    if (loading) {
        return <TabLoadingSpinner />;
    }

    return (
        <Stack
            direction="column"
            sx={{
                borderBottomLeftRadius: theme.shape.smallBorderRadius,
                borderBottomRightRadius: theme.shape.smallBorderRadius,
                backgroundColor: theme.palette.common.white,
                borderWidth: '1px',
                borderColor: theme.palette.border.main,
                padding: theme.spacing(2.5),
            }}
        >
            <CareerPageTabContent />
        </Stack>
    );
}

export default CareerPageTabLoadingContent;
