import { useTheme } from '@mui/material';
import { IconButton, Stack, TMTooltip, WorkArrangements } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import { useField } from 'formik';
import React from 'react';
import UIStrings from '../../../../../../../Utils/UIStrings';
import MultiFontWeightLabel from '../../../../Components/MultiFontWeightLabel';
import { JobDetailsFieldTypes } from '../../JobDetailsStepUtils';
import JobLocationTooltipBody from './JobLocationTooltipBody';

function JobLocationLabel(): JSX.Element {
    const theme = useTheme();
    const [{ value: workArrangement }] = useField<WorkArrangements>(JobDetailsFieldTypes.WorkArrangement);

    return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <MultiFontWeightLabel
                variant="h6"
                label={`${workArrangement === 'Remote' ? UIStrings.EmployeeLocation : UIStrings.JobLocation}*`}
                comment=""
            />
            <TMTooltip title={<JobLocationTooltipBody />} placement="bottom">
                <IconButton sx={{ color: theme.palette.secondary.light, padding: theme.spacing(0) }}>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </TMTooltip>
        </Stack>
    );
}

export default JobLocationLabel;
