import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';

const RequestInformationStepHeader = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ py: theme.spacing(2.5) }}
        >
            <Typography variant="h2" color={theme.palette.text.menuTitle}>
                {UIStrings.RequestAdditionalApplicantInformation}
            </Typography>
            <Typography variant="body1" color={theme.palette.text.menuTitle}>
                {UIStrings.QuickSummaryRequestInformationStep}
            </Typography>
        </Stack>
    );
};
export default RequestInformationStepHeader;
