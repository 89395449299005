import { Theme, useTheme } from '@mui/material/styles';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import React from 'react';
import StripeCardElement from './StripeCardElement';

const buildCardOptions = (theme: Theme): StripeCardElementOptions => {
    return {
        hidePostalCode: true,
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: theme.palette.grey[400],
                color: theme.palette.text.primary,
                fontWeight: theme.typography.body1.fontWeight,
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.body1.fontSize?.toString(),

                '::placeholder': {
                    color: theme.palette.grey[400],
                },
                ':focus': {
                    iconColor: theme.palette.secondary.main,
                },
            },
            invalid: {
                iconColor: theme.palette.error.main,
                color: theme.palette.error.main,
            },
        },
    };
};

const CreditCard = () => {
    const theme = useTheme();
    const cardOptions = buildCardOptions(theme);
    return <StripeCardElement inputProps={{ options: cardOptions }} />;
};
export default CreditCard;
