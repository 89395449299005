import React from 'react';
import { CheckCircleIcon } from '@talentmesh/icons';
import { GridColDef } from '@mui/x-data-grid';
import UIStrings from '../../../../../Utils/UIStrings';
import { RecruiterRoles } from '../../../../../Models/RecruiterRoles';
import RoleColumnCell from '../TeamMembersTable/Components/RoleColumnCell';
import AllowanceColumnHeader from '../TeamMembersTable/Components/AllowanceColumnHeader';
import classes from '../TeamMembersTable/utils/classes';

export interface RoleItemProps {
    name: RecruiterRoles;
    description: string;
    inviteMember: boolean;
    createRecruitment: boolean;
    useAts: boolean;
    viewApplicants: boolean;
}

function useBaseColumn(fieldName: string): GridColDef<RoleItemProps> {
    return {
        field: fieldName,
        sortable: false,
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
    };
}

function useRoleColumn(): GridColDef<RoleItemProps> {
    const column = useBaseColumn('name');
    column.headerName = UIStrings.Role;
    column.width = 425;
    column.cellClassName = classes.borderRight;
    column.headerClassName = classes.borderRight;
    column.renderCell = (value) => <RoleColumnCell value={value.row as RoleItemProps} />;

    return column;
}

function useAllowanceColumn(fieldName: string, headerName: string): GridColDef<RoleItemProps> {
    const column = useBaseColumn(fieldName);
    column.headerName = headerName;
    column.width = 100;
    column.align = 'center';
    column.renderCell = (value) => (value ? <CheckCircleIcon color="success" /> : null);
    column.renderHeader = () => <AllowanceColumnHeader headerName={headerName} />;

    return column;
}

export { useRoleColumn, useAllowanceColumn };
