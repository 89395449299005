import { useEffect, useState } from 'react';
import { AssessmentsOverviewContextType, AssessmentsOverviewContextState } from './AssessmentsOverviewContext';
import { useAssessmentClient, useClientsClient } from '../../../../Hooks/ClientHooks';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';
import PaginationConsts from '../../../../Utils/PaginationConsts';
import useAssessmentCurrentTab from '../Utils/useAssessmentCurrentTab';
import useCompanyId from '../../../../Hooks/UseCompanyId';
import useClientIdPathParam from '../../../../Hooks/UseClientIdPathParam';
import { DefaultClientResponse } from '../../../../DTO/Clients/ClientResponse';

function useAssessmentsOverviewContextValue(initialStatus: AssessmentStatuses): AssessmentsOverviewContextType {
    const assessmentClient = useAssessmentClient();
    const clientsClient = useClientsClient();
    const currentTab = useAssessmentCurrentTab();
    const companyId = useCompanyId();
    const clientId = useClientIdPathParam();

    const [state, setState] = useState<AssessmentsOverviewContextState>({
        client: DefaultClientResponse,
        assessments: [],
        loading: true,
        status: initialStatus,
        search: '',
        currentOffset: 0,
        totalAssessmentsCount: 0,
    });

    const { client, assessments, loading, status, search, currentOffset, totalAssessmentsCount } = state;

    useEffect(() => {
        // load client
        const loadClient = async () => {
            const retrievedClient = await clientsClient.getClientByIdAsync(clientId);
            setState((prev) => {
                return {
                    ...prev,
                    client: retrievedClient,
                };
            });
        };
        loadClient();
    }, []);

    const loadPageAsync = async (offset: number, pageSize: number = PaginationConsts.regularPageSize) => {
        setState((prev) => {
            return {
                ...prev,
                loading: true,
            };
        });
        const page = await assessmentClient.getAssessmentOverviewsAsync(
            offset,
            pageSize,
            status === AssessmentStatuses.Public ? [AssessmentStatuses.NonPublic, AssessmentStatuses.Public] : [status],
            search,
            clientId,
        );
        setState((prev) => {
            return {
                ...prev,
                loading: false,
                assessments: offset === 0 ? page.data : [...prev.assessments, ...page.data],
                currentOffset: page.pagination.offset,
                totalAssessmentsCount: page.pagination.totalCount,
            };
        });
    };

    useEffect(() => {
        const doLoadAssessmentsAsync = async () => await loadPageAsync(0);
        doLoadAssessmentsAsync();
    }, [status, search, companyId]);

    const filterByStatus = (value: AssessmentStatuses) => {
        setState((prev) => {
            return {
                ...prev,
                assessments: [],
                loading: true,
                status: value,
            };
        });
    };

    const searchByName = (value: string) => {
        setState((prev) => {
            return {
                ...prev,
                assessments: [],
                search: value,
            };
        });
    };

    return {
        client,
        assessments,
        loading,
        status,
        search,
        currentOffset,
        totalAssessmentsCount,
        loadPageAsync,
        filterByStatus,
        searchByName,
        currentTab,
    };
}

export default useAssessmentsOverviewContextValue;
