import React from 'react';
import { Box } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import { useTheme } from '@mui/material/styles';

const CompanyNameSection = () => {
    const theme = useTheme();
    return (
        <Box sx={{ flex: 1, width: theme.spacing(64) }}>
            <FormikTextField id="companyName" name="companyName" type="text" fullWidth />
        </Box>
    );
};

export default CompanyNameSection;
