import { useTheme } from '@mui/material';
import { Box, SpanTypography, Stack, Typography } from '@talentmesh/core';
import { Link } from 'react-router-dom';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import { useCareerPageTabContext } from '../Contexts/CareerPageTabContext';
import RoutePath from '../../../../Routing/RoutePath';
import StatusChip from '../../../../Components/StatusChip';
import { useCompanyIdRoute } from '../../../../Routing/useCompanyIdRoute';

function CareerPageStatus(): JSX.Element {
    const theme = useTheme();
    const { isCareerPageEnabled } = useCareerPageTabContext();
    const careerPageSettingsPath = useCompanyIdRoute(RoutePath.AccountSettingsCareerPage);

    return (
        <Stack direction="column" spacing={theme.spacing(2)}>
            <Box>
                <SpanTypography variant="body1">{`${UIStrings.BenefitFromEmployerBranding} `}</SpanTypography>
                <Link to={careerPageSettingsPath}>{UIStrings.Settings.toLowerCase()}</Link>
                <SpanTypography variant="body1">.</SpanTypography>
            </Box>
            <StatusChip
                color={isCareerPageEnabled ? 'success' : 'warning'}
                label={
                    <Typography variant="body2">
                        {isCareerPageEnabled ? UIStrings.YourCareerPageIsActive : UIStrings.CareerPageNotEnabled}
                    </Typography>
                }
            />
        </Stack>
    );
}

export default CareerPageStatus;
