import { DropdownOption } from '@talentmesh/core';
import { findJobCategoryByJobFunctionId, JobCategory } from '../Models/JobCategory';
import { JobFunction } from '../Models/JobFunction';

export interface JobCategoryJobFunctionPair {
    jobCategoryName: string;
    jobFunctionName: string;
}

const other = 'Other';

export function sortByName(a: DropdownOption, b: DropdownOption): number {
    let result = 0;

    if (a.name.startsWith(other) || b.name.startsWith(other)) {
        if (a.name.startsWith(other) && !b.name.startsWith(other)) {
            result = 1;
        }

        if (!a.name.startsWith(other) && b.name.startsWith(other)) {
            result = -1;
        }
    } else {
        result = a.name.localeCompare(b.name);
    }

    return result;
}

export function mapCategoriesToOptions(jobCategories: JobCategory[]): DropdownOption[] {
    return (
        jobCategories?.map((jobCategory) => ({
            id: `${jobCategory.id}`,
            name: jobCategory.jobCategoryName,
        })) || []
    ).sort(sortByName);
}

export function mapJobFunctionsToOptions(jobFunctions: JobFunction[]): DropdownOption[] {
    return jobFunctions
        ?.map((jobFunction) => ({
            id: `${jobFunction.id}`,
            name: jobFunction.jobFunctionName,
        }))
        .sort(sortByName);
}

export function findJobCategory(jobCategories: JobCategory[], jobCategoryId: string): JobCategory | undefined {
    return jobCategories.find((item) => item.id.toString() === jobCategoryId);
}

export function findJobFunction(category: JobCategory, jobFunctionId: string): JobFunction | undefined {
    return category.jobFunctions.find((item) => item.id.toString() === jobFunctionId);
}

export function getJobCategoryJobFunctionPair(
    jobCategories: JobCategory[],
    jobFunctionId: number,
): JobCategoryJobFunctionPair {
    const jobCategory = findJobCategoryByJobFunctionId(jobCategories, jobFunctionId);
    if (!jobCategory) {
        return { jobCategoryName: '', jobFunctionName: '' };
    }

    const jobFunction = findJobFunction(jobCategory, jobFunctionId.toString());

    return {
        jobCategoryName: jobCategory.jobCategoryName,
        jobFunctionName: jobFunction ? jobFunction.jobFunctionName : '',
    };
}

export function getFullJobFunctionName(jobCategories: JobCategory[], jobFunctionId: number): string {
    const pair = getJobCategoryJobFunctionPair(jobCategories, jobFunctionId);
    return `${pair.jobCategoryName}: ${pair.jobFunctionName}`;
}

export function getJobFunctionName(jobCategories: JobCategory[], jobFunctionId: number): string {
    const jobCategory = findJobCategoryByJobFunctionId(jobCategories, jobFunctionId) as JobCategory;
    const jobFunction = findJobFunction(jobCategory, jobFunctionId.toString()) as JobFunction;
    return jobFunction.jobFunctionName;
}
