import { useTheme } from '@mui/material';
import React from 'react';
import { Button, Stack } from '@talentmesh/core';
import RoutePath from '../../../../../Routing/RoutePath';
import UIStrings from '../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../Context/ActionDialogContext';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';

interface CongratsOnHireDialogActionsProps {
    assessmentId: string;
}

function CongratsOnHireDialogActions({ assessmentId }: CongratsOnHireDialogActionsProps): JSX.Element {
    const history = useTalentMeshHistory();
    const { selectedCandidates, hideDialog } = useActionDialogContext();
    const candidate = selectedCandidates[0];

    const theme = useTheme();

    const closeAssessmentCloseDialog = () => {
        hideDialog(DialogEnum.CongratsOnHire);
    };
    return (
        <Stack sx={{ width: '100%' }} direction="row" spacing={theme.spacing(1.25)}>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                    closeAssessmentCloseDialog();

                    const queryParams = new URLSearchParams({
                        recruitmentId: assessmentId,
                        applicantId: candidate.candidateId,
                    });
                    history.push({ pathname: RoutePath.Checkout, search: queryParams.toString() });
                }}
            >
                {UIStrings.ContinueToPayment}
            </Button>
        </Stack>
    );
}
export default CongratsOnHireDialogActions;
