import React from 'react';
import { Label, Stack } from '@talentmesh/core';
import { FormikAutocompleteDropdown } from '@talentmesh/forms';
import UIStrings from '../../../../Utils/UIStrings';
import currencies from '../../../../Utils/Currency/currencies';

export const CurrencyFormikName = 'currency';

function CurrencyAutocomplete(): JSX.Element {
    return (
        <Stack sx={{ width: '100%' }} flex={1}>
            <Label>{`${UIStrings.Currency}*`}</Label>
            <FormikAutocompleteDropdown
                name={CurrencyFormikName}
                data-cy={CurrencyFormikName}
                autocompleteOptions={currencies}
                disableClearable
                freeSolo={false}
            />
        </Stack>
    );
}

export default CurrencyAutocomplete;
