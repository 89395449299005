import { useAuth0 } from '@auth0/auth0-react';
import { LoadingOverlay } from '@talentmesh/core';
import React from 'react';

const Auth0LoadingOverlay = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return children as JSX.Element;
};

export default Auth0LoadingOverlay;
