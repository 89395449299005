import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack, Typography } from '@talentmesh/core';

interface PaymentResultContentProps {
    title: string;
    descriptionLine1: string;
    descriptionLine2: string;
    imageData: string;
    onClick: () => void;
    buttonText: string;
    children?: React.ReactNode;
}

const PaymentResultContent = ({
    title,
    descriptionLine1,
    descriptionLine2,
    imageData,
    onClick,
    buttonText,
    children,
}: PaymentResultContentProps) => {
    const theme = useTheme();

    return (
        <Box sx={{ margin: { xs: `${theme.spacing(4)} auto`, sm: 'auto' } }}>
            <Stack sx={{ maxWidth: theme.spacing(52.5) }} justifyContent="center" alignItems="center">
                <Stack
                    sx={{
                        maxWidth: theme.spacing(8.75),
                        mb: theme.spacing(4),
                    }}
                >
                    <object
                        width="100%"
                        height="100%"
                        type="image/svg+xml"
                        data={imageData}
                        aria-label="Payment successful"
                    />
                </Stack>
                <Typography variant="h2" color="secondary" sx={{ marginBottom: 3 }}>
                    {title}
                </Typography>
                <Typography variant="body1" color={theme.palette.text.hint}>
                    {descriptionLine1}
                </Typography>
                <Typography sx={{ mb: theme.spacing(3) }} variant="body1" color={theme.palette.text.hint}>
                    {descriptionLine2}
                </Typography>
                <Button size="small" variant="contained" color="secondary" onClick={onClick}>
                    {buttonText}
                </Button>
                {children && children}
            </Stack>
        </Box>
    );
};

export default PaymentResultContent;
