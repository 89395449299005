import React from 'react';
import { useTheme } from '@mui/material';
import { Box, Stack, Toaster } from '@talentmesh/core';
import PageHeader from '../../../../Components/PageHeader';
import HeaderActions from './Header/HeaderActions';
import HeaderInfo from './Header/HeaderInfo';
import DownloadButton from './Common/DownloadButton';
import useCandidateResultsHeader from './Hooks/UseCandidateResultsHeader';
import { useCandidatesResultsContext } from '../Contexts/CandidateResultsContext';
import CandidateResultTalentScoreGroup from './Header/CandidateResultTalentScoreGroup';
import AssessmentResetDialog from '../../../Dialogs/AssessmentResetDialog/AssessmentResetDialog';
import UIStrings from '../../../../Utils/UIStrings';
import ResultsTab from './ResultsTab';

function ApplicantResultsHeader(): JSX.Element {
    const theme = useTheme();

    const {
        results,
        resetAssessmentAsync,
        openResetToaster,
        setOpenResetToaster,
        resetToasterStatus,
        resetToasterMessage,
    } = useCandidatesResultsContext();

    // TalentScore is only available when both tests are completed and average experience criteria score is calculated
    // We only allow to download report when all scores are in place.
    const disableDownloadButton = results.talentScore === undefined || results.talentScore === null;

    const { isReportDownloading, downloadReportHandler, openAssessmentResetDialog, setOpenAssessmentResetDialog } =
        useCandidateResultsHeader();

    return (
        <PageHeader>
            <Stack spacing={2.5}>
                <Stack spacing={1.25} direction="row" sx={{ alignItems: 'center', paddingY: theme.spacing(1.25) }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <HeaderInfo />
                    </Box>
                    <CandidateResultTalentScoreGroup
                        level={results.talentScore?.scoreLevel || 'Low'}
                        progress={results.talentScore?.talentScore}
                    />
                    <Box>
                        <DownloadButton
                            disabled={disableDownloadButton}
                            onClick={downloadReportHandler}
                            loading={isReportDownloading}
                            data-cy="downloadReportButton"
                            label={UIStrings.DownloadReport}
                        />
                    </Box>
                    <HeaderActions setOpenAssessmentResetDialog={setOpenAssessmentResetDialog} />
                </Stack>
                <ResultsTab />
            </Stack>
            <AssessmentResetDialog
                open={openAssessmentResetDialog}
                setOpen={setOpenAssessmentResetDialog}
                onConfirm={resetAssessmentAsync}
            />
            <Toaster
                open={openResetToaster}
                close={() => setOpenResetToaster(false)}
                alertMessage={resetToasterMessage}
                status={resetToasterStatus}
                autoHideDuration={5000}
            />
        </PageHeader>
    );
}

export default ApplicantResultsHeader;
