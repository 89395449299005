import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Stack, Step, StepIcon, StepLabel, Typography } from '@talentmesh/core';
import CustomStepper from './Steps/CustomStepper';
import { StepMapperProps } from './StepMappers';

interface ModifyRecruitmentStepperProps {
    steps: string[];
    stepMapper: (props: StepMapperProps) => JSX.Element;
    pageContentContainerRef: React.RefObject<HTMLDivElement>;
}

function ModifyRecruitmentStepper({
    steps,
    stepMapper: StepMapper,
    pageContentContainerRef,
}: ModifyRecruitmentStepperProps): JSX.Element {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<Array<boolean>>(Array.from({ length: steps.length }, () => false));
    const theme = useTheme();

    const handleStep = (step: number) => () => {
        if (completed[step]) {
            setCompleted((prevState) => {
                const next = [...prevState];
                for (let i = activeStep; i >= step; i -= 1) {
                    next[i] = false;
                }
                return next;
            });
            setActiveStep(step);
        }
    };

    useEffect(() => {
        if (pageContentContainerRef.current) {
            pageContentContainerRef.current.scrollTo(0, 0); // Scroll to top on step change
        }
    }, [activeStep, pageContentContainerRef]);

    const handleNext = () => {
        setCompleted((prevState) => {
            const next = [...prevState];
            next[activeStep] = true;
            return next;
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setCompleted((prevState) => {
            const next = [...prevState];
            next[activeStep] = false;
            next[activeStep - 1] = false;
            return next;
        });
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
        <Stack spacing={theme.spacing(1)} marginTop={theme.spacing(6)}>
            <CustomStepper activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} active={activeStep === index} completed={completed[index]}>
                        <StepLabel
                            StepIconComponent={StepIcon}
                            onClick={handleStep(index)}
                            sx={{
                                cursor: completed[index] ? 'pointer' : undefined,
                            }}
                            icon={
                                <Typography variant="subtitle2" color={theme.palette.common.white} visibility="hidden">
                                    {index + 1}
                                </Typography>
                            }
                        >
                            <Typography
                                variant="body1"
                                fontWeight={theme.typography.fontWeightBold}
                                color={
                                    completed[index] || activeStep === index
                                        ? theme.palette.primary.main
                                        : theme.palette.text.disabled
                                }
                            >
                                {label}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </CustomStepper>
            <StepMapper activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
        </Stack>
    );
}
export default ModifyRecruitmentStepper;
