import { useAuth0 } from '@auth0/auth0-react';

interface UseWrongEmailInstructionsInterface {
    createNewAccountHandler: () => void;
    loginAgainHandler: () => void;
}

function useWrongEmailInstructionsHandlers(): UseWrongEmailInstructionsInterface {
    const { host, protocol } = window.location;
    const { logout } = useAuth0();

    const createNewAccountHandler = () => {
        const registerUrl = `${protocol}//${host}/register`;
        logout({ returnTo: registerUrl });
    };

    const loginAgainHandler = () => {
        logout({ returnTo: `${protocol}//${host}` });
    };
    return {
        createNewAccountHandler,
        loginAgainHandler,
    };
}

export default useWrongEmailInstructionsHandlers;
