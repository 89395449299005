import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import {
    CareerPageStats,
    DefaultCareerPageStats,
    mapCareerPageStatsDTOtoCareerPageStats,
} from '../../../Models/Career';
import { jobsBaseUrl } from '../../../AppSettings';
import RoutePath from '../../../Routing/RoutePath';
import { useCareerClient } from '../../../Hooks/ClientHooks';
import useTalentMeshHistory from '../../../Routing/useTalentMeshHistory';
import useCompanyId from '../../../Hooks/UseCompanyId';
import { useCompanyContext } from '../../../Context/CompanyContext';

interface UseCareerPageTabState {
    stats: CareerPageStats;
    isLoading: boolean;
    isLoadingFailed: boolean;
}

interface UseCareeerPageTabInterface extends UseCareerPageTabState {
    handleVisitCareerPageBuilder: () => void;
    handleVisitCareerPage: () => void;
}

function useCareerPageTab(): UseCareeerPageTabInterface {
    const [state, setState] = useState<UseCareerPageTabState>({
        stats: DefaultCareerPageStats,
        isLoading: true,
        isLoadingFailed: false,
    });
    const history = useTalentMeshHistory();
    const {
        currentCompanyProfile: { companySlug },
    } = useCompanyContext();
    const companyId = useCompanyId();
    const careerClient = useCareerClient();
    const location = useLocation();

    const { stats, isLoading, isLoadingFailed } = state;

    useEffect(() => {
        const doLoadStats = async () => {
            try {
                setState((prev) => ({ ...prev, isLoading: true, isLoadingFailed: false }));
                const dto = await careerClient.getCareerPageStatsAsync(companyId);

                const result = mapCareerPageStatsDTOtoCareerPageStats(dto);
                setState((prev) => ({ ...prev, stats: result, isLoading: false }));
            } catch (error) {
                setState((prev) => ({ ...prev, isLoadingFailed: true, isLoading: false }));
            }
        };
        doLoadStats();
    }, []);

    const handleVisitCareerPageBuilder = () => {
        history.push(RoutePath.CareerBuilder, { from: location.pathname });
    };

    const handleVisitCareerPage = () => {
        window.open(`${jobsBaseUrl}/${companySlug}`, '_blank');
    };

    return {
        stats,
        isLoading,
        isLoadingFailed,
        handleVisitCareerPageBuilder,
        handleVisitCareerPage,
    };
}

export default useCareerPageTab;
