import React from 'react';
import { useTheme } from '@mui/material';
import { Stack } from '@talentmesh/core';
import Header from './Components/Header';
import Instructions from './Components/Instructions';

function InvitationLink(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            direction="column"
            sx={{
                borderBottomLeftRadius: theme.shape.smallBorderRadius,
                borderBottomRightRadius: theme.shape.smallBorderRadius,
                backgroundColor: theme.palette.common.white,
                borderWidth: '1px',
                borderColor: theme.palette.border.main,
                padding: theme.spacing(2.5),
            }}
            spacing={1.25}
        >
            <Header />
            <Instructions />
        </Stack>
    );
}

export default InvitationLink;
