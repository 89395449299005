import React from 'react';
import { TabsProps, Tabs } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import JobBoardOption from './JobBoardOption';
import { JobBoards } from './AvailableJobBoards';

interface JobBoardsScrollableListProps extends Pick<TabsProps, 'value' | 'onChange'> {}

const JobBoardsScrollableList = ({ value, onChange }: JobBoardsScrollableListProps): JSX.Element => {
    const theme = useTheme();
    const scrollButtonSize = theme.spacing(5.5);
    const halfOfScrollButtonSize = theme.spacing(2.75);

    return (
        <Tabs
            value={value}
            onChange={onChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
                position: 'relative',

                '& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons': {
                    height: scrollButtonSize,
                    width: scrollButtonSize,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    margin: 'auto',
                    top: `calc(50% - ${halfOfScrollButtonSize} - ${theme.spacing(1.25)})`,
                    opacity: 1,

                    '&:first-of-type': {
                        marginRight: theme.spacing(0.625),
                    },

                    '&:last-of-type': {
                        marginLeft: theme.spacing(0.625),
                    },
                },

                '& .MuiButtonBase-root.MuiTab-root': {
                    position: 'relative',
                    marginRight: theme.spacing(1.875),
                    padding: theme.spacing(2.5, 1),
                    border: `2px solid ${theme.palette.border.main}`,
                    borderRadius: theme.shape.smallBorderRadius,

                    '&.Mui-selected': {
                        borderColor: theme.palette.primary.main,
                        boxShadow: theme.shadows[6],
                    },
                },

                '& .MuiTabs-indicator': {
                    display: 'none',
                },

                '& .MuiTabs-scroller': {
                    paddingY: theme.spacing(3.75),
                },
            }}
        >
            {JobBoards.map((item, index) => (
                <JobBoardOption key={item.name} jobBoard={item} value={index.toString()} />
            ))}
        </Tabs>
    );
};

export default JobBoardsScrollableList;
