import { Box } from '@talentmesh/core';
import React from 'react';
import TitleWings from './TitleWings.svg';

interface WingsIconProps {
    marginRight?: string;
}

function WingsIcon({ marginRight }: WingsIconProps): JSX.Element {
    const alt = 'smallLoadingWings';
    return (
        <Box
            sx={{
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                marginRight,
            }}
            data-cy="JobAdTitleGenerationLoadingOverlay"
        >
            <object type="image/svg+xml" data={TitleWings} aria-label={alt} />
        </Box>
    );
}

export default WingsIcon;
