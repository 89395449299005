import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { CompanyLogo, Stack, Paper, ContainerBackground, LoadingOverlay } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserSignupNavigationContext } from '../../Context/UserSignupNavigationContext';
import RoutePath from '../../Routing/RoutePath';
import togglePageOverflow from '../../Utils/HTMLElementHelpers';
import { HorizontalDivider } from '../Candidates/Results/Components/Tabs/Components/Common/CustomDivider';
import AccordionPanel from './Components/AccordionPanel';
import VerifyEmailInstructions from './Components/VerifyEmailInstructions';

function VerifyEmail(): JSX.Element {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const { user } = useAuth0();
    const { isLoading } = useUserSignupNavigationContext();

    useEffect(() => {
        togglePageOverflow<HTMLBodyElement>('body', isTablet);

        return () => {
            togglePageOverflow<HTMLBodyElement>('body', isTablet);
        };
    }, [isTablet]);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (user?.email_verified) {
        return <Navigate to={RoutePath.Provision} />;
    }

    return (
        <ContainerBackground>
            <Stack
                alignSelf={{ xs: 'start', md: 'center' }}
                alignItems="center"
                spacing={3.75}
                sx={{
                    marginY: { xs: theme.spacing(3.75), md: 0 },
                    marginX: { xs: theme.spacing(2), md: 0 },
                    maxWidth: { xs: theme.spacing(42.875), sm: theme.spacing(54.5) },
                }}
            >
                <CompanyLogo height={theme.spacing(4.6875)} width={theme.spacing(24.375)} />
                <Paper elevation={5} sx={{ padding: 0, borderRadius: theme.shape.smallBorderRadius }}>
                    <VerifyEmailInstructions email={user?.email as string} />
                    <HorizontalDivider sx={{ margin: 0 }} />
                    <AccordionPanel />
                </Paper>
            </Stack>
        </ContainerBackground>
    );
}
export default VerifyEmail;
