import { useTheme } from '@mui/material/styles';
import { Card, CardContent } from '@talentmesh/core';
import React, { ReactNode } from 'react';

interface DetailsCardProps {
    children: ReactNode;
}

const DetailsCard = ({ children }: DetailsCardProps) => {
    const theme = useTheme();
    return (
        <Card
            elevation={0}
            sx={{
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.mediumBorderRadius,
                flexGrow: 1,
            }}
        >
            <CardContent
                sx={{
                    padding: 2.5,
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
};

export default DetailsCard;
