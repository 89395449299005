import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@talentmesh/core';
import { PreviewIcon } from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../../Utils/UIStrings';

function PreviewDisclaimer(): JSX.Element {
    const { palette } = useTheme();
    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <PreviewIcon
                sx={{
                    color: palette.grey[500],
                }}
            />
            <Stack direction="column">
                <Typography variant="body1" color={palette.grey[500]}>
                    {UIStrings.ThisIsAPreviewOfHowJobAdPostMayAppear}
                </Typography>
                <Typography variant="body1" color={palette.grey[500]}>
                    {UIStrings.PleaseNoteLiveVersionMayBeDifferent}
                </Typography>
            </Stack>
        </Stack>
    );
}

export default PreviewDisclaimer;
