import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Button } from '@talentmesh/core';
import { Stack, useTheme } from '@mui/material';
import UIStrings from '../../Utils/UIStrings';
import DefaultErrorDescription from './Components/DefaultErrorDescription';
import ErrorDialog from './Components/ErrorDialog';
import ErrorQueryNames from './ErrorQueryNames';
import useQuery from '../../Hooks/UseQuery';

function ErrorPage(): JSX.Element {
    const titleFromQuery = useQuery(ErrorQueryNames.ErrorTitle);
    const descriptionFromQuery = useQuery(ErrorQueryNames.ErrorDescription);
    const renderLoginButton = useQuery(ErrorQueryNames.LoginAvailable);
    const theme = useTheme();
    const { buildAuthorizeUrl } = useAuth0();

    const title = titleFromQuery || UIStrings.OopsError;
    const description = descriptionFromQuery || UIStrings.SomethingWentWrongLong;

    const clickHandler = async () => {
        window.location.href = await buildAuthorizeUrl();
    };

    return (
        <ErrorDialog
            errorTitle={title}
            errorBody={
                <Stack direction="column" spacing={theme.spacing(1)}>
                    <DefaultErrorDescription errorDescription={description} />
                    {renderLoginButton && (
                        <Button
                            href="/login"
                            variant="contained"
                            color="primary"
                            sx={{
                                width: theme.spacing(12),
                                alignSelf: 'center',
                            }}
                            onClick={clickHandler}
                        >
                            {UIStrings.LogIn}
                        </Button>
                    )}
                </Stack>
            }
        />
    );
}

export default ErrorPage;
