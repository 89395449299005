import React, { useState, useRef, useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import CheckboxRow, { CustomMenuItemWithValueProps } from './CheckboxRow';

export interface ListVirtualizationProps {
    items: Array<CustomMenuItemWithValueProps>;
    itemHeight: number;
    containerHeight: number;
}

function ListVirtualization({ items, itemHeight, containerHeight }: ListVirtualizationProps) {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement>(null);

    const [scrollTop, setScrollTop] = useState(0);

    const startIndex = Math.floor(scrollTop / itemHeight);
    const endIndex = Math.min(startIndex + Math.ceil(containerHeight / itemHeight), items.length);

    const visibleItems = useMemo(() => items.slice(startIndex, endIndex), [items, startIndex, endIndex]);
    const invisibleItemsHeight = (startIndex + visibleItems.length - endIndex) * itemHeight;

    const handleScroll = useCallback(() => {
        if (ref.current) {
            setScrollTop(ref.current.scrollTop);
        }
    }, []);

    return (
        <div
            ref={ref}
            style={{
                maxHeight: `${containerHeight}px`,
                overflowY: 'auto',
                paddingBlock: theme.spacing(1.25),
            }}
            onScroll={handleScroll}
        >
            <div style={{ height: `${items.length * itemHeight}px` }}>
                <div
                    style={{
                        position: 'relative',
                        height: `${visibleItems.length * itemHeight}px`,
                        top: `${startIndex * itemHeight}px`,
                    }}
                >
                    {visibleItems.map((item) => (
                        <CheckboxRow key={item.id} {...item} style={{ height: `${itemHeight}px` }} />
                    ))}
                </div>
                <div style={{ minHeight: `${invisibleItemsHeight}px` }} />
            </div>
        </div>
    );
}

export default ListVirtualization;
