import React from 'react';
import { SpanTypography, TypographyProps } from '@talentmesh/core';
import { useTheme } from '@mui/material';

function H4Typography({ children, ...props }: TypographyProps): JSX.Element {
    const theme = useTheme();
    return (
        <SpanTypography variant="h4" color={theme.palette.text.menuTitle} {...props}>
            {children}
        </SpanTypography>
    );
}
export default H4Typography;
