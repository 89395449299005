import { useState } from 'react';
import { DownloadReportStatusContextData } from './UseDownloadReportStatusContext';

function useDownloadReportStatusContextValue(): DownloadReportStatusContextData {
    const [downloads, setDownloads] = useState(new Set<string>());

    const getKey = (assessmentId: string, candidateId: string) => `${assessmentId}-${candidateId}`;

    const start = (assessmentId: string, candidateId: string) => {
        setDownloads((prevState) => {
            const key = getKey(assessmentId, candidateId);
            if (prevState.has(key)) {
                return prevState;
            }
            const next = new Set<string>(prevState);
            next.add(key);
            return next;
        });
    };

    const complete = (assessmentId: string, candidateId: string) => {
        setDownloads((prevState) => {
            const next = new Set<string>(prevState);
            next.delete(getKey(assessmentId, candidateId));
            return next;
        });
    };

    return {
        downloads,
        start,
        complete,
        getKey,
    };
}

export default useDownloadReportStatusContextValue;
