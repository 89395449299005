import { TestTypes } from '../../../../../../../../../../../../Models/Configuration';
import TestSelectionFormFieldNames from '../../../../../../../Models/TestSelectionFormFieldNames';

function getFieldNameByTestType(testType: TestTypes): string {
    switch (testType) {
        case TestTypes.Personality:
            return TestSelectionFormFieldNames.isPersonalitySelected;
        case TestTypes.Aptitude:
            return TestSelectionFormFieldNames.isAptitudeSelected;
        case TestTypes.Skills:
            return TestSelectionFormFieldNames.isSkillsSelected;
        case TestTypes.EmotionalIntelligence:
            return TestSelectionFormFieldNames.isEmotionalIntelligenceSelected;
        default:
            throw new Error(`Test type '${testType}' is not supported`);
    }
}

export default getFieldNameByTestType;
