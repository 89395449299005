import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';

const NoTeamMembersOverlay = () => {
    return (
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
            <Typography variant="body1">{UIStrings.NoTeamMembersFound}</Typography>
        </Stack>
    );
};

export default NoTeamMembersOverlay;
