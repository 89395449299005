import React, { useEffect } from 'react';
import { BrandedCardContainer, ContainerBackground, LoadingOverlay } from '@talentmesh/core';
import useAcceptInvitationState from './Hooks/useAcceptInvitationState';
import AccountCreationForm from '../../Components/AccountCreation/AccountCreationForm';
import UIStrings from '../../Utils/UIStrings';
import { useUserContext } from '../../Context/UserContext';

function AcceptInvitationPage() {
    const { companyName, isLoading, allowAccountSetup, isSubmitting, submitHandler, isError } =
        useAcceptInvitationState();
    const { user } = useUserContext();

    useEffect(() => {
        if (!allowAccountSetup) {
            const { firstName, lastName } = user;
            submitHandler({ firstName, lastName, companyName });
        }
    }, [allowAccountSetup]);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return allowAccountSetup ? (
        <ContainerBackground>
            <BrandedCardContainer header={UIStrings.SetupYourProfile}>
                <AccountCreationForm
                    initialFirstName={user.firstName || ''}
                    initialLastName={user.lastName || ''}
                    initialCompanyName={companyName}
                    isSubmitting={isSubmitting}
                    submitHandler={submitHandler}
                    isError={isError}
                />
            </BrandedCardContainer>
        </ContainerBackground>
    ) : (
        <LoadingOverlay />
    );
}

export default AcceptInvitationPage;
