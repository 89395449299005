import React from 'react';
import { Box } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import useVerifiedOnMobileInstructions from '../Utils/useVerifiedOnMobileInstructions';
import ContentLink from './ContentLink';
import InstructionsSpanTypography from './InstructionsSpanTypography';

function VerifiedOnMobileInstructions(): JSX.Element {
    const { proceedToProductHandler } = useVerifiedOnMobileInstructions();
    return (
        <Box>
            <InstructionsSpanTypography>{`${UIStrings.IfIHaveAlreadyVerifiedOnMyMobileDevice} `}</InstructionsSpanTypography>
            <ContentLink onClick={proceedToProductHandler}>{UIStrings.ClickHereToProceed}</ContentLink>
            <InstructionsSpanTypography>{` ${UIStrings.ToTalentmesh}`}</InstructionsSpanTypography>
        </Box>
    );
}

export default VerifiedOnMobileInstructions;
