import { SxProps } from '@mui/system';
import React, { ReactNode } from 'react';
import { Grid } from '@talentmesh/core';

interface ThreeColumnContainerProps {
    left: ReactNode;
    children: ReactNode;
    right?: ReactNode;
    sx?: SxProps;
}

const ThreeColumnContainer = ({ left, children, right, sx }: ThreeColumnContainerProps) => {
    return (
        <Grid sx={sx} container spacing={3}>
            <Grid item xs={12} sm={2} md={2}>
                {left}
            </Grid>
            <Grid item xs={12} sm={9} md={8}>
                {children}
            </Grid>
            <Grid item xs={12} sm={1} md={2}>
                {right}
            </Grid>
        </Grid>
    );
};

export default ThreeColumnContainer;
