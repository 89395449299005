import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../../../../../Utils/UIStrings';

function BenchmarkHeader(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography textAlign="center" variant="h3" color={theme.palette.text.menuTitle}>
                {UIStrings.ScoreBenchmarks}
            </Typography>
            <Typography textAlign="center" variant="body2" color={theme.palette.text.menuTitle}>
                {UIStrings.CompareTheOverallScore}
            </Typography>
        </Stack>
    );
}

export default BenchmarkHeader;
