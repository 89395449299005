import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import React from 'react';
import { Box, LoadingButton, Typography } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import UIStrings from '../../Utils/UIStrings';
import useAccountFormValidationScheme from './useAccountCreationFormValidation';
import SubmissionError from '../../Pages/AcceptInvitation/Components/SumbissionError';

export interface AccountCreationFormValues {
    firstName: string;
    lastName: string;
    companyName: string;
}

export interface AccountCreationFormAPI {
    isLoading: boolean;
    isSubmitting: boolean;
    allowAccountSetup: boolean;
    submitHandler: (values: AccountCreationFormValues) => Promise<void>;
}

interface AccountCreationFormProps {
    initialCompanyName: string;
    initialFirstName: string;
    initialLastName: string;
    isSubmitting: boolean;
    submitHandler: (values: AccountCreationFormValues) => Promise<void>;
    isError?: boolean;
}

const AccountCreationForm = ({
    initialCompanyName,
    initialFirstName,
    initialLastName,
    submitHandler,
    isSubmitting,
    isError,
}: AccountCreationFormProps) => {
    const theme = useTheme();

    const validationSchema = useAccountFormValidationScheme();

    return (
        <Formik
            initialValues={{
                firstName: initialFirstName,
                lastName: initialLastName,
                companyName: initialCompanyName,
            }}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
            validateOnChange={false}
            validateOnBlur={false}
        >
            <Form>
                <Stack spacing={3} direction="column">
                    {isError && <SubmissionError />}
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        {UIStrings.ApplicantsWillSeeThisInEmails}
                    </Typography>
                    <Stack direction="row" flexWrap="wrap">
                        <Box flexBasis={{ xs: '100%', sm: '45%' }}>
                            <FormikTextField
                                id="firstName"
                                name="firstName"
                                type="text"
                                label={UIStrings.FirstName}
                                fullWidth
                            />
                        </Box>
                        <Box
                            flexGrow="1"
                            flexBasis={{ xs: '100%', sm: '45%' }}
                            sx={{
                                marginTop: { xs: theme.spacing(3), sm: 0 },
                                marginLeft: { xs: 0, sm: theme.spacing(3) },
                            }}
                        >
                            <FormikTextField
                                id="lastName"
                                name="lastName"
                                type="text"
                                label={UIStrings.LastName}
                                fullWidth
                            />
                        </Box>
                    </Stack>
                    <FormikTextField
                        id="companyName"
                        name="companyName"
                        type="text"
                        label={`${UIStrings.CompanyName}*`}
                        fullWidth
                        disabled={initialCompanyName !== ''}
                    />
                </Stack>
                <LoadingButton
                    loading={isSubmitting}
                    sx={{
                        marginTop: theme.spacing(4),
                        textTransform: 'none',
                        letterSpacing: '0.09375rem',
                    }}
                    size="large"
                    fullWidth
                    variant="contained"
                    type="submit"
                >
                    {UIStrings.SaveAndContinue}
                </LoadingButton>
            </Form>
        </Formik>
    );
};

export default AccountCreationForm;
