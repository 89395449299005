import React from 'react';
import { Tab, TabProps } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import JobBoardOptionContent, { JobBoardOptionContentProps } from './JobBoardOptionContent';

interface JobBoardOptionProps extends TabProps {
    jobBoard: JobBoardOptionContentProps;
}

const JobBoardOption = ({ sx, jobBoard, ...props }: JobBoardOptionProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Tab
            {...props}
            sx={{
                ...sx,
                width: theme.spacing(33),
                height: theme.spacing(21.375),
                backgroundColor: theme.palette.common.white,
            }}
            label={<JobBoardOptionContent {...jobBoard} />}
            data-cy={`job-board-${jobBoard.name}`}
        />
    );
};

export default JobBoardOption;
