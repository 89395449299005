import { Loader } from '@googlemaps/js-api-loader';
import React, { useRef, useState } from 'react';
import { LoadingOverlay } from '@talentmesh/core';
import { useNotificationContext } from '../Context/NotificationContext';

// eslint-disable-next-line func-names
const withGooglePlacesAPICheck = function <T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) {
    return (props: T) => {
        const { showFailToaster } = useNotificationContext();
        const loaded = useRef(false);
        const [isLoading, setIsLoading] = useState(true);

        if (!loaded.current) {
            const loader = new Loader({
                apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '',
                libraries: ['places'],
            });

            loader
                .load()
                .then(() => {
                    loaded.current = true;
                })
                .catch(() => showFailToaster())
                .finally(() => setIsLoading(false));
        }

        return <>{isLoading ? <LoadingOverlay /> : <Component {...props} />}</>;
    };
};

export default withGooglePlacesAPICheck;
