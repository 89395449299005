import React from 'react';
import withSignupChecks from '../../Routing/withSignupChecks';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';
import CareerBuilder from './Builder/CareerBuilder';
import { CareerBuilderContextProvider } from './Contexts/CareerBuilderContext';
import withCompanyProfileRequired from '../../Routing/withCompanyProfileRequired';

const CareerBuilderPage = (): JSX.Element => {
    return (
        <CareerBuilderContextProvider>
            <CareerBuilder />
        </CareerBuilderContextProvider>
    );
};

export default withCompanyProfileRequired(withTalentMeshUserRequired(withSignupChecks(CareerBuilderPage)));
