import { DefaultDemoQuestionDTO, DemoQuestionsDTO } from '../DTO/Questions/DemoQuestionsDTO';
import { JobTestInfoDTO } from '../DTO/Questions/JobTestInfoDTO';
import { DefaultVersionDto, VersionDto } from '../DTO/VersionDto';
import { get } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class JobTestInfoClient extends ClientBase {
    async getVersionAsync(): Promise<VersionDto> {
        const response = await get<VersionDto>({
            path: this.addHost(`questions/version`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultVersionDto;
    }

    async getTestInfosAsync(jobFunctionId: number): Promise<JobTestInfoDTO[]> {
        const response = await get<JobTestInfoDTO[]>({
            path: this.addHost(`questions/infos?jobFunctionId=${jobFunctionId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return [];
    }

    async getDemoQuestionsAsync(jobFunctionId: number, locale: string) {
        const response = await get<DemoQuestionsDTO>({
            path: this.addHost(`questions/questions/demo?jobFunctionId=${jobFunctionId}&locale=${locale}`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return DefaultDemoQuestionDTO;
    }
}
