import React from 'react';
import FormikAutocompleteLocation from './FormikAutocompleteLocation';
import JobLocationLabel from './JobLocationLabel';

function JobLocation() {
    if (
        (window as any).google === undefined ||
        (window as any).google.maps === undefined ||
        (window as any).google.maps.places === undefined
    ) {
        return null;
    }

    return <FormikAutocompleteLocation label={<JobLocationLabel />} />;
}

export default JobLocation;
