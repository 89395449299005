import React from 'react';
import { ImageCropInner, ImageCropInnerProps } from './ImageCropInner';
import { ImageCropContextProvider, ImageCropProviderProps } from './ImageCropContext';

export interface ImageCropProps extends ImageCropProviderProps, ImageCropInnerProps {}

export const ImageCrop = ({
    sx,
    src,
    strings,
    inputProps,
    aspect,
    circularCrop,
    validateCallback,
    uploadCallbackAsync,
    resetCallbackAsync,
    processingErrorCallback,
}: ImageCropProps): JSX.Element => {
    return (
        <ImageCropContextProvider
            uploadCallbackAsync={uploadCallbackAsync}
            resetCallbackAsync={resetCallbackAsync}
            processingErrorCallback={processingErrorCallback}
        >
            <ImageCropInner
                sx={sx}
                src={src}
                strings={strings}
                inputProps={inputProps}
                aspect={aspect}
                circularCrop={circularCrop}
                validateCallback={validateCallback}
            />
        </ImageCropContextProvider>
    );
};
