import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import React from 'react';
import { jobsBaseUrl } from '../../../AppSettings';

function SubdomainSection(): JSX.Element {
    const theme = useTheme();
    return (
        <Stack flexDirection="row" alignItems="start" width={theme.spacing(64)}>
            <Typography
                variant="body1"
                color={theme.palette.grey[500]}
                sx={{
                    paddingRight: 1,
                    whiteSpace: 'nowrap',
                    flex: '0 0 auto',
                    paddingTop: 2,
                }}
            >
                {`${new URL(jobsBaseUrl).hostname}/`}
            </Typography>
            <FormikTextField id="companySlug" name="companySlug" type="text" />
        </Stack>
    );
}

export default SubdomainSection;
