import React from 'react';
import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import { RoleItemProps } from '../../Hooks/useRolePickerColumns';

interface RoleColumnCellProps {
    value: RoleItemProps;
}

function RoleColumnCell({ value }: RoleColumnCellProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack>
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {value.name}
            </Typography>
            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                {value.description}
            </Typography>
        </Stack>
    );
}

export default RoleColumnCell;
