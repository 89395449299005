import React from 'react';
import { BookOutlinedIcon } from '@talentmesh/icons';
import ProfileChip from './ProfileChip';
import ProfileDetailLabel from './ProfileDetailLabel';
import { getEducationLevelLabel } from '../../../Results/Components/Tabs/Details/Components/Qualification/QualificationUtils';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';
import UIStrings from '../../../../../Utils/UIStrings';

export interface ProfileEducationProps extends Pick<CandidateOverview, 'education'> {}

function ProfileEducation({ education }: ProfileEducationProps): JSX.Element {
    const fieldOfEducation = education.educationField.userDefined ?? '';
    const levelOfEducation = getEducationLevelLabel(education.educationLevel);
    const schoolName = education.schoolName ?? '';

    return (
        <ProfileChip
            testLabel={UIStrings.Education}
            label={`${levelOfEducation}, ${fieldOfEducation}`}
            icon={<BookOutlinedIcon />}
            hoverContent={
                <>
                    <ProfileDetailLabel name={UIStrings.HighestLevelOfEducation} value={levelOfEducation} />
                    <ProfileDetailLabel name={UIStrings.FieldOfEducation} value={fieldOfEducation} />
                    <ProfileDetailLabel name={UIStrings.NameOfSchool} value={schoolName} />
                </>
            }
        />
    );
}

export default ProfileEducation;
