import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@talentmesh/core';
import CandidatesList from './Components/CandidatesList';
import CandidatesToolbar from './Components/CandidatesToolbar';
import CandidateAddSection from './Components/CandidateAddSection';
import SendInvitationsButton from './Components/SendInvitationsButton';
import { useInviteCandidateContext } from '../Contexts/InviteCandidateContext';
import { Candidate } from '../../../../Models/Candidate';

function EditCandidatesPanel(): JSX.Element {
    const theme = useTheme();
    const { candidates, dirtyCount } = useInviteCandidateContext();
    const [editableCandidate, setEditableCandidate] = useState<Candidate | undefined>();

    const disableSendInviteButton =
        dirtyCount === 0 || (editableCandidate !== undefined && editableCandidate.email !== '');

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: theme.shape.smallBorderRadius,
                padding: theme.spacing(2.5),
            }}
        >
            <CandidateAddSection />
            {candidates.length > 0 && (
                <Stack
                    sx={{
                        borderRadius: theme.shape.smallBorderRadius,
                        backgroundColor: theme.palette.grey[100],
                        paddingInline: theme.spacing(1.25),
                        paddingBottom: theme.spacing(1.25),
                    }}
                >
                    <CandidatesToolbar />
                    <CandidatesList editableCandidate={editableCandidate} setEditableCandidate={setEditableCandidate} />
                    <SendInvitationsButton disabled={disableSendInviteButton} />
                </Stack>
            )}
        </Stack>
    );
}

export default EditCandidatesPanel;
