import { useTheme } from '@mui/material';
import React from 'react';
import { SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

interface DialogCardInterviewHeaderProps {
    name: string;
}

function DialogCardInterviewHeader({ name }: DialogCardInterviewHeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <SpanTypography variant="h5" color={theme.palette.text.menuTitle}>
            {`${UIStrings.Invite} ${name} ${UIStrings.ForAnInterview}`}
        </SpanTypography>
    );
}

export default DialogCardInterviewHeader;
