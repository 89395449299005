import LocationPrediction from '../../../../../../../Models/LocationPrediction';

export default interface FormikAutocompleteLocationState {
    value: LocationPrediction | null;
    inputValue: string;
    options: readonly LocationPrediction[];
    open: boolean;
}

export const DefaultFormikAutocompleteLocationState: FormikAutocompleteLocationState = {
    value: null,
    inputValue: '',
    options: [],
    open: false,
};
