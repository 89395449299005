import React from 'react';
import { Box, Divider, Stack } from '@talentmesh/core';
import { PaymentIntent } from '../../../Models/PaymentIntent';
import PriceEntry from './PriceEntry';
import UIStrings from '../../../Utils/UIStrings';
import TotalAmountLine from './TotalAmountLine';

interface PriceBreakdownProps {
    paymentIntent: PaymentIntent | undefined;
}

const PriceBreakdown = ({ paymentIntent }: PriceBreakdownProps) => {
    return (
        <Box>
            <Stack sx={{ margin: 2.5 }} spacing={1}>
                <PriceEntry
                    description={UIStrings.FeeAmount}
                    amount={paymentIntent?.amount || 0}
                    currency={paymentIntent?.currency || ''}
                />
                <PriceEntry description={UIStrings.Taxes} amount={0} currency={paymentIntent?.currency || ''} />
                <Divider />
                <TotalAmountLine
                    description={UIStrings.TotalAmount}
                    amount={paymentIntent?.amount || 0}
                    currency={paymentIntent?.currency || ''}
                />
            </Stack>
        </Box>
    );
};

export default PriceBreakdown;
