// TODO: https://dev.azure.com/TalentMesh/TalentMesh/_sprints/taskboard/TalentMesh%20Team/TalentMesh/Sprint%2010?workitem=726
enum CandidateFilterByEnum {
    InvitedStatus = 'Invited',
    AssessmentOpenedStatus = 'Assessment Opened',
    AssessmentOngoingStatus = 'Assessment Ongoing',
    AssessmentCompletedStatus = 'Assessment Completed / Retaken',
    InterviewingStatus = 'Interviewing',
    FavoriteCandidates = 'Favorite',
}

export default CandidateFilterByEnum;
