import { Skeleton, SkeletonProps, useTheme } from '@mui/material';
import { Paper, Stack } from '@talentmesh/core';
import React from 'react';
import CriteriaTextAreaInput, { CriteriaTextAreaInputProps } from './CriteriaTextAreaInput';
import CriteriaFieldLabel, { CriteriaFieldLabelProps } from './CriteriaFieldLabel';

interface CriteriaFieldProps extends CriteriaFieldLabelProps, CriteriaTextAreaInputProps {
    /**
     * leaving this empty will result in a loading animation
     */
    name?: string;
}

const CriteriaField = ({ index, commentary, disabled, name, onRemove }: CriteriaFieldProps) => {
    const theme = useTheme();
    const skeletonStyle: SkeletonProps = { animation: 'wave', sx: { bgcolor: theme.palette.grey[100] } };

    return (
        <Stack spacing={1.25}>
            <CriteriaFieldLabel index={index} disabled={disabled || !name} onRemove={onRemove} />
            {name ? (
                <CriteriaTextAreaInput disabled={disabled} name={name} commentary={commentary} />
            ) : (
                <Paper elevation={0} sx={{ px: theme.spacing(1.25), py: theme.spacing(0.5) }}>
                    <Skeleton {...skeletonStyle} />
                    <Skeleton {...skeletonStyle} />
                    <Skeleton {...skeletonStyle} />
                    <Skeleton {...skeletonStyle} />
                </Paper>
            )}
        </Stack>
    );
};

export default CriteriaField;
