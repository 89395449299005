import { MeanTopicScore } from '../../../../../../../../Models/AssessmentMeanBenchmark';
import { TestTypes } from '../../../../../../../../Models/Configuration';
import { TopicScore } from '../../../../../../../../Models/TestScore';

function getNthPosition(str: string, subString: string, nPosition: number) {
    return str.split(subString, nPosition).join(subString).length;
}

function splitTopicNameIntoTwoLines(topicName: string): string | Array<string> {
    const spaceCount = topicName.length - topicName.replaceAll(' ', '').length;
    const splitIndex = getNthPosition(topicName, ' ', Math.round(spaceCount / 2));
    return spaceCount === 0 ? topicName : [topicName.slice(0, splitIndex), topicName.slice(splitIndex + 1)];
}

function buildDetailedGraphData(
    testType: TestTypes,
    topicScores: Array<TopicScore>,
    meanTopicScores: Array<MeanTopicScore>,
) {
    const labels: Array<string | Array<string>> = [];
    const candidateScoreData: Array<number> = [];
    const meanScoreData: Array<number | null> = [];
    const internalMeanScoreData: Array<number | null> = [];

    topicScores.forEach((score) => {
        const label =
            testType === TestTypes.Skills
                ? splitTopicNameIntoTwoLines(score.questionTopicName)
                : score.questionTopicName;
        labels.push(label);
        candidateScoreData.push(score.percentileScore);

        const foundMeanScore = meanTopicScores.find(
            (meanScore) => meanScore.questionTopicName === score.questionTopicName,
        );

        meanScoreData.push(foundMeanScore?.meanPercentileScore ?? null);
        internalMeanScoreData.push(foundMeanScore?.internalMeanPercentileScore ?? null);
    });

    return { labels, candidateScoreData, meanScoreData, internalMeanScoreData };
}

export default buildDetailedGraphData;
