import React from 'react';
import { useTheme } from '@mui/material';
import { FormControlLabel, Switch, Typography } from '@talentmesh/core';
import { useCareerPageTabContext } from '../Contexts/CareerPageTabContext';
import UIStrings from '../../../../Utils/UIStrings';

function CareerPageToggle(): JSX.Element {
    const { enableCareerPageToggle, enableCareerPage } = useCareerPageTabContext();
    const theme = useTheme();

    return (
        <FormControlLabel
            control={<Switch checked={enableCareerPageToggle} onChange={enableCareerPage} />}
            sx={{
                alignSelf: 'flex-start',
            }}
            label={
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{
                        paddingRight: theme.spacing(2),
                    }}
                >
                    {UIStrings.EnableCareerPage}
                </Typography>
            }
            labelPlacement="start"
            data-cy="CareerPageToggle"
        />
    );
}

export default CareerPageToggle;
