import { Theme, useTheme } from '@mui/material';
import React from 'react';
import { Stack } from '@talentmesh/core';
import { FiberManualRecordIcon } from '@talentmesh/icons';

function getImportanceColor(value: number, importantValue: number, theme: Theme) {
    return value < importantValue ? theme.palette.grey[200] : theme.palette.charts.chart12;
}

const importanceValues = [1, 2, 3, 4, 5];

interface ImportanceProps {
    weight: number;
}

function Importance({ weight }: ImportanceProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            sx={{ height: theme.spacing(2.5) }}
        >
            {importanceValues.map((item) => (
                <FiberManualRecordIcon
                    key={item}
                    sx={{ height: theme.spacing(1), width: theme.spacing(1.5) }}
                    style={{
                        color: getImportanceColor(weight, item, theme),
                        visibility: weight < 3 ? 'hidden' : 'unset',
                    }}
                />
            ))}
        </Stack>
    );
}

export default Importance;
