import React from 'react';
import { Outlet } from 'react-router';
import Auth0ProviderWithNavigate from '../Auth/Auth0ProviderWithNavigate';
import TelemetryErrorBoundary from '../Telemetry/TelemetryErrorBoundary';
import Auth0LoadingOverlay from './Auth0LoadingOverlay';

const ProvisionApp = () => {
    return (
        <Auth0ProviderWithNavigate>
            <TelemetryErrorBoundary>
                <Auth0LoadingOverlay>
                    <Outlet />
                </Auth0LoadingOverlay>
            </TelemetryErrorBoundary>
        </Auth0ProviderWithNavigate>
    );
};

export default ProvisionApp;
