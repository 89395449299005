import { useTheme } from '@mui/material';
import React from 'react';
import { Typography } from '@talentmesh/core';

interface DefaultErrorDescriptionProps {
    errorDescription: string;
}

function DefaultErrorDescription({ errorDescription }: DefaultErrorDescriptionProps): JSX.Element {
    const theme = useTheme();
    return (
        <Typography variant="body1" color={theme.palette.text.primary}>
            {errorDescription}
        </Typography>
    );
}

export default DefaultErrorDescription;
