import React from 'react';
import * as yup from 'yup';
import { AutocompleteOption, Box, LoadingButton, Stack } from '@talentmesh/core';
import { Form, Formik } from 'formik';
import UIStrings from '../../../../Utils/UIStrings';
import Compensation, { mapCompensation2Dto } from '../Compensation';
import { Currency } from '../../../../Models/Currency';
import YupValidationErrors from '../../../../Utils/YupValidationErrors';
import CurrencyAutocomplete, { CurrencyFormikName } from './CurrencyAutocomplete';
import AnnualFixedAmountTextField from './AnnualFixedAmountTextField';
import AnnualTargetBonusTextField from './AnnualTargetBonusTextField';
import { PaymentIntent } from '../../../../Models/PaymentIntent';
import { usePaymentClient } from '../../../../Hooks/ClientHooks';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import HttpError from '../../../../Utils/HTTP/Errors/HttpError';
import { IDRCurrencyISO } from '../../../../Utils/Currency/currencies';
import { getFormattedAmount } from '../../../../Utils/Amounts/AmountHelpers';
import useCompanyId from '../../../../Hooks/UseCompanyId';

// stripe has technical limit on the transaction amount
// for IDR, the maximum is 12 digits: 999 999 999 999 for a charge of 9,999,999,999.99 IDR
// for other currencies, the maximum is 8 digits: 99 999 999 for a charge of 999,999.99 USD
// We put the following limits on our platform:
// IDR: 10 000 000 000
// other: 1 000 000
// https://docs.stripe.com/currencies#minimum-and-maximum-charge-amounts
const MAX_ALLOWED_AMOUNT_IDR: number = 10000000000;
const MAX_ALLOWED_AMOUNT_OTHER: number = 1000000;

interface CompensationSectionValues {
    currency: Currency;
    annualFixedAmount: string;
    annualTargetBonus: string;
}

interface CompensationEditProps {
    recruitmentId: string | null;
    applicantId: string | null;
    paymentLoading: boolean;
    setPaymentLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setPaymentIntent: React.Dispatch<React.SetStateAction<PaymentIntent | undefined>>;
    compensation: Compensation;
    setCompensation: React.Dispatch<React.SetStateAction<Compensation>>;
    setIsCompensationInEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompensationEdit = ({
    recruitmentId,
    applicantId,
    paymentLoading,
    setPaymentLoading,
    setPaymentIntent,
    compensation,
    setCompensation,
    setIsCompensationInEdit,
}: CompensationEditProps) => {
    const paymentClient = usePaymentClient();
    const { showFailToaster } = useNotificationContext();
    const companyId = useCompanyId();

    const priceInitialValues: CompensationSectionValues = {
        currency: compensation.currency,
        annualFixedAmount: compensation.annualFixedAmount,
        annualTargetBonus: compensation.annualTargetBonus,
    };

    const priceValidationScheme = yup.object().shape({
        currency: yup.object().shape({
            id: yup.string(),
            label: yup.string().required(YupValidationErrors.Required),
        }),
        annualFixedAmount: yup
            .number()
            .required(YupValidationErrors.Required)
            .min(1, YupValidationErrors.GreaterThanZero)
            .when(CurrencyFormikName, {
                is: (currency: AutocompleteOption) => currency.id === IDRCurrencyISO,
                then: (schema) =>
                    schema.max(
                        MAX_ALLOWED_AMOUNT_IDR,
                        YupValidationErrors.AmountCannotExceed(getFormattedAmount(MAX_ALLOWED_AMOUNT_IDR)),
                    ),
                otherwise: (schema) =>
                    schema.max(
                        MAX_ALLOWED_AMOUNT_OTHER,
                        YupValidationErrors.AmountCannotExceed(getFormattedAmount(MAX_ALLOWED_AMOUNT_OTHER)),
                    ),
            }),
        annualTargetBonus: yup
            .number()
            .required(YupValidationErrors.Required)
            .min(0)
            .when(CurrencyFormikName, {
                is: (currency: AutocompleteOption) => currency.id === IDRCurrencyISO,
                then: (schema) =>
                    schema.max(
                        MAX_ALLOWED_AMOUNT_IDR,
                        YupValidationErrors.BonusCannotExceed(getFormattedAmount(MAX_ALLOWED_AMOUNT_IDR)),
                    ),
                otherwise: (schema) =>
                    schema.max(
                        MAX_ALLOWED_AMOUNT_OTHER,
                        YupValidationErrors.BonusCannotExceed(getFormattedAmount(MAX_ALLOWED_AMOUNT_OTHER)),
                    ),
            }),
    });

    const handleSubmit = async (values: CompensationSectionValues) => {
        setPaymentLoading(true);
        try {
            const comp: Compensation = {
                currency: values.currency,
                annualFixedAmount: values.annualFixedAmount,
                annualTargetBonus: values.annualTargetBonus,
            };
            const dto = mapCompensation2Dto(comp, recruitmentId, applicantId);
            const paymentIntent = await paymentClient.createPaymentIntentAsync(companyId, dto);
            setPaymentIntent(paymentIntent);
            setCompensation(comp);
            setIsCompensationInEdit(false);
        } catch (error) {
            if (error instanceof HttpError && error.detail) {
                showFailToaster(error.detail);
            } else {
                showFailToaster();
            }
        } finally {
            setPaymentLoading(false);
        }
    };

    return (
        <Formik
            initialValues={priceInitialValues}
            validationSchema={priceValidationScheme}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange
        >
            <Form>
                <Stack direction="column" alignItems="start" spacing={2.5}>
                    <CurrencyAutocomplete />
                    <AnnualFixedAmountTextField />
                    <AnnualTargetBonusTextField />
                </Stack>
                <Stack sx={{ mt: 3.75 }} alignItems="end">
                    <Box>
                        <LoadingButton
                            size="large"
                            variant="contained"
                            loading={paymentLoading}
                            type="submit"
                            fullWidth={false}
                        >
                            {UIStrings.Calculate}
                        </LoadingButton>
                    </Box>
                </Stack>
            </Form>
        </Formik>
    );
};

export default CompensationEdit;
