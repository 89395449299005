import React from 'react';
import { AccordionDetails } from '@talentmesh/core';
import { useTheme } from '@mui/system';
import getApplicantInformationLabel from '../../../../../../Utils/getLabelsFunctions';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import FormikChoiceSelector from './FormikChoiceSelector';
import UIStrings from '../../../../../../Utils/UIStrings';
import AdditionalInformationAccordionSummary from './Accordion/AdditionalInformationAccordionSummary';
import { AdditionalInformationAccordion } from './Accordion/AdditionalInformationAccordion';
import MandatoryInformationOption from './MandatoryInformationOption';

const mandatoryInformationOptions: string[] = [UIStrings.CV, UIStrings.Email];

const generalInformationToggles: ApplicantInformationType[] = [
    ApplicantInformationType.PhoneNumber,
    ApplicantInformationType.NoticePeriod,
    ApplicantInformationType.ValidWorkPermit,
    ApplicantInformationType.SalaryExpectation,
];

const GeneralInformationAccordion = (): JSX.Element => {
    const theme = useTheme();
    return (
        <AdditionalInformationAccordion defaultExpanded>
            <AdditionalInformationAccordionSummary
                sx={{
                    borderTopLeftRadius: theme.shape.smallBorderRadius,
                    borderTopRightRadius: theme.shape.smallBorderRadius,
                }}
                title={UIStrings.GeneralInformation}
            />
            <AccordionDetails>
                {mandatoryInformationOptions.map((value) => (
                    <MandatoryInformationOption key={value} label={value} />
                ))}
                {generalInformationToggles.map((value) => (
                    <FormikChoiceSelector
                        key={ApplicantInformationType[value]}
                        label={getApplicantInformationLabel(value)}
                        name={value}
                    />
                ))}
            </AccordionDetails>
        </AdditionalInformationAccordion>
    );
};

export default GeneralInformationAccordion;
