/* eslint-disable react-hooks/rules-of-hooks */
import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material';
import { GridCellParams, GridColDef, HeaderTypography, GridAlignment, GridSortModel } from '@talentmesh/core';
import { GridCellParams as GridCellParamsWithGeneric } from '@mui/x-data-grid';
import { ClearIcon, DoneAllIcon, DoneIcon } from '@talentmesh/icons';
import InternalBenchmarkBadge from '../../../../../Components/InternalBadges';
import TalentScoreTooltip from '../../../../../Components/Tooltip/TalentScoreTooltip';
import {
    CandidateOverview,
    CandidateStatuses,
    PercentileScoreDTO,
    ScoreDTO,
} from '../../../../../Models/CandidateOverview';
import { TestTypes } from '../../../../../Models/Configuration';
import UIStrings from '../../../../../Utils/UIStrings';
import FavoriteCell from '../Cells/FavoriteCell';
import TalentScoreCell from '../Cells/TalentScoreCell';
import TestTypeCell from '../Cells/TestTypeCell';
import ApplicantCell from '../Cells/ApplicantCell';
import ApplicantProfileCell from '../Cells/ApplicantProfileCell';
import { MultiLineHeader } from '../List/MultiLineHeader';
import CheckAll from '../List/CheckAll';
import { DialogEnum } from '../../../../Dialogs/ATS/Context/ActionDialogContext';
import MoreActionsCell from '../Cells/MoreActionsCell';
import CandidateSortByEnum from '../../Models/CandidateSortByEnum';
import createATSColumn from '../Column/createATSColumn';

function getCellData(params: GridCellParams): CandidateOverview {
    return params.row as CandidateOverview;
}

function getTestScore(test: TestTypes, item: CandidateOverview) {
    switch (test) {
        case TestTypes.Aptitude:
            return item.aptitudePercentileScore;
        case TestTypes.EmotionalIntelligence:
            return item.emotionalIntelligencePercentileScore;
        case TestTypes.Personality:
            return item.personalityPercentileScore;
        case TestTypes.Skills:
            return item.skillsPercentileScore;
        default:
            throw new Error(`Test type '${test}' is not supported`);
    }
}

function getPercentileScore(item?: PercentileScoreDTO) {
    if (item == null) {
        return undefined;
    }
    return item.percentileScore;
}

function getPercentileLevel(item?: PercentileScoreDTO) {
    if (item == null) {
        return 'Low';
    }
    return item.percentileLevel;
}

function getScore(item?: ScoreDTO) {
    if (item == null) {
        return undefined;
    }
    return item.score;
}

function getScoreLevel(item?: ScoreDTO) {
    if (item == null) {
        return 'Low';
    }
    return item.scoreLevel;
}

function getHeader(test: TestTypes) {
    switch (test) {
        case TestTypes.Aptitude:
            return UIStrings.Aptitude;
        case TestTypes.EmotionalIntelligence:
            return UIStrings.EmotionalIntelligence;
        case TestTypes.Personality:
            return UIStrings.Personality;
        case TestTypes.Skills:
            return UIStrings.Skills;
        default:
            return undefined;
    }
}

function createFlexBlankColumn(): GridColDef {
    return {
        field: 'flexBlankColumn',
        flex: 1,
        minWidth: 50,
        renderHeader: () => null,
        renderCell: () => null,
    };
}

function createTestColumn(testType: TestTypes, connectorLeft: boolean, connectorRight: boolean): GridColDef {
    return {
        field: (testType as string).toLowerCase(),
        renderHeader: () => {
            const header = getHeader(testType) || '';
            return <MultiLineHeader header={header} />;
        },
        width: 67,
        headerAlign: 'center' as GridAlignment,
        align: 'center' as GridAlignment,
        renderCell: (params: GridCellParams) => {
            const candidate = getCellData(params);
            const percentileScore = getTestScore(testType, candidate);
            const score = getPercentileScore(percentileScore);
            const level = getPercentileLevel(percentileScore);
            return (
                <TestTypeCell
                    connectorLeft={connectorLeft}
                    connectorRight={connectorRight}
                    score={score}
                    level={level}
                />
            );
        },
    };
}

function createExperienceScoreColumn(): GridColDef {
    return {
        field: 'experienceCriteriaScore',
        renderHeader: () => <MultiLineHeader header={UIStrings.Experience} />,
        width: 90,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridCellParamsWithGeneric) => {
            const applicantOverview = getCellData(params);
            const score = getScore(applicantOverview.experienceCriteriaScore);
            const level = getScoreLevel(applicantOverview.experienceCriteriaScore);
            return <TestTypeCell connectorLeft connectorRight level={level} score={score} />;
        },
    };
}

function createTalentScoreColumn(): GridColDef {
    return {
        field: 'talentScore',
        renderHeader: () => {
            return (
                <>
                    <HeaderTypography>{UIStrings.TalentScore}</HeaderTypography>
                    <TalentScoreTooltip />
                </>
            );
        },
        width: 124,
        headerAlign: 'center' as GridAlignment,
        align: 'center' as GridAlignment,
        renderCell: (params: GridCellParams) => <TalentScoreCell candidate={getCellData(params)} />,
    };
}

function createFavoriteColumn(tabValue: string): GridColDef {
    return {
        field: 'action',
        renderHeader: () => {
            return <CheckAll tabValue={tabValue} />;
        },
        width: 75,
        renderCell: (params: GridCellParams) => <FavoriteCell candidate={getCellData(params)} tabValue={tabValue} />,
    };
}

function createApplicantColumn(): GridColDef {
    return {
        field: 'name',
        renderHeader: () => {
            return <HeaderTypography>{UIStrings.Applicant}</HeaderTypography>;
        },
        width: 180,
        align: 'left' as GridAlignment,
        renderCell: (params: GridCellParams) => {
            const { name, currentStatus } = getCellData(params);
            return <ApplicantCell name={name} currentStatus={currentStatus} />;
        },
    };
}

function createApplicantProfileColumn(): GridColDef {
    return {
        field: 'applicantProfile',
        renderHeader: () => {
            return <HeaderTypography>{`${UIStrings.ApplicantsProfile}:`}</HeaderTypography>;
        },
        width: 230,
        headerAlign: 'left',
        renderCell: (params: GridCellParams) => {
            const overview = getCellData(params);
            const { locationDetails, experience, education } = overview;

            return (
                <ApplicantProfileCell locationDetails={locationDetails} experience={experience} education={education} />
            );
        },
    };
}

function createRejectColumn(): GridColDef {
    const width = 56;
    const theme = useTheme();

    const activeStyle: CSSProperties = {
        backgroundColor: theme.palette.hue.red.h50,
        color: theme.palette.hue.red.h900,
    };

    return createATSColumn({
        field: 'reject',
        headerLabel: UIStrings.Reject,
        tooltipMessage: UIStrings.Reject,
        activeStyle,
        width,
        colSpan: (params: GridCellParams) => {
            const candidate = getCellData(params);
            if (candidate.isInternal) {
                return 3;
            }
            return 1;
        },
        dialogStatus: DialogEnum.Reject,
        candidateStatus: CandidateStatuses.Rejected,
        icon: <ClearIcon />,
        internalBenchmarkComponent: <InternalBenchmarkBadge />,
    });
}

function createInterviewColumn(): GridColDef {
    const width = 56;
    const theme = useTheme();

    const activeStyle: CSSProperties = {
        backgroundColor: theme.palette.hue.green.h50,
        color: theme.palette.hue.green.h900,
    };

    return createATSColumn({
        field: 'interview',
        headerLabel: UIStrings.Interview,
        tooltipMessage: UIStrings.Interview,
        activeStyle,
        width,
        colSpan: 1,
        dialogStatus: DialogEnum.Interview,
        candidateStatus: CandidateStatuses.Interviewing,
        icon: <DoneIcon />,
    });
}

function createHireColumn(): GridColDef {
    const width = 39;
    const theme = useTheme();

    const activeStyle: CSSProperties = {
        backgroundColor: theme.palette.hue.green.h50,
        color: theme.palette.hue.green.h900,
    };

    return createATSColumn({
        field: 'hire',
        headerLabel: UIStrings.Hire,
        tooltipMessage: UIStrings.Hire,
        activeStyle,
        width,
        colSpan: 1,
        dialogStatus: DialogEnum.Hire,
        candidateStatus: CandidateStatuses.Hired,
        icon: <DoneAllIcon />,
    });
}

function createMoreActionsColumn(): GridColDef {
    const width = 32;

    return {
        field: 'moreActions',
        renderHeader: () => <div />,
        width,
        minWidth: width,
        maxWidth: width,
        renderCell: (params: GridCellParams) => <MoreActionsCell candidate={getCellData(params)} />,
    };
}

function getSortByEnum(model: GridSortModel): CandidateSortByEnum | undefined {
    if (model.length > 0) {
        // when sorting by default the length is 0
        if (model[0].sort === 'desc') {
            switch (model[0].field) {
                case 'talentScore':
                    return CandidateSortByEnum.TalentScoreDesc;
                case 'personality':
                    return CandidateSortByEnum.PersonalityScoreDesc;
                case 'aptitude':
                    return CandidateSortByEnum.AptitudeScoreDesc;
                case 'skills':
                    return CandidateSortByEnum.SkillsScoreDesc;
                case 'emotionalintelligence':
                    return CandidateSortByEnum.EmotionalIntelligenceScoreDesc;
                case 'experienceCriteriaScore':
                    return CandidateSortByEnum.ExperienceCriteriaScoreDesc;
                default:
                    return undefined;
            }
        } else if (model[0].sort === 'asc') {
            switch (model[0].field) {
                case 'talentScore':
                    return CandidateSortByEnum.TalentScoreAsc;
                case 'personality':
                    return CandidateSortByEnum.PersonalityScoreAsc;
                case 'aptitude':
                    return CandidateSortByEnum.AptitudeScoreAsc;
                case 'skills':
                    return CandidateSortByEnum.SkillsScoreAsc;
                case 'emotionalintelligence':
                    return CandidateSortByEnum.EmotionalIntelligenceScoreAsc;
                case 'experienceCriteriaScore':
                    return CandidateSortByEnum.ExperienceCriteriaScoreAsc;
                default:
                    return undefined;
            }
        }
    }

    return undefined;
}

export {
    getCellData,
    getHeader,
    getTestScore,
    getPercentileLevel,
    getPercentileScore,
    getScore,
    getScoreLevel,
    createFlexBlankColumn,
    createTalentScoreColumn,
    createExperienceScoreColumn,
    createTestColumn,
    createFavoriteColumn,
    createApplicantColumn,
    createApplicantProfileColumn,
    createRejectColumn,
    createInterviewColumn,
    createHireColumn,
    createMoreActionsColumn,
    getSortByEnum,
};
