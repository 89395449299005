import { createContext, useContext } from 'react';

export interface DownloadReportStatusContextData {
    downloads: Set<string>;
    start: (assessmentId: string, candidateId: string) => void;
    complete: (assessmentId: string, candidateId: string) => void;
    getKey: (assessmentId: string, candidateId: string) => string;
}

export const DownloadReportStatusContext = createContext<DownloadReportStatusContextData | undefined>(undefined);

export function useDownloadReportStatusContext() {
    const context = useContext(DownloadReportStatusContext);
    if (!context) {
        throw new Error("'DownloadReportStatusContext' should be called within 'DownloadReportStatusContext.Provider'");
    }
    return context;
}
