import { CandidateStatusDTO } from '../DTO/Candidates/CandidateStatusDTO';
import { CandidateInviteDTO, inviteCandidateDTOFromCandidate } from '../DTO/Users/CandidateInviteDTO';
import { Candidate } from '../Models/Candidate';
import { CandidateAccount, DefaultCandidateAccount } from '../Models/CandidateAccount';
import {
    CandidateAssessmentResults,
    DefaultCandidateAssessmentResults,
    mapCandidateAssessmentResultsDTO2Model,
} from '../Models/CandidateAssessmentResults';
import { CandidateStatuses } from '../Models/CandidateOverview';
import { post, put, toJson } from '../Utils/HTTP/HttpUtils';
import { CandidateAssessmentResultsDTO } from '../DTO/Assessments/CandidateAssessmentResultsDTO';
import ClientBase from './ClientBase';

export default class CandidatesClient extends ClientBase {
    async createCandidateAccountAsync(email: string): Promise<CandidateAccount> {
        const dto = { email } as CandidateAccount;
        const strategy = toJson(dto);
        const response = await post<CandidateAccount>({
            path: this.addHost('candidates/candidate-account'),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultCandidateAccount;
    }

    async inviteCandidatesAsync(assessmentId: string, candidates: Array<Candidate>): Promise<void> {
        const dto = {
            assessmentId,
            invites: candidates.map((item) => inviteCandidateDTOFromCandidate(item)),
        };

        const strategy = toJson(dto);
        await post<CandidateInviteDTO>({
            path: this.addHost(`candidates/invite`),
            body: strategy.body,
            contentType: strategy.contentType,
            accessToken: await this.getAccessTokenSilently(),
        });
    }

    async resendInvitationAsync(assessmentId: string, candidateId: string): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/resend-invitation`);
        const strategy = toJson({ assessmentId });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async interviewCandidateAsync(assessmentId: string, candidateId: string): Promise<void> {
        await this.updateCandidateStatusAsync(assessmentId, candidateId, 'interview');
    }

    async hireCandidateAsync(assessmentId: string, candidateId: string): Promise<void> {
        await this.updateCandidateStatusAsync(assessmentId, candidateId, 'hire');
    }

    async rejectCandidatesAsync(assessmentId: string, sendEmail: boolean, candidateIds: string[]): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/reject`);
        const strategy = toJson({ assessmentId, sendEmail, candidateIds });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async updateCandidateStatusAsync(assessmentId: string, candidateId: string, statusPath: string): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/${statusPath}`);
        const strategy = toJson({ assessmentId });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async undoCandidateStatusAsync(
        assessmentId: string,
        candidateId: string,
        status: CandidateStatuses,
    ): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/undo-status`);
        const strategy = toJson({ assessmentId, status });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async undoCandidateStatusesAsync(assessmentId: string, candidateStatuses: CandidateStatusDTO[]): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/undo-status`);
        const strategy = toJson({ assessmentId, candidateStatuses });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async saveFavoriteAsync(assessmentId: string, candidateId: string, isFavorite: boolean): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/save-favorite`);
        const strategy = toJson({ assessmentId, isFavorite });
        await put({
            path,
            accessToken,
            ...strategy,
        });
    }

    async resetApplicantAssessmentResultAsync(
        assessmentId: string,
        candidateId: string,
    ): Promise<CandidateAssessmentResults> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/reset`);
        const strategy = toJson({ assessmentId });
        const response = await post<CandidateAssessmentResultsDTO>({
            path,
            accessToken,
            ...strategy,
        });
        if (response.ok && response.body) {
            return mapCandidateAssessmentResultsDTO2Model(response.body);
        }
        return DefaultCandidateAssessmentResults;
    }
}
