import { HighestLevelEducations } from '@talentmesh/core';
import { EducationLevel } from '../../../../../../../../Models/EducationLevel';
import { toMonthYearDateString } from '../../../../../../../../Utils/DateHelper';
import UIStrings from '../../../../../../../../Utils/UIStrings';

export function getUserDefined<T extends { userDefined?: string }>(value: T): string {
    return value?.userDefined || '';
}

export function getEducationLevelLabel(educationLevel: EducationLevel): string {
    const { level, userDefined } = educationLevel;

    switch (level) {
        case 'UserDefined':
            return userDefined || '';
        case 'EightGrade':
            return UIStrings.EightGrade;
        case 'HighSchool':
            return UIStrings.HighSchool;
        case 'AssociateDegree':
            return UIStrings.AssociateDegree;
        case 'BachelorDegree':
            return UIStrings.BachelorDegree;
        case 'MasterDegree':
            return UIStrings.MasterDegree;
        case 'MBA':
            return UIStrings.MBA;
        case 'PostgraduateDegree':
            return UIStrings.PostgraduateDegree;
        case 'PHDDegree':
            return UIStrings.PHDDegree;
        default:
            throw new Error(`unknown EducationLevel: ${level}`);
    }
}

export function getEducationLevel(id: string): HighestLevelEducations {
    switch (id) {
        case '10':
            return 'UserDefined';
        case '20':
            return 'EightGrade';
        case '30':
            return 'HighSchool';
        case '40':
            return 'AssociateDegree';
        case '50':
            return 'BachelorDegree';
        case '60':
            return 'MasterDegree';
        case '70':
            return 'MBA';
        case '80':
            return 'PostgraduateDegree';
        case '90':
            return 'PHDDegree';
        default:
            throw new Error(`unknown EducationLevel: ${id}`);
    }
}

export function getPeriod(startDate: Date, endDate: Date | null): string {
    return `${toMonthYearDateString(startDate)} - ${endDate ? toMonthYearDateString(endDate) : UIStrings.Present}`;
}
