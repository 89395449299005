import { CreatePaymentIntentDTO } from '../DTO/Assessments/CreatePaymentIntentDTO';
import { PaymentIntentDTO } from '../DTO/Assessments/PaymentIntentDTO';
import { PaymentIntent, fromPaymentIntentDTO } from '../Models/PaymentIntent';
import { post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';
import SetupCard, { fromSetupCardDTO } from '../Models/SetupCard';
import SetupCardDTO from '../DTO/Users/SetupCardDTO';

export default class PaymentClient extends ClientBase {
    createPaymentIntentAsync = async (
        companyId: string,
        dto: CreatePaymentIntentDTO,
    ): Promise<PaymentIntent | undefined> => {
        const result = await post<PaymentIntentDTO>({
            path: this.addHost(`users/company/${companyId}/create-payment-intent`),
            // eslint-disable-next-line object-shorthand
            ...toJson(dto),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromPaymentIntentDTO(result.body);
        }
        return undefined;
    };

    createCardSetupAsync = async (companyId: string): Promise<SetupCard | undefined> => {
        const result = await post<SetupCardDTO>({
            path: this.addHost(`users/company/${companyId}/payment`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromSetupCardDTO(result.body);
        }
        return undefined;
    };
}
