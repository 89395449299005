import React from 'react';
import { LoadingOverlay } from '@talentmesh/core';
import { useUserSignupNavigationContext } from '../../Context/UserSignupNavigationContext';
import EmailVerified from './EmailVerified';

function EmailVerifiedPage(): JSX.Element {
    const { isLoading } = useUserSignupNavigationContext();

    return <>{isLoading ? <LoadingOverlay /> : <EmailVerified />}</>;
}

export default EmailVerifiedPage;
