import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import JobStatusTypography from './JobStatusTypography';
import { useCareerPageTabContext } from '../Contexts/CareerPageTabContext';
import StatusChip from '../../../../Components/StatusChip';

function JobAdStatus(): JSX.Element {
    const theme = useTheme();
    const { isCareerPageEnabled, isRecruitmentPublic } = useCareerPageTabContext();

    return (
        <Stack direction="column" spacing={theme.spacing(2)}>
            <Typography variant="h4" fontWeight="bold">
                {UIStrings.JobStatusOnCareerPage}
            </Typography>
            <Stack direction="row" spacing={theme.spacing(1)}>
                <StatusChip
                    color={isCareerPageEnabled && isRecruitmentPublic ? 'success' : 'warning'}
                    label={<JobStatusTypography />}
                />
            </Stack>
        </Stack>
    );
}

export default JobAdStatus;
