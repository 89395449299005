import React from 'react';
import { Navigate, generatePath } from 'react-router';
import { LoadingOverlay } from '@talentmesh/core';
import { useUserContext } from '../../Context/UserContext';
import RoutePath from '../../Routing/RoutePath';
import withSignupChecks from '../../Routing/withSignupChecks';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';

function RootPage() {
    const { isLoading, memberships } = useUserContext();

    if (isLoading) {
        return <LoadingOverlay />;
    }

    // by default we log user into the first company
    const companyId = memberships[0]?.companyId;

    if (memberships.length === 0) {
        return <Navigate to={RoutePath.SetupAccount} />;
    }

    return <Navigate to={generatePath(RoutePath.CompanyRoot, { companyId })} />;
}

export default withTalentMeshUserRequired(withSignupChecks(RootPage));
