import { MenuItemWithValueProps } from '@talentmesh/core';
import React, { SyntheticEvent } from 'react';
import { MembershipStatus } from '../../../../../../Models/TeamMember';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useTeamMembersContext } from '../../Context/TeamMembersContext';

function createMenuItem(label: string): MenuItemWithValueProps {
    return {
        menuItemLabel: label,
        dense: true,
    };
}

function useTeamMemberManagementMenuItems(status: MembershipStatus, id: string): Array<MenuItemWithValueProps> {
    const { openDialog, resendInvitation } = useTeamMembersContext();
    switch (status) {
        case 'Invited':
            return [
                {
                    ...createMenuItem(UIStrings.ResendInvitation),
                    onClick: async (e: SyntheticEvent) => {
                        await resendInvitation(id);
                    },
                },
                {
                    ...createMenuItem(UIStrings.RevokeInvitation),
                    onClick: (e: SyntheticEvent) => {
                        openDialog(id, 'RevokeInvitationConfirmation');
                    },
                },
            ];
        case 'Member':
            return [
                {
                    ...createMenuItem(UIStrings.RemoveAccess),
                    onClick: (e: SyntheticEvent) => {
                        openDialog(id, 'RemoveAccessConfirmation');
                    },
                },
            ];
        case 'Expired':
            return [
                {
                    ...createMenuItem(UIStrings.RevokeInvitation),
                    onClick: async (e: SyntheticEvent) => {
                        openDialog(id, 'RevokeInvitationConfirmation');
                    },
                },
            ];
        default:
            throw new Error(`Unknown status: ${status}`);
    }
}

export default useTeamMemberManagementMenuItems;
