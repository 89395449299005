import { Grid } from '@talentmesh/core';
import React from 'react';
import CustomBox from '../../../Components/Common/CustomBox';
import { HorizontalDivider } from '../../../Components/Common/CustomDivider';
import DetailsHeader from '../Common/DetailsHeader';
import { QualificationItem, QualificationItemProps } from './QualificationItem';

interface QualificationSectionProps {
    header: string;
    items: QualificationItemProps[];
}
function QualificationSection({ header, items }: QualificationSectionProps): JSX.Element {
    return (
        <>
            <DetailsHeader label={header} />
            <HorizontalDivider />
            <CustomBox>
                <Grid container direction="column" spacing={2}>
                    {items.map((item) => (
                        <QualificationItem key={item.period} {...item} />
                    ))}
                </Grid>
            </CustomBox>
        </>
    );
}

export default QualificationSection;
