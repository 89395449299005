import { Label, Stack, Typography } from '@talentmesh/core';
import React, { useState } from 'react';
import { ImageCrop } from '../../../../Components/ImageCrop/ImageCrop';
import { defaultAllowedImageTypes, ImageCropStrings } from '../../../../Components/ImageCrop/ImageCropUtils';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import UIStrings from '../../../../Utils/UIStrings';

export interface ClientImageCropProps {
    src: string;
    handleUploadImage: (image: Blob) => Promise<void>;
    handleResetImage: () => Promise<void>;
}

const ClientImageCrop = ({ src, handleUploadImage, handleResetImage }: ClientImageCropProps) => {
    const { showFailToaster } = useNotificationContext();
    const [validationMessage, setValidationMessage] = useState<string>('');

    const imageCropStrings: ImageCropStrings = {
        selectButtonTooltipText: UIStrings.UploadImage,
        removeButtonTooltipText: UIStrings.RemoveImage,
        uploadButtonText: UIStrings.Upload,
        dialogHeaderText: UIStrings.CropYourImage,
        dialogContentText: UIStrings.SelectTheAreaToBeCropped,
    };

    const validateCallback = (value: Blob): boolean => {
        if (value.size > 1024 * 1024 * 1) {
            setValidationMessage(UIStrings.DefaultImageSizeValidationErrorText);
            return false;
        }

        if (defaultAllowedImageTypes.find((imageType) => imageType === value.type) === undefined) {
            setValidationMessage(UIStrings.DefaultImageTypeValidationErrorText);
            return false;
        }

        if (validationMessage) {
            setValidationMessage('');
        }

        return true;
    };

    const processingErrorCallback = () => {
        showFailToaster(UIStrings.DefaultImageCropDialogProcessingErrorText);
    };

    return (
        <Stack data-cy="ClientImageCrop" sx={{ alignItems: 'start' }}>
            <Label>{UIStrings.UploadLogo}</Label>
            <Stack spacing={0.625}>
                <Typography variant="body2" color="error">
                    {validationMessage}
                </Typography>
            </Stack>
            <ImageCrop
                src={src}
                circularCrop
                strings={imageCropStrings}
                validateCallback={validateCallback}
                uploadCallbackAsync={handleUploadImage}
                resetCallbackAsync={handleResetImage}
                processingErrorCallback={processingErrorCallback}
            />
        </Stack>
    );
};

export default ClientImageCrop;
