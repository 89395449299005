import { useField, useFormikContext } from 'formik';
import { Stack, FormHelperText, LoadingButton, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { RichTextEditor, $generateHtmlFromNodes } from '@talentmesh/rte';
import React, { useMemo, useState } from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';

interface NoteRTEProps {
    saved: boolean;
    errorSaving: boolean;
}

function NoteRTE({ saved, errorSaving }: NoteRTEProps): JSX.Element {
    const theme = useTheme();
    const [field, meta, helpers] = useField('noteRte');
    const [isInitialRender, setIsInitialRender] = useState(true);
    const { dirty, isSubmitting } = useFormikContext();
    const initialHtml = useMemo(() => field.value, []);

    return (
        <Stack spacing={2.5} sx={{ alignItems: 'start' }} data-cy="NoteSection">
            <Stack
                spacing={1}
                sx={{
                    borderRadius: theme.spacing(1.25),
                    border: `1px solid ${theme.palette.border.main}`,
                    borderWidth: '1px',
                    backgroundColor: theme.palette.grey[100],
                    padding: theme.spacing(2.5),
                    height: theme.spacing(60.375),
                    width: '100%',
                }}
            >
                <RichTextEditor
                    data-cy="NoteEditor"
                    initialHtml={initialHtml}
                    features={['headingStyle', 'bold', 'italic', 'ol', 'ul']}
                    onChange={(editorState, editor) => {
                        editorState.read(() => {
                            helpers.setValue($generateHtmlFromNodes(editor), !isInitialRender);
                            if (isInitialRender) {
                                setIsInitialRender(false);
                            }
                        });
                    }}
                    contentEditableProps={{
                        sx: {
                            overflowY: 'auto',
                            flex: 1,
                            borderColor: meta.error ? theme.palette.error.main : 'inherit',
                        },
                    }}
                    toolbarProps={{ sx: { marginBottom: theme.spacing(1.25) } }}
                />
                {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
            </Stack>
            <Stack direction="row" spacing={2.5} sx={{ alignItems: 'center' }}>
                <LoadingButton
                    data-cy="NoteSubmitButton"
                    size="large"
                    loading={isSubmitting}
                    variant="contained"
                    type="submit"
                >
                    {UIStrings.Save}
                </LoadingButton>
                {isSubmitting && <Typography variant="body1">{UIStrings.SavingWithDots}</Typography>}
                {saved && !dirty && <Typography variant="body1">{UIStrings.Saved}</Typography>}
                {errorSaving && (
                    <Typography variant="body1" sx={{ color: theme.palette.error.main }}>
                        {UIStrings.NoteCannotBeSaved}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}

export default NoteRTE;
