import React from 'react';
import { Container, Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { AssessmentStatuses } from '../../../../../Models/AssessmentData';
import { useAssessmentsOverviewContext } from '../../Contexts/AssessmentsOverviewContext';
import UIStrings from '../../../../../Utils/UIStrings';
import CreateRecruitmentButton from '../CreateRecruitmentButton';

const EmptyAssessmentsOverview = (): JSX.Element => {
    const theme = useTheme();
    const { status } = useAssessmentsOverviewContext();

    return (
        <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Container maxWidth="sm">
                <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ textAlign: 'center', color: theme.palette.text.menuTitle }}>
                        {status === AssessmentStatuses.Closed
                            ? UIStrings.ThereAreNoClosedRecruitments
                            : UIStrings.ThereAreNoActiveRecruitments}
                    </Typography>
                    {status !== AssessmentStatuses.Closed && <CreateRecruitmentButton />}
                </Stack>
            </Container>
        </Stack>
    );
};

export default EmptyAssessmentsOverview;
