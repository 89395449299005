import React, { SyntheticEvent, useState } from 'react';
import JobBoardsScrollableList from '../JobBoardsTab/JobBoardScrollableList';
import { JobBoards } from '../JobBoardsTab/AvailableJobBoards';
import JobBoardInstructions from '../JobBoardsTab/JobBoardInstructions';
import JobBoardBookMeeting from '../JobBoardsTab/JobBoardBookMeeting';

const JobBoardsTabContents = (): JSX.Element => {
    const [activeJobBoard, setActiveJobBoard] = useState<string>('0');

    const handleChange = (_: SyntheticEvent, newValue: string) => {
        setActiveJobBoard(newValue);
    };

    return (
        <>
            <JobBoardsScrollableList value={activeJobBoard} onChange={handleChange} />
            {JobBoards.map(({ name }, index) => (
                <JobBoardInstructions key={name} name={name} tabKey={index.toString()} value={activeJobBoard} />
            ))}
            <JobBoardBookMeeting />
        </>
    );
};

export default JobBoardsTabContents;
