import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Divider, Stack, Typography } from '@talentmesh/core';
import BaseTestCardTagItem from './BaseTestCardTagItem';
import BaseTestCardsConstants from './BaseTestCardConstants';
import TestInfo from '../../../../../../../../../Models/TestInfo';
import { getTopicsDescriptionHeaderByTestType } from '../../../../../../../../../Utils/RecommendationStepUtils';

export interface BaseTestCardTagCloudProps extends Pick<TestInfo, 'testType' | 'topics' | 'totalTopicsCount'> {}

function BaseTestCardTagCloud({ topics, totalTopicsCount, testType }: BaseTestCardTagCloudProps): JSX.Element {
    const theme = useTheme();

    const topicDescriptionHeight = theme.spacing(5.25);

    return (
        <Stack
            spacing={1.25}
            sx={{
                paddingX: theme.spacing(0.625),
            }}
        >
            <Box
                sx={{
                    minHeight: topicDescriptionHeight,
                    maxHeight: topicDescriptionHeight,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography variant="subtitle2" sx={{ color: theme.palette.text.menuTitle, fontWeight: 'bold' }}>
                    {getTopicsDescriptionHeaderByTestType(totalTopicsCount, testType)}
                </Typography>
            </Box>

            <Divider sx={{ borderColor: theme.palette.border.main }} />

            <Stack justifyContent="space-between" alignContent="start" gap={theme.spacing(0.5)}>
                {topics.slice(0, BaseTestCardsConstants.MaxTagCount).map((item) => (
                    <BaseTestCardTagItem key={item} label={item} />
                ))}
            </Stack>
        </Stack>
    );
}

export default BaseTestCardTagCloud;
