import React from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router';
import GetFullExperiencePage from '../Pages/GetFullExperiencePage/GetFullExperiencePage';

const DesktopOnlyRoutes = () => {
    return isMobile ? <GetFullExperiencePage /> : <Outlet />;
};

export default DesktopOnlyRoutes;
