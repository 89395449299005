import React, { ReactNode } from 'react';
import { Avatar, Box, Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/system';

interface CheckoutSectionProps {
    orderNumber: number;
    title: string;
    children: ReactNode;
}

export default function CheckoutSection({ orderNumber, title, children }: CheckoutSectionProps) {
    const theme = useTheme();
    return (
        <Box>
            <Stack
                sx={{
                    background: theme.palette.hue.primary.h50,
                    p: theme.spacing(1.25),
                    borderRadius: theme.shape.smallBorderRadius,
                    mb: theme.spacing(2.5),
                }}
                direction="row"
                alignItems="center"
                spacing={1.25}
            >
                <Avatar sx={{ bgcolor: theme.palette.primary.main, width: theme.spacing(4), height: theme.spacing(4) }}>
                    {orderNumber}
                </Avatar>
                <Typography variant="h5" color="primary">{`${title}`}</Typography>
            </Stack>
            {children}
        </Box>
    );
}
