import { Box, CircularProgress } from '@talentmesh/core';
import React from 'react';

function TabLoadingSpinner(): JSX.Element {
    return (
        <Box sx={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    );
}

export default TabLoadingSpinner;
