import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Slider, Stack, Typography } from '@talentmesh/core';
import { SnapshotsSliceDTO } from '../../../../../DTO/Snapshots/SnapshotsSliceDTO';
import { useSnapshotsClient } from '../../../../../Hooks/ClientHooks';
import { TestTypes } from '../../../../../Models/Configuration';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import Snapshot from './Snapshot';
import SnapshotMetadata from './SnapshotMetadata';
import CTMonitoringScreenshotsStub from './CTMonitoringScreenshotsStub';
import CTMonitoringWebcamStub from './CTMonitoringWebcamStub';

export interface CTMonitoringBodyProps {
    isOpened: boolean;
    testType: TestTypes;
    isWebcamEnabled: boolean;
    isScreenSnapshotsEnabled: boolean;
}

function CTMonitoringBody({
    isOpened,
    testType,
    isWebcamEnabled,
    isScreenSnapshotsEnabled,
}: CTMonitoringBodyProps): JSX.Element {
    const { results } = useCandidatesResultsContext();
    const [items, setItems] = useState<Array<SnapshotsSliceDTO>>([]);
    const [current, setCurrent] = useState<number>(0);
    const theme = useTheme();
    const client = useSnapshotsClient();

    const handleChange = (event: Event, value: number | number[], activeThumb: number): void => {
        if (typeof value === 'number') {
            setCurrent(value);
        }
    };

    useEffect(() => {
        if (!items || items.length === 0) {
            const doGetSnapshots = async () => {
                const snapshots = await client.getSnapshotsAsync(results.assessmentId, results.candidateId, testType);
                setItems(snapshots.data);
            };
            doGetSnapshots();
        }
    }, []);

    return (
        <Stack
            direction="column"
            sx={{
                padding: theme.spacing(2),
                backgroundColor: theme.palette.common.white,
                display: isOpened ? 'flex' : 'none',
                borderBottomLeftRadius: theme.shape.mediumBorderRadius,
                borderBottomRightRadius: theme.shape.mediumBorderRadius,
                border: `1px solid ${theme.palette.border.main}`,
                borderTop: isOpened ? 'none' : `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Typography variant="body2">{UIStrings.TestMonitoringExplanation}</Typography>
            <Stack
                direction="row"
                spacing={theme.spacing(2)}
                sx={{
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.shape.smallBorderRadius,
                    marginTop: theme.spacing(2),
                    padding: theme.spacing(2),
                }}
            >
                <Stack width="60%">
                    {items[current]?.screenshotUrl ? (
                        <Snapshot url={items[current]?.screenshotUrl} title={UIStrings.ScreenSnapshots} />
                    ) : (
                        <CTMonitoringScreenshotsStub isScreenSnapshotsEnabled={isScreenSnapshotsEnabled} />
                    )}
                </Stack>
                <Stack direction="column" spacing={theme.spacing(2)} width="40%">
                    {isWebcamEnabled && items[current]?.webcamPhotoUrl ? (
                        <Snapshot url={items[current]?.webcamPhotoUrl} title={UIStrings.Webcam} />
                    ) : (
                        <CTMonitoringWebcamStub />
                    )}
                    {items[current] && (
                        <SnapshotMetadata current={current + 1} total={items.length} date={items[current].createdAt} />
                    )}
                </Stack>
            </Stack>
            {items && items.length > 0 && (
                <Slider defaultValue={0} step={1} marks min={0} max={items.length - 1} onChange={handleChange} />
            )}
        </Stack>
    );
}

export default CTMonitoringBody;
