import React from 'react';
import { useTheme } from '@mui/material';
import { useField } from 'formik';
import { ChoiceSelector, Box, Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';
import MultiFontWeightLabel from '../../../Components/MultiFontWeightLabel';

interface FormikAlternativeToggleWithTextProps {
    label: string;
    name: string;
}

const FormikChoiceSelector = ({ label, name }: FormikAlternativeToggleWithTextProps): JSX.Element => {
    const theme = useTheme();
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [field, _, helpers] = useField(name);

    const handleChange = (event: React.MouseEvent<HTMLElement>, value: number) => {
        helpers.setValue(value > 0);
    };

    return (
        <Box p={2.5}>
            <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={5}>
                <Typography variant="h5" color={theme.palette.text.menuTitle}>
                    <MultiFontWeightLabel variant="h5" label={label} />
                </Typography>
                <Stack direction="row" spacing={1.25}>
                    <ChoiceSelector
                        size="small"
                        leftOption={<Typography variant="caption">{UIStrings.Mandatory}</Typography>}
                        leftValue={1}
                        rightOption={<Typography variant="caption">{UIStrings.Off}</Typography>}
                        rightValue={0}
                        onChange={handleChange}
                        value={field.value ? 1 : 0}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};

export default FormikChoiceSelector;
