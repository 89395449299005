import DescriptionDTO, { DefaultDescriptionDTO } from './DescriptionDTO';

interface AIJobAdDTO {
    title: string;
    companyDescription?: DescriptionDTO;
    aboutTheRole: DescriptionDTO;
    roleResponsibilitiesDescription: DescriptionDTO;
    compensationAndBenefits: DescriptionDTO;
    conclusion: string;
}

export const DefaultAIJobAdDTO: AIJobAdDTO = {
    title: '',
    aboutTheRole: DefaultDescriptionDTO,
    roleResponsibilitiesDescription: DefaultDescriptionDTO,
    compensationAndBenefits: DefaultDescriptionDTO,
    conclusion: '',
};

export default AIJobAdDTO;
