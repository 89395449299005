import React from 'react';
import { CircularProgress, InfiniteScroll, Stack } from '@talentmesh/core';
import { useAssessmentsOverviewContext } from '../Contexts/AssessmentsOverviewContext';
import RecruitmentListItem from './RecruitmentList/RecruitmentListItem';
import EndOfListMessage from './EndOfListMessage';
import EmptyRecruitmentList from './EmptyRecruitmentList';

function AssessmentsOverviewList(): JSX.Element {
    const { assessments, loading, totalAssessmentsCount, loadPageAsync } = useAssessmentsOverviewContext();

    if (loading && assessments.length === 0) {
        return (
            <Stack alignItems="center" height="100%" justifyContent="center" spacing={1}>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <>
            <InfiniteScroll
                next={async () => {
                    await loadPageAsync(assessments.length);
                }}
                hasMore={assessments.length < totalAssessmentsCount}
                endMessage={assessments.length > 0 && <EndOfListMessage />}
            >
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                    {assessments.map((assessment) => (
                        <RecruitmentListItem key={assessment.id} recruitment={assessment} />
                    ))}
                </Stack>
            </InfiniteScroll>
            {!loading && assessments.length === 0 && <EmptyRecruitmentList />}
        </>
    );
}

export default AssessmentsOverviewList;
