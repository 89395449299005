interface AddressComponent {
    longName: string;
    shortName: string;
}

const DefaultAddressComponent = {
    longName: '',
    shortName: '',
};

interface Coordinates {
    latitude: number;
    longitude: number;
}

interface LocationDetails {
    locationId: string;
    description: string;
    geometry: Coordinates;
    city?: AddressComponent;
    administrativeAreaLevel3?: AddressComponent;
    administrativeAreaLevel2?: AddressComponent;
    administrativeAreaLevel1?: AddressComponent;
    country: AddressComponent;
    utcOffsetMinutes: number;
}

export const DefaultLocationDetails = {
    locationId: '',
    description: '',
    geometry: { latitude: 0, longitude: 0 },
    city: DefaultAddressComponent,
    administrativeAreaLevel3: undefined,
    administrativeAreaLevel2: undefined,
    administrativeAreaLevel1: undefined,
    country: DefaultAddressComponent,
    utcOffsetMinutes: 0,
};

export default LocationDetails;

export function locationDetailsDeepEquals(left: LocationDetails, right: LocationDetails): boolean {
    return (
        left.locationId === right.locationId &&
        left.description === right.description &&
        left.utcOffsetMinutes === right.utcOffsetMinutes
    );
}
