import React, { useState } from 'react';
import { Tab, TabContext, TabList, TabPanel } from '@talentmesh/core';
import { AssessmentMeanBenchmark } from '../../../../../../../../Models/AssessmentMeanBenchmark';
import { TestTypes } from '../../../../../../../../Models/Configuration';
import { TestScore } from '../../../../../../../../Models/TestScore';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import DetailedBreakdownBenchmarkTab from './DetailedBreakdownBenchmarkTab';
import OverallScoreBenchmarkTab from './OverallScoreBenchmarkTab';

interface BenchmarkTabsProps {
    candidateFirstName: string;
    candidateScore: TestScore;
    testType: TestTypes;
    benchmark: AssessmentMeanBenchmark;
}

function BenchmarkTabs({ candidateFirstName, candidateScore, testType, benchmark }: BenchmarkTabsProps): JSX.Element {
    const [tabValue, setTabValue] = useState<string>(UIStrings.BenchmarkOverallScore);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <TabContext value={tabValue}>
            <TabList sx={{ marginTop: 2.5 }} onChange={handleChange} disableTabIndicator={false} centered>
                <Tab label={UIStrings.BenchmarkOverallScore} value={UIStrings.BenchmarkOverallScore} />
                <Tab label={UIStrings.BenchmarkDetailedBreakdown} value={UIStrings.BenchmarkDetailedBreakdown} />
            </TabList>
            <TabPanel value={UIStrings.BenchmarkOverallScore}>
                <OverallScoreBenchmarkTab
                    candidateFirstName={candidateFirstName}
                    candidateScore={candidateScore.percentileScore}
                    testType={testType}
                    benchmark={benchmark}
                />
            </TabPanel>
            <TabPanel value={UIStrings.BenchmarkDetailedBreakdown}>
                <DetailedBreakdownBenchmarkTab
                    candidateFirstName={candidateFirstName}
                    topicScores={candidateScore.scores}
                    testType={testType}
                    benchmark={benchmark}
                />
            </TabPanel>
        </TabContext>
    );
}

export default BenchmarkTabs;
