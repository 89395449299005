import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { Grid, PercentileLevel, TMCircularProgress } from '@talentmesh/core';
import UIStrings from '../../../../../../../../../Utils/UIStrings';
import Importance from '../../../../Components/Importance';
import { characteristicSize, descriptionSize, importanceSize } from './EICharacteristicConsts';

interface EICharacteristicHeaderProps {
    characteristicName: string;
    percentileScore: number;
    percentileLevel: PercentileLevel;
    importanceRating: number;
}

function EICharacteristicHeader({
    characteristicName,
    percentileScore,
    percentileLevel,
    importanceRating,
}: EICharacteristicHeaderProps): JSX.Element {
    const theme = useTheme();

    return (
        <>
            <>
                <Grid item xs={characteristicSize} sx={{ paddingBottom: 1 }} display="flex" justifyContent="center">
                    <Typography variant="label" color={theme.palette.text.secondary}>
                        {UIStrings.Characteristic}
                    </Typography>
                </Grid>
                <Grid item xs={descriptionSize} />
                <Grid item xs={importanceSize}>
                    <Typography variant="label" color={theme.palette.text.secondary}>
                        {UIStrings.Importance}
                    </Typography>
                </Grid>
            </>
            <>
                <Grid item xs={characteristicSize} sx={{ paddingBottom: 1 }} display="flex" justifyContent="center">
                    <TMCircularProgress level={percentileLevel} progress={percentileScore} />
                </Grid>
                <Grid item xs={descriptionSize}>
                    <Typography variant="h4" color={theme.palette.text.menuTitle}>
                        {characteristicName}
                    </Typography>
                </Grid>
                <Grid item xs={importanceSize}>
                    <Importance weight={importanceRating} />
                </Grid>
            </>
        </>
    );
}

export default EICharacteristicHeader;
