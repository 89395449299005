import { useTheme } from '@mui/material';
import React from 'react';
import { TMTooltip, IconButton, Stack, Typography, TooltipPaper, Label } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';

interface LabelTooltipBodyProps {
    text: string;
}

function LabelTooltipBody({ text }: LabelTooltipBodyProps): JSX.Element {
    const theme = useTheme();
    return (
        <TooltipPaper minWidth={theme.spacing(45)}>
            <Typography variant="body2" color={theme.palette.secondary.main}>
                {text}
            </Typography>
        </TooltipPaper>
    );
}

interface JobTitleLabelProps {
    label: string;
    tooltipText: string;
}

function LabelWithTooltip({ label, tooltipText }: JobTitleLabelProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <Label component="span">{label}</Label>
            <TMTooltip title={<LabelTooltipBody text={tooltipText} />} placement="bottom">
                <IconButton sx={{ color: theme.palette.secondary.light, padding: theme.spacing(0) }}>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </TMTooltip>
        </Stack>
    );
}

export default LabelWithTooltip;
