import { useTheme } from '@mui/material';
import React from 'react';
import { Box, SpanTypography } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

interface DialogCardInterviewBodyProps {
    name: string;
}

function DialogCardInterviewBody({ name }: DialogCardInterviewBodyProps): JSX.Element {
    const theme = useTheme();
    return (
        <Box>
            <SpanTypography variant="body1" color={theme.palette.text.primary}>{`${UIStrings.Click} `}</SpanTypography>
            <SpanTypography
                variant="body1"
                color={theme.palette.text.primary}
            >{`"${UIStrings.ComposeInvitationEmail}" `}</SpanTypography>
            <SpanTypography variant="body1" color={theme.palette.text.primary}>
                {`${UIStrings.InterviewDialogBody3}. ${UIStrings.InterviewDialogBody4} ${name}${UIStrings.InterviewDialogBody5}.`}
            </SpanTypography>
        </Box>
    );
}

export default DialogCardInterviewBody;
