import React from 'react';
import CvIconContainer from './CvIconContainer';
import UIStrings from '../../../../../../../../../Utils/UIStrings';
import DocLargeSVG from '../Icons/DocLargeSVG.svg';

function DocLargeIcon(): JSX.Element {
    return (
        <CvIconContainer>
            <object type="image/svg+xml" data={DocLargeSVG} aria-label={UIStrings.DocDocx} data-cy="DocLargeIcon" />
        </CvIconContainer>
    );
}
export default DocLargeIcon;
