import { TeamMemberDTO } from '../DTO/Membership/TeammemberDTO';
import CompanyMembershipDTO from '../DTO/Users/CompanyMembershipDTO';
import ProvisionStatusDTO, { DefaultProvisionStatusDTO } from '../DTO/Users/ProvisionStatusDTO';
import { SetupAccountDTO } from '../DTO/Users/SetupAccountDTO';
import { updateRecruiterDTOFromUser } from '../DTO/Users/UpdateRecruiterDTO';
import UserDTO from '../DTO/Users/UserDTO';
import { DefaultVersionDto, VersionDto } from '../DTO/VersionDto';
import Page, { EmptyPage } from '../Models/Page';
import User, { DefaultUser, fromUserDTO } from '../Models/User';
import ContentTypes from '../Utils/ContentTypes';
import { get, post, put, toJson, httpDelete } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class UsersClient extends ClientBase {
    getUserAsync = async (): Promise<User> => {
        const result = await get<UserDTO>({
            path: this.addHost(`users`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromUserDTO(result.body);
        }
        return DefaultUser;
    };

    setupAccountAsync = async (dto: SetupAccountDTO): Promise<User> => {
        const result = await post<UserDTO>({
            path: this.addHost(`users/setup-account`),
            ...toJson(dto),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromUserDTO(result.body);
        }
        return DefaultUser;
    };

    updateUserAsync = async (input: User): Promise<User> => {
        const dto = updateRecruiterDTOFromUser(input);
        const path = `users`;
        const result = await put<UserDTO>({
            path: this.addHost(path),
            body: JSON.stringify(dto),
            contentType: ContentTypes.ApplicationJson,
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return fromUserDTO(result.body);
        }
        return DefaultUser;
    };

    sendVerificationEmailAsync = async (): Promise<boolean> => {
        const response = await post<any>({
            path: this.addHost(`jobs/sendVerificationEmails`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok) {
            return true;
        }
        return false;
    };

    async setProvisionStatusAsync(): Promise<boolean> {
        const response = await post<any>({
            path: this.addHost(`users/provision`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok) {
            return true;
        }
        return false;
    }

    async getProvisionStatusAsync(): Promise<ProvisionStatusDTO> {
        const response = await get<ProvisionStatusDTO>({
            path: this.addHost(`users/provision`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultProvisionStatusDTO;
    }

    async getMembershipsAsync(): Promise<Array<CompanyMembershipDTO>> {
        const response = await get<Array<CompanyMembershipDTO>>({
            path: this.addHost(`users/memberships`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return [];
    }

    async getVersionAsync(): Promise<VersionDto> {
        const response = await get<VersionDto>({
            path: this.addHost(`users/version`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultVersionDto;
    }

    async getMembersAsync(
        companyId: string,
        offset: number,
        limit: number,
        search?: string,
    ): Promise<Page<TeamMemberDTO>> {
        const params = new URLSearchParams();
        params.append('offset', offset.toString());
        params.append('limit', limit.toString());
        if (search && search.length > 0) {
            params.append('search', search);
        }
        const queryString = params.toString();

        const response = await get<Page<TeamMemberDTO>>({
            path: this.addHost(`users/company/${companyId}/members?${queryString}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return EmptyPage;
    }

    async removeMemberAsync(membershipId: string): Promise<void> {
        await httpDelete({
            path: this.addHost(`users/membership/${membershipId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
    }
}
