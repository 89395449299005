import { CandidateStatuses } from '../Models/CandidateOverview';

function isResendInvitationAllowed(applicantStatus: CandidateStatuses, completedAt?: string | null) {
    return (
        (completedAt === '' || completedAt === undefined || completedAt === null) &&
        applicantStatus !== CandidateStatuses.AssessmentCompleted &&
        applicantStatus !== CandidateStatuses.Hired &&
        applicantStatus !== CandidateStatuses.Rejected
    );
}

export default isResendInvitationAllowed;
