import { hubspotFormApiUrl } from '../AppSettings';
import SubmitFormDTO from '../DTO/Marketing/SubmitFormDTO';
import { post, toJson } from '../Utils/HTTP/HttpUtils';

export default class HubspotClient {
    addHost(path: string) {
        return `${hubspotFormApiUrl}${path}`;
    }

    submitForm = async (hubspotPortalId: string, hubspotFormId: string, formFields: SubmitFormDTO): Promise<void> => {
        const path = this.addHost(`submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`);
        const strategy = toJson(formFields);
        await post({
            path,
            ...strategy,
        });
    };
}
