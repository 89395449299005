import { useTheme } from '@mui/material';
import { Button, Divider, Stack, Typography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../../../Utils/UIStrings';
import CareerPageInfo from './CareerPageTab/CareerPageInfo';
import { DefaultCareerPageStats } from '../../../Models/Career';
import CareerPageIntegration from '../../../Components/CareerPageIntegration';
import LoadingTab from '../AccountSettingsTabs/LoadingTab';
import useCareerPageTab from '../Hooks/useCareerPageTab';

const CareerPageTab = () => {
    const theme = useTheme();
    const { stats, isLoading, isLoadingFailed, handleVisitCareerPageBuilder, handleVisitCareerPage } =
        useCareerPageTab();

    return (
        <LoadingTab isLoading={isLoading} loadingFailed={isLoadingFailed}>
            <Stack spacing={1.25} useFlexGap>
                <Typography variant="body1">{UIStrings.CareerPageTabDescription}</Typography>
                <CareerPageInfo stats={stats} />
                <Stack spacing={1.25} direction="row" sx={{ mt: theme.spacing(2.5) }}>
                    <Button variant="contained" onClick={handleVisitCareerPageBuilder}>
                        {stats.publishedDate === DefaultCareerPageStats.publishedDate
                            ? UIStrings.CreateCareerPage
                            : UIStrings.EditCareerPage}
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={stats.publishedDate === DefaultCareerPageStats.publishedDate}
                        onClick={handleVisitCareerPage}
                        sx={{ backgroundColor: theme.palette.common.white }}
                    >
                        {UIStrings.ViewCareerPage}
                    </Button>
                </Stack>
                <Divider orientation="horizontal" sx={{ margin: theme.spacing(1) }} />
                <CareerPageIntegration />
            </Stack>
        </LoadingTab>
    );
};

export default CareerPageTab;
