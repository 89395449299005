enum HttpErrorTypes {
    CouponExpired = 'coupon_expired',
    CouponIsInvalid = 'coupon_invalid',
    SlugExists = 'slug_exists',
    MembershipExists = 'membership_exists',
    InvitationExists = 'invitation_exists',
    InvalidDomain = 'invalid_domain',
    InvitationExpired = 'invitation_expired',
    InvitationAccepted = 'invitation_accepted',
}

export default HttpErrorTypes;
