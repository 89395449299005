import React, { MouseEvent } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button } from '@talentmesh/core';
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from '@talentmesh/icons';

export interface BaseFilterButtonProps {
    label: string;
    open: boolean;
    handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
    activeFilterCount: number;
}

function BaseFilterButton({ label, open, handleOpen, activeFilterCount }: BaseFilterButtonProps): JSX.Element {
    const theme = useTheme();
    const countLabel = activeFilterCount > 0 ? `(+${activeFilterCount})` : '';

    return (
        <Button
            onClick={handleOpen}
            endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            data-cy="AdvancedFilterButton"
            sx={{
                ...theme.typography.caption,
                height: theme.spacing(4),
                padding: theme.spacing(0.75, 0.375),
                color: activeFilterCount > 0 ? theme.palette.primary.main : theme.palette.secondary.main,
                fontWeight: activeFilterCount > 0 ? theme.typography.fontWeightBold : 'inherit',

                '& .MuiButton-endIcon': {
                    marginLeft: 0,
                    color: theme.palette.secondary.main,
                },
            }}
        >
            {`${label} ${countLabel}`}
        </Button>
    );
}

export default BaseFilterButton;
