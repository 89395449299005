import { Page as TMPage, PageProps } from '@talentmesh/core';
import React, { ReactNode, forwardRef } from 'react';

interface PagePropsExt extends PageProps {
    header?: ReactNode;
}
const Page = forwardRef<HTMLDivElement, PagePropsExt>(
    ({ children, topBar = null, header = null, ...props }: PagePropsExt, ref) => {
        return (
            <>
                {header}
                <TMPage topBar={topBar} {...props} ref={ref}>
                    {children}
                </TMPage>
            </>
        );
    },
);

export default Page;
