import { useTheme } from '@mui/material';
import { FormikTextField } from '@talentmesh/forms';
import { ChevronRightIcon } from '@talentmesh/icons';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormHelperText,
    LoadingButton,
} from '@talentmesh/core';
import { FormikTeamMember, newTeamMemberSchema } from '../../../../../Models/TeamMember';
import UIStrings from '../../../../../Utils/UIStrings';
import TeamMemberRolePicker from '../TeamMembersRolePicker';
import { useTeamMembersContext } from '../Context/TeamMembersContext';
import { useNotificationContext } from '../../../../../Context/NotificationContext';
import MembershipExistsError from '../../../../../Utils/HTTP/Errors/MembershipExistsError';
import InvalidDomainError from '../../../../../Utils/HTTP/Errors/InvalidDomainError';
import InvitationExistsError from '../../../../../Utils/HTTP/Errors/InvitationExistsError';

interface TeamMemberAddDialogProps extends Omit<DialogProps, 'maxWidth' | 'fullWidth'> {
    onClose: () => void;
}

const TeamMemberAddDialog = (props: TeamMemberAddDialogProps) => {
    const theme = useTheme();
    const { sendInvitation, isOperationLoading } = useTeamMembersContext();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    const handleSubmit = async (
        values: FormikTeamMember,
        { setSubmitting, setErrors }: FormikHelpers<FormikTeamMember>,
    ) => {
        try {
            const { email, role } = values;
            setSubmitting(false);
            await sendInvitation(email, role);
            props.onClose();
            showSuccessToaster(UIStrings.TheInvitationHasBeenSendTo(email));
            setSubmitting(true);
        } catch (error) {
            if (
                error instanceof MembershipExistsError ||
                error instanceof InvalidDomainError ||
                error instanceof InvitationExistsError
            ) {
                const { detail } = error;
                setErrors({ email: detail });
            } else {
                showFailToaster();
            }
        }
    };

    return (
        <Dialog maxWidth="md" fullWidth {...props}>
            <Formik
                initialValues={{ email: '', role: 'Admin' }}
                validationSchema={newTeamMemberSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validateOnMount={false}
            >
                {({ touched, errors, dirty }) => (
                    <Form>
                        <DialogTitle
                            variant="h4"
                            color={theme.palette.primary.main}
                            sx={{
                                paddingX: theme.spacing(2.5),
                                paddingTop: theme.spacing(2.5),
                                paddingBottom: theme.spacing(1.25),
                            }}
                        >
                            {UIStrings.InviteNewTeamMember}
                        </DialogTitle>
                        <DialogContent sx={{ paddingX: theme.spacing(2.5) }}>
                            <FormikTextField
                                label={`${UIStrings.Email}*`}
                                name="email"
                                fullWidth
                                sx={{ mb: theme.spacing(1.25) }}
                                data-cy="email"
                            />
                            <TeamMemberRolePicker />
                            <FormHelperText error>{touched.role && errors.role}</FormHelperText>
                            <DialogActions>
                                <Button onClick={props.onClose}>{UIStrings.Cancel}</Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    endIcon={<ChevronRightIcon />}
                                    disabled={!dirty}
                                    loading={isOperationLoading}
                                >
                                    {UIStrings.SendInvitation}
                                </LoadingButton>
                            </DialogActions>
                        </DialogContent>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default TeamMemberAddDialog;
