import { useTheme } from '@mui/material';
import { Avatar, Box, Card, CardHeader, Chip, MenuIconButton, Paper, Typography } from '@talentmesh/core';
import { MoreVertIcon } from '@talentmesh/icons';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import useCompanyId from '../../../../Hooks/UseCompanyId';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import { ClientOverviewResponse } from '../../../../DTO/Clients/ClientOverviewResponse';

interface ClientCardProps extends ClientOverviewResponse {
    onEditClient: (clientToEdit: ClientOverviewResponse) => void;
}

const ClientCard = ({ onEditClient, ...client }: ClientCardProps) => {
    const theme = useTheme();
    const companyId = useCompanyId();
    const clientId = client.id;

    return (
        <Card
            data-cy="ClientCard"
            sx={{
                width: '100%',
                height: '100%',
                ':hover': {
                    backgroundColor: theme.palette.grey[100],
                },
            }}
        >
            <Link
                to={generatePath(RoutePath.AssessmentsActive, { companyId, clientId })}
                style={{
                    display: 'block',
                    height: '100%',
                    padding: theme.spacing(1.25),
                    borderTop: `1px solid ${theme.palette.grey[300]}`,
                    textDecoration: 'none',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-between',
                        color: theme.palette.text.hint,
                    }}
                >
                    <CardHeader
                        data-cy="clientCardHeader"
                        avatar={
                            <Avatar
                                data-cy="ClientIcon"
                                alt={UIStrings.CompanyNameLogo(client.name)}
                                src={client.iconURL!}
                            />
                        }
                        title={client.name}
                        titleTypographyProps={{ variant: 'h4' }}
                        subheader={
                            client.isDefault && (
                                <Chip
                                    sx={{
                                        backgroundColor: theme.palette.hue.primary.h50,
                                        color: theme.palette.hue.primary.h900,
                                    }}
                                    size="small"
                                    label={UIStrings.MyCompany}
                                />
                            )
                        }
                        action={
                            !client.isDefault && (
                                <MenuIconButton
                                    menuItems={[
                                        {
                                            menuItemLabel: UIStrings.EditClient,
                                            dense: true,
                                            onClick: () => onEditClient(client),
                                        },
                                    ]}
                                    icon={<MoreVertIcon />}
                                />
                            )
                        }
                        sx={{
                            placeItems: 'start',
                            padding: theme.spacing(1.25),
                        }}
                    />

                    <Paper
                        elevation={0}
                        sx={{
                            py: theme.spacing(0.625),
                            backgroundColor: theme.palette.background.silverSand,
                            placeItems: 'center',
                        }}
                    >
                        <Typography variant="h6" align="center">
                            {UIStrings.Active}:
                        </Typography>
                        <Typography variant="h3" align="center">
                            {client.activeRecruitmentCount}
                        </Typography>
                    </Paper>
                </Box>
            </Link>
        </Card>
    );
};

export default ClientCard;
