import SchoolIcon from '@mui/icons-material/School';
import { WorkIcon } from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import TabPaper from '../Common/TabPaper';
import ProfileInformationSection from './Details/Components/ProfileInformationSection';
import { QualificationItemProps } from './Details/Components/Qualification/QualificationItem';
import QualificationSection from './Details/Components/Qualification/QualificationSection';
import {
    getEducationLevelLabel,
    getPeriod,
    getUserDefined,
} from './Details/Components/Qualification/QualificationUtils';
import TimelineChip from './Details/Components/Qualification/TimelineChip';
import RecruitmentInformationSection from './Details/Components/RecruitmentInformationSection';
import TestInformationSection from './Details/Components/TestInformationSection';

function DetailsTab(): JSX.Element {
    const { results } = useCandidatesResultsContext();
    const {
        applicantProfile: { name, location, isWillingToRelocate, email, experiences, educations },
        additionalInformation,
    } = results;

    const experienceItems: QualificationItemProps[] = experiences.map(
        ({ startDate, endDate, title, company, jobExperience }, index) => {
            return {
                period: getPeriod(startDate, endDate),
                header1: title,
                header2: company,
                label: `${UIStrings.JobType}: ${getUserDefined(jobExperience)}`,
                badge: index === 0 ? <TimelineChip icon={<WorkIcon />} label={UIStrings.LatestJobExperience} /> : null,
            };
        },
    );
    const educationItems: QualificationItemProps[] = educations.map(
        ({ startDate, endDate, schoolName, educationLevel, educationField }, index) => {
            return {
                period: getPeriod(startDate, endDate),
                header1: getEducationLevelLabel(educationLevel),
                header2: schoolName,
                label: `${UIStrings.FieldOfEducationIn} ${getUserDefined(educationField)}`,
                badge:
                    index === 0 ? (
                        <TimelineChip icon={<SchoolIcon />} label={UIStrings.HighestLevelOfEducation} />
                    ) : null,
            };
        },
    );

    return (
        <TabPaper data-cy="ApplicantResultsDetailsTab">
            <ProfileInformationSection
                name={name}
                location={location}
                isWillingToRelocate={isWillingToRelocate}
                email={email}
            />
            {experiences.length > 0 && (
                <QualificationSection header={UIStrings.JobExperience} items={experienceItems} />
            )}
            {educations.length > 0 && <QualificationSection header={UIStrings.Education} items={educationItems} />}
            {additionalInformation.length > 0 && (
                <RecruitmentInformationSection additionalInformation={additionalInformation} />
            )}
            {results.completedAt && (
                <TestInformationSection completedAt={results.completedAt} locale={results.locale} />
            )}
        </TabPaper>
    );
}

export default DetailsTab;
