import React from 'react';
import DocLargeIcon from './DocLargeIcon';
import PdfLargeIcon from './PdfLargeIcon';

function getCvIcon(extension: string): JSX.Element {
    switch (extension) {
        case '.pdf':
            return <PdfLargeIcon />;
        case '.doc':
        case '.docx':
            return <DocLargeIcon />;
        default:
            return <></>;
    }
}

export default getCvIcon;
