import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import React from 'react';
import UIStrings from '../Utils/UIStrings';
import CopyLinkControl from '../Pages/Candidates/Invite/Components/CopyLinkControl';
import { useNotificationContext } from '../Context/NotificationContext';
import { jobsBaseUrl } from '../AppSettings';
import { useCompanyContext } from '../Context/CompanyContext';

function CareerPageIntegration(): JSX.Element {
    const theme = useTheme();
    const { showSuccessToaster } = useNotificationContext();
    const { currentCompanyProfile } = useCompanyContext();

    const careerPageUrl = `${jobsBaseUrl}/${currentCompanyProfile.companySlug}`;

    const onClickHandler = () => {
        navigator.clipboard.writeText(careerPageUrl);
        showSuccessToaster('', UIStrings.LinkCopied);
    };

    return (
        <Stack direction="column" spacing={theme.spacing(2)}>
            <Typography variant="h4" fontWeight="bold">
                {UIStrings.IntegrateCareerPageWithWebsite}
            </Typography>
            <Typography variant="body1">{UIStrings.ConnectCareerPageToWebsite}</Typography>
            <CopyLinkControl link={careerPageUrl} onClickHandler={onClickHandler} width={theme.spacing(60)} />
        </Stack>
    );
}

export default CareerPageIntegration;
