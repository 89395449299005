import { useEffect, useState, SyntheticEvent, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { GridPaginationModel } from '@talentmesh/core';
import { useAssessmentClient, useCandidatesClient, useResultsClient } from '../../../../Hooks/ClientHooks';
import { CandidateOverview, CandidateStatuses, CandidateTabs } from '../../../../Models/CandidateOverview';
import {
    AdvancedFilter,
    mapAdvancedFiltersToIdsByType,
    mapAdvancedFilterDto2Model,
    mapAdvancedFilterModel2Dto,
} from '../Models/AdvancedFilter';
import AdvancedFilterTypes from '../../../../Models/AdvancedFilterTypes';
import CandidateFilterByEnum from '../Models/CandidateFilterByEnum';
import CandidateSortByEnum from '../Models/CandidateSortByEnum';
import { CandidatesOverviewContextState, CandidatesOverviewContextType } from './CandidatesOverviewContext';
import { sortByTestType } from '../Utils/ContextUtils';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';
import PaginationConsts from '../../../../Utils/PaginationConsts';
import { JobCategory } from '../../../../Models/JobCategory';
import { getJobCategoryJobFunctionPair } from '../../../../Utils/JobFunctionSelectionUtils';
import useCandidatesOverviewQueryParams, {
    CandidateOverviewQueryParams,
    CandidateOverviewQueryParamsSeparator,
} from '../Components/Hooks/useCandidatesOverviewQueryParams';

function useCandidatesOverviewContextValue(
    assessmentId: string,
    jobCategories: JobCategory[],
): CandidatesOverviewContextType {
    const assessmentClient = useAssessmentClient();
    const candidatesClient = useCandidatesClient();
    const resultsClient = useResultsClient();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useCandidatesOverviewQueryParams();

    const initialAdvancedFilterIds = useMemo<Record<AdvancedFilterTypes, string[]>>(
        () => ({
            All: [],
            Country: queryParams.countries,
            FieldOfEducation: queryParams.fieldOfEducations,
            HighestLevelOfEducation: queryParams.highestLevelOfEducations,
            JobType: queryParams.jobTypes,
        }),
        [],
    );

    const [state, setState] = useState<CandidatesOverviewContextState>({
        assessmentName: '',
        assessmentStatus: AssessmentStatuses.NonPublic,
        clientId: '',
        status: undefined,
        candidates: [],
        totalCandidatesCount: 0,
        loading: true,
        initialLoading: true,
        openFilter: false,
        sortBy: undefined,
        filterBy: queryParams.filterBy,
        search: queryParams.search,
        testTypes: [],
        selected: [],
        pageSize: PaginationConsts.regularPageSize,
        pageNumber: queryParams.pageNumber,
        totalOpen: 0,
        totalHired: 0,
        totalReject: 0,
        tabValue: queryParams.candidateTab,
        jobCategoryName: '',
        jobFunctionName: '',
        advancedFilters: [],
        includesExperienceCriteria: false,
    });

    const {
        assessmentName,
        assessmentStatus,
        clientId,
        status,
        candidates,
        totalCandidatesCount,
        loading,
        initialLoading,
        openFilter,
        sortBy,
        filterBy,
        search,
        testTypes,
        selected,
        pageSize,
        pageNumber,
        totalOpen,
        totalHired,
        totalReject,
        tabValue,
        jobCategoryName,
        jobFunctionName,
        advancedFilters,
        includesExperienceCriteria,
    } = state;

    /** query param block */
    const selectedAdvancedFilters = useMemo(
        () => advancedFilters.filter((advanceFilter) => advanceFilter.selected),
        [advancedFilters],
    );
    const advancedFilterQuery = useMemo(
        () => ({
            countries: mapAdvancedFiltersToIdsByType(selectedAdvancedFilters, AdvancedFilterTypes.Country),
            jobTypes: mapAdvancedFiltersToIdsByType(selectedAdvancedFilters, AdvancedFilterTypes.JobType),
            fieldOfEducations: mapAdvancedFiltersToIdsByType(
                selectedAdvancedFilters,
                AdvancedFilterTypes.FieldOfEducation,
            ),
            highestLevelOfEducations: mapAdvancedFiltersToIdsByType(
                selectedAdvancedFilters,
                AdvancedFilterTypes.HighestLevelOfEducation,
            ),
        }),
        [selectedAdvancedFilters],
    );

    // this is responsible for adding applicant's overview filters to the
    // query parameters inside current url so filters are preserved between page change, refresh, etc
    useEffect(() => {
        // a way to check if spec is loaded or not, a better one is to have a separate fetch status for loadSpecificationAsync, but initialLoading should suffice
        // this is done so if the url does contain filters and we're saving filters in useState instead of taking it directly from queryParams,
        // it won't assign anything after spec is fully loaded rather than causing flash of url change in the browser which made it look unpleasant
        if (initialLoading) {
            return;
        }

        const params: Partial<Record<CandidateOverviewQueryParams, string>> = {
            page: pageNumber.toString(),
            tab: tabValue,
        };

        const assignFilterToParamsIfNotEmpty = (arr: any[], param: CandidateOverviewQueryParams) => {
            if (arr.length) {
                params[param] = arr.join(CandidateOverviewQueryParamsSeparator);
            }
        };

        assignFilterToParamsIfNotEmpty(filterBy, 'filterBy');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.countries, 'Country');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.fieldOfEducations, 'FieldOfEducation');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.highestLevelOfEducations, 'HighestLevelOfEducation');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.jobTypes, 'JobType');

        if (search) {
            params.search = search;
        }

        navigate({ pathname: location.pathname, search: new URLSearchParams(params).toString() }, { replace: true });
    }, [pageNumber, tabValue, filterBy, advancedFilterQuery, search, initialLoading]);

    // this is for opening up the filter menu if initial value from query params is present
    useEffect(() => {
        if (
            queryParams.filterBy.length ||
            queryParams.countries.length ||
            queryParams.fieldOfEducations.length ||
            queryParams.highestLevelOfEducations.length ||
            queryParams.jobTypes.length
        ) {
            setState((p) => ({
                ...p,
                openFilter: true,
            }));
        }
    }, [
        queryParams.filterBy,
        queryParams.countries,
        queryParams.fieldOfEducations,
        queryParams.highestLevelOfEducations,
        queryParams.jobTypes,
    ]);
    /** query param block */

    const loadPageAsync = async () => {
        if (initialLoading) {
            return;
        }

        setState((prev) => {
            return {
                ...prev,
                loading: true,
            };
        });

        const currentStatus = (tabValue === CandidateTabs.Open ? undefined : `${tabValue}`) as CandidateStatuses;

        try {
            const page = await assessmentClient.getApplicantsOverviewAsync(
                assessmentId,
                pageSize * pageNumber,
                pageSize,
                filterBy,
                search,
                currentStatus,
                sortBy,
                selectedAdvancedFilters.map(mapAdvancedFilterModel2Dto),
            );

            setState((prev) => {
                return {
                    ...prev,
                    status: currentStatus,
                    totalCandidatesCount: page.pagination.totalCount,
                    candidates: page.data,
                    selected: [],
                    totalOpen: page.totalOpen,
                    totalHired: page.totalHired,
                    totalReject: page.totalRejected,
                };
            });
        } finally {
            setState((prev) => {
                return {
                    ...prev,
                    loading: false,
                };
            });
        }
    };

    useEffect(() => {
        const doLoadCandidates = async () => await loadPageAsync();
        doLoadCandidates();
    }, [status, sortBy, filterBy, search, pageNumber, pageSize, advancedFilters]);

    const loadSpecificationAsync = async () => {
        const spec = await assessmentClient.getSpecificationAsync(assessmentId);
        const jobPair = getJobCategoryJobFunctionPair(jobCategories, spec.jobFunctionId);

        const filters = await resultsClient.getAdvancedFilters(assessmentId);
        const mappedAdvancedFilters = filters.map(mapAdvancedFilterDto2Model);
        const selectedByInitialAdvancedFilters = mappedAdvancedFilters.map((filter) => ({
            ...filter,
            selected: initialAdvancedFilterIds[filter.filterType].includes(filter.id),
        }));

        setState((prev) => {
            return {
                ...prev,
                initialLoading: false,
                loading: false,
                assessmentName: spec.name,
                assessmentStatus: spec.assessmentStatus,
                clientId: spec.clientId,
                testTypes: spec.includedTests.sort(sortByTestType),
                jobCategoryName: jobPair.jobCategoryName,
                jobFunctionName: jobPair.jobFunctionName,
                advancedFilters: selectedByInitialAdvancedFilters,
                includesExperienceCriteria: spec.includesExperienceCriteria,
            };
        });
    };

    useEffect(() => {
        loadSpecificationAsync();
    }, []);

    const sort = (value?: CandidateSortByEnum) => {
        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                sortBy: value,
            };
        });
    };

    const filter = (values: CandidateFilterByEnum[]) => {
        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                filterBy: values,
            };
        });
    };

    const searchByName = (name: string) => {
        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                search: name,
            };
        });
    };

    const saveFavoriteAsync = async (candidateId: string, isFavorite: boolean) => {
        await candidatesClient.saveFavoriteAsync(assessmentId, candidateId, isFavorite);

        const map = (item: CandidateOverview) => {
            if (item.candidateId === candidateId) {
                return { ...item, isFavorite };
            }
            return item;
        };

        setState((prev) => {
            return {
                ...prev,
                candidates: prev.candidates.map((item) => map(item)),
            };
        });
    };

    const handlePaginationModelChange = (model: GridPaginationModel) =>
        setState((prev) => {
            return {
                ...prev,
                pageNumber: model.page,
            };
        });

    const setSelected = (values: Array<CandidateOverview>) => {
        setState((prev) => {
            return {
                ...prev,
                selected: values,
            };
        });
    };

    const resetAdvancedFilterSelectedValues = (): AdvancedFilter[] => {
        return advancedFilters.map((item: AdvancedFilter) => {
            return { ...item, selected: false };
        });
    };

    const handleTabChange = (_: SyntheticEvent, value: CandidateTabs) => {
        const nextStatus = (value === CandidateTabs.Open ? undefined : `${value}`) as CandidateStatuses;

        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                tabValue: value,
                status: nextStatus,
                openFilter: false,
                filterBy: [],
                advancedFilters: resetAdvancedFilterSelectedValues(),
            };
        });
    };

    const toggleOpenFilter = (): void => {
        setState((prev) => {
            return {
                ...prev,
                openFilter: !prev.openFilter,
            };
        });
    };

    const compareFilters = (a: AdvancedFilter, b: AdvancedFilter): boolean => {
        return a.id === b.id && a.filterType === b.filterType;
    };

    const updateAdvancedFilter = (newFilter: AdvancedFilter) => {
        setState((prev) => {
            const newAdvancedFilters = prev.advancedFilters.filter((x) => !compareFilters(x, newFilter));
            newAdvancedFilters.push(newFilter);

            return {
                ...prev,
                advancedFilters: newAdvancedFilters,
            };
        });
    };

    const resetAdvancedFilterByType = (filterType: AdvancedFilterTypes) => {
        setState((prev) => {
            const resettedFilters = prev.advancedFilters
                .filter((x) => x.filterType === filterType)
                .map((filters: AdvancedFilter): AdvancedFilter => {
                    return { ...filters, selected: false };
                });

            const currentFilters = prev.advancedFilters.filter((x) => x.filterType !== filterType);

            return {
                ...prev,
                advancedFilters: [...currentFilters, ...resettedFilters],
            };
        });
    };

    const resetAdvancedFilter = () => {
        setState((prev) => {
            return {
                ...prev,
                filterBy: [],
                advancedFilters: resetAdvancedFilterSelectedValues(),
            };
        });
    };

    return {
        assessmentId,
        assessmentName,
        clientId,
        assessmentStatus,
        candidates,
        loading,
        openFilter,
        totalOpen,
        totalHired,
        totalReject,
        includesExperienceCriteria,

        testTypes,

        sort,
        filter,
        searchByName,

        saveFavoriteAsync,

        selected,
        setSelected,

        loadPageAsync,
        totalCandidatesCount,

        pageSize,
        pageNumber,
        handlePaginationModelChange,

        filterBy,

        tabValue,
        handleTabChange,
        jobCategoryName,
        jobFunctionName,
        toggleOpenFilter,
        advancedFilters,
        updateAdvancedFilter,
        resetAdvancedFilterByType,
        resetAdvancedFilter,
        search,
    };
}

export default useCandidatesOverviewContextValue;
