import React from 'react';
import { BrandedCardContainer, ContainerBackground } from '@talentmesh/core';
import EmailVerifiedPageContent from './Components/EmailVerifiedPageContent';

function EmailVerified(): JSX.Element {
    return (
        <ContainerBackground>
            <BrandedCardContainer>
                <EmailVerifiedPageContent />
            </BrandedCardContainer>
        </ContainerBackground>
    );
}

export default EmailVerified;
