import { Pagination, paginationItemClasses, useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import React, { useMemo } from 'react';
import UIStrings from '../../../../../Utils/UIStrings';
import { useClientsOverviewContext } from '../../../contexts/ClientsOverviewContext';

const ClientsOverviewPagination = () => {
    const theme = useTheme();
    const {
        pagination: { limit, offset, totalCount },
        setPagination,
    } = useClientsOverviewContext();

    const paginationSizeInfo = useMemo<Parameters<typeof UIStrings.PaginationSizeInfo>>(() => {
        let currentSize = limit + offset;

        if (totalCount < currentSize) {
            currentSize = totalCount;
        }

        return [offset + 1, currentSize, totalCount];
    }, [limit, offset, totalCount]);

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        const currentOffset = (value - 1) * limit;
        setPagination((p) => ({ ...p, offset: currentOffset }));
    };

    return (
        <Stack data-cy="clientsPagination" direction="row" spacing={2.5} justifyContent="center" alignItems="center">
            <Pagination
                variant="outlined"
                page={(limit + offset) / limit}
                count={Math.ceil(totalCount / limit)}
                onChange={handleChange}
                boundaryCount={0}
                siblingCount={4}
                sx={{
                    marginLeft: 'auto',
                    [`.${paginationItemClasses.root}`]: {
                        backgroundColor: theme.palette.common.white,
                        [`&.${paginationItemClasses.selected}`]: {
                            backgroundColor: theme.palette.hue.primary.h50,
                        },
                        [`&.${paginationItemClasses.disabled}`]: {
                            opacity: 0.5,
                            backgroundColor: theme.palette.common.white,
                        },
                        [`&.${paginationItemClasses.ellipsis}`]: {
                            display: 'none',
                        },
                    },
                }}
            />
            <Typography variant="caption">{UIStrings.PaginationSizeInfo(...paginationSizeInfo)}</Typography>
        </Stack>
    );
};

export default ClientsOverviewPagination;
