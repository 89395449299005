import { createContext, useContext } from 'react';

export interface UserSignupNavigationData {
    isLoading: boolean;
    navigateToNextPage: () => void;
    refreshTokenAsync: () => Promise<void>;
}

export const UserSignupNavigationContext = createContext<UserSignupNavigationData | undefined>(undefined);

export function useUserSignupNavigationContext() {
    const context = useContext(UserSignupNavigationContext);
    if (!context) {
        throw new Error(
            "'useUserSignupNavigationContext' must be called within 'UserSignupNavigationContext.Provider'",
        );
    }
    return context;
}
