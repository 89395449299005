import * as yup from 'yup';
import BillingInfoDTO from '../DTO/Users/BillingInfoDTO';
import YupValidationErrors from '../Utils/YupValidationErrors';

export default interface BillingInfo {
    /**
     * Full name.
     */
    billingTo: string;

    /**
     * City, district, suburb, town, or village.
     */
    city: string;

    /**
     * Two-letter country code ([ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)).
     */
    country: string;

    /**
     * Address line 1 (e.g., street, PO Box, or company name).
     */
    addressLine1: string;

    /**
     * Address line 2 (e.g., apartment, suite, unit, or building).
     */
    addressLine2?: string;

    /**
     * ZIP or postal code.
     */
    postalCode: string;

    /**
     * State, county, province, or region.
     */
    state: string;
}

export const billingInfoValidationScheme = yup.object().shape({
    billingTo: yup
        .string()
        .required(YupValidationErrors.Required)
        .max(50, YupValidationErrors.MaxSymbolsValidationError),
    city: yup.string().required(YupValidationErrors.Required).max(50, YupValidationErrors.MaxSymbolsValidationError),
    country: yup.string().required(YupValidationErrors.Required).max(2, YupValidationErrors.MaxSymbolsValidationError),
    addressLine1: yup
        .string()
        .required(YupValidationErrors.Required)
        .max(50, YupValidationErrors.MaxSymbolsValidationError),
    addressLine2: yup.string().max(50, YupValidationErrors.MaxSymbolsValidationError),
    postalCode: yup
        .string()
        .required(YupValidationErrors.Required)
        .max(10, YupValidationErrors.MaxSymbolsValidationError),
    state: yup.string().required(YupValidationErrors.Required).max(50, YupValidationErrors.MaxSymbolsValidationError),
});

export const EmptyBillingInfo: BillingInfo = {
    billingTo: '',
    city: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    state: '',
};

export const fromBillingInfoDTO = (dto: BillingInfoDTO): BillingInfo => {
    return {
        billingTo: dto.billingTo,
        city: dto.city,
        country: dto.country,
        addressLine1: dto.addressLine1,
        addressLine2: dto.addressLine2,
        postalCode: dto.postalCode,
        state: dto.state,
    } as BillingInfo;
};

export function fromBillingInfo(info: BillingInfo): BillingInfoDTO {
    return {
        billingTo: info.billingTo,
        city: info.city,
        country: info.country,
        addressLine1: info.addressLine1,
        addressLine2: info.addressLine2,
        postalCode: info.postalCode,
        state: info.state,
    } as BillingInfoDTO;
}
