import React from 'react';
import { useTheme } from '@mui/material';
import { Box, Typography } from '@talentmesh/core';

interface PostingOptionLabelProps {
    label: string;
    description?: React.ReactNode;
}

const PostingOptionLabel = ({ label, description }: PostingOptionLabelProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Box>
            <Typography
                sx={{ mb: theme.spacing(1.25), textAlign: 'center' }}
                variant="h4"
                color={theme.palette.text.menuTitle}
            >
                {label}
            </Typography>
            {description && description}
        </Box>
    );
};

export default PostingOptionLabel;
