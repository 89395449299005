import { useTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { Dialog, IconButton, Paper, Stack } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import { DialogEnum, useActionDialogContext } from '../Context/ActionDialogContext';

interface ActionConfirmationDialogProps {
    dialogType: DialogEnum;
    header: ReactNode;
    body: ReactNode;
    actions: ReactNode;
    renderClose?: boolean;
    paperWidth?: number;
}

function ActionConfirmationDialog({
    dialogType,
    header,
    body,
    actions,
    renderClose = true,
    paperWidth = 65,
}: ActionConfirmationDialogProps): JSX.Element {
    const theme = useTheme();
    const { hideDialog, isDialogOpen } = useActionDialogContext();

    const closeConfirm = () => {
        hideDialog(dialogType);
    };

    return (
        <Dialog
            open={isDialogOpen(dialogType)}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    maxWidth: 'unset',
                    width: 'auto',
                    height: 'auto',
                },
            }}
        >
            <Stack direction="row" spacing={8}>
                <Paper sx={{ width: theme.spacing(paperWidth) }} elevation={1}>
                    <Stack
                        sx={{
                            paddingTop: 0,
                            paddingLeft: 2.5,
                            paddingRight: 2.5,
                            paddingBottom: 2.5,
                        }}
                        spacing={3.75}
                    >
                        <Stack direction="column">
                            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                                {header}
                                {renderClose && (
                                    <IconButton onClick={closeConfirm} size="large">
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            {body}
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            {actions}
                        </Stack>
                    </Stack>
                </Paper>
            </Stack>
        </Dialog>
    );
}

export default ActionConfirmationDialog;
