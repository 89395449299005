import { useTheme } from '@mui/material';
import { IconButton, Stack, Typography } from '@talentmesh/core';
import { DeleteOutlineIcon } from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../../../../../../../../Utils/UIStrings';

export interface CriteriaFieldLabelProps {
    index: number;
    disabled?: boolean;
    onRemove?: () => void;
}

const CriteriaFieldLabel = ({ index, disabled, onRemove }: CriteriaFieldLabelProps) => {
    const theme = useTheme();

    return (
        <Stack direction="row" sx={{ width: '100%', placeItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4" color={theme.palette.text.menuTitle}>
                {UIStrings.Criteria(index + 1)}
            </Typography>
            <IconButton color="primary" aria-label="delete" disabled={disabled} onClick={onRemove}>
                <DeleteOutlineIcon />
            </IconButton>
        </Stack>
    );
};

export default CriteriaFieldLabel;
