import React from 'react';
import { Grid } from '@talentmesh/core';
import UIStrings from '../../../../../../../Utils/UIStrings';
import CustomBox from '../../Components/Common/CustomBox';
import { HorizontalDivider } from '../../Components/Common/CustomDivider';
import DetailsHeader from './Common/DetailsHeader';
import GridRowItem from './Common/GridRowItem';
import toDateString, { toUTCDate } from '../../../../../../../Utils/DateHelper';

interface RecruitmentInformationSectionProps {
    completedAt?: string;
    locale: string | null;
}

function TestInformationSection({ completedAt, locale }: RecruitmentInformationSectionProps): JSX.Element {
    const formattedDate = completedAt === undefined ? '' : toDateString(toUTCDate(completedAt));

    return (
        <>
            <DetailsHeader label={UIStrings.TestInformation} />
            <HorizontalDivider />
            <CustomBox>
                <Grid container direction="column" spacing={3}>
                    <GridRowItem title={UIStrings.TestsCompleted} value={formattedDate} />
                    <GridRowItem title={UIStrings.TestsLanguage} value={locale ?? ''} />
                </Grid>
            </CustomBox>
        </>
    );
}
export default TestInformationSection;
