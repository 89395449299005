import React, { useState } from 'react';
import { Stack, Typography } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import { useCompanyClient } from '../../../Hooks/ClientHooks';
import { CompanyImage } from '../../../Clients/CompanyClient';
import { useNotificationContext } from '../../../Context/NotificationContext';
import { ImageCropStrings, defaultAllowedImageTypes } from '../../../Components/ImageCrop/ImageCropUtils';
import { ImageCrop, ImageCropProps } from '../../../Components/ImageCrop/ImageCrop';
import useCompanyId from '../../../Hooks/UseCompanyId';
import { useCompanyContext } from '../../../Context/CompanyContext';

interface CompanyUploadImageSectionProps
    extends Omit<
        ImageCropProps,
        'strings' | 'validateCallback' | 'uploadCallbackAsync' | 'resetCallbackAsync' | 'processingErrorCallback'
    > {
    title: string;
    description: string;
    companyImageType: CompanyImage;
}

const CompanyUploadImageSection = ({
    sx,
    src,
    title,
    aspect,
    description,
    circularCrop,
    companyImageType,
}: CompanyUploadImageSectionProps): JSX.Element => {
    const { refreshCurrentCompanyProfile } = useCompanyContext();
    const companyId = useCompanyId();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();
    const companyClient = useCompanyClient();
    const [validationMessage, setValidationMessage] = useState<string>('');

    const imageCropStrings: ImageCropStrings = {
        selectButtonTooltipText: UIStrings.UploadImage,
        removeButtonTooltipText: UIStrings.RemoveImage,
        uploadButtonText: UIStrings.Upload,
        dialogHeaderText: UIStrings.CropYourImage,
        dialogContentText: UIStrings.SelectTheAreaToBeCropped,
    };

    const validateCallback = (value: Blob): boolean => {
        if (value.size > 1024 * 1024 * 1) {
            setValidationMessage(UIStrings.DefaultImageSizeValidationErrorText);
            return false;
        }

        if (defaultAllowedImageTypes.find((imageType) => imageType === value.type) === undefined) {
            setValidationMessage(UIStrings.DefaultImageTypeValidationErrorText);
            return false;
        }

        if (validationMessage) {
            setValidationMessage('');
        }

        return true;
    };

    const processingErrorCallback = () => {
        showFailToaster(UIStrings.DefaultImageCropDialogProcessingErrorText);
    };

    const handleImageChange = async (image?: Blob): Promise<void> => {
        try {
            if (image) {
                await companyClient.updateCompanyImage(companyId, image, companyImageType);
            } else {
                await companyClient.resetCompanyImage(companyId, companyImageType);
            }
            await refreshCurrentCompanyProfile(companyId);
            showSuccessToaster(UIStrings.SuccessfulImageChangeToaster);
        } catch (error) {
            showFailToaster(image ? UIStrings.FailedImageUploadToaster : UIStrings.FailedImageResetToaster);
        }
    };

    return (
        <Stack
            data-cy={`CompanyUpload${companyImageType}Section`}
            direction="row"
            sx={{ alignItems: 'start', justifyContent: 'space-between' }}
        >
            <Stack spacing={0.625}>
                <Typography variant="h5" color="secondary">
                    {title}
                </Typography>
                <Typography variant="body2" color="secondary">
                    {description}
                </Typography>
                <Typography variant="body2" color="error">
                    {validationMessage}
                </Typography>
            </Stack>
            <ImageCrop
                circularCrop={circularCrop}
                aspect={aspect}
                src={src}
                strings={imageCropStrings}
                validateCallback={validateCallback}
                uploadCallbackAsync={handleImageChange}
                resetCallbackAsync={handleImageChange}
                processingErrorCallback={processingErrorCallback}
                sx={sx}
            />
        </Stack>
    );
};

export default CompanyUploadImageSection;
