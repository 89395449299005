import { useTheme } from '@mui/material';
import React from 'react';
import { FormControlLabel, Switch, Typography } from '@talentmesh/core';
import { FormikSwitchProps } from '@talentmesh/forms';

export interface LabeledFormikSwitchProps extends FormikSwitchProps {
    label: string;
}

function LabeledFormikSwitch({ label, ...props }: LabeledFormikSwitchProps): JSX.Element {
    const theme = useTheme();
    return (
        <FormControlLabel
            control={<Switch {...props} />}
            label={
                <Typography
                    variant="body1"
                    color={theme.palette.common.black}
                    sx={{
                        paddingLeft: theme.spacing(2),
                    }}
                >
                    {label}
                </Typography>
            }
        />
    );
}

export default LabeledFormikSwitch;
