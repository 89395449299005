import { ProfileMenuItemProps } from '../Components/TopBars/TopBarMenu/ProfileMenuItem';

export enum MenuViews {
    Main,
    Companies,
}

export interface MenuViewItem {
    viewId: MenuViews;
    menuItems: ProfileMenuItemProps[];
}
