import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Search, Stack, Button } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { useTeamMembersContext } from '../Context/TeamMembersContext';
import TeamMemberAddDialog from './TeamMemberAddDialog';

const TeamMembersActionBar = () => {
    const { searchTeamMember } = useTeamMembersContext();
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleInviteNewMembers = () => setOpenDialog(true);

    return (
        <Stack direction="row" justifyContent="space-between">
            <Box width="50%">
                <Search fullWidth placeholder={UIStrings.SearchByNameOrEmail} onSearch={searchTeamMember} />
            </Box>
            <Button variant="text" color="primary" startIcon={<AddCircleIcon />} onClick={handleInviteNewMembers}>
                {UIStrings.InviteNewMembers}
            </Button>
            <TeamMemberAddDialog open={openDialog} onClose={handleCloseDialog} />
        </Stack>
    );
};

export default TeamMembersActionBar;
