import React from 'react';
import { useTheme } from '@mui/material';
import { LoadingButton, Stack, Typography } from '@talentmesh/core';
import { TimerOutlinedIcon } from '@talentmesh/icons';
import TestInfo from '../../../../../../../../../Models/TestInfo';
import UIStrings from '../../../../../../../../../../../../Utils/UIStrings';

export interface BaseTestCardDurationProps extends Pick<TestInfo, 'duration'> {
    toggleDialog: () => void;
    isPreviewQuestionsLoading: boolean;
}

function BaseTestCardDuration({
    duration,
    toggleDialog,
    isPreviewQuestionsLoading,
}: BaseTestCardDurationProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={2.5}>
            <Stack direction="row" width="100%" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={0.625}>
                    <TimerOutlinedIcon />
                    <Typography variant="body2" color={theme.palette.text.primary}>
                        {`${duration} ${UIStrings.ShortMinute}`}
                    </Typography>
                </Stack>
                <LoadingButton
                    loading={isPreviewQuestionsLoading}
                    variant="outlined"
                    size="medium"
                    onClick={(e) => {
                        // to stop toggling the test card when clicked
                        e.stopPropagation();
                        toggleDialog();
                    }}
                >
                    {UIStrings.PreviewQuestions}
                </LoadingButton>
            </Stack>
        </Stack>
    );
}

export default BaseTestCardDuration;
