import { Button } from '@talentmesh/core';
import { AddIcon } from '@talentmesh/icons';
import React, { useEffect, useState } from 'react';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import UIStrings from '../../../../Utils/UIStrings';
import { useClientsOverviewContext } from '../../contexts/ClientsOverviewContext';
import { ClientFormValues } from '../Client';
import ClientFormDialog from '../ClientForm/ClientFormDialog';
import ProcessingState from '../../../../Models/ProcessingState';

const ClientAddButton = () => {
    const { showFailToaster } = useNotificationContext();
    const [open, setOpen] = useState(false);
    const { createClient, createClientProcessingState } = useClientsOverviewContext();

    useEffect(() => {
        if (createClientProcessingState === ProcessingState.Error) {
            showFailToaster();
        }

        if (createClientProcessingState === ProcessingState.Success) {
            setOpen(false);
        }
    }, [createClientProcessingState]);

    const onSubmit = async (client: ClientFormValues) => {
        await createClient(client);
    };

    return (
        <>
            <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="large"
                onClick={() => setOpen(true)}
            >
                {UIStrings.CreateClient}
            </Button>
            <ClientFormDialog open={open} onClose={() => setOpen(false)} onSubmit={onSubmit} />
        </>
    );
};

export default ClientAddButton;
