import React from 'react';
import { AccountCircleOutlinedIcon } from '@talentmesh/icons';
import ProfileChip from './ProfileChip';
import ProfileDetailLabel from './ProfileDetailLabel';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';
import UIStrings from '../../../../../Utils/UIStrings';

export interface ProfileExperienceProps extends Pick<CandidateOverview, 'experience'> {}

function ProfileExperience({ experience }: ProfileExperienceProps): JSX.Element {
    const { title, company, jobExperience } = experience;

    return (
        <ProfileChip
            testLabel={UIStrings.JobExperience}
            label={`${title} (${company})`}
            icon={<AccountCircleOutlinedIcon />}
            hoverContent={
                <>
                    <ProfileDetailLabel name={UIStrings.Title} value={experience.title} />
                    <ProfileDetailLabel name={UIStrings.CompanyName} value={experience.company} />
                    <ProfileDetailLabel name={UIStrings.JobType} value={jobExperience.userDefined ?? ''} />
                </>
            }
        />
    );
}

export default ProfileExperience;
