import React, { useEffect } from 'react';
import { Stack } from '@talentmesh/core';
import { InviteCandidateProvider } from '../Contexts/InviteCandidateContext';
import EditCandidatesPanel from '../EditCandidatesPanel/EditCandidatesPanel';
import ConfirmationDialog from '../Components/ConfirmationDialog';
import ChangesMaybeLostDialog from '../Components/ChangesMaybeLostDialog';
import PreviewInvitationEmailDialog from '../Components/PreviewInvitationEmailDialog';
import RecruitmentLinkCopiedDialog from '../Components/RecruitmentLinkCopiedDialog';
import InvitationLinkPanel from '../InvitationLinkPanel/InvitationLinkPanel';

const InviteCandidateTabContents = (): JSX.Element => {
    // this logic is used to prevent navigation away from the page without saving changes
    useEffect(() => {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            return '';
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return (
        <InviteCandidateProvider>
            <Stack spacing={3}>
                <InvitationLinkPanel />
                <EditCandidatesPanel />
            </Stack>
            <ConfirmationDialog />
            <RecruitmentLinkCopiedDialog />
            <PreviewInvitationEmailDialog />
            <ChangesMaybeLostDialog />
        </InviteCandidateProvider>
    );
};

export default InviteCandidateTabContents;
