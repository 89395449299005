import React from 'react';
import { useTheme } from '@mui/material';
import { Button, Dialog, DialogContent, DialogActions, DialogProps, DialogTitle } from '@talentmesh/core';
import UIStrings from '../../../../../../Utils/UIStrings';

interface ConfirmationDialogProps extends DialogProps {
    title?: string;
    actionButtonText?: string;
    onConfirm?: () => void;
    onClose?: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    onConfirm,
    title,
    actionButtonText,
    children,
    ...props
}) => {
    const theme = useTheme();

    return (
        <Dialog PaperProps={{ sx: { maxWidth: theme.spacing(48) } }} {...props}>
            <DialogTitle variant="h4">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="outlined" color="secondary">
                    {UIStrings.Cancel}
                </Button>
                <Button onClick={onConfirm} color="error" variant="contained">
                    {actionButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
