import * as yup from 'yup';
import UIStrings from '../Utils/UIStrings';
import YupValidationErrors from '../Utils/YupValidationErrors';
import { RecruiterRoles } from './RecruiterRoles';

export type MembershipStatus = 'Invited' | 'Member' | 'Expired';

export interface TeamMember {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: RecruiterRoles;
    status: MembershipStatus;
}

export type FormikTeamMember = Pick<TeamMember, 'email' | 'role'>;

export const newTeamMemberSchema: yup.SchemaOf<FormikTeamMember> = yup.object().shape({
    email: yup
        .string()
        .email(YupValidationErrors.EmailValidationError)
        .max(128, YupValidationErrors.MaxSymbolsValidationError)
        .required(UIStrings.Required),
    role: yup
        .mixed<RecruiterRoles>()
        .oneOf(['Admin'] as RecruiterRoles[])
        .required(UIStrings.Required),
});
