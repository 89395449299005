import React, { ReactNode } from 'react';
import SettingsTabFailedLoading from './SettingsTabFailedLoading';
import TabLoadingSpinner from '../../../Components/TabLoadingSpinner';

interface LoadingTabProps {
    isLoading: boolean;
    loadingFailed: boolean;
    children: ReactNode;
}

const LoadingTab = ({ isLoading, loadingFailed, children }: LoadingTabProps) => {
    if (isLoading) {
        return <TabLoadingSpinner />;
    }

    if (loadingFailed) {
        return <SettingsTabFailedLoading />;
    }

    return <>{children}</>;
};

export default LoadingTab;
