import React, { useRef, SyntheticEvent, InputHTMLAttributes } from 'react';
import { ReactCrop, ReactCropProps } from 'react-image-crop';
import { useTheme } from '@mui/material/styles';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageCropDialog } from './ImageCropDialog';
import { useImageCropContext } from './ImageCropContext';
import { ImageCropDialogHeader } from './ImageCropDialogHeader';
import { ImageCropRemoveButton } from './ImageCropRemoveButton';
import { ImageCropDialogActions } from './ImageCropDialogActions';
import { ImageCropSelectButton, ImageCropSelectButtonProps } from './ImageCropSelectButton';
import {
    ImageCropStrings,
    centerCropByAspectRatio,
    defaultAspectRatio,
    defaultMinimumCropWidth,
    defaultAllowedImageTypes,
} from './ImageCropUtils';

export interface ImageCropInnerProps
    extends Pick<ImageCropSelectButtonProps, 'sx'>,
        Pick<ReactCropProps, 'aspect' | 'circularCrop'>,
        Pick<HTMLImageElement, 'src'> {
    strings: ImageCropStrings;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    validateCallback: (value: Blob) => boolean;
}

export const ImageCropInner = ({
    sx,
    src,
    aspect,
    circularCrop,
    strings,
    inputProps,
    validateCallback,
}: ImageCropInnerProps): JSX.Element => {
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imagePreviewRef = useRef<HTMLImageElement>(null);
    const {
        openDialog,
        validationError,
        processing,
        selectedImage,
        crop,
        setOpenDialog,
        setSelectedImage,
        setCrop,
        setPixelCrop,
        uploadImageAsync,
        resetImage,
    } = useImageCropContext();

    const { selectButtonTooltipText, removeButtonTooltipText, uploadButtonText, dialogHeaderText, dialogContentText } =
        strings;

    const isImageSet = src !== '';
    const aspectRatio = aspect || defaultAspectRatio;

    const focusFileInput = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    };

    const handleFileRead = (reader: FileReader, type: string) => {
        const result = reader.result?.toString() ?? '';

        if (result) {
            setSelectedImage(result, type);
        }
    };

    const handleFileChange = () => {
        if (inputRef.current) {
            const { files } = inputRef.current;

            if (files && files.length > 0) {
                const reader = new FileReader();
                const file = files[0];

                if (validateCallback(file)) {
                    reader.addEventListener('load', () => handleFileRead(reader, file.type));
                    reader.readAsDataURL(file);

                    reader.removeEventListener('load', () => handleFileRead(reader, file.type));
                }
            }
        }
    };

    const handleFileUpload = async () => {
        if (imagePreviewRef.current && canvasRef.current) {
            uploadImageAsync(imagePreviewRef.current, canvasRef.current);
        }
    };

    const handleInitialCrop = (event: SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = event.currentTarget;
        setCrop(centerCropByAspectRatio(width, height, aspectRatio));
    };

    return (
        <>
            <ImageCropSelectButton
                selectButtonTooltipText={selectButtonTooltipText}
                removeButton={
                    <ImageCropRemoveButton
                        processing={processing}
                        removeButtonTooltipText={removeButtonTooltipText}
                        onClick={resetImage}
                    />
                }
                onClick={focusFileInput}
                disabled={isImageSet}
                sx={sx}
            >
                <img
                    data-cy="ImageCropCurrentImage"
                    src={src}
                    alt=""
                    style={{
                        height: 'inherit',
                        width: 'inherit',
                    }}
                />
            </ImageCropSelectButton>
            {!isImageSet && (
                <>
                    <canvas ref={canvasRef} hidden />
                    <input
                        ref={inputRef}
                        type="file"
                        accept={defaultAllowedImageTypes.join(', ')}
                        {...inputProps}
                        onChange={handleFileChange}
                        hidden
                    />
                </>
            )}
            <ImageCropDialog
                dialogContentText={dialogContentText}
                open={openDialog}
                validationError={validationError}
                header={
                    <ImageCropDialogHeader
                        processing={processing}
                        onClick={() => setOpenDialog(false)}
                        dialogHeaderText={dialogHeaderText}
                    />
                }
                actions={
                    <ImageCropDialogActions
                        processing={processing}
                        onUpload={handleFileUpload}
                        uploadButtonText={uploadButtonText}
                    />
                }
            >
                {selectedImage && (
                    <ReactCrop
                        crop={crop}
                        keepSelection
                        circularCrop={circularCrop}
                        disabled={processing}
                        aspect={aspectRatio}
                        onChange={(_, y) => setCrop(y)}
                        onComplete={(x) => setPixelCrop(x)}
                        minWidth={defaultMinimumCropWidth}
                    >
                        <img
                            ref={imagePreviewRef}
                            src={selectedImage}
                            onLoad={handleInitialCrop}
                            alt=""
                            style={{
                                backgroundColor: theme.palette.common.white,
                                minWidth: '100%',
                                minHeight: '100%',
                                maxWidth: theme.spacing(69),
                                maxHeight: theme.spacing(30),
                            }}
                        />
                    </ReactCrop>
                )}
            </ImageCropDialog>
        </>
    );
};
