export interface ClientResponse {
    id: string;
    name: string;
    websiteURL: string | null;
    linkedInURL: string | null;
    isDefault: boolean;
    iconURL: string | null;
    description: string | null;
}

export const DefaultClientResponse: ClientResponse = {
    id: '',
    name: '',
    websiteURL: null,
    linkedInURL: null,
    isDefault: false,
    iconURL: null,
    description: null,
};

export const DefaultDefaultClientResponse: ClientResponse = {
    id: '',
    name: '',
    websiteURL: null,
    linkedInURL: null,
    isDefault: true,
    iconURL: null,
    description: null,
};
