import { useTheme } from '@mui/material';
import React from 'react';
import { Paper, Stack, Typography } from '@talentmesh/core';
import { heightSpacing, paddingBottomSpacing, paddingTopSpacing } from '../RecruitmentListItemConsts';

interface StatusCounterProps {
    status: string;
    count: number;
}
function BigStatusCounter({ status, count }: StatusCounterProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: theme.palette.background.silverSand,
                height: theme.spacing(heightSpacing),
                paddingTop: theme.spacing(paddingTopSpacing),
                paddingBottom: theme.spacing(paddingBottomSpacing),
            }}
        >
            <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ height: '100%' }}>
                <Typography variant="h6" color={theme.palette.text.menuTitle}>
                    {status}
                </Typography>
                <Typography variant="h2" color={theme.palette.text.menuTitle}>
                    {count}
                </Typography>
            </Stack>
        </Paper>
    );
}

export default BigStatusCounter;
