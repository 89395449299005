import React from 'react';
import { RoundButtonTab, Tabs } from '@talentmesh/core';
import { useCandidatesResultsContext } from '../Contexts/CandidateResultsContext';
import {
    getTabHeaderByTestType,
    getCandidateResultsTabValue,
    isTestCompleted,
    hasCandidateOnboared,
    mapTestTypeToResultTabType,
    ResultTabType,
} from '../Utils/ResultsUtils';
import { mainTestTypesRenderingOrder } from '../../Utils/Utils';
import UIStrings from '../../../../Utils/UIStrings';

function ResultsTab(): JSX.Element {
    const { results, activeTestDetailsTab, setActiveTestDetailsTab } = useCandidatesResultsContext();
    const handleChange = (_: React.SyntheticEvent, newValue: ResultTabType) => {
        setActiveTestDetailsTab(newValue);
    };

    return (
        <Tabs value={activeTestDetailsTab} onChange={handleChange}>
            {mainTestTypesRenderingOrder.map((testType) => {
                const resultTabType = mapTestTypeToResultTabType(testType);

                if (results.includedTests.indexOf(testType) > -1) {
                    return (
                        <RoundButtonTab
                            key={resultTabType}
                            label={getTabHeaderByTestType(testType)}
                            value={getCandidateResultsTabValue(resultTabType)}
                            disabled={!isTestCompleted(results, testType)}
                        />
                    );
                }
                return null;
            })}
            {results.includesExperienceCriteria && (
                <RoundButtonTab
                    key={ResultTabType.Experience}
                    label={UIStrings.Experience}
                    value={getCandidateResultsTabValue(ResultTabType.Experience)}
                    disabled={!hasCandidateOnboared(results.candidateStatus)}
                />
            )}
            <RoundButtonTab
                key={ResultTabType.ApplicantInformation}
                label={UIStrings.CVAndApplicantInformation}
                value={getCandidateResultsTabValue(ResultTabType.ApplicantInformation)}
            />
            <RoundButtonTab
                key={ResultTabType.Note}
                label={UIStrings.Notes}
                value={getCandidateResultsTabValue(ResultTabType.Note)}
            />
        </Tabs>
    );
}

export default ResultsTab;
