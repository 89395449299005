import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { RTEInitValue } from '@talentmesh/rte';
import {
    useAssessmentClient,
    useAutocompleteOptionsClient,
    useCareerClient,
    useClientsClient,
    useCriteriaClient,
    useJobAdClient,
    useJobTestInfoClient,
    useQuestionsConfigurationClient,
    useSettingsClient,
} from '../../../../../Hooks/ClientHooks';
import { AssessmentStatus, TestTypes } from '../../../../../Models/Configuration';
import { Currency } from '../../../../../Models/Currency';
import ProcessingState from '../../../../../Models/ProcessingState';
import { ApplicantInformation } from '../../Models/ApplicantInformation';
import { DefaultJobAd, JobAd } from '../../Models/JobAd';
import { JobDetails } from '../../Models/JobDetails';
import { mapTestInfoDto2Model } from '../../Models/TestInfo';
import {
    CreateRecruitmentContextState,
    DefaultCreateRecruitmentContextState,
    mapCreateRecruitmentContextState2Dto,
} from './CreateRecruitmentContextState';
import GenerateJobAdDTO from '../../../../../DTO/JobAds/GenerateJobAdDTO';
import { getJobFunctionName } from '../../../../../Utils/JobFunctionSelectionUtils';
import { useAssessmentSettingsContext } from '../../../../../Context/AssessmentSettingsContext';
import JobAdMapper from '../../Steps/JobAdStep/Builder/JobAdMapper';
import { WritingTone } from '../../../../../Models/WritingTone';
import { extractTextWithNewLinesFromHtml } from '../../Steps/JobAdStep/JobAdStepUtils';
import ImproveJobAdDTO from '../../../../../DTO/JobAds/ImproveJobAdDTO';
import AIJobAdDTO from '../../../../../DTO/JobAds/AIJobAdDTO';
import { DemoQuestions, mapDemoQuestionsDto2Model } from '../../Models/Questions';
import useCompanyId from '../../../../../Hooks/UseCompanyId';
import { useCompanyContext } from '../../../../../Context/CompanyContext';
import { jobDetailsDeepEqual } from '../../Steps/JobDetailsStep/JobDetailsStepUtils';
import { isDefaultLanguage } from '../../../../../Utils/JobAdLanguage/JobAdLanguages';
import GenerateExperienceCriteriaDTO, {
    createGenerateExperienceCriteriaDTO,
} from '../../../../../DTO/ExperienceCriteria/GenerateExperienceCriteriaDTO';
import useClientIdPathParam from '../../../../../Hooks/UseClientIdPathParam';
import { ClientResponse, DefaultClientResponse } from '../../../../../DTO/Clients/ClientResponse';
import { UpdateClientRequest } from '../../../../../DTO/Clients/UpdateClientRequest';

interface ICreateRecruitmentContext extends CreateRecruitmentContextState {
    client: ClientResponse;
    currencies: Currency[];
    locales: string[];
    gettingDataProcessingState: ProcessingState;

    setContextState: (value: CreateRecruitmentContextState) => void;
    setJobAd: (value: JobAd) => void;
    setIncludedTests: (value: TestTypes[]) => void;
    setRecruitmentCreationOption: (value: AssessmentStatus) => void;
    setApplicantInformation: (value: ApplicantInformation[]) => void;
    setProcessingState: (value: ProcessingState) => void;
    setTestLanguage: (value: string) => void;
    setExperienceCriteriaForm: (valueSet: {
        experienceCriteria: CreateRecruitmentContextState['experienceCriteria'];
        includesExperienceCriteria: CreateRecruitmentContextState['includesExperienceCriteria'];
    }) => void;
    resetExperienceCriteriaProcessingState: () => void;
    generateExperienceCriteria: () => Promise<void>;

    updateJobDetails: (newJobDetails: JobDetails) => Promise<void>;
    getCareerPageStatus: () => Promise<boolean>;
    createRecruitmentAsync: (clientId: string, assessmentStatus: AssessmentStatus) => Promise<string>;
    generateJobAdAsync: () => Promise<void>;
    improveJobAdAsync: (tone: WritingTone, title: string, jobDescription: string, companyDesc: string) => Promise<void>;
    getDemoQuestions: (testLang: string) => Promise<DemoQuestions>;

    resolvedCompanyDescription: string;
    shouldGenerateJobAdCompanyDescription: () => boolean;
    closeDialog: () => void;
    setShouldRegenExperienceCriteria: (value: CreateRecruitmentContextState['regenerateExperienceCriteria']) => void;
}

const CreateRecruitmentContext = createContext<ICreateRecruitmentContext | undefined>(undefined);

const CreateRecruitmentContextProvider = CreateRecruitmentContext.Provider;

interface CreateRecruitmentContextProviderProps {
    children: ReactNode;
}

export const CreateRecruitmentProvider = ({ children }: CreateRecruitmentContextProviderProps): JSX.Element => {
    const { updateCompanyProfile, currentCompanyProfile } = useCompanyContext();
    const { companyName, companyDescription } = currentCompanyProfile;

    const hookCompanyId = useCompanyId();
    const clientIdFromPath = useClientIdPathParam();

    const [state, setState] = useState<CreateRecruitmentContextState>({
        ...DefaultCreateRecruitmentContextState,
    });

    const {
        recruitmentId,
        clientId,
        jobDetails,
        jobAd,
        includedTests,
        testInfos,
        applicantInformation,
        recruitmentCreationOption,
        processingState,
        regenerateAtStep2,
        regenerateExperienceCriteria,
        showErrorDialog,
        testLanguage,
        experienceCriteria,
        experienceCriteriaProcessingState,
        includesExperienceCriteria,
    } = state;

    const assessmentClient = useAssessmentClient();
    const jobTestInfoClient = useJobTestInfoClient();
    const autocompleteOptionsClient = useAutocompleteOptionsClient();
    const settingsClient = useSettingsClient();
    const jobAdClient = useJobAdClient();
    const careerClient = useCareerClient();
    const configuration = useAssessmentSettingsContext();
    const questionsConfigurationClient = useQuestionsConfigurationClient();
    const criteriaClient = useCriteriaClient();
    const clientsClient = useClientsClient();

    const [client, setClient] = useState<ClientResponse>(DefaultClientResponse);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [locales, setLocales] = useState<string[]>([]);

    // Job ad company description is taken either from:
    // - tenant settings (company description) if client is default (My company)
    // - client description if client is not default
    // We use this state to store resolved company description in the job ad.
    const [resolvedCompanyDescription, setResolvedCompanyDescription] = useState<string>('');
    const [gettingDataProcessingState, setGettingDataProcessingState] = useState(ProcessingState.Processing);

    const getDemoQuestions = useCallback(
        async (testLang: string) => {
            const demoQuestionsDto = await jobTestInfoClient.getDemoQuestionsAsync(
                jobDetails.jobFunctionId,
                testLang === 'applicant' ? 'en' : testLang,
            );
            const demoQuestions = mapDemoQuestionsDto2Model(demoQuestionsDto);
            return demoQuestions;
        },
        [jobDetails.jobFunctionId],
    );

    const setProcessingState = (value: ProcessingState): void => {
        setState((prevState) => {
            return {
                ...prevState,
                processingState: value,
            };
        });
    };

    /*
     * Function to resolve company description in the job ad
     * - If client is default (My company) then company description is taken from the tenant settings
     * - If client is not default, then company description is taken from client description
     * @returns resolved company description which is used for job ad company description
     * */
    const resolveJobAdCompanyDescription = (clientResponse: ClientResponse): string => {
        const jobAdInitialDescription = clientResponse.isDefault
            ? companyDescription
            : clientResponse.description ?? RTEInitValue;
        setResolvedCompanyDescription(jobAdInitialDescription);
        return jobAdInitialDescription;
    };

    const shouldGenerateJobAdCompanyDescription = (): boolean => {
        // Company description is generated in the following cases:
        // 1. Resolved company description is empty in the settings
        // 2. Resolved company description is not empty and job ad language is not default
        // 3. If job posting type is on behalf but on no named basis then company description should be generated
        const isResolvedCompanyDescriptionEmpty = extractTextWithNewLinesFromHtml(resolvedCompanyDescription) === '';
        const shouldGenerateCompanyDescription =
            isResolvedCompanyDescriptionEmpty ||
            !isDefaultLanguage(jobDetails.jobDescriptionLanguage) ||
            jobDetails.jobPostingType === 'OnBehalfNoNamedBasis';

        return shouldGenerateCompanyDescription;
    };

    useEffect(() => {
        // loads all required data on create recruitment page initialization
        const loadInfoAsync = async () => {
            try {
                const currenciesList = await settingsClient.getCurrenciesAsync();
                setCurrencies(currenciesList);

                const localesList = await questionsConfigurationClient.getTestLocalesAsync();
                setLocales(localesList);

                const retrievedClient = await clientsClient.getClientByIdAsync(clientIdFromPath);
                setClient(retrievedClient);

                // set job posting type depending on client type
                setState((prevState) => {
                    return {
                        ...prevState,
                        jobDetails: {
                            ...prevState.jobDetails,
                            jobPostingType: retrievedClient.isDefault ? 'Direct' : 'OnBehalf',
                        },
                    };
                });

                // once client is loaded we can resolve company description which should be used for job ad
                resolveJobAdCompanyDescription(retrievedClient);

                setGettingDataProcessingState(ProcessingState.Success);
            } catch {
                setGettingDataProcessingState(ProcessingState.Error);
            }
        };
        loadInfoAsync();
    }, []);

    const setContextState = (value: CreateRecruitmentContextState): void => {
        setState(value);
    };

    const setJobAd = (value: JobAd): void => {
        setState((prevState) => {
            return {
                ...prevState,
                jobAd: { ...value },
                regenerateAtStep2: false,
            };
        });
    };

    const setIncludedTests = (value: TestTypes[]): void => {
        setState((prevState) => {
            return {
                ...prevState,
                includedTests: value,
            };
        });
    };

    const setApplicantInformation = (value: ApplicantInformation[]): void => {
        setState((prevState) => {
            return {
                ...prevState,
                applicantInformation: value,
            };
        });
    };

    const setRecruitmentCreationOption = (value: AssessmentStatus): void => {
        setState((prevState) => {
            return {
                ...prevState,
                recruitmentCreationOption: value,
            };
        });
    };

    const setTestLanguage = (value: string): void => {
        setState((prevState) => {
            return {
                ...prevState,
                testLanguage: value,
            };
        });
    };

    const includeTest = (contextState: CreateRecruitmentContextState, testType: TestTypes): boolean => {
        if (contextState.testInfos.findIndex((x) => x.testType === testType) >= 0) {
            contextState.includedTests.push(testType);
            return true;
        }

        return false;
    };

    const updateJobDetails = async (newJobDetails: JobDetails): Promise<void> => {
        setProcessingState(ProcessingState.Processing);
        try {
            const { jobFunctionId } = newJobDetails;
            const testInfosDto = await jobTestInfoClient.getTestInfosAsync(jobFunctionId);
            const newTestInfos = testInfosDto.map((dto) => mapTestInfoDto2Model(dto));
            const equal = jobDetailsDeepEqual(jobDetails, newJobDetails);
            let newJobAd = equal ? jobAd : DefaultJobAd;

            if (!equal) {
                const didJobFunctionChange = jobDetails.jobFunctionId !== newJobDetails.jobFunctionId;
                const { hardSkills, softSkills } = didJobFunctionChange
                    ? await autocompleteOptionsClient.getDefaultSkillsOptions(jobFunctionId)
                    : { hardSkills: jobAd.hardSkills, softSkills: jobAd.softSkills };

                newJobAd = {
                    ...newJobAd,
                    companyDescription: isDefaultLanguage(newJobDetails.jobDescriptionLanguage)
                        ? resolvedCompanyDescription
                        : jobAd.companyDescription,
                    hardSkills,
                    hardSkillsSwitch: didJobFunctionChange ? hardSkills.length > 0 : jobAd.hardSkillsSwitch,
                    softSkills,
                    softSkillsSwitch: didJobFunctionChange ? softSkills.length > 0 : jobAd.softSkillsSwitch,
                };
            }

            const newContextState: CreateRecruitmentContextState = {
                ...state,
                recruitmentId: '',
                jobDetails: newJobDetails,
                jobAd: newJobAd,
                includedTests: [],
                testInfos: newTestInfos,
                applicantInformation: [],
                processingState: ProcessingState.Success,
                regenerateAtStep2: !equal,
            };

            includeTest(newContextState, TestTypes.Personality);
            if (!includeTest(newContextState, TestTypes.Skills)) {
                includeTest(newContextState, TestTypes.Aptitude);
            }
            if (newContextState.jobDetails.directReports !== 'None') {
                includeTest(newContextState, TestTypes.EmotionalIntelligence);
            }

            setContextState(newContextState);
        } catch (e) {
            setProcessingState(ProcessingState.Error);
        }
    };

    const getCareerPageStatus = async (): Promise<boolean> => {
        setProcessingState(ProcessingState.Processing);
        try {
            const careerPageStats = await careerClient.getCareerPageStatsAsync(hookCompanyId);
            setProcessingState(ProcessingState.Success);
            return careerPageStats.publishedDate !== null;
        } catch (e) {
            setProcessingState(ProcessingState.Error);
            throw e;
        }
    };

    const createRecruitmentAsync = async (
        clientIdToCreateFor: string,
        assessmentStatus: AssessmentStatus,
    ): Promise<string> => {
        let id = '';
        setProcessingState(ProcessingState.Processing);
        try {
            const dto = mapCreateRecruitmentContextState2Dto(state, assessmentStatus);
            const result = await assessmentClient.createAssessmentAsync(clientIdToCreateFor, dto);
            id = result.id;

            // Only update company description in the settings if:
            // - company description is empty AND
            // - public recruitment is created AND
            // - job ad language is default (English)
            if (
                resolvedCompanyDescription === RTEInitValue &&
                assessmentStatus === 'Public' &&
                isDefaultLanguage(jobDetails.jobDescriptionLanguage)
            ) {
                // if client is default, the tenant settings are updated
                // otherwise, the client is updated
                if (client.isDefault) {
                    await updateCompanyProfile({
                        ...currentCompanyProfile,
                        companyDescription: jobAd.companyDescription,
                    });
                } else if (jobDetails.jobPostingType !== 'OnBehalfNoNamedBasis') {
                    // in no named basis case, client description is generated every time and should not be saved under client description
                    // preserve everything except company description
                    const updateClientRequest: UpdateClientRequest = {
                        name: client.name,
                        websiteURL: client.websiteURL,
                        linkedInURL: client.linkedInURL,
                        description: jobAd.companyDescription,
                    };
                    await clientsClient.updateClientAsync(client.id, updateClientRequest);
                }
            }
            setProcessingState(ProcessingState.Success);
        } catch (e) {
            setProcessingState(ProcessingState.Error);
            throw e;
        }

        return id;
    };

    const mapAndSetJobAd = (value: AIJobAdDTO): void => {
        const ja = new JobAdMapper(value, jobAd.companyDescription).map();
        setState((prev: CreateRecruitmentContextState) => ({
            ...prev,
            jobAd: {
                ...prev.jobAd,
                title: ja.title,
                description: ja.description,
                descriptionLen: ja.descriptionLen,
                companyDescription: ja.companyDescription,
            },
            processingState: ProcessingState.Success,
        }));
    };

    const generateJobAdAsync = async (): Promise<void> => {
        const shouldGenerateCompanyDescription = shouldGenerateJobAdCompanyDescription();
        try {
            setProcessingState(ProcessingState.Processing);

            const dto: GenerateJobAdDTO = {
                jobPostingType: jobDetails.jobPostingType,
                jobTitle: jobDetails.name,
                companyName,
                clientName: client.isDefault ? undefined : client.name,
                workArrangement: jobDetails.workArrangement,
                jobLocation: jobDetails.locationDetails.description,
                jobFunction: getJobFunctionName(
                    configuration.assessmentSettings.jobCategories,
                    jobDetails.jobFunctionId,
                ),
                workExperience: jobDetails.workExperience,
                employmentType: jobDetails.employmentType,
                includeCompanyDescription: shouldGenerateCompanyDescription,
                jobAdLanguage: jobDetails.jobDescriptionLanguage.label,
            };

            const response = await jobAdClient.generateJobAdAsync(dto);
            mapAndSetJobAd(response);
        } catch (error) {
            setState((prev) => ({
                ...prev,
                showErrorDialog: 'JobAdGenerationError',
                processingState: ProcessingState.Idle,
            }));
        }
    };

    const improveJobAdAsync = async (tone: WritingTone, title: string, jobDescription: string, companyDesc: string) => {
        try {
            setState((prev) => ({
                ...prev,
                processingState: ProcessingState.Processing,
                jobAd: { ...prev.jobAd, title: ' ' }, // imitate empty field without causing Required error for title field
            }));
            const plainJobDescription = extractTextWithNewLinesFromHtml(jobDescription);
            const plainCompanyDescription = extractTextWithNewLinesFromHtml(companyDesc);

            const includeCompanyDescription = shouldGenerateJobAdCompanyDescription();

            const dto: ImproveJobAdDTO = {
                title,
                jobAd: plainJobDescription,
                tone,
                jobAdLanguage: jobDetails.jobDescriptionLanguage.label,
                companyDescription: includeCompanyDescription ? plainCompanyDescription : undefined,
            };
            const response = await jobAdClient.improveJobAdAsync(dto);
            mapAndSetJobAd(response);
        } catch (error) {
            setState((prev) => ({
                ...prev,
                showErrorDialog: 'JobAdImproveError',
                processingState: ProcessingState.Idle,
                jobAd: { ...prev.jobAd, title }, // in case there is an error - rollback title to the initial value
            }));
        }
    };

    const closeDialog = () => {
        setState((prev) => {
            return {
                ...prev,
                showErrorDialog: undefined,
            };
        });
    };

    const generateExperienceCriteria = async () => {
        setState((p) => ({
            ...p,
            experienceCriteriaProcessingState: ProcessingState.Processing,
            regenerateExperienceCriteria: false,
        }));

        try {
            const dto: GenerateExperienceCriteriaDTO = createGenerateExperienceCriteriaDTO(jobAd, jobDetails);
            const response = await criteriaClient.generateCriteriaAsync(dto);
            setState((prev: CreateRecruitmentContextState) => ({
                ...prev,
                experienceCriteria: response.criteria,
                experienceCriteriaProcessingState: ProcessingState.Success,
                includesExperienceCriteria: true,
            }));
        } catch (error) {
            setState((prev) => ({ ...prev, experienceCriteriaProcessingState: ProcessingState.Error }));
        }
    };

    const resetExperienceCriteriaProcessingState = () => {
        setState((prev) => ({ ...prev, experienceCriteriaProcessingState: ProcessingState.Idle }));
    };

    const setExperienceCriteriaForm: ICreateRecruitmentContext['setExperienceCriteriaForm'] = (newValue) => {
        setState((p) => ({
            ...p,
            experienceCriteria: newValue.experienceCriteria,
            includesExperienceCriteria: newValue.includesExperienceCriteria,
        }));
    };

    const setShouldRegenExperienceCriteria: ICreateRecruitmentContext['setShouldRegenExperienceCriteria'] = (value) => {
        setState((p) => ({
            ...p,
            regenerateExperienceCriteria: p.regenerateExperienceCriteria || value,
        }));
    };

    return (
        <CreateRecruitmentContextProvider
            value={{
                recruitmentId,
                clientId,
                jobDetails,
                jobAd,
                includedTests,
                testInfos,
                applicantInformation,
                recruitmentCreationOption,
                processingState,
                regenerateAtStep2,
                regenerateExperienceCriteria,
                showErrorDialog,
                testLanguage,
                includesExperienceCriteria,
                experienceCriteria,
                experienceCriteriaProcessingState,
                generateExperienceCriteria,

                client,
                currencies,
                locales,
                gettingDataProcessingState,

                setContextState,
                setJobAd,
                setIncludedTests,
                setRecruitmentCreationOption,
                setApplicantInformation,
                setProcessingState,
                setTestLanguage,
                updateJobDetails,
                getCareerPageStatus,
                createRecruitmentAsync,
                generateJobAdAsync,
                improveJobAdAsync,
                closeDialog,
                getDemoQuestions,
                setExperienceCriteriaForm,
                resetExperienceCriteriaProcessingState,

                resolvedCompanyDescription,
                shouldGenerateJobAdCompanyDescription,
                setShouldRegenExperienceCriteria,
            }}
        >
            {children}
        </CreateRecruitmentContextProvider>
    );
};

export function useCreateRecruitmentContext() {
    const context = useContext(CreateRecruitmentContext);
    if (!context) {
        throw new Error('useCreateRecruitmentContext must be used within the CreateRecruitmentContext.Provider');
    }
    return context;
}
